import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
import { FileUpload } from 'primeng/fileupload';
import Utils from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../loader.service';
import { AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { AuditCampaignService } from './audit-campaign.service';
var AuditCampaignInitiateComponent = /** @class */ (function () {
    function AuditCampaignInitiateComponent(AuditCampaignService, loaderService, alertDialogService, router) {
        this.AuditCampaignService = AuditCampaignService;
        this.loaderService = loaderService;
        this.alertDialogService = alertDialogService;
        this.router = router;
        this.validated = false;
        this.validationError = false;
        this.fileToUpload = null;
        this.fileDetails = null;
        this.maxTransactions = environment.maxAuditTransactionsPerFile;
        this.enabledActionTypes = this.AuditCampaignService
            .actionTypes.filter(function (t) { return t.enabled; });
        this.auditCampaignFormGroup = new FormGroup({
            actionType: new FormControl(null, Validators.required)
        });
    }
    AuditCampaignInitiateComponent.prototype.resetFile = function () {
        this.fileDetails = null;
        this.validated = false;
        this.validationError = false;
    };
    ;
    AuditCampaignInitiateComponent.prototype.ngOnInit = function () {
        this.fileUploadRef && this.fileUploadRef.clear();
        this.resetFile();
    };
    AuditCampaignInitiateComponent.prototype.selectedActionType = function () {
        return this.auditCampaignFormGroup.get("actionType").value;
    };
    AuditCampaignInitiateComponent.prototype.handleFile = function (event) {
        var _this = this;
        this.validated = false;
        var file = event.files[0];
        Papa.parse(file, {
            complete: function (results) {
                _this.fileDetails = { records: results.data, name: file.name },
                    _this.fileToUpload = file;
            },
            header: true,
            skipEmptyLines: true,
            transformHeader: function (header) { return header.trim(); },
            transform: function (value, header) { return _this.parseCSVValue(value, header); },
        });
        this.resetFile();
    };
    AuditCampaignInitiateComponent.prototype.parseCSVValue = function (value, header) {
        var actionType = this.selectedActionType();
        var arrayFields = actionType.arrayFields || [];
        if (arrayFields.includes(header)) {
            try {
                if (!value || value.length == 0) {
                    console.log("ERROR: File Validation error .Invalid file format/incomplete information in the file! " + ("" + header) + " value can't be empty");
                    throw new Error("Invalid file format/incomplete information in the file! " + ("" + header) + " value can't be empty");
                }
            }
            catch (err) {
                this.handleError(err);
            }
            return value.trim();
        }
        return value.trim();
    };
    AuditCampaignInitiateComponent.prototype.downloadSampleFile = function () {
        var actionType = this.selectedActionType();
        if (!actionType) {
            return;
        }
        Utils.downloadCSVFile({
            fields: actionType.headers,
            data: actionType.sampleData,
            fileName: actionType.code.toLowerCase() + ".csv",
        });
    };
    AuditCampaignInitiateComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(function (confirmed) { return confirmed && cb(); });
    };
    AuditCampaignInitiateComponent.prototype.valdidateAuditCampaignFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var validCount;
            return tslib_1.__generator(this, function (_a) {
                validCount = this.validateTransactionCount();
                if (!validCount) {
                    return [2 /*return*/];
                }
                this.showAlertBox({
                    title: 'Success',
                    message: 'File validation successful.',
                });
                this.validated = true;
                return [2 /*return*/];
            });
        });
    };
    AuditCampaignInitiateComponent.prototype.validateTransactionCount = function () {
        var transactions = this.fileDetails.records;
        var count = transactions.length;
        if (count > this.maxTransactions) {
            this.showAlertBox({
                title: 'Error',
                message: "You can only upload " + this.maxTransactions + " transactions at a time.",
            });
            return false;
        }
        return true;
    };
    AuditCampaignInitiateComponent.prototype.confirmAuditCampaignInitiate = function () {
        var _this = this;
        this.showAlertBox({
            title: 'Confirm',
            message: "You are about to start audit for " + this.fileDetails.records.length + " captains. Do you want to proceed?",
            confirmText: 'Yes, proceed',
        }, function () { return _this.initiateAudit(); });
    };
    AuditCampaignInitiateComponent.prototype.initiateAudit = function () {
        var _this = this;
        this.loaderService.openLoading();
        this.AuditCampaignService
            .initiateAudit(this.selectedActionType(), this.fileToUpload)
            .then(function () {
            _this.showAlertBox({
                title: 'Success',
                message: 'Audit initiated successfully.'
            });
        })
            .catch(function (err) { return _this.handleError(err); })
            .finally(function () { return _this.loaderService.closeLoading(); });
    };
    AuditCampaignInitiateComponent.prototype.handleError = function (err) {
        var unknownErr = "Something went wrong !!! Please check console log.";
        var res = err.error || { message: unknownErr };
        if (!res.data) {
            this.showAlertBox({
                title: 'Error',
                message: res.message,
            });
            return;
        }
        var data = res.data;
        if (data.code) {
            this.validationError = true;
            var errors = data.errors;
            var value = errors
                .map(function (err) { return "Record: " + err.recordNumber + " - " + err.error; });
            this.showAlertBox({
                title: 'Validation Error',
                details: [
                    {
                        value: value,
                        message: "Please correct the errors and try again."
                    }
                ],
            });
            return;
        }
        var error = data.error || data;
        this.showAlertBox({
            title: 'Error',
            message: error.message || unknownErr,
        });
    };
    return AuditCampaignInitiateComponent;
}());
export { AuditCampaignInitiateComponent };
