<app-assignment-details *ngIf="incentiveIdForPerformace" [incentiveId]="incentiveIdForPerformace"
  (close)="incentiveIdForPerformace = null">
</app-assignment-details>
<app-toaster [config]="toastService.toaster"></app-toaster>
<filter-pane [filterComponents]="filterComponents" [showFilter]="showFilters" (change)="filterChange($event)"
  (submit)="applyFilters($event)"></filter-pane>
<div>
  <mat-toolbar class="toolbar-border">
    Incentive Details
    <div class="pull-right is-flex" style="height: 35px;">
      <input type="text" style="margin-right: 10px;" (change)="nameSearchChange()"
        [(ngModel)]="requestBody.incentiveName" placeholder="Search By Name">
      <button mat-raised-button class="btn-primary" (click)="showFilters = !showFilters">Filters</button>
    </div>
  </mat-toolbar>
  <data-table [table]="table" (paginationChange)="fetchTableData()" (headerToSort)="sortByHeader($event)"></data-table>
</div>
