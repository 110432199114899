import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../shared.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnChanges, OnInit {

  constructor(private router: Router,
    private sharedService: SharedService) { }

  @Input() header: any;
  @Input() state: any;
  @Input() incentiveId: any;
  @Output() selectedIndex: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    this.header = changes.header.currentValue;
  }

  ngOnInit() {
  }

  move(index: number) {
    if (this.state === this.sharedService.STATE.VIEW) {
    this.router.navigate([`incentive/${this.incentiveId}/${this.sharedService.STATE.EDIT}`]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
    // this.router.navigate([`incentive/${this.incentiveId}/${this.sharedService.STATE.EDIT}`,
    // {dummyData: (new Date()).getTime()}]);
    // this.router.navigate(["/url", {myRealData: RealData, dummyData: (new Date).getTime()}])

    }
   this.selectedIndex.emit(index);
  }

}
