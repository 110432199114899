import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
export var BASE_URL = {
    OLD_DASHBOARD: environment.rapido_dashboard,
};
var SharedService = /** @class */ (function () {
    function SharedService(http) {
        this.http = http;
        this.getCities = environment.server + '/api/get/cities';
        this.getServices = environment.server + '/api/get/services';
        this.getShifts = environment.server + '/api/get/shifts';
        this.getZones = environment.server + '/api/get/zones';
        this.getRules = environment.server + '/api/get/rules';
        this.getRuleDetails = environment.server + '/api/get/rules/details/';
        this.incentiveMetrics = environment.server + '/api/incentive/metrics';
        this.getTranslations = environment.server + '/api/translations';
        this.STATE = {
            CREATE: 'create',
            EDIT: 'edit',
            VIEW: 'view',
            DUPLICATE: 'duplicate'
        };
        this.INCENTIVE_TYPES = [
            'Daily',
            'Weekly Fixed',
            'Adhoc'
        ];
        this.INCENTIVE_TYPES_MAP = {
            "daily": "Daily",
            "weekly": "Weekly",
            "adhoc": "Adhoc",
        };
        this.SEGMENT_TYPES = [
            'UHP D UHO',
            'HP D UHO',
            'HP D HO',
            'MP D UHO',
            'MP D HO',
            'MP D MO',
            'MP D LO',
            'LP D UHO',
            'LP D HO',
            'LP D MO',
            'LP D LO',
            'MP Intra UHO',
            'MP Intra HO',
            'MP Intra MO',
            'MP Intra LO',
            'LP Intra UHO',
            'LP Intra HO',
            'LP Intra MO',
            'LP Intra LO',
            'LP Inter UHO',
            'LP Inter HO',
            'LP Inter MO',
            'LP Inter LO',
            'CUSTOM'
        ];
        this.SUBSCRIPTION_VALIDITY_TYPES = ["earnings", "rides", "unlimited"];
    }
    SharedService.prototype.fetchTranslations = function (locales, keys) {
        var queryparams = "?";
        locales.forEach(function (locale) { return queryparams += "&locales[]=" + locale; });
        keys.forEach(function (key) { return queryparams += "&keys[]=" + key; });
        return this.http.get(this.getTranslations + queryparams).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getIncentiveMetrics = function (incentiveId) {
        return this.http.get(this.incentiveMetrics + "?incentiveId=" + incentiveId)
            .pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchCities = function () {
        return this.http.get(this.getCities).pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchServices = function (id) {
        var payload = {
            city: id
        };
        return this.http.post(this.getServices, payload).pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchServicesWithMode = function (cityId) {
        return this.http.get(environment.server + "/api/" + cityId + "/services").pipe(catchError(this.handleError));
    };
    SharedService.prototype.getUserSelectorDetails = function (ruleId) {
        return this.http.get(this.getRuleDetails + ruleId).pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchShifts = function (city) {
        return this.http.get(this.getShifts + '/' + city).pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchRules = function (city, service, shift, userType, selectorType) {
        if (selectorType === void 0) { selectorType = ""; }
        var queryparams = '?userType=' + userType + '&city=' + city;
        if (shift) {
            queryparams += '&shift=' + shift;
        }
        if (service) {
            queryparams += '&service=' + service;
        }
        if (selectorType) {
            queryparams += '&type=' + selectorType;
        }
        return this.http.get(this.getRules + queryparams).pipe(catchError(this.handleError));
    };
    SharedService.prototype.fetchZones = function (city, groupId) {
        var queryparams = '?cityId=' + city + '&groupId=' + groupId;
        return this.http.get(this.getZones + queryparams).pipe(catchError(this.handleError));
    };
    SharedService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    SharedService.prototype.redirectToDashbaord = function (url) {
        var redirectUrl = "" + BASE_URL.OLD_DASHBOARD + url;
        var token = this.readCookie('token');
        window.open(redirectUrl + "?token=" + token);
    };
    SharedService.prototype.readCookie = function (name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    };
    SharedService.ngInjectableDef = i0.defineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.inject(i1.MyHttpClient)); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
export { SharedService };
