import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
var ViewSlabsService = /** @class */ (function () {
    function ViewSlabsService(http) {
        this.http = http;
        this.fetchSlabs = environment.server + '/api/get/slabs'; // make this configurable
    }
    ViewSlabsService.prototype.fetchSlabData = function (payload) {
        return this.http.post(this.fetchSlabs, payload).pipe(catchError(this.handleError));
    };
    ViewSlabsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    ViewSlabsService.ngInjectableDef = i0.defineInjectable({ factory: function ViewSlabsService_Factory() { return new ViewSlabsService(i0.inject(i1.MyHttpClient)); }, token: ViewSlabsService, providedIn: "root" });
    return ViewSlabsService;
}());
export { ViewSlabsService };
