import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { LoaderService } from 'src/app/loader.service';
import { AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { ToasterService } from 'src/app/toaster.service';
import { SubscriptionService } from '../../subscription.service';
import { environment } from 'src/environments/environment';
var CreateAccessFeeComponent = /** @class */ (function () {
    function CreateAccessFeeComponent(sharedService, subsService, loader, alertService, toasterService) {
        this.sharedService = sharedService;
        this.subsService = subsService;
        this.loader = loader;
        this.alertService = alertService;
        this.toasterService = toasterService;
        this.accessFeeFormGroup = new FormGroup({
            planName: new FormControl(null),
            city: new FormControl(null),
            services: new FormControl(null),
            zones: new FormControl(null),
            userSelector: new FormControl(null),
            targetSegment: new FormControl('userSelectors'),
            priority: new FormControl(null),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            tiers: new FormControl([])
        });
        this.cities = [];
        this.zones = [];
        this.services = [];
        this.userSelectors = [];
        this.servicesOrSDIDsAllowedForAccessFeeCreation = {
            serviceIds: new Set(environment.servicesOrSDIDsAllowedForAccessFeeCreation.serviceIds)
        };
        this.today = moment().startOf('day').toDate();
        this.tomorrowEod = moment().endOf('day').add(1, 'days').toDate();
        this.minEndDate = this.tomorrowEod;
        this.fetchCities();
        this.addTier();
    }
    CreateAccessFeeComponent.prototype.resetOnchangeOf = function (controlName, toReset, cb) {
        var _this = this;
        if (cb === void 0) { cb = function () { }; }
        this.accessFeeFormGroup.get(controlName).valueChanges.subscribe(function () {
            toReset.forEach(function (control) {
                _this.accessFeeFormGroup.get(control).setValue(null);
            });
            cb();
        });
    };
    CreateAccessFeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetOnchangeOf("city", ["services", "zones", "userSelector", "tiers", "startDate", "endDate", "priority"], function () {
            _this.services = [];
            _this.userSelectors = [];
            try {
                _this.addTier();
                _this.fetchServices();
            }
            catch (err) {
                console.error(err);
            }
        });
        this.resetOnchangeOf("services", ["zones", "userSelector", "tiers", "startDate", "endDate", "priority"], function () {
            _this.userSelectors = [];
            _this.zones = [];
            try {
                _this.addTier();
                _this.fetchZones();
                _this.fetchSelectors();
                _this.validateModeAndGeoGroupIdForServices();
            }
            catch (err) {
                console.error(err);
            }
        });
        this.resetOnchangeOf("zones", ["userSelector", "tiers", "startDate", "endDate", "priority"], function () {
            _this.addTier();
        });
        this.resetOnchangeOf("targetSegment", ["priority", "userSelector"], function () {
        });
    };
    CreateAccessFeeComponent.prototype.fetchSelectors = function () {
        var _this = this;
        var city = this.formValue("city");
        var services = this.formValue("services");
        if (city && services) {
            var serviceTypes = services
                .map(function (s) { return s.serviceDetailId; }).join(",");
            this.loader.openLoading();
            this.sharedService
                .fetchRules(city.id, serviceTypes, null, "rider", "custom")
                .toPromise()
                .then(function (res) {
                _this.userSelectors = res.data.selectors
                    .map(function (_a) {
                    var _id = _a._id, name = _a.name;
                    return ({ id: _id, name: name });
                });
            })
                .finally(function () { return _this.loader.closeLoading(); });
        }
    };
    CreateAccessFeeComponent.prototype.formValue = function (name) {
        return this.accessFeeFormGroup.get(name).value;
    };
    CreateAccessFeeComponent.prototype.fetchZones = function () {
        var _this = this;
        var city = this.formValue("city");
        var services = this.formValue("services");
        var serviceWithGeoGroupId = services.find(function (service) { return service.geoServiceGroupId !== ''; });
        var firstNonEmptyGeoServiceGroupId = serviceWithGeoGroupId ? serviceWithGeoGroupId.geoServiceGroupId : undefined;
        if (city && services && firstNonEmptyGeoServiceGroupId) {
            this.loader.openLoading();
            this.sharedService
                .fetchZones(city.id, firstNonEmptyGeoServiceGroupId)
                .toPromise()
                .then(function (res) {
                _this.zones = res.geolayers
                    .map(function (_a) {
                    var id = _a.id, name = _a.name;
                    return ({ id: id, name: name });
                });
            })
                .finally(function () { return _this.loader.closeLoading(); });
        }
    };
    CreateAccessFeeComponent.prototype.fetchServices = function () {
        var _this = this;
        var city = this.formValue("city");
        if (city) {
            this.loader.openLoading();
            this.sharedService
                .fetchServicesWithMode(city.id)
                .toPromise()
                .then(function (res) {
                _this.services = res.data
                    .filter(function (serviceDetail) { return serviceDetail.active; })
                    .filter(function (_a) {
                    var _id = _a._id, service = _a.service;
                    return _this.servicesOrSDIDsAllowedForAccessFeeCreation.serviceIds.has(service._id);
                })
                    .map(function (_a) {
                    var service = _a.service, _id = _a._id, modeInfo = _a.modeInfo, geoServiceGroupId = _a.geoServiceGroupId;
                    return ({
                        id: service._id,
                        name: service.name,
                        mode: modeInfo._id,
                        serviceDetailId: _id,
                        geoServiceGroupId: geoServiceGroupId
                    });
                });
            })
                .finally(function () { return _this.loader.closeLoading(); });
        }
    };
    CreateAccessFeeComponent.prototype.fetchCities = function () {
        var _this = this;
        this.loader.openLoading();
        this.sharedService
            .fetchCities()
            .toPromise()
            .then(function (res) {
            _this.cities = res.data.cities
                .map(function (_a) {
                var _id = _a._id, displayName = _a.displayName;
                return ({ id: _id, name: displayName });
            });
        })
            .finally(function () { return _this.loader.closeLoading(); });
    };
    CreateAccessFeeComponent.prototype.changeInDate = function () {
        var startDate = this.formValue("startDate");
        if (startDate) {
            this.minEndDate = startDate;
        }
        var endDate = this.formValue("endDate");
        if (endDate) {
            this.maxStartDate = endDate;
        }
        else {
            this.maxStartDate = null;
        }
    };
    CreateAccessFeeComponent.prototype.createAccessFeeRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var plans, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loader.openLoading();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.buildBillingPlan()];
                    case 2:
                        plans = _a.sent();
                        if (!plans) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.subsService.createBillingPlan({ plans: plans }).subscribe(function (resp) {
                                if (resp.rejectedPlans && resp.rejectedPlans.length > 0) {
                                    alert(resp.reason);
                                }
                                else {
                                    _this.alertService.open({
                                        title: "Success",
                                        message: "Access Fee rules created successfully",
                                    });
                                }
                            })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _a.sent();
                        this.alertService.open({
                            title: "Error",
                            message: error_1.message,
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        this.loader.closeLoading();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CreateAccessFeeComponent.prototype.alertMessage = function (alert) {
        this.alertService.open(alert);
    };
    CreateAccessFeeComponent.prototype.errorMessage = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message
        }));
    };
    CreateAccessFeeComponent.prototype.validateField = function (fieldName, message) {
        var value = this.formValue(fieldName);
        if (!value) {
            this.errorMessage(message);
            throw new Error(message);
        }
        return value;
    };
    CreateAccessFeeComponent.prototype.buildBillingPlan = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var planName, city, services, mode, zones, startDate, endDate, priority, userSelector, minRides, i, commonFields;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                planName = this.validateField("planName", "Plan name is required");
                city = this.validateField("city", "City is required");
                services = this.validateField("services", "Services is required");
                mode = this.validateModeAndGeoGroupIdForServices();
                zones = this.formValue("zones");
                startDate = this.validateField("startDate", "Purchase Start date is required");
                endDate = this.validateField("endDate", "Purchase  End date is required");
                if (this.accessFeeFormGroup.get('targetSegment').value === 'userSelectors') {
                    priority = this.formValue("priority");
                    if (!priority || !Number.isInteger(priority) || priority <= 0) {
                        this.errorMessage("Priority is required/should be positive number for userSelector");
                        throw new Error("Priority is required/should be positive number for userSelector");
                    }
                    userSelector = this.formValue("userSelector");
                    if (!userSelector) {
                        this.errorMessage("UserSelector is required for targetSegment userSelector");
                        throw new Error("UserSelector is required for targetSegment userSelector");
                    }
                }
                if (this.formValue('tiers').length < 1) {
                    this.errorMessage("At least one tier is required");
                }
                if (this.formValue('tiers').length > 1) {
                    minRides = this.formValue('tiers').map(function (tier) { return tier.minRides.value; });
                    for (i = 1; i < minRides.length; i++) {
                        if (minRides[i] <= minRides[i - 1]) {
                            this.errorMessage("Minimum rides should be in increasing order");
                            throw new Error("Minimum rides should be in increasing order");
                        }
                    }
                }
                commonFields = {
                    planName: planName,
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                    priority: Number(priority),
                    city: city.id,
                    services: services.map(function (s) { return s.id; }),
                    zones: (zones && zones.length > 0) ? zones.map(function (z) { return z.id; }) : undefined,
                    mode: mode,
                    userSelectors: userSelector ? userSelector.map(function (usId) { return usId.id; }) : undefined,
                };
                return [2 /*return*/, [tslib_1.__assign({}, commonFields, { tiers: this.formValue('tiers').map(function (tier, index) {
                                _this.validateTier(tier, index);
                                return {
                                    minValue: tier.minRides.value,
                                    amount: tier.tierAmount.value,
                                    strikeOutAmount: tier.strikeOutAmount.value
                                };
                            }) })]];
            });
        });
    };
    CreateAccessFeeComponent.prototype.validateTier = function (tier, index) {
        if (!tier.minRides || !Number.isInteger(tier.minRides.value) || tier.minRides.value <= 0) {
            this.errorMessage("minimum rides value is required for tier " + (index + 1));
            throw new Error("Minimum rides value is required/ should be non zero positive number for tier " + (index + 1));
        }
        if (!tier.tierAmount || (!Number.isInteger(tier.tierAmount.value) || tier.tierAmount.value < 0)) {
            this.errorMessage("Charged Fee is required and should be integer for tier  " + (index + 1));
            throw new Error("Charged Fee is required and should be non negative number for tier " + (index + 1));
        }
        if (tier.strikeOutAmount && (tier.strikeOutAmount.value || tier.strikeOutAmount.value == 0) &&
            (!Number.isInteger(tier.strikeOutAmount.value) || tier.strikeOutAmount.value < 0 || tier.strikeOutAmount.value <= tier.tierAmount.value)) {
            this.errorMessage("Striked Out amount should be positive number for tier " + (index + 1));
            throw new Error("Striked Out should be greater than charged fee for tier " + (index + 1));
        }
    };
    CreateAccessFeeComponent.prototype.addTier = function () {
        var defaultTierElement = {
            tierCondition: new FormControl(null),
            tierAmount: new FormControl(null),
            minRides: new FormControl(null),
            strikeOutAmount: new FormControl(null),
        };
        if (this.formValue('tiers')) {
            this.formValue('tiers').push(defaultTierElement);
        }
        else {
            this.accessFeeFormGroup.get('tiers').setValue([defaultTierElement]);
        }
    };
    CreateAccessFeeComponent.prototype.deleteTier = function (index) {
        this.formValue('tiers').splice(index, 1);
    };
    CreateAccessFeeComponent.prototype.validateModeAndGeoGroupIdForServices = function () {
        if (!this.formValue('services')) {
            return;
        }
        var distinctModes = new Set(this.formValue('services').map(function (service) { return service.mode; }));
        var distinctGeoGroupId = new Set(this.formValue('services')
            .map(function (service) { return service.geoServiceGroupId; }));
        if (distinctModes.size > 1) {
            this.accessFeeFormGroup.get('services').setValue(null);
            this.alertMessage({
                title: "Error",
                message: "Please select services with same mode"
            });
            throw new Error("services with different mode selected");
        }
        if (distinctGeoGroupId.size > 1) {
            this.accessFeeFormGroup.get('services').setValue(null);
            this.alertMessage({
                title: "Error",
                message: "Please select services with same geoGroupId"
            });
            throw new Error("services with different geoGroupId selected");
        }
        var mode = distinctModes.values().next().value;
        var geoServiceGroupId = distinctModes.values().next().value;
        return mode;
    };
    return CreateAccessFeeComponent;
}());
export { CreateAccessFeeComponent };
