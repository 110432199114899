import { Routes } from '@angular/router';
import { SidenavContentComponent } from './shared/sidenav-content/sidenav-content.component';
import { SigninComponent } from './signin/signin.component';
import { ViewSlabsComponent } from './view-slabs/view-slabs.component';
import { ViewIncentivesComponent } from './view-incentives/view-incentives.component';
import { IAllocatorIncentiveComponent } from './i-allocator-incentive/i-allocator-incentive.component';
import { CreateIncentiveComponent } from './create-incentive/create-incentive.component';
import { AuthGuardService } from './auth-guard.service';
import { PenaltyRewardCampaignComponent } from './penalty-reward-campaign/penalty-reward-campaign.component';
import { ViewCampaignsComponent } from './view-campaigns/view-campaigns.component';
import { ViewIncentiveComponent } from './view-incentive/view-incentive.component';
import { BulkCreditInitiateComponent } from './bulkcredit/initiate/initiate.component';
import { BulkCreditHistoryComponent } from './bulkcredit/history/history.component';
import { AUDIT_CAMPAIGN_INITIATE, BULK_CREDIT_HISTORY, BULK_CREDIT_INITIATE, CREATE_SUBSCRIPTION, LIST_SUBSCRIPTIONS, VIEW_SUBSCRIPTION } from './routes';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AuditCampaignInitiateComponent } from './penalty-reward-campaign/audit-campaign/audit-campaign.component';
import { IAllocatorConstructUploadComponent } from './i-allocator-incentive/construct-upload/construct-upload.component';
import { ListSubscriptionsComponent } from './subscription/prepaid/list/list.component';
import { ViewSubscriptionRuleComponent } from './subscription/prepaid/view/view.component';
var ɵ0 = { permission: 'edit_incentive' }, ɵ1 = { permission: 'edit_incentive' }, ɵ2 = { permission: 'edit_penalty_reward' }, ɵ3 = { permission: 'view_penalty_reward' }, ɵ4 = { permission: 'edit_incentive' }, ɵ5 = { permission: 'iallocator_construct_upload' }, ɵ6 = { permission: 'view_penalty_reward' }, ɵ7 = { permission: 'edit_audit_campaign' }, ɵ8 = { permission: 'bulkpay_create' }, ɵ9 = { permission: 'bulkpay_history' }, ɵ10 = { permission: 'captain_subscription_create' };
var routes = [
    {
        path: 'login',
        component: SigninComponent
    },
    {
        path: '',
        canActivate: [AuthGuardService],
        component: SidenavContentComponent,
        children: [
            {
                path: 'createIncentive',
                component: CreateIncentiveComponent,
                data: ɵ0
            },
            {
                path: 'slabs',
                component: ViewSlabsComponent
            },
            {
                path: 'incentives',
                component: ViewIncentivesComponent
            },
            {
                path: 'viewIncentive/:incentiveId/:action',
                component: ViewIncentiveComponent,
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'incentive/:incentiveId/:action',
                component: CreateIncentiveComponent,
                runGuardsAndResolvers: 'always',
                data: ɵ1,
            },
            {
                path: 'penalty-reward-campaign',
                children: [{
                        path: 'create',
                        component: PenaltyRewardCampaignComponent,
                    }],
                canActivate: [AuthGuardService],
                data: ɵ2
            },
            {
                path: 'campaigns',
                component: ViewCampaignsComponent,
                canActivate: [AuthGuardService],
                data: ɵ3
            },
            {
                path: 'newIncentive',
                canActivate: [AuthGuardService],
                component: IAllocatorIncentiveComponent,
                data: ɵ4
            },
            {
                path: 'uploadConstruct',
                canActivate: [AuthGuardService],
                component: IAllocatorConstructUploadComponent,
                data: ɵ5
            },
            {
                path: 'campaign/:campaignId/:status/:action',
                component: PenaltyRewardCampaignComponent,
                data: ɵ6
            },
            {
                path: AUDIT_CAMPAIGN_INITIATE,
                canActivate: [AuthGuardService],
                component: AuditCampaignInitiateComponent,
                data: ɵ7
            },
            {
                path: BULK_CREDIT_INITIATE,
                canActivate: [AuthGuardService],
                component: BulkCreditInitiateComponent,
                data: ɵ8
            },
            {
                path: BULK_CREDIT_HISTORY,
                canActivate: [AuthGuardService],
                component: BulkCreditHistoryComponent,
                data: ɵ9
            },
            {
                path: CREATE_SUBSCRIPTION,
                canActivate: [AuthGuardService],
                component: SubscriptionComponent,
                data: ɵ10
            },
            {
                path: LIST_SUBSCRIPTIONS,
                canActivate: [AuthGuardService],
                component: ListSubscriptionsComponent,
            },
            {
                path: VIEW_SUBSCRIPTION,
                canActivate: [AuthGuardService],
                component: ViewSubscriptionRuleComponent,
            }
        ]
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
