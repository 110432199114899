<div class="center">
  <form [formGroup]="accessFeeFormGroup" class="flex-col width-800">
    <h2 class="center margin-y-10">
      Create AccessFee Rules
    </h2>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Plan Name:</label>
      <input formControlName="planName" name="planName" placeholder="Plan Name" class="flex-grow font-20 margin-l-10" />
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">City:</label>
      <ng-select formControlName="city" name="city" placeholder="Select city" class="flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let item of cities" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Services:</label>
      <ng-select formControlName="services" name="services" placeholder="Select services" (change)="validateModeAndGeoGroupIdForServices();"
        class="flex-grow font-20 margin-l-10" [multiple]=true>
        <ng-option *ngFor="let item of services" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Zones:</label>
      <ng-select formControlName="zones" name="zones" placeholder="Select zones"
        class="flex-grow font-20 margin-l-10" [multiple]=true>
        <ng-option *ngFor="let item of zones" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>


    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Plan Start Date:</label>
      <p-calendar formControlName="startDate" icon="pi pi-calendar" class="font-20 margin-l-10" name="startDate"
        [showIcon]=true dateFormat="dd-mm-yy" (ngModelChange)="changeInDate()" [minDate]="today" [defaultDate]="today"
        [maxDate]="maxStartDate" [selectOtherMonths]="true" [showButtonBar]="true"
        class="flex-grow font-20 margin-l-10">
      </p-calendar>
    </div>
    
    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Plan End Date:</label>
      <p-calendar formControlName="endDate" icon="pi pi-calendar" class="font-20 margin-l-10" name="endDate"
        [defaultDate]="tomorrowEod" [showIcon]=true dateFormat="dd-mm-yy" (ngModelChange)="changeInDate()"
        [selectOtherMonths]="true" [showButtonBar]="true" [minDate]="minEndDate"
        class="flex-grow font-20 margin-l-10">
      </p-calendar>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Target Segment:</label>
      <div class="custom-control custom-radio" style="padding-right: 10px;">
        <input type="radio" id="userSelectors" name="targetSegment" class="margin-l-10" [value]="'userSelectors'" formControlName="targetSegment" checked>
        <label for="userSelectors" style="font-weight: bold;">User Selector</label>
      </div>
      <div class="custom-control custom-radio" style="padding-right: 10px;">
        <input type="radio" id="entireCity" name="targetSegment" class="margin-l-10" [value]="'entireCity'" formControlName="targetSegment">
        <label for="entireCity" style="font-weight: bold;">Entire City(includes HH)</label>
      </div>
    </div>

    <div class="flex-row margin-y-10" *ngIf="accessFeeFormGroup.get('targetSegment').value === 'userSelectors'">
      <label class="width-50 font-20 bold">Plan Priority:</label>
      <input formControlName="priority" class="flex-grow font-20 margin-l-10" name="priority" type="number" />
    </div>
    
    <div class="flex-row margin-y-10" *ngIf="accessFeeFormGroup.get('targetSegment').value === 'userSelectors'">
      <label class="width-50 font-20 bold">User Selector:</label>
      <ng-select formControlName="userSelector" name="userSelector" placeholder="Select User Selector" class="flex-grow font-20 margin-l-10" [multiple]=true>
        <ng-option *ngFor="let item of userSelectors" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>



    <div *ngFor="let tier of formValue('tiers') || []; let i = index">

      <label class="width-50 font-20 bold">Tier {{i+1}}:</label>

      <button mat-stroked-button (click)="deleteTier(i)" [disabled]="formValue('tiers').length === 1" color="warn"
      class="right-align">
      <mat-icon>delete</mat-icon>
      </button>

      <div class="margin-l-50">

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Condition for deduction</label>
          <ng-select name="condition" placeholder="Select deduction Condition"
            class="flex-grow font-20 margin-l-10">
            <ng-option value="Days">Days</ng-option>
          </ng-select>
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Days Condition</label>
          <ng-select name="daysCondition" placeholder="Select Number of Days"
            class="flex-grow font-20 margin-l-10">
            <ng-option value="daysCondition">1</ng-option>
          </ng-select>
        </div>
        
        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Minimum Rides</label>
          <input [formControl]="tier.minRides" class="flex-grow font-20 margin-l-10" name="minRides" type="number" />
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Charged Fee</label>
          <input [formControl]="tier.tierAmount" class="flex-grow font-20 margin-l-10" name="tierAmount" type="number" pattern="^[0-9]*$" />
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Striked Out Amount</label>
          <input [formControl]="tier.strikeOutAmount" class="flex-grow font-20 margin-l-10" name="strikeOutAmount"
            type="number" pattern="^[0-9]*$"/>
        </div>
      </div>
    </div>

    <div class="margin-y-10 flex-row pull-right">
      <button mat-stroked-button (click)="addTier()">
        <mat-icon>add</mat-icon>
        Add Tier
      </button>
    </div>

    <div class="margin-y-10 flex-row pull-right">
      <button mat-raised-button class="btn-primary" (click)="createAccessFeeRules()"> Create Access Fee Rules </button>
    </div>
  </form>
</div>
<app-toaster [config]="toasterService.toaster"></app-toaster>

