/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rolling-window-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./rolling-window-dialog.component";
var styles_RollingWindowDialogComponent = [i0.styles];
var RenderType_RollingWindowDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RollingWindowDialogComponent, data: {} });
export { RenderType_RollingWindowDialogComponent as RenderType_RollingWindowDialogComponent };
export function View_RollingWindowDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "imgStyle"], ["src", "../../assets//svgImages/rolling_window.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "dialog-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rolling Window"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select the rolling time period over which the number of trigger event will be counted."])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "dialog-btn"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["Okay"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 10, 0, currVal_0); }); }
export function View_RollingWindowDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rolling-window-dialog", [], null, null, null, View_RollingWindowDialogComponent_0, RenderType_RollingWindowDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.RollingWindowDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RollingWindowDialogComponentNgFactory = i1.ɵccf("app-rolling-window-dialog", i3.RollingWindowDialogComponent, View_RollingWindowDialogComponent_Host_0, {}, {}, []);
export { RollingWindowDialogComponentNgFactory as RollingWindowDialogComponentNgFactory };
