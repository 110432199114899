import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../global-constants';
import { LoaderService } from '../loader.service';
import { AlertDialogService } from '../shared/alert-dialog/alert-dialog.service';
import { Toaster, ToasterType } from '../shared/types/toaster.types';
import { ToasterService } from '../toaster.service';
import { IAllocatorService } from './i-allocator.service';
var IAllocatorIncentiveComponent = /** @class */ (function () {
    function IAllocatorIncentiveComponent(toasterService, iAllocatorService, loaderService, alertDialogService) {
        this.toasterService = toasterService;
        this.iAllocatorService = iAllocatorService;
        this.loaderService = loaderService;
        this.alertDialogService = alertDialogService;
        this.config = _.sortBy(environment.iAllocatorConfig, 'cityName');
        this.todayDate = new Date();
        this.minDate = this.todayDate;
        this.defaultCaptainPhase = "PHH-Recent";
        this.iAllocatorFormGroup = new FormGroup({
            selectedCity: new FormControl(null, Validators.required),
            selectedService: new FormControl(null, Validators.required),
            selectedIncentive: new FormControl(null, Validators.required),
            fromDate: new FormControl(null, Validators.required),
            toDate: new FormControl(null, Validators.required),
            budget: new FormControl(0, Validators.required),
            captainPhase: new FormControl(this.defaultCaptainPhase, Validators.required)
        });
    }
    IAllocatorIncentiveComponent.prototype.ngOnInit = function () {
    };
    IAllocatorIncentiveComponent.prototype.errorMessage = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message
        }));
    };
    IAllocatorIncentiveComponent.prototype.getValues = function (name) {
        return this.iAllocatorFormGroup.get(name).value;
    };
    IAllocatorIncentiveComponent.prototype.getServices = function () {
        var config = this.getValues("selectedCity") || { services: [] };
        return config.services;
    };
    IAllocatorIncentiveComponent.prototype.budgetAllocationEnabled = function () {
        var config = this.getValues("selectedCity");
        var incentiveType = this.getValues("selectedIncentive");
        return config && incentiveType && (config.budgetAllocation || []).includes(incentiveType);
    };
    IAllocatorIncentiveComponent.prototype.getIncentiveTypes = function () {
        var config = this.getValues("selectedCity") || { incentiveTypes: [] };
        return config.incentiveTypes;
    };
    IAllocatorIncentiveComponent.prototype.getCaptainPhases = function () {
        var config = this.getValues("selectedCity");
        return config && config.captainPhases ? config.captainPhases.concat(this.defaultCaptainPhase) : [this.defaultCaptainPhase];
    };
    IAllocatorIncentiveComponent.prototype.citySelected = function () {
        this.iAllocatorFormGroup.patchValue({
            selectedService: null,
            selectedIncentive: null,
            captainPhase: this.defaultCaptainPhase
        });
        this.constructs = null;
    };
    IAllocatorIncentiveComponent.prototype.changeInDate = function () {
        if (this.getValues('fromDate')) {
            this.minDate = this.getValues('fromDate');
        }
        if (this.getValues('toDate')) {
            this.maxDate = this.getValues('toDate');
        }
    };
    IAllocatorIncentiveComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(function (confirmed) { return confirmed && cb(); });
    };
    IAllocatorIncentiveComponent.prototype.fetchConstructs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, constructs, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = this.createRequest();
                        if (!request) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.loaderService.openLoading();
                        return [4 /*yield*/, this.iAllocatorService.fetchIncentiveConstructs(request)];
                    case 2:
                        constructs = _a.sent();
                        constructs
                            .segments
                            .forEach(function (segment) { return _this.calculateSegmentTargets(segment); });
                        this.constructs = constructs;
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        this.showAlertBox({
                            title: 'Error',
                            message: 'Looks like iAllocator is down. Please reach out to the Captain-Retention team and try again later.',
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    IAllocatorIncentiveComponent.prototype.calculateSegmentTargets = function (segment) {
        var _this = this;
        var segmentInfo = segment.segmentInfo;
        segmentInfo
            .forEach(function (si) { return si.days.sort(_this.compareDays); });
        segmentInfo
            .sort(function (a, b) {
            var aEnd = a.days[a.days.length - 1];
            var bStart = b.days[0];
            return _this.compareDays(aEnd, bStart);
        })
            .forEach(function (incentive) { return _this.calculateIncentiveTargets(segment, incentive); });
        this.validateIncentiveDays(segmentInfo);
    };
    IAllocatorIncentiveComponent.prototype.calculateIncentiveTargets = function (segment, incentive) {
        incentive
            .forecastedData
            .forEach(function (f) {
            var distanceTarget = 0;
            var prevRideTarget = 0;
            f.rideSlabs
                .forEach(function (slab) {
                if (segment.distanceBaseTarget) {
                    if (!slab.perOrderDistance) {
                        throw new Error("Invalid incentive missing distance targets");
                    }
                    var rides = slab.rideTarget - prevRideTarget;
                    prevRideTarget = slab.rideTarget;
                    distanceTarget += rides * slab.perOrderDistance;
                    slab.distanceTarget = distanceTarget;
                    slab.rides = rides;
                }
                slab.suggestedIPR = slab.ipr;
            });
        });
    };
    IAllocatorIncentiveComponent.prototype.validateIncentiveDays = function (segementInfo) {
        var validateDays = function (days) {
            days.forEach(function (d) {
                if (!GlobalConstants.AllIncentiveWeekDays.includes(d)) {
                    throw new Error("Invalid days in segment incentive: " + d);
                }
            });
            var start = GlobalConstants.AllIncentiveWeekDays.indexOf(days[0]);
            var end = GlobalConstants.AllIncentiveWeekDays.indexOf(days[days.length - 1]);
            if (end - start != (days.length - 1)) {
                throw new Error("Invalid incentive contruct day range");
            }
            return days;
        };
        var allDays = _.flatMap(segementInfo, function (s) { return validateDays(_.uniq(s.days)); });
        var seen = {};
        allDays.forEach(function (d) {
            if (!seen[d]) {
                seen[d] = true;
                return;
            }
            throw new Error("Overlapping day range in incentives");
        });
    };
    IAllocatorIncentiveComponent.prototype.validateAndGet = function (field, message) {
        var value = this.getValues(field);
        if (!value) {
            this.errorMessage(message);
            throw new Error(message);
        }
        return value;
    };
    IAllocatorIncentiveComponent.prototype.createRequest = function () {
        var startDate = this.validateAndGet('fromDate', 'Start date is required');
        var endDate = this.validateAndGet('toDate', 'Start date is required');
        var incentiveType = this.validateAndGet("selectedIncentive", "Incentive type is required");
        this.validateDates(startDate, endDate);
        var budget = this.budgetAllocationEnabled() ?
            this.validateAndGet('budget', 'Budget is required') : undefined;
        var captainPhase = this.validateAndGet('captainPhase', 'captain phase is required');
        return {
            cityId: this.validateAndGet("selectedCity", "City is required").cityId,
            services: [this.validateAndGet("selectedService", "Service is required")],
            incentiveType: incentiveType,
            startDate: this.formatDate(startDate),
            endDate: this.formatDate(endDate),
            budget: budget,
            captainPhase: captainPhase
        };
    };
    IAllocatorIncentiveComponent.prototype.validateDates = function (startDate, endDate) {
        if (startDate.getDay() != 1) {
            this.errorMessage("Start date should be Monday");
            throw new Error("Start date should be Monday");
        }
        if (endDate.getDay() != 0) {
            this.errorMessage("End date should be Sunday");
            throw new Error("End date should be Sunday");
        }
    };
    IAllocatorIncentiveComponent.prototype.compareDays = function (day1, day2) {
        return GlobalConstants.AllIncentiveWeekDays.indexOf(day1) - GlobalConstants.AllIncentiveWeekDays.indexOf(day2);
    };
    IAllocatorIncentiveComponent.prototype.formatDate = function (date) {
        return moment(date).format('YYYY-MM-DD');
    };
    ;
    return IAllocatorIncentiveComponent;
}());
export { IAllocatorIncentiveComponent };
