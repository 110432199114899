import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatStepper } from '@angular/material';
import { IncentiveService } from '../create-incentive/create-incentive.service';
import { ToastMessage } from '../toast-message/toast-message.service';
import { DatePipe } from '@angular/common';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import {Router, ActivatedRoute} from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader.service';


@Component({
  selector: 'app-create-incentive',
  templateUrl: './create-incentive.component.html',
  providers: [ToastMessage, DatePipe]
})
export class CreateIncentiveComponent implements OnInit {
  submittingForm = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  public incentiveData: any = {};
  incentiveId: any;
  defaultValue: any= {};
  formLoaded = false;
  @ViewChild('stepper') stepper: MatStepper;
  state = this.sharedService.STATE.CREATE;

  public NOT_EDITABLE = {
    incentiveName: false,
    selectedSegment: false,
    identifier: false,
    incentiveDisplayName: false,
    selectedIncentive: false,
    selectedCity: false,
    selectedService: false,
    selectedShift: false,
    selectedRule: false,
    fromDate: false,
    toDate: false,
    priority: false,

    variables: false,
    timings: false,
    goals: false,
    weeklyDays: false,

    tnc: false,
    smsTemplate: false,
    isHHIncentive: false,
  };

  constructor(private formBuilder: FormBuilder,
              private incentiveService: IncentiveService,
              private toastMessage: ToastMessage,
              private datePipe: DatePipe,
              public toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private loaderService: LoaderService
              ) {}

  createFormUpdateRule() {
    const startDate = this.defaultValue.startDate;
    if (!startDate) {
      return {};
    }
    const currentDate = new Date();
    const insentiveStartDate = new Date(this.defaultValue.startDate);
    if (this.defaultValue.incentiveType == 'Adhoc') {
      if (this.defaultValue.active)
        Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
          this.NOT_EDITABLE[fieldName] = true;
        });
      else {
        Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
          this.NOT_EDITABLE[fieldName] = false;
        });
      }
    } else if (this.defaultValue.incentiveType == 'Weekly Fixed'){
      this.NOT_EDITABLE['weeklyDays'] = true;
      this.NOT_EDITABLE['variables'] = true;
      this.NOT_EDITABLE['incentiveName'] = true;
      this.NOT_EDITABLE['selectedIncentive'] = true;
      this.NOT_EDITABLE['selectedShift'] = true;
      this.NOT_EDITABLE['selectedCity'] = true;
      this.NOT_EDITABLE['selectedRule'] = true;
      this.NOT_EDITABLE['selectedService'] = true;
      this.NOT_EDITABLE['fromDate'] = true;
      this.NOT_EDITABLE['priority'] = true;
      this.NOT_EDITABLE['timings'] = true;
    } else {
      if (insentiveStartDate.getTime() > currentDate.getTime()) {
        Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
          this.NOT_EDITABLE[fieldName] = false;
        });
      } else {
        Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
          this.NOT_EDITABLE[fieldName] = true;
        });
        this.NOT_EDITABLE.toDate = false
      }
    }
  }

  ngOnInit() {
    this.loaderService.openLoading();
    const incentiveId = this.route.snapshot.paramMap.get('incentiveId');
    this.incentiveId = incentiveId;
    const action = this.route.snapshot.paramMap.get('action') || this.sharedService.STATE.CREATE;
    switch (action) {
      case 'edit': {
        this.incentiveService.getIncentive(incentiveId)
          .subscribe((res: any) => {
            this.defaultValue = res.data;
            this.createFormUpdateRule();
            this.formLoaded = true;
            this.loaderService.closeLoading();
          });
        this.state = this.sharedService.STATE.EDIT;
        break;
      }
      case this.sharedService.STATE.CREATE: {
        this.formLoaded = true;
        this.loaderService.closeLoading();
        break;
      }
      case this.sharedService.STATE.DUPLICATE: {
        this.incentiveService.getIncentive(incentiveId)
          .subscribe((res: any) => {
            this.defaultValue = res.data;
            this.formLoaded = true;
            this.loaderService.closeLoading();
          });
        this.state = this.sharedService.STATE.DUPLICATE;
        break;
      }
    }
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ''
    });
  }

 goForward(stepper: MatStepper) {
    stepper.next();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }


  generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // sorting order
  orderGoal(goals) {
    Object.keys(goals).forEach(day => {
      const dayGoal = goals[day];
      dayGoal.forEach(goal => {
        goal.rules = goal.rules || [];
        goal.rules = goal.rules.sort((g1, g2) => {
          if (g1.order === g2.order) {
              return g1.distance > g2.distance ? 1 : -1;
          }
          return g1.order > g2.order ? 1 : -1;
        });
        goal.rules.map((rule, index) => {
          rule.index = index + 1;
        });
      });
    });
  }

  storeIncentiveInfo(event, type) {
    if (!event) {
      return;
    }
    if (this.submittingForm) {
      return;
    }
    this.submittingForm = true;
    this.loaderService.openLoading();
    if (event.submit === true) {
      let incentiveObj:any = {};
      incentiveObj['type'] = 'incentives';
      incentiveObj['incentiveName'] = this.incentiveData.basicInfo.incentiveName;
      incentiveObj['incentiveType'] = this.incentiveData.basicInfo.incentiveType;
      incentiveObj['cities'] = this.incentiveData.basicInfo.city;
      incentiveObj['shift'] = this.incentiveData.basicInfo.shift;
      incentiveObj['serviceType'] = this.incentiveData.basicInfo.service;
      incentiveObj['serviceNames'] = this.incentiveData.basicInfo.serviceNames;
      incentiveObj['ruleName'] = this.incentiveData.basicInfo.ruleName;
      incentiveObj['ruleId'] = this.incentiveData.basicInfo.ruleId;
      incentiveObj['startDate'] = this.incentiveData.basicInfo.startDate;
      incentiveObj['endDate'] = this.incentiveData.basicInfo.endDate;
      incentiveObj['priority'] = this.incentiveData.basicInfo.priority;
      incentiveObj['smsTemplate'] = this.incentiveData.communicationInfo.smsTemplate;
      incentiveObj['messageLocale'] = this.incentiveData.communicationInfo.messageLocale;
      incentiveObj['tnc'] = this.incentiveData.communicationInfo.termsAndCondition;
      incentiveObj['active'] = event.active;
      if (this.incentiveData.basicInfo.isHHIncentive) {
        incentiveObj['tags'] = ["HH"]
      }
      if (this.incentiveData.basicInfo.incentiveType === 'Redeem') {
        incentiveObj['redeemCondition'] = this.incentiveData.goalsInfo.selectedCondition;
        incentiveObj['redeem'] = this.incentiveData.goalsInfo.redeem;
      } else if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
        if(this.incentiveData.basicInfo.incentiveType === 'Daily'){
          if(this.incentiveData.goalsInfo.cancellationChecked){
            incentiveObj['cancellationLimit'] = this.incentiveData.goalsInfo.cancellationLimit;
            if(this.incentiveData.goalsInfo.selectedVariable.indexOf("cancellation") == -1){
              this.incentiveData.goalsInfo.selectedVariable.push("cancellation");
            }
          }else{
            delete incentiveObj.cancellationLimit;
          }
        } else if (this.incentiveData.basicInfo.incentiveType === 'Adhoc'){
          if(this.incentiveData.goalsInfo.maxOrderDistanceChecked){
            incentiveObj['maxOrderDistance'] = this.incentiveData.goalsInfo.maxOrderDistanceGlobal;
            if(this.incentiveData.goalsInfo.selectedVariable.indexOf("maxOrderDistance") == -1){
              this.incentiveData.goalsInfo.selectedVariable.push("maxOrderDistance");
            }
          }else{
            delete incentiveObj.maxOrderDistance;
          }
          incentiveObj['incentiveDisplayName'] = this.incentiveData.basicInfo.incentiveDisplayName;
        }
        incentiveObj['selectedCondition'] = (this.incentiveData.goalsInfo.selectedCondition instanceof Array) ?
                                            '' : this.incentiveData.goalsInfo.selectedCondition;
        incentiveObj['selectedVariable'] = this.incentiveData.goalsInfo.selectedVariable;
        let dailyIncentiveData = this.makeIncentiveOrdersAndDistanceCumulative(this.incentiveData.goalsInfo.dailyIncentiveData);
        let goals = {};
        goals['Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday'] = [];
        for (const index in dailyIncentiveData) {
          if (index) {
            let keys = Object.keys(dailyIncentiveData[index]);
            keys.splice(keys.indexOf('days'), 1);
            for (var key in keys) {
              if (key) {
                const existingUuid = dailyIncentiveData[index][keys[key]].uuid;
                let newSet: any = {
                  timeSlot: [],
                  rules: [],
                  uuid: this.generateUuid()
                };

                if(this.incentiveData.goalsInfo.cancellationChecked){
                  newSet.cancellationLimit = this.incentiveData.goalsInfo.setsCancellationLimit[key];
                }
                if (this.state === this.sharedService.STATE.EDIT) {
                  newSet.uuid = existingUuid || newSet.uuid;
                }
                newSet.timeSlot = dailyIncentiveData[index][keys[key]].timeSlot;
                for (let i in newSet.timeSlot) {
                  if (newSet.timeSlot[i]) {
                    try {
                      newSet.timeSlot[i].fromTime = this.datePipe.transform(newSet.timeSlot[i].fromTime, 'HH:mm');
                      newSet.timeSlot[i].toTime = this.datePipe.transform(newSet.timeSlot[i].toTime, 'HH:mm');
                    } catch(e) {
                      console.log(e)
                    }
                  }
                }
                newSet.rules = dailyIncentiveData[index][keys[key]].rules;
                if(this.incentiveData.goalsInfo.cancellationChecked){
                  for(var rule in newSet.rules){
                    if(this.incentiveData.goalsInfo.cancellationLimitScope == "global"){
                      newSet.rules[rule].cancellationLimit = this.incentiveData.goalsInfo.cancellationLimit;
                    }else{
                      newSet.rules[rule].cancellationLimit = this.incentiveData.goalsInfo.setsCancellationLimit[key];
                    }
                  }
                }else{
                  for(var rule in newSet.rules){
                    if(newSet.rules[rule].cancellationLimit != null){
                      delete newSet.rules[rule].cancellationLimit;
                    }
                  }
                }
                goals['Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday'].push(newSet);
              }
            }
          }
        }
        this.orderGoal(goals);
        incentiveObj['goals'] = goals;
      } else {
        incentiveObj['selectedCondition'] = this.incentiveData.goalsInfo.selectedCondition;
        incentiveObj['selectedVariable'] = this.incentiveData.goalsInfo.selectedVariable;
        const weeklyData = this.makeIncentiveOrdersAndDistanceCumulative(this.incentiveData.goalsInfo.weeklyIncentiveData);
        const goals = {};
        for (const goal in weeklyData) {
          if (goal && weeklyData[goal]) {
            const day = weeklyData[goal].days;
            delete weeklyData[goal]['days'];
            delete weeklyData[goal]['startDay'];
            delete weeklyData[goal]['endDay'];
            delete weeklyData[goal]['disabled'];
            goals[day] =  [];
            for (const rule in weeklyData[goal]) {
              if (rule && weeklyData[goal][rule]) {
                for (const time in weeklyData[goal][rule].timeSlot) {
                  if (time && weeklyData[goal][rule].timeSlot[time]) {
                    weeklyData[goal][rule].timeSlot[time].fromTime =
                      this.datePipe.transform(weeklyData[goal][rule].timeSlot[time].fromTime, 'HH:mm');
                    weeklyData[goal][rule].timeSlot[time].toTime =
                      this.datePipe.transform(weeklyData[goal][rule].timeSlot[time].toTime, 'HH:mm');
                  }
                }
                if (this.state === this.sharedService.STATE.DUPLICATE) {
                  weeklyData[goal][rule].uuid = this.generateUuid();
                }
                goals[day].push(weeklyData[goal][rule]);
              }
            }
          }
        }
        this.orderGoal(goals);
        incentiveObj['goals'] = goals;
      }
      // this.loaderService.closeLoading();
      // throw new Error('shit');
      switch (this.state) {
        case this.sharedService.STATE.EDIT: {
          this.incentiveService.editIncentive(this.incentiveId, incentiveObj).subscribe((response: any) => {
            if (response) {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: 'Successfully updated ' +  incentiveObj['incentiveType'] + ' incentive',
              }));
              setTimeout( () => {
                this.submittingForm = false;
                this.router.navigate(['/incentives']);
              }, 1000);
            } else {
              this.submittingForm = false;
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'Cannot create ' +  incentiveObj['incentiveType'] +  ' incentive',
              }));
            }
            this.loaderService.closeLoading();
          }, error => {
            this.submittingForm = false;
            this.loaderService.closeLoading();
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Error in creating ' +  incentiveObj['incentiveType'] +  ' incentive',
            }));
          });
          break;
        }
        case this.sharedService.STATE.DUPLICATE:
        case this.sharedService.STATE.CREATE: {
          this.incentiveService.createIncentive(incentiveObj).subscribe((response: any) => {
            if (response) {
              // if (response && response['data'].success) {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: 'Successfully created ' +  incentiveObj['incentiveType'] + ' incentive',
              }));
              setTimeout( () => {
                this.submittingForm = false;
                this.router.navigate(['/incentives']);
              }, 500);
            }
            else {
              this.submittingForm = false;
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'Cannot create ' +  incentiveObj['incentiveType'] +  ' incentive',
              }));
            }
            this.loaderService.closeLoading();
          }, error => {
            this.submittingForm = false;
             this.loaderService.closeLoading();
             this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Error in creating ' +  incentiveObj['incentiveType'] +  ' incentive',
            }));
          });
          break;
        }
      }
    } else {
      const info = {...this.incentiveData};
      info[type] = event;
      this.incentiveData = info;
      this.submittingForm = false;
      this.loaderService.closeLoading();
    }
  }

  moveStepper(event) {
    this.stepper.selectedIndex = event;
  }

  makeIncentiveOrdersAndDistanceCumulative(incentiveData: any){
    for (let index in incentiveData) {
      if (index) {
        const keys = Object.keys(incentiveData[index]);
        for (let key in keys) {
          if (keys[key]) {
            let distanceSum = 0;
            let orderSum  = 0;
            for (const rule in incentiveData[index][keys[key]].rules) {
              if (rule) {
                if (incentiveData[index][keys[key]].rules[rule].order) {
                  orderSum += incentiveData[index][keys[key]].rules[rule].order;         
                  incentiveData[index][keys[key]].rules[rule].order = orderSum;
                }
                if (incentiveData[index][keys[key]].rules[rule].distance) {
                  distanceSum += incentiveData[index][keys[key]].rules[rule].distance;
                  incentiveData[index][keys[key]].rules[rule].distance = distanceSum;
                }
              }
            }
          }
        }
      }
    }
    return incentiveData;
  }
}
