import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SubscriptionService } from '../../subscription.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes } from '../../../shared/types';
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';
import { SharedService } from '../../../shared/shared.service';
import { ToasterService } from '../../../toaster.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { Toaster, ToasterType } from '../../../shared/types/toaster.types';
import { LoaderService } from '../../../loader.service';
import { AlertDialogService } from '../../../shared/alert-dialog/alert-dialog.service';
import { getEpochFromDate, getDateFromEpoch } from '../../../utils/utils';
var ListSubscriptionsComponent = /** @class */ (function () {
    function ListSubscriptionsComponent(subscriptionService, sharedService, loaderService, router, accessRights, toastService, dialogService, alertDialogService) {
        this.subscriptionService = subscriptionService;
        this.sharedService = sharedService;
        this.loaderService = loaderService;
        this.router = router;
        this.accessRights = accessRights;
        this.toastService = toastService;
        this.dialogService = dialogService;
        this.alertDialogService = alertDialogService;
        this.table = new Table({
            headers: {
                active: 'Status',
                cities: 'City',
                subscriptionName: 'Name',
                serviceNames: 'Service',
                startDate: 'Start Date',
                endDate: 'End Date',
                type: "Type",
                priority: "Priority",
                action: "Actions"
            },
            pagination: new Pagination(0, 15, 150, [15, 20]),
            data: []
        });
        this.showFilters = true;
        this.requestBody = {
            filters: {},
            sort: {
                sortBy: "startTime",
                sortOrder: -1
            }
        };
        this.cities = [];
        this.services = {};
        this.cityMap = {};
        this.cityIdNameMap = {};
        this.serviceMap = {};
        this.citiesFilter = {
            type: 'searchable-dropdown',
            title: 'City',
            values: [],
            default: [],
            key: 'subscription-city'
        };
        this.serviceFilter = {
            type: 'searchable-dropdown',
            title: 'Services',
            values: [],
            default: [],
            key: 'subscription-services'
        };
        this.displayFieldToActualFieldMap = {
            "startDate": "startTime",
            "endDate": "endTime"
        };
        this.filterComponents = [
            this.citiesFilter,
            this.serviceFilter,
            {
                type: 'radio-button',
                title: 'Type',
                name: 'type',
                values: ['Non-HH', 'HH'],
                default: 'Non-HH',
                key: 'subscription-HH'
            },
            {
                type: 'calendar',
                title: '',
                values: [],
                default: undefined,
                key: 'subscription-dates',
            },
            {
                type: 'radio-button',
                title: 'Status',
                name: 'status',
                values: ['Both', 'Active', 'Inactive'],
                default: 'Both',
                key: 'subscription-status'
            }
        ];
    }
    ListSubscriptionsComponent.prototype.ngOnInit = function () {
        this.fetchAllCities();
    };
    ListSubscriptionsComponent.prototype.withLoader = function (cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loaderService.openLoading();
                        return [4 /*yield*/, cb()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.fetchAllCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var citiesRes, citiesData, _i, citiesData_1, city;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.withLoader(function () { return _this.sharedService.fetchCities().toPromise(); })];
                    case 1:
                        citiesRes = _a.sent();
                        citiesData = citiesRes.data.cities;
                        this.cities = citiesData;
                        this.citiesFilter.values = citiesData.map(function (city) { return city.displayName; });
                        for (_i = 0, citiesData_1 = citiesData; _i < citiesData_1.length; _i++) {
                            city = citiesData_1[_i];
                            this.cityMap[city.displayName] = city._id;
                            this.cityIdNameMap[city._id] = city.displayName;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.fetchServices = function (cities) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cityMap, _i, _a, city, _b, cities_1, city, cityId, res, _c, _d, serviceDetail, name_1;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.services = {};
                        cityMap = new Map();
                        for (_i = 0, _a = this.cities; _i < _a.length; _i++) {
                            city = _a[_i];
                            cityMap.set(city.displayName, city._id);
                        }
                        _b = 0, cities_1 = cities;
                        _e.label = 1;
                    case 1:
                        if (!(_b < cities_1.length)) return [3 /*break*/, 4];
                        city = cities_1[_b];
                        cityId = cityMap.get(city);
                        if (!(cityId !== undefined)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.sharedService.fetchServices(cityId).toPromise()];
                    case 2:
                        res = _e.sent();
                        for (_c = 0, _d = res.data; _c < _d.length; _c++) {
                            serviceDetail = _d[_c];
                            name_1 = serviceDetail.service.name;
                            this.services[name_1] = this.services[name_1] || [];
                            this.services[name_1].push(serviceDetail._id);
                        }
                        _e.label = 3;
                    case 3:
                        _b++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.serviceFilter.values = Object.keys(this.services);
                        return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.filterChange = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (filters['subscription-city'] && filters['subscription-city'].length) {
                    this.withLoader(function () { return _this.fetchServices(filters['subscription-city']); });
                }
                return [2 /*return*/];
            });
        });
    };
    ListSubscriptionsComponent.prototype.sortByHeader = function (header) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sortOrder, column;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sortOrder = header.includes("-") ? -1 : 1;
                        column = header.replace("-", "");
                        if (!["startDate"].includes(column)) {
                            return [2 /*return*/];
                        }
                        if (this.displayFieldToActualFieldMap[column]) {
                            this.requestBody.sort.sortBy = this.displayFieldToActualFieldMap[column];
                        }
                        else {
                            this.requestBody.sort.sortBy = column;
                        }
                        this.requestBody.sort.sortOrder = sortOrder;
                        return [4 /*yield*/, this.fetchTableData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.applyFilters = function (filters) {
        var _this = this;
        this.table.pagination.setPageNoToZero();
        var filtersRequest = {};
        if (filters['subscription-status'] === 'Active') {
            filtersRequest.active = true;
        }
        if (filters['subscription-status'] === 'Inactive') {
            filtersRequest.active = false;
        }
        var _a = filters['subscription-dates'] || [], _b = _a[0], startDate = _b === void 0 ? undefined : _b, _c = _a[1], endDate = _c === void 0 ? undefined : _c;
        filtersRequest.startTime = startDate ? getEpochFromDate(startDate) : undefined;
        filtersRequest.endTime = endDate ? getEpochFromDate(endDate) : undefined;
        var allServiceIds = [];
        for (var _i = 0, _d = (filters['subscription-services'] || []); _i < _d.length; _i++) {
            var service = _d[_i];
            allServiceIds = allServiceIds.concat(this.services[service]);
        }
        if (allServiceIds.length > 0) {
            filtersRequest['serviceDetails'] = allServiceIds;
        }
        if ((filters['subscription-city'] || []).length > 0) {
            filtersRequest['cities'] = filters['subscription-city'].map(function (x) { return _this.cityMap[x]; });
        }
        if (filters['subscription-HH'] == "HH") {
            filtersRequest['isAutoAssignedToHH'] = true;
        }
        else {
            filtersRequest['isAutoAssignedToHH'] = false;
        }
        this.requestBody.filters = filtersRequest;
        this.fetchTableData();
    };
    ListSubscriptionsComponent.prototype.fetchTableData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.withLoader(function () { return _this.fetchSubscriptionRules(); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.fetchSubscriptionRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload, permissions, rules;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.validateFilters();
                        payload = {
                            filters: this.requestBody.filters,
                            pagination: {
                                page: this.table.pagination.pageNo + 1,
                                pageSize: this.table.pagination.perPage,
                            },
                            queryParams: {
                                sortBy: this.requestBody.sort.sortBy,
                                sortOrder: this.requestBody.sort.sortOrder,
                            }
                        };
                        return [4 /*yield*/, this.accessRights.getPermissions()];
                    case 1:
                        permissions = _a.sent();
                        return [4 /*yield*/, this.subscriptionService.getRules(payload).toPromise()];
                    case 2:
                        rules = _a.sent();
                        this.table.data = rules
                            .map(function (rule) {
                            var cities = rule.cities.map(function (x) { return _this.cityIdNameMap[x]; });
                            var serviceNames = rule.serviceNames || [];
                            var endDate = rule.endTime ? getDateFromEpoch(rule.endTime) : "--";
                            var row = {
                                id: rule.id,
                                active: rule.active ? 'Active' : 'Inactive',
                                cities: cities.join(', '),
                                subscriptionName: rule.ruleName,
                                serviceNames: serviceNames.join(', '),
                                startDate: getDateFromEpoch(rule.startTime),
                                endDate: endDate,
                                type: rule.validity[0].type,
                                priority: rule.priority || 0,
                                action: _this.createAction(permissions, rule)
                            };
                            return row;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListSubscriptionsComponent.prototype.validateFilters = function () {
        this.validate("cities", "Select city and serviceDetails to fetch subscription rules");
        this.validate("serviceDetails", "Select services to fetch subscription rules");
    };
    ListSubscriptionsComponent.prototype.validate = function (key, message) {
        if (!this.requestBody.filters[key]) {
            this.showFilters = true;
            this.toastService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: message
            }));
            throw new Error(message);
        }
    };
    ListSubscriptionsComponent.prototype.createAction = function (accessData, rule) {
        var _this = this;
        var actionArr = [];
        actionArr.push(new TableAction({
            onClick: function (data) {
                _this.openPage("/subscription/view/" + data.id);
            },
            text: 'View'
        }));
        if (accessData.includes('captain_subscription_deactivate') && rule.active) {
            actionArr.push(new TableAction({
                onClick: function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deactivateSubs(data)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); },
                text: 'Deactivate'
            }));
        }
        return new TableData({
            actions: new TableActions(actionArr),
            type: TableDataTypes.ACTION
        });
    };
    ListSubscriptionsComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(function (confirmed) { return confirmed && cb(); });
    };
    ListSubscriptionsComponent.prototype.deactivateSubs = function (ruleData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                options = {
                    title: 'Are you sure you want to deactivate the subscription?',
                    message: "You are about to deactivate subscription " + ruleData.subscriptionName,
                    cancelText: 'Cancel',
                    confirmText: 'Yes, Proceed'
                };
                this.dialogService.open(options);
                this.dialogService.confirmed().subscribe(function (confirmed) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var payload, error_1;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!confirmed) return [3 /*break*/, 4];
                                payload = {
                                    active: false
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.subscriptionService.updateRule(ruleData.id, payload).toPromise()];
                            case 2:
                                _a.sent();
                                this.showAlertBox({
                                    title: 'Success',
                                    message: "Subscription " + ruleData.subscriptionName + " deactivated successfully."
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _a.sent();
                                this.showAlertBox({
                                    title: 'Error',
                                    message: error_1.message
                                });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    ListSubscriptionsComponent.prototype.openPage = function (url) {
        window.open(this.router.serializeUrl(this.router.createUrlTree([url])));
    };
    return ListSubscriptionsComponent;
}());
export { ListSubscriptionsComponent };
