<div class="view-container main-container shadow-all">
  <app-header [header]="header" [state]="state"></app-header>
  <div>
    <form>
        <div class="row padding-20 flex-row margin-h-8" *ngIf="incentiveData">
            <div class="flex-grow-basis">
              <p class="preview-text preview-text-color">Name</p>
              <p class="preview-text preview-terms-color">{{incentiveData.incentiveName}}</p>
            </div>
            <div *ngIf="incentiveData.incentiveDisplayName" class="flex-grow-basis">
              <p class="preview-text preview-text-color">App Display Name</p>
              <p class="preview-text preview-terms-color">{{incentiveData.incentiveDisplayName}}</p>
            </div>
            <div class="flex-grow-basis">
                <p class="preview-text preview-text-color">Type of incentive</p>
                <p class="preview-text preview-terms-color">{{incentiveData.incentiveType}}</p>
            </div>
            <div class="flex-grow-basis">
                <p class="preview-text preview-text-color">City</p>
                <p class="preview-text preview-terms-color">{{incentiveData.cities}}</p>
            </div>
            <div class="flex-grow-basis">
                <p class="preview-text preview-text-color">Service</p>
                <p class="preview-text preview-terms-color">{{incentiveData.serviceNames}}</p>
            </div>

            <div class="flex-grow-basis" *ngIf="isHHIncentive">
              <p class="preview-text preview-text-color">HH Incentive</p>
              <p class="preview-text preview-terms-color">true</p>
            </div>

            <div class="flex-grow-basis" *ngIf="!isHHIncentive">
              <p class="preview-text preview-text-color">User selector</p>
              <p class="preview-text preview-terms-color bold">
                {{incentiveData.ruleName}} <br>
                <span *ngIf="userSelectorCount.count">({{userSelectorCount.count}} captains)</span>
              </p>
            </div>
            <div class="flex-grow-basis" *ngIf="!isHHIncentive">
              <p class="preview-text preview-text-color">
                User selector status
                <br/>
                <span *ngIf="selectorStatusUpdateTimer" class="subtitle">(Status will update automatically in 30 seconds)</span>
              </p>
              <p class="preview-text preview-terms-color {{userSelectorStatus.type.toLowerCase()}}">
                <span class="bold">{{userSelectorStatus.status}}</span>
              </p>
            </div>
            <div *ngIf='userSelectorStatus.type != "Ok" && !isHHIncentive' class="flex-grow-basis">
              <p class='preview-text preview-terms-color {{userSelectorStatus.type.toLowerCase()}}'>
                <span class="bold">{{userSelectorStatus.type}}:</span> {{userSelectorStatus.message}}
              </p>
            </div>
        </div>
        <div class="divider"></div>
        <div class="preview-height">
            <div class="row padding-20" *ngIf="incentiveData">
              <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                        <p class="preview-text preview-text-color">Start Date</p>
                        <p class="preview-text preview-terms-color">{{incentiveData.startDate | date: 'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="preview-text preview-text-color">End Date</p>
                        <p class="preview-text preview-terms-color">{{incentiveData.endDate | date: 'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="preview-text preview-text-color">Priority</p>
                        <p class="preview-text preview-terms-color">{{incentiveData.priority}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="incentiveData && incentiveData.goalsInfo && incentiveData.goalsInfo.cancellationLimitScope == 'global'">
                      <p class="preview-text preview-text-color">Cancellation Limit</p>
                      <p class="preview-text preview-terms-color">{{incentiveData.goalsInfo.cancellationLimit}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="incentiveData && incentiveData.goalsInfo && incentiveData.goalsInfo.maxOrderDistanceChecked">
                      <p class="preview-text preview-text-color">Max Order Distance</p>
                      <p class="preview-text preview-terms-color">{{incentiveData.goalsInfo.maxOrderDistanceGlobal}}</p>
                    </div>
                  </div>
                  <!--Redeem Incentive-->
                  <div class="row margin-top-17 padding-left-24" *ngIf="incentiveData.goalsInfo && incentiveData.goalsInfo.redeem  && incentiveData.incentiveType === 'Redeem'">
                      <table>
                        <tr *ngFor="let item of incentiveData.goalsInfo.redeem">
                            <td *ngIf="item.bonusAmount" class="multiline-row">Redeem &#x20b9; {{item.redeemAmount}} and get &#x20b9; {{item.bonusAmount}} Bonus</td>
                            <td *ngIf="item.bonusPercentage" class="multiline-row">Redeem &#x20b9; {{item.redeemAmount}} and get {{item.bonusPercentage}} Percentage</td>
                        </tr>
                      </table>
                  </div>


                  <!--Daily and weekly incentive-->
                <div class="row margin-top-17 padding-left-24" *ngIf="(incentiveData.goalsInfo && incentiveData.goalsInfo.dailyIncentiveData  && (incentiveData.incentiveType === 'Daily' || incentiveData.incentiveType === 'Adhoc')) ||
                (incentiveData.goalsInfo && incentiveData.goalsInfo.weeklyIncentiveData && (incentiveData.incentiveType === 'Weekly Fixed' || incentiveData.incentiveType === 'Weekly Selectable'))">
                  <table>
                    <tr *ngFor="let item of goal; let i= index">
                      <td class="multiline-row" *ngIf="incentiveData.incentiveType === 'Weekly Fixed' || incentiveData.incentiveType === 'Weekly Selectable'">{{item.days}}</td>
                      <td class="multiline-row">{{item.timeSlot}}</td>
                      <td class="multiline-row" *ngIf="incentiveData && incentiveData.goalsInfo && incentiveData.goalsInfo.cancellationLimitScope == 'set'">Cancellation Limit : {{incentiveData.goalsInfo.setsCancellationLimit[i]}}</td>
                      <td class="multiline-row">{{item.rules}}</td>
                      <td *ngIf="(item.qualities)" class="multiline-row" >{{item.qualities}}</td>
                    </tr>
                    </table>
                  </div>

                  <div class="row" *ngIf="(incentiveData  && incentiveData.incentiveType !== 'Redeem')">
                        <div class="col-md-12">
                            <p class="preview-text preview-text-color">SMS Template</p>
                            <div class="border border-radius-4 margin-left-9">
                                <p class="preview-text preview-terms-color">{{incentiveData.smsTemplate}}</p>
                            </div>
                        </div>
                  </div>
              </div>

              <div class="col-md-3 padding-left-24">
                  <label class="preview-text preview-text-color margin-left-2">
                    T&C Preview
                  </label><br>
                  <div class="termsPreview border">
                      <div *ngFor="let preview of incentiveData.tnc; let i = index">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="preview-text preview-terms-color">{{ i + 1 }} . {{preview}}</p>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-3 padding-left-24" *ngIf="incentiveData.createdBy">
                <p class="preview-text preview-text-color">Created By</p>
                <p class="preview-text preview-terms-color">{{incentiveData.createdBy}}</p>
              </div>
            </div>
        </div>

        <div class="row padding-15">
            <div class="divider"></div>
        </div>
        <div class="row margin-bottom-23">
            <div class="pull-right">
              <span class="rapido-form-field padding-right-24">
                <mat-checkbox [disabled]= true [checked]="active" >Active</mat-checkbox>
              </span>
                <span class="rapido-form-field">
                    <button mat-raised-button class="next-button" color="primary" (click)="goBack()" type="button">
                      Back
                    </button>
                  </span>

            </div>
        </div>
    </form>
  </div>
</div>
