import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import * as Papa from "papaparse";
import { FileUpload } from "primeng/fileupload";
import { ToasterService } from "src/app/toaster.service";
import { environment } from "src/environments/environment";
import { IAllocatorService } from "../i-allocator.service";
import { LoaderService } from "src/app/loader.service";
import { AlertDialogService } from "src/app/shared/alert-dialog/alert-dialog.service";
import * as moment from "moment";
import { GlobalConstants } from "src/app/global-constants";
import Utils from "src/app/utils/utils";
var IAllocatorConstructUploadComponent = /** @class */ (function () {
    function IAllocatorConstructUploadComponent(toasterService, iAllocatorService, loaderService, alertDialogService) {
        this.toasterService = toasterService;
        this.iAllocatorService = iAllocatorService;
        this.loaderService = loaderService;
        this.alertDialogService = alertDialogService;
        this.config = _.sortBy(environment.iAllocatorConfig, 'cityName');
        this.minDate = new Date();
        this.iAllocatorConstructFormGroup = new FormGroup({
            selectedCity: new FormControl(null, Validators.required),
            selectedService: new FormControl(null, Validators.required),
            selectedIncentive: new FormControl(null, Validators.required),
            fromDate: new FormControl(null, Validators.required),
            toDate: new FormControl(null, Validators.required),
        });
    }
    IAllocatorConstructUploadComponent.prototype.ngOnInit = function () {
    };
    IAllocatorConstructUploadComponent.prototype.getValues = function (name) {
        return this.iAllocatorConstructFormGroup.get(name).value;
    };
    IAllocatorConstructUploadComponent.prototype.getServices = function () {
        var config = this.getValues("selectedCity") || { services: [] };
        return config.services;
    };
    IAllocatorConstructUploadComponent.prototype.getIncentiveTypes = function () {
        var config = this.getValues("selectedCity") || { incentiveTypes: [] };
        return config.incentiveTypes;
    };
    IAllocatorConstructUploadComponent.prototype.citySelected = function () {
        this.iAllocatorConstructFormGroup.patchValue({
            selectedService: null,
            selectedIncentive: null,
        });
    };
    IAllocatorConstructUploadComponent.prototype.canConstructBeUploaded = function () {
        return this.getValues("selectedCity")
            && this.getValues("selectedService")
            && this.getValues("selectedIncentive")
            && this.getValues('fromDate')
            && this.getValues('toDate');
    };
    IAllocatorConstructUploadComponent.prototype.getIncentiveType = function (incentiveType) {
        return GlobalConstants.IAllocatorIncentiveTypes[incentiveType];
    };
    IAllocatorConstructUploadComponent.prototype.changeInDate = function () {
        if (this.getValues('fromDate')) {
            this.minDate = this.getValues('fromDate');
        }
        if (this.getValues('toDate')) {
            this.maxDate = this.getValues('toDate');
        }
    };
    IAllocatorConstructUploadComponent.prototype.resetFile = function () {
        this.fileDetails = null;
    };
    ;
    IAllocatorConstructUploadComponent.prototype.handleFile = function (event) {
        var _this = this;
        var file = event.files[0];
        this.resetFile();
        Papa.parse(file, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            transformHeader: function (header) { return header.trim(); },
            transform: function (value) { return value.trim(); },
            complete: function (results) {
                _this.fileDetails = { records: results.data, name: file.name };
            }
        });
    };
    IAllocatorConstructUploadComponent.prototype.submitConstructs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loaderService.openLoading();
                this.iAllocatorService.uploadConstructs({
                    city_name: this.getValues('selectedCity').cityName,
                    city_id: this.getValues('selectedCity').cityId,
                    service_name: this.getValues('selectedService'),
                    incentive_type: this.getIncentiveType(this.getValues('selectedIncentive').toUpperCase()),
                    segment_details: this.fileDetails.records,
                    start_date: this.formatDate(this.getValues('fromDate')),
                    end_date: this.formatDate(this.getValues('toDate'))
                })
                    .then(function (res) { return _this.showAlertBox({
                    title: 'Success',
                    message: 'File successfully uploaded.',
                }); })
                    .catch(function (err) {
                    console.log(err);
                    if (err.error.code == "CSV_VALIDATION_FAILED") {
                        _this.showAlertBox({
                            title: 'Error',
                            details: _this.getCSVErrorDetails(err.error.data)
                        });
                    }
                    else {
                        _this.showAlertBox({
                            title: 'Error',
                            message: JSON.stringify(err.error.data) || err.error.message
                        });
                    }
                })
                    .finally(function () {
                    _this.loaderService.closeLoading();
                });
                return [2 /*return*/];
            });
        });
    };
    IAllocatorConstructUploadComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(function (confirmed) { return confirmed && cb(); });
    };
    IAllocatorConstructUploadComponent.prototype.formatDate = function (date) {
        return moment(date).format('YYYY-MM-DD');
    };
    ;
    IAllocatorConstructUploadComponent.prototype.getCSVErrorDetails = function (data) {
        var value = data.map(function (error) {
            var jsonPath = error.instancePath;
            var errData = jsonPath.split("/");
            var column = errData[3];
            var row = Number(errData[2]);
            if (Number.isNaN(row) || column == undefined) {
                return "Validation failed for " + errData[1] + " on " + error.keyword + " : " + error.message;
            }
            return "Validation failed for line " + (row + 2) + " in " + column + " column on " + error.keyword + " : " + error.message;
        });
        return [{
                value: value,
                message: "There are some validation errors, please fix them and try again"
            }];
    };
    IAllocatorConstructUploadComponent.prototype.downloadSampleFile = function () {
        Utils.downloadCSVFile({
            fields: environment.iAllocatorConstructsCSVHeaders,
            data: null,
            fileName: "iallocator_construct_sample.csv",
        });
    };
    return IAllocatorConstructUploadComponent;
}());
export { IAllocatorConstructUploadComponent };
