<mat-toolbar>
  <img src="../../assets/Rapido.png" width="30" height="30" class="d-inline-block align-top" alt="" /> <span
    class="rapido-form-field">Rapido</span>
</mat-toolbar>
<filter-pane [filterComponents]="filterComponents" [showFilter]="showFilters" (change)="listen($event)"
  (submit)="applyFilters($event)"></filter-pane>
<div class="main-container" *ngIf="tableLoaded">
  <mat-toolbar class="toolbar-border">
    Slab Details
    <div class="pull-right is-flex">
      <input type="text" class="filter-button filter-input-box" (change)="searchByShift()" [(ngModel)]="shiftSearch"
        placeholder="Search By Shift">
      <button class="btn btn-sm btn-outline-primary filter-button" (click)="showFilters = !showFilters">
        Filters
      </button>
    </div>
  </mat-toolbar>
  <data-table [table]="table" (paginationChange)="fetchTableData()" (headerToSort)="sortByHeader($event)"></data-table>
</div>
