import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
var PenaltyRewardCampaignService = /** @class */ (function () {
    function PenaltyRewardCampaignService(http) {
        var _this = this;
        this.http = http;
        this.createCampaignUrl = environment.server + "/api/createCampaign";
        this.editCampaignUrl = environment.server + "/api/editCampaign";
        this.fetchCampaignsUrl = environment.server + '/api/get/campaigns/v1';
        this.fetchActionsUrl = environment.server + "/api/get/actions/v1";
        this.fetchNotificationsUrl = environment.server + "/api/get/notifications/v1";
        this.fetchCaptainTasksUrl = environment.server + "/api/get/captainTasks";
        this.fetchGalaxyCampaignsUrl = environment.server + "/api/get/galaxyCampaigns";
        this.createCampaign = function (campaign) { return _this.http.post(_this.createCampaignUrl, campaign).pipe(catchError(_this.handleError)); };
        this.editCampaign = function (campaign, campaignId) { return _this.http.put(_this.editCampaignUrl + '/' + encodeURIComponent(campaignId), campaign).pipe(catchError(_this.handleError)); };
        this.getCampaign = function (payload) { return _this.http.post(_this.fetchCampaignsUrl, payload).pipe(catchError(_this.handleError)); };
        this.fetchActions = function () { return _this.http.get(_this.fetchActionsUrl).pipe(catchError(_this.handleError)); };
        this.fetchCaptainTasks = function () { return _this.http.get(_this.fetchCaptainTasksUrl).pipe(catchError(_this.handleError)); };
        this.fetchGalaxyCampaigns = function () { return _this.http.get(_this.fetchGalaxyCampaignsUrl).pipe(catchError(_this.handleError)); };
        this.getNotifications = function () { return _this.http.get(_this.fetchNotificationsUrl).pipe(catchError(_this.handleError)); };
    }
    PenaltyRewardCampaignService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    PenaltyRewardCampaignService.ngInjectableDef = i0.defineInjectable({ factory: function PenaltyRewardCampaignService_Factory() { return new PenaltyRewardCampaignService(i0.inject(i1.MyHttpClient)); }, token: PenaltyRewardCampaignService, providedIn: "root" });
    return PenaltyRewardCampaignService;
}());
export { PenaltyRewardCampaignService };
