/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./penalty-reward-campaign.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../node_modules/@angular/material/stepper/typings/index.ngfactory";
import * as i5 from "@angular/material/stepper";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/core";
import * as i9 from "@angular/cdk/stepper";
import * as i10 from "./penalty-basic-info/penalty-basic-info.component.ngfactory";
import * as i11 from "./penalty-basic-info/penalty-basic-info.component";
import * as i12 from "@angular/forms";
import * as i13 from "../shared/shared.service";
import * as i14 from "./penalty-basic-info/penalty-basic-info.service";
import * as i15 from "../toaster.service";
import * as i16 from "../loader.service";
import * as i17 from "@angular/router";
import * as i18 from "@angular/material/dialog";
import * as i19 from "./penalty-rule/penalty-rule.component.ngfactory";
import * as i20 from "./penalty-rule/penalty-rule.component";
import * as i21 from "./penalty-reward-campaign.service";
import * as i22 from "../shared/confirm-dialog/confirm-dialog.service";
import * as i23 from "./penalty-preview/penalty-preview.component.ngfactory";
import * as i24 from "./penalty-preview/penalty-preview.component";
import * as i25 from "../shared/toaster/toaster.component.ngfactory";
import * as i26 from "../shared/toaster/toaster.component";
import * as i27 from "./penalty-reward-campaign.component";
import * as i28 from "../logs.service";
import * as i29 from "../auth.service";
var styles_PenaltyRewardCampaignComponent = [i0.styles];
var RenderType_PenaltyRewardCampaignComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PenaltyRewardCampaignComponent, data: {} });
export { RenderType_PenaltyRewardCampaignComponent as RenderType_PenaltyRewardCampaignComponent };
function View_PenaltyRewardCampaignComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "completed mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["done"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PenaltyRewardCampaignComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 30, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "preview-text font-size-20 text-capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Penalty & Reward Campaign"])), (_l()(), i1.ɵeld(3, 0, null, null, 27, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 26, "mat-horizontal-stepper", [["aria-orientation", "horizontal"], ["class", "background-stepper create-campaign mat-stepper-horizontal"], ["linear", ""], ["role", "tablist"]], [[2, "mat-stepper-label-position-end", null], [2, "mat-stepper-label-position-bottom", null]], null, null, i4.View_MatHorizontalStepper_0, i4.RenderType_MatHorizontalStepper)), i1.ɵdid(5, 5423104, [[1, 4], ["stepper", 4]], 2, i5.MatHorizontalStepper, [[2, i6.Directionality], i1.ChangeDetectorRef, i1.ElementRef, i7.DOCUMENT], { linear: [0, "linear"] }, null), i1.ɵqud(603979776, 2, { _steps: 1 }), i1.ɵqud(603979776, 3, { _icons: 1 }), i1.ɵprd(2048, null, i5.MatStepper, null, [i5.MatHorizontalStepper]), (_l()(), i1.ɵand(0, null, null, 1, null, View_PenaltyRewardCampaignComponent_2)), i1.ɵdid(10, 16384, [[3, 4]], 0, i5.MatStepperIcon, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "mat-step", [], null, null, null, i4.View_MatStep_0, i4.RenderType_MatStep)), i1.ɵprd(6144, null, i8.ErrorStateMatcher, null, [i5.MatStep]), i1.ɵdid(13, 573440, [[2, 4]], 1, i5.MatStep, [i5.MatStepper, [1, i8.ErrorStateMatcher], [2, i9.STEPPER_GLOBAL_OPTIONS]], { label: [0, "label"] }, null), i1.ɵqud(335544320, 4, { stepLabel: 0 }), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "app-penalty-basic-info", [], null, [[null, "basicInfo"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("basicInfo" === en)) {
        _co.storeCampaignInfo($event, "basicInfo");
        var pd_0 = (_co.goForward(i1.ɵnov(_v, 5)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_PenaltyBasicInfoComponent_0, i10.RenderType_PenaltyBasicInfoComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(17, 114688, null, 0, i11.PenaltyBasicInfoComponent, [i12.FormBuilder, i13.SharedService, i14.PenaltyBasicInfoService, i15.ToasterService, i16.LoaderService, i7.DatePipe, i17.Router, i18.MatDialog], { defaultValue: [0, "defaultValue"], state: [1, "state"], NOT_EDITABLE: [2, "NOT_EDITABLE"], campaignData: [3, "campaignData"] }, { basicInfo: "basicInfo" }), (_l()(), i1.ɵeld(18, 0, null, null, 5, "mat-step", [], null, null, null, i4.View_MatStep_0, i4.RenderType_MatStep)), i1.ɵprd(6144, null, i8.ErrorStateMatcher, null, [i5.MatStep]), i1.ɵdid(20, 573440, [[2, 4]], 1, i5.MatStep, [i5.MatStepper, [1, i8.ErrorStateMatcher], [2, i9.STEPPER_GLOBAL_OPTIONS]], { label: [0, "label"] }, null), i1.ɵqud(335544320, 5, { stepLabel: 0 }), (_l()(), i1.ɵeld(22, 0, null, 0, 1, "app-penalty-rule", [], null, [[null, "back"], [null, "rulesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.goBack(i1.ɵnov(_v, 5)) !== false);
        ad = (pd_0 && ad);
    } if (("rulesChange" === en)) {
        _co.storeCampaignInfo($event, "rulesChange");
        var pd_1 = (_co.goForward(i1.ɵnov(_v, 5)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i19.View_PenaltyRuleComponent_0, i19.RenderType_PenaltyRuleComponent)), i1.ɵdid(23, 638976, null, 0, i20.PenaltyRuleComponent, [i12.FormBuilder, i13.SharedService, i15.ToasterService, i16.LoaderService, i21.PenaltyRewardCampaignService, i22.ConfirmDialogService], { defaultValue: [0, "defaultValue"], campaignData: [1, "campaignData"], state: [2, "state"], NOT_EDITABLE: [3, "NOT_EDITABLE"] }, { rulesChange: "rulesChange", back: "back" }), (_l()(), i1.ɵeld(24, 0, null, null, 6, "mat-step", [["label", "Preview and Finish"]], null, null, null, i4.View_MatStep_0, i4.RenderType_MatStep)), i1.ɵprd(6144, null, i8.ErrorStateMatcher, null, [i5.MatStep]), i1.ɵdid(26, 573440, [[2, 4]], 1, i5.MatStep, [i5.MatStepper, [1, i8.ErrorStateMatcher], [2, i9.STEPPER_GLOBAL_OPTIONS]], { label: [0, "label"] }, null), i1.ɵqud(335544320, 6, { stepLabel: 0 }), (_l()(), i1.ɵeld(28, 0, null, 0, 2, "app-penalty-preview", [], null, [[null, "back"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.goBack(i1.ɵnov(_v, 5)) !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.storeCampaignInfo($event, "") !== false);
        ad = (pd_1 && ad);
    } return ad; }, i23.View_PenaltyPreviewComponent_0, i23.RenderType_PenaltyPreviewComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(30, 638976, null, 0, i24.PenaltyPreviewComponent, [i13.SharedService, i7.DatePipe], { campaignData: [0, "campaignData"], defaultValue: [1, "defaultValue"], state: [2, "state"], NOT_EDITABLE: [3, "NOT_EDITABLE"] }, { back: "back", submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = ""; _ck(_v, 5, 0, currVal_3); var currVal_4 = "edit"; _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.state, " Campaign"); _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.defaultValue; var currVal_7 = _co.state; var currVal_8 = _co.NOT_EDITABLE; var currVal_9 = _co.campaignData; _ck(_v, 17, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "", _co.state, " Rule"); _ck(_v, 20, 0, currVal_10); var currVal_11 = _co.defaultValue; var currVal_12 = _co.campaignData; var currVal_13 = _co.state; var currVal_14 = _co.NOT_EDITABLE; _ck(_v, 23, 0, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = "Preview and Finish"; _ck(_v, 26, 0, currVal_15); var currVal_16 = _co.campaignData; var currVal_17 = _co.defaultValue; var currVal_18 = _co.state; var currVal_19 = _co.NOT_EDITABLE; _ck(_v, 30, 0, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).labelPosition == "end"); var currVal_2 = (i1.ɵnov(_v, 5).labelPosition == "bottom"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_PenaltyRewardCampaignComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { stepper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-toaster", [["class", "toaster"]], null, null, null, i25.View_ToasterComponent_0, i25.RenderType_ToasterComponent)), i1.ɵdid(2, 114688, null, 0, i26.ToasterComponent, [], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PenaltyRewardCampaignComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toasterService.toaster; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formLoaded; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PenaltyRewardCampaignComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-penalty-reward-campaign", [], null, null, null, View_PenaltyRewardCampaignComponent_0, RenderType_PenaltyRewardCampaignComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 114688, null, 0, i27.PenaltyRewardCampaignComponent, [i21.PenaltyRewardCampaignService, i15.ToasterService, i17.Router, i17.ActivatedRoute, i13.SharedService, i16.LoaderService, i7.DatePipe, i28.LogService, i29.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PenaltyRewardCampaignComponentNgFactory = i1.ɵccf("app-penalty-reward-campaign", i27.PenaltyRewardCampaignComponent, View_PenaltyRewardCampaignComponent_Host_0, {}, {}, []);
export { PenaltyRewardCampaignComponentNgFactory as PenaltyRewardCampaignComponentNgFactory };
