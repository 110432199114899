import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ViewSlabsService } from './view-slabs.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { LoaderService } from '../loader.service';
var ViewSlabsComponent = /** @class */ (function () {
    function ViewSlabsComponent(viewSlabService, sharedService, loaderService) {
        this.viewSlabService = viewSlabService;
        this.sharedService = sharedService;
        this.loaderService = loaderService;
        this.limit = 15;
        this.gotData = false;
        this.offset = 0;
        this.dataLoaded = false;
        this.tableLoaded = false;
        this.cities = [];
        this.services = [];
        this.shifts = [];
        this.filterComponents = [];
        this.cityMap = {};
        this.serviceMap = {};
        this.filterParams = {};
        this.showFilters = false;
        this.defineTable();
    }
    ViewSlabsComponent.prototype.defineTable = function () {
        this.table = new Table({
            headers: {
                active: 'Status',
                cities: 'City',
                shift: 'Shift Name',
                serviceTypes: 'Service',
                startDate: 'Start Date',
                endDate: 'End Date',
                days: 'Weekdays',
                action: 'Action',
            },
            pagination: new Pagination(0, 15, 10, [15, 20]),
            onSortingChange: function (header) {
            }
        });
        this.tableLoaded = true;
    };
    ViewSlabsComponent.prototype.sortByHeader = function ($evt) {
        if ($evt !== 'action' && $evt !== '-action') {
            this.headerToSort = $evt;
            this.fetchTableData();
        }
    };
    ViewSlabsComponent.prototype.fetchTableData = function () {
        var _this = this;
        this.loaderService.openLoading();
        var payload = {
            queryParams: {
                limit: this.table.pagination.perPage,
                offset: this.table.pagination.pageNo * this.table.pagination.perPage,
                sort: this.headerToSort || '-startDate'
            }
        };
        if (this.filterParams) {
            payload['queryParams'] = tslib_1.__assign({}, this.filterParams, payload['queryParams']);
            console.log("Filters : ", payload);
        }
        this.viewSlabService.fetchSlabData(payload).subscribe(function (res) {
            res.data = res.data || [];
            for (var slab in res.data) {
                if (res.data[slab]) {
                    var days = [];
                    res.data[slab]['serviceTypes'] = res.data[slab].serviceNames || [];
                    for (var day in res.data[slab]['days']) {
                        if (res.data[slab]['days'][day]) {
                            switch (res.data[slab]['days'][day]) {
                                case 'Monday':
                                    days.push('M');
                                    break;
                                case 'Tuesday':
                                    days.push('T');
                                    break;
                                case 'Wednesday':
                                    days.push('W');
                                    break;
                                case 'Thursday':
                                    days.push('Th');
                                    break;
                                case 'Friday':
                                    days.push('F');
                                    break;
                                case 'Saturday':
                                    days.push('S');
                                    break;
                                case 'Sunday':
                                    days.push('Su');
                                    break;
                                default: days = [];
                            }
                        }
                    }
                    res.data[slab]['days'] = days;
                }
            }
            _this.table.data = res.data.map(function (slab) {
                slab.cities = slab.cities || [];
                return Object.assign({}, slab, {
                    cities: slab.cities.join(', '),
                    action: _this.createAction(),
                });
            });
            _this.table.pagination.count = res.count;
            _this.loaderService.closeLoading();
            _this.dataLoaded = true;
        });
    };
    ViewSlabsComponent.prototype.fetchAllCities = function () {
        var _this = this;
        this.sharedService.fetchCities().subscribe(function (res) {
            res.data = res.data || [];
            _this.cities = res.data.cities.map(function (city) { return city.displayName; });
            _this.filterComponents = _this.filterComponents.map(function (node, index) {
                if (index === 0) {
                    node.values = _this.cities;
                }
                return node;
            });
            for (var city in res.data.cities) {
                if (res.data.cities[city]) {
                    _this.cityMap[res.data.cities[city].displayName] = res.data.cities[city]._id;
                }
            }
        });
    };
    ViewSlabsComponent.prototype.fetchServices = function (city) {
        var _this = this;
        this.services = [];
        if (city.length === 0) {
            this.filterComponents = this.filterComponents.map(function (node, index) {
                if (index === 1) {
                    node.values = _this.services;
                }
                return node;
            });
        }
        for (var _i = 0, city_1 = city; _i < city_1.length; _i++) {
            var cty = city_1[_i];
            if (cty) {
                var cityId = this.cityMap[cty];
                this.sharedService.fetchServices(cityId).subscribe(function (res) {
                    for (var service in res.data) {
                        if (_this.services.indexOf(res.data[service].service.name) === -1) {
                            _this.services.push(res.data[service].service.name);
                        }
                    }
                    for (var service in res.data) {
                        if (res.data[service].service) {
                            _this.serviceMap[res.data[service].service.name] = res.data[service]._id;
                        }
                    }
                    _this.filterComponents = _this.filterComponents.map(function (node, index) {
                        if (index === 1) {
                            node.values = _this.services;
                        }
                        return node;
                    });
                });
            }
        }
    };
    ViewSlabsComponent.prototype.fetchShifts = function (city) {
        var _this = this;
        this.shifts = [];
        if (city.length === 0) {
            this.filterComponents = this.filterComponents.map(function (node, index) {
                if (index === 2) {
                    node.values = [];
                }
                return node;
            });
        }
        for (var _i = 0, city_2 = city; _i < city_2.length; _i++) {
            var cty = city_2[_i];
            if (cty) {
                this.sharedService.fetchShifts(cty).subscribe(function (res) {
                    var _a;
                    (_a = _this.shifts).push.apply(_a, res.data);
                    _this.filterComponents = _this.filterComponents.map(function (node, index) {
                        if (index === 2) {
                            node.values = _this.shifts;
                        }
                        return node;
                    });
                });
            }
        }
    };
    ViewSlabsComponent.prototype.createAction = function () {
        var _this = this;
        return new TableData({
            actions: new TableActions([
                new TableAction({
                    onClick: function (data) {
                        _this.onViewSlab(data._id["$oid"]);
                    },
                    text: 'View'
                }),
                new TableAction({
                    onClick: function (data) {
                        _this.onDuplicateSlab(data._id["$oid"]);
                    },
                    text: 'Duplicate'
                })
            ]),
            type: TableDataTypes.ACTION
        });
    };
    ViewSlabsComponent.prototype.onViewSlab = function (slabId) {
        var url = "/payroll/viewSlab/" + slabId;
        this.sharedService.redirectToDashbaord(url);
    };
    ViewSlabsComponent.prototype.onDuplicateSlab = function (slabId) {
        var url = "/payroll/duplicateSlab/" + slabId;
        this.sharedService.redirectToDashbaord(url);
    };
    ViewSlabsComponent.prototype.filterToggle = function () {
        this.showFilters = !this.showFilters;
    };
    ViewSlabsComponent.prototype.ngOnInit = function () {
        this.fetchTableData();
        this.fetchAllCities();
        // call city api, assign names to cities []
        this.filterComponents = [
            {
                type: 'searchable-dropdown',
                title: 'City',
                values: this.cities,
                default: '',
                key: 'slab-city'
            },
            {
                type: 'searchable-dropdown',
                title: 'Services',
                values: this.services,
                default: '',
                key: 'slab-services'
            },
            {
                type: 'searchable-dropdown',
                title: 'Shifts',
                values: this.shifts,
                default: '',
                key: 'slab-shifts'
            },
            {
                type: 'calendar',
                title: '',
                values: [],
                default: '',
                key: 'slab-dates'
            },
            // {
            //   type : 'single-searchable-dropdown',
            //   title : 'Type',
            //   values : ['cm', 'order', 'distance'],
            //   default : '',
            //   key: 'slab-type'
            // },
            {
                type: 'searchable-dropdown',
                title: 'Weekdays',
                values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                default: '',
                key: 'slab-weekdays'
            },
            {
                type: 'radio-button',
                title: 'Status',
                values: ['Both', 'Active', 'Inactive'],
                default: '',
                key: 'slab-status'
            }
        ];
    };
    ViewSlabsComponent.prototype.listen = function ($evt) {
        if ($evt['slab-city']) {
            if ($evt['slab-city'] === []) {
                this.services = [];
            }
            else {
                this.fetchServices($evt['slab-city']);
                this.fetchShifts($evt['slab-city']);
            }
        }
    };
    ViewSlabsComponent.prototype.searchByShift = function () {
        this.filterParams = {
            shift__regex: this.shiftSearch
        };
        this.fetchTableData();
    };
    ViewSlabsComponent.prototype.applyFilters = function ($event) {
        // keys: 'slab-status' 'slab-weekdays' 'slab-type' 'slab-dates' 'slab-shifts' 'slab-services' 'slab-city'
        console.log('Got these filters : ', $event);
        this.table.pagination.setPageNoToZero();
        this.filterParams = {};
        var filters = $event;
        if (filters['slab-status']) {
            if (filters['slab-status'] === 'Both') {
                delete this.filterParams['active__eq'];
            }
            else {
                this.filterParams['active__eq'] = filters['slab-status'] === 'Inactive' ? false : true;
            }
        }
        if (filters['slab-weekdays'] && filters['slab-weekdays'].length > 0) {
            this.filterParams['days__in'] = filters['slab-weekdays'];
        }
        // if (filters['slab-type']) {
        //   this.filterParams['type'] = filters['slab-type'];
        // }
        if (filters['slab-dates'] && filters['slab-dates'].length > 0) {
            if (filters['slab-dates'][0]) {
                this.filterParams['startDate__gte'] = filters['slab-dates'][0];
            }
            if (filters['slab-dates'][1]) {
                this.filterParams['startDate__lte'] = filters['slab-dates'][1];
            }
        }
        if (filters['slab-shifts'] && filters['slab-shifts'].length > 0) {
            this.filterParams['shift__in'] = filters['slab-shifts'];
        }
        if (filters['slab-services'] && filters['slab-services'].length > 0) {
            var serviceIds = [];
            for (var _i = 0, _a = filters['slab-services']; _i < _a.length; _i++) {
                var service = _a[_i];
                serviceIds.push(this.serviceMap[service]);
            }
            this.filterParams['serviceTypes__in'] = serviceIds;
        }
        if (filters['slab-city'] && filters['slab-city'].length > 0) {
            this.filterParams['cities__in'] = filters['slab-city'];
        }
        this.fetchTableData();
    };
    return ViewSlabsComponent;
}());
export { ViewSlabsComponent };
