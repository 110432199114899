<div class="padding-bottom-25">
  <div>
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content *ngFor="let details of data.details">
    <p class="dialog-message">{{ details.message }}</p>
    <ul *ngIf="details.value">
      <li *ngFor="let value of details.value">{{value}}</li>
    </ul>
  </div>

  <mat-dialog-actions>
    <button class="btn btn-cancel" (click)="close(false)">Close</button>
    <button *ngIf="data.confirmText" class="btn btn-confirm" (click)="confirm()">{{ data.confirmText }}</button>
  </mat-dialog-actions>
</div>
