import * as tslib_1 from "tslib";
import { HelperService } from './helper.service';
import { of } from 'rxjs';
import { SigninService } from './signin/signin.service';
import * as i0 from "@angular/core";
import * as i1 from "./signin/signin.service";
var AuthService = /** @class */ (function () {
    function AuthService(signInService) {
        this.signInService = signInService;
        this.user = null;
        this._token = null;
        this.redirectUrl = null;
        this.permissions = null;
    }
    AuthService.prototype.setToken = function (token) {
        if (!token) {
            this._token = null;
            this.user = null;
            HelperService.setCookie({
                token: '',
                user: '',
            });
            return;
        }
        this._token = token;
    };
    Object.defineProperty(AuthService.prototype, "isUserLoggedIn", {
        get: function () {
            return this.token ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.fetchUserInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userDetails, error_1, status_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.signInService.getUserInfo().toPromise()];
                    case 1:
                        userDetails = _a.sent();
                        this.setUserDetails(userDetails);
                        return [2 /*return*/, userDetails];
                    case 2:
                        error_1 = _a.sent();
                        status_1 = error_1.status;
                        if (status_1 == 401) {
                            this.setToken(null);
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            return this._token;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.isAuthenticated = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = localStorage.getItem('token');
                        this.setToken(token);
                        return [4 /*yield*/, this.fetchUserInfo()];
                    case 1:
                        _a.sent();
                        if (!token) {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthService.prototype.clearUserCred = function () {
        this.setToken(null);
    };
    AuthService.prototype.getUserDetails = function () {
        return of(this.user);
    };
    AuthService.prototype.setUserDetails = function (user) {
        this.permissions = user.permissions;
        this.user = user;
    };
    AuthService.prototype.getToken = function () {
        return HelperService.accessCookie('token');
    };
    AuthService.prototype.clearToken = function () {
        this.setToken(null);
    };
    AuthService.prototype.setTokenToCookie = function (token) {
        HelperService.setCookie({
            token: token,
        });
    };
    AuthService.prototype.getPermissions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.permissions) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchUserInfo()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, this.permissions];
                }
            });
        });
    };
    AuthService.prototype.isAuthorizedFor = function (permission) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var permissionList, permissions;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!permission) {
                            return [2 /*return*/, true];
                        }
                        permissionList = permission.split(',');
                        return [4 /*yield*/, this.getPermissions()];
                    case 1:
                        permissions = _a.sent();
                        if (!permissions) {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, permissions.every(function (p) { return permissionList.includes(p); })];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.SigninService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
