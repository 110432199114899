import { isObject, isArray } from 'util';

export interface ITableAction {
  text: string;
  onClick: (data: any) => void;
}

export class TableAction {
  text: string;
  onClick: () => {};
  constructor(action: ITableAction) {
    Object.assign(this, action);
  }
}

export interface IButton {
  label: string;
  onClick: () => void;
  className?: string;
}

export class Button {
  label: string;
  onClick: () => void;
  className?: string;
  constructor(data: IButton) {
    Object.assign(this, data);
  }
}
export enum TableDataTypes {
  DATA,
  ACTION,
  BUTTON,
  STATUS
}

export class TableActions {
  constructor(public actions: TableAction[]) {
  }
}


export class Pagination {
  constructor(public pageNo: number = 1, public perPage: number = 10, public count: number = 10, public pageSizeOptions = [
    5, 10, 15, 20
  ]) {

  }
  setPageNoToZero(){
    this.pageNo=0;
  }
}

export interface ITableData {
  type: TableDataTypes;
  data?: string;
  actions?: TableActions;
  button?: Button;
}

export class TableData {
  type: TableDataTypes;
  data?: string;
  actions?: TableActions;
  button?: Button;
  constructor(data: ITableData) {
    Object.assign(this, data);
  }
}

export interface ITable {
  pagination: Pagination;
  onSortingChange?: (header) => void;
  data?: any[];
  headers: any;
}

export class Table {
  pagination: Pagination = new Pagination();
  onSortingChange?: (header) => void = () => { };
  private _data = [];
  headers: any = {};
  realData: any[] = [];
  set data(rows: any[]) {
    this.realData = rows;
    this._data = rows
      .map(row => {
        return Object.entries(row)
          .reduce((row, [key, value]) => {
            let dataValue = value;
            if (isArray(value)) {
              dataValue = value.join(', ');
            }
            if (!isObject(dataValue)) {
              dataValue = new TableData({
                data: value as string,
                type: TableDataTypes.DATA
              });
            }

            return {
              ...row,
              [key]: dataValue
            };
          }, {});
      });
  }

  get data() {
    return this._data;
  }

  constructor(data: ITable) {
    Object.assign(this, data);
  }
}
