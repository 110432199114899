import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../http-client';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private rulePath = "/api/subscription/rule";
  private ruleByIdPath = "/api/subscription/rule";
  private createrulePath = "/api/accessfee/rule";

  public createSubscriptionRules(req: any) {
    return this.http
      .post(environment.server + this.rulePath, req)
      .pipe(catchError(this.handleError));
  }

  public createBillingPlan(req: any) {
    return this.http
      .post(environment.server + this.createrulePath, req)
      .pipe(catchError(this.handleError));
  }

  public getSubscriptionRule(id: string){
    return this.http
    .get(environment.server+this.ruleByIdPath+"/"+id)
    .pipe(catchError(this.handleError));
  }

  public getHighestPriorityHHRule(serviceDetails) {
    return this.http
      .post(`${environment.server}/api/subscription/fetchRules`, {
        filters: {
          serviceDetails: serviceDetails,
          isAutoAssignedToHH: true,
          active: true
        },
        pagination: {
          page: 1,
          pageSize: 1
        }
      })
      .pipe(catchError(this.handleError));
  }

  public getSamePriorityRules(serviceDetails: [string], startTime, endTime, priority) {
    return this.http
      .post(`${environment.server}/api/subscription/fetchRules`, {
        filters: {
          serviceDetails: serviceDetails,
          startTime: startTime,
          endTime: endTime,
          active: true,
          isAutoAssignedToHH: false,
          priority: priority
        },
        pagination: {
          page: 1,
          pageSize: environment.maxRulesCreationAtOnce
        }
      })
      .pipe(catchError(this.handleError))
  }

  public getRules(payload: any) {
    return this.http
      .post(`${environment.server}/api/subscription/fetchRules`, payload)
      .pipe(catchError(this.handleError))
  }

  public updateRule(ruleId: string,payload: any) {
    return this.http
      .patch(`${environment.server}/api/subscription/rule/${ruleId}`, payload)
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was:`, error.error);
    }
    return throwError(error);
  }

  constructor(private http: MyHttpClient) { }
}
