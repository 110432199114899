import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
export var BASE_URL = {
    OLD_DASHBOARD: environment.rapido_dashboard,
};
var IncentiveService = /** @class */ (function () {
    function IncentiveService(http) {
        this.http = http;
        this.addIncentive = environment.server + '/api/createIncentive';
        this.incentive = environment.server + '/api/incentive';
    }
    IncentiveService.prototype.createIncentive = function (incentive) {
        incentive.active = incentive.active || false;
        return this.http.post(this.addIncentive, incentive).pipe(catchError(this.handleError));
    };
    IncentiveService.prototype.editIncentive = function (incentiveId, incentive) {
        incentive.active = incentive.active || false;
        return this.http.put(this.incentive + "/" + incentiveId, incentive).pipe(catchError(this.handleError));
    };
    IncentiveService.prototype.getIncentive = function (incentiveId) {
        return this.http.get(this.incentive + "/" + incentiveId).pipe(catchError(this.handleError));
    };
    IncentiveService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    IncentiveService.prototype.redirectToDashbaord = function (url) {
        var redirectUrl = "" + BASE_URL.OLD_DASHBOARD + url;
        var token = this.readCookie('token');
        window.open(redirectUrl + "?token=" + token);
        // window.location.href = `${redirectUrl}?token=${token}`;
    };
    IncentiveService.prototype.readCookie = function (name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    };
    IncentiveService.ngInjectableDef = i0.defineInjectable({ factory: function IncentiveService_Factory() { return new IncentiveService(i0.inject(i1.MyHttpClient)); }, token: IncentiveService, providedIn: "root" });
    return IncentiveService;
}());
export { IncentiveService };
