import { OnInit, EventEmitter } from '@angular/core';
var SingleSelectDropdownComponent = /** @class */ (function () {
    function SingleSelectDropdownComponent() {
        this.selectedDropDown = new EventEmitter();
    }
    SingleSelectDropdownComponent.prototype.ngOnInit = function () {
        // console.log('single : ', this.singleDropdownData);
        // this.singleDropdownData = {
        //     type : 'single-searchable-dropdown',
        //     title : 'City',
        //     values : ['Bangalore', 'Hyderabad', 'Mumbai', 'Ahmedabad'],
        //     default : '',
        //     key : 'city-dropdown'
        //   };
    };
    SingleSelectDropdownComponent.prototype.chosenOptions = function () {
        var _a;
        var emitSelections = (_a = {},
            _a[this.singleDropdownData.key] = this.selectedValues,
            _a);
        this.selectedDropDown.emit(emitSelections);
    };
    return SingleSelectDropdownComponent;
}());
export { SingleSelectDropdownComponent };
