import * as tslib_1 from "tslib";
import { EventEmitter, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { SharedService } from 'src/app/shared/shared.service';
import Utils from 'src/app/utils/utils';
import { defaultStatusMessage } from "../../utils/utils";
var ADHOC_TYPE = 'Adhoc';
var OTHERS = 'Others';
var PreviewComponent = /** @class */ (function () {
    function PreviewComponent(datePipe, sharedService) {
        this.datePipe = datePipe;
        this.sharedService = sharedService;
        this.back = new EventEmitter();
        this.saveData = new EventEmitter();
        this.stepperIndex = new EventEmitter();
        this.header = 'Preview';
        this.type = "preview";
        this.goal = [];
        this.incentiveTypeMap = [];
        this.termsPreview = [];
        this.userSelectorStatus = tslib_1.__assign({ status: "UNKNOWN" }, defaultStatusMessage);
        this.isCreateOrDuplicateFlow = true;
        this.NOT_EDITABLE = {};
        this.defaultValue = {
            active: true
        };
        this.active = true;
    }
    PreviewComponent.prototype.ngOnChanges = function (changes) {
        this.incentiveData = changes.incentiveData.currentValue;
        this.incentiveTypeMap['Daily'] = "Daily";
        this.incentiveTypeMap['Weekly Fixed'] = "Weekly";
        this.incentiveTypeMap['Weekly Selectable'] = "Weekly";
        this.incentiveTypeMap['Adhoc'] = "Daily";
        if (this.incentiveData.basicInfo) {
            this.header = 'Preview - ' + this.incentiveData.basicInfo.incentiveType + ' incentive - ' +
                this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy')
                + ' to ' + this.datePipe.transform(this.incentiveData.basicInfo.endDate, 'd MMMM yy');
            if (!this.incentiveData.basicInfo.isHHIncentive) {
                this.updateUserSelectorStatus();
            }
        }
        if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.dailyIncentiveData) {
            this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.dailyIncentiveData);
        }
        if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.weeklyIncentiveData) {
            this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.weeklyIncentiveData);
        }
        this.goal = Utils.organizeData(this.type, this.incentiveData, this.dailyData, this.datePipe, this.variables);
        if (this.incentiveData.communicationInfo && this.incentiveData.communicationInfo.termsAndCondition) {
            this.termsPreview = this.incentiveData.communicationInfo.termsAndCondition;
        }
    };
    PreviewComponent.prototype.ngOnInit = function () {
        this.active = this.defaultValue.hasOwnProperty('active') ? this.defaultValue.active : true;
        this.isCreateOrDuplicateFlow = this.state == this.sharedService.STATE.DUPLICATE ||
            this.state == this.sharedService.STATE.CREATE;
    };
    PreviewComponent.prototype.goBack = function () {
        this.back.emit();
    };
    PreviewComponent.prototype.submit = function () {
        if (this.userSelectorStatus.type == 'Error' && this.isCreateOrDuplicateFlow) {
            return;
        }
        var data = {
            submit: true,
            active: this.active,
        };
        this.saveData.emit(data);
    };
    PreviewComponent.prototype.toggleActive = function () {
        this.active = !this.active;
    };
    PreviewComponent.prototype.moveStepper = function (event) {
        this.stepperIndex.emit(event);
    };
    PreviewComponent.prototype.updateUserSelectorStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var details;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.selectorStatusUpdateTimer) {
                            clearTimeout(this.selectorStatusUpdateTimer);
                        }
                        this.selectorStatusUpdateTimer = null;
                        return [4 /*yield*/, Utils.fetchUserSelectorStatus(this.type, this.userSelectorId, this.incentiveData, this.userSelectorStatus, this.sharedService)];
                    case 1:
                        details = _a.sent();
                        this.userSelectorStatus = details.userSelectorStatus;
                        this.userSelectorId = details.userSelectorId;
                        if (this.isCreateOrDuplicateFlow && !Utils.isFinalUserSelectorState(this.userSelectorStatus.status) && !this.incentiveData.basicInfo.isHHIncentive) {
                            this.selectorStatusUpdateTimer = setTimeout(function () {
                                _this.updateUserSelectorStatus();
                            }, 30 * 1000);
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return PreviewComponent;
}());
export { PreviewComponent };
