import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { assert } from 'console';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IncentiveService } from 'src/app/create-incentive/create-incentive.service';
import { GlobalConstants } from 'src/app/global-constants';
import { LoaderService } from 'src/app/loader.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import Utils from 'src/app/utils/utils';
import { AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { ToasterService } from '../../toaster.service';
import { IAllocatorIncentiveTypes, IAllocatorService } from '../i-allocator.service';

const InvalidSelector = "INVALID_SELECTOR";
const SegmentLabels = {
  D: "Daily Captains",
  INTER: "Inter Captains",
  INTRA: "Intra Captains",
};

const dateFormat = "YYYY-MM-DD";
@Component({
  selector: 'app-incentive-generator',
  templateUrl: './incentive-generator.component.html',
  styleUrls: ['./incentive-generator.component.css'],
  providers: [ToastMessage, DatePipe]
})
export class IncentiveGeneratorComponent implements OnInit, OnChanges {
  @Input() constructs: any;
  public allSegmentsToggle = false;
  private cities: Promise<any> = Promise.resolve({});
  private services: Promise<any> = Promise.resolve({});
  public groupedConstructs = [];
  public iMetricStatus: any;

  constructor(
    private alertDialogService: AlertDialogService,
    public loaderService: LoaderService,
    private incentiveService: IncentiveService,
    private sharedService: SharedService,
    private toasterService: ToasterService,
    private iallocatorService: IAllocatorService
  ) { }

  ngOnChanges(): void {
    const groupedConstructs = _.groupBy(this.constructs.segments,
      segment => this.getGroupIdentifier(segment.label));
    this.groupedConstructs = Object.entries(groupedConstructs)
      .map(([label, segments]) => {
        return {
          label: SegmentLabels[label],
          segments: _.sortBy(segments, "label")
        };
      });

    const dateFormat = "DD-MM-YYYY";
    this.iMetricStatus = {
      dataStatus: this.constructs.imetricNumDays >= 28 ? "Complete" : "InComplete",
      dataLatestDate: moment(this.constructs.imetricLatestDate).format(dateFormat),
      selectorLatestDate: moment(this.constructs.selectorLatestDate).format(dateFormat),
    };
  }

  getGroupIdentifier(label: string) {
    return label.split("_")[1].toUpperCase();
  }

  ngOnInit(): void {
    this.cities = this.sharedService
      .fetchCities().toPromise()
      .then((r: any) => r.data.cities);
    this.services = this.sharedService
      .fetchServices(this.constructs.cityId).toPromise()
      .then((r: any) => r.data);
  }

  incentiveDays(incentive) {
    const { days } = incentive;
    const first = days[0];
    const last = days[days.length - 1];

    if (first == last) {
      return first
    }
    return `${first} - ${last}`
  }

  showAlertBox(options, cb = () => { }) {
    this.alertDialogService.open(options);
    this.alertDialogService.confirmed().subscribe(confirmed => confirmed && cb());
  }

  toggleAllSegments() {
    this.constructs.segments
      .forEach(segment => {
        segment.selected = this.allSegmentsToggle;
      });
  }

  segmentToggled() {
    this.allSegmentsToggle = this.constructs.segments.every(segment => segment.selected);
  }

  resetInvalidIPR(forecast) {
    const ipr = forecast.ipr;
    if (!ipr) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: 'Invalid IPR',
      }));
      forecast.ipr = IAllocatorService.defaultIPR;
    }
  }

  calculateBurn(slab) {
    return slab.ipr * slab.rides * slab.achievingCaptains;
  }

  calculateTotalBurn(forecastedData: any[]) {
    return _.flatMap(forecastedData, forecast => forecast.rideSlabs)
      .reduce((total, slab) => total + this.calculateBurn(slab), 0);
  }

  calculateTotalBurnInTimeSlot(forecast) {
    const slabs = forecast.rideSlabs;
    let totalBurnInTimeSlot = 0;
    slabs.forEach(slab => {
      totalBurnInTimeSlot += this.calculateBurn(slab);
    });
    return totalBurnInTimeSlot;
  }

  selectedSegmentTotals() {
    return this.constructs
      .segments
      .filter(segment => segment.selected)
      .flatMap(segment => segment.segmentInfo)
      .reduce((acc, incentive) => {
        const { forecastedData, netCaptains, netRides } = incentive;
        return {
          netCaptains: acc.netCaptains + netCaptains,
          netRides: acc.netRides + netRides,
          totalBurn: acc.totalBurn + this.calculateTotalBurn(forecastedData)
        };
      }, {
        netCaptains: 0,
        netRides: 0,
        totalBurn: 0
      });
  }

  confirmSelectedIncentives() {
    const selectedSegments = this.getSelectedSegments();
    const segmentsList = selectedSegments.map(segment => segment.label);

    this.showAlertBox({
      title: "Create incentives",
      message: "Create incentives for following segments",
      confirmText: 'Confirm',
      value: segmentsList
    }, async () => {
      try {
        this.loaderService.openLoading();
        const { created, failed } = await this.createIncentives(selectedSegments);
        this.showIncentiveCreationResult(created, failed);
      } catch (error) {
        console.log(error);
        return this.incentiveCreationFailed(error);
      } finally {
        this.loaderService.closeLoading();
      }
    });
  }

  private getSelectedSegments() {
    const selectedSegments = this.constructs
      .segments.filter(segment => segment.selected);

    if (selectedSegments.length === 0) {
      this.showAlertBox({
        title: 'No Segments Selected',
        message: 'Please select at least one segment to proceed.',
      });
      throw new Error("No segments selected");
    }
    return selectedSegments;
  }

  private incentiveCreationFailed(error: any) {
    console.error(error);
    return this.showAlertBox({
      title: 'Error',
      message: 'We are not able to create incentives at this point in time. Please try again after some time. If the issue persists, please reach out to the Captain-Retention team.',
    });
  }

  showIncentiveCreationResult(created, failed) {
    const details = [];
    created.length > 0 && details.push({
      message: "Following incentives were created successfully",
      value: created.map(r => r.message),
    });

    failed.length > 0 && details.push({
      message: "Failed to create following incentives",
      value: failed,
    });

    this.showAlertBox({
      title: "Result",
      details,
      confirmText: 'Download Constructs'
    }, () => this.downloadSegments(created.map(c => c.incentive)));
  }

  async downloadSegments(incentives) {
    const cityId = this.constructs.cityId;
    const cityName = await this.findCityShortName(cityId);
    const fileName = `${this.constructs.incentiveType}_${cityName}_${this.constructs.startDate}_${moment().unix()}`;
    const formatTimeSlots = (goal: any) => goal.timeSlot
      .map(({ fromTime, toTime }) => `${fromTime} - ${toTime}`)
      .join(", ");

    const data = _.flatMap(incentives, (incentive) => {
      const { ruleName, ruleId, startDate, endDate, segment } = incentive;
      return _.flatten(Object.values(incentive.goals))
        .map((goal: any) => {
          const timeSlots = formatTimeSlots(goal);
          const { slabTargets, earningPotential, ipr } = goal.rules
            .reduce((res, rule) => {
              const { order, amount } = rule;
              const { totalEarning, prevTarget } = res;

              const newTotalEarning = totalEarning + amount;
              const newOrders = order - prevTarget;
              const ipr = amount / newOrders;
              res.slabTargets.push(order);
              res.earningPotential.push(newTotalEarning);
              res.ipr.push(ipr);

              return {
                ...res,
                totalEarning: newTotalEarning,
                prevTarget: order
              }
            }, { slabTargets: [], earningPotential: [], ipr: [], totalEarning: 0, prevTarget: 0 })

          return {
            slabTargets: slabTargets.join(", "),
            earningPotential: earningPotential.join(", "),
            ipr: ipr.join(", "),
            timeSlots
          }
        })
        .map(g =>
          [segment, ruleName, ruleId, startDate, endDate, g.timeSlots, g.slabTargets, g.earningPotential, g.ipr])
        .concat([])
    })
    const fields = ["segment", "user_selector_name", "user_selector_id", "start_date", "end_date", "time_buckets", "slab_targets", "earning_potential", "slab_wise_ipr"];

    Utils.downloadCSVFile({ fields, data, fileName });
  }

  async createIncentives(segments) {
    const result = { created: [], failed: [] };
    const incentives = await this.buildIncentives(segments);
    for (const incentive of incentives) {
      try {
        if (incentive instanceof Error) {
          throw incentive;
        }
        await this.incentiveService.createIncentive(incentive).toPromise();
        result.created.push({
          message: `${incentive.incentiveName} - Priority: ${incentive.priority}`, incentive
        });
        const { startDate, endDate } = incentive;
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `Created: ${incentive.incentiveName}, Date: ${startDate} - ${endDate}, Priority: ${incentive.priority}`
        }));
      } catch (error) {
        console.log(error);
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Failed: ${error.message}`,
        }));
        result.failed.push(error.message);
      }
    }
    return result;
  }

  private async buildIncentives(segments: any) {
    const allCities = await this.cities;
    const allServices = await this.services;

    const cityId = this.constructs.cityId;
    const city = allCities.find(c => cityId === c._id);

    const incentiveServices = allServices
      .filter(s => this.constructs.services.includes(s._id));
    const serviceNames = incentiveServices.map(s => s.service.name);
    const serviceType = incentiveServices.map(s => s._id);

    if (serviceType.length != this.constructs.services.length) {
      throw new Error('Invalid services');
    }

    if (!city) {
      throw new Error('Invalid city');
    }

    const incentiveType = this.constructs.incentiveType.toUpperCase();

    const { priority } = this.constructs;
    let incentives = [];
    for (let segment of segments) {
      const segmentIncentives = await this.buildIncentive({
        segment, city, incentiveType, serviceType,
        serviceNames, priority
      });
      incentives = incentives.concat(segmentIncentives);
    }
    return incentives;
  }

  private async buildIncentive({ segment, city, incentiveType, serviceType,
    serviceNames, priority }) {
    const ruleId = segment.selectorId;
    let ruleName: string;
    try {
      const { name, status: selectorStatus } = await this.sharedService
        .getUserSelectorDetails(ruleId).toPromise();
      if (selectorStatus != "COMPLETED") {
        throw new Error("Selector is not completed");
      }
      ruleName = name;
    } catch (error) {
      return [incentiveError(segment.label)];
    }

    const selectedVariable = ["order"];
    if (segment.distanceBaseTarget) {
      selectedVariable.push("distance");
    }

    return this.getSegmentConstructs(segment.segmentInfo)
      .map(incentiveConstruct => {
        const { startDate, endDate } = incentiveConstruct;
        return {
          type: "incentives",
          incentiveName: this.getIncentiveName(segment, city.shortName, incentiveConstruct),
          incentiveType: this.getIncentiveType(incentiveType),
          cities: [city.displayName],
          shift: [],
          label: "iAllocator",
          segment: segment.label,
          serviceType,
          serviceNames,
          ruleName,
          ruleId,
          startDate,
          endDate,
          priority,
          smsTemplate: GlobalConstants.smsTemplate[incentiveType],
          tnc: GlobalConstants.incentiveTncs,
          active: true,
          selectedCondition: "&&",
          selectedVariable,
          ...this.createGoalsAndPredictions(segment, incentiveConstruct),
        };
      })
  }

  private getSegmentConstructs(segmentConstructs: any) {
    const { startDate, endDate } = this.constructs;
    if (this.constructs.incentiveType == IAllocatorIncentiveTypes.WEEKLY) {
      return [
        {
          startDate,
          endDate,
          forecastedData: segmentConstructs
            .flatMap(({ days, forecastedData, netRides, netCaptains }) => {
              return forecastedData
                .map(fc => ({
                  ...fc, days, netRides, netCaptains
                }))
            })
        }
      ];
    }

    const allDayDates = this.getDatesBetween(startDate, endDate)
      .map(d => ({
        date: d,
        day: this.getWeekDay(d)
      }))
      .reduce((acc, d) => {
        if (!acc[d.day]) {
          acc[d.day] = [];
        }
        acc[d.day].push(d.date);
        return acc;
      }, {});

    return segmentConstructs
      .flatMap(({ days, forecastedData, netRides, netCaptains }) => {
        const first = days[0];
        const last = days.length - 1;

        return allDayDates[first]
          .map(startDate => ({
            startDate,
            endDate: moment(startDate).add(last, "days").format(dateFormat),
            forecastedData,
            netRides, netCaptains
          }))
      });
  }

  private getWeekDay(d: string): string {
    moment(d).endOf("isoWeek")
    return moment(d).format("dddd");
  }

  private getDatesBetween(startDate: string, endDate: string) {
    const dates = [startDate];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.format(dateFormat));
    }

    return dates;
  }

  private createSlabRules(slabs, isDistanceTargetEnabled) {
    return slabs.map((slab, index) => {
      let slabRule = {
        order: slab.rideTarget,
        amount: this.slabCreditAmount(slab),
        index: index + 1
      };
      if (isDistanceTargetEnabled) {
        slabRule["distance"] = slab.distanceTarget
      }
      return slabRule;
    });
  };

  private createGoalsAndPredictions(segment, incentive) {
    const isDistanceTargetEnabled = segment.distanceBaseTarget;
    return incentive.forecastedData
      .reduce((acc, forecast) => {
        const { goals, allocatorPredictions } = acc;
        const daysKey = this.createDaysKey(forecast);

        const uuid = GlobalConstants.generateUuid();
        const goal = {
          timeSlot: forecast.timeSlotsProjection,
          rules: this.createSlabRules(forecast.rideSlabs, isDistanceTargetEnabled),
          uuid
        };

        goals[daysKey] = goals[daysKey] || [];
        goals[daysKey].push(goal);

        const { netRides, netCaptains } = forecast.timeSlotsProjection[0];
        allocatorPredictions
          .forecasts
          .push({
            uuid,
            netRides,
            netCaptains,
            rideSlabs: this.createSlabForecasts(forecast.rideSlabs, isDistanceTargetEnabled)
          });
        return acc;
      }, {
        goals: {},
        allocatorPredictions: {
          forecasts: [],
          netCaptains: segment.segmentInfo.netCaptains,
          netRides: segment.segmentInfo.netRides,
          selectorSize: segment.selectorSize
        }
      });
  }

  createDaysKey({ days }) {
    if (this.constructs.incentiveType == IAllocatorIncentiveTypes.DAILY) {
      return GlobalConstants.AllIncentiveWeekDays.join(', ')
    }

    return days.join(", ");
  }

  createSlabForecasts(rideSlabs: any, isDistanceTargetEnabled) {
    return rideSlabs.map((slab, index) => {
      let slabForecast = {
        index: index + 1,
        achievingCaptains: slab.achievingCaptains,
        bauIPR: slab.bauIPR,
        suggestedIPR: slab.suggestedIPR,
        finalIPR: slab.ipr,
        finalAchievingCaptains: slab.achievingCaptains
      };
      if (isDistanceTargetEnabled) {
        slabForecast["distancePerOrder"] = slab.perOrderDistance;
        slabForecast["distanceTarget"] = slab.distanceTarget;
      }
      return slabForecast
    });
  }

  slabCreditAmount(slab) {
    return Math.floor(slab.rides * slab.ipr);
  }

  earningPotential(forecast) {
    const slabs = forecast.rideSlabs
    const target = slabs[slabs.length - 1].rideTarget
    return {
      total: slabs.map(slab => this.slabCreditAmount(slab))
        .reduce((a, b) => a + b),
      target
    }
  }

  getIncentiveName(segment, cityName, incentiveConstruct) {
    const { incentiveType } = this.constructs;
    const { startDate, endDate } = incentiveConstruct;
    const shortStartDate = moment(startDate).format('DDMMYYYY');
    const shortEndDate = moment(endDate).format('DDMMYYYY');

    const incentiveName = `ALLOCATOR_${cityName}_${segment.label}_${shortStartDate}_${shortEndDate}_${incentiveType}`;
    return incentiveName.toUpperCase();
  }

  getIncentiveType(incentiveType) {
    return GlobalConstants.IAllocatorIncentiveTypes[incentiveType];
  }

  async csvForUserSelectorIdAndUserId() {
    const userSelectorIdsWithLabels = this.getSelectedSegments()
      .map(selectedSegment => [selectedSegment.selectorId, selectedSegment.label]);
    const data: any = await this.iallocatorService.fetchAllUserFor(userSelectorIdsWithLabels);

    const cityName = await this.findCityShortName(this.constructs.cityId);

    const fileName = `selectors_${cityName}_${this.dateToday()}_${this.getCurrentTime()}`;
    const fields = ["segment_name", "selector_id", "rider_id"];

    Utils.downloadCSVFile({ fields, data, fileName });
  }

  private dateToday() {
    return moment().format(dateFormat);
  }

  private getCurrentTime() {
    return moment().format('HH:mm:ss');
  }

  async findCityShortName(cityId: string): Promise<string> {
    const allCities = await this.cities;
    return allCities.find(c => cityId === c._id).shortName.toUpperCase();
  }
}

function incentiveError(segment: any) {
  const err = new Error(`${segment} : User selector not created`);
  err["type"] = InvalidSelector;
  return err;
}
