import { Component, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, Input } from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { SharedService } from 'src/app/shared/shared.service';
import message from './smsTemplates.js'

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  providers: [ToastMessage]
})
export class CommunicationComponent implements OnChanges, OnInit {

  constructor(private toastMessage: ToastMessage,
              public toasterService: ToasterService,
              public sharedService: SharedService) { }
  @Input() incentiveData: any;
  @Output() communicationInfo: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  public termsPreview: any = [
    `Rides strictly accepted in the given time duration will only be considered.`,
    `You will not receive any incentive if you are involved in any kind of fraud and additionally, you will be penalized.`,
    `Rapido has the right to make changes in the incentives at any time.`,
  ];
  public nextClicked = false;
  public header: any = 'Communication';
  public smsTemplate: any = '';
  public messageLocale: String = '';
  public displayTemplate = false;
  public templates = [];

  communicationFormGroup = new FormGroup({
    termsAndCondition: new FormControl('', Validators.required),
    smsTemplate: new FormControl('', Validators.required),
    messageLocale: new FormControl("en")
  });
  @Input()  NOT_EDITABLE = {
    tnc: false,
    smsTemplate: false,
  };

  public hover: any;
  @Input() defaultValue: any;
  @Input() state: string;


  ngOnChanges(changes: SimpleChanges) {
    if (changes.incentiveData) {
      this.incentiveData = changes.incentiveData.currentValue;
      this.templates = this.getSmsTemplates();
    }
  }

  private getSmsTemplates() {
    const incentiveType = this.incentiveData && this.incentiveData.basicInfo ? this.incentiveData.basicInfo.incentiveType : '';
    if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.selectedVariable && this.incentiveData.goalsInfo.selectedVariable.includes('quality')) {
      return message["weeklyWithQuality"]
    }
    return message[incentiveType]
  }

  checkForDisableTemplate() {
    if (this.NOT_EDITABLE.smsTemplate)  {
      this.communicationFormGroup.get('smsTemplate').disable();
    }
  }

  ngOnInit() {
    switch (this.state) {
      case this.sharedService.STATE.EDIT: {
        this.patchDefaultValue(this.defaultValue);
        this.checkForDisableTemplate();
        break;
      }
      case this.sharedService.STATE.DUPLICATE: {
        this.patchDefaultValue(this.defaultValue);
        break;
      }
    }
  }

  patchDefaultValue(defaultValue = {
    smsTemplate : '',
    tnc : [],
    messageLocale: ''
  }) {
    this.termsPreview = defaultValue.tnc || [];
    this.communicationFormGroup.patchValue({smsTemplate: defaultValue.smsTemplate, messageLocale: defaultValue.messageLocale || "en"});
    this.displayTemplate = false;
    document.getElementById('overlay').style.display = 'none';

  }

  getValues(formControl: string) {
    return this.communicationFormGroup.get(formControl).value;
  }

  passCommunicationInfo() {
    this.nextClicked = true;
    if (this.termsPreview.length === 0) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `T&C is empty.`,
      }));
      return;
    }
    if ((this.incentiveData && this.incentiveData.basicInfo &&
        this.incentiveData.basicInfo.incentiveType !== 'Redeem') && (!this.getValues('smsTemplate'))) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Select an SMS template for incentive communication.`,
        }));
        return;
    }
    const info = {
      submit : false,
      termsAndCondition : this.termsPreview,
      smsTemplate: this.getValues('smsTemplate'),
      messageLocale: this.getValues('messageLocale')
    };
    this.communicationInfo.emit(info);
  }

  goBack() {
    this.back.emit();
  }


  addTermsToPreview() {
    if (!this.getValues('termsAndCondition')) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter terms and conditions to add`,
      }));
      return;
    }
    this.termsPreview.push(this.getValues('termsAndCondition'));
    this.communicationFormGroup.patchValue({termsAndCondition: ''});
  }

  deleteTerms(index) {
    this.termsPreview.splice(index, 1);
  }

  showTemplates() {
    this.displayTemplate = true;
    document.getElementById('overlay').style.display = 'block';
  }

  cancel() {
    if (!this.displayTemplate) {
      document.getElementById('overlay').style.display = 'none';
    }
  }

  assignTemplate(index) {
    this.communicationFormGroup.patchValue({smsTemplate: this.templates[index].message, messageLocale: this.templates[index].locale});
    this.displayTemplate = false;
    document.getElementById('overlay').style.display = 'none';
  }

}
