import { Injectable } from '@angular/core';

@Injectable()
export class GlobalConstants {
  static smsTemplate = {
    DAILY: "Earn daily incentives upto Rs ${totalAmount} by doing ${ordertypes} orders on ${date}. For more details click",
    WEEKLY: "Earn weekly incentives up to Rs ${totalAmount} by doing ${ordertypes} orders from ${fromDate} to ${toDate}.For more details click"
  };

  static locales = {
    ENGLISH: "en",
    HINDI: "hi",
    KANNADA: "kn",
    TELUGU: "te",
    TAMIL: "ta",
    BENGALI: "bn",
    MARATHI: "mr",
    MALAYALAM: "ml"
  };

  static incentiveTncs = [
    "Rides strictly accepted in the given time duration will only be considered.",
    "You will not receive any incentive if you are involved in any kind of fraud and additionally, you will be penalized.",
    "Rapido has the right to make changes in the incentives at any time."
  ];

  static generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static IAllocatorIncentiveTypes = {
    DAILY: "Daily",
    WEEKLY: "Weekly Fixed",
  };

  static AllIncentiveWeekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
}
