<mat-sidenav-container class="home-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="sidenav-pane" fullscreen>
    <app-sidenav-list [toggleList]="showLabels" [access]="userRights" (collapse)="showLabels = false"
      (expand)="showLabels = true"></app-sidenav-list>
  </mat-sidenav>
  <div id="overlay"></div>
  <mat-toolbar class="primary-toolbar app-toolbar">
    <button mat-button (click)="showLabels = !showLabels">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="loggedIn-user" [matMenuTriggerFor]="userMenu" *ngIf="authService.isUserLoggedIn">
      <div class="toolbar-image">
        <img class="toolbar-avatar" [src]="user.imageUrl">
      </div>
      <div class="toolbar-info">
        <div class="toolbar-username clip-text" [attr.title]="user.name">{{user.name}}</div>
        <div class="toolbar-role clip-text" [attr.title]="user.role">{{user.role}}</div>
      </div>
    </div>
  </mat-toolbar>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="logOut()">
      <mat-icon class="logout-icon">power_settings_new</mat-icon>
      Logout
    </button>
  </mat-menu>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
