import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
import { FileUpload } from 'primeng/fileupload';
import { BULK_CREDIT_HISTORY } from 'src/app/routes';
import Utils from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../loader.service';
import { AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { BulkCreditArrayFields, BulkCreditService } from '../bulkcredit.service';
var BulkCreditInitiateComponent = /** @class */ (function () {
    function BulkCreditInitiateComponent(bulkCreditService, loaderService, alertDialogService, router) {
        this.bulkCreditService = bulkCreditService;
        this.loaderService = loaderService;
        this.alertDialogService = alertDialogService;
        this.router = router;
        this.validated = null;
        this.validationError = false;
        this.fileDetails = null;
        this.maxTransactions = environment.maxBulkCreditTransactionsPerFile;
        this.enabledTransactionTypes = this.bulkCreditService
            .transactionTypes.filter(function (t) { return !t.disabled; });
        this.bulkCreditFormGroup = new FormGroup({
            subTransactionType: new FormControl(null, Validators.required),
            comment: new FormControl(null, Validators.required),
            remark: new FormControl(null, Validators.minLength(1)),
        });
    }
    ;
    BulkCreditInitiateComponent.prototype.resetFile = function () {
        this.fileDetails = null;
        this.validated = null;
        this.validationError = false;
    };
    ;
    BulkCreditInitiateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetOnchangeOf("subTransactionType", ["remark"], function () {
            _this.fileUploadRef && _this.fileUploadRef.clear();
            _this.resetFile();
        });
    };
    BulkCreditInitiateComponent.prototype.selectedSubTransactionType = function () {
        return this.bulkCreditFormGroup.get("subTransactionType").value;
    };
    BulkCreditInitiateComponent.prototype.resetOnchangeOf = function (controlName, toReset, cb) {
        var _this = this;
        if (cb === void 0) { cb = function () { }; }
        this.bulkCreditFormGroup.get(controlName).valueChanges.subscribe(function () {
            toReset.forEach(function (control) {
                _this.bulkCreditFormGroup.get(control).setValue(null);
            });
            cb();
        });
    };
    BulkCreditInitiateComponent.prototype.handleFile = function (event) {
        var _this = this;
        this.validated = null;
        var file = event.files[0];
        this.resetFile();
        Papa.parse(file, {
            complete: function (results) {
                _this.fileDetails = { records: results.data, name: file.name };
            },
            header: true,
            skipEmptyLines: true,
            transformHeader: function (header) { return header.trim(); },
            transform: function (value, header) { return _this.parseCSVValue(value, header); },
        });
    };
    BulkCreditInitiateComponent.prototype.parseCSVValue = function (value, header) {
        if (BulkCreditArrayFields.includes(header)) {
            return value.split("|")
                .map(function (v) { return v.trim(); });
        }
        return value.trim();
    };
    BulkCreditInitiateComponent.prototype.downloadSampleFile = function () {
        var subTransactionType = this.selectedSubTransactionType();
        if (!subTransactionType) {
            return;
        }
        Utils.downloadCSVFile({
            fields: subTransactionType.headers,
            data: subTransactionType.sampleData,
            fileName: subTransactionType.name.toLowerCase() + ".csv",
        });
    };
    BulkCreditInitiateComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(function (confirmed) { return confirmed && cb(); });
    };
    BulkCreditInitiateComponent.prototype.valdidateBulkCreditFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var valid, _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        valid = this.validateTransactionCount();
                        if (!valid) {
                            return [2 /*return*/];
                        }
                        this.loaderService.openLoading();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        _a = this;
                        return [4 /*yield*/, this.bulkCreditService
                                .valdidateBulkCreditFile(this.bulkCreditRequest())];
                    case 2:
                        _a.validated = _b.sent();
                        this.showAlertBox({
                            title: 'Success',
                            message: 'File validation successful.',
                        });
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _b.sent();
                        this.handleError(err_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BulkCreditInitiateComponent.prototype.validateTransactionCount = function () {
        var transactions = this.fileDetails.records;
        var count = transactions.length;
        if (count > this.maxTransactions) {
            this.showAlertBox({
                title: 'Error',
                message: "You can only upload " + this.maxTransactions + " transactions at a time.",
            });
            return false;
        }
        return true;
    };
    BulkCreditInitiateComponent.prototype.confirmBulkCreditInitiate = function () {
        var _this = this;
        var subTransactionType = this.selectedSubTransactionType();
        var message = "You are about to credit \u20B9" + this.validated.totalAmount + " to " + this.validated.transactionsCount + " captains. Do you want to proceed?";
        if (subTransactionType.type == "debit") {
            message = "You are about to debit \u20B9" + Math.abs(this.validated.totalAmount) + " from " + this.validated.transactionsCount + " captains. Do you want to proceed?";
        }
        this.showAlertBox({
            title: 'Confirm',
            message: message,
            confirmText: 'Yes, proceed',
        }, function () { return _this.initiateBulkCredit(); });
    };
    BulkCreditInitiateComponent.prototype.initiateBulkCredit = function () {
        var _this = this;
        this.loaderService.openLoading();
        this.bulkCreditService
            .initiateBulkCredit(this.bulkCreditRequest())
            .then(function (res) {
            _this.showAlertBox({
                title: 'Success',
                message: 'Bulk pay initiated successfully.',
                confirmText: 'View history',
            }, function () { return _this.gotoHistoryPage(); });
        })
            .catch(function (err) { return _this.handleError(err); })
            .finally(function () { return _this.loaderService.closeLoading(); });
    };
    BulkCreditInitiateComponent.prototype.gotoHistoryPage = function () {
        this.router.navigateByUrl(BULK_CREDIT_HISTORY);
    };
    BulkCreditInitiateComponent.prototype.bulkCreditRequest = function () {
        var subTransactionType = this.selectedSubTransactionType();
        return {
            subTransactionType: subTransactionType.name,
            remarks: this.bulkCreditFormGroup.get("remark").value,
            comment: this.bulkCreditFormGroup.get("comment").value,
            transactions: this.fileDetails.records,
        };
    };
    BulkCreditInitiateComponent.prototype.handleError = function (err) {
        var unknownErr = "Unknown error, Please check history to get latest status of your file.";
        var res = err.error || { message: unknownErr };
        if (!res.data) {
            this.showAlertBox({
                title: 'Error',
                message: res.message,
            });
            return;
        }
        var data = res.data;
        if (data.code && data.code == "BULK_PAY_VALIDATION_ERROR") {
            this.validationError = true;
            var errors = data.errors;
            var value = errors
                .map(function (err) { return "Record: " + err.recordNumber + " - " + err.error; });
            this.showAlertBox({
                title: 'Validation Error',
                details: [
                    {
                        value: value,
                        message: "Please correct the errors and try again."
                    }
                ],
            });
            return;
        }
        var error = data.error || data;
        this.showAlertBox({
            title: 'Error',
            message: error.message || unknownErr,
        });
    };
    return BulkCreditInitiateComponent;
}());
export { BulkCreditInitiateComponent };
