<div>
  <div class="padding-20">
    <!-- Daily Incentive-->
    <div class="row" *ngIf="incentiveData.basicInfo && (incentiveData.basicInfo.incentiveType === 'Daily' || incentiveData.basicInfo.incentiveType === 'Adhoc')">
      <div class="col-md-12">
          <div class="condition-card shadow-all">
              <app-header [header]="header"></app-header>
              <div class="row margin-2">
                  <div class="col-md-5 margin-top-7">
                    <div class="col-md-1">
                        <p class="preview-text font-weight" style="margin: 0px !important">Variables</p>
                    </div>
                    <div class="col-md-4 padding-top-12">
                      <div class="customisedFlexRow">
                          <div class="customisedFlexCol">
                              <mat-checkbox [disabled]="NOT_EDITABLE.variables"  (click)="addVariables('order', $event)" [checked]="orderChecked">Order</mat-checkbox>
                          </div>
                          <div class="customisedFlexCol">
                              <mat-checkbox [disabled]="NOT_EDITABLE.variables" *ngIf="showDistanceVariable()" (click)="addVariables('distance', $event)" [checked]="distanceChecked">Distance</mat-checkbox>
                          </div>
                          <div class="customisedFlexCol" *ngIf="incentiveData.basicInfo && (incentiveData.basicInfo.incentiveType === 'Daily') && incentiveData.basicInfo.isCancellationLimitEnabledForCity">
                            <mat-checkbox [disabled]="NOT_EDITABLE.variables" [(ngModel)]="cancellationChecked" (click)="handleCancellation()">Cancellation</mat-checkbox>
                          </div>
                          <div class="customisedFlexCol" *ngIf="maxOrderConditionCheck()">
                             <mat-checkbox [disabled]="NOT_EDITABLE.variables" [(ngModel)]="maxOrderDistanceChecked" (click)="handleMaxOrderDistance()">Max Order Distance</mat-checkbox>
                          </div>
                      </div>
                    </div>
                    <p *ngIf="variableError" class="error">Variable required</p>
                  </div>
                  <div class="col-md-6 margin-top-7" [hidden]="!(selectedVariable.length > 1)">
                    <p class="preview-text font-weight condition-margin">Conditions</p>
                    <div class="ui-g">
                      <div class="ui-g-12 padding-left-0">
                        <div class="ui-g-3">
                          <p-radioButton [disabled]="NOT_EDITABLE.variables" name="group1" value="&&" label="AND (&&)" [(ngModel)]="condition"></p-radioButton>
                        </div>
                        <div class="ui-g-3">
                          <p-radioButton [disabled]="NOT_EDITABLE.variables" name="group1" value="||" label="OR (||)" [(ngModel)]="condition"></p-radioButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div class="cancellationInfoBlock" *ngIf="this.cancellationChecked">
            <div style="margin-left: 10px;padding-top: 15px;padding-bottom: 15px;">
              Cancellation Limit
              <select [(ngModel)]="cancellationLimitScope" (ngModelChange)="clearCancellationLimits()">
                <option value="global">Global</option>
                <option value="set">Set</option>
              </select>
            </div>
            <div style="margin-left: 10px;padding-bottom: 15px;" *ngIf="this.cancellationLimitScope == 'global'">
              Cancellation #
              <input style="padding: 0px;margin-left: 10px;" type="number"
              min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              pInputText [(ngModel)]="this.cancellationGlobalLimit">
            </div>
          </div>
          <div class="cancellationInfoBlock" *ngIf="this.maxOrderDistanceChecked">
            <div style="margin-left: 10px;padding-bottom: 15px;">
              Max Order Distance (Global) #
              <input style="padding: 0px;margin-left: 10px;" type="number"
              min="0" step="0.01" pInputText [(ngModel)]="this.maxOrderDistanceGlobal">
              <label> </label>
            </div>
          </div>
          <div class="goals-card shadow-all margin-top-6">
              <div class="row margin-2" *ngIf="selectedVariable.length > 0">
                  <div class="col-md-5 border-right">
                    <p class="preview-text">Select timing</p>
                  </div>
                  <div class="col-md-7">
                    <p class="preview-text">Select Goals</p>
                  </div>
              </div>
                <div class="row margin-0">
                  <div *ngIf="selectedVariable.length > 0" class="divider"></div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                      <p-scrollPanel [style]="{width: '100%'}">
                          <div *ngFor="let incentiveData of dailyIncentiveData; let i = index">
                              <div *ngFor="let data of incentiveData | keyvalue; let slotIndex = index">
                                <div *ngIf="data.key !== 'days'">
                                  <p-accordion>
                                    <p-accordionTab id="accordian" class="height-19" [selected]="true" header="Set {{ data.key }}">
                                        <p-header *ngIf="data.key !== '1'" class="margin-left-10">Set {{ data.key }}
                                            <button id="deleteMenu" *ngIf="!NOT_EDITABLE.timings" class="ellipse" mat-icon-button [matMenuTriggerFor]="menu" (click)="preventDefault($event)">
                                                <mat-icon class="margin-bottom-20">more_vert</mat-icon>
                                              </button>
                                              <!-- <mat-menu #menu="matMenu"> -->
                                                  <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="deleteSet(data.key)">
                                                  <mat-icon class="delete-set" (click)="deleteSet(data.key)">delete</mat-icon>
                                                  <span class="delete-text" (click)="deleteSet(data.key)"> Delete set {{ data.key }}</span>
                                                </button>
                                              </mat-menu>
                                        </p-header>
                                        <div style="font-weight: bold;margin-top: 20px;margin-right: 10px;" *ngIf="this.cancellationLimitScope == 'set'">
                                          Cancellation #
                                          <input style="margin-left: 10px;" type="number"
                                          min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                          pInputText [(ngModel)]="this.setsCancellationLimit[data.key - 1]">
                                        </div>
                                      <div class="row incentive-set-height" id="dailyIncenitveTiming">
                                        <div class="col-md-5 border-right padding-bottom-8">
                                          <div *ngFor="let goals of data.value | keyvalue">
                                            <div *ngIf="goals.key === 'timeSlot'">
                                              <div *ngFor="let time of goals.value;let timeIndex = index">
                                                <div class="row">
                                                  <div class="col-md-9 margin-top-17 margin-bottom-17">
                                                    <div class="row">
                                                      <div class="col-md-6 cover">
                                                          <p class="preview-text font-weight">Start Time</p>
                                                          <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings"  placeholder="hour" [options]="hours" [(ngModel)]="goals.value[timeIndex].fromTimeHour"  (onChange)="timeSlotChanged(data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                          <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings"  placeholder="min" [options]="startMinutes" [(ngModel)]="goals.value[timeIndex].fromTimeMin" (onChange)="timeSlotChanged(data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                      </div>
                                                      <div class="col-md-6">
                                                          <p class="preview-text font-weight">
                                                              End Time
                                                            </p>
                                                            <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings"  placeholder="hour" [options]="hours" [(ngModel)]="goals.value[timeIndex].toTimeHour" (onChange)="timeSlotChanged(data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                            <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings"  placeholder="min" [options]="endMinutes" [(ngModel)]="goals.value[timeIndex].toTimeMin" (onChange)="timeSlotChanged(data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-1 margin-top-36">
                                                    <button class="ui-button-secondary sms-template" *ngIf="!NOT_EDITABLE.timings" pButton type="button" (click)="addTimeSlot(data.key)" icon="pi pi-plus"></button>
                                                  </div>
                                                  <div class="col-md-2 margin-top-36" [hidden]="timeIndex === 0">
                                                    <div class="trash-container margin-left-14">
                                                      <div class="trash-border" *ngIf="!NOT_EDITABLE.timings">
                                                        <i class="pi pi-trash trash" (click)="deleteTimeSlot(data.key, timeIndex)"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  </div>
                                                  <div *ngIf="slotHasError(data.key, timeIndex)" class="error">
                                                    There are overlapping incentive/s for the same time bucket.
                                                    <a class="triggerPopup"
                                                      style="text-decoration: underline;"
                                                      (click)="showIncentivePopup(data.key, timeIndex)">Click here</a> to see them.
                                                    <br/>
                                                    <br/>
                                                    <a class="triggerPopup"
                                                    style="text-decoration: underline;"
                                                    (click)="showTimeBucketPopup()">Click here</a> to know more about time buckets and setting incentives
                                                  </div>
                                                </div>
                                              </div>

                                          </div>
                                        </div>
                                        <div class="col-md-7 padding-bottom-8"> 
                                          <div *ngIf="selectedVariable.length > 0" class="inline-content">
                                            Captain has to do
                                            <div *ngIf="selectedVariable.indexOf('order') !== -1">
                                              <span class="bold">{{totalOrdersMap.get(data.key)}}</span> orders
                                            </div>
                                            <div *ngIf="selectedVariable.length > 1">&nbsp;{{condition}}&nbsp;</div>
                                            <div *ngIf="selectedVariable.indexOf('distance') !== -1">
                                              <span class="bold">{{totalDistanceMap.get(data.key)}}</span>  kms
                                            </div>
                                            to earn a total of <span class="bold">{{totalAmountMap.get(data.key)}}</span> rupees 
                                          </div>
                                          <div *ngFor="let goals of data.value | keyvalue">
                                            <div *ngIf="goals.key === 'rules'">
                                              <div
                                                *ngFor="let rule of goals.value;let ruleIndex = index">
                                                <div class="row z-index-10">
                                                  <div class="col-md-2 margin-top-17" *ngIf="selectedVariable.indexOf('order') !== -1">
                                                    <p class="preview-text font-weight">
                                                      Order#
                                                    </p>
                                                    <input class="width-85 border" [disabled]="NOT_EDITABLE.goals" type="number" min="1"
                                                    step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    pInputText (change)="reComputeTotalOrders(data.key, goals.value)" [(ngModel)]="goals.value[ruleIndex].order"
                                                    />
                                                  </div>
                                                  <div class="col-md-1 margin-top-36" *ngIf="selectedVariable.length > 1">
                                                    <p class="preview-text">{{ condition }}</p>
                                                  </div>
                                                  <div class="col-md-2 padding-left-0 margin-top-17" *ngIf="selectedVariable.indexOf('distance') !==-1">
                                                    <p class="preview-text font-weight">
                                                      Distance#
                                                    </p>
                                                    <input class="width-85 border" [disabled]="NOT_EDITABLE.goals" type="number"
                                                    min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    pInputText (change)="reComputeTotalDistance(data.key, goals.value)" [(ngModel)]="goals.value[ruleIndex].distance"/>
                                                  </div>
                                                  <div class="col-md-1 margin-top-36" *ngIf="selectedVariable.length !== 0">
                                                    <p class="preview-text">=</p>
                                                  </div>
                                                  <div class="col-md-2 margin-top-17" *ngIf="selectedVariable.length !== 0">
                                                    <p class="preview-text font-weight">
                                                      Amount
                                                    </p>
                                                    <span class="input-euro-daily left">
                                                        <input class="width-85 border" [disabled]="NOT_EDITABLE.goals" type="number"
                                                        min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        pInputText (change)="reComputeTotalAmount(data.key, goals.value)" [(ngModel)]="goals.value[ruleIndex].amount"/>
                                                    </span>
                                                    <p *ngIf="goals.value[ruleIndex].amount >= ruleWarnAmountLimit && goals.value[ruleIndex].amount < ruleMaxAmountLimit" class="warning-text">
                                                      High Amount!
                                                    </p>
                                                    <p *ngIf="goals.value[ruleIndex].amount >= ruleMaxAmountLimit" class="max-limit-reached-text">
                                                      Max limit reached!
                                                    </p>
                                                  </div>
                                                  <div class="col-md-1 margin-top-36" *ngIf="selectedVariable.length !== 0 && totalAmountMap.get(data.key) < setMaxAmountLimit">
                                                    <button *ngIf="!NOT_EDITABLE.goals" class="ui-button-secondary sms-template" pButton type="button" (click)="addGoals(data.key, ruleIndex+1)" icon="pi pi-plus"></button>
                                                  </div>
                                                  <div class="col-md-2 margin-top-36" [hidden]="ruleIndex === 0">
                                                    <div class="trash-container">
                                                      <div *ngIf="!NOT_EDITABLE.goals" class="trash-border">
                                                        <i class="pi pi-trash trash" (click)="deleteGoals(data.key, ruleIndex, goals.value)"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <p *ngIf="totalAmountMap.get(data.key) > setWarnAmountLimit && totalAmountMap.get(data.key) < setMaxAmountLimit" class="warning-total-text">
                                            High total amount!
                                          </p>
                                          <p *ngIf="totalAmountMap.get(data.key) > setMaxAmountLimit" class="max-limit-total-reached-text">
                                            Max amount for set exceeded!
                                          </p>
                                        </div>
                                      </div>
                                    </p-accordionTab>
                                  </p-accordion>
                                </div>
                              </div>
                          </div>
                          <!-- Create Set -->
                          <div class="row margin-2">
                              <div class="col-md-3 margin-top-17">
                                <span class="rapido-form-field">
                                  <button
                                    *ngIf="incentiveData.basicInfo.incentiveType !== 'Adhoc' && !NOT_EDITABLE.timings"
                                    [disabled]="selectedVariable.length === 0"
                                    class="ui-button-secondary sms-template"
                                    pButton
                                    type="button"
                                    (click)="addSet()"
                                    icon="pi pi-plus"
                                    label="Create Set"></button>
                                </span>
                              </div>
                          </div>
                      </p-scrollPanel>
                  </div>
                </div>

                <div class="row margin-0">
                    <div class="margin-top-5 divider"></div>
                </div>

                <!--Back and Next button-->
                <div class="row margin-bottom-20">
                    <div class="pull-right">
                        <span class="rapido-form-field">
                            <button class="back-button" (click)="goBack()" type="button">
                              Back
                            </button>
                        </span>
                      <span class="rapido-form-field padding-right-24">
                        <button mat-raised-button class="next-button" color="primary" (click)="passGoalsInfo()" type="button">
                          Next
                        </button>
                      </span>
                    </div>
                </div>
          </div>
      </div>
    </div>

    <!-- Weekly Incentive-->
    <div class="row" *ngIf="incentiveData.basicInfo && (incentiveData.basicInfo.incentiveType === 'Weekly Fixed' || incentiveData.basicInfo.incentiveType === 'Weekly Selectable')">
      <div class="col-md-12">
        <div class="condition-card shadow-all">
            <app-header [header]="header"></app-header>
            <div class="row margin-2">
                <div class="col-md-5 margin-top-7">
                    <div class="col-md-1">
                        <p class="preview-text font-weight" style="margin: 0px !important">Variables</p>
                    </div>
                    <div class="col-md-4 padding-top-12">
                      <div class="row">
                          <div class="col-md-6">
                              <mat-checkbox [disabled]="NOT_EDITABLE.variables"  (click)="addVariables('order', $event)" [checked]="orderChecked">Order</mat-checkbox>
                          </div>
                          <div class="col-md-6">
                              <mat-checkbox [disabled]="NOT_EDITABLE.variables" (click)="addVariables('distance', $event)" [checked]="distanceChecked">Distance</mat-checkbox>
                          </div>
                      </div>
                    </div>
                    <p *ngIf="variableError" class="error">Variable required</p>
                  </div>
              <div class="col-md-6 margin-top-8" [hidden]="!(selectedVariable.length > 1)">
                <p class="preview-text font-weight condition-margin">Conditions</p>
                <div class="ui-g">
                  <div class="ui-g-12 padding-left-0">
                    <div class="ui-g-3">
                      <p-radioButton name="group1" [disabled]="NOT_EDITABLE.variables" value="&&" label="AND (&&)" [(ngModel)]="condition"></p-radioButton>
                    </div>
                    <div class="ui-g-3">
                      <p-radioButton name="group1" [disabled]="NOT_EDITABLE.variables" value="||" label="OR (||)" [(ngModel)]="condition"></p-radioButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="goals-card shadow-all margin-top-6">
            <div class="row margin-2" *ngIf="selectedVariable.length > 0">
                <div class="col-md-3 border-right">
                  <p class="preview-text">Select Days</p>
                </div>
                <div class="col-md-9">
                  <div class="row">
                      <div class="col-md-3 border-right">
                          <p class="preview-text margin-left-0 ">Select timing</p>
                        </div>
                        <div class="col-md-6 margin-left-0">
                          <p class="preview-text margin-left-0 ">Select Goals</p>
                        </div>
                  </div>
                </div>
            </div>

            <div class="row margin-0">
                <div class="divider" *ngIf="selectedVariable.length > 0"></div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p-scrollPanel [style]="{width: '100%'}">
                        <div *ngIf="selectedVariable.length > 0">
                            <div *ngFor="let incentiveData of weeklyIncentiveData; let i = index" >
                                <p-accordion>
                                  <p-accordionTab id="weeklyAccordian" class="height-19" header="Set {{ i + 1 }}" [selected]="true">
                                      <p-header *ngIf="i !== 0" class="margin-left-10">Set {{ i + 1 }}
                                          <button id="deleteMenu" *ngIf="!NOT_EDITABLE.timings" class="ellipse" mat-icon-button [matMenuTriggerFor]="menu" (click)="preventDefault($event)">
                                              <mat-icon class="margin-bottom-20">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                              <button mat-menu-item (click)="deleteWeeklySet(i)">
                                                <mat-icon class="delete-set" (click)="deleteWeeklySet(i)">delete</mat-icon>
                                                <span class="delete-text" (click)="deleteWeeklySet(i)"> Delete set {{ i + 1}}</span>
                                              </button>
                                            </mat-menu>
                                      </p-header>
                                      <div class="incentive-set-height" id="weeklyIncentive">
                                          <div class="row">
                                              <!--Selecting days-->
                                              <div class="col-md-3">
                                                <div>
                                                   <div class="row margin-top-11">
                                                    <div class="col-6 margin-top-36">
                                                        <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.weeklyDays" (click)="onWeeklyIncentiveTimeClick()"  [options]="days" (onChange)="joinDays(incentiveData.startDay, i)"   [(ngModel)]="incentiveData.startDay" optionLabel="day"></p-dropdown>
                                                    </div>
                                                    <div class="col-5 margin-top-36">
                                                        <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.weeklyDays" (click)="onWeeklyIncentiveTimeClick()"  [options]="days" (onChange)="joinDays(incentiveData.endDay, i)" [(ngModel)]="incentiveData.endDay" optionLabel="day"></p-dropdown>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2">
                                                <ng-container class="col-md-3 pull-right" style="margin :0 30px 0 30 px" *ngFor="let data of incentiveData | keyvalue">
                                                  <div class="row" *ngIf="(data.key !== 'days') && (data.key !== 'startDay') && (data.key !== 'endDay') && (data.key !== 'day')">
                                                      <div *ngIf="(data.key !== '1') && (data.key !== 'days') && (data.key !== 'startDay') && (data.key !== 'endDay') && (data.key !== 'day')" class="subset-divider"></div>
                                                      <!--Time Slot-->
                                                      <div class="col-md-12 border-left border-right padding-bottom-8" *ngIf="((data.key !== 'days') && (data.key !== 'startDay') && (data.key !== 'endDay') && (data.key !== 'day'))">
                                                        <div class="row  margin-top-10" style="padding: 0px 7px;">
                                                        </div>
                                                            <ng-container *ngFor="let goals of data.value | keyvalue">
                                                                <div *ngIf="goals.key === 'timeSlot'">
                                                                    <ng-container  *ngFor="let time of goals.value;let timeIndex = index">
                                                                        <div class="row">
                                                                            <div class="margin-top-17">
                                                                                <p class="preview-text font-weight">Start Time </p>
                                                                                <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings" placeholder="hour" [options]="hours" [(ngModel)]="goals.value[timeIndex].fromTimeHour"  (onChange)="weeklyTimeSlotChanged(i, data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                                                <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings" placeholder="min" [options]="startMinutes" [(ngModel)]="goals.value[timeIndex].fromTimeMin" (onChange)="weeklyTimeSlotChanged(i, data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                                            </div>
                                                                            <div class="margin-top-17"  style="padding: 0px 5px">
                                                                                <p class="preview-text font-weight">End Time </p>
                                                                                <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings" placeholder="hour" [options]="hours" [(ngModel)]="goals.value[timeIndex].toTimeHour" (onChange)="weeklyTimeSlotChanged(i, data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                                                <p-dropdown appendTo="body" [disabled]="NOT_EDITABLE.timings"  placeholder="min" [options]="endMinutes" [(ngModel)]="goals.value[timeIndex].toTimeMin" (onChange)="weeklyTimeSlotChanged(i, data.key, timeIndex)" optionLabel="value"></p-dropdown>
                                                                            </div>
                                                                            <div class="margin-top-36" [hidden]="timeIndex === 0">
                                                                                <div class="trash-container">
                                                                                    <div class="trash-border">
                                                                                      <i class="pi pi-trash trash" (click)="deleteWeeklyTimeSlot(i, data.key, timeIndex)"></i>
                                                                                    </div>
                                                                                  </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                      </div>
                                                  </div>
                                                </ng-container>
                                              </div>
                                               <!--Goals-->
                                              <div class="col-md-7" *ngFor="let data of incentiveData | keyvalue">
                                                  <div class="row" *ngIf="(data.key !== 'days') && (data.key !== 'startDay') && (data.key !== 'endDay') && (data.key !== 'day')">
                                                    <div class="col-md-12" *ngIf="data.key !== 'days'">
                                                      <div class="inline-content">
                                                        Captain has to do
                                                        <div *ngIf="selectedVariable.indexOf('order') !== -1">
                                                          <span class="bold">{{totalOrdersMap.get(i.toString())}}</span> orders
                                                        </div>
                                                        <div *ngIf="(selectedVariable.length > 2 ) || (selectedVariable.length > 1 && selectedVariable.indexOf('quality') ===-1) ">
                                                          &nbsp;{{condition}}&nbsp;
                                                        </div>
                                                        <div *ngIf="selectedVariable.indexOf('distance') !== -1">
                                                          <span class="bold">{{totalDistanceMap.get(i.toString())}}</span>  kms
                                                        </div>
                                                        to earn a total of <span class="bold">{{totalAmountMap.get(i.toString())}}</span> rupees 
                                                      </div>
                                                      <ng-container *ngFor="let goals of data.value | keyvalue">
                                                          <div *ngIf="goals.key === 'rules'">
                                                            <ng-container *ngFor="let rule of goals.value;let ruleIndex = index">
                                                                <div class="row padding-0-7">
                                                                </div>
                                                                <div class="row z-index-10 padding-0-7">
                                                                  <div class="margin-top-17"  *ngIf="selectedVariable.indexOf('order') !== -1">
                                                                      <p class="preview-text font-weight"> Order# </p>
                                                                      <input [disabled]="incentiveData.disabled" class="width-75 border" type="number"
                                                                      min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                      pInputText (ngModelChange)="onOrderValueChange(goals.value[ruleIndex])" (change)="reComputeTotalOrders(i.toString(), goals.value)" [(ngModel)]="goals.value[ruleIndex].order"/>
                                                                  </div>
                                                                  <div class="margin-top-36 margin-left-14 align-goals" *ngIf="(selectedVariable.length > 1 && selectedVariable.indexOf('quality') == -1) || selectedVariable.length > 2">
                                                                    <p class="preview-text">{{ condition }}</p>
                                                                  </div>
                                                                  <div class="margin-top-17" *ngIf="selectedVariable.indexOf('distance') !== -1">
                                                                      <p class="preview-text font-weight"> Distance# </p>
                                                                      <input [disabled]="incentiveData.disabled" class="width-75 border" type="number"
                                                                      min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                      pInputText (change)="reComputeTotalDistance(i.toString(), goals.value)" [(ngModel)]="goals.value[ruleIndex].distance"/>
                                                                  </div>
                                                                  <div class="margin-top-36 margin-left-14 align-goals">
                                                                      <p class="preview-text">=</p>
                                                                  </div>
                                                                  <div class="margin-top-17" *ngIf="selectedVariable.length !== 0">
                                                                      <p class="preview-text font-weight"> Amount </p>
                                                                      <span class="input-euro-daily left">
                                                                          <input [disabled]="incentiveData.disabled" class="width-75 border" type="number"
                                                                          min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                          pInputText (change)="reComputeTotalAmount(i.toString(), goals.value)" [(ngModel)]="goals.value[ruleIndex].amount"/>
                                                                      </span>
                                                                      <p *ngIf="goals.value[ruleIndex].amount >= ruleWarnAmountLimit && goals.value[ruleIndex].amount < ruleMaxAmountLimit" class="warning-text">
                                                                        High Amount!
                                                                      </p>
                                                                      <p *ngIf="goals.value[ruleIndex].amount >= ruleMaxAmountLimit" class="max-limit-reached-text">
                                                                        Max limit reached!
                                                                      </p>
                                                                  </div>

                                                                  <div class="margin-top-48" style="margin-left:10px">
                                                                    <mat-checkbox [disabled]="NOT_EDITABLE.variables"  (change)="addVariables('quality', $event)" [checked]="qualityChecked"></mat-checkbox>
                                                                    <label *ngIf="qualityChecked === false">Add Qualitative Metric</label>
                                                                  </div>

                                                                  <div class="margin-top-17  padding-left-0" *ngIf="selectedVariable.indexOf('quality') !==-1">
                                                                    <p class="preview-text font-weight">
                                                                      Qualitative Metric
                                                                    </p>
                                                                    <ng-select [items]="qualitativeMetrics" [(ngModel)]="goals.value[ruleIndex].quality.metric" (change)="setMetricValue(goals.value[ruleIndex].quality.metric)" class="width-85 border"> </ng-select>
                                                                  </div>

                                                                  <div class="margin-top-17 padding-left-0" *ngIf="selectedVariable.indexOf('quality') !==-1">
                                                                    <p class="preview-text font-weight">
                                                                      Percentage
                                                                    </p>
                                                                    <span class="input-percent right">
                                                                      <input class="width-85 border" [disabled]="NOT_EDITABLE.goals" type="number"
                                                                      min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                      pInputText [(ngModel)]="goals.value[ruleIndex].quality.value"/>
                                                                  </span>
                                                                  </div>

                                                                  <div class="margin-top-36 margin-left-14 align-goals" *ngIf="selectedVariable.indexOf('quality') !==-1">
                                                                    <p class="preview-text">=</p>
                                                                  </div>
                                                                  <div class="margin-top-17" *ngIf="selectedVariable.indexOf('quality') !==-1">
                                                                    <p class="preview-text font-weight">
                                                                      Amount
                                                                    </p>
                                                                    <span class="input-euro-daily left">
                                                                        <input class="width-85 border" [disabled]="NOT_EDITABLE.goals" type="number"
                                                                        min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                        pInputText (change)="reComputeTotalAmount(i.toString(), goals.value)" [(ngModel)]="goals.value[ruleIndex].quality.amount"/>
                                                                    </span>
                                                                  </div>
                                                                  <div class="margin-top-36 align-goals" *ngIf="selectedVariable.length !== 0 && totalAmountMap.get(i.toString()) < setMaxAmountLimit">
                                                                      <button *ngIf="!NOT_EDITABLE.goals" class="ui-button-secondary sms-template" pButton type="button" (click)="addWeeklyGoals(i, data.key, ruleIndex + 1)"  icon="pi pi-plus">
                                                                      </button>
                                                                  </div>
                                                                  <div class="margin-top-36" [hidden]="ruleIndex === 0">
                                                                      <div class="trash-container">
                                                                          <div class="trash-border" *ngIf="!NOT_EDITABLE.goals">
                                                                            <i class="pi pi-trash trash" (click)="deleteWeeklyGoals(i, data.key, ruleIndex, goals.value)"></i>
                                                                          </div>
                                                                        </div>
                                                                  </div>
                                                                </div>
                                                              </ng-container>
                                                          </div>
                                                      </ng-container>
                                                      <p *ngIf="totalAmountMap.get(i.toString()) > setWarnAmountLimit && totalAmountMap.get(i.toString()) < setMaxAmountLimit" class="warning-total-text">
                                                        High total amount!
                                                      </p>
                                                      <p *ngIf="totalAmountMap.get(i.toString()) > setMaxAmountLimit" class="max-limit-total-reached-text">
                                                        Max amount for set exceeded!
                                                      </p>  
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <!--Creates Subset-->
                                          <!-- <div class="row ">
                                              <div class="col-md-3"></div>
                                              <div class="col-md-9">
                                                <div class="row">
                                                    <div class="col-md-6 border-right border-left padding-bottom-8">
                                                        <span class="rapido-form-field">
                                                            <button  class="ui-button-secondary sms-template" (click)="addSubset(i)" pButton type="button" icon="pi pi-plus" label="Create SubSet"></button>
                                                          </span>
                                                    </div>
                                                </div>
                                              </div>
                                          </div> -->
                                      </div>
                                  </p-accordionTab>
                                </p-accordion>
                              </div>
                        </div>
                        <div class="row margin-2">
                            <div class="col-md-3 margin-top-17">
                              <span class="rapido-form-field">
                                <button
                                *ngIf="!NOT_EDITABLE.weeklyDays"
                                [disabled]="selectedVariable.length === 0"
                                class="ui-button-secondary sms-template" pButton
                                type="button" (click)="addSet()" icon="pi pi-plus" label="Create Set"></button>
                              </span>
                            </div>
                        </div>
                    </p-scrollPanel>
                </div>
            </div>

            <div class="row margin-0">
                <div class="margin-top-5 divider"></div>
            </div>

            <!--Back and Next button-->
            <div class="row margin-bottom-20">
                <div class="pull-right">
                    <span class="rapido-form-field">
                        <button class="back-button" (click)="goBack()" type="button">
                          Back
                        </button>
                      </span>
                    <span class="rapido-form-field padding-right-24">
                        <button mat-raised-button class="next-button" color="primary" type="button" (click)="passGoalsInfo()">Next</button>
                      </span>
                </div>
            </div>

        </div>

      </div>
    </div>

    <!-- Redeem Incentive -->
    <form [formGroup]="goalsFormGroup">
      <div class="row" *ngIf="incentiveData.basicInfo && incentiveData.basicInfo.incentiveType === 'Redeem'">
          <div class="col-md-12">
            <div class="condition-card shadow-all">
                <app-header [header]="header"></app-header>
                <div class="row margin-left-2">
                    <div class="col-md-4">
                      <p class="preview-text font-weight">Conditions</p>
                      <div class="ui-g">
                        <div class="ui-g-12 condition-padding">
                          <div class="ui-g-5">
                            <p-radioButton (onClick)="conditionChange('flat')" name="group1" value="flat" label="Flat" formControlName="selectedCondition"></p-radioButton>
                          </div>
                          <div class="ui-g-6">
                            <p-radioButton (onClick)="conditionChange('percentage')" name="group1" value="percentage" label="Percentage (%)" formControlName="selectedCondition"></p-radioButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="(goalsFormGroup.get('selectedCondition').dirty && goalsFormGroup.get('selectedCondition').invalid)" class="error">
                      Condition required.
                  </p>
            </div>

            <div class="goals-card shadow-all margin-top-6">
                <div class="row margin-2">
                    <div class="col-md-4">
                      <p class="preview-text">Select Redeem amt & Bonus amt</p>
                    </div>
                </div>

                <div class="row padding-left-20 padding-right-20">
                    <div class="divider"></div>
                </div>

                <div class="redeem">
                    <div class="row margin-left-2">
                        <div class="col-md-6" formArrayName="redeemGoals">
                          <div *ngFor="let redeemObj of redeemIncentives.controls;let i = index" [formGroupName]="i">
                          <div class="row padding-left-12">
                            <div class="col fit-content">
                                <div class="row">
                                    <p class="preview-text font-weight">Redeem Amt</p>
                                </div>
                                <div class="row padding-0-10">
                                    <span class="input-euro left">
                                        <input class="width-85 height-36" id="redeem" type="number" pInputText formControlName="redeemAmount"/>
                                      </span>
                                </div>
                            </div>
                            <div class="col-md-1 line-height-8"> = </div>
                            <div class="col fit-content" *ngIf="redeemCondition === 'flat'">
                                <div class="row">
                                    <p class="preview-text font-weight">Bonus Amt</p>
                                </div>
                                <div class="row padding-0-10">
                                    <span class="input-euro left">
                                        <input class="width-85 height-36" id="bonus" type="number" pInputText formControlName="bonusAmount" />
                                    </span>
                                </div>
                            </div>
                            <div class="col fit-content" *ngIf="redeemCondition === 'percentage'">
                                <div class="row">
                                    <p class="preview-text font-weight">Bonus Percentage</p>
                                </div>
                                <div class="row padding-0-10">
                                    <input class="width-85 height-36" id="bonus" type="number" pInputText formControlName="bonusPercentage"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf="i > 0">
                              <div class="trash-container margin-top-38">
                                  <div class="trash-border">
                                    <i class="pi pi-trash trash" (click)="deleteRedeemSet(i)"></i>
                                  </div>
                                </div>
                            </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div class="row  margin-left-2">
                        <div class="padding-left-24">
                          <span class="rapido-form-field">
                            <button class="ui-button-secondary sms-template" pButton type="button" (click)="addRedeemSet()" icon="pi pi-plus" label="Create Set">
                            </button>
                          </span>
                        </div>
                    </div>
                </div>

                <div class="row padding-left-20 padding-right-20">
                    <div class="margin-top-5 divider"></div>
                </div>

                <!--Back and Next button-->
                <div class="row margin-bottom-20">
                  <div class="pull-right">
                      <span class="rapido-form-field">
                          <button class="back-button" (click)="goBack()" type="button">
                            Back
                          </button>
                      </span>
                    <span class="rapido-form-field padding-right-24">
                      <button mat-raised-button class="next-button" color="primary" (click)="passGoalsInfo()" type="button">
                        Next
                      </button>
                    </span>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </form>

    <!-- Redeem Incentive -->

    <div class="popup incentiveNamePopup popupStyle">
      <span class="helper"></span>
      <div class="popupIncentives">
        <div class="popupCloseButton" (click)="hidePopup()">&times;</div>
        <p class= "contentStyle">The overlapping incentive/s for the same city, service, and time-bucket are</p>
        <div class="container">
          <table class="table table-striped table-bordered outerTable">
              <thead>
                  <tr>
                      <th>Incentive name</th>
                      <th>User selector</th>
                      <th>Timings & Constructs</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let incentive of errorPopupIncentives">
                      <td class = "displayStyle">{{incentive.incentiveName}}</td>
                      <td class = "displayStyle">{{incentive.ruleName}}</td>
                      <td class="constructStyle">
                          <table class="table table-striped table-bordered">
                            <tbody>
                                <tr *ngFor="let goal of incentive.goals">
                                  <td>
                                    <span *ngFor="let timeSlot of goal.timeSlots">
                                      <div class = "timeStyle">
                                        {{timeSlot.fromTime}} - {{timeSlot.toTime}}
                                      </div>
                                    </span>
                                  </td>
                                  <td class="cancellationStyle" *ngIf="incentive.cancellationLimit">Cancellation Limit : {{incentive.cancellationLimit}}</td>
                                  <td class="cancellationStyle" *ngIf="goal.cancellationLimit">Cancellation Limit : {{goal.cancellationLimit}}</td>
                                  <td class="cancellationStyle" *ngIf="incentive.maxOrderDistance">Max Order Distance : {{incentive.maxOrderDistance}}</td>
                                  <td class="cancellationStyle" *ngIf="goal.maxOrderDistance">Max Order Distance : {{goal.maxOrderDistance}}</td>
                                  <td class="ruleStyle">
                                    <span *ngFor="let rule of goal.rules">
                                        <div>
                                        <span *ngIf="rule.order">{{rule.order}} order(s) </span>
                                        <span *ngIf="(rule.order && rule.distance && incentive.selectedCondition == '&&'); "> and </span>
                                        <span *ngIf="(rule.order && rule.distance && incentive.selectedCondition == '||'); "> or </span>
                                        <span *ngIf="rule.distance"> {{rule.distance}} km(s)</span>
                                        <span> = &#x20b9;  {{rule.amount}}</span>
                                        </div>
                                    </span>
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      </div>
    </div>

    <div class="popup incentiveBucketsPopup">
      <span class="helper"></span>
      <div class="popupTimeslots">
        <div class="popupCloseButton" (click)="hidePopup()">&times;</div>
        <p>Here are the time buckets</p>
        <ul style="display: flex; flex-direction: column; align-items: start">
          <li *ngFor="let timeBucket of dailyIncentiveTimeBuckets">
            {{timeBucket.name}} : {{formatTimeStringto12H(timeBucket.fromTime)}} - {{formatTimeStringto12H(timeBucket.toTime)}}
          </li>
        </ul>
        <p>
          Within these time buckets, a captain can have only one single incentive for any given service. If there are multiple incentives for the same service, then the highest priority incentive will be assigned. If you are planning to assign multiple incentives for the same service to captains in a day, make sure the individual incentive sets are within these time buckets. You can still go ahead and set incentives across multiple time buckets. In this case, if there are overlapping incentives, then the highest priority incentive will be assigned.
        </p>
      </div>
    </div>
    <!--Popup-->
  </div>
</div>
