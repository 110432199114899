import { Component, OnInit, Output, EventEmitter, Input, ViewChild, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../shared/shared.service';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import * as moment from 'moment';

import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { environment } from 'src/environments/environment';
import { ViewIncentivesService } from 'src/app/view-incentives/view-incentives.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css'],
  providers: [DatePipe, ToastMessage]
})
export class BasicInfoComponent implements OnInit {

  public header: any = 'Basic Info';
  public incentiveType = this.sharedService.INCENTIVE_TYPES;
  public fromDate: Date;
  public toDate: Date;
  public maxDate: Date;
  public minDate: Date;
  public priority: number;
  public cities: any;
  public services: any;
  public serviceNames: any;
  public serviceType: any = [];
  public rules = [];
  public ruleSelector = [];
  public shifts: any;
  public tempServices: any;
  public tempShifts: any;
  public nextClicked = false;
  public ruleObj = { city: false, service: false, shift: false };
  public isCancellationLimitEnabledForCity: any = false;
  public isMaxOrderDistanceEnabledForCity: any = false;
  public adhocIncentiveNames: string[] = environment.adhocIncentiveNames;
  public blockServicesForIncentiveCreation = new Set(environment.blockServicesForIncentiveCreation);
  public adHocIncentive: boolean = false;
  public selectedRuleUserCount = null;
  public currentDate: Date = new Date();
  @ViewChild("HHIncentiveAlertDialog") HHIncentiveAlertDialog: TemplateRef<any>;
  public errorPopupIncentives = [];
  public segmentType = this.sharedService.SEGMENT_TYPES;
  public selectedCity = "";
  public selectedService = "";

  @Input() NOT_EDITABLE = {
    incentiveName: false,
    incentiveDisplayName: false,
    selectedIncentive: false,
    selectedCity: false,
    selectedService: false,
    isHHIncentive: false,
    selectedShift: false,
    selectedRule: false,
    fromDate: false,
    toDate: false,
    priority: false,
    identifier: false,
    selectedSegment: false,
  };
  @Output() basicInfo: EventEmitter<any> = new EventEmitter();
  @Output() basicInfoDataChange: EventEmitter<any> = new EventEmitter();
  @Input() defaultValue: any;
  @Input() state: string;

  private incentiveDisplayNameFormControl = new FormControl(null, Validators.required);

  basicInfoFormGroup = new FormGroup({
    incentiveName: new FormControl('', Validators.required),
    incentiveDisplayName: this.incentiveDisplayNameFormControl,
    selectedIncentive: new FormControl(null, Validators.required),
    selectedCity: new FormControl(null, Validators.required),
    selectedService: new FormControl('', Validators.required),
    isHHIncentive: new FormControl(false, Validators.required),
    selectedShift: new FormControl([], Validators.required),
    selectedRule: new FormControl({value: null, disabled: false}, Validators.required),
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required),
    priority: new FormControl('', Validators.required),
    identifier: new FormControl('', Validators.required),
    selectedSegment: new FormControl(null, Validators.required),
  });

  constructor(private datePipe: DatePipe,
    private sharedService: SharedService,
    public toasterService: ToasterService,
    private loaderService: LoaderService,
    private router: Router,
    private incentiveService: ViewIncentivesService,
    private dialog: MatDialog) { }

  isDisabled(disable) {
    return disable ? '' : null;
  }

  makeFieldsDisable() {
    // this.basicInfoFormGroup.get('incentiveName').disable()
    Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
      const disable = this.NOT_EDITABLE[fieldName];
      if (disable) {
        try {
          this.basicInfoFormGroup.get(fieldName).disable();
        } catch (err) { }
      }
    });
  }

  getMinEditableDate(){
    if (this.currentDate < this.minDate){
      return this.minDate;
    }
    return this.currentDate;
  }

  patchDefaultValue(defaultValue) {
    this.loaderService.openLoading();
    let fieldValues = this.fetchSegmentNameAndIdentifierName(defaultValue.incentiveName);

    this.basicInfoFormGroup.patchValue({
      incentiveName: fieldValues.incentiveName,
      selectedIncentive: defaultValue.incentiveType,
      fromDate: new Date(defaultValue.startDate),
      toDate: new Date(defaultValue.endDate),
      priority: defaultValue.priority,
      selectedSegment: fieldValues.segmentName,
      identifier: fieldValues.identifierName
    });
    this.incentiveDisplayNameFormControl.patchValue(defaultValue.incentiveDisplayName || null);
    if (defaultValue.selectedVariable.indexOf("cancellation") != -1) {
      this.isCancellationLimitEnabledForCity = true;
    }
    if (defaultValue.selectedVariable.indexOf("maxOrderDistance") != -1) {
      this.isMaxOrderDistanceEnabledForCity = true;
    }

    if(defaultValue.tags) {
      this.basicInfoFormGroup.patchValue({
        isHHIncentive: defaultValue.tags.includes("HH"),
      })
    }
    this.changeInDate();
    this.cityChangeCheck(defaultValue.cities[0])
      .then(() => this.serviceChangeCheck(defaultValue.serviceType))
      .then(() => this.getRules())
      .then(() => this.ruleChangeCheck(defaultValue.ruleId))
      .then(() => {
        const data = this.formatData();
        this.basicInfoDataChange.emit(data);
        this.loaderService.closeLoading();
        // this.passBasicInfo();
      })
      .catch(err => {
        this.loaderService.closeLoading();
      });
  }

  fetchSegmentNameAndIdentifierName(finalName){
    let splitArray = finalName.split("_");
    let result = {
      segmentName: "",
      identifierName: "",
      incentiveName: ""
    }
    try {
      const segmentAndIncentiveName = splitArray[3];
      const segment = segmentAndIncentiveName.split(" ");

      if (splitArray[0] == "ALLOCATOR") {  // Naming convention of iAllocator incentives -> ALLOCATOR_${ cityName }_${ segment.label }_${ shortStartDate }_${ shortEndDate }_${ incentiveType }
        const segment = splitArray.slice(2, -3).join(' ')
        result.segmentName = segment;
        result.identifierName = "iAllocator";
        result.incentiveName = segment;
      }
      else if(splitArray[0] == "MANUAL" && segment && segment[0] == "CUSTOM"){
        result.segmentName = "CUSTOM";
        result.identifierName = splitArray[7];
        result.incentiveName = segmentAndIncentiveName.substr(7);
      }
      else if(splitArray[0] == "MANUAL"){
        result.segmentName = segmentAndIncentiveName;
        result.identifierName = splitArray[7];
        result.incentiveName = segmentAndIncentiveName;
      }
      else{
        result.segmentName = "CUSTOM";
        result.incentiveName = finalName;
        result.identifierName = "identifier";
      }
    }
    catch (err) {
      result.segmentName = "CUSTOM";
      result.incentiveName = finalName;
      result.identifierName = "identifier";
    }
    return result;
  }

  private async cityChangeCheck(selectedCity) {
    if (this.cities) {
      const city = this.cities.find(city => city.displayName === selectedCity);
      this.basicInfoFormGroup.patchValue({
        selectedCity: city,
      });
      this.checkCityEnabledForCancellationLimit(city);
      this.checkCityEnabledForMaxOrderDistance(city);
      await this.onCityChange();
    }
  }

  private ruleChangeCheck(selectedRuleId) {
    if (this.getValues("isHHIncentive")) {
      this.basicInfoFormGroup.get("selectedRule").disable();
      this.basicInfoFormGroup.patchValue({
        selectedRule: null,
      });
      return;
    }
    if (this.rules && this.rules.length) {
      const rule = this.ruleSelector.find(sel => sel._id === selectedRuleId);
      if (rule && rule.name) {
        this.basicInfoFormGroup.patchValue({
          selectedRule: rule.name,
        });
      }
    }
  }

  private serviceChangeCheck(selectedServiceIds) {
    if (this.services.length) {
      const selectedServicesObject = [];
      selectedServiceIds.forEach(selectedServiceId => {
        const service = this.services.find(service => service._id === selectedServiceId);
        if (service) {
          selectedServicesObject.push(service);
        }
      });
      this.basicInfoFormGroup.patchValue({
        selectedService: selectedServicesObject,
      });
    }
  }

  private shiftChangeCheck(selectedShifts) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (this.shifts.length) {
          this.basicInfoFormGroup.patchValue({
            selectedShift: selectedShifts,
          });
          // const city = this.shifts.find(city => city.displayName === selectedCity);
          // this.basicInfoFormGroup.patchValue({
          //   selectedCity: city,
          // });
          // this.onCityChange();
          clearInterval(interval);
          return resolve({});
        }
      }, 100);
    });
  }


  compareCityFn(cityOne, cityTwo) {
    return cityOne.displayName === cityTwo.displayName;
  }

  compareServiceFn(serviceOne, serviceTwo) {
    return serviceOne._id === serviceTwo._id;
  }

  compareShiftFn(shiftOne, shiftTwo) {
    return shiftOne === shiftTwo;
  }

  compareRuleFn(shiftOne, shiftTwo) {
    return shiftOne === shiftTwo;
  }

  async onCityChange() {
    await this.getCityServices(this.basicInfoFormGroup.get('selectedCity'));
    // this.getShifts(this.basicInfoFormGroup.get('selectedCity'));
  }

  async ngOnInit() {
    this.makeFieldsDisable();
    await this.getCities();
    switch (this.state) {
      case this.sharedService.STATE.EDIT: {
        this.patchDefaultValue(this.defaultValue);
        this.incentiveTypeSelected();
        break;
      }
      case this.sharedService.STATE.DUPLICATE: {
        this.patchDefaultValue(this.defaultValue);
        this.incentiveTypeSelected();
        break;
      }
    }
    this.basicInfoFormGroup.get('selectedCity').valueChanges.subscribe(data => {
      this.checkCityEnabledForCancellationLimit(data);
      this.checkCityEnabledForMaxOrderDistance(data);
      this.basicInfoFormGroup.get('selectedRule').setValue(null);
    });
    this.basicInfoFormGroup.get('selectedService').valueChanges.subscribe(data => {
      this.basicInfoFormGroup.get('selectedRule').setValue(null);
    });
    this.basicInfoFormGroup.get('selectedShift').valueChanges.subscribe(data => {
      this.basicInfoFormGroup.get('selectedRule').setValue(null);
    });
    this.basicInfoFormGroup.get('selectedRule').valueChanges.subscribe(data => {
      this.ruleSelected();
    });
  }

  changeInDate() {
    if (this.getValues('fromDate')) {
      this.minDate = this.getValues('fromDate');
    }
    if (this.getValues('toDate')) {
      this.maxDate = this.getValues('toDate');
    }
  }

  async getCities() {
    await this.sharedService.fetchCities().toPromise().then(response => {
      this.cities = response['data'].cities;
      this.ruleObj['service'] = false;
      this.ruleObj['shift'] = false;
      this.ruleObj['city'] = true;
      for (const index in this.cities) {
        if (this.cities[index].displayName === 'All') {
          this.cities.splice(index, 1);
        }
      }
    }, error => {
      console.log('Error : ', error);
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching cities`,
      }));
    });
  }

  async getCityServices(cityControl) {
    let city = cityControl.value;
    if (!city) {
      return;
    }
    try {
      this.services = [];
      this.basicInfoFormGroup.patchValue({ selectedService: '' });
      const cityId = city._id;
      const response = await this.sharedService.fetchServices(cityId).toPromise() as any;
      const services = response.data || [];
      if (this.state == this.sharedService.STATE.EDIT) {
        this.services = services.filter(dat => {
          if (dat.service) {
            return dat.service = dat.service || [];
          }
        });
      } else {
        this.services = services.filter(dat => {
          if (dat.service && !this.blockServicesForIncentiveCreation.has(dat.service._id)) {
            return dat.service = dat.service || [];
          }
        });
      }
      this.serviceNames = [];
      for (const service of this.services) {
        this.serviceNames[service._id] = service.service.name;
      }
    } catch (e) {
      console.log(e);
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching city services`,
      }));
    } finally {
      this.loaderService.closeLoading();
    }
  }

  getShifts(cityControl) {
    let city = cityControl.value;
    if (city) {
      city = [city];
      this.shifts = [];
      this.basicInfoFormGroup.patchValue({ selectedShift: [] });
      this.tempShifts = [];
      let count = 0;
      let cityName = '';
      for (const i in city) {
        if (i) {
          for (const index in this.cities) {
            if (this.cities[index].displayName === city[i].displayName) {
              cityName = city[i].displayName;
              this.loaderService.openLoading();
              this.sharedService.fetchShifts(cityName).subscribe(response => {
                this.loaderService.closeLoading();
                count++;
                this.tempShifts.push(response['data']);
                if (count === city.length && city.length > 1) {
                  this.getIntersectionOfShifts(city.length);
                } else {
                  this.shifts = response['data'];
                }
              }, error => {
                console.log('Error : ', error);
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: `Error in fetching shifts`,
                }));
              });
            }
          }
        }
      }
    }
  }

  getIntersectionOfShifts(selectedCityCount) {
    this.shifts = [];
    const shiftMap = new Map();
    this.tempShifts.forEach(shifts => {
      shifts.forEach(shift => {
        if (shiftMap.has(shift)) {
          shiftMap.set(shift, shiftMap.get(shift) + 1);
        } else {
          shiftMap.set(shift, 1);
        }
      });
    });

    shiftMap.forEach((value, key) => {
      if (value === selectedCityCount) {
        this.shifts.push(key);
      }
    });
  }

  getIntersectionOfServices() {
    this.services = [];
    const serviceMap = {};
    this.tempServices.forEach(cities => {
      cities.forEach(cityService => {
        if (serviceMap[cityService.service._id]) {
          serviceMap[cityService.service._id].push(cityService);
        } else {
          serviceMap[cityService.service._id] = [cityService];
        }

        if (serviceMap[cityService.service._id].length === this.tempServices.length) {
          this.services.push(serviceMap[cityService.service._id][0]);
        }
      });
    });

    for (var svc in this.services) {
      this.serviceNames[this.services[svc].service._id] = this.services[svc].service.name;
    }
  }

  checkPriority() {
    if (this.getValues('priority') && this.getValues('priority') < 0) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Priority should be greater than 0`,
      }));
    }
  }

  checkRuleObj() {
    if (this.ruleObj['city'] && this.ruleObj['service'] && !this.getValues('isHHIncentive')) {
      this.getRules();
    }
  }

  serviceSelected() {
    if (this.getValues('selectedService').length > 0) {
      this.ruleObj['service'] = true;
    } else {
      this.rules = [];
      this.ruleObj['service'] = false;
    }
  }

  enableAdhocIncentiveDisplayName() {
    this.adHocIncentive = this.isAdhocIncentive();
    if (this.adHocIncentive) {
      this.basicInfoFormGroup.addControl("incentiveDisplayName", this.incentiveDisplayNameFormControl);
    } else {
      this.basicInfoFormGroup.removeControl('incentiveDisplayName');
    }
  }

  incentiveTypeSelected() {
    const today = moment().utcOffset("+0530").toDate();

    this.basicInfoFormGroup.get('isHHIncentive').enable();
    if (!this.isDailyIncentive()) {
      this.basicInfoFormGroup.get('isHHIncentive').setValue(false);
      this.basicInfoFormGroup.get('isHHIncentive').disable();
    }
    this.enableAdhocIncentiveDisplayName();
    if (this.isAdhocIncentive()) {
      this.basicInfoFormGroup.get('fromDate').setValue(today);
      this.basicInfoFormGroup.get('toDate').setValue(today);
      this.basicInfoFormGroup.get('fromDate').disable();
      this.basicInfoFormGroup.get('toDate').disable();
      this.basicInfoFormGroup.get('priority').setValue(0);
      return;
    }

    if (!this.NOT_EDITABLE.fromDate)
      this.basicInfoFormGroup.get('fromDate').enable();
    if (!this.NOT_EDITABLE.toDate)
      this.basicInfoFormGroup.get('toDate').enable();
    if (this.NOT_EDITABLE.isHHIncentive)
      this.basicInfoFormGroup.get('isHHIncentive').disable();
  }
  private isDailyIncentive() {
    const selectedType = this.getValues('selectedIncentive') || [];
    return selectedType.includes('Daily');
  }

  private isAdhocIncentive() {
    const selectedType = this.getValues('selectedIncentive') || [];
    return selectedType.includes('Adhoc');
  }

  shiftSelected() {
    if (this.getValues('selectedShift').length > 0) {
      this.ruleObj['shift'] = true;
    } else {
      this.rules = [];
      this.ruleObj['shift'] = false;
    }
  }

  async getRules() {
    this.loaderService.openLoading();
    try {
      let shift, service, city, userType = 'rider';
      let serviceId = [], cityId = [];
      let selectedCity = this.getValues('selectedCity');
      selectedCity = [selectedCity];
      const selectedService = this.getValues('selectedService');
      const selectedShift = this.getValues('selectedShift');
      for (const cityIndex in selectedCity) {
        if (selectedCity[cityIndex]) {
          cityId.push(selectedCity[cityIndex]._id);
        }
      }
      for (const serviceIndex in selectedService) {
        if (selectedService[serviceIndex]) {
          serviceId.push(selectedService[serviceIndex]._id);
        }
      }
      shift = selectedShift.join(',');
      city = cityId.join(',');
      service = serviceId.join(',');
      const response = await this.sharedService.fetchRules(city, service, shift, userType).toPromise() as any;
      this.rules = [];
      let result = response['data'].selectors;
      this.ruleSelector = response['data'].selectors;

      for (let index in result) {
        if (this.rules.indexOf(result[index].name) === -1) {
          this.rules.push(result[index].name);
        }
      }
    } catch {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching selectors`,
      }));
    } finally {
      this.loaderService.closeLoading();
    }
  }

  getValues(name: string) {
    return this.basicInfoFormGroup.get(name).value;
  }

  errorMessage(message: string) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: message,
    }));
  }

  getServiceName(service) {
    this.serviceType = [];
    for (var index in service) {
      if (index) {
        const serviceId = service[index]._id;
        if (!this.serviceType.includes(serviceId)) {
          this.serviceType.push(serviceId);
        }
      }
    }
  }

  goBack() {
    this.router.navigate(['/incentives']);
  }

  formatData() {
    const fromDate = this.getValues('fromDate');
    const toDate = this.getValues('toDate');
    const startDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
    const formattedCurrentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    if (!this.getValues('selectedIncentive')) {
      this.errorMessage("Select the type of incentive.");
      return;
    }
    if (!this.getValues('selectedSegment')) {
      this.errorMessage("Select the type of Segment.");
      return;
    }
    if (!this.getValues('incentiveName')) {
      this.errorMessage("Enter the incentive name.");
      return;
    }
    if (!this.getValues('identifier')) {
      this.errorMessage("Enter the identifier.");
      return;
    }
    if (this.state != this.sharedService.STATE.EDIT) {
      if (this.basicInfoFormGroup.get('incentiveName').invalid) {
        this.errorMessage("Only alphanumeric characters allowed for Incentive name.");
        return;
      }
      if (this.basicInfoFormGroup.get('identifier').invalid) {
        this.errorMessage("Only alphanumeric characters allowed for Identifier.");
        return;
      }
    }
    if (this.adHocIncentive && !this.getValues('incentiveDisplayName')) {
      this.errorMessage("Provide a display name for the incentive.");
      return;
    }
    if (!this.getValues('selectedCity')) {
      this.errorMessage("You need to select a city.");
      return;
    }
    if (!this.getValues('selectedService')) {
      this.errorMessage("You need to select at least one service.");
      return;
    }
    if (!this.getValues('isHHIncentive') && !this.getValues('selectedRule')) {
      this.errorMessage("Select a 'User Selector'.");
      return;
    }
    if (!this.minDate) {
      this.errorMessage("Provide the start date of the incentive.");
      return;
    }
    if (!this.maxDate) {
      this.errorMessage("Provide the end date of the incentive");
      return;
    }
    if(this.state != this.sharedService.STATE.EDIT && (endDate < startDate || startDate < formattedCurrentDate)){
      this.errorMessage("You cannot create an incentive in the past.");
      return;
    }
    if (!this.getValues('priority') && this.getValues('selectedIncentive') !== 'Adhoc') {
      this.errorMessage("You need to provide a priority for the incentive.");
      return;
    }

    this.getServiceName(this.getValues('selectedService'));
    const services = [];
    const cities = [];
    let cityValue = this.getValues('selectedCity');
    if (!(cityValue instanceof Array)) {
      cityValue = [cityValue];
    }
    const serviceValue = this.getValues('selectedService');
    for (const index in serviceValue) {
      if (serviceValue[index]) {
        services.push(serviceValue[index].service._id);
      }
    }
    for (const index in cityValue) {
      if (cityValue[index]) {
        cities.push(cityValue[index].displayName);
      }
    }
    const ruleSelectorId = this.getSelectedRuleSelectorId();
    if (this.getValues('selectedIncentive').includes('Weekly') && fromDate.getDay() !== 1) {
      this.basicInfoFormGroup.get('fromDate').setValue('');
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Start date should be monday`,
      }));
      return;
    }
    if (this.getValues('selectedIncentive').includes('Weekly') && toDate.getDay() !== 0) {
      this.basicInfoFormGroup.get('toDate').setValue('');
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `End date should be sunday`,
      }));
      return;
    }
    const data = {
      incentiveName: this.state == this.sharedService.STATE.EDIT ? this.defaultValue.incentiveName : this.buildFinalIncentiveName(),
      incentiveDisplayName: this.adHocIncentive ? this.getValues('incentiveDisplayName') : null,
      incentiveType: this.getValues('selectedIncentive'),
      city: cities,
      isCancellationLimitEnabledForCity: this.isCancellationLimitEnabledForCity,
      isMaxOrderDistanceEnabledForCity: this.isMaxOrderDistanceEnabledForCity,
      service: this.serviceType,
      shift: this.getValues('selectedShift'),
      ruleName: this.getValues('selectedRule'),
      ruleId: ruleSelectorId,
      startDate: startDate,
      endDate: endDate,
      priority: this.getValues('priority') || 0,
      serviceNames: this.serviceType.map(service => this.serviceNames[service]),
      submit: false,
      userSelectorCount: this.selectedRuleUserCount && this.selectedRuleUserCount.count,
      isHHIncentive: this.getValues("isHHIncentive"),
    };
    return data;
  }

  passBasicInfo() {
    this.nextClicked = true;
    let higherPriorityHHIncentives;
    const data = this.formatData();
    if (!data) {
      return
    }
    if (this.getValues("isHHIncentive") && this.state != this.sharedService.STATE.EDIT) {
      this.incentiveService.fetchIncentiveData({
        "filters": {
          "cities": data.city,
          "serviceType": data.service,
          "types": [
            "Daily"
          ],
          "startDate": data.startDate,
          "endDate": data.startDate,
          "active": true,
          "isHHIncentive": true,
        }
      }).subscribe(res => {
        higherPriorityHHIncentives = res.filter(incentive => this.getValues('priority') <= incentive.priority)
        if (higherPriorityHHIncentives.length > 0) {
          this.errorPopupIncentives = higherPriorityHHIncentives;
          this.dialog.open(this.HHIncentiveAlertDialog);
        }
        else if (data) {
          this.basicInfo.emit(data);
        }
      })
    }
    else if (data) {
      this.basicInfo.emit(data);
    }
  }

  checkCityEnabledForCancellationLimit(data) {
    
    if (environment.cancellationLimitEnabledCities.includes('All')) {
      this.isCancellationLimitEnabledForCity = true;
    }
    else if (data && environment.cancellationLimitEnabledCities.includes(data._id)) {
      this.isCancellationLimitEnabledForCity = true;
    } 
    else {
      this.isCancellationLimitEnabledForCity = false;
    }
  }

  checkCityEnabledForMaxOrderDistance(data) {
    if (environment.maxOrderDistanceEnabledCities.includes('All')) {
      this.isMaxOrderDistanceEnabledForCity = true;
    }
    else if (data && environment.maxOrderDistanceEnabledCities.includes(data._id)) {
      this.isMaxOrderDistanceEnabledForCity = true;
    } 
    else {
      this.isMaxOrderDistanceEnabledForCity = false;
    }
  }

  fetchRequiredDetails(){
    this.getCityServices(this.basicInfoFormGroup.get('selectedCity'));
    this.getShifts(this.basicInfoFormGroup.get('selectedCity'));
    this.checkCityEnabledForCancellationLimit(this.basicInfoFormGroup.get('selectedCity').value);
    this.checkCityEnabledForMaxOrderDistance(this.basicInfoFormGroup.get('selectedCity').value)
  }

  private getSelectedRuleSelectorId() {
    for (const index in this.ruleSelector) {
      if (this.ruleSelector[index].name === this.getValues('selectedRule')) {
        return this.ruleSelector[index]._id;
      }
    }
    return "";
  }

  ruleSelected() {
    this.selectedRuleUserCount = { loaded: false };
    const selectedRule = this.getSelectedRuleSelectorId();
    if (!selectedRule) {
      this.selectedRuleUserCount = null;
      return;
    }

    this.sharedService.getUserSelectorDetails(selectedRule)
      .subscribe((response: any) => {
        this.selectedRuleUserCount = { loaded: true, count: response.userCount };
      });
  }

  HHIncentiveChecked() {
    if (this.getValues("isHHIncentive")) {
      this.basicInfoFormGroup.get("selectedRule").disable();
      this.basicInfoFormGroup.patchValue({
        selectedRule: null,
      });
      return;
    }
    this.basicInfoFormGroup.get("selectedRule").enable();
  }

  segmentSelected() {
    if(this.getValues("selectedSegment") != "CUSTOM"){
      this.basicInfoFormGroup.patchValue({
        incentiveName: this.getValues("selectedSegment")
      });
      this.basicInfoFormGroup.get('incentiveName').disable();
    }
    else{
      this.basicInfoFormGroup.get('incentiveName').enable();
    }
  }

  buildCityShortName(){
    if(this.basicInfoFormGroup.get('selectedCity').value != null){
      return this.basicInfoFormGroup.get('selectedCity').value.shortName.toUpperCase();
    }
  }
  
  buildServiceName(){
    let services = this.basicInfoFormGroup.get('selectedService').value
    if(services.length > 0){
      let serviceNames = services[0].service.name.toUpperCase();
      for (let index = 1; index < services.length; index++) {
        serviceNames += " " + services[index].service.name.toUpperCase();
      }
      return serviceNames;
    }
  }

  buildIncentiveAndSegmentName(){
    if (!this.basicInfoFormGroup.get('selectedSegment').value) {
      return
    }
    else if (this.basicInfoFormGroup.get('selectedSegment').value && this.basicInfoFormGroup.get('incentiveName').value && this.basicInfoFormGroup.get('selectedSegment').value == "CUSTOM"){
      return `${this.basicInfoFormGroup.get('selectedSegment').value.trim().toUpperCase()} ${this.basicInfoFormGroup.get('incentiveName').value.trim().toUpperCase()}`
    }
    else {
      return this.basicInfoFormGroup.get('selectedSegment').value.trim().toUpperCase()
    }
  }

  buildFormatedFromDate(){
    if(this.basicInfoFormGroup.get('fromDate').value != ""){
      const fromDate = this.basicInfoFormGroup.get('fromDate').value;
      return this.datePipe.transform(fromDate, 'ddMMyyyy').trim();
    }
  }

  buildFormatedToDate(){
    if(this.basicInfoFormGroup.get('toDate').value != ""){
      const toDate = this.basicInfoFormGroup.get('toDate').value;
      return this.datePipe.transform(toDate, 'ddMMyyyy').trim();
    }
  }
  buildIncentiveType(){
    if(this.basicInfoFormGroup.get('selectedIncentive').value != null)
    return this.basicInfoFormGroup.get('selectedIncentive').value.toUpperCase();
  }

  buildIdentifier(){
    if(this.basicInfoFormGroup.get('identifier').value != null)
    return this.basicInfoFormGroup.get('identifier').value.trim().toUpperCase();
  }

  buildRuleName(){
    if(this.basicInfoFormGroup.get('selectedRule').value != null){
      return this.basicInfoFormGroup.get('selectedRule').value.trim()
    }
    else {
       return ""
    }
  }
  buildFinalIncentiveName(){
    return `MANUAL_${this.buildCityShortName()}_${this.buildServiceName()}_${this.buildIncentiveAndSegmentName()}_${this.buildFormatedFromDate()}_${this.buildFormatedToDate()}_${this.buildIncentiveType()}_${this.buildIdentifier()}_${this.buildRuleName()}`;
  }
  public openUserSelectorPage() {
    this.basicInfoFormGroup.get('selectedRule').setValue(null);
    window.open(environment.userSelectorCreationPageLink);
  }
}
