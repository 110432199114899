<div *ngIf="triggerForOldRules" class="secondary-container shadow-all">
  <label class="step-header">Preview</label>
  <div class="divider"></div>
  <div style="padding: 50px 20px;">
    <div class="padding-child-row-td-10">
      <div class="row p-container">
        <div class="col-md-12">
          <div class="row p-border-bottom pad-div">
            <ng-container *ngFor="let ruleBasicInfo of ruleBasicInfoUI">
              <div *ngIf="ruleBasicInfo.value" class="col-md-2">
                <p class="preview-text preview-text-color">{{ruleBasicInfo.title}}</p>
                <p class="preview-text preview-terms-color">{{ruleBasicInfo.value}}</p>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-md-4 p-border-right p-border-bottom pad-div">
              <div *ngIf="penaltyRule.userSelector">
                <div class="preview-text preview-text-color">User Selectors</div>
                <p class="preview-text preview-terms-color" *ngFor="let selector of penaltyRule.userSelector;">{{selector}}</p>
                <div class="preview-text preview-text-color">Priority</div>
                <p class="preview-text preview-terms-color">{{penaltyRule.priority}}</p>
              </div>
              <div *ngIf="!isGame()">
                <div class="preview-text preview-text-color">Time Slots</div>
                <div class="row" *ngFor="let timeSlot of penaltyRule.timeSlots; let i = index">
                  <div class="col-md-2">
                    Slot{{i+1}}
                  </div>
                  <div class="col-md-5 padding-10">
                    <span class="d-block preview-text preview-text-color">Start Time</span>
                    <span class="d-block preview-text">{{timeSlot.startTime}}</span>
                  </div>
                  <div class="col-md-5 padding-10">
                    <span class="d-block preview-text preview-text-color">End Time</span>
                    <span class="d-block preview-text">{{timeSlot.endTime}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 padding-0">
              <div class="row">
                <div *ngFor="let header of eventHeaders" class="col-md-4 p-border-right">
                  <div class="preview-text preview-text-color center-align">{{header}}</div>
                </div>
              </div>
              <div class="events-section">
                <div class="row" *ngFor="let event of campaignData.basicInfo?.eventsAndReasons">
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{eventName[event.event]}}</p>
                  </div>
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{event.key || 'N/A'}}</p>
                  </div>
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{event.values?.join(", ") || 'N/A'}}</p>
                  </div>
                </div>
              </div>

              <!-- single rule -->
              <div *ngIf="penaltyRule.rules.oldRules.length === 1">
                <div class="row" *ngFor="let rule of penaltyRule.rules.oldRules; let i = index;">
                  <div class="col-md-12 padding-0-30">
                    <div class="row p-border-bottom pad-div small-border-top">
                      <div class="col-md-12 accordian-header">
                        Rule {{i+1}}
                      </div>
                    </div>
                    <div class="row pad-div">
                      <div class="col rule-headers" *ngIf="!isGame() || !isStreakGame()">Start Limit</div>
                      <div class="col rule-headers" *ngIf="!isGame()">End Limit</div>
                      <div class="col rule-headers" *ngIf="isStreakGame()">Ride Count</div>
                      <div class="col rule-headers" *ngIf="isStreakGame()">Mimimum Days</div>
                      <div class="col rule-headers">Action</div>
                      <div class="col rule-headers">Notification Type</div>
                      <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>Amount</div>
                      <div class="col rule-headers" *ngIf='rule.action === "suspend"'>Suspension Hrs</div>
                      <div class="col rule-headers" *ngIf='rule.action === "deprioritize"'>Deprioritize Mins</div>
                      <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>Message</div>
                      <div class="col rule-headers" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>Message</div>
                      <div class="col rule-headers" *ngIf='rule.action == "suspend" && rule.blockRedeem == true '>Block Redeem Amount</div>
                    </div>
                    <div class="row pad-div">
                      <div class="col p-border-bottom rule-values">{{rule.startLimit}}</div>
                      <div class="col p-border-bottom rule-values" *ngIf="!isGame()">{{rule.endLimit}}</div>
                      <div class="col p-border-bottom rule-values" *ngIf="isStreakGame()">{{rule.workingDays}}</div>
                      <div class="col p-border-bottom rule-values">{{rule.action}}</div>
                      <div class="col p-border-bottom rule-values overflow-break-word">{{rule.notificationDisplay}}</div>
                      <div class="col p-border-bottom rule-values" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>{{rule.amount}}</div>
                      <div class="col p-border-bottom rule-values" *ngIf='rule.action === "suspend"'>{{rule.activateAfterHours}}</div>
                      <div class="col p-border-bottom rule-values" *ngIf='rule.action === "deprioritize"'>{{rule.prioritizeAfterMins}}</div>
                      <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>{{rule.message}}</div>
                      <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>{{rule.message}}</div>
                      <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'>{{rule.amount}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- multiple rules -->
              <div *ngIf="penaltyRule.rules.oldRules.length > 1">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel class="expansion-panel" *ngFor="let rule of penaltyRule.rules.oldRules; let i = index;">
                    <mat-expansion-panel-header class="accordian-header">
                      <mat-panel-title>
                        Rule {{i+1}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="padding-0">
                      <div class="row center-align">
                        <div class="col rule-headers">Start Limit</div>
                        <div class="col rule-headers">End Limit</div>
                        <div class="col rule-headers">Action</div>
                        <div class="col rule-headers">Notification Type</div>
                        <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>Amount</div>
                        <div class="col rule-headers" *ngIf='rule.action === "suspend"'>Suspension Hrs</div>
                        <div class="col rule-headers" *ngIf='rule.action === "deprioritize"'>Deprioritize Mins</div>
                        <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>Message</div>
                        <div class="col rule-headers" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>Message</div>
                        <div class="col rule-headers" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'>Block Redeem Amount</div>
                      </div>
                      <div class="row center-align">
                        <div class="col rule-values">{{rule.startLimit}}</div>
                        <div class="col rule-values">{{rule.endLimit}}</div>
                        <div class="col rule-values">{{rule.action}}</div>
                        <div class="col rule-values overflow-break-word">{{rule.notificationDisplay}}</div>
                        <div class="col rule-values" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>{{rule.amount}}</div>
                        <div class="col rule-values" *ngIf='rule.action === "suspend"'>{{rule.activateAfterHours}}</div>
                        <div class="col rule-values" *ngIf='rule.action === "deprioritize"'>{{rule.prioritizeAfterMins}}</div>
                        <div class="col rule-values overflow-break-word" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>{{rule.message}}</div>
                        <div class="col rule-values overflow-break-word" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>{{rule.message}}</div>
                        <div class="col rule-values overflow-break-word" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'>{{rule.amount}}</div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="pull-right">
          <span>
            <button class="back-button" type="button" (click)="goBack()">
              Back
            </button>
          </span>
          <span>
            <button mat-raised-button class="next-button" color="primary" type="button" (click)="onSubmit()">Finish</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!triggerForOldRules"  class="secondary-container shadow-all">
  <label class="step-header">Preview</label>
  <div class="divider"></div>
  <div style="padding: 50px 20px;">
    <div class="padding-child-row-td-10">
      <div class="row p-container">
        <div class="col-md-12">
          <div class="row p-border-bottom pad-div">
            <ng-container *ngFor="let ruleBasicInfo of ruleBasicInfoUI">
              <div *ngIf="ruleBasicInfo.value" class="col-md-2">
                <p class="preview-text preview-text-color">{{ruleBasicInfo.title}}</p>
                <p class="preview-text preview-terms-color">{{ruleBasicInfo.value}}</p>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-md-4 p-border-right p-border-bottom pad-div">
              <div *ngIf="penaltyRule.userSelector">
                <div class="preview-text preview-text-color">User Selectors</div>
                <p class="preview-text preview-terms-color" *ngFor="let selector of penaltyRule.userSelector;">{{selector}}</p>
                <div class="preview-text preview-text-color">Priority</div>
                <p class="preview-text preview-terms-color">{{penaltyRule.priority}}</p>
              </div>
              <div *ngIf="!isGame()">
                <div class="preview-text preview-text-color">Time Slots</div>
                <div class="row" *ngFor="let timeSlot of penaltyRule.timeSlots; let i = index">
                  <div class="col-md-2">
                    Slot{{i+1}}
                  </div>
                  <div class="col-md-5 padding-10">
                    <span class="d-block preview-text preview-text-color">Start Time</span>
                    <span class="d-block preview-text">{{timeSlot.startTime}}</span>
                  </div>
                  <div class="col-md-5 padding-10">
                    <span class="d-block preview-text preview-text-color">End Time</span>
                    <span class="d-block preview-text">{{timeSlot.endTime}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 padding-0">
              <div class="row">
                <div *ngFor="let header of eventHeaders" class="col-md-4 p-border-right">
                  <div class="preview-text preview-text-color center-align">{{header}}</div>
                </div>
              </div>
              <div *ngIf="campaignData.basicInfo" class="events-section">
                <div class="row" *ngFor="let event of campaignData.basicInfo.eventsAndReasons">
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{eventName[event.event]}}</p>
                  </div>
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{event.key || 'N/A'}}</p>
                  </div>
                  <div class="preview-column col-md-4">
                    <p class="preview-text">{{event.values?.join(", ") || 'N/A'}}</p>
                  </div>
                </div>
              </div>

              <!-- single rule -->
              <div *ngIf="penaltyRule.rules.length === 1">
                <div class="row" *ngFor="let rule of penaltyRule.rules; let i = index;">
                  <div class="col-md-12 padding-0-30">
                    <div class="row p-border-bottom pad-div small-border-top">
                      <div class="col-md-12 accordian-header">
                        Rule {{i+1}}
                      </div>
                    </div>
                    <div *ngFor="let set of rule.communications let ii=index">
                      <div class="row pad-div">
                        <div class="col rule-headers" *ngIf="!isGame() || !isStreakGame()">Start Limit</div>
                        <div class="col rule-headers" *ngIf="!isGame()">End Limit</div>
                        <div class="col rule-headers" *ngIf="isStreakGame()">Ride Count</div>
                        <div class="col rule-headers" *ngIf="isStreakGame()">Mimimum Days</div>
                        <div class="col rule-headers">Action</div>
                        <div class="col rule-headers">Notification Type</div>
                        <div class="col rule-headers">
                          Title
                          <br>
                          Languages Provided: <span *ngFor="let title of set.titleLocales | keyvalue">
                            <span *ngIf="title.value">{{title.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>Amount</div>
                        <div class="col rule-headers" *ngIf='rule.action === "suspend"'>Suspension Hrs</div>
                        <div class="col rule-headers" *ngIf='rule.action === "deprioritize"'>Deprioritize Mins</div>
                        <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>
                          Message
                          <br>
                          Languages Provided: <span *ngFor="let message of set.messageLocales | keyvalue">
                            <span *ngIf="message.value">{{message.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>
                          Message
                          <br>
                          Languages Provided: <span *ngFor="let message of set.messageLocales | keyvalue">
                            <span *ngIf="message.value">{{message.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'> Block Redeem Amount</div>
                        <div class="col rule-headers" *ngIf='rule.action == "pushToAgent" && rule.campaignId'> Campaign Id</div>
                      </div>
                      <div class="row pad-div">
                        <div class="col p-border-bottom rule-values">{{rule.startLimit}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf="!isGame()">{{rule.endLimit}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf="isStreakGame()">{{rule.workingDays}}</div>
                        <div class="col p-border-bottom rule-values">{{rule.action}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word">{{set.type}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word">{{set.titleLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>{{rule.amount}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "suspend"'>{{rule.activateAfterHours}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "deprioritize"'>{{rule.prioritizeAfterMins}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>{{set.messageLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>{{set.messageLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action == "suspend" &&  rule.blockRedeem == true'>{{rule.amount}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action == "pushToAgent" && rule.campaignId'>{{rule.campaignId}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- multiple rules -->
              <div *ngIf="penaltyRule.rules.length > 1">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel class="expansion-panel" *ngFor="let rule of penaltyRule.rules; let i = index;">
                    <mat-expansion-panel-header class="accordian-header">
                      <mat-panel-title>
                        Rule {{i+1}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let sets of rule.communications let ii=index">
                      <div class="row pad-div">
                        <div class="col rule-headers" *ngIf="!isGame() || !isStreakGame()">Start Limit</div>
                        <div class="col rule-headers" *ngIf="!isGame()">End Limit</div>
                        <div class="col rule-headers" *ngIf="isStreakGame()">Ride Count</div>
                        <div class="col rule-headers" *ngIf="isStreakGame()">Mimimum Days</div>
                        <div class="col rule-headers">Action</div>
                        <div class="col rule-headers">Notification Type</div>
                        <div class="col rule-headers">
                          Title
                          <br>
                          Languages Provided: <span *ngFor="let title of sets.titleLocales | keyvalue">
                            <span *ngIf="title.value">{{title.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>Amount</div>
                        <div class="col rule-headers" *ngIf='rule.action === "suspend"'>Suspension Hrs</div>
                        <div class="col rule-headers" *ngIf='rule.action === "deprioritize"'>Deprioritize Mins</div>
                        <div class="col rule-headers"
                          *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>
                          Message
                          <br>
                          Languages Provided: <span *ngFor="let message of sets.messageLocales | keyvalue">
                            <span *ngIf="message.value">{{message.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers"
                          *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>
                          Message
                          <br>
                          Languages Provided: <span *ngFor="let message of sets.messageLocales | keyvalue">
                            <span *ngIf="message.value">{{message.key}} </span>
                          </span>
                        </div>
                        <div class="col rule-headers" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'>Block Redeem Amount</div>
                        <div class="col rule-headers" *ngIf='rule.action == "pushToAgent" && rule.campaignId'> Campaign Id</div>
                      </div>
                      <div class="row pad-div">
                        <div class="col p-border-bottom rule-values">{{rule.startLimit}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf="!isGame()">{{rule.endLimit}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf="isStreakGame()">{{rule.workingDays}}</div>
                        <div class="col p-border-bottom rule-values">{{rule.action}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word">{{sets.type}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word">{{sets.titleLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "adjustment"'>{{rule.amount}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "suspend"'>{{rule.activateAfterHours}}</div>
                        <div class="col p-border-bottom rule-values" *ngIf='rule.action === "deprioritize"'>{{rule.prioritizeAfterMins}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action === "penalty" || rule.action === "reward" || rule.action === "suspend" || rule.action === "adjustment"'>{{sets.messageLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action !== "penalty" && rule.action !== "reward" && rule.action !== "suspend" && rule.action !== "adjustment"'>{{sets.messageLocales[defaultLocale.code]}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action == "suspend" && rule.blockRedeem == true'>{{rule.amount}}</div>
                        <div class="col p-border-bottom rule-values overflow-break-word" *ngIf='rule.action == "pushToAgent" && rule.campaignId'>{{rule.campaignId}}</div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="pull-right">
          <span>
            <button class="back-button" type="button" (click)="goBack()">
              Back
            </button>
          </span>
          <span>
            <button mat-raised-button class="next-button" color="primary" type="button" (click)="onSubmit()">Finish</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
