/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./material-timepicker.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "ngx-material-timepicker";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/ngx-material-timepicker/ngx-material-timepicker.ngfactory";
import * as i6 from "./material-timepicker.component";
var styles_MaterialTimepickerComponent = [i0.styles];
var RenderType_MaterialTimepickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaterialTimepickerComponent, data: {} });
export { RenderType_MaterialTimepickerComponent as RenderType_MaterialTimepickerComponent };
export function View_MaterialTimepickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "input", [["aria-label", "12hr format"], ["readonly", ""]], [[8, "placeholder", 0], [8, "disabled", 0]], [[null, "input"], [null, "blur"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TimepickerDirective]), i1.ɵdid(2, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 671744, null, 0, i3.TimepickerDirective, [i1.ElementRef], { timepicker: [0, "timepicker"], value: [1, "value"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ngx-material-timepicker", [], null, [[null, "timeSet"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("timeSet" === en)) {
        var pd_1 = (_co.onTimeChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_NgxMaterialTimepickerComponent_0, i5.RenderType_NgxMaterialTimepickerComponent)), i1.ɵprd(512, null, i3.ɵa, i3.ɵa, []), i1.ɵdid(6, 245760, [["default", 4]], 0, i3.NgxMaterialTimepickerComponent, [i3.ɵa, i3.ɵb], null, { timeSet: "timeSet" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.style; _ck(_v, 2, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 6); var currVal_4 = _co._value; var currVal_5 = _co.disabled; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; var currVal_1 = i1.ɵnov(_v, 3).disabled; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MaterialTimepickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-material-timepicker", [], null, null, null, View_MaterialTimepickerComponent_0, RenderType_MaterialTimepickerComponent)), i1.ɵdid(1, 114688, null, 0, i6.MaterialTimepickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaterialTimepickerComponentNgFactory = i1.ɵccf("app-material-timepicker", i6.MaterialTimepickerComponent, View_MaterialTimepickerComponent_Host_0, { disabled: "disabled", placeholder: "placeholder", style: "style", value: "value" }, { valueChange: "valueChange" }, []);
export { MaterialTimepickerComponentNgFactory as MaterialTimepickerComponentNgFactory };
