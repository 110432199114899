import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../subscription.service';
import {Router, ActivatedRoute} from '@angular/router';
import { LoaderService } from '../../../loader.service';
import { getDateFromEpoch} from '../../../utils/utils'

@Component({
    selector:"app-view-subscription-rule",
    templateUrl: './view.component.html'
})

export class ViewSubscriptionRuleComponent implements OnInit{
    public ruleData: any = {};

    constructor(
        private subscriptionService :SubscriptionService,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private router: Router,
    ){}

    ngOnInit(){
        this.loaderService.openLoading();
        const ruleId = this.route.snapshot.paramMap.get('id');
        this.subscriptionService.getSubscriptionRule(ruleId)
                                .subscribe((res: any) =>{
                                    this.ruleData = res;
                                    this.ruleData.startDate = getDateFromEpoch(this.ruleData.startTime)
                                    this.ruleData.endDate = getDateFromEpoch(this.ruleData.endTime)
                                    this.loaderService.closeLoading();
                                }, err => console.log(err))
    }
}