<app-toaster class="toaster" [config]="toasterService.toaster"></app-toaster>
<div class="main-container" *ngIf="formLoaded">
  <p class="preview-text font-size-20">{{state.toUpperCase()}} INCENTIVE</p>
  <div>
      <mat-horizontal-stepper linear #stepper class="background-stepper">
          <ng-template matStepperIcon="edit">
              <mat-icon class="completed">done</mat-icon>
            </ng-template>
          <mat-step>
            <form>
              <ng-template matStepLabel>Basic Info</ng-template>
              <basic-info [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (basicInfoDataChange)="storeIncentiveInfo($event, 'basicInfo')" (basicInfo)="storeIncentiveInfo($event, 'basicInfo');goForward(stepper)"></basic-info>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup" [optional]="isOptional">
            <form [formGroup]="secondFormGroup">
              <ng-template matStepLabel class="padding-0-18">
                <div class="padding-0-18">Goal</div></ng-template>
              <app-goals [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (goalsInfo)="storeIncentiveInfo($event, 'goalsInfo');goForward(stepper)" (back)="goBack(stepper)" [incentiveData]="incentiveData"></app-goals>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup" [optional]="isOptional">
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Commmunication</ng-template>
                <app-communication [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (communicationInfo)="storeIncentiveInfo($event, 'communicationInfo');goForward(stepper)" (back)="goBack(stepper)" [incentiveData]="incentiveData"></app-communication>
              </form>
            </mat-step>
          <mat-step>
            <ng-template matStepLabel>Preview & Final</ng-template>
            <app-preview [state]="state" [defaultValue]="defaultValue" [incentiveId]="incentiveId" [NOT_EDITABLE]="NOT_EDITABLE" (back)="goBack(stepper)" [incentiveData]="incentiveData" (saveData)="storeIncentiveInfo($event, '')" (stepperIndex)="moveStepper($event)"></app-preview>
          </mat-step>
      </mat-horizontal-stepper>
  </div>
</div>
