/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./header.component";
import * as i3 from "@angular/router";
import * as i4 from "../shared.service";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [["class", "preview-text float-right color-467fcf padding-right-24 margin-top-15"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.move(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "edit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Edit"]))], null, null); }
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "step-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.header.indexOf("Preview") !== (0 - 1)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 638976, null, 0, i2.HeaderComponent, [i3.Router, i4.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i0.ɵccf("app-header", i2.HeaderComponent, View_HeaderComponent_Host_0, { header: "header", state: "state", incentiveId: "incentiveId" }, { selectedIndex: "selectedIndex" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
