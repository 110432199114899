import { SharedService } from 'src/app/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as Papa from 'papaparse';

const ADHOC_TYPE = 'Adhoc';
const OTHERS = 'Others';
const pendingSelectorErrorMessage = 'You cannot create an ad-hoc incentive until the user selector is created. Please wait until the user selector status is "COMPLETED".';
const failedSelectorErrorMessage = 'User selector creation has failed. Please retry by creating a user selector and incentive to proceed.';
const pendingSelectorWarnMessage = 'User selector is still not created. The incentive assignment might fail. Please ensure the the user selector is created before assignment.';
const timezone = "Asia/Kolkata";

export const defaultStatusMessage = { type: 'Ok', message: '' };

export const userSelectorStatusMessages = {
  [ADHOC_TYPE]: {
    FAILED: { type: 'Error', message: failedSelectorErrorMessage },
    INITIATED: { type: 'Error', message: pendingSelectorErrorMessage },
    PROCESSING: { type: 'Error', message: pendingSelectorErrorMessage },
    UNKNOWN: defaultStatusMessage
  },
  [OTHERS]: {
    FAILED: { type: 'Error', message: failedSelectorErrorMessage },
    INITIATED: { type: 'Warning', message: pendingSelectorWarnMessage },
    PROCESSING: { type: 'Warning', message: pendingSelectorWarnMessage },
    UNKNOWN: defaultStatusMessage
  },
};

const incentiveTypeMap = {
  'Daily': "Daily",
  'Weekly Fixed': "Weekly",
  'Weekly Selectable': "Weekly",
  'Adhoc': "Daily"
};

const days = [{ day: '', index: 0 },
{ day: 'Monday', index: 1 }, { day: 'Tuesday', index: 2 },
{ day: 'Wednesday', index: 3 }, { day: 'Thursday', index: 4 },
{ day: 'Friday', index: 5 }, { day: 'Saturday', index: 6 },
{ day: 'Sunday', index: 7 }];

export const getEpochFromDate = (dateString) =>{
  const dateObject = new Date(dateString + "T00:00:00Z");
  const options = { timeZone: timezone };
  const dateFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = dateFormatter.format(dateObject);
  const formattedDateObject = new Date(formattedDate);
  const epochTimestamp = formattedDateObject.getTime()/1000;
  return epochTimestamp;
}

export const getDateFromEpoch= (epochTimestamp:number) =>{
  const epochInMillis= epochTimestamp*1000;
  const dateObject = new Date(epochInMillis);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export default class Utils {


  static timeToTimeStamp(time = "16:10"): any {
    const [min, sec] = time.split(":");
    if (!min || !sec) {
      return "";
    }
    const timeStamp = new Date();
    timeStamp.setHours(parseInt(min));
    timeStamp.setMinutes(parseInt(sec));
    return timeStamp;
  }

  static dateFromEpoch(epochTimeInMS) {
    let date = new Date(epochTimeInMS)
    return date.toLocaleString();
  }

  static findIndexOfDay(day): any {
    for (const dayObj of days) {
      if (dayObj && dayObj.day && dayObj.day === day.trim()) {
        return dayObj.index;
      }
    }
    return -1;
  }

  static isFinalUserSelectorState(userSelectorStatus: string): boolean {
    return ["COMPLETED", "FAILED"].includes(userSelectorStatus);
  }

  static organizeData(
    type : string,
    incentiveData: any,
    dailyData: any,
    datePipe: DatePipe,
    variables: any
  ) : any {
    const goal = [];
    let order: boolean;
    let distance: boolean;
    let condition: string
    if(incentiveData.goalsInfo){
      variables = incentiveData.goalsInfo.selectedVariable;;
    }
    if (incentiveData.goalsInfo && incentiveData.goalsInfo.selectedCondition === "||") {
      condition = "or";
    } else {
      condition = "and";
    }

    if (variables && variables.indexOf("order") !== -1) {
      order = true;
    }
    if (variables && variables.indexOf("distance") !== -1) {
      distance = true;
    }
    for (let index in dailyData) {
      if (index) {
        let keys = Object.keys(dailyData[index]);
        if (keys.indexOf("startDay") !== -1) {
          keys.splice(keys.indexOf("startDay"), 1);
        }
        if (keys.indexOf("endDay") !== -1) {
          keys.splice(keys.indexOf("endDay"), 1);
        }
        if (keys.indexOf("days") !== -1) {
          keys.splice(keys.indexOf("days"), 1);
        }
        if (keys.indexOf("day") !== -1) {
          keys.splice(keys.indexOf("day"), 1);
        }
        if (keys.indexOf("disabled") !== -1) {
          keys.splice(keys.indexOf("disabled"), 1);
        }
        for (let key in keys) {
          if (key) {
            let set = { timeSlot: "", rules: "", days: "", qualities: "" };
            let time = [],
              rule = [],
              quality = [];
            for (let i in dailyData[index][keys[key]].timeSlot) {
              if (i) {
                let slab = "";
                slab +=
                  datePipe.transform(
                    dailyData[index][keys[key]].timeSlot[i].fromTime,
                    "HH:mm"
                  ) +
                  "-" +
                  datePipe.transform(
                    dailyData[index][keys[key]].timeSlot[i].toTime,
                    "HH:mm"
                  );
                time.push(slab);
              }
            }
            set["timeSlot"] = time.join("\n");
            set["days"] = dailyData[index].days;
            for (let i in dailyData[index][keys[key]].rules) {
              if (i) {
                let ruleMessage = "";
                if (order && distance) {
                  ruleMessage +=
                    dailyData[index][keys[key]].rules[i].order +
                    " order(s) " +
                    condition +
                    " " +
                    dailyData[index][keys[key]].rules[i].distance +
                    " Km = \u20B9 " +
                    dailyData[index][keys[key]].rules[i].amount;
                }
                if (!order && distance) {
                  ruleMessage +=
                    dailyData[index][keys[key]].rules[i].distance +
                    " Km = \u20B9 " +
                    dailyData[index][keys[key]].rules[i].amount;
                }
                if (order && !distance) {
                  ruleMessage +=
                    dailyData[index][keys[key]].rules[i].order +
                    " order(s) = \u20B9 " +
                    dailyData[index][keys[key]].rules[i].amount;
                }
                rule.push(ruleMessage);

                if (
                  dailyData[index][keys[key]].rules[i].quality !== undefined
                ) {
                  let qualityMessage =
                    incentiveTypeMap[(type == "preview")
                    ? incentiveData.basicInfo.incentiveType :incentiveData.incentiveType] +
                    " " +
                    dailyData[index][keys[key]].rules[i].quality.metric +
                    " " +
                    dailyData[index][keys[key]].rules[i].quality.value +
                    " % = \u20B9 " +
                    dailyData[index][keys[key]].rules[i].quality.amount;
                  quality.push(qualityMessage);
                }
              }
            }
            set["rules"] = rule.join("\n");
            set["qualities"] = quality.join("\n");
            goal.push(set);
          }
        }
      }
    }
    return goal;
  }


  static async fetchUserSelectorStatus(
    type : string,
    userSelectorId: string,
    incentiveData: any,
    userSelectorStatus: any,
    sharedService: SharedService
  ) {
    const ruleId = (type == "preview")? incentiveData.basicInfo.ruleId : incentiveData.ruleId;
    if (!ruleId) {
      return;
    }

    try {
      let status: string = userSelectorStatus.status;
      if (
        ruleId != userSelectorId ||
        !this.isFinalUserSelectorState(userSelectorStatus.status)
      ) {
        const response: any = await sharedService
          .getUserSelectorDetails(ruleId)
          .toPromise();
        status = response.status;
        userSelectorId = ruleId;
      }
      const intcentiveType = (type == "preview")
      ? incentiveData.basicInfo.incentiveType : incentiveData.incentiveType;
      const statusMessages =
        userSelectorStatusMessages[
        intcentiveType == ADHOC_TYPE ? ADHOC_TYPE : OTHERS
        ];
      const statusMessage = statusMessages[status] || defaultStatusMessage;
      userSelectorStatus = { status, ...statusMessage };
      return { userSelectorStatus, userSelectorId };

    } catch (error) { }
  }

  static downloadCSVFile(options: { fields: string[], data: any[], fileName: string; }) {
    const { fields, data, fileName } = options;
    const csv = Papa.unparse({ data, fields });
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }
}
