/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i2 from "@angular/material/toolbar";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "../toast-message/toast-message.service";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "./signin.service";
import * as i8 from "@angular/common/http";
import * as i9 from "./signin.component";
import * as i10 from "@angular/router";
var styles_SigninComponent = [];
var RenderType_SigninComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SigninComponent, data: {} });
export { RenderType_SigninComponent as RenderType_SigninComponent };
export function View_SigninComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i1.View_MatToolbar_0, i1.RenderType_MatToolbar)), i0.ɵdid(2, 4243456, null, 1, i2.MatToolbar, [i0.ElementRef, i3.Platform, i4.DOCUMENT], null, null), i0.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "img", [["alt", ""], ["class", "d-inline-block align-top"], ["height", "30"], ["src", "../../assets/Rapido.png"], ["width", "30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "span", [["class", "rapido-form-field"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0Rapido Payroll Dashboard"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["id", "buttonDiv"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 2)._toolbarRows.length > 0); var currVal_1 = (i0.ɵnov(_v, 2)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SigninComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "signin", [], null, null, null, View_SigninComponent_0, RenderType_SigninComponent)), i0.ɵprd(4608, null, i5.ToastMessage, i5.ToastMessage, [i6.MatSnackBar]), i0.ɵprd(512, null, i7.SigninService, i7.SigninService, [i8.HttpClient]), i0.ɵdid(3, 4243456, null, 0, i9.SigninComponent, [i10.Router, i7.SigninService, i0.NgZone], null, null)], null, null); }
var SigninComponentNgFactory = i0.ɵccf("signin", i9.SigninComponent, View_SigninComponent_Host_0, {}, {}, []);
export { SigninComponentNgFactory as SigninComponentNgFactory };
