import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { MyHttpClient } from '../http-client';

export const IAllocatorIncentiveTypes = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
};

@Injectable({ providedIn: 'root' })
export class IAllocatorService {
  private iAllocatorConstructs = environment.server + '/api/incentiveConstructs';
  private iAllocatorUploadConstructs = environment.server + '/api/uploadIncentiveConstructs'
  private userIdsConstructs = environment.server + "/api/userselector/users";
  static defaultIPR = 10;

  fetchIncentiveConstructs(iAllocatorData) {
    return this.http.post(this.iAllocatorConstructs, iAllocatorData).toPromise();
  }

  uploadConstructs(constructData) {
    return this.http.post(this.iAllocatorUploadConstructs, constructData).toPromise();
  }

  async fetchAllUserFor(userSelectorIdsWithLabels: string[]) {
    let userIdsAccordingToSegmentIdsWithLabels: any[] = await Promise.all(
      userSelectorIdsWithLabels.map(userSelectorIdsWithLabel => this.fetchUsersFor(userSelectorIdsWithLabel[0]))
    );
    userIdsAccordingToSegmentIdsWithLabels.map((userIdsAccordingToSegmentId,index) => {
      userIdsAccordingToSegmentId.segment_name = userSelectorIdsWithLabels[index][1];
    });
    return _.flatMap(userIdsAccordingToSegmentIdsWithLabels,
      (usersResponse) => {
        const { data: { users, _id },segment_name } = usersResponse;
        return users.map(userId => [segment_name,_id, userId]);
      });
  }

  constructor(private http: MyHttpClient) { }

  private fetchUsersFor(userSelectorId: string): Promise<Object> {
    return this.http.get(`${this.userIdsConstructs}/${userSelectorId}`).toPromise();
  }
}
