<div class="secondary-container shadow-all overflow-set">
  <label class="step-header">Basic Info</label>
  <div class="divider"></div>
  <div style="padding: 50px 20px;">
    <form [formGroup]="basicInfoFormGroup" class="padding-child-row-td-10">
      <!-- Campaign Type -->
      <span>
        <label class="heading" for="campaignType">Campaign Type</label>
        <div class="row" style="padding-left: 15px;">
          <div class="custom-control custom-radio" style="padding-right: 10px;">
            <input type="radio" id="penaltyAndReward" name="campaignType" class="custom-control-input" [disabled]="state == 'view'" [value]="'penaltyAndReward'" [(ngModel)]="campaignType" [ngModelOptions]="{standalone: true}" (change)="campaignTypeCheck()">
            <label class="custom-control-label" for="penaltyAndReward">Penalty & Reward</label>
          </div>
          <div class="custom-control custom-radio" style="padding-right: 10px;">
            <input type="radio" id="captainGame" name="campaignType" class="custom-control-input" [disabled]="state == 'view'" [value]="'captainGame'" [(ngModel)]="campaignType" [ngModelOptions]="{standalone: true}" (change)="campaignTypeCheck()">
            <label class="custom-control-label" for="captainGame">Game</label>
          </div>
          <div class="custom-control custom-radio" style="padding-right: 10px;">
            <input type="radio" id="automatedReactiveModel" name="campaignType" class="custom-control-input" [disabled]="state == 'view'" [value]="'automatedReactiveModel'" [(ngModel)]="campaignType" [ngModelOptions]="{standalone: true}" (change)="campaignTypeCheck()">
            <label class="custom-control-label" for="automatedReactiveModel">Automated Reactive Model</label>
          </div>
        </div>
      </span>


      <!-- Campaign Name | City | Service -->
      <div class="row">
        <div class="col-md-4">
            <label class="heading" for="name">Campaign Name *</label>
            <input type="text" class="text-box width-270" placeholder="Enter Campaign Name" [formControl]="basicInfoFormGroup.get('name')">
            <p *ngIf="(basicInfoFormGroup.get('name').dirty && basicInfoFormGroup.get('name').invalid)" [ngClass]="'error'"> Campaign name required. </p>
        </div>
        <div class="col-md-4">
            <label class="heading" for="city">City *</label>
            <ng-select class="width-270" [formControl]="basicInfoFormGroup.get('city')" placeholder="Select City" (change)="onCityChange($event)">
              <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}}</ng-option>
            </ng-select>
            <p *ngIf="(basicInfoFormGroup.get('city').dirty && basicInfoFormGroup.get('city').invalid)" [ngClass]="'error'"> City is required. </p>
        </div>
        <div class="col-md-4" *ngIf="!isStreakGame()">
            <label class="heading" for="service">Service *</label>
            <div>
            <ng-select [maxSelectedItems]="checkMaxSelectedItemsForService()"  class="width-270" placeholder="Select Service" [formControl]="basicInfoFormGroup.get('services')" (change)="serviceChangeCheck($event)" [multiple]="true">
              <ng-option *ngFor="let service of services" [(value)]="service.id">{{service.name}}</ng-option>
            </ng-select>
            </div>
        </div>
      </div>

      <div *ngIf="!isGame()">
        <div formArrayName="triggerEvents" *ngFor="let evt of basicInfoFormGroup.controls.triggerEvents['controls']; let i=index" class="row">
          <div class="col-md-3">
            <span>
              <label class="heading" for="triggerEvent">Trigger Event *</label>
              <ng-select [formControl]="evt.get('event')" class="width-230" placeholder="Select Trigger Event" (change)="getEventReasons($event, i)">
                <ng-option *ngFor="let event of TRIGGER_EVENTS_OPTIONS" [(value)]="event.event">{{event.displayText}}</ng-option>
              </ng-select>
              <p *ngIf="(basicInfoFormGroup.get('triggerEvents').dirty && basicInfoFormGroup.get('triggerEvents').invalid)" [ngClass]="'error'"> Event is required. </p>
            </span>
            <div *ngIf="!isGame() && state !== 'view' && !checkIfTriggerEvent()">
              <button class="add-trigger" (click)="addTriggerEvent()">+ Add trigger event</button>
            </div>
          </div>
          <div class="col-md-3" *ngIf="enableKeyReason && enableKeyReason[i] && !isAutomatedReactiveModel()">
            <span>
              <label class="heading">Key Reason</label>
              <ng-select [formControl]="evt.get('key')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Reasons" (change)="getEventReasonOptions($event, i)">
                <ng-option *ngFor="let eventReason of eventReasons[i]" [(value)]="eventReason.key">{{eventReason.displayKey}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div class="col-md-3" *ngIf="enableKeyReason && enableKeyReason[i] && isAutomatedReactiveModel()">
            <span>
              <label class="heading">Key Reason</label>
              <ng-select [formControl]="evt.get('key')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Reasons" (change)="getEventReasonOptions($event, i)">
                <ng-option *ngFor="let eventReason of eventReasons[i]" [(value)]="eventReason.key">{{eventReason.key}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div class="col-md-3" *ngIf="(getEventReasonType(i) === EVENT_REASON_TYPE.MULTI_SELECT || getEventReasonType(i) === EVENT_REASON_TYPE.SINGLE_SELECT) && !isPartialOfflineDropEvent(i)">
            <span>
              <label class="heading">Event Reason</label>
              <ng-select [maxSelectedItems]="checkMaxSelectedItems(i)" [formControl]="evt.get('values')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Reasons" [multiple]="true"  (change)="getAllowedValuesForEventReason($event, i)">
                <ng-option *ngFor="let eventReason of eventReasonOptions[i]" [value]="eventReason">{{eventReason}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div *ngIf="isPartialOfflineDropEvent(i)" class="col-md-3">
            <span>
              <label class="heading">Customer Reason</label>
              <ng-select  [formControl]="evt.get('customerReason')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Reasons">
                <ng-option *ngFor="let customerReason of customerReasonsOptions" [value]="customerReason.code">{{customerReason.message}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div *ngIf="isPartialOfflineDropEvent(i)" class="col-md-3">
            <span>
              <label class="heading">Captain Reason</label>
              <ng-select [formControl]="evt.get('captainReason')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Reasons">
                <ng-option *ngFor="let captainReason of captainReasonsOptions" [value]="captainReason.code">{{captainReason.message}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div *ngIf="applyRatingBetween[i] || applyRatingGreaterOrLessThan[i]" class="col-md-4">
            <div class="row" style="padding-top: 0px;">
              <div class="padding-left-16 padding-right-16" *ngIf='applyRatingBetween[i]'>
                <span>
                  <label class="heading">Start</label>
                  <input [disabled]="state === 'view'" type="number" min="1" max="4" class="text-box width-100"
                  (change)="addingRatingValue('start', $event, i)" [(value)]="ratingValues[i] && ratingValues[i].ratingBetweenStart">              </span>
              </div>
              <div  *ngIf='applyRatingBetween[i]'>
                <span>
                  <label class="heading">End</label>
                  <input [disabled]="state === 'view'" type="number" min="3" max="5" class="text-box width-100"
                  (change)="addingRatingValue('end', $event, i)" [(value)]="ratingValues[i] && ratingValues[i].ratingBetweenEnd">              </span>
              </div>
              <div *ngIf='applyRatingGreaterOrLessThan[i]'>
                <span>
                  <label class="heading">Value</label>
                  <input [disabled]="state === 'view'" type="number" min="1" max="5" class="text-box width-100"
                  (change)="addingRatingValue('single', $event, i)" [(value)]="ratingValues[i] && ratingValues[i].ratingGreaterOrLessThan">              </span>
              </div>
            </div>
          </div>
           <div class="dep-evt" *ngIf="showDependantEventFeature && showDependantEventFeature[i] &&!isAutomatedReactiveModel()">
              <label class="heading">Dependant event Order</label>
              <div >
                <label style= "padding: 5px;">
                  <input [name]="'group' + i" type="radio" [formControl]="evt.get('dependantEventOrder')" [disabled]="state == 'view'" value="before"> Before
                </label>
                <label style= "padding: 5px;">
                  <input [name]="'group' + i" type="radio" [formControl]="evt.get('dependantEventOrder')" [disabled]="state == 'view'" value="after"> After
                </label>
                <label style= "padding: 5px;">
                  <input [name]="'group' + i" type="radio" [formControl]="evt.get('dependantEventOrder')" [disabled]="state == 'view'" value="none"> None
                </label>
              </div>
          </div>
          <div class="dep-evt" *ngIf="showDependantEventFeature && showDependantEventFeature[i] && showDependantEventDropDown(i) && !isAutomatedReactiveModel()">
            <span>
              <label class="heading">Dependant event</label>
              <ng-select [formControl]="evt.get('dependantEvent')" [ngbTooltip]="tipContent" class="width-230" placeholder="Select Dependant Event">
                <ng-option *ngFor="let event of DEPENDANT_EVENTS_OPTIONS" [(value)]="event">{{event}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div *ngIf="i != 0 && state !== 'view'" class="delTriggerEvent-icon">
            <i class="pi pi-trash font-30 color-danger outlint-auto" (click)="deleteTriggerEvent(i)"></i>
          </div>
        </div>
      </div>
      
      <div class="row" *ngIf="!isGame() && !isAutomatedReactiveModel()">
        <div class="col-md-8" *ngIf="!isGame() && !isAutomatedReactiveModel()">
          <div class="row align-flex-end m-0 pb-0 padding-left-16 padding-right-16">
            <div>
              <label class="heading" for="name">Dapr Check</label>
              <div class="row" style="padding-left: 15px;">
                <div class="custom-control custom-radio" style="padding-right: 10px;">
                  <input type="radio" id="daprCheckYes" name="daprCheck" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applyDaprCheck" [ngModelOptions]="{standalone: true}">
                  <label class="custom-control-label" for="daprCheckYes">Yes</label>
                </div>
                <div class="custom-control custom-radio" style="padding-right: 10px;">
                  <input type="radio" id="daprCheckNo" name="daprCheck" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applyDaprCheck" [ngModelOptions]="{standalone: true}">
                  <label class="custom-control-label" for="daprCheckNo">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf='applyDaprCheck'>
            <div class="col-md-4" >
              <label class="heading">Start</label>
              <input type="number" [min]="0" [max]="basicInfoFormGroup.get('daprPercentageEnd').value" class="text-box width-100" pInputNumber placeholder="Dapr %"
              [formControl]="basicInfoFormGroup.get('daprPercentageStart')">
            </div>
            <div class="col-md-4" >
              <label class="heading">End</label>
              <input type="number" [min]="basicInfoFormGroup.get('daprPercentageStart').value" [max]='100' class="text-box width-100" pInputNumber placeholder="Dapr %"
              [formControl]="basicInfoFormGroup.get('daprPercentageEnd')">
            </div>
          </div>
          <p *ngIf="(basicInfoFormGroup.get('daprPercentageStart').dirty && basicInfoFormGroup.get('daprPercentageStart').invalid)" [ngClass]="'error'"> Dapr Start value required. </p>
          <p *ngIf="(basicInfoFormGroup.get('daprPercentageEnd').dirty && basicInfoFormGroup.get('daprPercentageEnd').invalid)" [ngClass]="'error'"> Dapr End value required. </p>
        </div>
      </div>

      <!-- User Selectors -->
      <div class="row">
        <div class="col-md-4">
          <div class="row" style="padding-top: 0px;padding-bottom: 0px;">
            <div class="padding-left-16 padding-right-16">
                <label class="heading" for="name">Do you have User Selectors?</label>
                <div class="row" style="padding-left: 15px;">
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="userSelectorYes" name="userSelector" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applySelector" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="userSelectorYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="userSelectorNo" name="userSelector" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applySelector" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="userSelectorNo">No</label>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-4" *ngIf="applySelector">
            <label class="heading" for="city">User Selectors</label>
            <ng-select class="width-270" placeholder="Select userSelectors" [formControl]="basicInfoFormGroup.get('selectors')" [multiple]=true>
              <ng-option *ngFor="let selector of userSelectors" [value]="selector._id">{{selector.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-md-4" class="padding-left-16 padding-right-16" *ngIf="applySelector">
          <label class="heading">Fallback to default rule if user not found in user selector?</label>
          <div class="row" style="padding-left: 15px;">
            <div class="custom-control custom-radio" style="padding-right: 10px;">
              <input type="radio" id="fallbackToDefaultRuleYes" name="fallbackToDefaultRule" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="fallbackToDefaultRule" [ngModelOptions]="{standalone: true}">
              <label class="custom-control-label" for="fallbackToDefaultRuleYes">Yes</label>
            </div>
            <div class="custom-control custom-radio" style="padding-right: 10px;">
              <input type="radio" id="fallbackToDefaultRuleNo" name="fallbackToDefaultRule" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="fallbackToDefaultRule" [ngModelOptions]="{standalone: true}">
              <label class="custom-control-label" for="fallbackToDefaultRuleNo">No</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" ngClass="width-176" style="padding-left: 15px;" *ngIf="applySelector">
        <label class="heading"> Priority (0 lowest) </label><br>
          <input formControlName="priority" class="text-box"  name="priority" type="number"
          (ngModelChange)="checkPriority()">
          <p *ngIf="(basicInfoFormGroup.get('priority').dirty && basicInfoFormGroup.get('priority').invalid)" [ngClass]="'error'"> Priority Required. </p>
          <p *ngIf="!((basicInfoFormGroup.get('priority').dirty && basicInfoFormGroup.get('priority').invalid))" [ngClass]="'hidden-visibility'"> Priority Required. </p>
      </div>

      <!-- Captain Segment Eligibility -->
      <div class="row" *ngIf="!isGame() && !isAutomatedReactiveModel()">
        <div class="col-md-4">
          <div class="row align-flex-end" style="padding-top: 0px;padding-bottom: 0px;">
            <div class="padding-left-16 padding-right-16">
              <span>
                <label class="heading" for="name">Captain Segment Eligibility</label>
                <div class="row" style="padding-left: 15px;">
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="segmentEligibilityYes" name="segmentEligibility" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applySegmentEligibility" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="segmentEligibilityYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="segmentEligibilityNo" name="segmentEligibility" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applySegmentEligibility" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="segmentEligibilityNo">No</label>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf='applySegmentEligibility'>
          <div class="align-flex-end" >
            <label class="heading" for="eligibilitySegment">Eligibility Segments*</label>
            <ng-select [formControl]="basicInfoFormGroup.get('eligibilitySegments')" class="width-230" placeholder="Eligibility Segments" [multiple]="true">
              <ng-option *ngFor="let eligibilitySegment of CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES" [value]="eligibilitySegment.value">{{ eligibilitySegment.label }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <!-- Start Date,End Date | Leeway | Rolling Window-->
      <div class="row">
        <div class="col-md-4">
          <div class="row" style="padding-top: 0px;">
            <div class="padding-left-16 padding-right-16">
              <span>
                <label class="heading">Start Date *</label>
                <p-calendar [inputStyle]="{'width':'95px', 'border': '1px solid #ccc'}" placeholder="Start Date" [formControl]="basicInfoFormGroup.get('startDate')" (ngModelChange)="changeInDate()"  [maxDate]="maxDate" [minDate]="currentDate" icon="pi pi-calendar" [showIcon]=true dateFormat="d/m/yy"></p-calendar>
              </span>
            </div>
            <div>
              <span>
                <label class="heading">End Date *</label>
                <p-calendar [inputStyle]="{'width':'95px', 'border': '1px solid #ccc'}" placeholder="End Date" [formControl]="basicInfoFormGroup.get('endDate')" (ngModelChange)="changeInDate()" [minDate]="minDate" icon="pi pi-calendar" [showIcon]=true dateFormat="d/m/yy"></p-calendar>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="!isGame()">
          <div *ngIf="!isAutomatedReactiveModel()" class="row align-flex-end" style="padding-top: 0px;padding-bottom: 0px;">
            <div class="padding-left-16 padding-right-16">
              <span>
                <label class="heading" for="name">Eligibility Criteria (New Captain Criteria)</label>
                <div class="row" style="padding-left: 15px;">
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="eligibilityYes" name="eligibility" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applyEligibility" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="eligibilityYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="eligibilityNo" name="eligibility" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applyEligibility" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="eligibilityNo">No</label>
                  </div>
                </div>
              </span>
            </div>
            <ng-container *ngIf='applyEligibility'>
              <div class="col-md-4" >
                <label class="heading"></label>
                <input type="number" min="0" class="text-box width-100" pInputNumber placeholder="Total Pings" [formControl]="basicInfoFormGroup.get('eligibilityPings')">
              </div>
            </ng-container>
          </div>
          <div class="row align-flex-end" *ngIf='applyEligibility'>
            <label class="heading" for="eligibilityEvent">Event*</label>
            <ng-select [formControl]="basicInfoFormGroup.get('eligibilityEvents')" class="width-230" placeholder="Events" [multiple]="true">
              <ng-option *ngFor="let eligibilityEvent of EVENT_KEY_VALUES" [value]="eligibilityEvent.value">{{ eligibilityEvent.label }}</ng-option>
            </ng-select>
          </div>
          <div class="align-flex-end" *ngIf='applyEligibility'>
            <label class="heading" for="name">Eligibility Warning Message *</label>
            <textarea class="text-box width-270" style="height: 50px;" rows="3" placeholder="Warning Message" [formControl]="basicInfoFormGroup.get('eligibilityWarnMsg')"></textarea>
            <p *ngIf="(basicInfoFormGroup.get('eligibilityWarnMsg').dirty && basicInfoFormGroup.get('eligibilityWarnMsg').invalid)" [ngClass]="'error'"> Eligibility Warning message is required. </p>
          </div>
        </div>
        <div class="col-md-2" *ngIf="!isGame() && !isAutomatedReactiveModel()">
          <div class="row align-flex-end m-0 pb-0 padding-left-16 padding-right-16">
            <div>
              <label class="heading" for="name">Rolling Window</label>
              <div class="row" style="padding-left: 15px;">
                <div class="custom-control custom-radio" style="padding-right: 10px;">
                  <input type="radio" id="rollingWindowYes" name="rollingWindowDays" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applyRollingWindow" [ngModelOptions]="{standalone: true}" (change)="showRollingWIndowModal()">
                  <label class="custom-control-label" for="rollingWindowYes">Yes</label>
                </div>
                <div class="custom-control custom-radio" style="padding-right: 10px;">
                  <input type="radio" id="rollingWindowNo" name="rollingWindowDays" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applyRollingWindow" [ngModelOptions]="{standalone: true}">
                  <label class="custom-control-label" for="rollingWindowNo">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf='applyRollingWindow'>
            <mat-checkbox [(ngModel)]="getCountFromHistory" [ngModelOptions]="{standalone: true}">
              Get count from historic action (from last action before {{daysAfterWhichCheckHistoricAction}} days)
            </mat-checkbox>
            <div class="col-md-4" >
              <input type="number" min="2" [max]='getCountFromHistory ? maxRollingWindowSpan:daysAfterWhichCheckHistoricAction' class="text-box width-100" pInputNumber placeholder="No of days"
              [formControl]="basicInfoFormGroup.get('rollingWindowDays')">
            </div>
          </div>
          <div [ngClass]="'error'">{{mandatoryRollingWindowCheck()}}</div>
        </div>
      </div>

      <!-- Instance Based Leeway -->
      <div *ngIf="!isGame() && checkIfTriggerEvent()" class="row">
        <div class="col-md-4">
          <div class="row align-flex-end" style="padding-top: 0px;padding-bottom: 0px;">
            <div class="padding-left-16 padding-right-16">
                <label class="heading" for="name">Instance Based Leeway</label>
                <div class="row" style="padding-left: 15px;">
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="instanceLeewayYes" name="instanceLeeway" class="custom-control-input" [disabled]="state == 'view'" [value]="true" [(ngModel)]="applyInstanceLeeway" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="instanceLeewayYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio" style="padding-right: 10px;">
                    <input type="radio" id="instanceLeewayNo" name="instanceLeeway" class="custom-control-input" [disabled]="state == 'view'" [value]="false" [(ngModel)]="applyInstanceLeeway" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="instanceLeewayNo">No</label>
                  </div>
                </div>
            </div>
            <ng-container *ngIf='applyInstanceLeeway'>
              <div class="col-md-4" >
                <label class="heading"></label>
                <input type="number" min="0" class="text-box width-100" pInputNumber placeholder="Count"
                [formControl]="basicInfoFormGroup.get('instanceLeeway')">
              </div>
            </ng-container>
          </div>
          <div class="align-flex-end" *ngIf='applyInstanceLeeway'>
            <label class="heading" for="name">Leeway Message *</label>
            <textarea class="text-box width-270" style="height: 50px;" rows="3" placeholder="Warning Message" [formControl]="basicInfoFormGroup.get('instanceLeewayWarnMsg')"></textarea>
            <p *ngIf="(basicInfoFormGroup.get('instanceLeewayWarnMsg').dirty && basicInfoFormGroup.get('instanceLeewayWarnMsg').invalid)" [ngClass]="'error'"> Leeway Warning message is required. </p>
          </div>
        </div>
      </div>

      <!-- Time slots -->
      <div class="row" *ngIf="!isGame() && !isAutomatedReactiveModel()">
        <div class="col-md-12">
          <span>
            <label class="heading" for="name">Time Slots</label>
          </span>
        </div>
        <div class="col-md-4">
          <div class="row" style="padding: 0px;" formArrayName="timeSlots" *ngFor="let ts of basicInfoFormGroup.controls.timeSlots['controls']; let i=index">
            <div class="row time-slot-container" [formGroupName]="i">
              <div class="col-md-2 pr-5 min-width-65">Slot {{i+1}} :</div>
              <div class="col-md-4">
                <span>
                  <app-material-timepicker
                    [disabled]="NOT_EDITABLE.timeSlots"
                    [style]="timeSlotStyle"
                    [placeholder]="'Start Time'"
                    [(value)]="timeSlots['controls'][i]['value']['startTime']">
                  </app-material-timepicker>
                </span>
              </div>
              <div class="col-md-4">
                <span>
                  <app-material-timepicker
                    [disabled]="NOT_EDITABLE.timeSlots"
                    [style]="timeSlotStyle"
                    [placeholder]="'End Time'"
                     [(value)]="timeSlots['controls'][i]['value']['endTime']">
                    </app-material-timepicker>
                </span>
              </div>
              <div class="col-md-2 max-width-30">
                <i class="pi pi-trash font-30 color-danger outlint-auto" (click)="deleteTimeSlot(i)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 padding-td-10">
          <span><i class="pi pi-plus font-30 color-primary outlint-auto  pull-right" (click)="addTimeSlot()"></i></span>
        </div>
      </div>
      <div class="margin-top-100 divider"></div>
      <div class="row">
        <div class="pull-right">
          <span><button class="back-button" type="button" (click)="goBack()">Back</button></span>
          <span><button mat-raised-button class="next-button" color="primary" type="button" (click)="submit()">Next</button></span>
        </div>
      </div>
    </form>
  </div>
</div>
