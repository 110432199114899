/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/filter-pane/filter-pane.component.ngfactory";
import * as i3 from "../../shared/filter-pane/filter-pane.component";
import * as i4 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i5 from "@angular/material/toolbar";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "../../shared/table/table.component.ngfactory";
import * as i13 from "../../shared/table/table.component";
import * as i14 from "./history.component";
import * as i15 from "../bulkcredit.service";
import * as i16 from "../../loader.service";
import * as i17 from "../../shared/alert-dialog/alert-dialog.service";
import * as i18 from "@angular/router";
var styles_BulkCreditHistoryComponent = [i0.styles];
var RenderType_BulkCreditHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkCreditHistoryComponent, data: {} });
export { RenderType_BulkCreditHistoryComponent as RenderType_BulkCreditHistoryComponent };
export function View_BulkCreditHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "filter-pane", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.applyFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FilterPaneComponent_0, i2.RenderType_FilterPaneComponent)), i1.ɵdid(2, 638976, null, 0, i3.FilterPaneComponent, [], { filterComponents: [0, "filterComponents"], showFilter: [1, "showFilter"] }, { submit: "submit" }), (_l()(), i1.ɵeld(3, 0, null, null, 7, "mat-toolbar", [["class", "toolbar-border mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i4.View_MatToolbar_0, i4.RenderType_MatToolbar)), i1.ɵdid(4, 4243456, null, 1, i5.MatToolbar, [i1.ElementRef, i6.Platform, i7.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵted(-1, 0, [" Bulk payment history "])), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "div", [["class", "pull-right is-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn-primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showFilters = !_co.showFilters) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i9.MatButton, [i1.ElementRef, i6.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Filters"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "data-table", [], null, [[null, "paginationChange"], [null, "headerToSort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paginationChange" === en)) {
        var pd_0 = (_co.fetchHistory() !== false);
        ad = (pd_0 && ad);
    } if (("headerToSort" === en)) {
        var pd_1 = (_co.sortByHeader($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_TableComponent_0, i12.RenderType_TableComponent)), i1.ɵdid(12, 638976, null, 0, i13.TableComponent, [], { table: [0, "table"] }, { paginationChange: "paginationChange", headerToSort: "headerToSort" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterComponents; var currVal_1 = _co.showFilters; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_6 = _co.table; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4)._toolbarRows.length > 0); var currVal_3 = (i1.ɵnov(_v, 4)._toolbarRows.length === 0); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 9).disabled || null); var currVal_5 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_BulkCreditHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-history", [], null, null, null, View_BulkCreditHistoryComponent_0, RenderType_BulkCreditHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i14.BulkCreditHistoryComponent, [i15.BulkCreditService, i16.LoaderService, i17.AlertDialogService, i18.ActivatedRoute, i18.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BulkCreditHistoryComponentNgFactory = i1.ɵccf("app-history", i14.BulkCreditHistoryComponent, View_BulkCreditHistoryComponent_Host_0, {}, {}, []);
export { BulkCreditHistoryComponentNgFactory as BulkCreditHistoryComponentNgFactory };
