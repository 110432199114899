import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class LoaderService {
  constructor(private loaderService: NgxUiLoaderService) { }

  openLoading() {
    this.loaderService.start();
  }

  closeLoading() {
    this.loaderService.stop();
  }
}
