import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'radio-buttons',
  templateUrl: './radio-buttons.component.html'
})
export class RadioButtonsComponent implements OnInit {
  @Input() radioData: any;
  @Output() selectedRadio: EventEmitter<any> = new EventEmitter();

  public selectedValues: '';
  constructor() { }



  ngOnInit() {
    // console.log('radio : ', this.radioData);
    // this.radioData = {
    //     type : 'radio-button',
    //     title : 'Status',
    //     values : ['Both', 'Active', 'Inactive'],
    //     default : 'Both',
    //     key : 'active-radio'
    //   };
  }

  chosenOptions() {
    const emitSelections = {
      [this.radioData.key] : this.selectedValues
    };
    // console.log('Emitted radio : ', emitSelections);
    this.selectedRadio.emit(emitSelections);
  }


}
