<mat-toolbar class="nav-toolbar bg-light-black">
  <img *ngIf="isExpanded" class="nav-header-image" src="./assets/img/group-25.png">
  <img *ngIf="!isExpanded" class="nav-header-image-small" src="./assets/img/Rapido.png">
</mat-toolbar>
<mat-accordion [multi]="true" [displayMode]="'flat'" class="bg-light-black">
  <mat-expansion-panel *ngFor="let item of menuItems" [(expanded)]="item.expanded" [hideToggle]="true"
    class="bg-light-black cursor-pointer black-border" (expandedChange)="$event && expand.emit(true)">
    <mat-expansion-panel-header class="p-x-10">
      <mat-panel-title class="bold text-white">
        <mat-icon [class.margin-r-10]="isExpanded">{{item.icon}}</mat-icon>
        <span *ngIf="isExpanded">{{item.name}}</span>
      </mat-panel-title>
      <mat-icon *ngIf="isExpanded" class="bold text-white">{{item.expanded ? "expand_less" : "expand_more"}}</mat-icon>
    </mat-expansion-panel-header>
    <mat-list *ngIf="isExpanded">
      <mat-list-item *ngFor="let option of item.options" class="primary-nav-list-item black-border"
        [class.menu-item-selected]="_route.url.includes(option.path)" (click)="goto(option.path)">
        <p class="primary-nav-list-label">{{option.name}}</p>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
