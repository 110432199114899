<div id="overlay"></div>
<app-toaster class="toaster" [config]="toasterService.toaster"></app-toaster>
<mat-toolbar>
  <img src="../../assets/Rapido.png" width="30" height="30" class="d-inline-block align-top" alt="" /> <span class="rapido-form-field">Rapido</span>
</mat-toolbar>
<div class="main-container">
  <div style="padding: 50px 20px;" [ngStyle]="{ 'background-color':'white'}">
     <div class="row">
       <div class="col-md-4">
         <span>
           <label class="heading" for="name"> Campaign Name</label>
           <input type="text" class="text-box width-270" placeholder="Enter Campaign Name" [(ngModel)]="filterParams.campaignName" >
         </span>
       </div>
      <div class="col-md-4">
        <div class="width-270">
          <span class="width-270">
            <label class="heading" for="city">City</label>
            <ng-select [(ngModel)]="filterParams.city" class="custom" placeholder="Select City" (change)="getCityServiceDetails($event)" [multiple]=true>
              <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}}</ng-option>
              <ng-template ng-header-tmp>
                <div>
                  <button class="btn btn-link" (click)="onSelectAll()">Select All</button>
                  <button class="btn btn-link" (click)="onClearAll()">Clear All</button>
                </div>
              </ng-template>
            </ng-select>
          </span>
        </div>
      </div>
       <div class="col-md-4">
         <span>
           <label class="heading" for="service">Service</label>
           <ng-select [(ngModel)]="filterParams.service" class="width-270" placeholder="Select Service" [multiple]=true>
             <ng-option *ngFor="let service of uniqueServices" [value]="service.serviceId">{{service.name}}</ng-option>
           </ng-select>
         </span>
       </div>
     </div>
     <div class="row">
       <div class="col-md-4">
         <div class="row">
           <div class="padding-left-16 padding-right-16 margin-top-48">
             <span class="rapido-form-field">
               <label class="heading"> Start Date</label><br>
               <p-calendar [inputStyle]="{'width':'95px', 'border': '1px solid #ccc'}" [(ngModel)]="filterParams.startDate" icon="pi pi-calendar" name="startDate" [showIcon]=true dateFormat="d/m/yy"></p-calendar>
             </span>
           </div>
           <div class="margin-top-48">
             <span class="rapido-form-field">
               <label class="heading"> End Date</label><br>
                 <p-calendar [inputStyle]="{'width':'95px', 'border': '1px solid #ccc'}" [(ngModel)]="filterParams.endDate" name="endDate" icon="pi pi-calendar"
                   [showIcon]=true dateFormat="d/m/yy"></p-calendar>
             </span>
           </div>
         </div>
       </div>

       <div class="col-md-4 margin-top-48">
       <span>
         <label class="heading" for="events">Trigger Event</label>
         <ng-select [(ngModel)]="filterParams.event" class="width-270" placeholder="Event">
           <ng-option *ngFor="let event of mappedEvents" [value]="event.event">{{ event.displayText }}</ng-option>
         </ng-select>
       </span>
       </div>
       <div class="col-md-4  margin-top-48">
         <span>
           <label class="heading" for="status">Status</label>
           <ng-select [(ngModel)]="filterParams.status" class="width-270" placeholder="status">
             <ng-option *ngFor="let status of status" [value]="status">{{ status }}</ng-option>
           </ng-select>
         </span>
       </div>
     </div>
     <div class="row">
       <div class="pull-right">
        <span>
          <button mat-raised-button class="next-button" color="primary" type="button" (click)="clearFilter()">Clear</button>
        </span>
           <span>
               <button mat-raised-button class="next-button" color="primary" type="button" (click)="submitFilter()">Submit</button>
             </span>
       </div>
     </div>
 </div>

 <div *ngIf="tableLoaded" style="padding-top: 50px;">

   <mat-toolbar class="toolbar-border">
     Campaign Details
   </mat-toolbar>
     <data-table [table]="table" (paginationChange)="fetchTableData()"></data-table>
     <app-activity-logs *ngIf="showActivityLogs" [activityLog]="activityLog" (closedPanel)="toggleActivityLog(false)"></app-activity-logs>
 </div>
</div>
