import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader.service';
import { Button, Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { BulkCreditArrayFields, BulkCreditService } from '../bulkcredit.service';
import * as moment from 'moment';
import { AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BULK_CREDIT_HISTORY } from 'src/app/routes';
import Utils from 'src/app/utils/utils';
var BulkCreditHistoryComponent = /** @class */ (function () {
    function BulkCreditHistoryComponent(bulkCreditService, loader, alertService, currentRoute, router) {
        this.bulkCreditService = bulkCreditService;
        this.loader = loader;
        this.alertService = alertService;
        this.currentRoute = currentRoute;
        this.router = router;
        this.table = new Table({
            pagination: new Pagination(0, 10, 100, [5, 10, 15, 20]),
            headers: {
                createdAt: 'Date',
                createdBy: 'Initiated By',
                totalTransactions: 'Number of captains',
                totalAmount: 'Total Amount',
                subTransactionType: 'Sub Transaction',
                status: 'Status',
                result: 'Download result',
            },
            data: []
        });
        this.sortableColumns = ["createdAt"];
        this.requestBody = {
            page: {},
            sort: {
                sortBy: "createdAt",
                sortOrder: -1
            },
            filter: {}
        };
        this.showFilters = false;
        this.filterComponents = [
            {
                type: 'searchable-dropdown',
                title: 'Subtransaction Type',
                values: this.bulkCreditService.transactionTypes.map(function (t) { return t.displayName; }),
                key: 'subTransactionType'
            },
            {
                type: 'calendar',
                title: 'Created Date',
                key: 'createdAt'
            }
        ];
    }
    BulkCreditHistoryComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertService.open(options);
        this.alertService.confirmed().subscribe(function (confirmed) { return confirmed && cb(); });
    };
    BulkCreditHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentRoute.queryParams
            .subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!params.downloadResult) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.downloadResultCSV(params.downloadResult)];
                    case 1:
                        _a.sent();
                        this.router.navigateByUrl(BULK_CREDIT_HISTORY);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.fetchHistory()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        ;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    BulkCreditHistoryComponent.prototype.fetchHistory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var history_1, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loader.openLoading();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.addPageDetails();
                        return [4 /*yield*/, this.bulkCreditService.fetchBulkCreditHistory(this.requestBody)];
                    case 2:
                        history_1 = _a.sent();
                        this.table.data = history_1.map(function (item) { return (tslib_1.__assign({}, item, { subTransactionType: _this.subTransactionTypeName(item.subTransactionType), createdAt: new Date(item.createdAt).toLocaleDateString(), result: new TableData({
                                type: TableDataTypes.BUTTON,
                                button: new Button({
                                    label: "Download Result",
                                    className: "btn btn-secondary",
                                    onClick: function () { return _this.downloadResultCSV(item._id.$oid); },
                                })
                            }) })); });
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.showAlertBox({
                            title: "Error",
                            message: "Something went wrong while fetching history",
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        this.loader.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BulkCreditHistoryComponent.prototype.downloadResultCSV = function (fileId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, file_1, transactions, subTransactionType, formatArrayFields, e_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loader.openLoading();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.bulkCreditService.downloadResult(fileId)];
                    case 2:
                        _a = _b.sent(), file_1 = _a.file, transactions = _a.transactions;
                        subTransactionType = this.bulkCreditService.transactionTypes
                            .find(function (t) { return t.name === file_1.subTransactionType; });
                        formatArrayFields = function (transaction) {
                            return BulkCreditArrayFields.reduce(function (item, field) {
                                var value = transaction[field];
                                if (!value) {
                                    return item;
                                }
                                item[field] = value.join("|");
                                return item;
                            }, transaction);
                        };
                        Utils.downloadCSVFile({
                            data: transactions.map(formatArrayFields),
                            fields: subTransactionType.headers.concat("status"),
                            fileName: (file_1.subTransactionType + "_" + file_1.createdAt + "_result.csv").toLowerCase()
                        });
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _b.sent();
                        this.showAlertBox({
                            title: "Error",
                            message: "Something went wrong while downloading result",
                        });
                        console.log(e_2);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loader.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BulkCreditHistoryComponent.prototype.addPageDetails = function () {
        var limit = this.table.pagination.perPage;
        this.requestBody.page.limit = limit;
        this.requestBody.page.offset = this.table.pagination.pageNo * limit;
    };
    BulkCreditHistoryComponent.prototype.subTransactionTypeName = function (code) {
        return this.bulkCreditService
            .transactionTypes
            .find(function (t) { return t.name === code; })
            .displayName;
    };
    BulkCreditHistoryComponent.prototype.subTransactionTypeCode = function (name) {
        return this.bulkCreditService
            .transactionTypes
            .find(function (t) { return t.displayName === name; })
            .name;
    };
    BulkCreditHistoryComponent.prototype.sortByHeader = function (header) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sortOrder, column;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sortOrder = header.includes("-") ? -1 : 1;
                        column = header.replace("-", "");
                        if (!this.sortableColumns.includes(column)) {
                            return [2 /*return*/];
                        }
                        this.requestBody.sort.sortBy = column;
                        this.requestBody.sort.sortOrder = sortOrder;
                        return [4 /*yield*/, this.fetchHistory()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BulkCreditHistoryComponent.prototype.applyFilters = function (newFilters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters, _a, from, to;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        filters = {};
                        if (newFilters.subTransactionType && newFilters.subTransactionType.length > 0) {
                            filters.subTransactionType = newFilters
                                .subTransactionType
                                .map(function (name) { return _this.subTransactionTypeCode(name); });
                        }
                        _a = newFilters.createdAt || [], from = _a[0], to = _a[1];
                        if (from) {
                            filters.from = moment(from).valueOf();
                        }
                        if (to) {
                            filters.to = moment(to).endOf("day").valueOf();
                        }
                        this.requestBody.filter = filters;
                        return [4 /*yield*/, this.fetchHistory()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return BulkCreditHistoryComponent;
}());
export { BulkCreditHistoryComponent };
