import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { tap } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService,
    public router: Router
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const permission = localStorage.getItem('permission');
    
    if (!(await this.auth.isAuthenticated())) {
      this.router.navigate(["/login"]);
      return false;
    }

    const allowed = await this.auth.isAuthorizedFor(permission)
    if (!allowed) {
      this.router.navigate(["/login"]);
    }
    return allowed;
  }
}
