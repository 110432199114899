import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
import { FileUpload } from 'primeng/fileupload';
import Utils from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../loader.service';
import { AlertDialogOptions, AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { AuditCampaignService } from './audit-campaign.service';

@Component({
  selector: 'app-audit-campaign',
  templateUrl: './audit-campaign.component.html',
  styleUrls: ['./audit-campaign.component.css']
})
export class AuditCampaignInitiateComponent implements OnInit {
  public validated = false;
  public validationError = false;
  public fileToUpload: File = null;
  public fileDetails: {
    records: any,
    name: string,
  } = null;
  private maxTransactions = environment.maxAuditTransactionsPerFile;
  @ViewChild('fileUpload') fileUploadRef: FileUpload;

  public enabledActionTypes = this.AuditCampaignService
    .actionTypes.filter(t => t.enabled);

  auditCampaignFormGroup = new FormGroup({
    actionType: new FormControl(null, Validators.required)
  });

  constructor(
    public AuditCampaignService: AuditCampaignService,
    private loaderService: LoaderService,
    private alertDialogService: AlertDialogService,
    private router: Router) { }

  resetFile() {
    this.fileDetails = null;
    this.validated = false;
    this.validationError = false;
  };

  ngOnInit() {
      this.fileUploadRef && this.fileUploadRef.clear();
      this.resetFile();
  }

  selectedActionType() {
    return this.auditCampaignFormGroup.get("actionType").value;
  }

  handleFile(event: { files: File[]; }) {
    this.validated = false;
    const file = event.files[0];
    
    Papa.parse(file, {
      complete: (results) => {
        this.fileDetails = { records: results.data, name: file.name },
        this.fileToUpload = file;
      },
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(),
      transform: (value, header: string) => this.parseCSVValue(value, header),   
    });

    this.resetFile();
  }

  parseCSVValue(value: string, header: string): any {
    const actionType = this.selectedActionType();
    const arrayFields: string[] = actionType.arrayFields || [];
      if (arrayFields.includes(header)) {
        try {
          if(!value || value.length==0) {
            console.log("ERROR: File Validation error .Invalid file format/incomplete information in the file! " + `${header}` + " value can't be empty");
            throw new Error("Invalid file format/incomplete information in the file! " + `${header}` + " value can't be empty");
          }
        } catch(err) {
          this.handleError(err)
        }
        return value.trim();
      }
   
    
    return value.trim();
  }

  downloadSampleFile() {
    const actionType = this.selectedActionType();
    if (!actionType) {
      return;
    }
    Utils.downloadCSVFile({
      fields: actionType.headers,
      data: actionType.sampleData,
      fileName: `${actionType.code.toLowerCase()}.csv`,
    });
  }

  showAlertBox(options: AlertDialogOptions, cb = () => { }) {
    this.alertDialogService.open(options);
    this.alertDialogService.confirmed()
      .subscribe(confirmed => confirmed && cb());
  }

  async valdidateAuditCampaignFile() {
    const validCount = this.validateTransactionCount();
    if (!validCount) {
      return;
    }

    this.showAlertBox({
      title: 'Success',
      message: 'File validation successful.',
    });

    this.validated = true;
  }

  validateTransactionCount() {
    const transactions = this.fileDetails.records;
    const count = transactions.length;
    if (count > this.maxTransactions) {
      this.showAlertBox({
        title: 'Error',
        message: `You can only upload ${this.maxTransactions} transactions at a time.`,
      });
      return false;
    }
    return true;
  }

  confirmAuditCampaignInitiate() {
    this.showAlertBox({
      title: 'Confirm',
      message: `You are about to start audit for ${this.fileDetails.records.length} captains. Do you want to proceed?`,
      confirmText: 'Yes, proceed',
    }, () => this.initiateAudit());
  }

  initiateAudit() {
    this.loaderService.openLoading();
    this.AuditCampaignService
      .initiateAudit(this.selectedActionType(), this.fileToUpload)
      .then(() => {
        this.showAlertBox({
          title: 'Success',
          message: 'Audit initiated successfully.'
        });
      })
      .catch(err => this.handleError(err))
      .finally(() => this.loaderService.closeLoading());
  }

  private handleError(err: any) {
    const unknownErr = "Something went wrong !!! Please check console log.";
    const res = err.error || { message: unknownErr };
    if (!res.data) {
      this.showAlertBox({
        title: 'Error',
        message: res.message,
      });
      return;
    }

    const data = res.data;
    if (data.code ) {
      this.validationError = true;
      const errors = data.errors;
      const value = errors
        .map(err => `Record: ${err.recordNumber} - ${err.error}`);

      this.showAlertBox({
        title: 'Validation Error',
        details: [
          {
            value,
            message: "Please correct the errors and try again."
          }
        ],
      });
      return;
    }

    const error = data.error || data;

    this.showAlertBox({
      title: 'Error',
      message: error.message || unknownErr,
    });
  }
}
