import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const nonGuiltyCaptainHeaders = ["orderId", "orderValue", "cityHighCapPenalty", "eventCount", "eventDate"];
const nonGuiltyCaptainSample = {
  orderId: "612ce928c1c4c492471fa03f",
  orderValue: "20",
  cityHighCapPenalty: "50",
  eventCount: "4",
  eventDate: "20210830"
};

const guiltyCaptainHeaders = ["orderId", "orderValue", "cityHighCapPenalty", "eventCount", "eventDate"];
const guiltyCaptainSample = {
  orderId: "612ce928c1c4c492471fa03f",
  orderValue: "20",
  cityHighCapPenalty: "50",
  eventCount: "4",
  eventDate: "20210830"
};

@Injectable()
export class AuditCampaignService {
  constructor(private http: HttpClient) {}
  private static apiRoot = environment.server;
  public actionTypes = [
    {
      name: "GuiltyCaptainAudit",
      code: "guilty",
      headers: guiltyCaptainHeaders,
      sampleData: [guiltyCaptainSample],
      arrayFields: ["orderId", "orderValue", "cityHighCapPenalty", "eventCount", "eventDate"],
      enabled : true
    }, {
      name: "NonGuiltyCaptainAudit",
      code: "notGuilty",
      headers: nonGuiltyCaptainHeaders,
      sampleData: [nonGuiltyCaptainSample],
      arrayFields:  ["orderId", "orderValue", "cityHighCapPenalty", "eventCount", "eventDate"],
      enabled : true
    }
  ];

  initiateAudit(actionType: any, fileToUpload: any): Promise<any> {
    const endpoint = AuditCampaignService.apiRoot + '/api/auditcampaign/initiate';

    const formData: FormData = new FormData();
    formData.append('importFile', fileToUpload, fileToUpload.name);
    formData.append('actionType', JSON.stringify({
      "actionType": actionType.code
    }));
   
    const headers = {
      "Content-Type": "multipart/form-data"
    }

    return this.http.post(endpoint, formData, {headers : headers}).toPromise(); 
  }
}
