import { BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE } from './../constants';
import { trigger } from '@angular/animations';
import { Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { FormControl, Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SharedService } from 'src/app/shared/shared.service';
import { PenaltyBasicInfoService } from './penalty-basic-info.service';
import { ToasterService } from '../../toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { LoaderService } from 'src/app/loader.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RollingWindowDialogComponent } from '../../rolling-window-dialog/rolling-window-dialog.component';
import { TRIGGER_EVENT, RATING_POSITION, RATING_TRIGGERS, eventsWithoutReasons, aprDprEvents,
  EVENT_REASON_TYPE, EQUATION_TYPES, EVENT_NAME_MAPPINGS, EVENT_REASON_TYPES, INSTANCE_LEEWAY_EVENTS,
  EVENT_KEY_VALUES, CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES, PARTIAL_OFFLINE_RIDE_KEY } from '../constants';
interface IEntity {
  name: string;
  id: string;
  serviceId: string;
}

@Component({
  selector: 'app-penalty-basic-info',
  templateUrl: './penalty-basic-info.component.html',
  styleUrls: ['./penalty-basic-info.component.css'],
  providers: [DatePipe]
})
export class PenaltyBasicInfoComponent implements OnInit {
  public eventName = EVENT_NAME_MAPPINGS;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private penaltyBasicInfoService: PenaltyBasicInfoService,
    public toasterService: ToasterService,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialog
  ) { this.updateCities();
  }

  @Input() defaultValue: any;
  @Input() state: string;
  @Input() NOT_EDITABLE: any;
  @Input() campaignData: string;
  @Output() basicInfoDataChange: EventEmitter<any> = new EventEmitter();
  @Output() basicInfo: EventEmitter<any> = new EventEmitter();

  public currentDate = new Date();
  public eventReasons: any = [];
  public TRIGGER_EVENTS_OPTIONS: any;
  public DEPENDANT_EVENTS_OPTIONS: any;
  public cities: IEntity[] = [];
  public services: IEntity[] = [];
  public serviceDetails: any;
  public serviceInfo: any;
  public eventsData = [];
  public eventReasonOptions = [];
  public captainReasonsOptions = [];
  public customerReasonsOptions = [];
  public enableKeyReason = [false];
  public ratingValues = [];
  public EVENT_REASON_TYPE = EVENT_REASON_TYPE;
  public EVENT_KEY_VALUES = EVENT_KEY_VALUES;
  public CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES = CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES;
  public campaignType: string = "penaltyAndReward";
  public showLMS = false;
  public useActionAmount = false;
  public actionMax = false;
  public actionMin = false;
  public escalationTicketKey = "escalationReason";
  public showDependantEventFeature = [false];
  public equations = [
    EQUATION_TYPES.LESS_THAN,
    EQUATION_TYPES.GREATER_THAN,
    EQUATION_TYPES.BETWEEN,
  ];
  public daysAfterWhichCheckHistoricAction = environment.daysAfterWhichCheckHistoricAction;
  public maxRollingWindowSpan = 1827;

  public timeSlotStyle = {
    height: '34px',
    width: '100%'
  }

  private getEmptyTimeSlot() {
    return this.fb.group({
      startTime: null,
      endTime: null
    });
  }

  private getEmptyTriggers() {
    return this.fb.group({
      event: null,
      values: null,
      key: null,
      customerReason: null,
      captainReason: null,
      dependantEventOrder: 'none',
      dependantEvent: null

    });
  }

  // Make this a constant
  public escalationTicketEvent="escalation_ticket";
  basicInfoFormGroup = this.fb.group({
    name: [null,Validators.required],
    city: [null,Validators.required],
    gameType: [null,Validators.required],
    services: [null,Validators.required],
    selectors: [null,Validators.required],
    startDate:[null,Validators.required],
    endDate: [null,Validators.required],
    triggerEvents: this.fb.array([this.getEmptyTriggers()]),
    timeSlots: this.fb.array([this.getEmptyTimeSlot()]),
    leeway: new FormControl(null, Validators.required),
    instanceLeeway: new FormControl(null, Validators.required),
    eligibilityPings: new FormControl(null, Validators.required),
    eligibilityEvents: new FormControl(null, Validators.required),
    eligibilitySegments: new FormControl(null, Validators.required),
    daprPercentageStart: new FormControl(null, Validators.required),
    daprPercentageEnd: new FormControl(null, Validators.required),
    eligibilityWarnMsg: new FormControl(null, Validators.required),
    rollingWindowDays: new FormControl(null, Validators.required),
    leewayWarnMsg: new FormControl(null, Validators.required),
    instanceLeewayWarnMsg: new FormControl(null, Validators.required),
    ratingBetweenStart: new FormControl(null, Validators.required),
    ratingBetweenEnd: new FormControl(null, Validators.required),
    ratingGreaterOrLessThanValue: new FormControl(null, Validators.required),
    priority: new FormControl('', Validators.required),
    _id: new FormControl(null),
  });

  public minDate: Date;
  public maxDate: Date;
  public applyLeeway: boolean = false;
  public applyInstanceLeeway: boolean = false;
  public applyRollingWindow: boolean = false;
  public getCountFromHistory: boolean = false;
  public applyEligibility: boolean = false;
  public applyDaprCheck: boolean = false;
  public applySegmentEligibility: boolean = false;
  public applySelector: boolean = false;
  public fallbackToDefaultRule: boolean = false;
  public userSelectors: any;
  public gameTypes: string[] = ["DPR", "Streak"];
  public applyRatingGreaterOrLessThan: boolean[] = [false];
  public applyRatingBetween: boolean[] = [false];
  events = [];

  get timeSlots(): FormArray{
    return this.basicInfoFormGroup.controls["timeSlots" ] as FormArray
  }

  get triggerEvents(): FormArray{
    return this.basicInfoFormGroup.controls["triggerEvents"] as FormArray
  }

  isGame() {
    return this.campaignType == "captainGame"
  }

  isAutomatedReactiveModel() {
    return this.campaignType === 'automatedReactiveModel'
  }

  isPenaltyAndRewards() {
    return this.campaignType === 'penaltyAndReward';
  }

  showDependantEventDropDown(index){
    if (this.basicInfoFormGroup.controls.triggerEvents.value[index].dependantEventOrder === 'before' ||
          this.basicInfoFormGroup.controls.triggerEvents.value[index].dependantEventOrder === 'after') {
      return true;
    }
    return false;
  }
  getEventReasonType(index) {
    try{
      return EVENT_REASON_TYPES[this.basicInfoFormGroup.controls.triggerEvents.value[index].event];
    } catch(err) {
      console.log(err);
    }
  }
  isPartialOfflineDropEvent(index) {
    if (this.basicInfoFormGroup.controls.triggerEvents.value[index].event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
      return true;
    }
    return false;
  }

  hasDependantEvent(eventName){
    return this.eventsData.some(eventData => eventData.event === eventName && 'dependantEvents' in eventData)
  }
  setDependantEvent(triggerEvent){
    let dependentEvent = this.eventsData.filter((eventData) => eventData.event === triggerEvent && 'dependantEvents' in eventData);
    this.DEPENDANT_EVENTS_OPTIONS = dependentEvent[0].dependantEvents;
  }

//Sets a field editable or non editable. Values are different for different screens.
  makeFieldsDisable() {

    Object.keys(this.NOT_EDITABLE).forEach(fieldName => {
      if (this.NOT_EDITABLE[fieldName]) {
        try {
          this.basicInfoFormGroup.get(fieldName).disable();
        } catch (err) {
          console.log("Error disabling field ", fieldName)
        }
      }
    });
  }

  ngOnInit() {
    this.fetchEvents();
    switch (this.state) {
      case this.sharedService.STATE.EDIT: {
        if (new Date(this.defaultValue.startDate).getTime() <= this.currentDate.getTime()) {
          this.basicInfoFormGroup.get("startDate").disable()
        }
        if (new Date(this.defaultValue.endDate).getTime() <= this.currentDate.getTime()) {
          this.basicInfoFormGroup.get("endDate").disable()
        }
      }
      case this.sharedService.STATE.DUPLICATE:
      case this.sharedService.STATE.VIEW: {
          this.patchDefaultValue(this.defaultValue);
        break;
      }
    }
  }

// Sets value according to the data recieved from the backend during viewing screen.
  async patchDefaultValue(defaultValue) {
    const response: any = await this.sharedService.fetchServices(defaultValue.cityId).toPromise();
    const detailsData = response['data'];
    this.serviceDetails = detailsData.map( detail => {
        return {
          id: detail._id,
          cityId: defaultValue.cityId,
          city: defaultValue.cityName,
          service: detail.service
        };
      });
    this.campaignType = defaultValue.ruleType;
    this.setTriggerEvents()
    this.TRIGGER_EVENTS_OPTIONS = this.eventsData;
    this.basicInfoFormGroup.patchValue({
      name: defaultValue.ruleName,
      startDate: new Date(defaultValue.startDate),
      endDate: new Date(defaultValue.endDate)
    });
    if (defaultValue.leeway && defaultValue.leewayWarnMsg) {
      this.applyLeeway = true;
      this.basicInfoFormGroup.patchValue({
        leeway: defaultValue.leeway,
        leewayWarnMsg: defaultValue.leewayWarnMsg
      });
    }
    if (defaultValue.instanceLeeway && defaultValue.instanceLeewayWarnMsg) {
      this.applyInstanceLeeway = true;
      this.basicInfoFormGroup.patchValue({
        instanceLeeway: defaultValue.instanceLeeway,
        instanceLeewayWarnMsg: defaultValue.instanceLeewayWarnMsg
      });
    }
    if(defaultValue.rollingWindowDays) {
      this.applyRollingWindow = true;
      this.basicInfoFormGroup.patchValue({
        rollingWindowDays: defaultValue.rollingWindowDays
      })
      if(defaultValue.rollingWindowDays > environment.daysAfterWhichCheckHistoricAction){
        this.getCountFromHistory = true;
      }
    }
    if(defaultValue.eligibility && defaultValue.eligibility.pings && defaultValue.eligibility.events && defaultValue.eligibility.warnMsg) {
      this.applyEligibility = true;
      this.basicInfoFormGroup.patchValue({
        eligibilityPings: defaultValue.eligibility.pings,
        eligibilityEvents: defaultValue.eligibility.events,
        eligibilityWarnMsg: defaultValue.eligibility.warnMsg
      })
    }
    if (defaultValue.daprPercentage && defaultValue.daprPercentage.start && defaultValue.daprPercentage.end) {
      this.applyDaprCheck = true;
      this.basicInfoFormGroup.patchValue({ daprPercentageStart: defaultValue.daprPercentage.start, 
        daprPercentageEnd: defaultValue.daprPercentage.end});
    }
    if(defaultValue.eligibilitySegments && defaultValue.eligibilitySegments.length) {
      this.applySegmentEligibility = true;
      this.basicInfoFormGroup.patchValue({
        eligibilitySegments: defaultValue.eligibilitySegments,
      })
    }
    this.patchCity(defaultValue.serviceDetails && defaultValue.serviceDetails[0] || defaultValue.serviceDetail).then(() => {
      this.basicInfoFormGroup.patchValue({
        services: defaultValue.serviceDetails || [defaultValue.serviceDetail],
      });
    }).then(() => {
      this.serviceChangeCheck(this.getValue('services')).then(() => {
        if (defaultValue.userSelectors && defaultValue.userSelectors.length) {
          this.applySelector = true;
          this.basicInfoFormGroup.patchValue({
            selectors: defaultValue.userSelectors,
            priority: defaultValue.priority,
          });
          this.fallbackToDefaultRule = defaultValue.fallbackToDefaultRule;
        }
      });
    });
    this.patchTimeSlots(defaultValue.rules[0].timeWindows);
    this.patchTriggers(defaultValue.eventsAndReasons);
    this.makeFieldsDisable();
    this.loaderService.closeLoading();
  }

   patchCity(serviceDetail) {
     return new Promise((resolve) => {
       const interval = setInterval(() => {
        this.serviceDetails.forEach( detail => {
          if (detail.id === serviceDetail) {
            this.serviceInfo = detail;
          }
        });

        if (this.serviceInfo) {
          this.basicInfoFormGroup.patchValue({
            city:  this.serviceInfo.cityId
          });
        }
        this.onCityChange(this.getValue('city'));
        clearInterval(interval);
        return resolve({});
       }, 100);
     });
   }
   patchTimeSlots(timeSlots) {
     return new Promise((resolve) => {
      let index = 0;
      timeSlots = timeSlots.map(timeSlot => {
        return {
         startTime: this.timeToTimeStamp(timeSlot.startTime.substring(0, 2) + ":" + timeSlot.startTime.substring(2, timeSlot.startTime.length)),
         endTime: this.timeToTimeStamp(timeSlot.endTime.substring(0, 2) + ":" + timeSlot.endTime.substring(2, timeSlot.endTime.length))
        };
      });
      timeSlots.forEach(() => {
        if (!this.timeSlots.controls[index]) {
          this.timeSlots.push(this.getEmptyTimeSlot());
        }
        index++;
      });
      this.timeSlots.patchValue(timeSlots);
      return resolve({});
     });
   }
// view
   patchTriggers(events) {
     return new Promise((resolve) => {
      let index = 0;
      events = events.map((event,index) => {
        if (event.event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
          return {
            values: event.values && event.values.length ? event.values : null,
            key: event.key || null,
            event: event.event || null,
            customerReason: event.values[0].split(":")[0],
            captainReason: event.values[0].split(":")[1],
          };
        }
        else {
          return {
            values: event.values && event.values.length ? event.values : null,
            key: event.key || null,
            event: event.event || null,
            dependantEvent: event.dependantEvent || null,
            dependantEventOrder: event.dependantEventOrder || null,
          }
        }
      });
      events.forEach(() => {
        if (!this.triggerEvents.controls[index]) {
          this.triggerEvents.push(this.getEmptyTriggers());
        }
        index++;
      });
      this.basicInfoFormGroup.patchValue({
        services : this.defaultValue.serviceDetails
      })
      for (let event in events) {
        setTimeout(() => {
          this.getEventReasons(events[event].event, event);
        }, 1000);
        if (~RATING_TRIGGERS.indexOf(events[event].event)) {
          this.ratingValues[event] = {};
          events[event].values = [this.ratingToEquationTransform(events[event].values, event)];
        }
      }
      this.triggerEvents.patchValue(events);
      return resolve({});
     });
   }

   private timeToTimeStamp(time) {
    const [hr, min] = time.split(':');
    if (!hr || !min) {
      return '';
    }
    const timeStamp = new Date();
    timeStamp.setHours(parseInt(hr));
    timeStamp.setMinutes(parseInt(min));
    return timeStamp;
  }


  serviceChangeCheck(event) {
    return new Promise((resolve) => {
      this.refreshTrigger(event)
      if (event) {
        this.getUserSelectors(event);
        return resolve({});
      }
    });
  }

// called whenever service or campaign type is changed
  refreshTrigger(service) {
    if (this.isGame()) {
      this.basicInfoFormGroup.patchValue({
        eventReason: null,
      });
      this.TRIGGER_EVENTS_OPTIONS = [
        { event: "dpr", displayText: "Drop to Ping Ratio", checkForReason: false },
        { event: "streak", displayText: "Streak", checkForReason: false }]
      return
    }
    if (this.isAutomatedReactiveModel()) {
      this.TRIGGER_EVENTS_OPTIONS = [
        {
          "event": "escalation_ticket",
          "displayText": "Escalation Ticket",
          "checkForReason": true
        },
        {
          "event": "feedback_for_captain_received",
          "displayText": "Captain Feedback",
          "checkForReason": true
        },
        {
          event: "partial_offline_ride_customer_continued_response",
          displayText: "POR Customer Continued Response",
          checkForReason: false
        }
        ]
      return
    }
    if (service) {
      this.basicInfoFormGroup.patchValue({
        eventReason: null,
      });
      //setting trigger event empty on every service change
      if(!this.isAutomatedReactiveModel() && this.state === this.sharedService.STATE.CREATE || (this.state === this.sharedService.STATE.DUPLICATE && service.length != this.defaultValue.serviceDetails.length)){
        for(let i =0; i< this.triggerEvents.controls.length; i++) {
          this.enableKeyReason= [];
          this.showDependantEventFeature = [];
          this.eventReasons = [];
          this.eventReasonOptions = [];
          this.triggerEvents.controls = [];
          if (!this.triggerEvents.controls.length) {
            this.addTriggerEvent();
          }
        }
      }
    } else {
      this.basicInfoFormGroup.patchValue({
        eventReason: null,
      });
    }
  }
  campaignTypeCheck() {
    this.refreshTrigger(null)
    this.basicInfoFormGroup.reset();
  }

  fetchEvents() {
   this.penaltyBasicInfoService.fetchEvents().subscribe(result => {
      if (result && result['data'] && result['data']['data']) {
        this.eventsData = result['data']['data'];
        this.setTriggerEvents();
      }
    });
  }
  setTriggerEvents() {
    if(this.isPenaltyAndRewards()) {
      this.TRIGGER_EVENTS_OPTIONS = this.eventsData.filter(triggerEvent => (triggerEvent.event !== TRIGGER_EVENT.ESCALATION_TICKET && triggerEvent.event !==TRIGGER_EVENT.POR_CUSTOMER_CONTINUED_RIDE))
    }
    else {
      this.TRIGGER_EVENTS_OPTIONS = this.eventsData
    }
  }

  patchValuesForARM() {
    this.applySegmentEligibility = false;
    this.applyEligibility = false;
    //this.applyRollingWindow = false;
    this.applyInstanceLeeway = false;
    this.applyDaprCheck = false;
  }
  checkMaxSelectedItems(index) {
    const event = this.basicInfoFormGroup.controls.triggerEvents.value[index].event;
    if (event === TRIGGER_EVENT.RATING || this.isAutomatedReactiveModel()) {
        return 1;
    }
}
  checkMaxSelectedItemsForService() {
    if(this.isAutomatedReactiveModel()) {
      if(this.getValue('services') && this.getValue('services').length>1) {
        this.basicInfoFormGroup.patchValue({
          services:null
        });
        this.showWarningToster("Please select service again in case of Escalation Ticket.")
      }
      return 1;
    }
  }

  getEventReasons(triggerEvent: any, index) {
    this.showDependantEventFeature[index] = this.hasDependantEvent(triggerEvent);
    if (this.showDependantEventFeature[index]){
      this.setDependantEvent(triggerEvent);
    }
    if (triggerEvent === undefined) {
      this.disableRatingValues(index);
      return;
    }
    if ((triggerEvent === TRIGGER_EVENT.ESCALATION_TICKET || triggerEvent === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED) && this.isAutomatedReactiveModel()) {
      this.patchValuesForARM();
    }
    if(triggerEvent != "rating_for_captain_received" ){
      this.disableRatingValues(index)
    }
    this.basicInfoFormGroup.controls.triggerEvents.value[index].event = triggerEvent;
    delete this.basicInfoFormGroup.controls.triggerEvents.value[index].key;
    if (this.isGame())
      return;
    if (triggerEvent && (eventsWithoutReasons.indexOf(triggerEvent) > -1)) {
      this.enableKeyReason[index] = false;
      delete this.eventReasonOptions[index];
      delete this.eventReasons[index];
      delete this.basicInfoFormGroup.controls.triggerEvents.value[index].key
      return;
    }
    return new Promise((resolve, reject) => {
      let payload: any;
      if (!triggerEvent) {
        return reject();
      }
      const servicesInfo = [];
        for (let i = 0; i< this.services.length; i++) {
          const currService = this.services[i];
            if(this.getValue('services').indexOf(currService["id"]) > -1) {
              servicesInfo.push({
                serviceType: currService["serviceType"],
                serviceDetailId: currService["id"]
              })
            }
        }
      payload = {
        event: triggerEvent,
        servicesInfo
      };
      this.penaltyBasicInfoService.fetchEventReasons(payload).subscribe(
        (result) => {
          if (result && result["data"] && result["data"]["data"] ) {
            if (result["data"]["data"][0] && result["data"]["data"][0].key) {
              this.basicInfoFormGroup.controls.triggerEvents.value[index].key = result["data"]["data"][0].key;
            }
            this.eventReasons[index] = result["data"]["data"];
            if (this.eventReasons[index].length > 0) {
              if (this.eventReasons[index].length > 1) {
                this.deDuplicateKeysForARM(triggerEvent, this.eventReasons[index], index);
                this.enableKeyReason[index] = true;
                this.eventReasonOptions[index] = [];
                return resolve({});
              } else {
                this.enableKeyReason[index] = false;
                this.eventReasonOptions[index] = this.eventReasons[index][0].values;
                return resolve({});
              }
            }
            else if (this.eventReasons[index].captainPartialOfflineRideReasons && this.eventReasons[index].captainPartialOfflineRideReasons.length && this.eventReasons[index].captainPartialOfflineRideReasons.length) {
              this.customerReasonsOptions = this.eventReasons[index].customerPartialDropReasons
              this.captainReasonsOptions = this.eventReasons[index].captainPartialOfflineRideReasons
            }
            else {
              this.showWarningToster("No reasons found");
              this.enableKeyReason[index] = false;
              this.eventReasons[index] = [];
              this.eventReasonOptions[index] = [];
              return reject();
            }
          }
          else if (result && result["data"]) {
            this.basicInfoFormGroup.controls.triggerEvents.value[index].key = this.escalationTicketKey;
            this.eventReasons[index] = result["data"];
            if (this.eventReasons[index].length > 0) {
              this.eventReasonOptions[index] = this.eventReasons[index].map(eventReason => eventReason.reason);
              return resolve({});
            }
            else {
              this.showWarningToster("No reasons found");
              this.enableKeyReason[index] = false;
              this.eventReasons[index] = [];
              this.eventReasonOptions[index] = [];
              return reject();
            }
          }
        },
        (err) => {
          this.showWarningToster("error in fetching reasons");
          this.enableKeyReason[index] = false;
          this.eventReasons[index] = [];
          this.eventReasonOptions[index] = [];
          return reject();
        }
      );
    });
  }
  disableRatingValues(index){
    this.applyRatingBetween[index] = false;
    this.applyRatingGreaterOrLessThan[index] = false;
  }
  deDuplicateKeysForARM(triggerEvent, eventReasons, index) {
    let eventReasonsMap = {};
    if (this.isAutomatedReactiveModel() && triggerEvent === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED) {
      this.eventReasons[index] = eventReasons.reduce((acc, eventReason) => {
        if (!eventReasonsMap[eventReason.key]) {
          eventReasonsMap[eventReason.key] = eventReason
          acc.push(eventReason)
        }
        return acc;
      }, [])
    }
  }

  getEventReasonOptions(event, index) {
    if (!event) {
      ((this.basicInfoFormGroup.get('triggerEvents') as FormArray).at(index) as FormGroup).get('key').patchValue(null);
      this.eventReasonOptions[index] = null;
    }
    ((this.basicInfoFormGroup.get('triggerEvents') as FormArray).at(index) as FormGroup).get('values').patchValue(null);
    this.basicInfoFormGroup.controls.triggerEvents.value[index].key = event|| null;
    if (event) {
     const keyReason = this.eventReasons[index].find(eventReason => eventReason.key === event);
     this.eventReasonOptions[index] = keyReason.values;
    } else {
      this.eventReasonOptions[index] = [];
    }
    this.basicInfoFormGroup.patchValue({
      eventReason: null
    });
  }

  onCityChange(event) {
    if (this.isStreakGame()) {
      this.getUserSelectors(null);
    }
    else {
      this.getCityServiceDetails(event);
    }
  }

  async updateCities() {
    try {
    this.loaderService.openLoading();
    const res: any = await this.sharedService.fetchCities().toPromise();
    const cities = res.data.cities;
    this.cities = cities
      .map((city) => {
        return {
          id: city._id,
          name: city.displayName,
        };
      })
      .sort((c1, c2) => {
        return c1.name > c2.name ? 1 : -1;
      });
    this.loaderService.closeLoading();
    } catch (error) {
      this.loaderService.closeLoading();
      this.showWarningToster("Unable to fetch Cities");
    }

  }

  getUserSelectors(event) {
    const userType = 'rider';
    const city = this.getValue('city');
    let shifts;
    let services = [];
    if(event){
      services = event
    }
    this.basicInfoFormGroup.patchValue({
      selectors: null
    });
    this.loaderService.openLoading();
    this.sharedService.fetchRules(city, services, shifts, userType).subscribe(result => {
      if (result && result['info'] && result['info']['status'] === "success") {
        this.userSelectors = result['data']['selectors'];
      }
      if (this.userSelectors.length === 0) {
        this.showWarningToster("No User Selectors Found");
      }
      this.loaderService.closeLoading();
    }, () => {
      this.showWarningToster("Error in fetching User Selectors");
      this.loaderService.closeLoading();
    });
  }

  async getCityServiceDetails(event: any) {
    try {
      this.loaderService.openLoading();
      if (event) {
        this.basicInfoFormGroup.patchValue({
          services: null
        });
        const response: any = await this.sharedService
          .fetchServices(event)
          .toPromise();
        const services = response.data;
        this.services = services.map(
          (service: { _id: String; serviceType: String, service: { name: String, _id: String } }) => {
            return {
              id: service._id,
              name: service.service.name,
              serviceId: service.service._id,
              serviceType: service.serviceType
            };
          }
        );
        this.loaderService.closeLoading();
      } else {
        this.TRIGGER_EVENTS_OPTIONS = [];
        this.enableKeyReason = [false];
        this.basicInfoFormGroup.patchValue({
          services: null,
          eventReason: null,
          // eventReasonKey: null,
          // triggerEvent: null
        });
        delete this.services;
        this.loaderService.closeLoading();
      }
    } catch (error) {
      this.loaderService.closeLoading();
      this.showWarningToster("Unable to fetch services");
    }
  }

  addTimeSlot() {
    return new Promise((resolve) => {{
      if (this.state === this.sharedService.STATE.VIEW) {
        return resolve({});
      } else {
        this.timeSlots.push(this.getEmptyTimeSlot());
        return resolve({});
      }
    }});
  }

  deleteTimeSlot(index: number) {
    if (this.state === this.sharedService.STATE.VIEW) {
      return;
    }
    this.timeSlots.controls.splice(index, 1);
    this.timeSlots.value.splice(index, 1);
    if (!this.timeSlots.controls.length) {
      this.addTimeSlot();
    }
  }

  addTriggerEvent() {
    return new Promise((resolve) => {{
      if (this.state === this.sharedService.STATE.VIEW) {
        return resolve({});
      } else {
        this.triggerEvents.push(this.getEmptyTriggers());
        this.applyRatingBetween.push(false);
        this.enableKeyReason.push(false);
        this.showDependantEventFeature.push(false);
        this.applyRatingGreaterOrLessThan.push(false);
        return resolve({});
      }
    }});
  }

  deleteTriggerEvent(index: number) {
    if (this.state === this.sharedService.STATE.VIEW) {
      return;
    }
    this.applyRatingBetween.splice(index, 1);
    this.applyRatingGreaterOrLessThan.splice(index, 1);
    this.ratingValues.splice(index, 1);
    this.enableKeyReason.splice(index, 1);
    this.showDependantEventFeature.splice(index, 1)
    this.eventReasons.splice(index, 1);
    this.eventReasonOptions.splice(index, 1);
    this.triggerEvents.controls.splice(index, 1);
    this.triggerEvents.value.splice(index, 1);
    if (!this.triggerEvents.controls.length) {
      this.addTriggerEvent();
    }
  }

  changeInDate() {
    if (this.getValue('startDate')) {
      this.minDate = this.getValue('startDate');
    }
    if (this.getValue('endDate')) {
      this.maxDate = this.getValue('endDate');
    }
  }

  goBack() {
    this.router.navigate(['/campaigns']);
  }

  submit() {
    const data = this.organiseData();
    data && this.basicInfo.emit(data);
  }

  validateTimeSlots() {

   let isValid = true;

   const timeSlots = this.timeSlots.value;

   timeSlots.forEach(timeSlot => {
     if (!timeSlot.startTime && !timeSlot.endTime) {
       return;
     } else {
       if (timeSlot.startTime && !timeSlot.endTime) {
        isValid = false;
        return;
       } else if (timeSlot.endTime && !timeSlot.startTime) {
         isValid = false;
         return;
       } else if (this.datePipe.transform(timeSlot.startTime, 'HHmm') > this.datePipe.transform(timeSlot.endTime, 'HHmm')) {
          isValid = false;
          return;
       }
     }
   });

   return isValid;
  }
// check if you can use this instead of
  getValue( name ) {
    return this.basicInfoFormGroup.get(name).value;
  }

  addingRatingValue(position, value, index) {
    if (value.target.value) {
      let number = parseInt(value.target.value);
      this.ratingValues[index] = this.ratingValues[index] || {};
      switch(position) {
        case RATING_POSITION.END : this.ratingValues[index]['ratingBetweenEnd'] = number; break;
        case RATING_POSITION.START : this.ratingValues[index]['ratingBetweenStart'] = number; break;
        case RATING_POSITION.SINGLE : this.ratingValues[index]['ratingGreaterOrLessThan'] = number; break;
      }
    }
  }
  fetchValueForPartialOfflineRide(triggerEvent){
    return triggerEvent.customerReason + ":" + triggerEvent.captainReason
  }
  // Check if you can move this part to a new file
  validateTriggerEvents(triggerEvents:any[]) {
    console.log("Trigger events ",triggerEvents)
    console.log("Event Reasons ",this.eventReasons)
    this.events = [];

    const triggerEventsSet = new Set(triggerEvents.map(triggerEvent => triggerEvent.event))
    if(triggerEventsSet.has(TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) && (triggerEventsSet.has(BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE.DROPPED) || triggerEventsSet.has(BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE.DROPPED_SPD))) {
      this.showWarningToster("Dropped offline trigger event cannot be in combination with dropped or dropped spd trigger event")
      return false;
    }
    if(triggerEventsSet.has(TRIGGER_EVENT.POR_CUSTOMER_CONTINUED_RIDE) && triggerEventsSet.size>1) {
      this.showWarningToster("POR Customer Continued Response trigger event cannot be in combination with escalation ticket or captain feedback")
      return false;
    }
    if(!triggerEvents || !triggerEvents.length ){
      this.showWarningToster("Please add at least 1 event");
      return false;
    }

    if(!triggerEvents[0].event){
        this.showWarningToster( `Missing event in rule`)
        return false;
    }
    for (let i in triggerEvents) {
      if (this.eventReasons[i]) {
        if(this.isAutomatedReactiveModel() && triggerEvents[i].event === TRIGGER_EVENT.ESCALATION_TICKET){
          const selectedEventReason = this.eventReasons[i].find(eventReason => triggerEvents[i].values && triggerEvents[i].values[0].toLowerCase() === eventReason.reason.toLowerCase())
              if (selectedEventReason !== undefined) {
                triggerEvents[i].values[0] = selectedEventReason.context
                triggerEvents[i].key = this.escalationTicketKey
              }
              continue;
        }
        if (triggerEvents[i].event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
          triggerEvents[i].values=[]
          if (!triggerEvents[i].customerReason || !triggerEvents[i].customerReason) {
            this.showWarningToster(`Missing customer reason in rule # ${parseInt(i) + 1}`);
            return false;
          }
          if (!triggerEvents[i].captainReason || !triggerEvents[i].captainReason) {
            this.showWarningToster(`Missing captain reason in rule # ${parseInt(i) + 1}`);
            return false;
          }
          triggerEvents[i].values[0] = this.fetchValueForPartialOfflineRide(triggerEvents[i])
          triggerEvents[i].key = PARTIAL_OFFLINE_RIDE_KEY
          continue;
        }
        if ((this.eventReasons[i][0] && this.eventReasons[i][0].key && !triggerEvents[i].key)) {
          triggerEvents[i].key = this.eventReasons[i][0].key
        }

        if(!triggerEvents[i].key){
          this.showWarningToster(`Missing key reason in rule # ${parseInt(i) + 1}`);
            return false;
        }
        if(!triggerEvents[i].values || triggerEvents[i].values.length == 0 ) {
          this.showWarningToster(`Missing event reason in rule # ${parseInt(i) + 1}`);
          return false;
        }
      } else {
          triggerEvents[i].key = "";
          triggerEvents[i].values=[];
      }
      if (~RATING_TRIGGERS.indexOf(triggerEvents[i].event)) {
        if(!Object.values(EQUATION_TYPES).includes(triggerEvents[i].values[0])){
          triggerEvents[i].values=[this.getEquationForRating(triggerEvents[i].values)]
        }
        if(Object.values(EQUATION_TYPES).includes(triggerEvents[i].values[0])){
          let ratingValues = this.equationToRatingTransform(triggerEvents[i].values[0], this.ratingValues[i]);
          triggerEvents[i].values = ratingValues;
        }
      }

      if(triggerEvents[i].event === TRIGGER_EVENT.RIDER_OR_CUSTOMER_CANCELLED && this.getValue('services').length > 1) {
        this.showWarningToster(`Trigger : (Rider or Customer Cancelled) can be used for only for a single service.`);
        return false;
      }

    }
    return true;
  }

  private showWarningToster(message:string){
    this.toasterService.showToaster(
      new Toaster({
        type: ToasterType.WARNING,
        message: message,
      })
    );
  }

  mandatoryRollingWindowCheck() {
    let message = '';
    const mandatoryRollingWindowTrigger = this.getValue("triggerEvents").find((trigger)=>
      [TRIGGER_EVENT.CCR, TRIGGER_EVENT.CAPTAIN_CANCELLATIONS, TRIGGER_EVENT.TRUE_DPR, TRIGGER_EVENT.OVERALL_RATING].includes(trigger.event));
    const rollingWindow = this.getValue("rollingWindowDays");
    if (mandatoryRollingWindowTrigger && !this.applyRollingWindow) { message = 'Rolling window is required for the selected trigger event'; }
    if (mandatoryRollingWindowTrigger && this.applyRollingWindow && rollingWindow > 30) { message = 'Rolling window should be less than 30'; }
    return message;
  }
// Check if you can move this part to a new file
  organiseData () {
      const overallRatingInvalidMsg = this.mandatoryRollingWindowCheck();
      if (overallRatingInvalidMsg.length) {
        this.showWarningToster(overallRatingInvalidMsg)
        return;
      }
      if (!this.getValue('name')) {
        this.showWarningToster("Campaign Name is required");
        return;
      } else if (!this.getValue('city')) {
        this.showWarningToster("Please Select a City");
        return;
      } else if (!this.isStreakGame() && !this.getValue('services') || (this.getValue('services') && this.getValue('services').length < 1)) {
        this.showWarningToster("Please Select a Service");
        return;
      } else if (!(this.minDate && this.maxDate )) {
        this.showWarningToster("Start Date and End Date are required");
        return;
      } else if (this.minDate.getTime() > this.maxDate.getTime()) {
        this.showWarningToster("Start Date cannot be greater than End Date");
        return;
      } else if (this.state != this.sharedService.STATE.VIEW && this.state != this.sharedService.STATE.EDIT && this.minDate.getTime() < this.currentDate.getTime()) {
        this.showWarningToster("You cannot create a campaign in the past");
        return;
      } else if (this.state != this.sharedService.STATE.VIEW && this.state != this.sharedService.STATE.EDIT && this.maxDate.getTime() < this.currentDate.getTime()) {
        this.showWarningToster("You cannot create a campaign in the past");
        return;
      }
      if (this.triggerEvents && this.triggerEvents.value && this.triggerEvents.value.length) {
        const haveInstanceLeewayEvent = this.triggerEvents.value.find((trigger)=> ~INSTANCE_LEEWAY_EVENTS.indexOf(trigger.event));
        if (haveInstanceLeewayEvent && this.triggerEvents.length > 1) {
          this.showWarningToster("You cannot have more than one trigger event with the selected event.");
          return;
        }
        const allEventValues = this.triggerEvents.value.map((trigger)=> trigger.event);
        if (this.triggerEvents.length !== new Set(allEventValues).size) {
          this.showWarningToster("Cannot have same type of trigger events.");
          return;
        }
        let validTriggerEvents = this.validateTriggerEvents(this.triggerEvents.value);
        if (!validTriggerEvents) {
          return;
        }
        if (this.isAutomatedReactiveModel()) {
          if (this.triggerEvents.length > 1) {
            const selectedEscalationTicketEvent = this.triggerEvents.value[0].event === TRIGGER_EVENT.ESCALATION_TICKET ? this.triggerEvents.value[0] : this.triggerEvents.value[1]
            const selectedCaptainFeedbackEvent: any = this.triggerEvents.value[0].event === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED ? this.triggerEvents.value[0] : this.triggerEvents.value[1]
            if (selectedEscalationTicketEvent.values[0] !== selectedCaptainFeedbackEvent.key) {
              this.showWarningToster("Captain Feedback and Escalation Ticket should have the same contexts");
              return;
            }
          }
        }
      }
      if(this.applyLeeway) {
        if(!this.getValue('leeway')) {
          this.showWarningToster("Please Enter Leeway Limit");
          return;
        }
        if (!this.getValue('leewayWarnMsg')) {
          this.showWarningToster("Please Enter Leeway Warning Message");
          return;
        }
      }
      if(this.applyInstanceLeeway) {
        if(!this.getValue('instanceLeeway')) {
          this.showWarningToster("Please Enter Leeway Limit");
          return;
        }
        if (!this.getValue('instanceLeewayWarnMsg')) {
          this.showWarningToster("Please Enter Leeway Warning Message");
          return;
        }
      }
      if(this.applyEligibility) {
        if(!this.getValue('eligibilityPings')) {
          this.showWarningToster(`Please Enter eligibility Pings`);
          return;
        }
        if (!this.getValue('eligibilityEvents').length) {
          this.showWarningToster("Please add eligibility Events");
          return;
        }
        if (!this.getValue('eligibilityWarnMsg').length) {
          this.showWarningToster("Please add eligibility warning Message");
          return;
        }
      }
      if(this.applyDaprCheck) {
        if(!this.getValue('daprPercentageStart')  || !this.getValue('daprPercentageEnd')) {
          this.showWarningToster("Please Enter DAPR Percentage");
          return;
        }
      }
      if(this.applySegmentEligibility && !this.getValue('eligibilitySegments')) {
        this.showWarningToster("Please Enter Captain eligibility Segments");
        return;
      }
      let mandatoryRollingWindowEvent = null;
      if (this.triggerEvents && this.triggerEvents.value && this.triggerEvents.value.length) {
         mandatoryRollingWindowEvent = this.triggerEvents.value.find((trigger)=> ~aprDprEvents.indexOf(trigger.event));
      }
      if (this.applyRollingWindow || mandatoryRollingWindowEvent) {
        let message;
        const rollingDays = this.getValue('rollingWindowDays');
        const startDate = this.getValue('startDate');
        const endDate = this.getValue('endDate');
        // const diff = startDate && endDate && Math.ceil((endDate-startDate) / (1000 * 60 * 60 * 24)) + 1;

        if (!rollingDays || !this.applyRollingWindow) {
          message = `Please Enter Rolling Window Days`;
        } else if(rollingDays < 1) {
          message = `Rolling Window Days value is not valid. Should be greater than or equal 1`;
        // } else if (rollingDays > diff) {
        //   message = `Rolling Window Days should be less than or equal the campaign duration.`;
        } else if(!this.getCountFromHistory && rollingDays > this.daysAfterWhichCheckHistoricAction){
          message = `Rolling Window Days value is not valid. Should be less than or equal ${this.daysAfterWhichCheckHistoricAction}`;
        } else if(rollingDays > this.maxRollingWindowSpan){
          message = `Rolling Window Days value is not valid. Should be less than or equal ${this.maxRollingWindowSpan}`;
        }
        if (message) {
          this.showWarningToster(message);
          return;
        }
      }

      if (!this.validateTimeSlots()) {
        this.showWarningToster("Please Check Time Slots");
        return;
      }
      const city = this.cities.find(city => city.id === this.getValue('city'));
      const cityName = city.name;
      let services = [];
      let selectedServices;
      if(typeof(this.getValue('services')) === "string") {
        selectedServices=[];
        selectedServices.push(this.getValue('services'));
      }
      else {
        selectedServices = this.getValue('services');
      }
      let serviceName = "";
      let serviceNames = [];
      let serviceIds = [];
      for (const service in selectedServices) {
        if (selectedServices[service]) {
          const currentService = this.services.find(servicee => servicee.id === selectedServices[service]);
          if (currentService) {
            services.push(currentService);
            serviceNames.push(currentService.name);
            serviceIds.push(currentService.serviceId);
          }
        }
      }
    const data = {
      name: this.getValue('name'),
      cityId: this.getValue('city'),
      city: cityName,
      // service: selectedServices && typeof(selectedServices) === 'string' ? selectedServices : '',
      services: selectedServices,
      serviceNames,
      serviceIds,
      userSelectors: this.getValue('selectors'),
      startDate: this.minDate,
      endDate: this.maxDate,
      // triggerEvent: this.events,
      timeSlots: this.getValue('timeSlots'),
      // eventReasonKey: this.getValue('eventReasonKey'),
      fetchedEvents: this.eventsData,
      eventsAndReasons: this.triggerEvents.value,
      ruleType: this.campaignType,
      priority: this.getValue('priority')
    };
    if (this.applyLeeway) {
      data['leeway'] = this.getValue('leeway');
      data['leewayWarnMsg'] = this.getValue('leewayWarnMsg');
    }
    if (this.applyInstanceLeeway) {
      data['instanceLeeway'] = this.getValue('instanceLeeway');
      data['instanceLeewayWarnMsg'] = this.getValue('instanceLeewayWarnMsg');
    }
    if(this.applyRollingWindow) {
      data['rollingWindowDays'] = this.getValue('rollingWindowDays');
    }
    if (this.applyEligibility) {
      data['eligibility'] = {
        pings: this.getValue('eligibilityPings'),
        events:  this.getValue('eligibilityEvents'),
        warnMsg: this.getValue('eligibilityWarnMsg')
      }
    }
    if (this.applyDaprCheck) {
      data['daprPercentageStart'] = this.getValue('daprPercentageStart');
      data['daprPercentageEnd'] = this.getValue('daprPercentageEnd');
    }
    if (this.applySegmentEligibility) {
      data['eligibilitySegments'] = this.getValue('eligibilitySegments');
    }
    if (this.applySelector) {
      let selectors = this.getValue('selectors');
      let selectorNames = [];
      if (selectors && selectors.length > 0) {
        selectors.forEach(selector => {
          const userSelector = this.userSelectors.find(userSelector => userSelector._id === selector);
          selectorNames.push(userSelector.name);
        });
        data['userSelector'] = selectors;
        data['userSelectorNames'] = selectorNames;
        data['fallbackToDefaultRule'] = this.fallbackToDefaultRule;
      }
    }
    if (this.state === this.sharedService.STATE.EDIT) {
      data['_id'] = this.defaultValue._id;
    }
    data['blockRedeemEligibility'] = this.penaltyBasicInfoService.checkBlockRedeemEligibility(this.services, data);
    console.log("Final Data ",data)
    return data;
  }

  showRollingWIndowModal () {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '275px';
    dialogConfig.width = '484px';
    dialogConfig.panelClass = 'custom-dialog-container'
    this.dialog.open(RollingWindowDialogComponent, dialogConfig);
  }

  getAllowedValuesForEventReason( eventReasons:string[], index ) {
    // this.basicInfoFormGroup.controls.triggerEvents.value[index].values = eventReasons;
    if( eventReasons[0] === EQUATION_TYPES.BETWEEN ) {
      this.applyRatingBetween[index] = true;
      this.applyRatingGreaterOrLessThan[index] = false;
      this.deleteGreaterOrLesserThanRatingValue(index)

    } else if(eventReasons[0] === EQUATION_TYPES.GREATER_THAN || eventReasons[0] === EQUATION_TYPES.LESS_THAN) {
      this.applyRatingGreaterOrLessThan[index] = true;
      this.applyRatingBetween[index] = false;
      this.deleteBetweenRatingValues(index)
    } else{
      this.applyRatingGreaterOrLessThan[index] = false;
      this.applyRatingBetween[index] = false;
      this.deleteGreaterOrLesserThanRatingValue(index)
      this.deleteBetweenRatingValues(index)
    }
  }
  deleteGreaterOrLesserThanRatingValue(index: number){
    const ratingValue = this.ratingValues[index];
    if (ratingValue && ratingValue.ratingGreaterOrLessThan) {
        delete ratingValue.ratingGreaterOrLessThan;
    }
  }
  deleteBetweenRatingValues(index: number){
    const ratingValue = this.ratingValues[index];
    if (ratingValue) {
        delete ratingValue.ratingBetweenStart;
        delete ratingValue.ratingBetweenEnd;
    }
  }

  equationToRatingTransform(equation, {ratingBetweenEnd, ratingBetweenStart,ratingGreaterOrLessThan}) {
    const upperLimit = 5;
    const lowerLimit = 1;
    const ratings = [];
    if(equation === EQUATION_TYPES.GREATER_THAN) {
      for (let i = ratingGreaterOrLessThan+1 ; i <= upperLimit; i++) {
        ratings.push( i.toString() );
      }

    } else if(equation === EQUATION_TYPES.LESS_THAN) {
      for (let i = lowerLimit ; i < ratingGreaterOrLessThan; i++) {
        ratings.push( i.toString() );
      }

    } else if(equation === EQUATION_TYPES.BETWEEN) {
      for (let i = ratingBetweenStart+1 ; i < ratingBetweenEnd; i++) {
        ratings.push( i.toString() );
      }
    }
    return ratings;
  }

  ratingToEquationTransform( ratings, index ) {
    let equation = this.getEquationForRating(ratings);
    if(equation === EQUATION_TYPES.LESS_THAN) {
      this.applyRatingGreaterOrLessThan[index] = true;
      this.ratingValues[index].ratingGreaterOrLessThan = parseInt( ratings[ratings.length - 1]) + 1;
    } else if(equation === EQUATION_TYPES.GREATER_THAN ) {
      this.applyRatingGreaterOrLessThan[index] = true;
      this.ratingValues[index].ratingGreaterOrLessThan = parseInt( ratings[0]) - 1;
    } else {
      this.applyRatingBetween[index] = true;
      this.ratingValues[index].ratingBetweenStart = parseInt( ratings[0]) - 1;
      this.ratingValues[index].ratingBetweenEnd = parseInt( ratings[ratings.length - 1]) + 1;
    }
    return equation;

  }

  getEquationForRating( ratings) {
    const upperLimit = "5";
    const lowerLimit = "1";
    if( ratings.indexOf(lowerLimit) != -1 ) {
      return EQUATION_TYPES.LESS_THAN;
    } else if( ratings.indexOf(upperLimit) != -1 ) {
      return EQUATION_TYPES.GREATER_THAN;
    } else {
      return EQUATION_TYPES.BETWEEN;
    }
  }

  checkIfTriggerEvent() {
    const triggerEvents = this.basicInfoFormGroup.controls.triggerEvents.value;
    return triggerEvents.some((event) => ~INSTANCE_LEEWAY_EVENTS.indexOf(event.event));
  }

  isStreakGame(){
    return this.triggerEvents.value && this.triggerEvents.value[0].event == "streak"
  }

  gameTypeCheck(){
    if(this.getValue('city'))
    this.basicInfoFormGroup.get('city').setValue('')
  }

  checkPriority() {
    if (this.getValue('priority') && this.getValue('priority') < 0) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Priority should be greater than 0`,
      }));
    }
  }
}
