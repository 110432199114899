import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../http-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
export const BASE_URL = {
  OLD_DASHBOARD: environment.rapido_dashboard, // make this configurable
};
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  getCities = environment.server + '/api/get/cities';
  getServices = environment.server + '/api/get/services';
  getShifts = environment.server + '/api/get/shifts';
  getZones = environment.server + '/api/get/zones';
  getRules = environment.server + '/api/get/rules';
  getRuleDetails = environment.server + '/api/get/rules/details/';
  incentiveMetrics = environment.server + '/api/incentive/metrics';
  getTranslations = environment.server + '/api/translations';

  STATE = {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
    DUPLICATE: 'duplicate'
  };
  INCENTIVE_TYPES = [
    'Daily',
    'Weekly Fixed',
    'Adhoc'
  ];

  INCENTIVE_TYPES_MAP = {
    "daily": "Daily",
    "weekly": "Weekly",
    "adhoc": "Adhoc",
  }

  SEGMENT_TYPES = [
    'UHP D UHO',
    'HP D UHO',
    'HP D HO',
    'MP D UHO',
    'MP D HO',
    'MP D MO',
    'MP D LO',
    'LP D UHO',
    'LP D HO',
    'LP D MO',
    'LP D LO',
    'MP Intra UHO',
    'MP Intra HO',
    'MP Intra MO',
    'MP Intra LO',
    'LP Intra UHO',
    'LP Intra HO',
    'LP Intra MO',
    'LP Intra LO',
    'LP Inter UHO',
    'LP Inter HO',
    'LP Inter MO',
    'LP Inter LO',
    'CUSTOM'
  ];

  SUBSCRIPTION_VALIDITY_TYPES =["earnings", "rides", "unlimited"];

  fetchTranslations(locales: Array<string>, keys: Array<string>) {
    let queryparams = `?`;
    locales.forEach(locale => queryparams += `&locales[]=${locale}`);
    keys.forEach(key => queryparams += `&keys[]=${key}`);
    return this.http.get(this.getTranslations + queryparams).pipe(catchError(this.handleError));
  }

  getIncentiveMetrics(incentiveId: string) {
    return this.http.get(`${this.incentiveMetrics}?incentiveId=${incentiveId}`)
      .pipe(catchError(this.handleError));
  }

  fetchCities() {
    return this.http.get(this.getCities).pipe(catchError(this.handleError));
  }

  fetchServices(id) {
    const payload = {
      city: id
    };
    return this.http.post(this.getServices, payload).pipe(catchError(this.handleError));
  }

  fetchServicesWithMode(cityId: string) {
    return this.http.get(`${environment.server}/api/${cityId}/services`).pipe(catchError(this.handleError));
  }

  getUserSelectorDetails(ruleId: any): Observable<any> {
    return this.http.get(this.getRuleDetails + ruleId).pipe(catchError(this.handleError));
  }

  fetchShifts(city) {
    return this.http.get(this.getShifts + '/' + city).pipe(catchError(this.handleError));
  }

  fetchRules(city, service, shift, userType, selectorType = "") {
    let queryparams = '?userType=' + userType + '&city=' + city;
    if (shift) {
      queryparams += '&shift=' + shift;
    }
    if (service) {
      queryparams += '&service=' + service;
    }

    if(selectorType) {
      queryparams += '&type=' + selectorType;
    }
    return this.http.get(this.getRules + queryparams).pipe(catchError(this.handleError));
  }

  fetchZones(city: string, groupId: string) {
    let queryparams = '?cityId=' + city + '&groupId=' + groupId;
    return this.http.get(this.getZones + queryparams).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  redirectToDashbaord(url) {
    const redirectUrl = `${BASE_URL.OLD_DASHBOARD}${url}`;
    const token = this.readCookie('token');
    window.open(`${redirectUrl}?token=${token}`);
  }

  private readCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
      if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
    }
    return null;
  }

  constructor(private http: MyHttpClient) { }
}
