import { Injectable } from '@angular/core';
import { MyHttpClient } from './http-client';
import { environment } from '../environments/environment';
import { LogType } from './shared/constants';
@Injectable()
export class LogService {
  constructor(
    private http: MyHttpClient) {
  }
  logServiceCall = environment.server + '/api/logService';
  logFetchCall = environment.server + '/api/fetchLogs';

    insertEvent(id, previous, changed, metadata = {}, user) {
      const payload = {
        data: {
          type: LogType.PENALTY_CAMPAIGN,
          id,
          previous,
          changed,
          metadata,
          timeStamp: new Date().getTime()
        },
        user: user || ''
      };
      return this.http.post(this.logServiceCall, payload);
    }

    fetchLogs(id) {
      return this.http.post(this.logFetchCall, {id, type: LogType.PENALTY_CAMPAIGN});
    }
}
