import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material';
import * as _ from 'lodash';
import { STRING } from '../constants';
import { Table, TableDataTypes } from '../types';

@Component({
  selector: 'data-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {
  TableDataTypes = TableDataTypes;
  @Input() table: Table;
  @Output() paginationChange = new EventEmitter();
  @Output() headerToSort = new EventEmitter();
  displayedColumns: string[];
  sortStatus = {};
  headers = [];

  constructor() {
  }

  ngOnInit() {
    this.initializeTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.table && changes.table.currentValue) {
      this.initializeTable();
    }
  }

  initializeTable() {
    if (!this.table) {
      throw Error('Table is Not Available in input');
    }
    this.displayedColumns = Object.keys(this.table.headers);
    this.headers = this.displayedColumns.map(col => this.table.headers[col]);
    for (const x of this.displayedColumns) {
      this.sortStatus[x] = 'None';
    }
  }

  doAction(index, cb) {
    if (cb && typeof cb === STRING.FUNCTION) {
      cb(this.table.realData[index]);
    }
  }

  doSelectAction(index, headerName, buttonName) {
    const realAction = this.table.data[index] &&
      this.table.data[index][headerName] &&
      this.table.data[index][headerName].actions &&
      this.table.data[index][headerName].actions.actions.find(a => {
        return a.text === buttonName;
      });
    if (realAction) {
      if (realAction && typeof realAction.onClick === STRING.FUNCTION) {
        realAction.onClick(this.table.realData[index]);
      }
    }
  }

  onPageChange(event: PageEvent) {
    this.table.pagination.pageNo = event.pageIndex;
    this.table.pagination.perPage = event.pageSize;
    this.paginationChange.emit(this.table.pagination);
  }

  clickedHeader(event: string) {
    const headerName = (_.invert(this.table.headers))[event];
    let valToEmit = '';

    if (this.sortStatus[headerName] === 'None' || this.sortStatus[headerName] === 'desc') {
      this.sortStatus[headerName] = 'asc';
      valToEmit = headerName;
    } else {
      this.sortStatus[headerName] = 'desc';
      valToEmit = '-' + headerName;
    }
    for (const x in this.sortStatus) {
      if (x !== headerName) {
        this.sortStatus[x] = 'None';
      }
    }
    this.headerToSort.emit(valToEmit);
  }
}
