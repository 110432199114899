<div class="secondary-container shadow-all">
    <app-header [header]="header"></app-header>
    <div id="overlay"></div>
    <div>
        <form [formGroup]="communicationFormGroup">
          <div class="row padding-20">
            <div class="col-md-6">
              <div class="row">
                  <div class="padding-left-24">
                      <span class="rapido-form-field">
                          <label class="heading"> Terms & Conditions </label><br>
                          <input class="width-451 height-50 border" type="text" pInputText formControlName="termsAndCondition"/>
                      </span>
                  </div>
              </div>
              <div class="row">
                <div class="padding-left-24 add">
                    <button *ngIf="!NOT_EDITABLE.tnc" class="width-63" pButton type="button"  icon="pi pi-plus" (click)="addTermsToPreview();" label="Add"></button>
                </div>
              </div>
              <div class="row" *ngIf="(incentiveData.basicInfo && incentiveData.basicInfo.incentiveType !== 'Redeem')">
                  <div class="padding-left-24 margin-top-67">
                      <span class="rapido-form-field">
                          <label class="heading margin-top-36px"> SMS Template </label><br>
                          <button *ngIf="(communicationFormGroup.get('smsTemplate').value == '')" class="ui-button-secondary sms-template" pButton type="button" (click)="showTemplates()"  icon="pi pi-plus" label="Select template"></button>
                          <button *ngIf="(communicationFormGroup.get('smsTemplate').value !== '')" class="ui-button-secondary sms-template" pButton type="button" (click)="showTemplates()"  icon="pi pi-pencil" label="Change template"></button>
                      </span>
                  </div>
                  <div>
                      <p-dialog header="Sms Template" [(visible)]="displayTemplate" [closeOnEscape]=false [contentStyle]="{'overflow-y':'visible',width:'574px','maxHeight':'469px'}" (click)="cancel();">
                          <div class="template-border" *ngFor="let template of templates; let i = index">
                            <p class="template-text template" (click)="assignTemplate(i)">{{template.message}}</p>
                            {{hover}}
                          </div>
                      </p-dialog>
                  </div>
              </div>
              <div class="row" *ngIf="(communicationFormGroup.get('smsTemplate').value !== '') && (incentiveData.basicInfo && incentiveData.basicInfo.incentiveType !== 'Redeem')">
                <div class="padding-left-24 margin-top-67">
                    <span class="rapido-form-field">
                        <label class="heading margin-top-36px"> SMS Template Preview </label><br>
                        <input class="width-451 height-50 border" type="text" disabled pInputText formControlName="smsTemplate"/>
                        <p *ngIf="(communicationFormGroup.get('smsTemplate').value === '')" class='error'> SMS Template required. </p>
                    </span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
                <label class="heading"> T&C Preview </label><br>
                    <div class="termsPreview border">
                        <div *ngFor="let preview of termsPreview; let i = index">
                            <div class="row">
                              <div class="col-md-9">
                                <p class="preview-text-incentives preview-terms-color">{{ i + 1 }} . {{preview}}</p>
                              </div>
                              <div class="col-md-3">
                                <div class="trash-container margin-10">
                                  <div class="trash-border" *ngIf="!NOT_EDITABLE.tnc">
                                    <i class="pi pi-trash trash" (click)="deleteTerms(i)"></i>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                <p *ngIf="termsPreview.length == 0" class='error'> Terms and conditions required. </p>
            </div>
          </div>
            <div class="row padding-15">
                <div class="divider"></div>
                <div class="pull-right">
                    <span class="rapido-form-field">
                        <button class="back-button" (click)="goBack()" type="button">
                          Back
                        </button>
                      </span>
                      <span class="rapido-form-field">
                          <button class="next-button" mat-raised-button color="primary" type="button"  (click)="passCommunicationInfo()">Next</button>
                        </span>
                </div>
            </div>
        </form>
      </div>
</div>
