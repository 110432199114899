import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import * as moment from "moment";
import { SharedService } from '../../shared/shared.service'
import { DatePipe } from '@angular/common';
import {MatAccordion} from '@angular/material/expansion';
import { EVENT_NAME_MAPPINGS, defaultLocale } from '../constants';
import { CAMPAIGN_TYPE } from '../constants';
@Component({
  selector: "app-penalty-preview",
  templateUrl: "./penalty-preview.component.html",
  styleUrls: ["./penalty-preview.component.css"],
  providers: [DatePipe]
})
export class PenaltyPreviewComponent implements OnInit, OnChanges {
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Input() campaignData: any;
  @Input() defaultValue: any;
  @Input() state: string;
  @Input() NOT_EDITABLE: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public basicInfo: any;
  public rules: any;

  public penaltyRule: any;
  public serviceDetails: any;

  public eventName = EVENT_NAME_MAPPINGS;
  public campaignTypes = CAMPAIGN_TYPE;
  public eventHeaders = ['Trigger Event', 'Key Reason', 'Event Reason/Value'];
  public defaultLocale = defaultLocale;

  public ruleBasicInfoUI: any;
  public campaignType: string;
  public gameTriggerEvent: string;
  public triggerForOldRules: boolean;
  constructor(
    public sharedService: SharedService,
    private datePipe: DatePipe
    ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.campaignData = changes.campaignData.currentValue;
    if(this.campaignData && this.campaignData.basicInfo && this.campaignData.rulesChange){
      this.campaignType = this.campaignData.basicInfo.ruleType
      this.gameTriggerEvent = changes.campaignData.currentValue.basicInfo.eventsAndReasons[0].event;
      this.penaltyRule = {}
      if (this.campaignData && this.campaignData.basicInfo) {
        this.basicInfo = this.campaignData["basicInfo"];
        let timeSlots: any;
        this.penaltyRule = {
          name: this.basicInfo.name,
          startDate: this.setDate(this.basicInfo.startDate),
          endDate: this.setDate(this.basicInfo.endDate),
          city: this.basicInfo.city,
          service: this.basicInfo.serviceName,
          services: this.basicInfo.serviceNames,
          campaignType: this.basicInfo.campaignType,
          // eventReason: this.basicInfo.eventReason,
          // eventReasonKey: '',
          timeSlots: []
        };

        if(this.basicInfo.ratingBetweenStart || this.basicInfo.ratingBetweenEnd){
          this.penaltyRule.ratingBetweenEnd = this.basicInfo.ratingBetweenEnd;
          this.penaltyRule.ratingBetweenStart = this.basicInfo.ratingBetweenStart;
        }

        if(this.basicInfo.ratingGreaterOrLessThan){
          this.penaltyRule.ratingGreaterOrLessThan = this.basicInfo.ratingGreaterOrLessThan;
        }

        if (this.basicInfo.userSelectorNames) {
          this.penaltyRule.userSelector = this.basicInfo.userSelectorNames;
          this.penaltyRule.priority = this.basicInfo.priority;
        }

        // if(this.basicInfo.eventReasonKey){
        //   this.penaltyRule.eventReasonKey = this.basicInfo.eventReasonKey.displayKey
        // }

        // let eventKey = this.basicInfo.fetchedEvents.find(event => event.event === this.basicInfo.triggerEvent)
        // this.penaltyRule.triggerEvent = eventKey.displayText
        if(this.basicInfo.leeway){
          this.penaltyRule.leeway = this.basicInfo.leeway;
        }
        if(this.basicInfo.instanceLeeway){
          this.penaltyRule.instanceLeeway = this.basicInfo.instanceLeeway;
        }
        if(this.basicInfo.rollingWindowDays){
          this.penaltyRule.rollingWindowDays = this.basicInfo.rollingWindowDays;
        }
        if(this.basicInfo.eligibility && this.basicInfo.eligibility.pings && this.basicInfo.eligibility.events && this.basicInfo.eligibility.warnMessage){
          this.penaltyRule.eligibilityPings = this.basicInfo.eligibility.pings;
          this.penaltyRule.eligibilityEvents = this.basicInfo.eligibility.events.join(', ');
          this.penaltyRule.eligibilityWarnMsg = this.basicInfo.eligibility.warnMsg;
        }
        if(this.basicInfo.eligibilitySegments){
          this.penaltyRule.eligibilitySegments = this.basicInfo.eligibilitySegments.join(', ');
        }
        if(this.basicInfo.daprPercentageStart && this.basicInfo.daprPercentageEnd){
          this.penaltyRule.daprPercentageStart = this.basicInfo.daprPercentageStart;
          this.penaltyRule.daprPercentageEnd = this.basicInfo.daprPercentageEnd;
        }


        if(this.basicInfo.timeSlots.length > 0){
          timeSlots = this.basicInfo.timeSlots.map(timeSlot => {
            return {
              startTime: this.datePipe.transform(timeSlot.startTime, 'hh:mm aa'),
              endTime: this.datePipe.transform(timeSlot.endTime, 'hh:mm aa')
            }
          })
          this.penaltyRule.timeSlots = timeSlots;
        }
      }
      if (this.campaignData && this.campaignData.rulesChange) {
        this.rules = this.campaignData["rulesChange"];
        this.penaltyRule.rules = this.rules;
      }
      if(this.penaltyRule.rules.oldRules) {
        this.triggerForOldRules = true;
      }

      this.setBasicRuleInfoForUI();
    }
  }

  ngOnInit() {
    this.penaltyRule = {
      name: "",
      campaignType: "",
      startDate: "",
      endDate: "",
      city: "",
      services: "",
      timeSlots: [],
      triggerEvent: "",
      eventReason: "",
      rules: [],
      priority: ""
    };
  }

  isGame() {
    return this.campaignType == "captainGame"
  }

  setBasicRuleInfoForUI() {
    this.ruleBasicInfoUI =  [
      { title: 'Name', value: this.penaltyRule.name },
      { title: 'Campaign Type', value: this.campaignTypes[this.penaltyRule.campaignType] },
      { title: 'Start Date', value: this.penaltyRule.startDate },
      { title: 'End Date', value: this.penaltyRule.endDate },
      { title: 'City', value: this.penaltyRule.city },
      { title: 'Service', value: this.penaltyRule.services || this.penaltyRule.service },
      { title: 'Leeway', value: this.penaltyRule.leeway },
      { title: 'Instance Leeway', value: this.penaltyRule.instanceLeeway },
      { title: 'Rolling Window', value: this.penaltyRule.rollingWindowDays },
      { title: 'Eligibility Criteria Pings', value: this.penaltyRule.eligibilityPings },
      { title: 'Eligibility Criteria Events', value: this.penaltyRule.eligibilityEvents },
      { title: 'Eligibility Criteria Segments', value: this.penaltyRule.eligibilitySegments },
      { title: 'Dapr Start', value: this.penaltyRule.daprPercentageStart },
      { title: 'Dapr End', value: this.penaltyRule.daprPercentageEnd },
    ]
  }

  // getServiceName = (serviceDetail) => {
  //   let singleService = this.serviceDetails.find(detail => detail.id === serviceDetail);
  //   let serviceName = singleService.service.name;
  //   return serviceName;
  // };

  getCityName = (cityId) => {
    let cityDetail = this.serviceDetails.find(detail =>  detail.cityId === cityId )
    let city = cityDetail.city;
    return city;
  };

  setDate = (date) => {
    return moment(date).format("DD-MM-YYYY").toString();
  };

  goBack() {
    this.back.emit();
  }

  onSubmit() {
    const data = {
      submit: true,
    };
    this.submit.emit(data);
  }

  isStreakGame() {
    return this.gameTriggerEvent == "streak"
  }
}
