<app-toaster class="toaster" [config]="toasterService.toaster"></app-toaster>
<div class="main-container" *ngIf="formLoaded">
  <p class="preview-text font-size-20 text-capitalize">{{state}} Penalty & Reward Campaign</p>
  <div>
    <mat-horizontal-stepper linear #stepper class="background-stepper create-campaign">
      <ng-template matStepperIcon="edit">
        <mat-icon class="completed">done</mat-icon>
      </ng-template>
      <mat-step label="{{state}} Campaign">
          <app-penalty-basic-info [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (basicInfo)="storeCampaignInfo($event, 'basicInfo');goForward(stepper)" [campaignData]="campaignData"></app-penalty-basic-info>
      </mat-step>
      <mat-step label="{{state}} Rule">
            <app-penalty-rule [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (back)="goBack(stepper)" (rulesChange)="storeCampaignInfo($event, 'rulesChange');goForward(stepper)" [campaignData]="campaignData"></app-penalty-rule>
      </mat-step>
      <mat-step label="Preview and Finish">
          <app-penalty-preview [NOT_EDITABLE]="NOT_EDITABLE" [state]="state" [defaultValue]="defaultValue" (back)="goBack(stepper)" [campaignData]="campaignData" (submit)="storeCampaignInfo($event, '')"></app-penalty-preview>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
