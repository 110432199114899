<label class="step-header">VIEW SUBSCRIPTION RULE: {{ruleData.ruleName}}</label>
<p class="preview-text float-right color-467fcf padding-right-24 margin-top-15">
<div class="divider"></div>

<div class="view-container main-container shadow-all">
  <div>
    <form>
      <div class="row padding-60 flex-row margin-h-10" *ngIf="ruleData">
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Name</p>
          <p class="preview-text preview-terms-color">{{ruleData.ruleName}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">City</p>
          <p class="preview-text preview-terms-color">{{ruleData.cityNames}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Services</p>
          <p class="preview-text preview-terms-color">{{ruleData.serviceNames}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">User Selectors</p>
          <p class="preview-text preview-terms-color">{{ruleData.userSelectorName}}</p>
        </div>
        <div class="flex-grow-basis" *ngIf="ruleData.isAutoAssignedToHH">
          <p class="preview-text preview-text-color">HH Subscription</p>
          <p class="preview-text preview-terms-color">true</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Active</p>
          <p class="preview-text preview-terms-color">{{ruleData.active}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Exclude Incentive Types</p>
          <p class="preview-text preview-terms-color">{{ruleData.excludeIncentiveTypes}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Priority</p>
          <p class="preview-text preview-terms-color">{{ruleData.priority}}</p>
        </div>
        <div class="divider"></div>
        <div class="flex-grow-basis">
            <p class="preview-text preview-text-color">Start Date</p>
            <p class="preview-text preview-terms-color">{{ruleData.startDate}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">End Date</p>
          <p class="preview-text preview-terms-color">{{ruleData.endDate}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Rule Amount</p>
          <p class="preview-text preview-terms-color">{{ruleData.ruleAmount}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Striked out Amount</p>
          <p class="preview-text preview-terms-color">{{ruleData.strikedOutAmount}}</p>
        </div>


        <div class="divider"></div>
        <div class="flex-grow-basis">
            <p class="preview-text preview-text-color">Validity Type</p>
            <p class="preview-text preview-terms-color">{{ruleData.validity[0].type}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Max Value</p>
          <p class="preview-text preview-terms-color">{{ruleData.validity[0].maxValue || "--"}}</p>
        </div>
        <div class="flex-grow-basis">
            <p class="preview-text preview-text-color">Period in Days</p>
            <p class="preview-text preview-terms-color">{{ruleData.periodInDays}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Rule Type</p>
          <p class="preview-text preview-terms-color">{{ruleData.ruleType}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Commission Type</p>
          <p class="preview-text preview-terms-color">{{ruleData.rule[0].type}}</p>
        </div>
        <div class="flex-grow-basis">
          <p class="preview-text preview-text-color">Commission Value</p>
          <p class="preview-text preview-terms-color">{{ruleData.rule[0].value}}</p>
        </div>
        <div class="flex-grow-basis" *ngIf="ruleData.ruleTitle">
          <p class="preview-text preview-text-color">Rule Title</p>
          <p class="preview-text preview-terms-color">{{ruleData.ruleTitle}}</p>
        </div>
        <div class="flex-grow-basis" *ngIf="ruleData.isRecommended">
          <p class="preview-text preview-text-color">Rule Recommended</p>
          <p class="preview-text preview-terms-color">{{ruleData.isRecommended}}</p>
        </div>
      </div>
    </form>
  </div>
</div>