export enum ToasterType {
  NOTIFICATION,
  WARNING,
  SUCCESS,
  AVATAR,
  AVATAR_SUCCESS,
  BUTTON_INPUT,
}

export enum ToasterButtonType{
  SUCCESS,
  SECONDARY,
}

export interface IToasterConfig {
  dismissible?: boolean;
  buttons?: IToasterButton[];
  thumbnailUrl?: string;
}

export interface IToasterButton {
  type: ToasterButtonType;
  text: string;
  onClick: () => void;
}
export interface IToaster {
  type: ToasterType;
  message: string;
  title?: string;
  delay?: number;
  config?: IToasterConfig;
}
export class Toaster {
  type: ToasterType;
  message: string;
  title: string;
  delay = 2000;
  visible = false;
  config?: IToasterConfig = {};
  constructor(config: IToaster) {
    Object.assign(this, config);
    this.visible = true;
    if (this.type !== ToasterType.BUTTON_INPUT) {
      setTimeout(() => {
        this.visible = false;
      }, this.delay);
    }
  }
}
