import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SidenavContentComponent } from './shared/sidenav-content/sidenav-content.component';

import { SigninComponent } from './signin/signin.component';
import { ViewSlabsComponent } from './view-slabs/view-slabs.component';
import { ViewIncentivesComponent } from './view-incentives/view-incentives.component';
import { IAllocatorIncentiveComponent } from './i-allocator-incentive/i-allocator-incentive.component';
import { CreateIncentiveComponent } from './create-incentive/create-incentive.component';
import { AuthGuardService } from './auth-guard.service';
import { PenaltyRewardCampaignComponent } from './penalty-reward-campaign/penalty-reward-campaign.component';
import { ViewCampaignsComponent } from './view-campaigns/view-campaigns.component';
import { ViewIncentiveComponent } from './view-incentive/view-incentive.component';
import { BulkCreditInitiateComponent } from './bulkcredit/initiate/initiate.component';
import { BulkCreditHistoryComponent } from './bulkcredit/history/history.component';
import { AUDIT_CAMPAIGN_INITIATE, BULK_CREDIT_HISTORY, BULK_CREDIT_INITIATE, CREATE_SUBSCRIPTION, LIST_SUBSCRIPTIONS, VIEW_SUBSCRIPTION } from './routes';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AuditCampaignInitiateComponent } from './penalty-reward-campaign/audit-campaign/audit-campaign.component';
import { IAllocatorConstructUploadComponent } from './i-allocator-incentive/construct-upload/construct-upload.component';
import { ListSubscriptionsComponent } from './subscription/prepaid/list/list.component';
import { ViewSubscriptionRuleComponent } from './subscription/prepaid/view/view.component';
import { AppComponent } from './app.component';
const routes: Routes = [
  {
    path: 'login',
    component: SigninComponent
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    component: SidenavContentComponent,
    children: [
      {
        path: 'createIncentive',
        component: CreateIncentiveComponent,
        data: { permission: 'edit_incentive' }
      },
      {
        path: 'slabs',
        component: ViewSlabsComponent
      },
      {
        path: 'incentives',
        component: ViewIncentivesComponent
      },
      {
        path: 'viewIncentive/:incentiveId/:action',
        component: ViewIncentiveComponent,
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'incentive/:incentiveId/:action',
        component: CreateIncentiveComponent,
        runGuardsAndResolvers: 'always',
        data: { permission: 'edit_incentive' },
      },
      {
        path: 'penalty-reward-campaign',
        children: [{
          path: 'create',
          component: PenaltyRewardCampaignComponent,
        }],
        canActivate: [AuthGuardService],
        data: { permission: 'edit_penalty_reward' }
      },
      {
        path: 'campaigns',
        component: ViewCampaignsComponent,
        canActivate: [AuthGuardService],
        data: { permission: 'view_penalty_reward' }
      },
      {
        path: 'newIncentive',
        canActivate: [AuthGuardService],
        component: IAllocatorIncentiveComponent,
        data: { permission: 'edit_incentive' }
      },
      {
        path: 'uploadConstruct',
        canActivate: [AuthGuardService],
        component: IAllocatorConstructUploadComponent,
        data: { permission: 'iallocator_construct_upload' }
      },
      {
        path: 'campaign/:campaignId/:status/:action',
        component: PenaltyRewardCampaignComponent,
        data: { permission: 'view_penalty_reward' }
      },
      {
        path: AUDIT_CAMPAIGN_INITIATE,
        canActivate: [AuthGuardService],
        component: AuditCampaignInitiateComponent,
        data: { permission: 'edit_audit_campaign' }
      },
      {
        path: BULK_CREDIT_INITIATE,
        canActivate: [AuthGuardService],
        component: BulkCreditInitiateComponent,
        data: { permission: 'bulkpay_create' }
      },
      {
        path: BULK_CREDIT_HISTORY,
        canActivate: [AuthGuardService],
        component: BulkCreditHistoryComponent,
        data: { permission: 'bulkpay_history' }
      },
      {
        path: CREATE_SUBSCRIPTION,
        canActivate: [AuthGuardService],
        component: SubscriptionComponent,
        data: { permission: 'captain_subscription_create' }
      },
      {
        path: LIST_SUBSCRIPTIONS,
        canActivate: [AuthGuardService],
        component: ListSubscriptionsComponent,
      },
      {
        path: VIEW_SUBSCRIPTION,
        canActivate: [AuthGuardService],
        component: ViewSubscriptionRuleComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
