import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AUDIT_CAMPAIGN_INITIATE, BULK_CREDIT_HISTORY, BULK_CREDIT_INITIATE, CREATE_SUBSCRIPTION } from 'src/app/routes';
import { LIST_SUBSCRIPTIONS } from '../../routes';

type MenuOption = { name: string, path: string, permission?: string; };
type MenuItem = {
  name: string,
  icon: string,
  expanded?: boolean,
  options: MenuOption[];
};

const MENU_ITEMS: MenuItem[] = [
  {
    name: 'Slab',
    icon: 'directions_bike',
    options: [
      {
        name: 'View Slabs',
        path: '/slabs'
      }
    ]
  },
  {
    name: 'Incentive',
    icon: 'face',
    options: [
      {
        name: 'View Incentives',
        path: '/incentives'
      },
      {
        name: 'Create Incentive',
        path: '/createIncentive',
        permission: 'edit_incentive'
      },
      {
        name: 'iAllocator',
        path: '/newIncentive',
        permission: 'edit_incentive'
      },
      {
        name: 'iAllocator constructs upload',
        path: '/uploadConstruct',
        permission: 'iallocator_construct_upload'
      }
    ]
  },
  {
    name: 'Penalty & Reward Campaign',
    icon: 'campaign',
    options: [
      {
        name: 'View Campaigns',
        path: '/campaigns',
        permission: 'view_penalty_reward'
      },
      {
        name: 'Create Campaign',
        path: '/penalty-reward-campaign/create',
        permission: 'edit_penalty_reward'
      },
      {
        name: 'Audit Campaign',
        path: AUDIT_CAMPAIGN_INITIATE,
        permission: 'edit_audit_campaign'
      }
    ]
  },
  {
    name: 'Bulk Payment',
    icon: 'payment',
    options: [
      {
        name: 'New payment',
        path: BULK_CREDIT_INITIATE,
        permission: 'bulkpay_create'
      },
      {
        name: 'History',
        path: BULK_CREDIT_HISTORY,
        permission: 'bulkpay_history'
      }
    ]
  },
  {
    name: 'Subscriptions',
    icon: 'card_membership',
    options: [
      {
        name: 'Create Rule',
        path: CREATE_SUBSCRIPTION,
        permission: 'captain_subscription_create'
      },
      {
        name: 'List Rules',
        path: LIST_SUBSCRIPTIONS,
      }
    ]
  }
];

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnChanges {
  @Input('toggleList') isExpanded: boolean;
  @Input('access') accessRights: string[] = [];
  @Output() expand = new EventEmitter<boolean>();

  menuItems: MenuItem[] = [];

  constructor(public _route: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.accessRights) {
      this.filterMenuItems();
    }
  }

  filterMenuItems() {
    this.menuItems = MENU_ITEMS
      .map(item => {
        const options = item.options
          .filter(option => {
            return !option.permission || this.accessRights.includes(option.permission);
          });
        return { ...item, options };
      })
      .filter((item: MenuItem) => item.options.length > 0);
  }

  goto(path) {
    this._route.navigate([path]);
  }
}
