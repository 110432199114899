import { OnInit, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(datePipe) {
        this.datePipe = datePipe;
        this.selectedDates = new EventEmitter();
    }
    CalendarComponent.prototype.ngOnInit = function () {
        // this.calendarData = {
        //     type : 'calendar',
        //     title : 'From Date',
        //     values : [],
        //     default : '',
        //     key : 'from-to-date'
        //   };
        // console.log('calendarData : ', this.calendarData);
    };
    CalendarComponent.prototype.changeInDate = function () {
        var _a;
        if (this.fromDate) {
            this.minDate = this.fromDate;
        }
        if (this.toDate) {
            this.maxDate = this.toDate;
        }
        // console.log('dates : : : : ', this.fromDate, this.toDate);
        var sendStartDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
        var sendEndDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
        var emitSelections = (_a = {},
            _a[this.calendarData.key] = [sendStartDate, sendEndDate],
            _a);
        // console.log('Emitted vals : ', emitSelections);
        this.selectedDates.emit(emitSelections);
    };
    return CalendarComponent;
}());
export { CalendarComponent };
