import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { HelperService } from 'src/app/helper.service';
const DEFAULT_USER = {
  name: '...',
  role: '...',
  imageUrl: '/assets/svgImages/profile_circle.svg',
};
function getDefaultUser() {
  return Object.assign({}, DEFAULT_USER);
}
@Component({
  selector: 'app-sidenav-content',
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.css']
})
export class SidenavContentComponent implements OnInit {
  events: string[] = [];
  showLabels: boolean = true;
  toggleFilter: boolean = false;
  userRights: any = null;
  user = getDefaultUser();
  constructor(
    public router: Router,
    public authService: AuthService) {
    this.authService.getUserDetails()
      .subscribe((data: any) => {
        data.roles = data.roles || [];
        data.google = data.google || {};
        data.google.image = data.google.image || {};
        this.user.name = `${data.firstName}`;
        this.user.imageUrl = data.google.image.url || data.google.picture || this.user.imageUrl;
        this.user.role = data.roles.join(', ');
        this.userRights = data.permissions;
      });
  }

  ngOnInit() {
  }

  handleOverlay(status) {
    if (status === 0) {
      this.showOverlay();
    } else if (status === 1) {
      this.removeOverlay();
    }
  }

  removeOverlay() {
    document.getElementById('overlay').style.display = 'none';
  }
  showOverlay() {
    document.getElementById('overlay').style.display = 'block';
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  logOut() {
    this.authService.clearUserCred();
    localStorage.clear();
    this.deleteAllCookies();
    this.router.navigate(['/login']);
  }
}
