/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [".header[_ngcontent-%COMP%], .dialog-message[_ngcontent-%COMP%] {\n      text-transform: lowercase;\n      white-space: break-spaces;\n  }\n  .header[_ngcontent-%COMP%]::first-letter, .dialog-message[_ngcontent-%COMP%]::first-letter {\n      text-transform: uppercase;\n  }\n  .btn-cancel[_ngcontent-%COMP%] {\n    border: #477FCF 1px solid;\n    color: #477FCF;\n    background-color: #fff;\n  }\n  .btn-confirm[_ngcontent-%COMP%] {\n    background-color: #477FCF;\n    color: #fff;\n}"];
var RenderType_ConfirmDialogComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "padding-bottom-25"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(3, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "dialog-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-cancel"], ["style", "margin-right:10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-confirm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["", ""]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.data.cancelText; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.data.confirmText; _ck(_v, 14, 0, currVal_4); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, [[null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("keydown.esc" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onEsc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmDialogComponent, [i1.MAT_DIALOG_DATA, i1.MatDialogRef], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i0.ɵccf("app-confirm-dialog", i2.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
