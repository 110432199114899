/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i3 from "ngx-ui-loader";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-ui-loader", [["blur", "0"], ["fgsColor", "#ffffff"], ["fgsSize", "75"], ["fgsType", "three-bounce"], ["overlayColor", "rgba(0,0,0,0.29)"]], null, null, null, i2.View_ɵb_0, i2.RenderType_ɵb)), i1.ɵdid(1, 770048, null, 0, i3.ɵb, [i4.DomSanitizer, i1.ChangeDetectorRef, i3.NgxUiLoaderService], { fgsColor: [0, "fgsColor"], fgsSize: [1, "fgsSize"], fgsType: [2, "fgsType"], overlayColor: [3, "overlayColor"], hasProgressBar: [4, "hasProgressBar"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "#ffffff"; var currVal_1 = "75"; var currVal_2 = "three-bounce"; var currVal_3 = "rgba(0,0,0,0.29)"; var currVal_4 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
