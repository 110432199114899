import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { of } from 'rxjs';
import { SigninService } from './signin/signin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = null;
  private _token = null;
  public redirectUrl = null;
  private permissions = null;

  setToken(token) {
    if (!token) {
      this._token = null;
      this.user = null;
      HelperService.setCookie({
        token: '',
        user: '',
      });
      return;
    }
    this._token = token;
  }

  get isUserLoggedIn() {
    return this.token ? true : false;
  }

  async fetchUserInfo() {
    try {
      const userDetails = await this.signInService.getUserInfo().toPromise()
      this.setUserDetails(userDetails);
      return userDetails;
    } catch (error) {
      const status = error.status;
      if (status == 401) {
        this.setToken(null);
      }
    }
  }

  get token() {
    return this._token;
  }

  constructor(
    private signInService: SigninService,
  ) { }

  public async isAuthenticated(): Promise<boolean> {
    const token = localStorage.getItem('token');
    this.setToken(token);
    await this.fetchUserInfo();
  
    if (!token) {
      return false;
    }
    return true;
  }

  public clearUserCred() {
    this.setToken(null);
  }

  public getUserDetails() {
    return of(this.user);
  }

  public setUserDetails(user) {
    this.permissions = user.permissions;
    this.user = user;
  }

  public getToken() {
    return HelperService.accessCookie('token');
  }

  public clearToken() {
    this.setToken(null);
  }

  private setTokenToCookie(token) {
    HelperService.setCookie({
      token,
    })
  }

  public async getPermissions() {
    if (!this.permissions) {
      await this.fetchUserInfo();
    }
    return this.permissions as string[];
  }

  public async isAuthorizedFor(permission?: string) {
    if (!permission) {
      return true;
    }
    const permissionList = permission.split(',');
    const permissions = await this.getPermissions();
    if (!permissions) {
      return false;
    }

    return permissions.every(p => permissionList.includes(p));
  }
}
