<div class="popup">
  <div class="card">
    <mat-card>
      <mat-card-header>
        <mat-card-title>User Selector Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
          <mat-list-item role="listitem">Name: {{metrics.selectorName}}</mat-list-item>
          <mat-list-item role="listitem">Current count of captains: {{metrics.selecorUserCount}}</mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-divider></mat-divider><br>
      <mat-card-header>
        <mat-card-title>Incentive Assignment Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
          <mat-list-item role="listitem">Last run date: {{metrics.date}}</mat-list-item>
          <mat-list-item role="listitem">Status: {{metrics.status}}</mat-list-item>
          <mat-list-item role="listitem">Number of Captains assigned: {{metrics.assigned}}</mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="btn-primary medium right" (click)="close.emit()">Okay</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
