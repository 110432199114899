<div class="text-center">
  <mat-dialog-content>
    <img src="../../assets//svgImages/rolling_window.svg" class="imgStyle"/>
    <div class="dialog-heading">Rolling Window</div>
    <div>Select the rolling time period over which the number of trigger event will be counted.</div>
  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button class="dialog-btn"  mat-dialog-close>Okay</button>
  </mat-dialog-actions>
</div>