
<div class="row">
  <div class="col-md-6">
    <mat-label>From Date</mat-label><br>
    <mat-form-field  appearance="outline">
      <input matInput [(ngModel)]="fromDate" (ngModelChange)="changeInDate()" [max]="maxDate" [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" (focus)="pickerFrom.open()">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
  </div>


  <div class="col-md-6">
    <mat-label>To Date</mat-label><br>
    <mat-form-field  appearance="outline">
      <input matInput [(ngModel)]="toDate" (ngModelChange)="changeInDate()" [min]="minDate" [matDatepicker]="pickerTo" (click)="pickerTo.open()" (focus)="pickerTo.open()">
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>
  </div>
</div>
