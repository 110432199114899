import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
import { FileUpload } from 'primeng/fileupload';
import { BULK_CREDIT_HISTORY } from 'src/app/routes';
import Utils from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../loader.service';
import { AlertDialogOptions, AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { BulkCreditArrayFields, BulkCreditService } from '../bulkcredit.service';

@Component({
  selector: 'app-bulkcredit',
  templateUrl: './initiate.component.html',
  styleUrls: ['./initiate.component.css']
})
export class BulkCreditInitiateComponent implements OnInit {
  public validated: {
    totalAmount: number,
    transactionsCount: number;
  } = null;
  public validationError = false;
  public fileDetails: {
    records: any,
    name: string,
  } = null;
  private maxTransactions = environment.maxBulkCreditTransactionsPerFile;
  @ViewChild('fileUpload') fileUploadRef: FileUpload;

  public enabledTransactionTypes = this.bulkCreditService
    .transactionTypes.filter(t => !t.disabled);;

  bulkCreditFormGroup = new FormGroup({
    subTransactionType: new FormControl(null, Validators.required),
    comment: new FormControl(null, Validators.required),
    remark: new FormControl(null, Validators.minLength(1)),
  });

  constructor(
    public bulkCreditService: BulkCreditService,
    private loaderService: LoaderService,
    private alertDialogService: AlertDialogService,
    private router: Router) { }

  resetFile() {
    this.fileDetails = null;
    this.validated = null;
    this.validationError = false;
  };

  ngOnInit() {
    this.resetOnchangeOf("subTransactionType", ["remark"], () => {
      this.fileUploadRef && this.fileUploadRef.clear();
      this.resetFile();
    });
  }

  selectedSubTransactionType() {
    return this.bulkCreditFormGroup.get("subTransactionType").value;
  }

  resetOnchangeOf(controlName: string, toReset: string[], cb = () => { }) {
    this.bulkCreditFormGroup.get(controlName).valueChanges.subscribe(() => {
      toReset.forEach(control => {
        this.bulkCreditFormGroup.get(control).setValue(null);
      });
      cb();
    });
  }

  handleFile(event: { files: File[]; }) {
    this.validated = null;
    const file = event.files[0];
    this.resetFile();
    Papa.parse(file, {
      complete: (results) => {
        this.fileDetails = { records: results.data, name: file.name };
      },
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(),
      transform: (value, header: string) => this.parseCSVValue(value, header),
    });
  }

  parseCSVValue(value: string, header: string): any {
    if (BulkCreditArrayFields.includes(header)) {
      return value.split("|")
        .map(v => v.trim());
    }

    return value.trim();
  }

  downloadSampleFile() {
    const subTransactionType = this.selectedSubTransactionType();
    if (!subTransactionType) {
      return;
    }
    Utils.downloadCSVFile({
      fields: subTransactionType.headers,
      data: subTransactionType.sampleData,
      fileName: `${subTransactionType.name.toLowerCase()}.csv`,
    });
  }

  showAlertBox(options: AlertDialogOptions, cb = () => { }) {
    this.alertDialogService.open(options);
    this.alertDialogService.confirmed()
      .subscribe(confirmed => confirmed && cb());
  }

  async valdidateBulkCreditFile() {
    const valid = this.validateTransactionCount();
    if (!valid) {
      return;
    }

    this.loaderService.openLoading();
    try {
      this.validated = await this.bulkCreditService
        .valdidateBulkCreditFile(this.bulkCreditRequest());

      this.showAlertBox({
        title: 'Success',
        message: 'File validation successful.',
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loaderService.closeLoading();
    }
  }

  validateTransactionCount() {
    const transactions = this.fileDetails.records;
    const count = transactions.length;
    if (count > this.maxTransactions) {
      this.showAlertBox({
        title: 'Error',
        message: `You can only upload ${this.maxTransactions} transactions at a time.`,
      });
      return false;
    }
    return true;
  }

  confirmBulkCreditInitiate() {
    const subTransactionType = this.selectedSubTransactionType();
    let message = `You are about to credit ₹${this.validated.totalAmount} to ${this.validated.transactionsCount} captains. Do you want to proceed?`
    if (subTransactionType.type == "debit") {
      message = `You are about to debit ₹${Math.abs(this.validated.totalAmount)} from ${this.validated.transactionsCount} captains. Do you want to proceed?`
    }

    this.showAlertBox({
      title: 'Confirm',
      message,
      confirmText: 'Yes, proceed',
    }, () => this.initiateBulkCredit());
  }

  initiateBulkCredit() {
    this.loaderService.openLoading();
    this.bulkCreditService
      .initiateBulkCredit(this.bulkCreditRequest())
      .then(res => {
        this.showAlertBox({
          title: 'Success',
          message: 'Bulk pay initiated successfully.',
          confirmText: 'View history',
        }, () => this.gotoHistoryPage());
      })
      .catch(err => this.handleError(err))
      .finally(() => this.loaderService.closeLoading());
  }

  gotoHistoryPage(): void {
    this.router.navigateByUrl(BULK_CREDIT_HISTORY);
  }

  private bulkCreditRequest(): any {
    const subTransactionType = this.selectedSubTransactionType();
    return {
      subTransactionType: subTransactionType.name,
      remarks: this.bulkCreditFormGroup.get("remark").value,
      comment: this.bulkCreditFormGroup.get("comment").value,
      transactions: this.fileDetails.records,
    };
  }

  private handleError(err: any) {
    const unknownErr = "Unknown error, Please check history to get latest status of your file.";
    const res = err.error || { message: unknownErr };
    if (!res.data) {
      this.showAlertBox({
        title: 'Error',
        message: res.message,
      });
      return;
    }

    const data = res.data;
    if (data.code && data.code == "BULK_PAY_VALIDATION_ERROR") {
      this.validationError = true;
      const errors = data.errors;
      const value = errors
        .map(err => `Record: ${err.recordNumber} - ${err.error}`);

      this.showAlertBox({
        title: 'Validation Error',
        details: [
          {
            value,
            message: "Please correct the errors and try again."
          }
        ],
      });
      return;
    }

    const error = data.error || data;

    this.showAlertBox({
      title: 'Error',
      message: error.message || unknownErr,
    });
  }
}
