import { Component, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MenuItem } from 'primeng/components/common/api';
import * as _ from 'lodash';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { ViewIncentivesService } from 'src/app/view-incentives/view-incentives.service';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css'],
  providers: [ToastMessage, DatePipe, ConfirmationService]
})
export class GoalsComponent implements OnChanges, OnInit {

  constructor(private datePipe: DatePipe,
    private fb: FormBuilder,
    private toastMessage: ToastMessage,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private changeDetectorRef: ChangeDetectorRef,
    public toasterService: ToasterService, private incentiveService: ViewIncentivesService) { }

  @Output() goalsInfo: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Input() incentiveData: any;
  @Input() defaultValue: any;
  @Input() state: string;
  public header: any;
  public items: MenuItem[];
  public tempVar: any;
  public selectedVariable = [];
  public condition: any = '&&';
  public nextClicked = false;
  public warningAlert = false; // alert to show when more variables are selected
  public isAccepted: any;
  public timeSlot: any;
  public globalQualityMetric: any;
  public rules: any = [];
  public quality: any;
  public confirmationValue = true;
  public blockServiceDetailIdsForIncentiveCreation = new Set(environment.blockServiceDetailIdsForIncentiveCreation);
  public displayGoalConfirmation = false; //confirmation box before changing the variables
  public dailyIncentiveData = [
    {
      'days': 'All',
    }
  ];
  @Input() NOT_EDITABLE = {
    variables: false,
    timings: false,
    goals: false,
    weeklyDays: false,
  };
  public weeklyIncentiveData = [
    {
      'days': 'All',
      'startDay': { day: 'Monday', index: 1 },
      'endDay': { day: '', index: null }
    }
  ];
  public variableError = false;
  public days = [{ day: '', index: 0 }, { day: 'Monday', index: 1 }, { day: 'Tuesday', index: 2 },
  { day: 'Wednesday', index: 3 }, { day: 'Thursday', index: 4 },
  { day: 'Friday', index: 5 }, { day: 'Saturday', index: 6 },
  { day: 'Sunday', index: 7 }];
  public startDay: any;
  public endDay: any;
  public subSetCount = [];
  public orderChecked = false;
  public distanceChecked = false;
  public cancellationChecked = false;
  public cancellationLimitScope = "";
  public cancellationGlobalLimit = 0;
  public setsCancellationLimit = [0];
  public maxOrderDistanceChecked = false;
  public maxOrderDistanceGlobal = 0;
  public qualityChecked = false;
  public qualitativeMetrics = ['APR', 'DPR'];
  public dailyIncentiveTimeBuckets = environment.incentiveTimeBuckets;
  public overlappingTimeBuckets = {};
  private existingIncentives = null;
  public errorPopupIncentives = [];
  public hours = this.getHours();
  public startMinutes = [{"value":"00"},{"value":"15"},{"value":"30"},{"value":"45"}];
  public endMinutes = [{"value":"14"},{"value":"29"},{"value":"44"},{"value":"59"}];
  public totalOrdersMap = new Map();
  public totalDistanceMap = new Map();
  public totalAmountMap = new Map();
  public ruleWarnAmountLimit = environment.ruleWarnAmountLimit;
  public ruleMaxAmountLimit = environment.ruleMaxAmountLimit;
  public setWarnAmountLimit = environment.setWarnAmountLimit;
  public setMaxAmountLimit = environment.setMaxAmountLimit;

  private getHours(){
    let hours = [];
    let hourValues = ["00","01","02","03","04","05","06","07",
    "08","09","10","11","12","13","14",
    "15","16","17","18","19","20","21","22","23","24"];
    Array.from(hourValues).forEach(hour => {
      hours.push({"value":hour});
    });
    return hours;
  }
  // public bonusType = 'Bonus Amt';
  public redeemCondition = 'flat';

  public goalsFormGroup = new FormGroup({
    selectedCondition: new FormControl('flat', Validators.required),
    redeemGoals: this.fb.array([]),
  });

  preventDefault(event: MouseEvent) {
    event.stopPropagation();
  }

  findIndexOfDay(day) {
    for (const dayObj of this.days) {
      if (dayObj && dayObj.day && dayObj.day === day.trim()) {
        return dayObj.index;
      }
    }
    return -1;
  }

  private patchWeeklyDefaultValue(state, defaultValue) {
    const selectedCondition = defaultValue.selectedCondition;
    this.goalsFormGroup.patchValue({
      selectedCondition,
    });
    this.condition = selectedCondition;
    const incentives = [];
    let formattedWeeklyincentiveData;
    if(!defaultValue.goals){
      return;
    }
    Object.keys(defaultValue.goals).forEach((goalInDays, index) => {
      formattedWeeklyincentiveData = {};
      formattedWeeklyincentiveData['days'] = goalInDays;
      const weekDays = goalInDays.split(',');
      formattedWeeklyincentiveData['startDay'] = { day: weekDays[0].trim(), index: this.findIndexOfDay(weekDays[0]) };
      formattedWeeklyincentiveData['endDay'] = {
        day: weekDays[weekDays.length - 1].trim(),
        index: this.findIndexOfDay(weekDays[weekDays.length - 1])
      };
      const weekWiseIncentive = defaultValue.goals[goalInDays];
      weekWiseIncentive.map(incentive => {
        incentive.timeSlot = incentive.timeSlot.map(slot => {
          const [fromTimeHour, fromTimeMin] =  slot.fromTime.split(":");
          const [toTimeHour, toTimeMin] = slot.toTime.split(":")
          return {
            fromTime: this.timeToTimeStamp(slot.fromTime),
            toTime: this.timeToTimeStamp(slot.toTime),
            fromTimeHour: {value : fromTimeHour},
            fromTimeMin : {value : fromTimeMin },
            toTimeHour: {value: toTimeHour},
            toTimeMin: {value : toTimeMin},
          };
        });
        //make orders, distance non cumulative for display
        incentive.rules = this.makeOrdersAndDistanceNonCumulative(incentive.rules);
      });
      if(state === this.sharedService.STATE.EDIT){
        const incentiveStartDate = new Date(defaultValue.startDate).getTime();
        const currentDate = new Date().getTime();
        var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
        if ((t) >= 7) {
          formattedWeeklyincentiveData['disabled'] = true;
        }
        else if (t <= 6 && t >= 0) {
          if (formattedWeeklyincentiveData['startDay'].index <= new Date().getUTCDay()) {
            formattedWeeklyincentiveData['disabled'] = true;
          }
        }
      }
      weekWiseIncentive.forEach((incentive) => {
        formattedWeeklyincentiveData['1'] = incentive;
        incentives.push(formattedWeeklyincentiveData);
      });
    });

    const keepWatchOnBasicDetails = () => {
      return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          if (this.incentiveData && this.incentiveData.basicInfo) {
            clearTimeout(timer);
            return resolve({});
          }
        }, 1000);
      });
    };
    const addAllVariable = [];
    defaultValue.selectedVariable.forEach((variable) => {
      switch (variable) {
        case 'order': {
          this.orderChecked = true;
          break;
        }
        case 'distance': {
          this.distanceChecked = true;
          break;
        }
        case 'quality': {
          this.qualityChecked = true;
          break;
        }
        case 'maxOrderDistance': {
          this.maxOrderDistanceChecked = true;
          break;
        }
      }
      addAllVariable.push(keepWatchOnBasicDetails()
        .then(() => {
          console.log('patching');
          this.addVariables(variable, null, true);
        }));
    });
    Promise.all(addAllVariable).then(() => {
      // this.weeklyIncentiveData.push(formattedWeeklyincentiveData);
      this.weeklyIncentiveData = incentives;
    }).then(() => {
      for(let index in this.weeklyIncentiveData){
        let incentiveData = incentives[index]['1'];
        this.reComputeTotalAmount(index, incentiveData.rules);
        this.reComputeTotalDistance(index, incentiveData.rules);
        this.reComputeTotalOrders(index, incentiveData.rules);
      }
    });
  }

  private patchDailyDefaultValue(defaultValue) {
    const selectedCondition = defaultValue.selectedCondition;
    this.goalsFormGroup.patchValue({
      selectedCondition,
    });
    this.condition = selectedCondition;
    const incentives = [];
    const formattedDailyincentiveData = {
      'days': 'All',
    };
    Object.keys(defaultValue.goals).forEach((goalInDays, index) => {
      const dayWiseIncentive = defaultValue.goals[goalInDays];
      dayWiseIncentive.map(incentive => {
        incentive.timeSlot = incentive.timeSlot.map(slot => {
          const [fromTimeHour, fromTimeMin] =  slot.fromTime.split(":");
          const [toTimeHour, toTimeMin] = slot.toTime.split(":")
          return {
            fromTime: this.timeToTimeStamp(slot.fromTime),
            toTime: this.timeToTimeStamp(slot.toTime),
            fromTimeHour: {value : fromTimeHour},
            fromTimeMin : {value : fromTimeMin },
            toTimeHour: {value: toTimeHour},
            toTimeMin: {value : toTimeMin},
          };
        });
        //make orders, distance non cumulative for display
        incentive.rules = this.makeOrdersAndDistanceNonCumulative(incentive.rules);
      });
      dayWiseIncentive.forEach((incentive, index) => {
        formattedDailyincentiveData[index + 1] = incentive;
      });
    });
    const keepWatchOnBasicDetails = () => {
      return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          if (this.incentiveData && this.incentiveData.basicInfo) {
            clearTimeout(timer);
            return resolve({});
          }
        }, 1000);
      });
    };
    const addAllVariable = [];
    defaultValue.selectedVariable.forEach((variable) => {
      switch (variable) {
        case 'order': {
          this.orderChecked = true;
          break;
        }
        case 'distance': {
          this.distanceChecked = true;
          break;
        }
        case 'quality': {
          this.qualityChecked = true;
          break;
        }
        case 'cancellation': {
          this.cancellationChecked = true;
        }
        case 'maxOrderDistance': {
          this.maxOrderDistanceChecked = true;
        }
      }

      var goalsKey = Object.keys(defaultValue.goals)[0];
      this.setsCancellationLimit = [];
      this.cancellationGlobalLimit = defaultValue.cancellationLimit;
      this.maxOrderDistanceGlobal = defaultValue.maxOrderDistance;
      for (var set of defaultValue.goals[goalsKey]) {
        this.setsCancellationLimit.push(set.cancellationLimit);
      }
      if (this.cancellationChecked) {
        if (defaultValue.cancellationLimit != 0) {
          this.cancellationLimitScope = "global";
        } else {
          this.cancellationLimitScope = "set";
        }
      }

      addAllVariable.push(keepWatchOnBasicDetails()
        .then(() => {
          console.log('patching');
          this.addVariables(variable, null, true);
        }));
    });
    Promise.all(addAllVariable).then(() => {
      this.dailyIncentiveData = [formattedDailyincentiveData];
    }).then(() => {
      let incentives = this.dailyIncentiveData[0];
      for(let index in incentives) {
        if(index !== 'days'){
          this.reComputeTotalAmount(index, incentives[index].rules);
          this.reComputeTotalDistance(index, incentives[index].rules);
          this.reComputeTotalOrders(index, incentives[index].rules);
        }
      }    
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.incentiveData && this.incentiveData.basicInfo && !this.incentiveData.basicInfo.isCancellationLimitEnabledForCity) {
      this.cancellationChecked = false;
      this.cancellationGlobalLimit = 0;
      this.cancellationLimitScope = "";
      this.setsCancellationLimit = this.setsCancellationLimit.map(x => 0);
    }
    if (this.incentiveData && this.incentiveData.basicInfo && !this.incentiveData.basicInfo.isMaxOrderDistanceEnabledForCity) {
      this.maxOrderDistanceChecked = false;
      this.maxOrderDistanceGlobal = 0;
    }
    if (changes.incentiveData) {
      let previous = changes.incentiveData.previousValue;
      this.incentiveData = changes.incentiveData.currentValue;
      if (previous && this.incentiveData) {
        if (previous.basicInfo && (previous.basicInfo.incentiveType !== this.incentiveData.basicInfo.incentiveType)) {
          this.dailyIncentiveData = [
            {
              'days': 'All',
            }
          ];
          this.weeklyIncentiveData = [
            {
              'days': 'All',
              'startDay': { day: 'Monday', index: 1 },
              'endDay': { day: '', index: null }
            }
          ];
          this.selectedVariable = [];
          this.goalsFormGroup = new FormGroup({
            selectedCondition: new FormControl('', Validators.required),
            redeemGoals: this.fb.array([]),
          });
          this.goalsFormGroup.patchValue({ selectedCondition: 'flat' });
        }
      }
      if (this.incentiveData.basicInfo) {
        this.header = this.incentiveData.basicInfo.incentiveType + ' incentive - ' +
          this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy')
          + ' to ' + this.datePipe.transform(this.incentiveData.basicInfo.endDate, 'd MMMM yy');
        if (this.incentiveData.basicInfo.incentiveType == 'Adhoc') {
          this.header = this.incentiveData.basicInfo.incentiveType + ' incentive - ' + this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy');
        }
        if (this.incentiveData.basicInfo.incentiveType === 'Redeem' && this.redeemIncentives.value.length === 0) {
          this.addRedeemSet();
        }
      }

      if (changes.incentiveData.previousValue && !_.isEqual(changes.incentiveData.currentValue.basicInfo,
        changes.incentiveData.previousValue.basicInfo)) {
        this.existingIncentives = null;
        this.checkTimeSlots();
      }
    }
  }

  findTypeOfIncentive(state, defaultValue) {
    if (defaultValue && (defaultValue.incentiveType === 'Daily' || defaultValue.incentiveType === 'Adhoc')) {
      this.patchDailyDefaultValue(defaultValue);
    } else {
      this.patchWeeklyDefaultValue(state, defaultValue);
    }
  }

  ngOnInit() {
    this.goalsFormGroup.patchValue({ selectedCondition: 'flat' });
    this.findTypeOfIncentive(this.state, this.defaultValue)
  }

  conditionChange(condition: string) {
    this.goalsFormGroup = new FormGroup({
      selectedCondition: new FormControl(condition, Validators.required),
      redeemGoals: this.fb.array([])
    });
    this.redeemCondition = condition;
    this.addRedeemSet();
  }

  onDailyIncentiveTimeClick() {
    $('#dailyIncenitveTiming').animate({ scrollTop: 200 }, 1000);
  }

  onWeeklyIncentiveTimeClick() {
    $('#weeklyIncentive').animate({ scrollTop: 200 }, 1000);
  }

  getValues(formControl: string) {
    return this.goalsFormGroup.get(formControl).value;
  }

  erroMessage(message: any = `Please check your data`) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message,
    }));
  }

  checkRulesValdity(incentiveData = []) {

    if(this.incentiveData.basicInfo.incentiveType == "Adhoc"
      && this.incentiveData.basicInfo.incentiveDisplayName == "Short Ride Incentive"
      && !this.maxOrderDistanceChecked)
    {
      return [false, `The max order distance must be selected for Short Ride Incentive.`];
    }
    if(this.incentiveData.basicInfo.incentiveType == "Adhoc" && this.maxOrderDistanceChecked){
      if(!this.maxOrderDistanceGlobal){
        return [false, `The max order distance must be greater than 0.`];
      }
      if(this.countDecimals(this.maxOrderDistanceGlobal)>2){
        return [false, `The max order distance must be rounded to two decimals.`];
      }
    }
    const isDistanceSelected = this.selectedVariable.includes('distance');
    const isOrder = this.selectedVariable.includes('order');
    for (const incentives of incentiveData) {
      const incentiveKeys = Object.keys(incentives);
      for (const key of incentiveKeys) {
        const currentSlot = incentives[key] || {};
        const rules = currentSlot.rules || [];
        for (const rule of rules) {
          if (isOrder && (!rule.order || rule.order < 1)) {
            return [false, `The target order(s) should be more than 0.`];
          }
          if (!rule.amount || rule.amount < 1) {
            return [false, `The amount should be more than 0 INR.`];
          }
          if (rule.quality !== undefined && this.qualityChecked && (rule.quality.value == null || rule.quality.value < 0)) {
            return [false, `Percentage should be postive or 0`];
          }
          if (rule.quality !== undefined && this.qualityChecked && (rule.quality.amount == null || rule.quality.amount < 0)) {
            return [false, `Amount should be postive or 0`];
          }
          if (rule.quality !== undefined && this.qualityChecked && !rule.quality.metric) {
            return [false, `Please select APR/DPR Quality Metric`];
          }
          if (isDistanceSelected && (!rule.distance || rule.distance < 1)) {
            return [false, `The target distance should be more than 0 Km.`];
          }
          if (rule.order) {
            const orderString = rule.order.toString();
            if (orderString.split('.').length > 1) {
              return [false, `The target order(s) should not be a decimal`];
            }
          }
          if (rule.distance) {
            const distanceString = rule.distance.toString();
            if (distanceString.split('.').length > 1) {
              return [false, `The target distance should not be a decimal`];
            }
          }
          if (rule.amount) {
            const amountString = rule.amount.toString();
            if (amountString.split('.').length > 1) {
              return [false, `Amount should not be a decimal`];
            }
          }
          if (rule.quality !== undefined && rule.quality.amount) {
            const amountString = rule.quality.amount.toString();
            if (amountString.split('.').length > 1) {
              return [false, `APR/DPR Amount should not be a decimal`];
            }
          }
        }
      }
    }
    return [true, ''];
  }

  countDecimals (value) {
    if ((value % 1) != 0)
        return value.toString().split(".")[1].length;
    return 0;
  };

  private timeToTimeStamp(time = "16:10") {
    const [min, sec] = time.split(':');
    if (!min || !sec) {
      return '';
    }
    const timeStamp = new Date();
    timeStamp.setHours(parseInt(min));
    timeStamp.setMinutes(parseInt(sec));
    return timeStamp;
  }

  validateIncentiveData(incentiveData, incentiveType, isCancellationChecked = false, datePipe = null) {
    if (incentiveType === 'Daily' || incentiveType === 'Adhoc') {
      const [isDailyIncentiveSlotsValid, errMsg] = this.isDailyIncentiveTimeSlotsUnique(incentiveData, isCancellationChecked, datePipe);
      if (!isDailyIncentiveSlotsValid) {
        this.erroMessage(errMsg);
        return false;
      }
    }
    if(this.state !== this.sharedService.STATE.EDIT && this.setAmountLimitExceeded()){
      this.erroMessage(`Total amount for a set exceeded limit of ${this.setMaxAmountLimit}`);
      return false;
    }
    const [valid, err] = this.checkRulesValdity(incentiveData);
    if (!valid) {
      return this.erroMessage(err);
    }
    for (let index in incentiveData) {
      if (index) {
        const keys = Object.keys(incentiveData[index]);
        for (let key in keys) {
          if (keys[key]) {
            for (const time in incentiveData[index][keys[key]].timeSlot) {
              if (time) {
                if (incentiveData[index][keys[key]].timeSlot[time].fromTime === null ||
                  incentiveData[index][keys[key]].timeSlot[time].toTime === null) {
                  this.erroMessage();
                  return false;
                }
              }
            }

            for (const rule in incentiveData[index][keys[key]].rules) {
              if (rule) {
                if (incentiveData[index][keys[key]].rules[rule].order &&
                  incentiveData[index][keys[key]].rules[rule].order === null) {
                  this.erroMessage();
                  return false;
                }
                if (incentiveData[index][keys[key]].rules[rule].distance &&
                  incentiveData[index][keys[key]].rules[rule].distance === null) {
                  this.erroMessage();
                  return false;
                }
                if (incentiveData[index][keys[key]].rules[rule].amount) {
                    if(incentiveData[index][keys[key]].rules[rule].amount === null) {
                      this.erroMessage();
                      return false;
                    }
                    if(this.state  !== this.sharedService.STATE.EDIT){
                      if(incentiveData[index][keys[key]].rules[rule].quality 
                        && incentiveData[index][keys[key]].rules[rule].quality.amount
                        && (incentiveData[index][keys[key]].rules[rule].quality.amount + incentiveData[index][keys[key]].rules[rule].amount > this.ruleMaxAmountLimit))
                      {
                        this.erroMessage(`Individual rule amount value is greater than limit ${this.ruleMaxAmountLimit}`);
                        return false;
                      }
                      else if( incentiveData[index][keys[key]].rules[rule].amount > this.ruleMaxAmountLimit){
                        this.erroMessage(`Individual rule amount value is greater than limit ${this.ruleMaxAmountLimit}`);
                        return false;
                      }          
                    }        
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  private isDailyIncentiveTimeSlotsUnique(dailyIncentiveData, isCancellationChecked, datePipe) {
    const ERROR = {
      OVERLAPPING_TIME_SLOTS: 'Overlapping time LO',
      INVALID_TIME_SLOTS: 'Invalid time slot',
      INVALID_MINS_FOR_CANCELLATION: 'Since cancellation is checked, minute value of both start and end time should be 00'
    };
    const dailyIncenitveTimeSlots = dailyIncentiveData.reduce((acc, slotsData) => {
      Object.keys(slotsData).forEach(key => {
        const slotData = slotsData[key];
        const timeSlots = slotData.timeSlot || [];
        acc = [...acc, ...timeSlots];
      });
      return acc;
    }, []);
    for (let timeSlot of dailyIncenitveTimeSlots) {
      if (!isValidTimeSlot(timeSlot)) {
        return [false, ERROR.INVALID_TIME_SLOTS];
      }
      if (!isMinutesValidForCancellationSelected(timeSlot, isCancellationChecked, datePipe)) {
        return [false, ERROR.INVALID_MINS_FOR_CANCELLATION];
      }
    }
    let [validTimeSlot, errMsg] = isUniqueTimeSlots(dailyIncenitveTimeSlots);
    if (!validTimeSlot) {
      return [false, errMsg];
    }
    return [true, ''];
    function isUniqueTimeSlots(timeSlots = [{ fromTime: '', toTime: '' }]) {
      for (let i = 0; i < timeSlots.length; i++) {
        for (let j = i + 1; j < timeSlots.length; j++) {
          if (isTimeSlotOverLapping(timeSlots[i], timeSlots[j])) {
            return [false, ERROR.OVERLAPPING_TIME_SLOTS];
          }
        }
      }
      return [true, ''];
    }

    function isValidTimeSlot(timeSlot) {
      return timeSlot.fromTime < timeSlot.toTime;
    }

    function isMinutesValidForCancellationSelected(timeSlot = { fromTime: '', toTime: '' }, isCancellationChecked, datePipe) {
      var minutesValidation = true;
      if (isCancellationChecked) {
        var fromTimeMinutes = datePipe.transform(timeSlot.fromTime, 'HH:mm').split(":")[1];
        var toTimeMinutes = datePipe.transform(timeSlot.toTime, 'HH:mm').split(":")[1];
        minutesValidation = fromTimeMinutes == "00" && toTimeMinutes == "00";
      }
      return minutesValidation;
    }

    function isTimeSlotsEqual(timeSlot1, timeSlot2) {
      return timeSlot1.fromTime === timeSlot2.fromTime &&
        timeSlot1.toTime === timeSlot2.toTime;
    }

    function changeDateToTodays(date) {
      if (!date) {
        return date;
      }
      const currentDate = new Date();
      const selectedSlot = new Date(date);
      selectedSlot.setDate(currentDate.getDate());
      selectedSlot.setMonth(currentDate.getMonth());
      selectedSlot.setDate(currentDate.getDate());
      selectedSlot.setSeconds(0);
      return selectedSlot;
    }

    function isTimeSlotOverLapping(timeSlot1, timeSlot2) {
      timeSlot1.fromTime = changeDateToTodays(timeSlot1.fromTime);
      timeSlot1.toTime = changeDateToTodays(timeSlot1.toTime);
      timeSlot2.fromTime = changeDateToTodays(timeSlot2.fromTime);
      timeSlot2.toTime = changeDateToTodays(timeSlot2.toTime);
      if (isTimeSlotsEqual(timeSlot1, timeSlot2)) {
        return true;
      }
      if (timeSlot1.fromTime < timeSlot2.fromTime) {
        if (timeSlot1.toTime > timeSlot2.fromTime) {
          return true;
        }
      }
      if (timeSlot2.fromTime < timeSlot1.fromTime) {
        if (timeSlot2.toTime > timeSlot1.fromTime) {
          return true;
        }
      }
      return false;
    }
  }

  onOrderValueChange(obj) {
    if (obj.order < 1) {
      obj.order = '';
    }
  }

  checkIfDaysOverlap() {
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const weekDayCount = new Array(7).fill(0);
    for (let index in this.weeklyIncentiveData) {
      if (this.weeklyIncentiveData[index] && this.weeklyIncentiveData[index].days) {
        const selectedDays = this.weeklyIncentiveData[index].days;
        const splitSelectedDays = selectedDays.split(',');
        for (const day of splitSelectedDays) {
          if (day) {
            const dayIndex = weekDays.indexOf(day.trim());
            weekDayCount[dayIndex] = weekDayCount[dayIndex] + 1;
          }
        }
      }
    }
    for (const weekIndex of weekDayCount) {
      if (weekIndex > 1) {
        return true;
      }
    }
    return false;
  }

  passGoalsInfo() {
    this.nextClicked = true;
    let goalsInfo = {};
    if (this.incentiveData.basicInfo.incentiveType === 'Redeem') {
      if (!this.getValues('selectedCondition')) {
        this.erroMessage();
        return;
      }
      for (let redeem in this.redeemIncentives.value) {
        if (redeem) {
          if (this.redeemIncentives.value[redeem].redeemAmount === null) {
            this.erroMessage();
            return;
          }
          if (this.redeemIncentives.value[redeem].redeemAmount <= 0) {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: `Redeem should be greater than 0`,
            }));
            return;
          }
          if (this.redeemIncentives.value[redeem].bonusAmount <= 0) {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: `Bonus amount should be greater than 0`,
            }));
            return;
          }
        }
      }
      goalsInfo['selectedVariable'] = this.selectedVariable;
      goalsInfo['selectedCondition'] = this.getValues('selectedCondition');
      goalsInfo['redeem'] = this.redeemIncentives.value;
      this.goalsInfo.emit(goalsInfo);
    } else if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
      if (this.selectedVariable.length === 0) {
        this.erroMessage();
        this.variableError = true;
        return;
      }
      if(this.state === 'edit' ){
        const sdidIsBlocked = this.incentiveData.basicInfo.service.some(ser => this.blockServiceDetailIdsForIncentiveCreation.has(ser));
        if (sdidIsBlocked) {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: 'Cannot edit incentive as it is blocked for creation/edit',
          }));
          return;
        }
      }
      if (this.validateIncentiveData(this.dailyIncentiveData, this.incentiveData.basicInfo.incentiveType, this.cancellationChecked, this.datePipe)) {
        if (this.incentiveData.basicInfo.incentiveType === 'Daily') {
          goalsInfo['cancellationLimitScope'] = this.cancellationLimitScope;
          goalsInfo['cancellationLimit'] = this.cancellationGlobalLimit;
          goalsInfo['cancellationChecked'] = this.cancellationChecked;
          goalsInfo['setsCancellationLimit'] = this.setsCancellationLimit;
         }
        else if(this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
          goalsInfo['maxOrderDistanceGlobal'] = this.maxOrderDistanceGlobal;
          goalsInfo['maxOrderDistanceChecked'] = this.maxOrderDistanceChecked;
        }
        goalsInfo['selectedVariable'] = this.selectedVariable;
        goalsInfo['selectedCondition'] = this.condition;
        goalsInfo['dailyIncentiveData'] = this.dailyIncentiveData;
        this.goalsInfo.emit(goalsInfo);
      } else {
        return;
      }
    } else if (this.state === 'edit' && this.incentiveData.basicInfo.incentiveType === 'Weekly Fixed') {
      for (var item of this.weeklyIncentiveData) {
        if (item) {
          if (item.startDay.index > item.endDay.index) {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Please check the selected days in set ' + (parseInt(index) + 1),
            }));
            return;
          }
        }
      }
      if (this.selectedVariable.length === 0) {
        this.erroMessage();
        this.variableError = true;
        return;
      }
      if (this.checkIfDaysOverlap()) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Overlapping of days are not allowed',
        }));
        return;
      }
      const incentiveStartDate = new Date(this.incentiveData.basicInfo.startDate).getTime();
      const currentDate = new Date().getTime();
      var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
      if ((t) >= 7) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Cannot edit incentive which has already run for a week',
        }));
        return;
      }
      const sdidIsBlocked = this.incentiveData.basicInfo.service.some(ser => this.blockServiceDetailIdsForIncentiveCreation.has(ser));
      if (sdidIsBlocked) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Cannot edit incentive as it is blocked for creation/edit',
        }));
        return;
      }
      if (this.validateIncentiveData(this.weeklyIncentiveData, this.incentiveData.basicInfo.incentiveType)) {
        goalsInfo['selectedVariable'] = this.selectedVariable;
        goalsInfo['selectedCondition'] = this.condition;
        goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
        console.log('Goals Info Weekly: ', goalsInfo);
        this.goalsInfo.emit(goalsInfo);
      } else {
        return;
      }
    } else {
      for (var index in this.weeklyIncentiveData) {
        if (this.weeklyIncentiveData[index] && this.weeklyIncentiveData[index]['1'] && this.weeklyIncentiveData[index]['1']['timeSlot']) {
          if (this.weeklyIncentiveData[index].startDay.index > this.weeklyIncentiveData[index].endDay.index) {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Please check the selected days in set ' + (parseInt(index) + 1),
            }));
            return;
          }
          if (!this.weeklyIncentiveData[index]['1']['timeSlot'].every(timeSlot => timeSlot.fromTime < timeSlot.toTime)) {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Invalid time slot in set ' + (parseInt(index) + 1),
            }));
            return;
          }
        }
      }
      if (this.selectedVariable.length === 0) {
        this.erroMessage();
        this.variableError = true;
        return;
      }
      if (this.checkIfDaysOverlap()) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Overlapping of days are not allowed',
        }));
        return;
      }
      if(!this.qualityChecked){
        this.weeklyIncentiveData.forEach(element => {
          element['1'].rules.forEach(rule => {
            delete rule["quality"];
          });
        });
      }
      if (this.validateIncentiveData(this.weeklyIncentiveData, this.incentiveData.basicInfo.incentiveType)) {
        goalsInfo['selectedVariable'] = this.selectedVariable;
        goalsInfo['selectedCondition'] = this.condition;
        goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
        console.log('Goals Info Weekly: ', goalsInfo);
        this.goalsInfo.emit(goalsInfo);
      } else {
        return;
      }
    }
  }

  get redeemIncentives() {
    return this.goalsFormGroup.get('redeemGoals') as FormArray;
  }

  addRedeemSet() {
    let redeemObj;
    if (this.getValues('selectedCondition') === 'flat') {
      redeemObj = this.fb.group({
        redeemAmount: null,
        bonusAmount: null
      });
    } else {
      redeemObj = this.fb.group({
        redeemAmount: null,
        bonusPercentage: null
      });
    }

    this.redeemIncentives.push(redeemObj);
    console.log(this.redeemIncentives);
  }

  deleteRedeemSet(index: number) {
    console.log('RedeemSet : ', this.redeemIncentives, index);
    this.redeemIncentives.removeAt(index);
    console.log('RedeemSet : ', this.redeemIncentives.value, index);
  }

  addVariables(variable: string, event: any, silently = false) {
    if (this.NOT_EDITABLE.variables && !silently) {
      return;
    }
    //remove variable from array
    if (!silently && this.selectedVariable.length >= 1) {
      this.confirmationValue = confirm('Are you sure you want to continue with changes?');
    }
    if (silently || this.confirmationValue) {
      //clear total value maps
      this.totalOrdersMap.clear();
      this.totalDistanceMap.clear();
      this.totalAmountMap.clear();
      if (this.selectedVariable.indexOf(variable) !== -1) {
        this.selectedVariable.splice(this.selectedVariable.indexOf(variable), 1);
        if (this.selectedVariable.length <= 1) {
          this.condition = '&&';
        }
      } else { // add variable to array
        this.selectedVariable.push(variable);
        if (this.selectedVariable.length > 1) {
          this.condition = this.condition || '&&';
        }
      }

      if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
        this.modifyData();
      } else {
        if (this.selectedVariable.indexOf(variable) !== -1) {
          if (variable === 'quality') {
            this.qualityChecked = true;
          }
        }
        else {
          if (variable === 'quality') {
            this.qualityChecked = false;
          }
        }
        this.modifyWeeklyData();
      }
    } else {
      event && event.preventDefault();
      if (this.selectedVariable.indexOf(variable) !== -1) {
        if (variable === 'order') {
          this.orderChecked = true;
        }
        if (variable === 'distance') {
          this.distanceChecked = true;
        }
        if (variable === 'quality') {
          this.qualityChecked = true;
        }
      } else {
        if (variable === 'order') {
          this.orderChecked = false;
        }
        if (variable === 'distance') {
          this.distanceChecked = false;
        }
        if (variable === 'quality') {
          this.qualityChecked = false;
        }
      }
    }
    if (this.variableError) {
      this.variableError = false;
    }
  }

  resetDailyIncentiveGoals() {
    this.dailyIncentiveData.forEach(data => {
      Object.keys(data).forEach(key => {
        const incentive = data[key] || {};
        const rules = incentive.rules;
        if (rules) {

          rules.forEach(rule => {

            rule.amount = null;
            rule.distance = null;
            rule.order = null;
          });
        }
      });
    });
  }

  resetWeeklyIncentiveGoals() {
    this.weeklyIncentiveData.forEach(data => {
      Object.keys(data).forEach(key => {
        const incentive = data[key] || {};
        const rules = incentive.rules;
        if (rules) {
          rules.forEach(rule => {
            rule.amount = null;
            rule.distance = null;
            rule.order = null;
            rule.quality = {};
            rule.quality.metric = null;
            rule.quality.value = null;
            rule.quality.amount = null;
          });
        }
      });
    });
  }

  modifyWeeklyData() {
    console.log(this.weeklyIncentiveData);
    function copyObj(obj) {
      try {
        return JSON.parse(JSON.stringify(obj));
      }
      catch (err) {
        return {};
      }
    }
    let ruleObj = {};
    this.rules = [];
    this.resetWeeklyIncentiveGoals();

    if (this.weeklyIncentiveData.length > 1) {
      for (let index in this.weeklyIncentiveData) {

        if (this.weeklyIncentiveData[index]) {
          let ruleIndex = 1;
          const keys = Object.keys(this.weeklyIncentiveData[index]);
          for (var key in keys) {
            if (keys[key] !== 'days' && keys[key] !== 'startDay' && keys[key] !== 'endDay') {
              try {
                // this.dailyIncentiveData[index][keys[key]].rules = []; //deleting the rules
                this.weeklyIncentiveData[index][keys[key]].rules.forEach(rule => {
                  delete rule.amount;
                  delete rule.distance;
                  delete rule.order;
                  // delete rule.quality;
                });
                this.rules = [];
                //adding the newly selected variables
                for (let i in this.selectedVariable) {
                  if (i) {
                    ruleObj[this.selectedVariable[i]] = null;
                    if (this.selectedVariable[i] === 'quality') {
                      ruleObj[this.selectedVariable[i]] = {};
                      ruleObj[this.selectedVariable[i]].metric = null;
                      ruleObj[this.selectedVariable[i]].value = null;
                      ruleObj[this.selectedVariable[i]].amount = null;
                    }
                  }
                }
                ruleObj['index'] = ruleIndex;
                ruleIndex++;
                this.rules.push(copyObj(ruleObj));
                // this.rules.push(ruleObj);
                // this.dailyIncentiveData[index][keys[key]]['rules'] = this.rules.slice();
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
      }
    } else {
      for (let index in this.weeklyIncentiveData) {
        if (index) {
          // add time and selected rules
          this.timeSlot = [{ fromTime: null, toTime: null }];
          for (const i in this.selectedVariable) {
            if (i) {
              ruleObj[this.selectedVariable[i]] = null;
              if (this.selectedVariable[i] === 'quality') {
                ruleObj[this.selectedVariable[i]] = {};
                ruleObj[this.selectedVariable[i]].metric = null;
                ruleObj[this.selectedVariable[i]].value = null;
                ruleObj[this.selectedVariable[i]].amount = null;
              }
            }
          }
          this.weeklyIncentiveData[index][1] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
          ruleObj['index'] = 1;
          this.rules.push(ruleObj);
          this.weeklyIncentiveData[index][1]['timeSlot'] = this.timeSlot;
          this.weeklyIncentiveData[index][1]['rules'] = this.rules;
          this.subSetCount[parseInt(index)] = 1;
        }
      }
    }
  }

  modifyData() {
    function copyObj(obj) {
      try {
        return JSON.parse(JSON.stringify(obj));
      }
      catch (err) {
        return {};
      }
    }
    let ruleObj = {};
    this.rules = [];
    this.resetDailyIncentiveGoals();
    for (let index in this.dailyIncentiveData) {
      //creating for first set (0)
      if (Object.keys(this.dailyIncentiveData[index]).length === 1) {
        // add time and selected rules
        this.timeSlot = [{ fromTime: null, toTime: null }];
        for (let i in this.selectedVariable) {
          if (i && this.selectedVariable[i] != "cancellation") {
            ruleObj[this.selectedVariable[i]] = null;
          }
        }
        this.dailyIncentiveData[index][1] = { timeSlot: [], rules: [] };
        ruleObj['index'] = 1;
        this.rules.push(ruleObj);
        this.dailyIncentiveData[index][1]['timeSlot'] = this.timeSlot;
        this.dailyIncentiveData[index][1]['rules'] = this.rules;
      } else { // when more than one variable is selected
        // loop through the Object keys and delete the rules
        let ruleIndex = 1;
        const keys = Object.keys(this.dailyIncentiveData[index]);
        for (var key in keys) {
          if (keys[key] !== 'days') {
            try {
              // this.dailyIncentiveData[index][keys[key]].rules = []; //deleting the rules
              this.dailyIncentiveData[index][keys[key]].rules.forEach(rule => {
                delete rule.amount;
                delete rule.distance;
                delete rule.order;
                // delete rule.quality;

              });
              this.rules = [];
              //adding the newly selected variables
              for (let i in this.selectedVariable) {

                if (i && this.selectedVariable[i] != "cancellation") {
                  ruleObj[this.selectedVariable[i]] = null;
                  if (this.selectedVariable[i] === 'quality') {
                    ruleObj[this.selectedVariable[i]] = {};
                    ruleObj[this.selectedVariable[i]].metric = null;
                    ruleObj[this.selectedVariable[i]].value = null;
                    ruleObj[this.selectedVariable[i]].amount = null;
                  }
                }
              }
              ruleObj['index'] = ruleIndex;
              ruleIndex++;
              this.rules.push(copyObj(ruleObj));
              // this.rules.push(ruleObj);
              // this.dailyIncentiveData[index][keys[key]]['rules'] = this.rules.slice();
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    }
  }

  generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  addSet() {
    console.log(this.weeklyIncentiveData);
    let ruleObj = {};
    if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
      if (this.incentiveData.basicInfo.incentiveType === 'Daily') {
        this.setsCancellationLimit.push(0);
      }
      for (const index in this.dailyIncentiveData) {
        if (index) {
          let keys = Object.keys(this.dailyIncentiveData[index]);
          keys.splice(keys.indexOf('days'), 1);
          const newSet = keys.length + 1;
          this.timeSlot = [{ fromTime: null, toTime: null }];
          this.dailyIncentiveData[index][newSet] = { timeSlot: [], rules: [] };
          this.dailyIncentiveData[index][newSet]['timeSlot'] = this.timeSlot;
          for (let i in this.selectedVariable) {
            if (i && this.selectedVariable[i] != "cancellation") {
              ruleObj[this.selectedVariable[i]] = null;
            }
          }
          ruleObj['index'] = 1;
          this.rules = [];
          this.rules.push(ruleObj);
          this.dailyIncentiveData[index][newSet]['rules'] = this.rules;
        }
      }
    } else {
      this.rules = [];
      this.quality = {};
      const keys = Object.keys(this.weeklyIncentiveData);
      const newSet = keys.length;
      if (!this.weeklyIncentiveData[newSet - 1].endDay.day) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Please select end day before creating new set`,
        }));
        return;
      }
      let nextStartDay;
      if (this.weeklyIncentiveData[newSet - 1].endDay.index === this.days.length - 1) {
        nextStartDay = { day: 'Monday', index: 1 };
      } else {
        for (let i in this.days) {
          if (this.days[i].index === this.weeklyIncentiveData[newSet - 1].endDay.index) {
            nextStartDay = this.days[parseInt(i) + 1];
            break;
          }
        }
      }
      let weeklyObj = { days: 'All', startDay: nextStartDay, endDay: { day: '', index: null } };
      this.weeklyIncentiveData.push(weeklyObj);
      this.weeklyIncentiveData[newSet][1] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
      this.timeSlot = [{ fromTime: null, toTime: null }];
      for (const i in this.selectedVariable) {
        if (i) {
          ruleObj[this.selectedVariable[i]] = null;
        }
      }
      ruleObj['index'] = 1;
      this.rules.push(ruleObj);
      this.weeklyIncentiveData[newSet][1]['timeSlot'] = this.timeSlot;
      this.weeklyIncentiveData[newSet][1]['rules'] = this.rules;
      if (this.weeklyIncentiveData[newSet - 1][1]['rules'][0]['quality']) {
        this.weeklyIncentiveData[newSet][1]['rules'][0]['quality'] = this.quality;
        this.weeklyIncentiveData[newSet][1]['rules'][0]['quality']['metric'] = this.globalQualityMetric;
      }
      this.subSetCount[newSet] = 1;
      this.changeDetectorRef.detectChanges();
    }
  }

  addSubset(setIndex: number) {
    let ruleObj = {};
    // let keys = Object.keys(this.weeklyIncentiveData[setIndex]);
    // keys.splice(keys.indexOf('days'), 1);
    const newSet = this.subSetCount[setIndex] + 1;
    this.subSetCount[setIndex] = newSet;
    this.timeSlot = [{ fromTime: null, toTime: null }];
    this.weeklyIncentiveData[setIndex][newSet] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
    this.weeklyIncentiveData[setIndex][newSet]['timeSlot'] = this.timeSlot;
    for (let i in this.selectedVariable) {
      if (i && this.selectedVariable[i] != "cancellation") {
        ruleObj[this.selectedVariable[i]] = null;
        if (this.selectedVariable[i] === 'quality') {
          ruleObj[this.selectedVariable[i]] = {};
          ruleObj[this.selectedVariable[i]].metric = null;
          ruleObj[this.selectedVariable[i]].value = null;
          ruleObj[this.selectedVariable[i]].amount = null;
        }
      }
    }
    ruleObj['index'] = 1;
    this.rules = [];
    this.rules.push(ruleObj);
    this.weeklyIncentiveData[setIndex][newSet]['rules'] = this.rules;
  }

  deleteSet(setIndex: string) {
    delete this.setsCancellationLimit[parseInt(setIndex) - 1];
    let backUpIncentive = [], set;
    for (const index in this.dailyIncentiveData) {
      if (index) {
        const keys = Object.keys(this.dailyIncentiveData[index]);
        keys.splice(keys.indexOf('days'), 1);
        if (keys.length === parseInt(setIndex)) {
          delete this.dailyIncentiveData[index][setIndex];
          break;
        } else {
          backUpIncentive = this.dailyIncentiveData;
          for (let i = parseInt(setIndex); i < keys.length; i++) {
            if (i) {
              set = (parseInt(setIndex) + 1).toString();
              // backUpIncentive[index][i] = this.dailyIncentiveData[index][i];
              backUpIncentive[index][i] = this.dailyIncentiveData[index][set];
            }
          }
          delete backUpIncentive[index][keys.length];
        }
      }
    }
  }

  deleteSubSet(setIndex: number, subsetIndex: string) {
    console.log(this.weeklyIncentiveData[setIndex][subsetIndex]);
    let backUpIncentive = this.weeklyIncentiveData, set;
    const keys = Object.keys(this.weeklyIncentiveData[setIndex]);
    console.log('Keys : ', keys);
    keys.splice(keys.indexOf('days'), 1);
    keys.splice(keys.indexOf('startDay'), 1);
    keys.splice(keys.indexOf('endDay'), 1);
    console.log('Keys : ', keys);
    for (let i = parseInt(subsetIndex); i < keys.length; i++) {
      if (i) {
        set = (parseInt(subsetIndex) + 1).toString();
        backUpIncentive[setIndex][i] = this.weeklyIncentiveData[setIndex][set];
      }
    }
    delete backUpIncentive[setIndex][keys.length];
    this.subSetCount[setIndex] = this.subSetCount[setIndex] - 1;
    console.log('Delete subset : ', this.weeklyIncentiveData);
  }

  deleteWeeklySet(setIndex: number) {
    let backUpIncentive = [], set;
    const keys = Object.keys(this.weeklyIncentiveData);
    if (keys.length === (setIndex + 1)) {
      this.weeklyIncentiveData.splice(setIndex, 1);
    } else {
      backUpIncentive = this.weeklyIncentiveData;
      for (let i = setIndex; i < (keys.length - 1); i++) {
        backUpIncentive[i] = this.weeklyIncentiveData[i + 1];
      }
      backUpIncentive.splice(keys.length - 1, 1);
    }
  }

  addTimeSlot(setIndex: string) {
    for (let index in this.dailyIncentiveData) {
      if (index) {
        const keys = Object.keys(this.dailyIncentiveData[index]);
        for (let key in keys) {
          if (keys[key] == setIndex) {
            this.dailyIncentiveData[index][setIndex].timeSlot = [
              ...this.dailyIncentiveData[index][setIndex].timeSlot, { fromTime: null, toTime: null }];
            break;
          }
        }
      }
    }
  }

  addWeeklyTimeSlot(subsetIndex: string, setIndex: number) {
    for (let index in this.weeklyIncentiveData) {
      if (index === setIndex.toString()) {
        const keys = Object.keys(this.weeklyIncentiveData[index]);
        for (let key in keys) {
          if (keys[key] === subsetIndex) {
            this.weeklyIncentiveData[setIndex][subsetIndex].timeSlot = [
              ...this.weeklyIncentiveData[setIndex][subsetIndex].timeSlot, { fromTime: null, toTime: null }];
            break;
          }
        }
      }
    }
  }

  deleteTimeSlot(setIndex: string, timeSlotIndex: string) {
    for (const index in this.dailyIncentiveData) {
      if (index) {
        const keys = Object.keys(this.dailyIncentiveData[index]);
        for (const key in keys) {
          if (keys[key] == setIndex) {
            let timeSlot = this.dailyIncentiveData[index][setIndex].timeSlot;
            this.dailyIncentiveData[index][setIndex].timeSlot.splice(timeSlotIndex, 1);
            break;
          }
        }
      }
    }
  }

  deleteWeeklyTimeSlot(setIndex: number, subsetIndex: string, timeSlotIndex: string) {
    for (const index in this.weeklyIncentiveData) {
      if (index === setIndex.toString()) {
        const keys = Object.keys(this.weeklyIncentiveData[index]);
        for (const key in keys) {
          if (keys[key] === subsetIndex) {
            let timeSlot = this.weeklyIncentiveData[index][subsetIndex].timeSlot;
            this.weeklyIncentiveData[index][subsetIndex].timeSlot.splice(timeSlotIndex, 1);
            break;
          }
        }
      }
    }
  }


  addGoals(setIndex: string, ruleIndex) {
    let ruleObj = {};
    for (let i in this.selectedVariable) {
      if (i && this.selectedVariable[i] != "cancellation") {
        ruleObj[this.selectedVariable[i]] = null;
        if (this.selectedVariable[i] === 'quality') {
          ruleObj[this.selectedVariable[i]] = {};
          ruleObj[this.selectedVariable[i]].metric = null;
          ruleObj[this.selectedVariable[i]].value = null;
          ruleObj[this.selectedVariable[i]].amount = null;
        }
      }
    }

    ruleObj['index'] = ruleIndex + 1;
    for (let index in this.dailyIncentiveData) {
      if (index) {
        const keys = Object.keys(this.dailyIncentiveData[index]);
        for (let key in keys) {
          if (keys[key] == setIndex) {
            this.dailyIncentiveData[index][setIndex].rules = [...this.dailyIncentiveData[index][setIndex].rules, ruleObj];
            break;
          }
        }
      }
    }
  }

  addWeeklyGoals(setIndex: number, subsetIndex: string, ruleIndex: number) {
    let ruleObj = {};
    for (let i in this.selectedVariable) {
      if (i) {
        ruleObj[this.selectedVariable[i]] = null;
        if (this.selectedVariable[i] === 'quality') {
          ruleObj[this.selectedVariable[i]] = {};
          ruleObj[this.selectedVariable[i]].metric = this.globalQualityMetric;
          ruleObj[this.selectedVariable[i]].value = null;
          ruleObj[this.selectedVariable[i]].amount = null;
        }
      }
    }
    ruleObj['index'] = ruleIndex + 1;
    for (let index in this.weeklyIncentiveData) {
      if (index === setIndex.toString()) {
        const keys = Object.keys(this.weeklyIncentiveData[index]);
        for (let key in keys) {
          if (keys[key] === subsetIndex) {
            this.weeklyIncentiveData[index][subsetIndex].rules =
              [...this.weeklyIncentiveData[index][subsetIndex].rules, ruleObj];
            break;
          }
        }
      }
    }
  }

  deleteGoals(setIndex: string, ruleIndex: string, rules: any) {
    for (const index in this.dailyIncentiveData) {
      if (index) {
        const keys = Object.keys(this.dailyIncentiveData[index]);
        for (const key in keys) {
          if (keys[key] == setIndex) {
            this.dailyIncentiveData[index][setIndex].rules.splice(ruleIndex, 1);
            break;
          }
        }
      }
    }
    //recompute all vars 
    this.reComputeTotalAmount(setIndex, rules);
    this.reComputeTotalDistance(setIndex, rules);
    this.reComputeTotalOrders(setIndex, rules);

  }

  deleteWeeklyGoals(setIndex: number, subsetIndex: string, ruleIndex: string, rules: any) {
    for (const index in this.weeklyIncentiveData) {
      if (index === setIndex.toString()) {
        const keys = Object.keys(this.weeklyIncentiveData[index]);
        for (const key in keys) {
          if (keys[key] === subsetIndex) {
            this.weeklyIncentiveData[index][subsetIndex].rules.splice(ruleIndex, 1);
            break;
          }
        }
      }
    }
    this.reComputeTotalAmount(setIndex.toString(), rules);
    this.reComputeTotalDistance(setIndex.toString(), rules);
    this.reComputeTotalOrders(setIndex.toString(), rules);
  }

  goBack() {
    this.back.emit();
  }

  setMetricValue(selectedValue) {
    this.globalQualityMetric = selectedValue;
    for (let i = 0; i < this.weeklyIncentiveData.length; i++) {
      this.weeklyIncentiveData[i]['1'].rules.forEach(element => {
        element.quality.metric = this.globalQualityMetric;
      });
    }
  }

  joinDays(day: string, setIndex: number) {
    //add start day for next set.
    // if (this.weeklyIncentiveData[setIndex + 1]) {
    //   this.weeklyIncentiveData[setIndex + 1].startDay.day = day;
    //   this.weeklyIncentiveData = this.weeklyIncentiveData;
    // }
    let startIndex, endIndex;
    if (this.weeklyIncentiveData[setIndex].startDay.day && this.weeklyIncentiveData[setIndex].endDay.day) {
      this.weeklyIncentiveData[setIndex].days = '';
      for (let index = 1; index < this.days.length; index++) {
        if (this.weeklyIncentiveData[setIndex].startDay.day === this.days[index].day) {
          startIndex = index;
        }
        if (this.weeklyIncentiveData[setIndex].endDay.day === this.days[index].day) {
          endIndex = index;
        }
        if (startIndex && endIndex) {
          break;
        }
      }
      let listOfDays = [];
      for (let i = startIndex; i <= endIndex; i++) {
        listOfDays.push(this.days[i].day);
      }
      this.weeklyIncentiveData[setIndex].days = listOfDays.join(', ');
    }
  }

  handleCancellation() {
    // this.cancellationChecked = !this.cancellationChecked;
    this.cancellationGlobalLimit = 0;
    this.cancellationLimitScope = "";
    this.setsCancellationLimit = this.setsCancellationLimit.map(x => 0);
    let cancellationIndex = this.selectedVariable.indexOf("cancellation");
    if (cancellationIndex > -1) {
      this.selectedVariable.splice(cancellationIndex, 1);
    }
  }

  handleMaxOrderDistance() {
    this.maxOrderDistanceGlobal = 0;
    let maxOrderDistanceIndex = this.selectedVariable.indexOf("maxOrderDistance");
    if (maxOrderDistanceIndex > -1) {
      this.selectedVariable.splice(maxOrderDistanceIndex, 1);
    }
  }

  clearCancellationLimits() {
    this.cancellationGlobalLimit = 0;
    this.setsCancellationLimit = this.setsCancellationLimit.map(x => 0);
  }

  async fetchIncentives() {
    const basicInfo = this.incentiveData.basicInfo;
    const incentives: any = await this.incentiveService.fetchIncentiveData({
      "filters": {
        "cities": basicInfo.city,
        "serviceType": basicInfo.service,
        "types": [
          "Daily"
        ],
        "startDate": basicInfo.startDate,
        "endDate": basicInfo.startDate,
        "active": true,
        "isHHIncentive": false,
      }
    }).toPromise();

    const currentEditIncentiveID = this.defaultValue && this.defaultValue._id;
    return incentives.filter(x => !_.isEqual(x._id, currentEditIncentiveID));
  }

  toMinutes(time: string): number {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  }

  isOverlappingTime(range1, range2) {
    if (range1.start >= range2.start && range1.start <= range2.end) {
      return true;
    }
    if (range1.end >= range2.start && range1.end <= range2.end) {
      return true;
    }
    if (range1.start <= range2.start && range1.end >= range2.end) {
      return true;
    }
    return false;
  }

  findTimeBuckets(timeSlot) {
    const start = this.toMinutes(timeSlot.fromTime);
    const end = this.toMinutes(timeSlot.toTime);

    const timeBuckets = this.dailyIncentiveTimeBuckets.filter(bucket => {
      const bucketStart = this.toMinutes(bucket.fromTime);
      const bucketEnd = this.toMinutes(bucket.toTime);
      return this.isOverlappingTime({ start, end },
        { start: bucketStart, end: bucketEnd });
    });

    return timeBuckets.map(bucket => bucket.name);
  }

  weeklyTimeSlotChanged(setIndex, slotIndex, timeIndex) {
    const newTimeSlot = this.weeklyIncentiveData[setIndex][slotIndex].timeSlot[timeIndex];
    if (!newTimeSlot.fromTimeHour || !newTimeSlot.fromTimeMin || !newTimeSlot.toTimeHour|| !newTimeSlot.toTimeMin) {
      return;
    }
    newTimeSlot.fromTime = this.timeToTimeStamp(`${newTimeSlot.fromTimeHour.value}:${newTimeSlot.fromTimeMin.value}`);
    newTimeSlot.toTime =  this.timeToTimeStamp(`${newTimeSlot.toTimeHour.value}:${newTimeSlot.toTimeMin.value}`);
    this.weeklyIncentiveData[setIndex][slotIndex].timeSlot[timeIndex] = newTimeSlot;
  }

  async timeSlotChanged(slotIndex, timeIndex) {
    const incentiveType = this.incentiveData.basicInfo.incentiveType;
    const isHHIncentive = this.incentiveData.basicInfo.isHHIncentive;
    
    const incentives = this.dailyIncentiveData[0][slotIndex];

    const newTimeSlot = incentives.timeSlot[timeIndex];
    if (!newTimeSlot.fromTimeHour || !newTimeSlot.fromTimeMin || !newTimeSlot.toTimeHour|| !newTimeSlot.toTimeMin) {
      return;
    }

    newTimeSlot.fromTime = this.timeToTimeStamp(`${newTimeSlot.fromTimeHour.value}:${newTimeSlot.fromTimeMin.value}`);
    newTimeSlot.toTime =  this.timeToTimeStamp(`${newTimeSlot.toTimeHour.value}:${newTimeSlot.toTimeMin.value}`);
    this.dailyIncentiveData[0][slotIndex].timeSlot[timeIndex] = newTimeSlot;

    const newTimeBuckets = this.findTimeBuckets({
      fromTime: `${newTimeSlot.fromTimeHour.value}:${newTimeSlot.fromTimeMin.value}`,
      toTime: `${newTimeSlot.toTimeHour.value}:${newTimeSlot.toTimeMin.value}`
    });

    if (incentiveType !== "Daily" || isHHIncentive) {
      return;
    }
    
    if (!this.existingIncentives) {
      this.existingIncentives = await this.fetchIncentives();
    }

    const overlappingIncentives = this.existingIncentives
      .filter(incentive => {
        const goals = _.flatten(Object.values(incentive.goals));
        const timeBucketsForSlots = _.flatMap(goals,
          (goal: any) => _.flatMap(goal.timeSlot, this.findTimeBuckets.bind(this))) as string[];
        return timeBucketsForSlots.some((timeBucket: string) => newTimeBuckets.includes(timeBucket));
      }).map(filteredIncentive => this.buildOverlappingIncentive(filteredIncentive));
    this.overlappingTimeBuckets[`${slotIndex}-${timeIndex}`] = overlappingIncentives.length > 0 ? overlappingIncentives : null;
  }

  checkTimeSlots() {
    const incentives = this.dailyIncentiveData[0] as any;
    Object.entries(incentives)
      .filter(([key]) => key !== "days").map(([index, incentive]: [string, any]) => {
        incentive.timeSlot
          .map((_timeSlot, timeIndex) => this.timeSlotChanged(index, timeIndex));
      });
  }

  slotHasError(slotIndex, timeIndex) {
    return Boolean(this.overlappingTimeBuckets[`${slotIndex}-${timeIndex}`]);
  }

  showTimeBucketPopup() {
    this.hidePopup();
    $('.incentiveBucketsPopup').show();
  }

  showIncentivePopup(slotIndex, timeIndex) {
    this.hidePopup();
    this.errorPopupIncentives = this.overlappingTimeBuckets[`${slotIndex}-${timeIndex}`];
    $('.incentiveNamePopup').show();
  }

  hidePopup(): void {
    $('.popup').hide();
  }

  formatTimeStringto12H(time) {
    return moment(time, "HH:mm").format("hh:mm A");
  }

  buildOverlappingIncentive(filteredIncentive: any) {
    const goals = filteredIncentive.goals["Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"];
    const goalRuleArray: any[] = goals.map((goal) => ({
      timeSlots: goal.timeSlot,
      rules: goal.rules,
      cancellationLimit: goal.cancellationLimit
    }));
    return {
      incentiveName: filteredIncentive.incentiveName,
      ruleName: filteredIncentive.ruleName,
      selectedVariable: filteredIncentive.selectedVariable,
      selectedCondition: filteredIncentive.selectedCondition,
      cancellationLimit: filteredIncentive.cancellationLimit,
      goals: goalRuleArray
    };
  }

  maxOrderConditionCheck() {
    return this.incentiveData.basicInfo
    && (this.incentiveData.basicInfo.incentiveType === 'Adhoc')
    && this.incentiveData.basicInfo.isMaxOrderDistanceEnabledForCity
    && this.incentiveData.basicInfo.incentiveDisplayName == 'Short Ride Incentive'
  }

  showDistanceVariable(){
    return !(this.incentiveData.basicInfo
      && this.incentiveData.basicInfo.incentiveType === 'Adhoc'
      && this.incentiveData.basicInfo.incentiveDisplayName == 'Short Ride Incentive')
  }

  reComputeTotalOrders(setIndex: string, rules: any) {
    const newTotalOrders = rules.reduce((accumulator, currentRule) => { 
      if('order' in currentRule){
        return accumulator + currentRule.order;
      } 
      return accumulator;
    }, 0);
    this.totalOrdersMap.set(setIndex, newTotalOrders);
  }

  reComputeTotalDistance(setIndex: string, rules: any) {
    const newTotalDistance = rules.reduce((accumulator, currentRule) => { 
      if('distance' in currentRule){
        return accumulator + currentRule.distance;
      } 
      return accumulator;
    }, 0);
    this.totalDistanceMap.set(setIndex, newTotalDistance);
  }

  reComputeTotalAmount(setIndex: string, rules: any) {
    const newTotalAmount = this.calculateRulesTotalAmount(rules);
    this.totalAmountMap.set(setIndex, newTotalAmount);
  }

  calculateRulesTotalAmount(rules: any){
    const total = rules.reduce((accumulator, currentRule) => { 
      if('amount' in currentRule){
        if('quality' in currentRule && currentRule.quality.amount) {
          return accumulator + currentRule.amount + currentRule.quality.amount;
        }
        return accumulator + currentRule.amount;
      } 
      return accumulator;
    }, 0);
    return total;
  }


  setAmountLimitExceeded(){
    let limtExceeded = false;
    this.totalAmountMap.forEach((amount) => {
      if (amount > this.setMaxAmountLimit) {
        limtExceeded = true;
      }
    });
    return limtExceeded;
  }

  makeOrdersAndDistanceNonCumulative(oldRules){
    let newRules = _.cloneDeep(oldRules);
    for(let index=0; index < oldRules.length; index++){
      if('distance' in oldRules[index]){
        newRules[index].distance = index === 0 ? oldRules[index].distance : oldRules[index].distance - oldRules[index - 1].distance;
      }
      if('order' in oldRules[index]){
        newRules[index].order = index === 0 ? oldRules[index].order : oldRules[index].order - oldRules[index - 1].order;
      }
    }
    return newRules;
  }
}
