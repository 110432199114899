<div class="center">
  <form [formGroup]="bulkCreditFormGroup" class="flex-col width-600">
    <div class="flex-row margin-y-10 font-20 space-bw width-600">
      <span class="bold">Transaction type</span>
      <span>Adjustment</span>
    </div>

    <div class="flex-row margin-y-10">
      <label for="subTransactionType" class="font-20 bold">Sub transaction type</label>
      <ng-select formControlName="subTransactionType" name="subTransactionType" placeholder="Select"
        class="width-200 flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let item of enabledTransactionTypes" [value]="item">{{item.displayName}}</ng-option>
      </ng-select>
    </div>

    <div *ngIf="selectedSubTransactionType()" class="flex-col margin-y-10">
      <div class="flex-row margin-y-10">
        <label for="remark" class="font-20 bold">Remark</label>
        <ng-select formControlName="remark" name="remark" placeholder="Select"
          class="width-200 flex-grow font-20 margin-l-10">
          <ng-option *ngFor="let item of selectedSubTransactionType().remarks" [value]="item">{{item}}</ng-option>
        </ng-select>
      </div>

      <label for="comment" class="font-20 bold">Comments <span class="red">*</span></label>
      <textarea pInputTextarea [rows]="3" placeholder="Max length 150" name="comment" formControlName="comment"
        class="font-20" maxlength="150"></textarea>

      <p-fileUpload #fileUpload accept="text/csv" [maxFileSize]="1000000" [showCancelButton]="false"
        [showUploadButton]="false" (onSelect)="handleFile($event)" class="margin-y-10"
        [disabled]="!bulkCreditFormGroup.getRawValue().remark" (onRemove)="resetFile()" chooseLabel="Upload File">
        <ng-template pTemplate="toolbar">
          <button pButton (click)="downloadSampleFile()" class="ui-button-secondary"
            style="background-color: gray; color: white;" label="Download Sample File">
          </button>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex-row left">
            <span class="center bold green" *ngIf="validated && !validationError">
              <mat-icon class="margin-x-10">check</mat-icon> File successfully validated
            </span>
            <span class="center bold red" *ngIf="validationError">
              <mat-icon class="margin-x-10">close</mat-icon> File validation failed. Please correct the errors and
              retry.
            </span>
          </div>
        </ng-template>
      </p-fileUpload>
      <mat-card>
        <span class="bold">Note:</span>
        For bulk transactions with more than one order and/or service, input the values separated by a '|' (pipe). Refer
        to the
        <span class="bold">Sample File</span> for more details.
      </mat-card>

      <div class="margin-y-10 flex-row space-bw pull-right">
        <button mat-raised-button class="btn-primary" (click)="valdidateBulkCreditFile()"
          [disabled]="!fileDetails || !bulkCreditFormGroup.getRawValue().comment || validationError"
          *ngIf="!validated">Validate File</button>
        <button mat-raised-button class="btn-primary" (click)="confirmBulkCreditInitiate()" *ngIf="validated">
          Initiate Bulk Payment</button>
      </div>
    </div>
  </form>
</div>
