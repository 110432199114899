import * as tslib_1 from "tslib";
import { BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE } from './../constants';
import { OnInit, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SharedService } from 'src/app/shared/shared.service';
import { PenaltyBasicInfoService } from './penalty-basic-info.service';
import { ToasterService } from '../../toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { LoaderService } from 'src/app/loader.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RollingWindowDialogComponent } from '../../rolling-window-dialog/rolling-window-dialog.component';
import { TRIGGER_EVENT, RATING_POSITION, RATING_TRIGGERS, eventsWithoutReasons, aprDprEvents, EVENT_REASON_TYPE, EQUATION_TYPES, EVENT_NAME_MAPPINGS, EVENT_REASON_TYPES, INSTANCE_LEEWAY_EVENTS, EVENT_KEY_VALUES, CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES, PARTIAL_OFFLINE_RIDE_KEY } from '../constants';
var PenaltyBasicInfoComponent = /** @class */ (function () {
    function PenaltyBasicInfoComponent(fb, sharedService, penaltyBasicInfoService, toasterService, loaderService, datePipe, router, dialog) {
        this.fb = fb;
        this.sharedService = sharedService;
        this.penaltyBasicInfoService = penaltyBasicInfoService;
        this.toasterService = toasterService;
        this.loaderService = loaderService;
        this.datePipe = datePipe;
        this.router = router;
        this.dialog = dialog;
        this.eventName = EVENT_NAME_MAPPINGS;
        this.basicInfoDataChange = new EventEmitter();
        this.basicInfo = new EventEmitter();
        this.currentDate = new Date();
        this.eventReasons = [];
        this.cities = [];
        this.services = [];
        this.eventsData = [];
        this.eventReasonOptions = [];
        this.captainReasonsOptions = [];
        this.customerReasonsOptions = [];
        this.enableKeyReason = [false];
        this.ratingValues = [];
        this.EVENT_REASON_TYPE = EVENT_REASON_TYPE;
        this.EVENT_KEY_VALUES = EVENT_KEY_VALUES;
        this.CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES = CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES;
        this.campaignType = "penaltyAndReward";
        this.showLMS = false;
        this.useActionAmount = false;
        this.actionMax = false;
        this.actionMin = false;
        this.escalationTicketKey = "escalationReason";
        this.showDependantEventFeature = [false];
        this.equations = [
            EQUATION_TYPES.LESS_THAN,
            EQUATION_TYPES.GREATER_THAN,
            EQUATION_TYPES.BETWEEN,
        ];
        this.daysAfterWhichCheckHistoricAction = environment.daysAfterWhichCheckHistoricAction;
        this.maxRollingWindowSpan = 1827;
        this.timeSlotStyle = {
            height: '34px',
            width: '100%'
        };
        // Make this a constant
        this.escalationTicketEvent = "escalation_ticket";
        this.basicInfoFormGroup = this.fb.group({
            name: [null, Validators.required],
            city: [null, Validators.required],
            gameType: [null, Validators.required],
            services: [null, Validators.required],
            selectors: [null, Validators.required],
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
            triggerEvents: this.fb.array([this.getEmptyTriggers()]),
            timeSlots: this.fb.array([this.getEmptyTimeSlot()]),
            leeway: new FormControl(null, Validators.required),
            instanceLeeway: new FormControl(null, Validators.required),
            eligibilityPings: new FormControl(null, Validators.required),
            eligibilityEvents: new FormControl(null, Validators.required),
            eligibilitySegments: new FormControl(null, Validators.required),
            daprPercentageStart: new FormControl(null, Validators.required),
            daprPercentageEnd: new FormControl(null, Validators.required),
            eligibilityWarnMsg: new FormControl(null, Validators.required),
            rollingWindowDays: new FormControl(null, Validators.required),
            leewayWarnMsg: new FormControl(null, Validators.required),
            instanceLeewayWarnMsg: new FormControl(null, Validators.required),
            ratingBetweenStart: new FormControl(null, Validators.required),
            ratingBetweenEnd: new FormControl(null, Validators.required),
            ratingGreaterOrLessThanValue: new FormControl(null, Validators.required),
            priority: new FormControl('', Validators.required),
            _id: new FormControl(null),
        });
        this.applyLeeway = false;
        this.applyInstanceLeeway = false;
        this.applyRollingWindow = false;
        this.getCountFromHistory = false;
        this.applyEligibility = false;
        this.applyDaprCheck = false;
        this.applySegmentEligibility = false;
        this.applySelector = false;
        this.fallbackToDefaultRule = false;
        this.gameTypes = ["DPR", "Streak"];
        this.applyRatingGreaterOrLessThan = [false];
        this.applyRatingBetween = [false];
        this.events = [];
        this.updateCities();
    }
    PenaltyBasicInfoComponent.prototype.getEmptyTimeSlot = function () {
        return this.fb.group({
            startTime: null,
            endTime: null
        });
    };
    PenaltyBasicInfoComponent.prototype.getEmptyTriggers = function () {
        return this.fb.group({
            event: null,
            values: null,
            key: null,
            customerReason: null,
            captainReason: null,
            dependantEventOrder: 'none',
            dependantEvent: null
        });
    };
    Object.defineProperty(PenaltyBasicInfoComponent.prototype, "timeSlots", {
        get: function () {
            return this.basicInfoFormGroup.controls["timeSlots"];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PenaltyBasicInfoComponent.prototype, "triggerEvents", {
        get: function () {
            return this.basicInfoFormGroup.controls["triggerEvents"];
        },
        enumerable: true,
        configurable: true
    });
    PenaltyBasicInfoComponent.prototype.isGame = function () {
        return this.campaignType == "captainGame";
    };
    PenaltyBasicInfoComponent.prototype.isAutomatedReactiveModel = function () {
        return this.campaignType === 'automatedReactiveModel';
    };
    PenaltyBasicInfoComponent.prototype.isPenaltyAndRewards = function () {
        return this.campaignType === 'penaltyAndReward';
    };
    PenaltyBasicInfoComponent.prototype.showDependantEventDropDown = function (index) {
        if (this.basicInfoFormGroup.controls.triggerEvents.value[index].dependantEventOrder === 'before' ||
            this.basicInfoFormGroup.controls.triggerEvents.value[index].dependantEventOrder === 'after') {
            return true;
        }
        return false;
    };
    PenaltyBasicInfoComponent.prototype.getEventReasonType = function (index) {
        try {
            return EVENT_REASON_TYPES[this.basicInfoFormGroup.controls.triggerEvents.value[index].event];
        }
        catch (err) {
            console.log(err);
        }
    };
    PenaltyBasicInfoComponent.prototype.isPartialOfflineDropEvent = function (index) {
        if (this.basicInfoFormGroup.controls.triggerEvents.value[index].event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
            return true;
        }
        return false;
    };
    PenaltyBasicInfoComponent.prototype.hasDependantEvent = function (eventName) {
        return this.eventsData.some(function (eventData) { return eventData.event === eventName && 'dependantEvents' in eventData; });
    };
    PenaltyBasicInfoComponent.prototype.setDependantEvent = function (triggerEvent) {
        var dependentEvent = this.eventsData.filter(function (eventData) { return eventData.event === triggerEvent && 'dependantEvents' in eventData; });
        this.DEPENDANT_EVENTS_OPTIONS = dependentEvent[0].dependantEvents;
    };
    //Sets a field editable or non editable. Values are different for different screens.
    PenaltyBasicInfoComponent.prototype.makeFieldsDisable = function () {
        var _this = this;
        Object.keys(this.NOT_EDITABLE).forEach(function (fieldName) {
            if (_this.NOT_EDITABLE[fieldName]) {
                try {
                    _this.basicInfoFormGroup.get(fieldName).disable();
                }
                catch (err) {
                    console.log("Error disabling field ", fieldName);
                }
            }
        });
    };
    PenaltyBasicInfoComponent.prototype.ngOnInit = function () {
        this.fetchEvents();
        switch (this.state) {
            case this.sharedService.STATE.EDIT: {
                if (new Date(this.defaultValue.startDate).getTime() <= this.currentDate.getTime()) {
                    this.basicInfoFormGroup.get("startDate").disable();
                }
                if (new Date(this.defaultValue.endDate).getTime() <= this.currentDate.getTime()) {
                    this.basicInfoFormGroup.get("endDate").disable();
                }
            }
            case this.sharedService.STATE.DUPLICATE:
            case this.sharedService.STATE.VIEW: {
                this.patchDefaultValue(this.defaultValue);
                break;
            }
        }
    };
    // Sets value according to the data recieved from the backend during viewing screen.
    PenaltyBasicInfoComponent.prototype.patchDefaultValue = function (defaultValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, detailsData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.fetchServices(defaultValue.cityId).toPromise()];
                    case 1:
                        response = _a.sent();
                        detailsData = response['data'];
                        this.serviceDetails = detailsData.map(function (detail) {
                            return {
                                id: detail._id,
                                cityId: defaultValue.cityId,
                                city: defaultValue.cityName,
                                service: detail.service
                            };
                        });
                        this.campaignType = defaultValue.ruleType;
                        this.setTriggerEvents();
                        this.TRIGGER_EVENTS_OPTIONS = this.eventsData;
                        this.basicInfoFormGroup.patchValue({
                            name: defaultValue.ruleName,
                            startDate: new Date(defaultValue.startDate),
                            endDate: new Date(defaultValue.endDate)
                        });
                        if (defaultValue.leeway && defaultValue.leewayWarnMsg) {
                            this.applyLeeway = true;
                            this.basicInfoFormGroup.patchValue({
                                leeway: defaultValue.leeway,
                                leewayWarnMsg: defaultValue.leewayWarnMsg
                            });
                        }
                        if (defaultValue.instanceLeeway && defaultValue.instanceLeewayWarnMsg) {
                            this.applyInstanceLeeway = true;
                            this.basicInfoFormGroup.patchValue({
                                instanceLeeway: defaultValue.instanceLeeway,
                                instanceLeewayWarnMsg: defaultValue.instanceLeewayWarnMsg
                            });
                        }
                        if (defaultValue.rollingWindowDays) {
                            this.applyRollingWindow = true;
                            this.basicInfoFormGroup.patchValue({
                                rollingWindowDays: defaultValue.rollingWindowDays
                            });
                            if (defaultValue.rollingWindowDays > environment.daysAfterWhichCheckHistoricAction) {
                                this.getCountFromHistory = true;
                            }
                        }
                        if (defaultValue.eligibility && defaultValue.eligibility.pings && defaultValue.eligibility.events && defaultValue.eligibility.warnMsg) {
                            this.applyEligibility = true;
                            this.basicInfoFormGroup.patchValue({
                                eligibilityPings: defaultValue.eligibility.pings,
                                eligibilityEvents: defaultValue.eligibility.events,
                                eligibilityWarnMsg: defaultValue.eligibility.warnMsg
                            });
                        }
                        if (defaultValue.daprPercentage && defaultValue.daprPercentage.start && defaultValue.daprPercentage.end) {
                            this.applyDaprCheck = true;
                            this.basicInfoFormGroup.patchValue({ daprPercentageStart: defaultValue.daprPercentage.start,
                                daprPercentageEnd: defaultValue.daprPercentage.end });
                        }
                        if (defaultValue.eligibilitySegments && defaultValue.eligibilitySegments.length) {
                            this.applySegmentEligibility = true;
                            this.basicInfoFormGroup.patchValue({
                                eligibilitySegments: defaultValue.eligibilitySegments,
                            });
                        }
                        this.patchCity(defaultValue.serviceDetails && defaultValue.serviceDetails[0] || defaultValue.serviceDetail).then(function () {
                            _this.basicInfoFormGroup.patchValue({
                                services: defaultValue.serviceDetails || [defaultValue.serviceDetail],
                            });
                        }).then(function () {
                            _this.serviceChangeCheck(_this.getValue('services')).then(function () {
                                if (defaultValue.userSelectors && defaultValue.userSelectors.length) {
                                    _this.applySelector = true;
                                    _this.basicInfoFormGroup.patchValue({
                                        selectors: defaultValue.userSelectors,
                                        priority: defaultValue.priority,
                                    });
                                    _this.fallbackToDefaultRule = defaultValue.fallbackToDefaultRule;
                                }
                            });
                        });
                        this.patchTimeSlots(defaultValue.rules[0].timeWindows);
                        this.patchTriggers(defaultValue.eventsAndReasons);
                        this.makeFieldsDisable();
                        this.loaderService.closeLoading();
                        return [2 /*return*/];
                }
            });
        });
    };
    PenaltyBasicInfoComponent.prototype.patchCity = function (serviceDetail) {
        var _this = this;
        return new Promise(function (resolve) {
            var interval = setInterval(function () {
                _this.serviceDetails.forEach(function (detail) {
                    if (detail.id === serviceDetail) {
                        _this.serviceInfo = detail;
                    }
                });
                if (_this.serviceInfo) {
                    _this.basicInfoFormGroup.patchValue({
                        city: _this.serviceInfo.cityId
                    });
                }
                _this.onCityChange(_this.getValue('city'));
                clearInterval(interval);
                return resolve({});
            }, 100);
        });
    };
    PenaltyBasicInfoComponent.prototype.patchTimeSlots = function (timeSlots) {
        var _this = this;
        return new Promise(function (resolve) {
            var index = 0;
            timeSlots = timeSlots.map(function (timeSlot) {
                return {
                    startTime: _this.timeToTimeStamp(timeSlot.startTime.substring(0, 2) + ":" + timeSlot.startTime.substring(2, timeSlot.startTime.length)),
                    endTime: _this.timeToTimeStamp(timeSlot.endTime.substring(0, 2) + ":" + timeSlot.endTime.substring(2, timeSlot.endTime.length))
                };
            });
            timeSlots.forEach(function () {
                if (!_this.timeSlots.controls[index]) {
                    _this.timeSlots.push(_this.getEmptyTimeSlot());
                }
                index++;
            });
            _this.timeSlots.patchValue(timeSlots);
            return resolve({});
        });
    };
    // view
    PenaltyBasicInfoComponent.prototype.patchTriggers = function (events) {
        var _this = this;
        return new Promise(function (resolve) {
            var index = 0;
            events = events.map(function (event, index) {
                if (event.event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
                    return {
                        values: event.values && event.values.length ? event.values : null,
                        key: event.key || null,
                        event: event.event || null,
                        customerReason: event.values[0].split(":")[0],
                        captainReason: event.values[0].split(":")[1],
                    };
                }
                else {
                    return {
                        values: event.values && event.values.length ? event.values : null,
                        key: event.key || null,
                        event: event.event || null,
                        dependantEvent: event.dependantEvent || null,
                        dependantEventOrder: event.dependantEventOrder || null,
                    };
                }
            });
            events.forEach(function () {
                if (!_this.triggerEvents.controls[index]) {
                    _this.triggerEvents.push(_this.getEmptyTriggers());
                }
                index++;
            });
            _this.basicInfoFormGroup.patchValue({
                services: _this.defaultValue.serviceDetails
            });
            var _loop_1 = function (event_1) {
                setTimeout(function () {
                    _this.getEventReasons(events[event_1].event, event_1);
                }, 1000);
                if (~RATING_TRIGGERS.indexOf(events[event_1].event)) {
                    _this.ratingValues[event_1] = {};
                    events[event_1].values = [_this.ratingToEquationTransform(events[event_1].values, event_1)];
                }
            };
            for (var event_1 in events) {
                _loop_1(event_1);
            }
            _this.triggerEvents.patchValue(events);
            return resolve({});
        });
    };
    PenaltyBasicInfoComponent.prototype.timeToTimeStamp = function (time) {
        var _a = time.split(':'), hr = _a[0], min = _a[1];
        if (!hr || !min) {
            return '';
        }
        var timeStamp = new Date();
        timeStamp.setHours(parseInt(hr));
        timeStamp.setMinutes(parseInt(min));
        return timeStamp;
    };
    PenaltyBasicInfoComponent.prototype.serviceChangeCheck = function (event) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.refreshTrigger(event);
            if (event) {
                _this.getUserSelectors(event);
                return resolve({});
            }
        });
    };
    // called whenever service or campaign type is changed
    PenaltyBasicInfoComponent.prototype.refreshTrigger = function (service) {
        if (this.isGame()) {
            this.basicInfoFormGroup.patchValue({
                eventReason: null,
            });
            this.TRIGGER_EVENTS_OPTIONS = [
                { event: "dpr", displayText: "Drop to Ping Ratio", checkForReason: false },
                { event: "streak", displayText: "Streak", checkForReason: false }
            ];
            return;
        }
        if (this.isAutomatedReactiveModel()) {
            this.TRIGGER_EVENTS_OPTIONS = [
                {
                    "event": "escalation_ticket",
                    "displayText": "Escalation Ticket",
                    "checkForReason": true
                },
                {
                    "event": "feedback_for_captain_received",
                    "displayText": "Captain Feedback",
                    "checkForReason": true
                },
                {
                    event: "partial_offline_ride_customer_continued_response",
                    displayText: "POR Customer Continued Response",
                    checkForReason: false
                }
            ];
            return;
        }
        if (service) {
            this.basicInfoFormGroup.patchValue({
                eventReason: null,
            });
            //setting trigger event empty on every service change
            if (!this.isAutomatedReactiveModel() && this.state === this.sharedService.STATE.CREATE || (this.state === this.sharedService.STATE.DUPLICATE && service.length != this.defaultValue.serviceDetails.length)) {
                for (var i = 0; i < this.triggerEvents.controls.length; i++) {
                    this.enableKeyReason = [];
                    this.showDependantEventFeature = [];
                    this.eventReasons = [];
                    this.eventReasonOptions = [];
                    this.triggerEvents.controls = [];
                    if (!this.triggerEvents.controls.length) {
                        this.addTriggerEvent();
                    }
                }
            }
        }
        else {
            this.basicInfoFormGroup.patchValue({
                eventReason: null,
            });
        }
    };
    PenaltyBasicInfoComponent.prototype.campaignTypeCheck = function () {
        this.refreshTrigger(null);
        this.basicInfoFormGroup.reset();
    };
    PenaltyBasicInfoComponent.prototype.fetchEvents = function () {
        var _this = this;
        this.penaltyBasicInfoService.fetchEvents().subscribe(function (result) {
            if (result && result['data'] && result['data']['data']) {
                _this.eventsData = result['data']['data'];
                _this.setTriggerEvents();
            }
        });
    };
    PenaltyBasicInfoComponent.prototype.setTriggerEvents = function () {
        if (this.isPenaltyAndRewards()) {
            this.TRIGGER_EVENTS_OPTIONS = this.eventsData.filter(function (triggerEvent) { return (triggerEvent.event !== TRIGGER_EVENT.ESCALATION_TICKET && triggerEvent.event !== TRIGGER_EVENT.POR_CUSTOMER_CONTINUED_RIDE); });
        }
        else {
            this.TRIGGER_EVENTS_OPTIONS = this.eventsData;
        }
    };
    PenaltyBasicInfoComponent.prototype.patchValuesForARM = function () {
        this.applySegmentEligibility = false;
        this.applyEligibility = false;
        //this.applyRollingWindow = false;
        this.applyInstanceLeeway = false;
        this.applyDaprCheck = false;
    };
    PenaltyBasicInfoComponent.prototype.checkMaxSelectedItems = function (index) {
        var event = this.basicInfoFormGroup.controls.triggerEvents.value[index].event;
        if (event === TRIGGER_EVENT.RATING || this.isAutomatedReactiveModel()) {
            return 1;
        }
    };
    PenaltyBasicInfoComponent.prototype.checkMaxSelectedItemsForService = function () {
        if (this.isAutomatedReactiveModel()) {
            if (this.getValue('services') && this.getValue('services').length > 1) {
                this.basicInfoFormGroup.patchValue({
                    services: null
                });
                this.showWarningToster("Please select service again in case of Escalation Ticket.");
            }
            return 1;
        }
    };
    PenaltyBasicInfoComponent.prototype.getEventReasons = function (triggerEvent, index) {
        var _this = this;
        this.showDependantEventFeature[index] = this.hasDependantEvent(triggerEvent);
        if (this.showDependantEventFeature[index]) {
            this.setDependantEvent(triggerEvent);
        }
        if (triggerEvent === undefined) {
            this.disableRatingValues(index);
            return;
        }
        if ((triggerEvent === TRIGGER_EVENT.ESCALATION_TICKET || triggerEvent === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED) && this.isAutomatedReactiveModel()) {
            this.patchValuesForARM();
        }
        if (triggerEvent != "rating_for_captain_received") {
            this.disableRatingValues(index);
        }
        this.basicInfoFormGroup.controls.triggerEvents.value[index].event = triggerEvent;
        delete this.basicInfoFormGroup.controls.triggerEvents.value[index].key;
        if (this.isGame())
            return;
        if (triggerEvent && (eventsWithoutReasons.indexOf(triggerEvent) > -1)) {
            this.enableKeyReason[index] = false;
            delete this.eventReasonOptions[index];
            delete this.eventReasons[index];
            delete this.basicInfoFormGroup.controls.triggerEvents.value[index].key;
            return;
        }
        return new Promise(function (resolve, reject) {
            var payload;
            if (!triggerEvent) {
                return reject();
            }
            var servicesInfo = [];
            for (var i = 0; i < _this.services.length; i++) {
                var currService = _this.services[i];
                if (_this.getValue('services').indexOf(currService["id"]) > -1) {
                    servicesInfo.push({
                        serviceType: currService["serviceType"],
                        serviceDetailId: currService["id"]
                    });
                }
            }
            payload = {
                event: triggerEvent,
                servicesInfo: servicesInfo
            };
            _this.penaltyBasicInfoService.fetchEventReasons(payload).subscribe(function (result) {
                if (result && result["data"] && result["data"]["data"]) {
                    if (result["data"]["data"][0] && result["data"]["data"][0].key) {
                        _this.basicInfoFormGroup.controls.triggerEvents.value[index].key = result["data"]["data"][0].key;
                    }
                    _this.eventReasons[index] = result["data"]["data"];
                    if (_this.eventReasons[index].length > 0) {
                        if (_this.eventReasons[index].length > 1) {
                            _this.deDuplicateKeysForARM(triggerEvent, _this.eventReasons[index], index);
                            _this.enableKeyReason[index] = true;
                            _this.eventReasonOptions[index] = [];
                            return resolve({});
                        }
                        else {
                            _this.enableKeyReason[index] = false;
                            _this.eventReasonOptions[index] = _this.eventReasons[index][0].values;
                            return resolve({});
                        }
                    }
                    else if (_this.eventReasons[index].captainPartialOfflineRideReasons && _this.eventReasons[index].captainPartialOfflineRideReasons.length && _this.eventReasons[index].captainPartialOfflineRideReasons.length) {
                        _this.customerReasonsOptions = _this.eventReasons[index].customerPartialDropReasons;
                        _this.captainReasonsOptions = _this.eventReasons[index].captainPartialOfflineRideReasons;
                    }
                    else {
                        _this.showWarningToster("No reasons found");
                        _this.enableKeyReason[index] = false;
                        _this.eventReasons[index] = [];
                        _this.eventReasonOptions[index] = [];
                        return reject();
                    }
                }
                else if (result && result["data"]) {
                    _this.basicInfoFormGroup.controls.triggerEvents.value[index].key = _this.escalationTicketKey;
                    _this.eventReasons[index] = result["data"];
                    if (_this.eventReasons[index].length > 0) {
                        _this.eventReasonOptions[index] = _this.eventReasons[index].map(function (eventReason) { return eventReason.reason; });
                        return resolve({});
                    }
                    else {
                        _this.showWarningToster("No reasons found");
                        _this.enableKeyReason[index] = false;
                        _this.eventReasons[index] = [];
                        _this.eventReasonOptions[index] = [];
                        return reject();
                    }
                }
            }, function (err) {
                _this.showWarningToster("error in fetching reasons");
                _this.enableKeyReason[index] = false;
                _this.eventReasons[index] = [];
                _this.eventReasonOptions[index] = [];
                return reject();
            });
        });
    };
    PenaltyBasicInfoComponent.prototype.disableRatingValues = function (index) {
        this.applyRatingBetween[index] = false;
        this.applyRatingGreaterOrLessThan[index] = false;
    };
    PenaltyBasicInfoComponent.prototype.deDuplicateKeysForARM = function (triggerEvent, eventReasons, index) {
        var eventReasonsMap = {};
        if (this.isAutomatedReactiveModel() && triggerEvent === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED) {
            this.eventReasons[index] = eventReasons.reduce(function (acc, eventReason) {
                if (!eventReasonsMap[eventReason.key]) {
                    eventReasonsMap[eventReason.key] = eventReason;
                    acc.push(eventReason);
                }
                return acc;
            }, []);
        }
    };
    PenaltyBasicInfoComponent.prototype.getEventReasonOptions = function (event, index) {
        if (!event) {
            this.basicInfoFormGroup.get('triggerEvents').at(index).get('key').patchValue(null);
            this.eventReasonOptions[index] = null;
        }
        this.basicInfoFormGroup.get('triggerEvents').at(index).get('values').patchValue(null);
        this.basicInfoFormGroup.controls.triggerEvents.value[index].key = event || null;
        if (event) {
            var keyReason = this.eventReasons[index].find(function (eventReason) { return eventReason.key === event; });
            this.eventReasonOptions[index] = keyReason.values;
        }
        else {
            this.eventReasonOptions[index] = [];
        }
        this.basicInfoFormGroup.patchValue({
            eventReason: null
        });
    };
    PenaltyBasicInfoComponent.prototype.onCityChange = function (event) {
        if (this.isStreakGame()) {
            this.getUserSelectors(null);
        }
        else {
            this.getCityServiceDetails(event);
        }
    };
    PenaltyBasicInfoComponent.prototype.updateCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, cities, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loaderService.openLoading();
                        return [4 /*yield*/, this.sharedService.fetchCities().toPromise()];
                    case 1:
                        res = _a.sent();
                        cities = res.data.cities;
                        this.cities = cities
                            .map(function (city) {
                            return {
                                id: city._id,
                                name: city.displayName,
                            };
                        })
                            .sort(function (c1, c2) {
                            return c1.name > c2.name ? 1 : -1;
                        });
                        this.loaderService.closeLoading();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.loaderService.closeLoading();
                        this.showWarningToster("Unable to fetch Cities");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PenaltyBasicInfoComponent.prototype.getUserSelectors = function (event) {
        var _this = this;
        var userType = 'rider';
        var city = this.getValue('city');
        var shifts;
        var services = [];
        if (event) {
            services = event;
        }
        this.basicInfoFormGroup.patchValue({
            selectors: null
        });
        this.loaderService.openLoading();
        this.sharedService.fetchRules(city, services, shifts, userType).subscribe(function (result) {
            if (result && result['info'] && result['info']['status'] === "success") {
                _this.userSelectors = result['data']['selectors'];
            }
            if (_this.userSelectors.length === 0) {
                _this.showWarningToster("No User Selectors Found");
            }
            _this.loaderService.closeLoading();
        }, function () {
            _this.showWarningToster("Error in fetching User Selectors");
            _this.loaderService.closeLoading();
        });
    };
    PenaltyBasicInfoComponent.prototype.getCityServiceDetails = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, services, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        this.loaderService.openLoading();
                        if (!event) return [3 /*break*/, 2];
                        this.basicInfoFormGroup.patchValue({
                            services: null
                        });
                        return [4 /*yield*/, this.sharedService
                                .fetchServices(event)
                                .toPromise()];
                    case 1:
                        response = _a.sent();
                        services = response.data;
                        this.services = services.map(function (service) {
                            return {
                                id: service._id,
                                name: service.service.name,
                                serviceId: service.service._id,
                                serviceType: service.serviceType
                            };
                        });
                        this.loaderService.closeLoading();
                        return [3 /*break*/, 3];
                    case 2:
                        this.TRIGGER_EVENTS_OPTIONS = [];
                        this.enableKeyReason = [false];
                        this.basicInfoFormGroup.patchValue({
                            services: null,
                            eventReason: null,
                        });
                        delete this.services;
                        this.loaderService.closeLoading();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        this.loaderService.closeLoading();
                        this.showWarningToster("Unable to fetch services");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PenaltyBasicInfoComponent.prototype.addTimeSlot = function () {
        var _this = this;
        return new Promise(function (resolve) {
            {
                if (_this.state === _this.sharedService.STATE.VIEW) {
                    return resolve({});
                }
                else {
                    _this.timeSlots.push(_this.getEmptyTimeSlot());
                    return resolve({});
                }
            }
        });
    };
    PenaltyBasicInfoComponent.prototype.deleteTimeSlot = function (index) {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.timeSlots.controls.splice(index, 1);
        this.timeSlots.value.splice(index, 1);
        if (!this.timeSlots.controls.length) {
            this.addTimeSlot();
        }
    };
    PenaltyBasicInfoComponent.prototype.addTriggerEvent = function () {
        var _this = this;
        return new Promise(function (resolve) {
            {
                if (_this.state === _this.sharedService.STATE.VIEW) {
                    return resolve({});
                }
                else {
                    _this.triggerEvents.push(_this.getEmptyTriggers());
                    _this.applyRatingBetween.push(false);
                    _this.enableKeyReason.push(false);
                    _this.showDependantEventFeature.push(false);
                    _this.applyRatingGreaterOrLessThan.push(false);
                    return resolve({});
                }
            }
        });
    };
    PenaltyBasicInfoComponent.prototype.deleteTriggerEvent = function (index) {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.applyRatingBetween.splice(index, 1);
        this.applyRatingGreaterOrLessThan.splice(index, 1);
        this.ratingValues.splice(index, 1);
        this.enableKeyReason.splice(index, 1);
        this.showDependantEventFeature.splice(index, 1);
        this.eventReasons.splice(index, 1);
        this.eventReasonOptions.splice(index, 1);
        this.triggerEvents.controls.splice(index, 1);
        this.triggerEvents.value.splice(index, 1);
        if (!this.triggerEvents.controls.length) {
            this.addTriggerEvent();
        }
    };
    PenaltyBasicInfoComponent.prototype.changeInDate = function () {
        if (this.getValue('startDate')) {
            this.minDate = this.getValue('startDate');
        }
        if (this.getValue('endDate')) {
            this.maxDate = this.getValue('endDate');
        }
    };
    PenaltyBasicInfoComponent.prototype.goBack = function () {
        this.router.navigate(['/campaigns']);
    };
    PenaltyBasicInfoComponent.prototype.submit = function () {
        var data = this.organiseData();
        data && this.basicInfo.emit(data);
    };
    PenaltyBasicInfoComponent.prototype.validateTimeSlots = function () {
        var _this = this;
        var isValid = true;
        var timeSlots = this.timeSlots.value;
        timeSlots.forEach(function (timeSlot) {
            if (!timeSlot.startTime && !timeSlot.endTime) {
                return;
            }
            else {
                if (timeSlot.startTime && !timeSlot.endTime) {
                    isValid = false;
                    return;
                }
                else if (timeSlot.endTime && !timeSlot.startTime) {
                    isValid = false;
                    return;
                }
                else if (_this.datePipe.transform(timeSlot.startTime, 'HHmm') > _this.datePipe.transform(timeSlot.endTime, 'HHmm')) {
                    isValid = false;
                    return;
                }
            }
        });
        return isValid;
    };
    // check if you can use this instead of
    PenaltyBasicInfoComponent.prototype.getValue = function (name) {
        return this.basicInfoFormGroup.get(name).value;
    };
    PenaltyBasicInfoComponent.prototype.addingRatingValue = function (position, value, index) {
        if (value.target.value) {
            var number = parseInt(value.target.value);
            this.ratingValues[index] = this.ratingValues[index] || {};
            switch (position) {
                case RATING_POSITION.END:
                    this.ratingValues[index]['ratingBetweenEnd'] = number;
                    break;
                case RATING_POSITION.START:
                    this.ratingValues[index]['ratingBetweenStart'] = number;
                    break;
                case RATING_POSITION.SINGLE:
                    this.ratingValues[index]['ratingGreaterOrLessThan'] = number;
                    break;
            }
        }
    };
    PenaltyBasicInfoComponent.prototype.fetchValueForPartialOfflineRide = function (triggerEvent) {
        return triggerEvent.customerReason + ":" + triggerEvent.captainReason;
    };
    // Check if you can move this part to a new file
    PenaltyBasicInfoComponent.prototype.validateTriggerEvents = function (triggerEvents) {
        console.log("Trigger events ", triggerEvents);
        console.log("Event Reasons ", this.eventReasons);
        this.events = [];
        var triggerEventsSet = new Set(triggerEvents.map(function (triggerEvent) { return triggerEvent.event; }));
        if (triggerEventsSet.has(TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) && (triggerEventsSet.has(BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE.DROPPED) || triggerEventsSet.has(BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE.DROPPED_SPD))) {
            this.showWarningToster("Dropped offline trigger event cannot be in combination with dropped or dropped spd trigger event");
            return false;
        }
        if (triggerEventsSet.has(TRIGGER_EVENT.POR_CUSTOMER_CONTINUED_RIDE) && triggerEventsSet.size > 1) {
            this.showWarningToster("POR Customer Continued Response trigger event cannot be in combination with escalation ticket or captain feedback");
            return false;
        }
        if (!triggerEvents || !triggerEvents.length) {
            this.showWarningToster("Please add at least 1 event");
            return false;
        }
        if (!triggerEvents[0].event) {
            this.showWarningToster("Missing event in rule");
            return false;
        }
        var _loop_2 = function (i) {
            if (this_1.eventReasons[i]) {
                if (this_1.isAutomatedReactiveModel() && triggerEvents[i].event === TRIGGER_EVENT.ESCALATION_TICKET) {
                    var selectedEventReason = this_1.eventReasons[i].find(function (eventReason) { return triggerEvents[i].values && triggerEvents[i].values[0].toLowerCase() === eventReason.reason.toLowerCase(); });
                    if (selectedEventReason !== undefined) {
                        triggerEvents[i].values[0] = selectedEventReason.context;
                        triggerEvents[i].key = this_1.escalationTicketKey;
                    }
                    return "continue";
                }
                if (triggerEvents[i].event === TRIGGER_EVENT.PARTIAL_OFFLINE_RIDE) {
                    triggerEvents[i].values = [];
                    if (!triggerEvents[i].customerReason || !triggerEvents[i].customerReason) {
                        this_1.showWarningToster("Missing customer reason in rule # " + (parseInt(i) + 1));
                        return { value: false };
                    }
                    if (!triggerEvents[i].captainReason || !triggerEvents[i].captainReason) {
                        this_1.showWarningToster("Missing captain reason in rule # " + (parseInt(i) + 1));
                        return { value: false };
                    }
                    triggerEvents[i].values[0] = this_1.fetchValueForPartialOfflineRide(triggerEvents[i]);
                    triggerEvents[i].key = PARTIAL_OFFLINE_RIDE_KEY;
                    return "continue";
                }
                if ((this_1.eventReasons[i][0] && this_1.eventReasons[i][0].key && !triggerEvents[i].key)) {
                    triggerEvents[i].key = this_1.eventReasons[i][0].key;
                }
                if (!triggerEvents[i].key) {
                    this_1.showWarningToster("Missing key reason in rule # " + (parseInt(i) + 1));
                    return { value: false };
                }
                if (!triggerEvents[i].values || triggerEvents[i].values.length == 0) {
                    this_1.showWarningToster("Missing event reason in rule # " + (parseInt(i) + 1));
                    return { value: false };
                }
            }
            else {
                triggerEvents[i].key = "";
                triggerEvents[i].values = [];
            }
            if (~RATING_TRIGGERS.indexOf(triggerEvents[i].event)) {
                if (!Object.values(EQUATION_TYPES).includes(triggerEvents[i].values[0])) {
                    triggerEvents[i].values = [this_1.getEquationForRating(triggerEvents[i].values)];
                }
                if (Object.values(EQUATION_TYPES).includes(triggerEvents[i].values[0])) {
                    var ratingValues = this_1.equationToRatingTransform(triggerEvents[i].values[0], this_1.ratingValues[i]);
                    triggerEvents[i].values = ratingValues;
                }
            }
            if (triggerEvents[i].event === TRIGGER_EVENT.RIDER_OR_CUSTOMER_CANCELLED && this_1.getValue('services').length > 1) {
                this_1.showWarningToster("Trigger : (Rider or Customer Cancelled) can be used for only for a single service.");
                return { value: false };
            }
        };
        var this_1 = this;
        for (var i in triggerEvents) {
            var state_1 = _loop_2(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return true;
    };
    PenaltyBasicInfoComponent.prototype.showWarningToster = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message,
        }));
    };
    PenaltyBasicInfoComponent.prototype.mandatoryRollingWindowCheck = function () {
        var message = '';
        var mandatoryRollingWindowTrigger = this.getValue("triggerEvents").find(function (trigger) {
            return [TRIGGER_EVENT.CCR, TRIGGER_EVENT.CAPTAIN_CANCELLATIONS, TRIGGER_EVENT.TRUE_DPR, TRIGGER_EVENT.OVERALL_RATING].includes(trigger.event);
        });
        var rollingWindow = this.getValue("rollingWindowDays");
        if (mandatoryRollingWindowTrigger && !this.applyRollingWindow) {
            message = 'Rolling window is required for the selected trigger event';
        }
        if (mandatoryRollingWindowTrigger && this.applyRollingWindow && rollingWindow > 30) {
            message = 'Rolling window should be less than 30';
        }
        return message;
    };
    // Check if you can move this part to a new file
    PenaltyBasicInfoComponent.prototype.organiseData = function () {
        var _this = this;
        var overallRatingInvalidMsg = this.mandatoryRollingWindowCheck();
        if (overallRatingInvalidMsg.length) {
            this.showWarningToster(overallRatingInvalidMsg);
            return;
        }
        if (!this.getValue('name')) {
            this.showWarningToster("Campaign Name is required");
            return;
        }
        else if (!this.getValue('city')) {
            this.showWarningToster("Please Select a City");
            return;
        }
        else if (!this.isStreakGame() && !this.getValue('services') || (this.getValue('services') && this.getValue('services').length < 1)) {
            this.showWarningToster("Please Select a Service");
            return;
        }
        else if (!(this.minDate && this.maxDate)) {
            this.showWarningToster("Start Date and End Date are required");
            return;
        }
        else if (this.minDate.getTime() > this.maxDate.getTime()) {
            this.showWarningToster("Start Date cannot be greater than End Date");
            return;
        }
        else if (this.state != this.sharedService.STATE.VIEW && this.state != this.sharedService.STATE.EDIT && this.minDate.getTime() < this.currentDate.getTime()) {
            this.showWarningToster("You cannot create a campaign in the past");
            return;
        }
        else if (this.state != this.sharedService.STATE.VIEW && this.state != this.sharedService.STATE.EDIT && this.maxDate.getTime() < this.currentDate.getTime()) {
            this.showWarningToster("You cannot create a campaign in the past");
            return;
        }
        if (this.triggerEvents && this.triggerEvents.value && this.triggerEvents.value.length) {
            var haveInstanceLeewayEvent = this.triggerEvents.value.find(function (trigger) { return ~INSTANCE_LEEWAY_EVENTS.indexOf(trigger.event); });
            if (haveInstanceLeewayEvent && this.triggerEvents.length > 1) {
                this.showWarningToster("You cannot have more than one trigger event with the selected event.");
                return;
            }
            var allEventValues = this.triggerEvents.value.map(function (trigger) { return trigger.event; });
            if (this.triggerEvents.length !== new Set(allEventValues).size) {
                this.showWarningToster("Cannot have same type of trigger events.");
                return;
            }
            var validTriggerEvents = this.validateTriggerEvents(this.triggerEvents.value);
            if (!validTriggerEvents) {
                return;
            }
            if (this.isAutomatedReactiveModel()) {
                if (this.triggerEvents.length > 1) {
                    var selectedEscalationTicketEvent = this.triggerEvents.value[0].event === TRIGGER_EVENT.ESCALATION_TICKET ? this.triggerEvents.value[0] : this.triggerEvents.value[1];
                    var selectedCaptainFeedbackEvent = this.triggerEvents.value[0].event === TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED ? this.triggerEvents.value[0] : this.triggerEvents.value[1];
                    if (selectedEscalationTicketEvent.values[0] !== selectedCaptainFeedbackEvent.key) {
                        this.showWarningToster("Captain Feedback and Escalation Ticket should have the same contexts");
                        return;
                    }
                }
            }
        }
        if (this.applyLeeway) {
            if (!this.getValue('leeway')) {
                this.showWarningToster("Please Enter Leeway Limit");
                return;
            }
            if (!this.getValue('leewayWarnMsg')) {
                this.showWarningToster("Please Enter Leeway Warning Message");
                return;
            }
        }
        if (this.applyInstanceLeeway) {
            if (!this.getValue('instanceLeeway')) {
                this.showWarningToster("Please Enter Leeway Limit");
                return;
            }
            if (!this.getValue('instanceLeewayWarnMsg')) {
                this.showWarningToster("Please Enter Leeway Warning Message");
                return;
            }
        }
        if (this.applyEligibility) {
            if (!this.getValue('eligibilityPings')) {
                this.showWarningToster("Please Enter eligibility Pings");
                return;
            }
            if (!this.getValue('eligibilityEvents').length) {
                this.showWarningToster("Please add eligibility Events");
                return;
            }
            if (!this.getValue('eligibilityWarnMsg').length) {
                this.showWarningToster("Please add eligibility warning Message");
                return;
            }
        }
        if (this.applyDaprCheck) {
            if (!this.getValue('daprPercentageStart') || !this.getValue('daprPercentageEnd')) {
                this.showWarningToster("Please Enter DAPR Percentage");
                return;
            }
        }
        if (this.applySegmentEligibility && !this.getValue('eligibilitySegments')) {
            this.showWarningToster("Please Enter Captain eligibility Segments");
            return;
        }
        var mandatoryRollingWindowEvent = null;
        if (this.triggerEvents && this.triggerEvents.value && this.triggerEvents.value.length) {
            mandatoryRollingWindowEvent = this.triggerEvents.value.find(function (trigger) { return ~aprDprEvents.indexOf(trigger.event); });
        }
        if (this.applyRollingWindow || mandatoryRollingWindowEvent) {
            var message = void 0;
            var rollingDays = this.getValue('rollingWindowDays');
            var startDate = this.getValue('startDate');
            var endDate = this.getValue('endDate');
            // const diff = startDate && endDate && Math.ceil((endDate-startDate) / (1000 * 60 * 60 * 24)) + 1;
            if (!rollingDays || !this.applyRollingWindow) {
                message = "Please Enter Rolling Window Days";
            }
            else if (rollingDays < 1) {
                message = "Rolling Window Days value is not valid. Should be greater than or equal 1";
                // } else if (rollingDays > diff) {
                //   message = `Rolling Window Days should be less than or equal the campaign duration.`;
            }
            else if (!this.getCountFromHistory && rollingDays > this.daysAfterWhichCheckHistoricAction) {
                message = "Rolling Window Days value is not valid. Should be less than or equal " + this.daysAfterWhichCheckHistoricAction;
            }
            else if (rollingDays > this.maxRollingWindowSpan) {
                message = "Rolling Window Days value is not valid. Should be less than or equal " + this.maxRollingWindowSpan;
            }
            if (message) {
                this.showWarningToster(message);
                return;
            }
        }
        if (!this.validateTimeSlots()) {
            this.showWarningToster("Please Check Time Slots");
            return;
        }
        var city = this.cities.find(function (city) { return city.id === _this.getValue('city'); });
        var cityName = city.name;
        var services = [];
        var selectedServices;
        if (typeof (this.getValue('services')) === "string") {
            selectedServices = [];
            selectedServices.push(this.getValue('services'));
        }
        else {
            selectedServices = this.getValue('services');
        }
        var serviceName = "";
        var serviceNames = [];
        var serviceIds = [];
        var _loop_3 = function (service) {
            if (selectedServices[service]) {
                var currentService = this_2.services.find(function (servicee) { return servicee.id === selectedServices[service]; });
                if (currentService) {
                    services.push(currentService);
                    serviceNames.push(currentService.name);
                    serviceIds.push(currentService.serviceId);
                }
            }
        };
        var this_2 = this;
        for (var service in selectedServices) {
            _loop_3(service);
        }
        var data = {
            name: this.getValue('name'),
            cityId: this.getValue('city'),
            city: cityName,
            // service: selectedServices && typeof(selectedServices) === 'string' ? selectedServices : '',
            services: selectedServices,
            serviceNames: serviceNames,
            serviceIds: serviceIds,
            userSelectors: this.getValue('selectors'),
            startDate: this.minDate,
            endDate: this.maxDate,
            // triggerEvent: this.events,
            timeSlots: this.getValue('timeSlots'),
            // eventReasonKey: this.getValue('eventReasonKey'),
            fetchedEvents: this.eventsData,
            eventsAndReasons: this.triggerEvents.value,
            ruleType: this.campaignType,
            priority: this.getValue('priority')
        };
        if (this.applyLeeway) {
            data['leeway'] = this.getValue('leeway');
            data['leewayWarnMsg'] = this.getValue('leewayWarnMsg');
        }
        if (this.applyInstanceLeeway) {
            data['instanceLeeway'] = this.getValue('instanceLeeway');
            data['instanceLeewayWarnMsg'] = this.getValue('instanceLeewayWarnMsg');
        }
        if (this.applyRollingWindow) {
            data['rollingWindowDays'] = this.getValue('rollingWindowDays');
        }
        if (this.applyEligibility) {
            data['eligibility'] = {
                pings: this.getValue('eligibilityPings'),
                events: this.getValue('eligibilityEvents'),
                warnMsg: this.getValue('eligibilityWarnMsg')
            };
        }
        if (this.applyDaprCheck) {
            data['daprPercentageStart'] = this.getValue('daprPercentageStart');
            data['daprPercentageEnd'] = this.getValue('daprPercentageEnd');
        }
        if (this.applySegmentEligibility) {
            data['eligibilitySegments'] = this.getValue('eligibilitySegments');
        }
        if (this.applySelector) {
            var selectors = this.getValue('selectors');
            var selectorNames_1 = [];
            if (selectors && selectors.length > 0) {
                selectors.forEach(function (selector) {
                    var userSelector = _this.userSelectors.find(function (userSelector) { return userSelector._id === selector; });
                    selectorNames_1.push(userSelector.name);
                });
                data['userSelector'] = selectors;
                data['userSelectorNames'] = selectorNames_1;
                data['fallbackToDefaultRule'] = this.fallbackToDefaultRule;
            }
        }
        if (this.state === this.sharedService.STATE.EDIT) {
            data['_id'] = this.defaultValue._id;
        }
        data['blockRedeemEligibility'] = this.penaltyBasicInfoService.checkBlockRedeemEligibility(this.services, data);
        console.log("Final Data ", data);
        return data;
    };
    PenaltyBasicInfoComponent.prototype.showRollingWIndowModal = function () {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.height = '275px';
        dialogConfig.width = '484px';
        dialogConfig.panelClass = 'custom-dialog-container';
        this.dialog.open(RollingWindowDialogComponent, dialogConfig);
    };
    PenaltyBasicInfoComponent.prototype.getAllowedValuesForEventReason = function (eventReasons, index) {
        // this.basicInfoFormGroup.controls.triggerEvents.value[index].values = eventReasons;
        if (eventReasons[0] === EQUATION_TYPES.BETWEEN) {
            this.applyRatingBetween[index] = true;
            this.applyRatingGreaterOrLessThan[index] = false;
            this.deleteGreaterOrLesserThanRatingValue(index);
        }
        else if (eventReasons[0] === EQUATION_TYPES.GREATER_THAN || eventReasons[0] === EQUATION_TYPES.LESS_THAN) {
            this.applyRatingGreaterOrLessThan[index] = true;
            this.applyRatingBetween[index] = false;
            this.deleteBetweenRatingValues(index);
        }
        else {
            this.applyRatingGreaterOrLessThan[index] = false;
            this.applyRatingBetween[index] = false;
            this.deleteGreaterOrLesserThanRatingValue(index);
            this.deleteBetweenRatingValues(index);
        }
    };
    PenaltyBasicInfoComponent.prototype.deleteGreaterOrLesserThanRatingValue = function (index) {
        var ratingValue = this.ratingValues[index];
        if (ratingValue && ratingValue.ratingGreaterOrLessThan) {
            delete ratingValue.ratingGreaterOrLessThan;
        }
    };
    PenaltyBasicInfoComponent.prototype.deleteBetweenRatingValues = function (index) {
        var ratingValue = this.ratingValues[index];
        if (ratingValue) {
            delete ratingValue.ratingBetweenStart;
            delete ratingValue.ratingBetweenEnd;
        }
    };
    PenaltyBasicInfoComponent.prototype.equationToRatingTransform = function (equation, _a) {
        var ratingBetweenEnd = _a.ratingBetweenEnd, ratingBetweenStart = _a.ratingBetweenStart, ratingGreaterOrLessThan = _a.ratingGreaterOrLessThan;
        var upperLimit = 5;
        var lowerLimit = 1;
        var ratings = [];
        if (equation === EQUATION_TYPES.GREATER_THAN) {
            for (var i = ratingGreaterOrLessThan + 1; i <= upperLimit; i++) {
                ratings.push(i.toString());
            }
        }
        else if (equation === EQUATION_TYPES.LESS_THAN) {
            for (var i = lowerLimit; i < ratingGreaterOrLessThan; i++) {
                ratings.push(i.toString());
            }
        }
        else if (equation === EQUATION_TYPES.BETWEEN) {
            for (var i = ratingBetweenStart + 1; i < ratingBetweenEnd; i++) {
                ratings.push(i.toString());
            }
        }
        return ratings;
    };
    PenaltyBasicInfoComponent.prototype.ratingToEquationTransform = function (ratings, index) {
        var equation = this.getEquationForRating(ratings);
        if (equation === EQUATION_TYPES.LESS_THAN) {
            this.applyRatingGreaterOrLessThan[index] = true;
            this.ratingValues[index].ratingGreaterOrLessThan = parseInt(ratings[ratings.length - 1]) + 1;
        }
        else if (equation === EQUATION_TYPES.GREATER_THAN) {
            this.applyRatingGreaterOrLessThan[index] = true;
            this.ratingValues[index].ratingGreaterOrLessThan = parseInt(ratings[0]) - 1;
        }
        else {
            this.applyRatingBetween[index] = true;
            this.ratingValues[index].ratingBetweenStart = parseInt(ratings[0]) - 1;
            this.ratingValues[index].ratingBetweenEnd = parseInt(ratings[ratings.length - 1]) + 1;
        }
        return equation;
    };
    PenaltyBasicInfoComponent.prototype.getEquationForRating = function (ratings) {
        var upperLimit = "5";
        var lowerLimit = "1";
        if (ratings.indexOf(lowerLimit) != -1) {
            return EQUATION_TYPES.LESS_THAN;
        }
        else if (ratings.indexOf(upperLimit) != -1) {
            return EQUATION_TYPES.GREATER_THAN;
        }
        else {
            return EQUATION_TYPES.BETWEEN;
        }
    };
    PenaltyBasicInfoComponent.prototype.checkIfTriggerEvent = function () {
        var triggerEvents = this.basicInfoFormGroup.controls.triggerEvents.value;
        return triggerEvents.some(function (event) { return ~INSTANCE_LEEWAY_EVENTS.indexOf(event.event); });
    };
    PenaltyBasicInfoComponent.prototype.isStreakGame = function () {
        return this.triggerEvents.value && this.triggerEvents.value[0].event == "streak";
    };
    PenaltyBasicInfoComponent.prototype.gameTypeCheck = function () {
        if (this.getValue('city'))
            this.basicInfoFormGroup.get('city').setValue('');
    };
    PenaltyBasicInfoComponent.prototype.checkPriority = function () {
        if (this.getValue('priority') && this.getValue('priority') < 0) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Priority should be greater than 0",
            }));
        }
    };
    return PenaltyBasicInfoComponent;
}());
export { PenaltyBasicInfoComponent };
