import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { $ } from 'protractor';


@Component({
  selector: 'filter-pane',
  templateUrl: './filter-pane.component.html'
})
export class FilterPaneComponent implements OnInit, OnChanges {
  public showMenu: boolean;
  @Input() filterComponents: any;
  @Input() showFilter: any;
  public storedData: any = {};
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  resetFields = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterComponents) {
      this.filterComponents = changes.filterComponents.currentValue;
    }
    if (changes.showFilter) {
      this.openNav();
    }
  }


  ngOnInit() {
    this.showMenu = true;
    this.closeNav();
  }

  private getSideNavObject() {
    const sideNav = document.getElementById('mySidenav');
    return sideNav;
  }

  openNav() {
    const sideNav = this.getSideNavObject();
    if (sideNav) {
      sideNav.style.width = '400px';
      this.showMenu = false;
    }
  }

  closeNav() {
    const sideNav = this.getSideNavObject();
    if (sideNav) {
      sideNav.style.width = '0';
      this.showMenu = true;
    }
  }

  updateData($evt, key) {
    this.storedData[key] = $evt[key];
    this.change.emit($evt);
  }

  submitFilters() {
    this.submit.emit(this.storedData);
    this.closeNav();
  }

  clearFilters() {
    this.clear.emit();
    this.storedData = {};
    this.resetFields = true;
    setTimeout(() => this.resetFields = false, 100);
    this.submitFilters();
  }
}
