import { Component, OnInit, Output } from '@angular/core';
import { ViewSlabsService } from './view-slabs.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes, Button } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { LoaderService } from '../loader.service';


@Component({
  selector: 'view-slabs',
  templateUrl: './view-slabs.component.html',
  providers: [ViewSlabsService]
})
export class ViewSlabsComponent implements OnInit {

  public limit = 15;
  public gotData = false;
  public offset = 0;
  dataLoaded = false;
  tableLoaded = false;
  public table: Table;
  public headerToSort: string;
  public cities: [] = [];
  public services = [];
  public shifts = [];
  public filterComponents: any[] = [];
  public cityMap = {};
  public serviceMap = {};
  public filterParams = {};
  public showFilters = false;
  public shiftSearch: string;

  constructor(
    private viewSlabService: ViewSlabsService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
  ) {
    this.defineTable();
   }

   defineTable() {
    this.table = new Table({
      headers: {
        active: 'Status',
        cities: 'City',
        shift: 'Shift Name',
        serviceTypes: 'Service',
        startDate: 'Start Date',
        endDate: 'End Date',
        days: 'Weekdays',
        action: 'Action',
      },
      pagination: new Pagination(0, 15, 10, [15, 20]),
      onSortingChange: (header) => {
      }
    });
    this.tableLoaded = true;
   }


sortByHeader($evt) {
  if ($evt !== 'action' && $evt !== '-action') {
    this.headerToSort = $evt;
    this.fetchTableData();
  }
}

    fetchTableData() {
        this.loaderService.openLoading();
        const payload = {
          queryParams : {
            limit : this.table.pagination.perPage,
            offset : this.table.pagination.pageNo * this.table.pagination.perPage,
            sort : this.headerToSort || '-startDate'
          }
        };
        if (this.filterParams) {
          payload['queryParams'] = {...this.filterParams, ...payload['queryParams']};
          console.log("Filters : ", payload);
        }
          this.viewSlabService.fetchSlabData(payload).subscribe((res: any) => {
          res.data = res.data || [];

          for (const slab in res.data) {
            if (res.data[slab]) {
              var days = [];
              res.data[slab]['serviceTypes'] = res.data[slab].serviceNames || [];
              for (const day in res.data[slab]['days']) {
                if (res.data[slab]['days'][day]) {
                  switch ( res.data[slab]['days'][day]) {
                    case 'Monday' : days.push('M'); break;
                    case 'Tuesday' : days.push('T'); break;
                    case 'Wednesday' : days.push('W'); break;
                    case 'Thursday' : days.push('Th'); break;
                    case 'Friday' : days.push('F'); break;
                    case 'Saturday' : days.push('S'); break;
                    case 'Sunday' : days.push('Su'); break;
                    default : days = [];
                  }
                }
              }
              res.data[slab]['days'] = days;
            }
          }
          this.table.data = res.data.map(slab => {
            slab.cities = slab.cities || [];
            return Object.assign({}, slab, {
              cities: slab.cities.join(', '),
              action: this.createAction(),
            });
          });
          this.table.pagination.count = res.count;
          this.loaderService.closeLoading();
          this.dataLoaded = true;
        });
    }

    fetchAllCities() {
      this.sharedService.fetchCities().subscribe((res: any) => {
        res.data = res.data || [];
        this.cities = res.data.cities.map(city => city.displayName);
        this.filterComponents = this.filterComponents.map((node, index) => {
          if (index === 0) {
            node.values = this.cities;
          }
          return node;
        });
        for (const city in res.data.cities) {
          if (res.data.cities[city]) {
            this.cityMap[res.data.cities[city].displayName] = res.data.cities[city]._id;
          }
        }
      });
    }

    fetchServices(city) {
      this.services = [];
      if (city.length === 0) {
        this.filterComponents = this.filterComponents.map((node, index) => {
          if (index === 1) {
            node.values = this.services;
          }
          return node;
        });
      }
      for (const cty of city) {
        if (cty) {
          const cityId = this.cityMap[cty];
          this.sharedService.fetchServices(cityId).subscribe((res: any) => {
            for (const service in res.data) {
              if (this.services.indexOf(res.data[service].service.name) === -1) {
                this.services.push(res.data[service].service.name);
              }
            }
            for (const service in res.data) {
              if (res.data[service].service) {
                this.serviceMap[res.data[service].service.name] = res.data[service]._id;
              }
            }
            this.filterComponents = this.filterComponents.map((node, index) => {
              if (index === 1) {
                node.values = this.services;
              }
              return node;
            });
          });
        }
      }
    }

    fetchShifts(city) {
      this.shifts = [];
      if (city.length === 0) {
        this.filterComponents = this.filterComponents.map((node, index) => {
          if (index === 2) {
            node.values = [];
          }
          return node;
        });
      }
      for (const cty of city) {
        if (cty) {
          this.sharedService.fetchShifts(cty).subscribe((res: any) => {
            this.shifts.push(...res.data);
            this.filterComponents = this.filterComponents.map((node, index) => {
              if (index === 2) {
                node.values = this.shifts;
              }
              return node;
            });
          });
        }
      }
    }

    createAction() {
      return new TableData({
        actions : new TableActions([
          new TableAction({
            onClick: (data) => {
              this.onViewSlab(data._id["$oid"]);
            },
            text: 'View'
          }),
          new TableAction({
            onClick: (data) => {
              this.onDuplicateSlab(data._id["$oid"]);
            },
            text: 'Duplicate'
          })
        ]),
        type: TableDataTypes.ACTION
      });
    }

    private onViewSlab(slabId) {
      const url = `/payroll/viewSlab/${slabId}`;
      this.sharedService.redirectToDashbaord(url);
    }

    private onDuplicateSlab(slabId) {
      const url = `/payroll/duplicateSlab/${slabId}`;
      this.sharedService.redirectToDashbaord(url);
    }

    filterToggle() {
      this.showFilters = !this.showFilters;
    }

  ngOnInit() {
    this.fetchTableData();
    this.fetchAllCities();
    // call city api, assign names to cities []
    this.filterComponents = [
      {
        type: 'searchable-dropdown',
        title: 'City',
        values: this.cities,
        default: '',
        key: 'slab-city'
      },
      {
        type: 'searchable-dropdown',
        title: 'Services',
        values: this.services,
        default: '',
        key: 'slab-services'
      },
      {
        type: 'searchable-dropdown',
        title: 'Shifts',
        values: this.shifts,
        default: '',
        key: 'slab-shifts'
      },
      {
        type : 'calendar',
        title : '',
        values : [],
        default : '',
        key : 'slab-dates'
      },
      // {
      //   type : 'single-searchable-dropdown',
      //   title : 'Type',
      //   values : ['cm', 'order', 'distance'],
      //   default : '',
      //   key: 'slab-type'
      // },
      {
        type : 'searchable-dropdown',
        title : 'Weekdays',
        values : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        default : '',
        key: 'slab-weekdays'
      },
      {
        type : 'radio-button',
        title : 'Status',
        values : ['Both', 'Active', 'Inactive'],
        default : '',
        key: 'slab-status'
      }
    ];
  }

  listen($evt) {
    if ($evt['slab-city']) {
      if ($evt['slab-city'] === []) {
        this.services = [];
      } else {
        this.fetchServices($evt['slab-city']);
        this.fetchShifts($evt['slab-city']);
      }
    }
  }

  searchByShift() {
    this.filterParams = {
      shift__regex : this.shiftSearch
    };
    this.fetchTableData();
  }

  applyFilters($event) {
    // keys: 'slab-status' 'slab-weekdays' 'slab-type' 'slab-dates' 'slab-shifts' 'slab-services' 'slab-city'
    console.log('Got these filters : ', $event);
    this.table.pagination.setPageNoToZero();
    this.filterParams = {};
    const filters = $event;
    if (filters['slab-status']) {
      if (filters['slab-status'] === 'Both') {
        delete this.filterParams['active__eq'];
      } else {
        this.filterParams['active__eq'] = filters['slab-status'] === 'Inactive' ? false : true;
      }
    }

    if (filters['slab-weekdays'] && filters['slab-weekdays'].length > 0) {
      this.filterParams['days__in'] = filters['slab-weekdays'];
    }

    // if (filters['slab-type']) {
    //   this.filterParams['type'] = filters['slab-type'];
    // }

    if (filters['slab-dates'] && filters['slab-dates'].length > 0) {
      if (filters['slab-dates'][0]) {
        this.filterParams['startDate__gte'] = filters['slab-dates'][0];
      }
      if (filters['slab-dates'][1]) {
        this.filterParams['startDate__lte'] = filters['slab-dates'][1];
      }
    }

    if (filters['slab-shifts'] && filters['slab-shifts'].length > 0) {
      this.filterParams['shift__in'] = filters['slab-shifts'];
    }

    if (filters['slab-services'] && filters['slab-services'].length > 0) {
      var serviceIds = [];
      for (const service of filters['slab-services']) {
        serviceIds.push(this.serviceMap[service]);
      }
      this.filterParams['serviceTypes__in'] = serviceIds;
    }

    if (filters['slab-city'] && filters['slab-city'].length > 0) {
      this.filterParams['cities__in'] = filters['slab-city'];
    }
    this.fetchTableData();
  }
}
