import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ViewIncentivesService {
  private fetchIncentives = environment.server + '/api/get/incentives/v2'; // make this configurable

  constructor(private http: MyHttpClient) { }

  fetchIncentiveData(payload) {
    // const payload = {
    //   "pagination":{
    //       "pageNo":1,
    //       "pageSize":10
    //   },
    //   "filter":{
    //       "cities":[
    //         "Bangalore"
    //       ],
    //       "serviceType":[
    //         "57370b61a6855d70057417d1"
    //       ],
    //       "types":[
    //         "Daily"
    //       ],
    //       "startDate":"2019-07-03",
    //       "endDate":"2019-07-11",
    //       "status":""
    //   }
    // };
    // payload.filter = filter;
    return this.http.post(this.fetchIncentives, payload)
      .pipe(map((res: any) => res.data || res))
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
    // return {};
  }
}
