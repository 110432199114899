import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inr' })
export class IndianCurrency implements PipeTransform {
  transform(value: number, args: string[]): string {
    return value.toLocaleString('en-IN', {
      style: 'currency',
      currency: "INR"
    });
  }
}
