import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PenaltyRewardCampaignService {

  constructor(private http: MyHttpClient) { }

  createCampaignUrl = `${environment.server}/api/createCampaign`;
  editCampaignUrl = `${environment.server}/api/editCampaign`;
  fetchCampaignsUrl = environment.server + '/api/get/campaigns/v1';
  fetchActionsUrl = `${environment.server}/api/get/actions/v1`;
  fetchNotificationsUrl = `${environment.server}/api/get/notifications/v1`;
  fetchCaptainTasksUrl = `${environment.server}/api/get/captainTasks`;
  fetchGalaxyCampaignsUrl = `${environment.server}/api/get/galaxyCampaigns`;

  createCampaign = (campaign) => this.http.post(this.createCampaignUrl, campaign).pipe(catchError(this.handleError));

  editCampaign = (campaign, campaignId) => this.http.put(this.editCampaignUrl + '/' + encodeURIComponent(campaignId), campaign).pipe(catchError(this.handleError));

  getCampaign = (payload) => this.http.post(this.fetchCampaignsUrl, payload).pipe(catchError(this.handleError));

  fetchActions = () => this.http.get(this.fetchActionsUrl).pipe(catchError(this.handleError));

  fetchCaptainTasks = () => this.http.get(this.fetchCaptainTasksUrl).pipe(catchError(this.handleError));

  fetchGalaxyCampaigns = () => this.http.get(this.fetchGalaxyCampaignsUrl).pipe(catchError(this.handleError));

  getNotifications = () => this.http.get(this.fetchNotificationsUrl).pipe(catchError(this.handleError));

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
