import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader.service';
import { Button, Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { BulkCreditArrayFields, BulkCreditService } from '../bulkcredit.service';
import * as moment from 'moment';
import { AlertDialogOptions, AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BULK_CREDIT_HISTORY } from 'src/app/routes';
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class BulkCreditHistoryComponent implements OnInit {
  public table: Table = new Table({
    pagination: new Pagination(0, 10, 100, [5, 10, 15, 20]),
    headers: {
      createdAt: 'Date',
      createdBy: 'Initiated By',
      totalTransactions: 'Number of captains',
      totalAmount: 'Total Amount',
      subTransactionType: 'Sub Transaction',
      status: 'Status',
      result: 'Download result',
    },
    data: []
  });
  private sortableColumns: string[] = ["createdAt"];
  private requestBody = {
    page: {} as any,
    sort: {
      sortBy: "createdAt",
      sortOrder: -1
    },
    filter: {} as any
  };

  showFilters = false;
  filterComponents = [
    {
      type: 'searchable-dropdown',
      title: 'Subtransaction Type',
      values: this.bulkCreditService.transactionTypes.map(t => t.displayName),
      key: 'subTransactionType'
    },
    {
      type: 'calendar',
      title: 'Created Date',
      key: 'createdAt'
    }
  ];

  constructor(private bulkCreditService: BulkCreditService,
    private loader: LoaderService,
    private alertService: AlertDialogService,
    private currentRoute: ActivatedRoute,
    private router: Router) { }

  showAlertBox(options: AlertDialogOptions, cb = () => { }) {
    this.alertService.open(options);
    this.alertService.confirmed().subscribe(confirmed => confirmed && cb());
  }

  ngOnInit() {
    this.currentRoute.queryParams
      .subscribe(async params => {
        if (params.downloadResult) {
          await this.downloadResultCSV(params.downloadResult);
          this.router.navigateByUrl(BULK_CREDIT_HISTORY);
        } else {
          await this.fetchHistory();
        };
      });
  }

  async fetchHistory() {
    this.loader.openLoading();
    try {
      this.addPageDetails();
      const history = await this.bulkCreditService.fetchBulkCreditHistory(this.requestBody) as any[];
      this.table.data = history.map(item => ({
        ...item,
        subTransactionType: this.subTransactionTypeName(item.subTransactionType),
        createdAt: new Date(item.createdAt).toLocaleDateString(),
        result: new TableData({
          type: TableDataTypes.BUTTON,
          button: new Button({
            label: "Download Result",
            className: "btn btn-secondary",
            onClick: () => this.downloadResultCSV(item._id.$oid),
          })
        })
      }));
    } catch (e) {
      this.showAlertBox({
        title: "Error",
        message: "Something went wrong while fetching history",
      });
    } finally {
      this.loader.closeLoading();
    }
  }

  async downloadResultCSV(fileId: string) {
    this.loader.openLoading();
    try {
      const { file, transactions } = await this.bulkCreditService.downloadResult(fileId) as any;

      const subTransactionType = this.bulkCreditService.transactionTypes
        .find(t => t.name === file.subTransactionType);

      const formatArrayFields: (value: any) => any = transaction => {
        return BulkCreditArrayFields.reduce((item, field) => {
          const value = transaction[field];
          if (!value) {
            return item;
          }
          item[field] = value.join("|");
          return item;
        }, transaction);
      };

      Utils.downloadCSVFile({
        data: transactions.map(formatArrayFields),
        fields: subTransactionType.headers.concat("status"),
        fileName: `${file.subTransactionType}_${file.createdAt}_result.csv`.toLowerCase()
      });
    } catch (e) {
      this.showAlertBox({
        title: "Error",
        message: "Something went wrong while downloading result",
      });
      console.log(e);
    } finally {
      this.loader.closeLoading();
    }
  }

  private addPageDetails() {
    const limit = this.table.pagination.perPage;
    this.requestBody.page.limit = limit;
    this.requestBody.page.offset = this.table.pagination.pageNo * limit;
  }

  private subTransactionTypeName(code: string) {
    return this.bulkCreditService
      .transactionTypes
      .find(t => t.name === code)
      .displayName;
  }

  private subTransactionTypeCode(name: string) {
    return this.bulkCreditService
      .transactionTypes
      .find(t => t.displayName === name)
      .name;
  }

  async sortByHeader(header: string) {
    const sortOrder = header.includes("-") ? -1 : 1;
    const column = header.replace("-", "");
    if (!this.sortableColumns.includes(column)) {
      return;
    }

    this.requestBody.sort.sortBy = column;
    this.requestBody.sort.sortOrder = sortOrder;

    await this.fetchHistory();
  }

  async applyFilters(newFilters) {
    const filters = {} as any;
    if (newFilters.subTransactionType && newFilters.subTransactionType.length > 0) {
      filters.subTransactionType = newFilters
        .subTransactionType
        .map((name: string) => this.subTransactionTypeCode(name));
    }
    const [from, to] = newFilters.createdAt || [] as string[];
    if (from) {
      filters.from = moment(from).valueOf();
    }
    if (to) {
      filters.to = moment(to).endOf("day").valueOf();
    }

    this.requestBody.filter = filters;
    await this.fetchHistory();
  }
}
