<div class="center">
  <form [formGroup]="auditCampaignFormGroup" class="flex-col width-600">
    <div class="flex-row margin-y-10 font-20 space-bw width-600">
      <span class="bold">Service type</span>
      <span>Food Delivery</span>
    </div>

    <div class="flex-row margin-y-10">
      <label for="actionType" class="font-20 bold">Action type</label>
      <ng-select formControlName="actionType" name="actionType" placeholder="Select"
        class="width-200 flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let item of enabledActionTypes" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div *ngIf="selectedActionType()" class="flex-col margin-y-10">
      <p-fileUpload #fileUpload accept="text/csv" [maxFileSize]="1000000" [showCancelButton]="false"
        [showUploadButton]="false" (onSelect)="handleFile($event)" class="margin-y-10" (onRemove)="resetFile()" chooseLabel="Upload File">
        <ng-template pTemplate="toolbar">
          <button pButton (click)="downloadSampleFile()" class="ui-button-secondary"
            style="background-color: gray; color: white;" label="Download Sample File">
          </button>
        </ng-template>
      </p-fileUpload>
      <mat-card>
        <span class="bold">Note:</span>
        Refer to the
        <span class="bold">Sample File</span> for input file format details.
      </mat-card>

      <div class="margin-y-10 flex-row space-bw pull-right">
        <button mat-raised-button class="btn-primary" (click)="valdidateAuditCampaignFile()"
          [disabled]="!fileDetails  || validationError"
          *ngIf="!validated">Validate File</button>
        <button mat-raised-button class="btn-primary" (click)="confirmAuditCampaignInitiate()" *ngIf="validated">
          Initiate Audit</button>
      </div>
    </div>
  </form>
</div>
