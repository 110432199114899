<div class="center">
  <form [formGroup]="subscriptionFormGroup" class="flex-col width-800">
    <h2 class="center margin-y-10">
      Create new subscription rules
    </h2>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Rule Name:</label>
      <input formControlName="ruleName" name="ruleName" placeholder="Rule Name" class="flex-grow font-20 margin-l-10" />
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">City:</label>
      <ng-select formControlName="city" name="city" placeholder="Select city" class="flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let item of cities" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Services:</label>
      <ng-select formControlName="services" name="services" placeholder="Select services" (change)="validateAndGetModeForServices();"
        class="flex-grow font-20 margin-l-10" [multiple]=true>
        <ng-option *ngFor="let item of services" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10">
      <div class="width-50 font-20 flex-grow">
        <label class="width-50 font-20 bold"></label>
        <mat-checkbox formControlName="isAutoAssignedToHH" class="flex-grow font-20 margin-l-10" color="primary">
          HH Subscription
        </mat-checkbox>
        <mat-checkbox formControlName="suspendServicesPostExpiry" class="flex-grow font-20 margin-l-50" color="primary">
          Block Orders Post Expiry
        </mat-checkbox>
      </div>
    </div>


    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Purchase Start Date:</label>
      <p-calendar formControlName="startDate" icon="pi pi-calendar" class="font-20 margin-l-10" name="startDate"
        [showIcon]=true dateFormat="d/m/yy" (ngModelChange)="changeInDate()" [minDate]="today" [defaultDate]="today"
        [maxDate]="maxStartDate" [selectOtherMonths]="true" showTime="true" hourFormat="24" [showButtonBar]="true"
        [stepMinute]="30" class="flex-grow font-20 margin-l-10">
      </p-calendar>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Purchase End Date:</label>
      <p-calendar formControlName="endDate" icon="pi pi-calendar" class="font-20 margin-l-10" name="endDate"
        [defaultDate]="tomorrowEod" [showIcon]=true dateFormat="d/m/yy" (ngModelChange)="changeInDate()"
        [selectOtherMonths]="true" showTime="true" hourFormat="24" [showButtonBar]="true" [minDate]="minEndDate"
        [stepMinute]="30" class="flex-grow font-20 margin-l-10">
      </p-calendar>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Subscription Priority:</label>
      <input formControlName="priority" class="flex-grow font-20 margin-l-10" name="priority" type="number" />
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">User Selector:</label>
      <ng-select formControlName="userSelector" name="userSelector" placeholder="Select User Selector"
        class="flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let item of userSelectors" [value]="item">{{item.name}}</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10">
      <label class="width-50 font-20 bold">Rule Type</label>
      <ng-select formControlName="ruleType" name="ruleType" placeholder="Select Rule Type" [multiple]=false
        class="flex-grow font-20 margin-l-10">
        <ng-option value="zeroCommission">Zero Commission</ng-option>
        <ng-option value="variableCommission" *ngIf="isModeEnabledForVariableComission()">Variable Commission</ng-option>
      </ng-select>
    </div>

    <div class="flex-row margin-y-10" *ngIf="formValue('ruleType') == 'variableCommission'">
      <label class="font-20 bold margin-r-10">Commission Discount Percent</label>
      <input formControlName="ruleValue" class="flex-grow font-20 margin-l-10" name="ruleValue" type="number"
        pTooltip="Enter commission discount percent" />
    </div>

    <div class="flex-row margin-y-10">
      <label class="font-20 bold">Exclude captains from these Incentives</label>
      <ng-select formControlName="excludeIncentiveTypes" name="excludeIncentiveTypes" placeholder="Select types"
        [multiple]=true class="flex-grow font-20 margin-l-10">
        <ng-option *ngFor="let incentiveType of incentiveTypes"
          [value]="incentiveType">{{incentiveTypesMap[incentiveType]}}</ng-option>
      </ng-select>
    </div>

    <div *ngFor="let rule of formValue('rules') || []; let i = index">

      <label class="width-50 font-20 bold">Rule {{i+1}}:</label>
      <button mat-stroked-button (click)="deleteRule(i)" [disabled]="formValue('rules').length === 1" color="warn"
        class="right-align">
        <mat-icon>delete</mat-icon>
      </button>

      <div class="margin-l-50">
        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Validity Type:</label>
          <ng-select [formControl]="rule.validityType" name="validityType" placeholder="Select validity type"
            class="flex-grow font-20 margin-l-10" (change)="validityTypeChanged(rule)">
            <ng-option *ngFor="let validityType of validityTypes" [value]="validityType">{{validityType}}</ng-option>
          </ng-select>
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Rule title (Optional):</label>
          <ng-select [formControl]="rule.subscriptionTitle" name="s" placeholder="Select rule title"
            class="flex-grow font-20 margin-l-10">
            <ng-option *ngFor="let subscriptionTitle of subscriptionTitles" [value]="subscriptionTitle">{{subscriptionTitle}}</ng-option>
          </ng-select>
        </div>

        <div class="flex-row margin-y-10">
          <div class="width-50 font-20 flex-grow">
            <label class="width-50 font-20 bold"></label>
            <mat-checkbox [formControl]="rule.isRecommended" name="isRecommended" class="flex-grow font-20 margin-l-10" color="primary"> Is Recommended</mat-checkbox>
          </div>
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Max Value:</label>
          <input [formControl]="rule.maxValue" class="flex-grow font-20 margin-l-10" name="maxValue" type="number" />
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Purchase Price:</label>
          <input [formControl]="rule.ruleAmount" class="flex-grow font-20 margin-l-10" name="ruleAmount" type="number" />
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Original Price:</label>
          <input [formControl]="rule.strikedOutAmount" class="flex-grow font-20 margin-l-10" name="strikedOutAmount"
            type="number" pTooltip="Optional: Enter amount more than Purchase Price" />
        </div>

        <div class="flex-row margin-y-10">
          <label class="width-50 font-20 bold">Period In Days:</label>
          <input [formControl]="rule.periodInDays" class="flex-grow font-20 margin-l-10" name="periodInDays"
            type="number" />
        </div>
      </div>

    </div>

    <div class="margin-y-10 flex-row pull-right">
      <button mat-stroked-button (click)="addRule()" [disabled]="formValue('rules') && formValue('rules').length === maxRulesAtOnce">
        <mat-icon>add</mat-icon>
        Add Rule
      </button>
    </div>

    <div class="margin-y-10 flex-row pull-right">
      <button mat-raised-button class="btn-primary" (click)="createSubscriptionRules()"> Create Rules </button>
    </div>
  </form>
</div>
<app-toaster [config]="toasterService.toaster"></app-toaster>

<ng-template #OverlappingPriorityRuleAlert>
  <h3 matDialogTitle>Subscription rule with same priority</h3>
  <table class="table table-striped table-bordered outerTable">
    <thead>
      <tr>
        <th>Rule Id</th>
        <th>Rule name</th>
        <th>Priority</th>
        <th>Amount</th>
        <th>Start Time</th>
        <th>End Time</th>
        <th>Period In Days</th>
        <th>Comission Type</th>
        <th>Validity Type</th>
        <th>Max Value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rule of overlappingRules">
        <td class="displayStyle">{{rule.id}}</td>
        <td class="displayStyle">{{rule.ruleName}}</td>
        <td class="displayStyle">{{rule.priority}}</td>
        <td class="displayStyle">{{rule.ruleAmount}}</td>
        <td class="displayStyle">{{Utils.dateFromEpoch(rule.startTime*1000)}}</td>
        <td class="displayStyle">{{Utils.dateFromEpoch(rule.endTime*1000)}}</td>
        <td class="displayStyle">{{rule.periodInDays}}</td>
        <td class="displayStyle">{{rule.ruleType}}</td>
        <td class="displayStyle">{{rule.validity && rule.validity.length > 0 ? rule.validity[0].type : "unlimited"}}</td>
        <td class="displayStyle">{{rule.validity && rule.validity.length > 0 ? rule.validity[0].maxValue : null}}</td>
      </tr>
    </tbody>
  </table>
  <span>Please create a Subscription Rule with other priority to proceed</span>
  <div mat-dialog-actions class="button-flex" style="float:right;">
    <button mat-button matDialogClose>ok</button>
  </div>
</ng-template>
