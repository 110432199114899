<form [formGroup]="iAllocatorConstructFormGroup" class="incentive-form">
    <div class="incentive-form-row">
        <div class="allocator-form-field">
            <label class="heading">City</label>
            <ng-select class="allocator-form-select" formControlName="selectedCity" name="city"
                placeholder="Select city" (change)="citySelected()">
                <ng-option *ngFor="let item of config" [value]="item">{{item.cityName}}</ng-option>
            </ng-select>
        </div>
        <div class="allocator-form-field">
            <label class="heading">Service</label>
            <ng-select class="allocator-form-select" formControlName="selectedService" name="service"
                placeholder="Select service">
                <ng-option *ngFor="let item of getServices()" [value]="item">{{item}}</ng-option>
            </ng-select>
        </div>
        <div class="allocator-form-field">
            <label class="heading">Incentive Type </label>
            <ng-select class="allocator-form-select" formControlName="selectedIncentive" name="type"
                placeholder="Select type">
                <ng-option *ngFor="let item of getIncentiveTypes()" [value]="item">{{item}}</ng-option>
            </ng-select>
        </div>
        <div class="allocator-form-field">
            <label class="heading">Start Date</label>
            <p-calendar dateFormat="d/m/yy" selectOtherMonths="true" [inputStyle]="{'width': '100px'}"
                formControlName="fromDate" icon="pi pi-calendar" [showIcon]=true (ngModelChange)="changeInDate()"
                [maxDate]="maxDate" [minDate]="minDate" [disabledDays]="[0,2,3,4,5,6]">
            </p-calendar>
        </div>
        <div class="allocator-form-field">
            <label class="heading">End Date</label>
            <p-calendar dateFormat="d/m/yy" selectOtherMonths="true" [inputStyle]="{'width': '100px'}" 
                formControlName="toDate" icon="pi pi-calendar" [showIcon]=true (ngModelChange)="changeInDate()"
                [minDate]="minDate" [disabledDays]="[1,2,3,4,5,6]">
            </p-calendar>
        </div>
    </div>
</form>
<p-fileUpload #constructUpload accept="text/csv" [maxFileSize]="1000000" [showCancelButton]="false"
    [showUploadButton]="false" (onSelect)="handleFile($event)" class="margin-y-10"
    [disabled]="!canConstructBeUploaded()" (onRemove)="resetFile()" chooseLabel="Upload Construct File">
    <ng-template pTemplate="toolbar">
        <button pButton (click)="downloadSampleFile()" class="ui-button-secondary"
            style="background-color: gray; color: white;" label="Download Sample File">
        </button>
    </ng-template>
    <ng-template pTemplate="content">
    <div>
        <button mat-raised-button class="btn-primary" (click)="submitConstructs()"
            [disabled]="!fileDetails">
            Submit
        </button>
    </div>
    </ng-template>
</p-fileUpload>

