import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ViewIncentivesService } from './view-incentives.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { LoaderService } from '../loader.service';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from '../shared/types/toaster.types';
import { AuthService } from '../auth.service';
var ViewIncentivesComponent = /** @class */ (function () {
    function ViewIncentivesComponent(viewIncentivesService, sharedService, loaderService, router, accessRights, toastService) {
        this.viewIncentivesService = viewIncentivesService;
        this.sharedService = sharedService;
        this.loaderService = loaderService;
        this.router = router;
        this.accessRights = accessRights;
        this.toastService = toastService;
        this.table = new Table({
            headers: {
                active: 'Status',
                cities: 'City',
                incentiveName: 'Name',
                serviceNames: 'Service',
                startDate: 'Start Date',
                endDate: 'End Date',
                type: "Type",
                priority: "Priority",
                action: "Actions"
            },
            pagination: new Pagination(0, 15, 150, [15, 20]),
            data: []
        });
        this.showFilters = true;
        this.requestBody = {
            filters: {},
            sort: {
                sortBy: "startDate",
                sortOrder: -1
            },
            incentiveName: ""
        };
        this.cities = [];
        this.services = {};
        this.citiesFilter = {
            type: 'searchable-dropdown',
            title: 'City',
            values: [],
            default: [],
            key: 'incentive-city'
        };
        this.serviceFilter = {
            type: 'searchable-dropdown',
            title: 'Services',
            values: [],
            default: [],
            key: 'incentive-services'
        };
        this.filterComponents = [
            this.citiesFilter,
            this.serviceFilter,
            {
                type: 'searchable-dropdown',
                title: 'Incentive Type',
                values: this.sharedService.INCENTIVE_TYPES,
                default: [],
                key: 'incentive-types'
            },
            {
                type: 'calendar',
                title: '',
                values: [],
                default: undefined,
                key: 'incentive-dates'
            },
            {
                type: 'radio-button',
                title: 'Status',
                values: ['Both', 'Active', 'Inactive'],
                default: 'Both',
                key: 'incentive-status'
            }
        ];
    }
    ViewIncentivesComponent.prototype.ngOnInit = function () {
        this.fetchAllCities();
    };
    ViewIncentivesComponent.prototype.sortByHeader = function (header) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sortOrder, column;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sortOrder = header.includes("-") ? -1 : 1;
                        column = header.replace("-", "");
                        if (!["startDate"].includes(column)) {
                            return [2 /*return*/];
                        }
                        this.requestBody.sort.sortBy = column;
                        this.requestBody.sort.sortOrder = sortOrder;
                        return [4 /*yield*/, this.fetchTableData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.fetchTableData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.withLoader(function () { return _this.fetchIncentives(); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.fetchIncentives = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload, permissions, incentives;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.validateFilters();
                        payload = {
                            filters: this.requestBody.filters,
                            pagination: {
                                pageNo: this.table.pagination.pageNo + 1,
                                pageSize: this.table.pagination.perPage,
                            },
                            queryParams: {
                                sortData: this.requestBody.sort.sortBy,
                                sortOrder: this.requestBody.sort.sortOrder,
                            }
                        };
                        return [4 /*yield*/, this.accessRights.getPermissions()];
                    case 1:
                        permissions = _a.sent();
                        return [4 /*yield*/, this.viewIncentivesService.fetchIncentiveData(payload).toPromise()];
                    case 2:
                        incentives = _a.sent();
                        this.table.data = incentives
                            .map(function (incentive) {
                            var cities = incentive.cities || [];
                            var serviceType = incentive.serviceType || [];
                            var id = incentive._id.$oid || incentive._id;
                            var serviceNames = incentive.serviceNames || [];
                            return {
                                _id: id,
                                active: incentive.active ? 'Active' : 'Inactive',
                                cities: cities.join(', '),
                                incentiveName: incentive.incentiveName,
                                serviceType: serviceType.join(', '),
                                serviceNames: serviceNames.join(', '),
                                startDate: incentive.startDate,
                                endDate: incentive.endDate,
                                type: incentive.incentiveType,
                                priority: incentive.priority || 0,
                                action: _this.createAction(permissions)
                            };
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.validateFilters = function () {
        this.validate("cities", "Select city and serviceTypes to fetch incentives");
        this.validate("serviceType", "Select serviceTypes to fetch incentives");
    };
    ViewIncentivesComponent.prototype.validate = function (key, message) {
        if (!this.requestBody.filters[key]) {
            this.showFilters = true;
            this.toastService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: message
            }));
            throw new Error(message);
        }
    };
    ViewIncentivesComponent.prototype.withLoader = function (cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.loaderService.openLoading();
                        return [4 /*yield*/, cb()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.fetchAllCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var citiesRes;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.withLoader(function () { return _this.sharedService.fetchCities().toPromise(); })];
                    case 1:
                        citiesRes = _a.sent();
                        this.cities = citiesRes.data.cities;
                        this.citiesFilter.values = this.cities
                            .map(function (city) { return city.displayName; });
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.fetchServices = function (city) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _loop_1, this_1, _i, city_1, cty;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.services = {};
                        _loop_1 = function (cty) {
                            var cityId, res, _i, _a, serviceDetail, name_1;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        cityId = this_1.cities.find(function (c) { return c.displayName == cty; })._id;
                                        return [4 /*yield*/, this_1.sharedService.fetchServices(cityId).toPromise()];
                                    case 1:
                                        res = _b.sent();
                                        for (_i = 0, _a = res.data; _i < _a.length; _i++) {
                                            serviceDetail = _a[_i];
                                            name_1 = serviceDetail.service.name;
                                            this_1.services[name_1] = this_1.services[name_1] || [];
                                            this_1.services[name_1].push(serviceDetail._id);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, city_1 = city;
                        _a.label = 1;
                    case 1:
                        if (!(_i < city_1.length)) return [3 /*break*/, 4];
                        cty = city_1[_i];
                        return [5 /*yield**/, _loop_1(cty)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.serviceFilter.values = Object.keys(this.services);
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewIncentivesComponent.prototype.createAction = function (accessData) {
        var _this = this;
        var actionArr = [];
        if (accessData.includes('view_incentive')) {
            actionArr.push(new TableAction({
                onClick: function (data) { return _this.openPage("/viewIncentive/" + data._id + "/view"); },
                text: 'View'
            }));
        }
        if (accessData.includes('edit_incentive')) {
            actionArr.push(new TableAction({
                onClick: function (data) { return _this.openPage("/incentive/" + data._id + "/edit"); },
                text: 'Edit'
            }));
            actionArr.push(new TableAction({
                onClick: function (data) { return _this.openPage("/incentive/" + data._id + "/duplicate"); },
                text: 'Duplicate'
            }));
        }
        actionArr.push(new TableAction({
            onClick: function (data) { return _this.incentiveIdForPerformace = data._id; },
            text: 'Assignment Details'
        }));
        return new TableData({
            actions: new TableActions(actionArr),
            type: TableDataTypes.ACTION
        });
    };
    ViewIncentivesComponent.prototype.openPage = function (url) {
        window.open(this.router.serializeUrl(this.router.createUrlTree([url])));
    };
    ViewIncentivesComponent.prototype.filterChange = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (filters['incentive-city'] && filters['incentive-city'].length) {
                    this.withLoader(function () { return _this.fetchServices(filters['incentive-city']); });
                }
                return [2 /*return*/];
            });
        });
    };
    ViewIncentivesComponent.prototype.filterToggle = function () {
        this.showFilters = !this.showFilters;
    };
    ViewIncentivesComponent.prototype.nameSearchChange = function () {
        this.requestBody.filters.incentiveName = this.requestBody.incentiveName;
        this.fetchTableData();
    };
    ViewIncentivesComponent.prototype.applyFilters = function (filters) {
        this.table.pagination.setPageNoToZero();
        var filtersRequest = { incentiveName: this.requestBody.incentiveName };
        if (filters['incentive-status'] === 'Active') {
            filtersRequest.active = true;
        }
        if (filters['incentive-status'] === 'Inactive') {
            filtersRequest.active = false;
        }
        var _a = filters['incentive-dates'] || [], _b = _a[0], startDate = _b === void 0 ? undefined : _b, _c = _a[1], endDate = _c === void 0 ? undefined : _c;
        filtersRequest.startDate = startDate ? startDate : undefined;
        filtersRequest.endDate = endDate ? endDate : undefined;
        var allServiceIds = [];
        for (var _i = 0, _d = (filters['incentive-services'] || []); _i < _d.length; _i++) {
            var service = _d[_i];
            allServiceIds = allServiceIds.concat(this.services[service]);
        }
        if (allServiceIds.length > 0) {
            filtersRequest['serviceType'] = allServiceIds;
        }
        if ((filters['incentive-city'] || []).length > 0) {
            filtersRequest['cities'] = filters['incentive-city'];
        }
        if ((filters['incentive-types'] || []).length > 0) {
            filtersRequest['types'] = filters['incentive-types'];
        }
        this.requestBody.filters = filtersRequest;
        this.fetchTableData();
    };
    return ViewIncentivesComponent;
}());
export { ViewIncentivesComponent };
