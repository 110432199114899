import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
var ViewCampaignsService = /** @class */ (function () {
    function ViewCampaignsService(http) {
        this.http = http;
        this.fetchCampaignsUrl = environment.server + '/api/get/campaigns/v1';
        this.fetchEventsUrl = environment.server + '/api/get/events/v1';
        this.deactivateRuleUrl = environment.server + '/api/deactivateRule';
        this.rulePerformanceUrl = environment.server + '/api/performance';
    }
    ViewCampaignsService.prototype.fetchCampaignsData = function (payload) {
        return this.http.post(this.fetchCampaignsUrl, payload).pipe(catchError(this.handleError));
    };
    ViewCampaignsService.prototype.fetchEventsData = function () {
        return this.http.get(this.fetchEventsUrl).pipe(catchError(this.handleError));
    };
    ViewCampaignsService.prototype.deactivateRule = function (ruleId) {
        var payload = { rid: ruleId };
        return this.http.put(this.deactivateRuleUrl, payload).pipe(catchError(this.handleError));
    };
    ViewCampaignsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
        // return {};
    };
    ViewCampaignsService.ngInjectableDef = i0.defineInjectable({ factory: function ViewCampaignsService_Factory() { return new ViewCampaignsService(i0.inject(i1.MyHttpClient)); }, token: ViewCampaignsService, providedIn: "root" });
    return ViewCampaignsService;
}());
export { ViewCampaignsService };
