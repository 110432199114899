import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../shared/shared.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import * as moment from 'moment';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { environment } from 'src/environments/environment';
import { ViewIncentivesService } from 'src/app/view-incentives/view-incentives.service';
import { MatDialog } from '@angular/material/dialog';
var BasicInfoComponent = /** @class */ (function () {
    function BasicInfoComponent(datePipe, sharedService, toasterService, loaderService, router, incentiveService, dialog) {
        this.datePipe = datePipe;
        this.sharedService = sharedService;
        this.toasterService = toasterService;
        this.loaderService = loaderService;
        this.router = router;
        this.incentiveService = incentiveService;
        this.dialog = dialog;
        this.header = 'Basic Info';
        this.incentiveType = this.sharedService.INCENTIVE_TYPES;
        this.serviceType = [];
        this.rules = [];
        this.ruleSelector = [];
        this.nextClicked = false;
        this.ruleObj = { city: false, service: false, shift: false };
        this.isCancellationLimitEnabledForCity = false;
        this.isMaxOrderDistanceEnabledForCity = false;
        this.adhocIncentiveNames = environment.adhocIncentiveNames;
        this.blockServicesForIncentiveCreation = new Set(environment.blockServicesForIncentiveCreation);
        this.adHocIncentive = false;
        this.selectedRuleUserCount = null;
        this.currentDate = new Date();
        this.errorPopupIncentives = [];
        this.segmentType = this.sharedService.SEGMENT_TYPES;
        this.selectedCity = "";
        this.selectedService = "";
        this.NOT_EDITABLE = {
            incentiveName: false,
            incentiveDisplayName: false,
            selectedIncentive: false,
            selectedCity: false,
            selectedService: false,
            isHHIncentive: false,
            selectedShift: false,
            selectedRule: false,
            fromDate: false,
            toDate: false,
            priority: false,
            identifier: false,
            selectedSegment: false,
        };
        this.basicInfo = new EventEmitter();
        this.basicInfoDataChange = new EventEmitter();
        this.incentiveDisplayNameFormControl = new FormControl(null, Validators.required);
        this.basicInfoFormGroup = new FormGroup({
            incentiveName: new FormControl('', Validators.required),
            incentiveDisplayName: this.incentiveDisplayNameFormControl,
            selectedIncentive: new FormControl(null, Validators.required),
            selectedCity: new FormControl(null, Validators.required),
            selectedService: new FormControl('', Validators.required),
            isHHIncentive: new FormControl(false, Validators.required),
            selectedShift: new FormControl([], Validators.required),
            selectedRule: new FormControl({ value: null, disabled: false }, Validators.required),
            fromDate: new FormControl('', Validators.required),
            toDate: new FormControl('', Validators.required),
            priority: new FormControl('', Validators.required),
            identifier: new FormControl('', Validators.required),
            selectedSegment: new FormControl(null, Validators.required),
        });
    }
    BasicInfoComponent.prototype.isDisabled = function (disable) {
        return disable ? '' : null;
    };
    BasicInfoComponent.prototype.makeFieldsDisable = function () {
        var _this = this;
        // this.basicInfoFormGroup.get('incentiveName').disable()
        Object.keys(this.NOT_EDITABLE).forEach(function (fieldName) {
            var disable = _this.NOT_EDITABLE[fieldName];
            if (disable) {
                try {
                    _this.basicInfoFormGroup.get(fieldName).disable();
                }
                catch (err) { }
            }
        });
    };
    BasicInfoComponent.prototype.getMinEditableDate = function () {
        if (this.currentDate < this.minDate) {
            return this.minDate;
        }
        return this.currentDate;
    };
    BasicInfoComponent.prototype.patchDefaultValue = function (defaultValue) {
        var _this = this;
        this.loaderService.openLoading();
        var fieldValues = this.fetchSegmentNameAndIdentifierName(defaultValue.incentiveName);
        this.basicInfoFormGroup.patchValue({
            incentiveName: fieldValues.incentiveName,
            selectedIncentive: defaultValue.incentiveType,
            fromDate: new Date(defaultValue.startDate),
            toDate: new Date(defaultValue.endDate),
            priority: defaultValue.priority,
            selectedSegment: fieldValues.segmentName,
            identifier: fieldValues.identifierName
        });
        this.incentiveDisplayNameFormControl.patchValue(defaultValue.incentiveDisplayName || null);
        if (defaultValue.selectedVariable.indexOf("cancellation") != -1) {
            this.isCancellationLimitEnabledForCity = true;
        }
        if (defaultValue.selectedVariable.indexOf("maxOrderDistance") != -1) {
            this.isMaxOrderDistanceEnabledForCity = true;
        }
        if (defaultValue.tags) {
            this.basicInfoFormGroup.patchValue({
                isHHIncentive: defaultValue.tags.includes("HH"),
            });
        }
        this.changeInDate();
        this.cityChangeCheck(defaultValue.cities[0])
            .then(function () { return _this.serviceChangeCheck(defaultValue.serviceType); })
            .then(function () { return _this.getRules(); })
            .then(function () { return _this.ruleChangeCheck(defaultValue.ruleId); })
            .then(function () {
            var data = _this.formatData();
            _this.basicInfoDataChange.emit(data);
            _this.loaderService.closeLoading();
            // this.passBasicInfo();
        })
            .catch(function (err) {
            _this.loaderService.closeLoading();
        });
    };
    BasicInfoComponent.prototype.fetchSegmentNameAndIdentifierName = function (finalName) {
        var splitArray = finalName.split("_");
        var result = {
            segmentName: "",
            identifierName: "",
            incentiveName: ""
        };
        try {
            var segmentAndIncentiveName = splitArray[3];
            var segment = segmentAndIncentiveName.split(" ");
            if (splitArray[0] == "ALLOCATOR") { // Naming convention of iAllocator incentives -> ALLOCATOR_${ cityName }_${ segment.label }_${ shortStartDate }_${ shortEndDate }_${ incentiveType }
                var segment_1 = splitArray.slice(2, -3).join(' ');
                result.segmentName = segment_1;
                result.identifierName = "iAllocator";
                result.incentiveName = segment_1;
            }
            else if (splitArray[0] == "MANUAL" && segment && segment[0] == "CUSTOM") {
                result.segmentName = "CUSTOM";
                result.identifierName = splitArray[7];
                result.incentiveName = segmentAndIncentiveName.substr(7);
            }
            else if (splitArray[0] == "MANUAL") {
                result.segmentName = segmentAndIncentiveName;
                result.identifierName = splitArray[7];
                result.incentiveName = segmentAndIncentiveName;
            }
            else {
                result.segmentName = "CUSTOM";
                result.incentiveName = finalName;
                result.identifierName = "identifier";
            }
        }
        catch (err) {
            result.segmentName = "CUSTOM";
            result.incentiveName = finalName;
            result.identifierName = "identifier";
        }
        return result;
    };
    BasicInfoComponent.prototype.cityChangeCheck = function (selectedCity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var city;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.cities) return [3 /*break*/, 2];
                        city = this.cities.find(function (city) { return city.displayName === selectedCity; });
                        this.basicInfoFormGroup.patchValue({
                            selectedCity: city,
                        });
                        this.checkCityEnabledForCancellationLimit(city);
                        this.checkCityEnabledForMaxOrderDistance(city);
                        return [4 /*yield*/, this.onCityChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.ruleChangeCheck = function (selectedRuleId) {
        if (this.getValues("isHHIncentive")) {
            this.basicInfoFormGroup.get("selectedRule").disable();
            this.basicInfoFormGroup.patchValue({
                selectedRule: null,
            });
            return;
        }
        if (this.rules && this.rules.length) {
            var rule = this.ruleSelector.find(function (sel) { return sel._id === selectedRuleId; });
            if (rule && rule.name) {
                this.basicInfoFormGroup.patchValue({
                    selectedRule: rule.name,
                });
            }
        }
    };
    BasicInfoComponent.prototype.serviceChangeCheck = function (selectedServiceIds) {
        var _this = this;
        if (this.services.length) {
            var selectedServicesObject_1 = [];
            selectedServiceIds.forEach(function (selectedServiceId) {
                var service = _this.services.find(function (service) { return service._id === selectedServiceId; });
                if (service) {
                    selectedServicesObject_1.push(service);
                }
            });
            this.basicInfoFormGroup.patchValue({
                selectedService: selectedServicesObject_1,
            });
        }
    };
    BasicInfoComponent.prototype.shiftChangeCheck = function (selectedShifts) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var interval = setInterval(function () {
                if (_this.shifts.length) {
                    _this.basicInfoFormGroup.patchValue({
                        selectedShift: selectedShifts,
                    });
                    // const city = this.shifts.find(city => city.displayName === selectedCity);
                    // this.basicInfoFormGroup.patchValue({
                    //   selectedCity: city,
                    // });
                    // this.onCityChange();
                    clearInterval(interval);
                    return resolve({});
                }
            }, 100);
        });
    };
    BasicInfoComponent.prototype.compareCityFn = function (cityOne, cityTwo) {
        return cityOne.displayName === cityTwo.displayName;
    };
    BasicInfoComponent.prototype.compareServiceFn = function (serviceOne, serviceTwo) {
        return serviceOne._id === serviceTwo._id;
    };
    BasicInfoComponent.prototype.compareShiftFn = function (shiftOne, shiftTwo) {
        return shiftOne === shiftTwo;
    };
    BasicInfoComponent.prototype.compareRuleFn = function (shiftOne, shiftTwo) {
        return shiftOne === shiftTwo;
    };
    BasicInfoComponent.prototype.onCityChange = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCityServices(this.basicInfoFormGroup.get('selectedCity'))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.makeFieldsDisable();
                        return [4 /*yield*/, this.getCities()];
                    case 1:
                        _a.sent();
                        switch (this.state) {
                            case this.sharedService.STATE.EDIT: {
                                this.patchDefaultValue(this.defaultValue);
                                this.incentiveTypeSelected();
                                break;
                            }
                            case this.sharedService.STATE.DUPLICATE: {
                                this.patchDefaultValue(this.defaultValue);
                                this.incentiveTypeSelected();
                                break;
                            }
                        }
                        this.basicInfoFormGroup.get('selectedCity').valueChanges.subscribe(function (data) {
                            _this.checkCityEnabledForCancellationLimit(data);
                            _this.checkCityEnabledForMaxOrderDistance(data);
                            _this.basicInfoFormGroup.get('selectedRule').setValue(null);
                        });
                        this.basicInfoFormGroup.get('selectedService').valueChanges.subscribe(function (data) {
                            _this.basicInfoFormGroup.get('selectedRule').setValue(null);
                        });
                        this.basicInfoFormGroup.get('selectedShift').valueChanges.subscribe(function (data) {
                            _this.basicInfoFormGroup.get('selectedRule').setValue(null);
                        });
                        this.basicInfoFormGroup.get('selectedRule').valueChanges.subscribe(function (data) {
                            _this.ruleSelected();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.changeInDate = function () {
        if (this.getValues('fromDate')) {
            this.minDate = this.getValues('fromDate');
        }
        if (this.getValues('toDate')) {
            this.maxDate = this.getValues('toDate');
        }
    };
    BasicInfoComponent.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.fetchCities().toPromise().then(function (response) {
                            _this.cities = response['data'].cities;
                            _this.ruleObj['service'] = false;
                            _this.ruleObj['shift'] = false;
                            _this.ruleObj['city'] = true;
                            for (var index in _this.cities) {
                                if (_this.cities[index].displayName === 'All') {
                                    _this.cities.splice(index, 1);
                                }
                            }
                        }, function (error) {
                            console.log('Error : ', error);
                            _this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Error in fetching cities",
                            }));
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.getCityServices = function (cityControl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var city, cityId, response, services, _i, _a, service, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        city = cityControl.value;
                        if (!city) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this.services = [];
                        this.basicInfoFormGroup.patchValue({ selectedService: '' });
                        cityId = city._id;
                        return [4 /*yield*/, this.sharedService.fetchServices(cityId).toPromise()];
                    case 2:
                        response = _b.sent();
                        services = response.data || [];
                        if (this.state == this.sharedService.STATE.EDIT) {
                            this.services = services.filter(function (dat) {
                                if (dat.service) {
                                    return dat.service = dat.service || [];
                                }
                            });
                        }
                        else {
                            this.services = services.filter(function (dat) {
                                if (dat.service && !_this.blockServicesForIncentiveCreation.has(dat.service._id)) {
                                    return dat.service = dat.service || [];
                                }
                            });
                        }
                        this.serviceNames = [];
                        for (_i = 0, _a = this.services; _i < _a.length; _i++) {
                            service = _a[_i];
                            this.serviceNames[service._id] = service.service.name;
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _b.sent();
                        console.log(e_1);
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Error in fetching city services",
                        }));
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.getShifts = function (cityControl) {
        var _this = this;
        var city = cityControl.value;
        if (city) {
            city = [city];
            this.shifts = [];
            this.basicInfoFormGroup.patchValue({ selectedShift: [] });
            this.tempShifts = [];
            var count_1 = 0;
            var cityName = '';
            for (var i in city) {
                if (i) {
                    for (var index in this.cities) {
                        if (this.cities[index].displayName === city[i].displayName) {
                            cityName = city[i].displayName;
                            this.loaderService.openLoading();
                            this.sharedService.fetchShifts(cityName).subscribe(function (response) {
                                _this.loaderService.closeLoading();
                                count_1++;
                                _this.tempShifts.push(response['data']);
                                if (count_1 === city.length && city.length > 1) {
                                    _this.getIntersectionOfShifts(city.length);
                                }
                                else {
                                    _this.shifts = response['data'];
                                }
                            }, function (error) {
                                console.log('Error : ', error);
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Error in fetching shifts",
                                }));
                            });
                        }
                    }
                }
            }
        }
    };
    BasicInfoComponent.prototype.getIntersectionOfShifts = function (selectedCityCount) {
        var _this = this;
        this.shifts = [];
        var shiftMap = new Map();
        this.tempShifts.forEach(function (shifts) {
            shifts.forEach(function (shift) {
                if (shiftMap.has(shift)) {
                    shiftMap.set(shift, shiftMap.get(shift) + 1);
                }
                else {
                    shiftMap.set(shift, 1);
                }
            });
        });
        shiftMap.forEach(function (value, key) {
            if (value === selectedCityCount) {
                _this.shifts.push(key);
            }
        });
    };
    BasicInfoComponent.prototype.getIntersectionOfServices = function () {
        var _this = this;
        this.services = [];
        var serviceMap = {};
        this.tempServices.forEach(function (cities) {
            cities.forEach(function (cityService) {
                if (serviceMap[cityService.service._id]) {
                    serviceMap[cityService.service._id].push(cityService);
                }
                else {
                    serviceMap[cityService.service._id] = [cityService];
                }
                if (serviceMap[cityService.service._id].length === _this.tempServices.length) {
                    _this.services.push(serviceMap[cityService.service._id][0]);
                }
            });
        });
        for (var svc in this.services) {
            this.serviceNames[this.services[svc].service._id] = this.services[svc].service.name;
        }
    };
    BasicInfoComponent.prototype.checkPriority = function () {
        if (this.getValues('priority') && this.getValues('priority') < 0) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Priority should be greater than 0",
            }));
        }
    };
    BasicInfoComponent.prototype.checkRuleObj = function () {
        if (this.ruleObj['city'] && this.ruleObj['service'] && !this.getValues('isHHIncentive')) {
            this.getRules();
        }
    };
    BasicInfoComponent.prototype.serviceSelected = function () {
        if (this.getValues('selectedService').length > 0) {
            this.ruleObj['service'] = true;
        }
        else {
            this.rules = [];
            this.ruleObj['service'] = false;
        }
    };
    BasicInfoComponent.prototype.enableAdhocIncentiveDisplayName = function () {
        this.adHocIncentive = this.isAdhocIncentive();
        if (this.adHocIncentive) {
            this.basicInfoFormGroup.addControl("incentiveDisplayName", this.incentiveDisplayNameFormControl);
        }
        else {
            this.basicInfoFormGroup.removeControl('incentiveDisplayName');
        }
    };
    BasicInfoComponent.prototype.incentiveTypeSelected = function () {
        var today = moment().utcOffset("+0530").toDate();
        this.basicInfoFormGroup.get('isHHIncentive').enable();
        if (!this.isDailyIncentive()) {
            this.basicInfoFormGroup.get('isHHIncentive').setValue(false);
            this.basicInfoFormGroup.get('isHHIncentive').disable();
        }
        this.enableAdhocIncentiveDisplayName();
        if (this.isAdhocIncentive()) {
            this.basicInfoFormGroup.get('fromDate').setValue(today);
            this.basicInfoFormGroup.get('toDate').setValue(today);
            this.basicInfoFormGroup.get('fromDate').disable();
            this.basicInfoFormGroup.get('toDate').disable();
            this.basicInfoFormGroup.get('priority').setValue(0);
            return;
        }
        if (!this.NOT_EDITABLE.fromDate)
            this.basicInfoFormGroup.get('fromDate').enable();
        if (!this.NOT_EDITABLE.toDate)
            this.basicInfoFormGroup.get('toDate').enable();
        if (this.NOT_EDITABLE.isHHIncentive)
            this.basicInfoFormGroup.get('isHHIncentive').disable();
    };
    BasicInfoComponent.prototype.isDailyIncentive = function () {
        var selectedType = this.getValues('selectedIncentive') || [];
        return selectedType.includes('Daily');
    };
    BasicInfoComponent.prototype.isAdhocIncentive = function () {
        var selectedType = this.getValues('selectedIncentive') || [];
        return selectedType.includes('Adhoc');
    };
    BasicInfoComponent.prototype.shiftSelected = function () {
        if (this.getValues('selectedShift').length > 0) {
            this.ruleObj['shift'] = true;
        }
        else {
            this.rules = [];
            this.ruleObj['shift'] = false;
        }
    };
    BasicInfoComponent.prototype.getRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shift, service, city, userType, serviceId, cityId, selectedCity, selectedService, selectedShift, cityIndex, serviceIndex, response, result, index, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loaderService.openLoading();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        shift = void 0, service = void 0, city = void 0, userType = 'rider';
                        serviceId = [], cityId = [];
                        selectedCity = this.getValues('selectedCity');
                        selectedCity = [selectedCity];
                        selectedService = this.getValues('selectedService');
                        selectedShift = this.getValues('selectedShift');
                        for (cityIndex in selectedCity) {
                            if (selectedCity[cityIndex]) {
                                cityId.push(selectedCity[cityIndex]._id);
                            }
                        }
                        for (serviceIndex in selectedService) {
                            if (selectedService[serviceIndex]) {
                                serviceId.push(selectedService[serviceIndex]._id);
                            }
                        }
                        shift = selectedShift.join(',');
                        city = cityId.join(',');
                        service = serviceId.join(',');
                        return [4 /*yield*/, this.sharedService.fetchRules(city, service, shift, userType).toPromise()];
                    case 2:
                        response = _b.sent();
                        this.rules = [];
                        result = response['data'].selectors;
                        this.ruleSelector = response['data'].selectors;
                        for (index in result) {
                            if (this.rules.indexOf(result[index].name) === -1) {
                                this.rules.push(result[index].name);
                            }
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        _a = _b.sent();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Error in fetching selectors",
                        }));
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BasicInfoComponent.prototype.getValues = function (name) {
        return this.basicInfoFormGroup.get(name).value;
    };
    BasicInfoComponent.prototype.errorMessage = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message,
        }));
    };
    BasicInfoComponent.prototype.getServiceName = function (service) {
        this.serviceType = [];
        for (var index in service) {
            if (index) {
                var serviceId = service[index]._id;
                if (!this.serviceType.includes(serviceId)) {
                    this.serviceType.push(serviceId);
                }
            }
        }
    };
    BasicInfoComponent.prototype.goBack = function () {
        this.router.navigate(['/incentives']);
    };
    BasicInfoComponent.prototype.formatData = function () {
        var _this = this;
        var fromDate = this.getValues('fromDate');
        var toDate = this.getValues('toDate');
        var startDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
        var endDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
        var formattedCurrentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
        if (!this.getValues('selectedIncentive')) {
            this.errorMessage("Select the type of incentive.");
            return;
        }
        if (!this.getValues('selectedSegment')) {
            this.errorMessage("Select the type of Segment.");
            return;
        }
        if (!this.getValues('incentiveName')) {
            this.errorMessage("Enter the incentive name.");
            return;
        }
        if (!this.getValues('identifier')) {
            this.errorMessage("Enter the identifier.");
            return;
        }
        if (this.state != this.sharedService.STATE.EDIT) {
            if (this.basicInfoFormGroup.get('incentiveName').invalid) {
                this.errorMessage("Only alphanumeric characters allowed for Incentive name.");
                return;
            }
            if (this.basicInfoFormGroup.get('identifier').invalid) {
                this.errorMessage("Only alphanumeric characters allowed for Identifier.");
                return;
            }
        }
        if (this.adHocIncentive && !this.getValues('incentiveDisplayName')) {
            this.errorMessage("Provide a display name for the incentive.");
            return;
        }
        if (!this.getValues('selectedCity')) {
            this.errorMessage("You need to select a city.");
            return;
        }
        if (!this.getValues('selectedService')) {
            this.errorMessage("You need to select at least one service.");
            return;
        }
        if (!this.getValues('isHHIncentive') && !this.getValues('selectedRule')) {
            this.errorMessage("Select a 'User Selector'.");
            return;
        }
        if (!this.minDate) {
            this.errorMessage("Provide the start date of the incentive.");
            return;
        }
        if (!this.maxDate) {
            this.errorMessage("Provide the end date of the incentive");
            return;
        }
        if (this.state != this.sharedService.STATE.EDIT && (endDate < startDate || startDate < formattedCurrentDate)) {
            this.errorMessage("You cannot create an incentive in the past.");
            return;
        }
        if (!this.getValues('priority') && this.getValues('selectedIncentive') !== 'Adhoc') {
            this.errorMessage("You need to provide a priority for the incentive.");
            return;
        }
        this.getServiceName(this.getValues('selectedService'));
        var services = [];
        var cities = [];
        var cityValue = this.getValues('selectedCity');
        if (!(cityValue instanceof Array)) {
            cityValue = [cityValue];
        }
        var serviceValue = this.getValues('selectedService');
        for (var index in serviceValue) {
            if (serviceValue[index]) {
                services.push(serviceValue[index].service._id);
            }
        }
        for (var index in cityValue) {
            if (cityValue[index]) {
                cities.push(cityValue[index].displayName);
            }
        }
        var ruleSelectorId = this.getSelectedRuleSelectorId();
        if (this.getValues('selectedIncentive').includes('Weekly') && fromDate.getDay() !== 1) {
            this.basicInfoFormGroup.get('fromDate').setValue('');
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Start date should be monday",
            }));
            return;
        }
        if (this.getValues('selectedIncentive').includes('Weekly') && toDate.getDay() !== 0) {
            this.basicInfoFormGroup.get('toDate').setValue('');
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "End date should be sunday",
            }));
            return;
        }
        var data = {
            incentiveName: this.state == this.sharedService.STATE.EDIT ? this.defaultValue.incentiveName : this.buildFinalIncentiveName(),
            incentiveDisplayName: this.adHocIncentive ? this.getValues('incentiveDisplayName') : null,
            incentiveType: this.getValues('selectedIncentive'),
            city: cities,
            isCancellationLimitEnabledForCity: this.isCancellationLimitEnabledForCity,
            isMaxOrderDistanceEnabledForCity: this.isMaxOrderDistanceEnabledForCity,
            service: this.serviceType,
            shift: this.getValues('selectedShift'),
            ruleName: this.getValues('selectedRule'),
            ruleId: ruleSelectorId,
            startDate: startDate,
            endDate: endDate,
            priority: this.getValues('priority') || 0,
            serviceNames: this.serviceType.map(function (service) { return _this.serviceNames[service]; }),
            submit: false,
            userSelectorCount: this.selectedRuleUserCount && this.selectedRuleUserCount.count,
            isHHIncentive: this.getValues("isHHIncentive"),
        };
        return data;
    };
    BasicInfoComponent.prototype.passBasicInfo = function () {
        var _this = this;
        this.nextClicked = true;
        var higherPriorityHHIncentives;
        var data = this.formatData();
        if (!data) {
            return;
        }
        if (this.getValues("isHHIncentive") && this.state != this.sharedService.STATE.EDIT) {
            this.incentiveService.fetchIncentiveData({
                "filters": {
                    "cities": data.city,
                    "serviceType": data.service,
                    "types": [
                        "Daily"
                    ],
                    "startDate": data.startDate,
                    "endDate": data.startDate,
                    "active": true,
                    "isHHIncentive": true,
                }
            }).subscribe(function (res) {
                higherPriorityHHIncentives = res.filter(function (incentive) { return _this.getValues('priority') <= incentive.priority; });
                if (higherPriorityHHIncentives.length > 0) {
                    _this.errorPopupIncentives = higherPriorityHHIncentives;
                    _this.dialog.open(_this.HHIncentiveAlertDialog);
                }
                else if (data) {
                    _this.basicInfo.emit(data);
                }
            });
        }
        else if (data) {
            this.basicInfo.emit(data);
        }
    };
    BasicInfoComponent.prototype.checkCityEnabledForCancellationLimit = function (data) {
        if (environment.cancellationLimitEnabledCities.includes('All')) {
            this.isCancellationLimitEnabledForCity = true;
        }
        else if (data && environment.cancellationLimitEnabledCities.includes(data._id)) {
            this.isCancellationLimitEnabledForCity = true;
        }
        else {
            this.isCancellationLimitEnabledForCity = false;
        }
    };
    BasicInfoComponent.prototype.checkCityEnabledForMaxOrderDistance = function (data) {
        if (environment.maxOrderDistanceEnabledCities.includes('All')) {
            this.isMaxOrderDistanceEnabledForCity = true;
        }
        else if (data && environment.maxOrderDistanceEnabledCities.includes(data._id)) {
            this.isMaxOrderDistanceEnabledForCity = true;
        }
        else {
            this.isMaxOrderDistanceEnabledForCity = false;
        }
    };
    BasicInfoComponent.prototype.fetchRequiredDetails = function () {
        this.getCityServices(this.basicInfoFormGroup.get('selectedCity'));
        this.getShifts(this.basicInfoFormGroup.get('selectedCity'));
        this.checkCityEnabledForCancellationLimit(this.basicInfoFormGroup.get('selectedCity').value);
        this.checkCityEnabledForMaxOrderDistance(this.basicInfoFormGroup.get('selectedCity').value);
    };
    BasicInfoComponent.prototype.getSelectedRuleSelectorId = function () {
        for (var index in this.ruleSelector) {
            if (this.ruleSelector[index].name === this.getValues('selectedRule')) {
                return this.ruleSelector[index]._id;
            }
        }
        return "";
    };
    BasicInfoComponent.prototype.ruleSelected = function () {
        var _this = this;
        this.selectedRuleUserCount = { loaded: false };
        var selectedRule = this.getSelectedRuleSelectorId();
        if (!selectedRule) {
            this.selectedRuleUserCount = null;
            return;
        }
        this.sharedService.getUserSelectorDetails(selectedRule)
            .subscribe(function (response) {
            _this.selectedRuleUserCount = { loaded: true, count: response.userCount };
        });
    };
    BasicInfoComponent.prototype.HHIncentiveChecked = function () {
        if (this.getValues("isHHIncentive")) {
            this.basicInfoFormGroup.get("selectedRule").disable();
            this.basicInfoFormGroup.patchValue({
                selectedRule: null,
            });
            return;
        }
        this.basicInfoFormGroup.get("selectedRule").enable();
    };
    BasicInfoComponent.prototype.segmentSelected = function () {
        if (this.getValues("selectedSegment") != "CUSTOM") {
            this.basicInfoFormGroup.patchValue({
                incentiveName: this.getValues("selectedSegment")
            });
            this.basicInfoFormGroup.get('incentiveName').disable();
        }
        else {
            this.basicInfoFormGroup.get('incentiveName').enable();
        }
    };
    BasicInfoComponent.prototype.buildCityShortName = function () {
        if (this.basicInfoFormGroup.get('selectedCity').value != null) {
            return this.basicInfoFormGroup.get('selectedCity').value.shortName.toUpperCase();
        }
    };
    BasicInfoComponent.prototype.buildServiceName = function () {
        var services = this.basicInfoFormGroup.get('selectedService').value;
        if (services.length > 0) {
            var serviceNames = services[0].service.name.toUpperCase();
            for (var index = 1; index < services.length; index++) {
                serviceNames += " " + services[index].service.name.toUpperCase();
            }
            return serviceNames;
        }
    };
    BasicInfoComponent.prototype.buildIncentiveAndSegmentName = function () {
        if (!this.basicInfoFormGroup.get('selectedSegment').value) {
            return;
        }
        else if (this.basicInfoFormGroup.get('selectedSegment').value && this.basicInfoFormGroup.get('incentiveName').value && this.basicInfoFormGroup.get('selectedSegment').value == "CUSTOM") {
            return this.basicInfoFormGroup.get('selectedSegment').value.trim().toUpperCase() + " " + this.basicInfoFormGroup.get('incentiveName').value.trim().toUpperCase();
        }
        else {
            return this.basicInfoFormGroup.get('selectedSegment').value.trim().toUpperCase();
        }
    };
    BasicInfoComponent.prototype.buildFormatedFromDate = function () {
        if (this.basicInfoFormGroup.get('fromDate').value != "") {
            var fromDate = this.basicInfoFormGroup.get('fromDate').value;
            return this.datePipe.transform(fromDate, 'ddMMyyyy').trim();
        }
    };
    BasicInfoComponent.prototype.buildFormatedToDate = function () {
        if (this.basicInfoFormGroup.get('toDate').value != "") {
            var toDate = this.basicInfoFormGroup.get('toDate').value;
            return this.datePipe.transform(toDate, 'ddMMyyyy').trim();
        }
    };
    BasicInfoComponent.prototype.buildIncentiveType = function () {
        if (this.basicInfoFormGroup.get('selectedIncentive').value != null)
            return this.basicInfoFormGroup.get('selectedIncentive').value.toUpperCase();
    };
    BasicInfoComponent.prototype.buildIdentifier = function () {
        if (this.basicInfoFormGroup.get('identifier').value != null)
            return this.basicInfoFormGroup.get('identifier').value.trim().toUpperCase();
    };
    BasicInfoComponent.prototype.buildRuleName = function () {
        if (this.basicInfoFormGroup.get('selectedRule').value != null) {
            return this.basicInfoFormGroup.get('selectedRule').value.trim();
        }
        else {
            return "";
        }
    };
    BasicInfoComponent.prototype.buildFinalIncentiveName = function () {
        return "MANUAL_" + this.buildCityShortName() + "_" + this.buildServiceName() + "_" + this.buildIncentiveAndSegmentName() + "_" + this.buildFormatedFromDate() + "_" + this.buildFormatedToDate() + "_" + this.buildIncentiveType() + "_" + this.buildIdentifier() + "_" + this.buildRuleName();
    };
    BasicInfoComponent.prototype.openUserSelectorPage = function () {
        this.basicInfoFormGroup.get('selectedRule').setValue(null);
        window.open(environment.userSelectorCreationPageLink);
    };
    return BasicInfoComponent;
}());
export { BasicInfoComponent };
