import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { LoaderService } from '../loader.service';
import { Router } from '@angular/router';
import { ViewCampaignsService } from './view-campaigns.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import * as moment from 'moment-timezone';
import { LogService } from '../logs.service';
import { AuthService } from '../auth.service';
import { RULE_KEY_MAPPINGS } from '../penalty-reward-campaign/constants';
var timezone = 'Asia/Kolkata';
var statusOptions = {
    active: 'Active',
    inactive: 'Inactive'
};
var ViewCampaignsComponent = /** @class */ (function () {
    function ViewCampaignsComponent(viewCampaignsService, sharedService, loaderService, _route, toasterService, logService, authService) {
        var _this = this;
        this.viewCampaignsService = viewCampaignsService;
        this.sharedService = sharedService;
        this.loaderService = loaderService;
        this._route = _route;
        this.toasterService = toasterService;
        this.logService = logService;
        this.authService = authService;
        this.limit = 10;
        this.offset = 0;
        this.tableLoaded = false;
        this.uniqueServices = [];
        this.status = [
            statusOptions.active,
            statusOptions.inactive
        ];
        this.mappedEvents = [];
        this.filterParams = {};
        this.filter = {};
        this.serviceInfo = {};
        this.events = {};
        this.showActivityLogs = false;
        this.getEvents = function () {
            _this.viewCampaignsService.fetchEventsData().subscribe(function (response) {
                if (response && response['data'] && response['data']['data']) {
                    var events = response['data']['data'];
                    _this.mappedEvents = events;
                    events.forEach(function (event) {
                        _this.events[event['event']] = event['displayText'];
                    });
                }
            }, function (err) {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Error in fetching events",
                }));
            });
        };
        this.fetchTableData = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var filter, payload, accessRights;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter = tslib_1.__assign({}, this.filter);
                        payload = {
                            filter: filter,
                            pagination: {
                                limit: this.table.pagination.perPage,
                                offset: this.table.pagination.pageNo * this.table.pagination.perPage
                            }
                        };
                        return [4 /*yield*/, this.authService.getPermissions()];
                    case 1:
                        accessRights = _a.sent();
                        this.table = new Table({
                            headers: {
                                campaignName: 'Name',
                                events: 'Event',
                                city: 'City',
                                services: 'Service',
                                startDate: 'Start Date',
                                endDate: 'End Date',
                                status: 'Status',
                                action: 'Action'
                            },
                            pagination: this.table.pagination,
                            onSortingChange: function (header) {
                            }
                        });
                        if (Object.keys(filter).length > 0) {
                            this.viewCampaignsService.fetchCampaignsData(payload)
                                .subscribe(function (result) {
                                _this.table.pagination.count = result.data.totalDocs;
                                _this.table.data = result.data.eventsData.map(function (data) {
                                    _this.serviceInfo = {};
                                    var id = data._id;
                                    var rid = data.ruleId;
                                    var campaign = data.ruleName;
                                    var city = data.cityName;
                                    var service = data.serviceNames || data.serviceName;
                                    var event = _this.getEventsList(data.events);
                                    var startDate = moment.tz(data.startDate, timezone).format('YYYY-MM-DD');
                                    var endDate = moment.tz(data.endDate, timezone).format('YYYY-MM-DD');
                                    var status = data.active ? statusOptions.active : statusOptions.inactive;
                                    return {
                                        _id: id,
                                        rid: rid,
                                        campaignName: campaign,
                                        events: event,
                                        city: city,
                                        services: service,
                                        startDate: startDate,
                                        endDate: endDate,
                                        status: _this.createStatus(status),
                                        action: _this.createAction(accessRights, status, endDate)
                                    };
                                });
                                _this.tableLoaded = true;
                                _this.loaderService.closeLoading();
                            }, function (err) {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Error in fetching data",
                                }));
                                _this.loaderService.closeLoading();
                                _this.tableLoaded = false;
                            });
                        }
                        else {
                            this.loaderService.closeLoading();
                            this.tableLoaded = false;
                        }
                        ;
                        return [2 /*return*/];
                }
            });
        }); };
        this.onView = function (campaignId, status) {
            var cId = encodeURIComponent(campaignId);
            var active;
            if (status.data === statusOptions.inactive) {
                active = false;
            }
            else {
                active = true;
            }
            var url = _this._route.serializeUrl(_this._route.createUrlTree(["/campaign/" + cId + "/" + active + "/view"]));
            window.open(url);
        };
        this.onDeActivate = function (data) {
            var previous = {
                _id: data,
                rid: data,
                active: true
            };
            var changed = {
                _id: data,
                rid: data,
                active: false
            };
            _this.viewCampaignsService.deactivateRule(data).subscribe(function (result) {
                if (result) {
                    var metadata = {
                        changedKeys: [RULE_KEY_MAPPINGS['active']],
                        user: {
                            name: _this.user.firstName,
                            email: _this.user.email
                        }
                    };
                    _this.logService.insertEvent(data, previous, changed, metadata, _this.user._id).subscribe();
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.SUCCESS,
                        message: 'Successfully De-Activated Campaign',
                    }));
                }
            }, function (error) {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Error in DeActivating campaign",
                }));
                console.log(error);
            });
        };
        this.OnClone = function (campaignId, status) {
            var cId = encodeURIComponent(campaignId);
            var active;
            if (status.data === statusOptions.inactive) {
                active = false;
            }
            else {
                active = true;
            }
            var url = _this._route.serializeUrl(_this._route.createUrlTree(["/campaign/" + cId + "/" + active + "/duplicate"]));
            window.open(url);
        };
        this.OnLogs = function (campaignId) {
            _this.logService.fetchLogs(campaignId).subscribe(function (result) {
                _this.activityLog = result && result['data'] || [];
            });
            _this.showActivityLogs = true;
        };
        this.OnEdit = function (campaignId, status) {
            var cId = encodeURIComponent(campaignId);
            var active;
            if (status.data === statusOptions.inactive) {
                active = false;
            }
            else {
                active = true;
            }
            var url = window.location.origin + ("/campaign/" + cId + "/" + active + "/edit");
            window.open(url);
        };
        this.toggleActivityLog = function (value) {
            if (value) {
                _this.showActivityLogs = value;
            }
            else {
                _this.showActivityLogs = !_this.showActivityLogs;
            }
            if (_this.showActivityLogs) {
                document.getElementById('overlay').style.display = 'block';
            }
            else {
                document.getElementById('overlay').style.display = 'none';
            }
        };
        this.defineTable();
    }
    ViewCampaignsComponent.prototype.ngOnChanges = function (changes) {
    };
    ViewCampaignsComponent.prototype.submitFilter = function () {
        var _this = this;
        this.filter = {};
        if (this.filterParams.city && this.filterParams.city.length && this.filterParams.service && this.filterParams.service.length) {
            if (this.filterParams.campaignName) {
                this.filter.action = this.filterParams.campaignName;
            }
            if (this.filterParams.status === statusOptions.inactive) {
                this.filter.active = false;
            }
            else if (this.filterParams.status === statusOptions.active) {
                this.filter.active = true;
            }
            if (this.filterParams.event) {
                this.filter.event = this.filterParams.event;
            }
            if (this.filterParams.city) {
                if (this.filterParams.service && this.filterParams.service.length) {
                    var filteredServiceDetails = [];
                    var _loop_1 = function (i) {
                        var matchingServiceDetails = this_1.services.filter(function (service) { return service.serviceId === _this.filterParams.service[i]; }).map(function (service) { return service.id; });
                        filteredServiceDetails.push.apply(filteredServiceDetails, matchingServiceDetails);
                    };
                    var this_1 = this;
                    for (var i = 0; i < this.filterParams.service.length; i++) {
                        _loop_1(i);
                    }
                    this.filter.serviceDetails = filteredServiceDetails;
                }
                else {
                    var filteredServices_1 = [];
                    if (this.services) {
                        this.services.forEach(function (service) {
                            filteredServices_1.push(service.id);
                        });
                    }
                    this.filter.serviceDetails = filteredServices_1;
                }
            }
            if (this.filterParams.startDate) {
                this.filter.startDate = moment.tz(this.filterParams.startDate, timezone).format('YYYY-MM-DD').toString();
            }
            if (this.filterParams.endDate) {
                this.filter.endDate = moment.tz(this.filterParams.endDate, timezone).format('YYYY-MM-DD').toString();
            }
            this.table.pagination.setPageNoToZero();
            this.fetchTableData();
        }
        else {
            if ((!this.filterParams.city || !this.filterParams.city.length) && (!this.filterParams.service || !this.filterParams.service.length)) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Please select a city and service",
                }));
            }
            else if (!this.filterParams.city || !this.filterParams.city.length) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Please select a city",
                }));
            }
            else if (!this.filterParams.service || !this.filterParams.service.length) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Please select a service",
                }));
            }
        }
    };
    ViewCampaignsComponent.prototype.clearFilter = function () {
        var filters = this.filter;
        this.filterParams = {};
        this.filter = {};
        if (Object.keys(filters).length === 0) {
            return;
        }
        this.table.pagination.pageNo = 0;
        this.fetchTableData();
    };
    ViewCampaignsComponent.prototype.defineTable = function () {
        this.table = new Table({
            headers: {
                campaignName: 'Campaign Name',
                events: 'Events',
                city: 'City',
                services: 'Services',
                startDate: 'Start Date',
                endDate: 'End Date',
                status: 'Status',
                actions: 'Actions'
            },
            pagination: new Pagination(0, 10, 10, [10, 15, 20]),
            onSortingChange: function (header) {
            }
        });
        this.tableLoaded = false;
    };
    ViewCampaignsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getEvents();
        this.getCities();
        this.authService.getUserDetails().subscribe(function (data) { _this.user = data; });
    };
    ViewCampaignsComponent.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, cities, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.sharedService.fetchCities().toPromise()];
                    case 1:
                        response = _a.sent();
                        cities = response['data']['cities'];
                        this.cities = cities.map(function (city) {
                            return {
                                id: city._id,
                                name: city.displayName,
                            };
                        }).sort(function (c1, c2) {
                            return c1.name > c2.name ? 1 : -1;
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Error in fetching cities",
                        }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ViewCampaignsComponent.prototype.onSelectAll = function () {
        var allCities = this.cities.map(function (item) { return item.id; });
        this.filterParams.city = allCities;
        this.getCityServiceDetails(allCities);
    };
    ViewCampaignsComponent.prototype.onClearAll = function () {
        this.filterParams.city = [];
        this.getCityServiceDetails(this.filterParams.city);
    };
    ViewCampaignsComponent.prototype.getCityServiceDetails = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, services, map, _i, _a, service, error_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!event) return [3 /*break*/, 2];
                        this.loaderService.openLoading();
                        return [4 /*yield*/, this.sharedService.fetchServices(event).toPromise()];
                    case 1:
                        response = _b.sent();
                        services = response.data;
                        this.services = services.map(function (service) {
                            return {
                                id: service._id,
                                serviceId: service.service._id,
                                name: service.service.name
                            };
                        });
                        map = new Map();
                        this.uniqueServices = [];
                        for (_i = 0, _a = this.services; _i < _a.length; _i++) {
                            service = _a[_i];
                            if (!map.has(service.serviceId)) {
                                map.set(service.serviceId, true); // set any value to Map
                                this.uniqueServices.push({
                                    id: service.id,
                                    serviceId: service.serviceId,
                                    name: service.name
                                });
                            }
                        }
                        this.loaderService.closeLoading();
                        return [3 /*break*/, 3];
                    case 2:
                        delete this.services;
                        _b.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_2 = _b.sent();
                        this.loaderService.closeLoading();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Error in fetching services",
                        }));
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ViewCampaignsComponent.prototype.getEventsList = function (events) {
        switch (typeof (events)) {
            case "string":
                return this.events[events];
                break;
            case "object":
                {
                    var eventList = [];
                    for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                        var event_1 = events_1[_i];
                        eventList.push(this.events[event_1]);
                    }
                    return eventList.join(", ");
                }
                break;
        }
    };
    ViewCampaignsComponent.prototype.createStatus = function (status) {
        return new TableData({
            data: status,
            type: TableDataTypes.STATUS,
        });
    };
    ViewCampaignsComponent.prototype.createAction = function (accessData, status, endDate) {
        var _this = this;
        var actionArr = [];
        if (accessData.includes('view_penalty_reward') || accessData.includes('edit_penalty_reward')) {
            actionArr.push(new TableAction({
                onClick: function (data) {
                    _this.onView(data.rid, data.status);
                },
                text: 'View'
            }));
        }
        if (accessData.includes('edit_penalty_reward')) {
            if (status === statusOptions.active) {
                actionArr.push(new TableAction({
                    onClick: function (data) {
                        _this.onDeActivate(data.rid);
                    },
                    text: 'De-Activate'
                }));
                if (endDate >= moment.tz(timezone).format('YYYY-MM-DD')) {
                    actionArr.push(new TableAction({
                        onClick: function (data) {
                            _this.OnEdit(data.rid, data.status);
                        },
                        text: 'Edit'
                    }));
                }
            }
            actionArr.push(new TableAction({
                onClick: function (data) {
                    _this.OnClone(data.rid, data.status);
                },
                text: 'Clone'
            }));
            actionArr.push(new TableAction({
                onClick: function (data) {
                    _this.OnLogs(data.rid);
                },
                text: 'Activity Log'
            }));
        }
        return new TableData({
            actions: new TableActions(actionArr),
            type: TableDataTypes.ACTION
        });
    };
    return ViewCampaignsComponent;
}());
export { ViewCampaignsComponent };
