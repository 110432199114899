import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');
    if (!token) {
      return next.handle(req);
    }
    req.headers.set('Authorization', 'Bearer ' + token)
    req = req.clone({
      headers:  new HttpHeaders({
        'Authorization': `Bearer ${token}`
      }),
    });

    return next.handle(req);
  }
}
