<div class="center">
    <form class="flex-col width-800">
      <span>
        <label class="heading" for="subscriptionType"><strong>Select Subscription Type</strong></label>
          <div class="row" style="padding-left: 15px;">
            <div class="custom-control custom-radio" style="padding-right: 10px;">
              <input type="radio" id="accessFee" name="subscriptionType" class="custom-control-input" [value]="'accessFee'" [(ngModel)]="subscriptionType" [ngModelOptions]="{standalone: true}" checked>
              <label class="custom-control-label" for="accessFee">Post Paid Subs</label>
            </div>
            <div class="custom-control custom-radio" style="padding-right: 10px;">
              <input type="radio" id="subscription" name="subscriptionType" class="custom-control-input" [value]="'subscription'" [(ngModel)]="subscriptionType" [ngModelOptions]="{standalone: true}">
              <label class="custom-control-label" for="subscription">Available for purchase</label>
            </div>

            <app-create-accessfee *ngIf="subscriptionType === 'accessFee'"></app-create-accessfee>
            <app-create-subscription *ngIf="subscriptionType === 'subscription'"></app-create-subscription>

          </div>
        </span>
    </form>
</div>
