export const TRIGGER_EVENT = {
    RIDER_BUSY: 'rider_busy',
    RIDER_REJECT: 'rider_reject',
    CUSTOMER_CANCELLED: 'customer_cancelled',
    RIDER_CANCELLED: 'rider_cancelled',
    ABORTED: 'aborted',
    ACCEPTED: 'accepted',
    FEEDBACK_FOR_CAPTAIN_RECEIVED: 'feedback_for_captain_received',
    DROPPED: 'dropped',
    DROPPED_SPD: 'dropped_spd',
    RATING: 'rating_for_captain_received',
    OFFLINE_RIDE: 'offlineRide',
    OVERALL_RATING: 'overall_rating',
    APR: 'apr',
    DPR: 'dpr',
    RIDER_SWITCH: 'switch_rider',
    TRUE_DPR: 'trueDPR',
    CAPTAIN_CANCELLATIONS: 'captainCancellations',
    CCR: 'ccr',
    ESCALATION_TICKET:'escalation_ticket',
    PARTIAL_OFFLINE_RIDE: 'partial_offline_ride',
    POR_CUSTOMER_CONTINUED_RIDE: 'partial_offline_ride_customer_continued_response',
    RIDER_OR_CUSTOMER_CANCELLED: 'rider_or_customer_cancelled',
};

export const CAMPAIGN_TYPE = {
    captainGame: 'Captain Game',
    penaltyAndReward: 'Penalty & Reward',
};

export const RATING_POSITION = {
    START: 'start',
    END: 'end',
    SINGLE: 'single'
};

export const RATING_TRIGGERS = [
    TRIGGER_EVENT.RATING,
    // TRIGGER_EVENT.OVERALL_RATING,
];

export const eventsWithoutReasons = [
    TRIGGER_EVENT.RIDER_BUSY,
    TRIGGER_EVENT.ACCEPTED,
    TRIGGER_EVENT.RIDER_REJECT,
    TRIGGER_EVENT.DROPPED,
    TRIGGER_EVENT.DROPPED_SPD,
    TRIGGER_EVENT.APR,
    TRIGGER_EVENT.DPR,
    TRIGGER_EVENT.OVERALL_RATING,
    TRIGGER_EVENT.CCR,
    TRIGGER_EVENT.TRUE_DPR,
    TRIGGER_EVENT.CAPTAIN_CANCELLATIONS,
    TRIGGER_EVENT.POR_CUSTOMER_CONTINUED_RIDE,
    TRIGGER_EVENT.RIDER_OR_CUSTOMER_CANCELLED,
    TRIGGER_EVENT.OFFLINE_RIDE,
];

export const aprDprEvents = [
    TRIGGER_EVENT.APR,
    TRIGGER_EVENT.DPR
];

export const EVENT_REASON_TYPE = {
    MULTI_SELECT: "multiSelect",
    SINGLE_SELECT: "singleSelect",
    RANGE: "range",
    NO_REASON: "noReason",
};

export const EQUATION_TYPES = {
    LESS_THAN: "Less than",
    GREATER_THAN: "Greater than",
    BETWEEN: "Between",
};

export const RULE_KEY_MAPPINGS = {
    ruleName: 'Rule Name',
    ruleId: 'Rule ID',
    startDate: 'Start Date',
    endDate: 'End Date',
    cityId: 'City',
    serviceDetails: 'Service',
    rules: 'Rules',
    timeWindows: 'Time Windows',
    rollingWindowDays: 'Rolling window days',
    event: 'Event',
    leeway: 'Leeway',
    leewayWarnMsg: 'Leeway message',
    instanceLeeway: 'Instance Leeway',
    instanceLeewayWarnMsg: 'Instance Leeway message',
    userSelectors: 'User Selector',
    active : "Active"
};

export const EVENT_NAME_MAPPINGS = {
    'rider_busy': 'Rider Busy',
    'rider_reject': 'Rider Reject',
    'customer_cancelled': 'Customer Cancelled',
    'aborted': 'Aborted',
    'accepted': 'Accepted',
    'feedback_for_captain_received': 'Captain Feedback',
    'dropped': 'Dropped',
    'rating_for_captain_received': 'Rating',
    'offlineRide': 'Offline ride Identified',
    'overall_rating': 'Overall Rating',
    'apr': 'Accept to Ping Ratio',
    'dpr': 'Drop to Ping Ratio',
    'switch_rider': 'Rider Switch',
    'streak': 'Streak',
    'trueDPR': 'True DPR',
    'captainCancellations': 'Captain Cancellations',
    'ccr': 'CCR%',
    'escalation_ticket': 'Escalation Ticket',
    [TRIGGER_EVENT.DROPPED_SPD]: "Dropped SPD",
    'partial_offline_ride_customer_continued_response' : 'POR Customer Continued Response',
    'rider_or_customer_cancelled' : 'Rider or Customer Cancelled'
};

export const EVENT_REASON_TYPES = {
    [TRIGGER_EVENT.RIDER_REJECT]: EVENT_REASON_TYPE.NO_REASON,
    [TRIGGER_EVENT.ACCEPTED]: EVENT_REASON_TYPE.NO_REASON,
    [TRIGGER_EVENT.RIDER_BUSY]: EVENT_REASON_TYPE.NO_REASON,
    [TRIGGER_EVENT.DROPPED]:  EVENT_REASON_TYPE.NO_REASON,
    [TRIGGER_EVENT.OFFLINE_RIDE]: EVENT_REASON_TYPE.NO_REASON,
    [TRIGGER_EVENT.ABORTED]:EVENT_REASON_TYPE.MULTI_SELECT,
    [TRIGGER_EVENT.CUSTOMER_CANCELLED]:EVENT_REASON_TYPE.MULTI_SELECT,
    [TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED]:EVENT_REASON_TYPE.MULTI_SELECT,
    [TRIGGER_EVENT.RIDER_SWITCH]: EVENT_REASON_TYPE.MULTI_SELECT,
    [TRIGGER_EVENT.RATING]: EVENT_REASON_TYPE.SINGLE_SELECT,
    [TRIGGER_EVENT.ESCALATION_TICKET]:EVENT_REASON_TYPE.MULTI_SELECT,
    [TRIGGER_EVENT.RIDER_CANCELLED]:EVENT_REASON_TYPE.MULTI_SELECT
}

export const INSTANCE_LEEWAY_EVENTS = [
    'apr',
    'dpr',
    'overall_rating',
    'ccr'
];

export const EVENT_KEY_VALUES = [
    { value: 'rider_reject', label: 'Rider Reject' },
    { value: 'rider_busy', label: 'Rider Busy' },
    { value: 'accepted', label: 'Rider Accepted' },
    { value: 'rider_cancelled', label: 'Rider Cancelled' },
    { value: 'aborted', label: 'Rider Abort' },
    { value: 'dropped', label: 'Dropped' },
    { value: 'offlineRide', label: 'Offline Ride Identified' },
];

export const CAPTAIN_ELIGIBILITY_SEGMENT_KEY_VALUES = [
    { value: 'CHURNED', label: 'Churned' },
    { value: 'HH', label: 'HandHolding' },
    { value: 'RECENT_UNDERPERFORMING', label: 'Recent Underperforming'},
    { value: 'RECENT_MIDPERFORMING', label: 'Recent Midperforming'},
    { value: 'RECENT_HIGHPERFORMING', label: 'Recent Highperforming'},
    { value: 'DORMANT_UNDERPERFORMING', label: 'Dormant Underperforming'},
    { value: 'DORMANT_MIDPERFORMING', label: 'Dormant Midperforming'},
    { value: 'DORMANT_HIGHPERFORMING', label: 'Dormant Highperforming'},
    { value: 'INACTIVE_UNDERPERFORMING', label: 'Inactive Underperforming'},
    { value: 'INACTIVE_MIDPERFORMING', label: 'Inactive Midperforming'},
    { value: 'INACTIVE_HIGHPERFORMING', label: 'Inactive Highperforming'}
];
export const ACTIONS = {
    WARNING: 'warning',
    PENALTY: 'penalty',
    REWARD: 'reward',
    SUSPEND: 'suspend',
    DEPRIORITIZE: 'deprioritize',
    DEACTIVATION: 'deactivation',
    ADJUSTMENT: 'adjustment',
    PUSH_TO_AGENT : 'pushToAgent'
};

export const defaultLocale = { code: 'en', label: 'English' }
export const supportedLocales = [
    { code: 'en', label: 'English' },
    { code: 'hi', label: 'Hindi' },
    { code: 'te', label: 'Telugu' },
    { code: 'ta', label: 'Tamil' },
    { code: 'kn', label: 'Kannada' },
    { code: 'mr', label: 'Marathi' },
    { code: 'bn', label: 'Bengali' },
]

export const PARTIAL_OFFLINE_RIDE_KEY = "dropVerificationStatus"
export const BLACKLISTED_TRIGGER_EVENTS_WITH_DROPPED_OFFLINE = {DROPPED:TRIGGER_EVENT.DROPPED, DROPPED_SPD:TRIGGER_EVENT.DROPPED_SPD}
export const BLACKLISTED_TRIGGER_EVENTS_WITH_POR_CUSTOMER_CONTINUED_RIDE = {CAPTAIN_FEEDBACK: TRIGGER_EVENT.FEEDBACK_FOR_CAPTAIN_RECEIVED,ESCALATION_TICKET:TRIGGER_EVENT.ESCALATION_TICKET}
