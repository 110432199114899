import { Component, OnInit, Input } from '@angular/core';
import { ViewIncentivesService } from './view-incentives.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes, Button } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { LoaderService } from '../loader.service';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from '../shared/types/toaster.types';
import { AuthService } from '../auth.service';

@Component({
  selector: 'view-incentives',
  templateUrl: './view-incentives.component.html',
  providers: [ViewIncentivesService]
})
export class ViewIncentivesComponent implements OnInit {
  public table: Table = new Table({
    headers: {
      active: 'Status',
      cities: 'City',
      incentiveName: 'Name',
      serviceNames: 'Service',
      startDate: 'Start Date',
      endDate: 'End Date',
      type: "Type",
      priority: "Priority",
      action: "Actions"
    },
    pagination: new Pagination(0, 15, 150, [15, 20]),
    data: []
  });
  public showFilters = true;
  public requestBody = {
    filters: {} as any,
    sort: {
      sortBy: "startDate",
      sortOrder: -1
    },
    incentiveName: ""
  };
  public incentiveIdForPerformace: string;

  private cities = [];
  private services = {};
  private citiesFilter = {
    type: 'searchable-dropdown',
    title: 'City',
    values: [],
    default: [],
    key: 'incentive-city'
  }

  private serviceFilter = {
    type: 'searchable-dropdown',
    title: 'Services',
    values: [],
    default: [],
    key: 'incentive-services'
  }

  public filterComponents: any[] = [
    this.citiesFilter,
    this.serviceFilter,
    {
      type: 'searchable-dropdown',
      title: 'Incentive Type',
      values: this.sharedService.INCENTIVE_TYPES,
      default: [],
      key: 'incentive-types'
    },
    {
      type: 'calendar',
      title: '',
      values: [],
      default: undefined,
      key: 'incentive-dates'
    },
    {
      type: 'radio-button',
      title: 'Status',
      values: ['Both', 'Active', 'Inactive'],
      default: 'Both',
      key: 'incentive-status'
    }
  ];

  constructor(
    private viewIncentivesService: ViewIncentivesService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private router: Router,
    private accessRights: AuthService,
    public toastService: ToasterService
  ) {
  }

  ngOnInit() {
    this.fetchAllCities();
  }

  async sortByHeader(header) {
    const sortOrder = header.includes("-") ? -1 : 1;
    const column = header.replace("-", "");
    if (!["startDate"].includes(column)) {
      return;
    }

    this.requestBody.sort.sortBy = column;
    this.requestBody.sort.sortOrder = sortOrder;

    await this.fetchTableData()
  }

  async fetchTableData() {
    await this.withLoader(() => this.fetchIncentives());
  }

  async fetchIncentives() {
    this.validateFilters();
    const payload = {
      filters: this.requestBody.filters,
      pagination: {
        pageNo: this.table.pagination.pageNo + 1,
        pageSize: this.table.pagination.perPage,
      },
      queryParams: {
        sortData: this.requestBody.sort.sortBy,
        sortOrder: this.requestBody.sort.sortOrder,
      }
    };

    const permissions: any = await this.accessRights.getPermissions();
    const incentives: any = await this.viewIncentivesService.fetchIncentiveData(payload).toPromise()

    this.table.data = incentives
      .map(incentive => {
        const cities = incentive.cities || [];
        const serviceType = incentive.serviceType || [];
        const id = incentive._id.$oid || incentive._id;
        const serviceNames = incentive.serviceNames || [];
        return {
          _id: id,
          active: incentive.active ? 'Active' : 'Inactive',
          cities: cities.join(', '),
          incentiveName: incentive.incentiveName,
          serviceType: serviceType.join(', '),
          serviceNames: serviceNames.join(', '),
          startDate: incentive.startDate,
          endDate: incentive.endDate,
          type: incentive.incentiveType,
          priority: incentive.priority || 0,
          action: this.createAction(permissions)
        };
      });
  }

  validateFilters() {
    this.validate("cities", "Select city and serviceTypes to fetch incentives");
    this.validate("serviceType", "Select serviceTypes to fetch incentives");
  }

  validate(key: string, message: string) {
    if (!this.requestBody.filters[key]) {
      this.showFilters = true;
      this.toastService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: message
      }))
      throw new Error(message);
    }
  }

  async withLoader(cb) {
    try {
      this.loaderService.openLoading();
      return await cb();
    } finally {
      this.loaderService.closeLoading()
    }
  }

  async fetchAllCities() {
    const citiesRes: any = await this.withLoader(() => this.sharedService.fetchCities().toPromise());
    this.cities = citiesRes.data.cities;
    this.citiesFilter.values = this.cities
      .map(city => city.displayName);
  }

  async fetchServices(city) {
    this.services = {};
    for (const cty of city) {
      const cityId = this.cities.find(c => c.displayName == cty)._id;
      const res: any = await this.sharedService.fetchServices(cityId).toPromise()

      for (const serviceDetail of res.data) {
        const name = serviceDetail.service.name;
        this.services[name] = this.services[name] || [];
        this.services[name].push(serviceDetail._id);
      }
    }
    this.serviceFilter.values = Object.keys(this.services)
  }

  createAction(accessData: any) {
    const actionArr = [];
    if (accessData.includes('view_incentive')) {
      actionArr.push(
        new TableAction({
          onClick: (data) => this.openPage(`/viewIncentive/${data._id}/view`),
          text: 'View'
        })
      );
    }

    if (accessData.includes('edit_incentive')) {
      actionArr.push(
        new TableAction({
          onClick: (data) => this.openPage(`/incentive/${data._id}/edit`),
          text: 'Edit'
        })
      );
      actionArr.push(
        new TableAction({
          onClick: (data) => this.openPage(`/incentive/${data._id}/duplicate`),
          text: 'Duplicate'
        })
      );
    }

    actionArr.push(
      new TableAction({
        onClick: (data) => this.incentiveIdForPerformace = data._id,
        text: 'Assignment Details'
      })
    );

    return new TableData({
      actions: new TableActions(actionArr),
      type: TableDataTypes.ACTION
    });
  }

  private openPage(url) {
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])))
  }

  async filterChange(filters) {
    if (filters['incentive-city'] && filters['incentive-city'].length) {
      this.withLoader(() => this.fetchServices(filters['incentive-city']));
    }
  }

  filterToggle() {
    this.showFilters = !this.showFilters;
  }

  nameSearchChange() {
    this.requestBody.filters.incentiveName = this.requestBody.incentiveName;
    this.fetchTableData()
  }

  applyFilters(filters) {
    this.table.pagination.setPageNoToZero();
    const filtersRequest: any = { incentiveName: this.requestBody.incentiveName };

    if (filters['incentive-status'] === 'Active') {
      filtersRequest.active = true;
    }

    if (filters['incentive-status'] === 'Inactive') {
      filtersRequest.active = false;
    }

    const [startDate = undefined, endDate = undefined] = filters['incentive-dates'] || [];
    filtersRequest.startDate = startDate ? startDate : undefined;
    filtersRequest.endDate = endDate ? endDate : undefined;

    let allServiceIds = [];
    for (const service of (filters['incentive-services'] || [])) {
      allServiceIds = allServiceIds.concat(this.services[service]);
    }
    if (allServiceIds.length > 0) {
      filtersRequest['serviceType'] = allServiceIds;
    }

    if ((filters['incentive-city'] || []).length > 0) {
      filtersRequest['cities'] = filters['incentive-city'];
    }

    if ((filters['incentive-types'] || []).length > 0) {
      filtersRequest['types'] = filters['incentive-types'];
    }

    this.requestBody.filters = filtersRequest;
    this.fetchTableData();
  }
}
