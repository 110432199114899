import { OnInit, EventEmitter } from '@angular/core';
var MaterialTimepickerComponent = /** @class */ (function () {
    function MaterialTimepickerComponent() {
        this.disabled = false;
        this._value = '';
        this.placeholder = 'Select Time';
        this.style = {
            height: '34px',
            width: '103px'
        };
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(MaterialTimepickerComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (date) {
            if (date && Date.parse(date)) {
                // date = new Date(date);
                // let hour = date.getHours();
                // let minutes = date.getMinutes();
                // let type = 'am';
                // if (hour > 12) {
                //   hour = hour % 12;
                //   type = 'pm';
                // }
                // this._value = `${hour}:${minutes} ${type}`;
                this._value = this.getTimeInString(date);
            }
        },
        enumerable: true,
        configurable: true
    });
    MaterialTimepickerComponent.prototype.getTimeInString = function (date) {
        date = new Date(date);
        var hour = date.getHours();
        var minutes = date.getMinutes();
        var type = 'am';
        if (hour === 12) {
            type = 'pm';
        }
        if (hour === 0) {
            hour = 12;
            type = 'am';
        }
        if (hour > 12) {
            hour = hour % 12;
            type = 'pm';
        }
        var timeInString = hour + ":" + minutes + " " + type;
        return timeInString;
    };
    MaterialTimepickerComponent.prototype.ngOnInit = function () {
    };
    MaterialTimepickerComponent.prototype.onTimeChange = function (time) {
        var _a = time.split(' '), timing = _a[0], type = _a[1];
        var _b = timing.split(':'), hour = _b[0], minute = _b[1];
        if (type === 'pm' && hour !== '12') {
            hour = parseInt(hour) + 12;
        }
        if (type === 'am' && hour === '12') {
            hour = 0;
        }
        var date = new Date();
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(hour);
        date.setMinutes(minute);
        this.value = date;
        this.valueChange.emit(date);
    };
    return MaterialTimepickerComponent;
}());
export { MaterialTimepickerComponent };
