import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { MyHttpClient } from '../http-client';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
export var IAllocatorIncentiveTypes = {
    DAILY: "Daily",
    WEEKLY: "Weekly",
};
var IAllocatorService = /** @class */ (function () {
    function IAllocatorService(http) {
        this.http = http;
        this.iAllocatorConstructs = environment.server + '/api/incentiveConstructs';
        this.iAllocatorUploadConstructs = environment.server + '/api/uploadIncentiveConstructs';
        this.userIdsConstructs = environment.server + "/api/userselector/users";
    }
    IAllocatorService.prototype.fetchIncentiveConstructs = function (iAllocatorData) {
        return this.http.post(this.iAllocatorConstructs, iAllocatorData).toPromise();
    };
    IAllocatorService.prototype.uploadConstructs = function (constructData) {
        return this.http.post(this.iAllocatorUploadConstructs, constructData).toPromise();
    };
    IAllocatorService.prototype.fetchAllUserFor = function (userSelectorIdsWithLabels) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userIdsAccordingToSegmentIdsWithLabels;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(userSelectorIdsWithLabels.map(function (userSelectorIdsWithLabel) { return _this.fetchUsersFor(userSelectorIdsWithLabel[0]); }))];
                    case 1:
                        userIdsAccordingToSegmentIdsWithLabels = _a.sent();
                        userIdsAccordingToSegmentIdsWithLabels.map(function (userIdsAccordingToSegmentId, index) {
                            userIdsAccordingToSegmentId.segment_name = userSelectorIdsWithLabels[index][1];
                        });
                        return [2 /*return*/, _.flatMap(userIdsAccordingToSegmentIdsWithLabels, function (usersResponse) {
                                var _a = usersResponse.data, users = _a.users, _id = _a._id, segment_name = usersResponse.segment_name;
                                return users.map(function (userId) { return [segment_name, _id, userId]; });
                            })];
                }
            });
        });
    };
    IAllocatorService.prototype.fetchUsersFor = function (userSelectorId) {
        return this.http.get(this.userIdsConstructs + "/" + userSelectorId).toPromise();
    };
    IAllocatorService.defaultIPR = 10;
    IAllocatorService.ngInjectableDef = i0.defineInjectable({ factory: function IAllocatorService_Factory() { return new IAllocatorService(i0.inject(i1.MyHttpClient)); }, token: IAllocatorService, providedIn: "root" });
    return IAllocatorService;
}());
export { IAllocatorService };
