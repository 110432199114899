import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertDialogComponent } from './alert-dialog.component';

export interface AlertDialogOptions {
  title: string,
  message?: string,
  value?: string[],
  confirmText?: string,
  details?: { message: string, value: string[]; }[];
}

@Injectable()
export class AlertDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<AlertDialogComponent>;

  public open(options: AlertDialogOptions): void {
    this.dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '450px',
      data: {
        title: options.title,
        confirmText: options.confirmText,
        details: options.details || [
          {
            message: options.message,
            value: options.value,
          }
        ]
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1));
  }
}
