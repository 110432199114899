import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../http-client';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(http) {
        this.http = http;
        this.rulePath = "/api/subscription/rule";
        this.ruleByIdPath = "/api/subscription/rule";
        this.createrulePath = "/api/accessfee/rule";
    }
    SubscriptionService.prototype.createSubscriptionRules = function (req) {
        return this.http
            .post(environment.server + this.rulePath, req)
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.createBillingPlan = function (req) {
        return this.http
            .post(environment.server + this.createrulePath, req)
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.getSubscriptionRule = function (id) {
        return this.http
            .get(environment.server + this.ruleByIdPath + "/" + id)
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.getHighestPriorityHHRule = function (serviceDetails) {
        return this.http
            .post(environment.server + "/api/subscription/fetchRules", {
            filters: {
                serviceDetails: serviceDetails,
                isAutoAssignedToHH: true,
                active: true
            },
            pagination: {
                page: 1,
                pageSize: 1
            }
        })
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.getSamePriorityRules = function (serviceDetails, startTime, endTime, priority) {
        return this.http
            .post(environment.server + "/api/subscription/fetchRules", {
            filters: {
                serviceDetails: serviceDetails,
                startTime: startTime,
                endTime: endTime,
                active: true,
                isAutoAssignedToHH: false,
                priority: priority
            },
            pagination: {
                page: 1,
                pageSize: environment.maxRulesCreationAtOnce
            }
        })
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.getRules = function (payload) {
        return this.http
            .post(environment.server + "/api/subscription/fetchRules", payload)
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.updateRule = function (ruleId, payload) {
        return this.http
            .patch(environment.server + "/api/subscription/rule/" + ruleId, payload)
            .pipe(catchError(this.handleError));
    };
    SubscriptionService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", body was:", error.error);
        }
        return throwError(error);
    };
    SubscriptionService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.inject(i1.MyHttpClient)); }, token: SubscriptionService, providedIn: "root" });
    return SubscriptionService;
}());
export { SubscriptionService };
