import {Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../subscription.service';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes } from '../../../shared/types';
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';
import { SharedService } from '../../../shared/shared.service';
import { ToasterService } from '../../../toaster.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { Toaster, ToasterType } from '../../../shared/types/toaster.types';
import { LoaderService } from '../../../loader.service';
import { AlertDialogOptions, AlertDialogService } from '../../../shared/alert-dialog/alert-dialog.service';
import {getEpochFromDate, getDateFromEpoch} from '../../../utils/utils'

@Component({
  selector:'app-list',
  templateUrl: './list.component.html',
  providers: [SubscriptionService]
})
export class ListSubscriptionsComponent implements OnInit{
  public table: Table = new Table({
    headers:{
      active: 'Status',
      cities: 'City',
      subscriptionName: 'Name',
      serviceNames:'Service',
      startDate: 'Start Date',
      endDate: 'End Date',
      type: "Type",
      priority: "Priority",
      action: "Actions"
    },
    pagination: new Pagination(0, 15, 150, [15, 20]),
    data: []
  });
  public showFilters = true;

  public requestBody = {
    filters: {} as any,
    sort: {
      sortBy: "startTime",
      sortOrder: -1
    }
  };

  private cities = [];
  private services = {};
  public cityMap = {};
  public cityIdNameMap = {};
  public serviceMap = {};
  private citiesFilter = {
    type: 'searchable-dropdown',
    title: 'City',
    values: [],
    default: [],
    key: 'subscription-city'
  }

  private serviceFilter = {
    type: 'searchable-dropdown',
    title: 'Services',
    values: [],
    default: [],
    key: 'subscription-services'
  }

  private displayFieldToActualFieldMap = {
    "startDate":"startTime",
    "endDate":"endTime"
  };

  public filterComponents: any[] = [
    this.citiesFilter,
    this.serviceFilter,
    {
      type: 'radio-button',
      title: 'Type',
      name:'type',
      values: ['Non-HH','HH'],
      default: 'Non-HH',
      key: 'subscription-HH'
    },
    {
      type: 'calendar',
      title: '',
      values: [],
      default: undefined,
      key: 'subscription-dates',
    },
    {
      type: 'radio-button',
      title: 'Status',
      name:'status',
      values: ['Both', 'Active', 'Inactive'],
      default: 'Both',
      key: 'subscription-status'
    }
  ];
  constructor(
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private router: Router,
    private accessRights: AuthService,
    public toastService: ToasterService,
    private dialogService: ConfirmDialogService,
    private alertDialogService: AlertDialogService
  ) {
  }
  ngOnInit() {
    this.fetchAllCities();
  }

  async withLoader(cb) {
    try {
      this.loaderService.openLoading();
      return await cb();
    } finally {
      this.loaderService.closeLoading()
    }
  }

  async fetchAllCities() {
    const citiesRes: any = await this.withLoader(() => this.sharedService.fetchCities().toPromise());
    const citiesData =citiesRes.data.cities;

    this.cities = citiesData;
    this.citiesFilter.values = citiesData.map(city => city.displayName);

      for (const city of citiesData) {
          this.cityMap[city.displayName] = city._id;
          this.cityIdNameMap[city._id] = city.displayName;
      }
  }

  async fetchServices(cities) {
    this.services = {};
    const cityMap = new Map();
    for (const city of this.cities) {
        cityMap.set(city.displayName, city._id);
    }

    for (const city of cities) {
      const cityId = cityMap.get(city);
      if (cityId !== undefined) {
        const res: any = await this.sharedService.fetchServices(cityId).toPromise()

        for (const serviceDetail of res.data) {
          const name = serviceDetail.service.name;
          this.services[name] = this.services[name] || [];
          this.services[name].push(serviceDetail._id);
        }
      }
    }
    this.serviceFilter.values = Object.keys(this.services)
  }

  async filterChange(filters) {
    if (filters['subscription-city'] && filters['subscription-city'].length) {
      this.withLoader(() => this.fetchServices(filters['subscription-city']));
    }
  }
  async sortByHeader(header) {
    const sortOrder = header.includes("-") ? -1 : 1;
    const column = header.replace("-", "");
    if (!["startDate"].includes(column)) {
      return;
    }
  if(this.displayFieldToActualFieldMap[column]){
    this.requestBody.sort.sortBy = this.displayFieldToActualFieldMap[column];
  }else{
    this.requestBody.sort.sortBy = column;
  }
    this.requestBody.sort.sortOrder = sortOrder;
    await this.fetchTableData()
  }

  applyFilters(filters) {
    this.table.pagination.setPageNoToZero();
    const filtersRequest: any = {};

    if (filters['subscription-status'] === 'Active') {
      filtersRequest.active = true;
    }

    if (filters['subscription-status'] === 'Inactive') {
      filtersRequest.active = false;
    }

    const [startDate = undefined, endDate = undefined] = filters['subscription-dates'] || [];

    filtersRequest.startTime = startDate ? getEpochFromDate(startDate) : undefined;
    filtersRequest.endTime = endDate ? getEpochFromDate(endDate) : undefined;

    let allServiceIds = [];
    for (const service of (filters['subscription-services'] || [])) {
      allServiceIds = allServiceIds.concat(this.services[service]);
    }
    if (allServiceIds.length > 0) {
      filtersRequest['serviceDetails'] = allServiceIds;
    }
    if ((filters['subscription-city'] || []).length > 0) {
      filtersRequest['cities'] = filters['subscription-city'].map(x=>this.cityMap[x]);
    }
    if(filters['subscription-HH'] == "HH"){
      filtersRequest['isAutoAssignedToHH'] = true;
    } else{
      filtersRequest['isAutoAssignedToHH'] = false;
    }
    this.requestBody.filters = filtersRequest;
    this.fetchTableData();
  }

  async fetchTableData() {
    await this.withLoader(() => this.fetchSubscriptionRules());
  }

  async fetchSubscriptionRules() {
    this.validateFilters();
    const payload = {
      filters: this.requestBody.filters,
      pagination: {
        page: this.table.pagination.pageNo + 1,
        pageSize: this.table.pagination.perPage,
      },
      queryParams: {
        sortBy: this.requestBody.sort.sortBy,
        sortOrder: this.requestBody.sort.sortOrder,
      }
    };

    const permissions: any = await this.accessRights.getPermissions();
    const rules: any = await this.subscriptionService.getRules(payload).toPromise()
    this.table.data = rules
      .map(rule => {
        const cities = rule.cities.map(x=>this.cityIdNameMap[x]);
        const serviceNames = rule.serviceNames || [];
        const endDate = rule.endTime ? getDateFromEpoch(rule.endTime): "--";
        const row ={
          id: rule.id,
          active: rule.active ? 'Active' : 'Inactive',
          cities: cities.join(', '),
          subscriptionName: rule.ruleName,
          serviceNames: serviceNames.join(', '),
          startDate: getDateFromEpoch(rule.startTime),
          endDate: endDate,
          type: rule.validity[0].type,
          priority: rule.priority || 0,
          action: this.createAction(permissions,rule)
        };
        return row;

      });
  }
  validateFilters() {
    this.validate("cities", "Select city and serviceDetails to fetch subscription rules");
    this.validate("serviceDetails", "Select services to fetch subscription rules");
  }

  validate(key: string, message: string) {
    if (!this.requestBody.filters[key]) {
      this.showFilters = true;
      this.toastService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: message
      }))
      throw new Error(message);
    }
  }

  createAction(accessData: any,rule:any) {
    const actionArr = [];

    actionArr.push(
      new TableAction({

        onClick: (data) => {
          this.openPage(`/subscription/view/${data.id}`)
        },
        text: 'View'
      })
    );

    if (accessData.includes('captain_subscription_deactivate') && rule.active) {
      actionArr.push(
        new TableAction({

          onClick: async (data) => {
            await this.deactivateSubs(data);
          },
          text: 'Deactivate'
        })
      );
    }
    return new TableData({
      actions: new TableActions(actionArr),
      type: TableDataTypes.ACTION
    });
  }
  private showAlertBox(options: AlertDialogOptions, cb = () => { }) {
    this.alertDialogService.open(options);
    this.alertDialogService.confirmed()
      .subscribe(confirmed => confirmed && cb());
  }

  async deactivateSubs(ruleData:any){
      const options = {
        title: 'Are you sure you want to deactivate the subscription?',
        message: `You are about to deactivate subscription ${ruleData.subscriptionName}`,
        cancelText: 'Cancel',
        confirmText: 'Yes, Proceed'
      };

      this.dialogService.open(options);
      this.dialogService.confirmed().subscribe(async (confirmed) => {
        if (confirmed) {
          const payload = {
            active:false
          }
          try{
            await this.subscriptionService.updateRule(ruleData.id,payload).toPromise();
            this.showAlertBox({
             title: 'Success',
             message: `Subscription ${ruleData.subscriptionName} deactivated successfully.`
           })
          }catch(error){
            this.showAlertBox({
              title: 'Error',
              message: error.message
            })
          }
        }
    })
  }
  private openPage(url) {
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])))
  }
}