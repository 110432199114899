import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    CalendarModule,
    TableModule,
    ConfirmDialogModule,
    AccordionModule,
    DropdownModule,
    MenuModule,
    ScrollPanelModule,
    FileUploadModule,
    TooltipModule
  ]
})
export class PrimengModule { }
