import { MyHttpClient } from '../../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client";
var PenaltyBasicInfoService = /** @class */ (function () {
    function PenaltyBasicInfoService(http) {
        var _this = this;
        this.http = http;
        this.eventsUrl = environment.server + "/api/get/events/v1";
        this.eventReasonsUrl = environment.server + "/api/eventReasons";
        this.escalationTicketEventReasonsUrl = environment.server + "/api/escalationReasons";
        this.fetchEventReasons = function (payload) {
            if (payload.event === "escalation_ticket") {
                return _this.http.get(_this.escalationTicketEventReasonsUrl).pipe(catchError(_this.handleError));
            }
            return _this.http.post(_this.eventReasonsUrl, payload).pipe(catchError(_this.handleError));
        };
        this.fetchEvents = function () {
            return _this.http.get(_this.eventsUrl).pipe(catchError(_this.handleError));
        };
    }
    PenaltyBasicInfoService.prototype.checkBlockRedeemEligibility = function (allServices, campaignData) {
        var serviceIds = campaignData.serviceIds, eventsAndReasons = campaignData.eventsAndReasons;
        var deliveryServices = allServices.filter(function (service) { return service.serviceType === 'delivery'; }).map(function (service) { return service.serviceId; });
        var isDeliveryService = function (service) { return ~deliveryServices.indexOf(service); };
        var hasAnyDeliveryService = serviceIds.some(isDeliveryService);
        var hasAnyCustomerCancelled = Boolean(eventsAndReasons.filter(function (trigger) { return trigger.event === 'customer_cancelled'; }).length);
        return hasAnyDeliveryService && hasAnyCustomerCancelled;
    };
    PenaltyBasicInfoService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    PenaltyBasicInfoService.ngInjectableDef = i0.defineInjectable({ factory: function PenaltyBasicInfoService_Factory() { return new PenaltyBasicInfoService(i0.inject(i1.MyHttpClient)); }, token: PenaltyBasicInfoService, providedIn: "root" });
    return PenaltyBasicInfoService;
}());
export { PenaltyBasicInfoService };
