<div class="activity-panel">
  <div class="row padding-20">
    <div class="col-2" (click)="closedActivityLogs()">
      <mat-icon>close</mat-icon>
    </div>
    <div class="col activity-heading">Activity Logs</div>
  </div>
  <div class="line"></div>
  <div class="scroll-logs">
    <div class="activity-logs-container" *ngFor="let log of logs">
      <div class="activity-log-date">
        {{modifyDate(log.date)}}
      </div>
      <div *ngFor="let data of log.logs">
        <div class="row space-between">
          <div class="col">
            <div class="row align-bullet">
              <div class="bullet"></div>
              <div class="activity-log-key">{{data.key.join(', ')}}</div>
            </div>
          </div>
          <div class="col activity-log-time">{{data.timestamp}}</div>
        </div>
        <div class="row">
          <div class="col activity-log-actor">{{data.actorEmail}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
