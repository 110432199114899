import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../http-client';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PenaltyBasicInfoService {

  constructor(private http: MyHttpClient) { }

  eventsUrl = `${environment.server}/api/get/events/v1`;
  eventReasonsUrl = `${environment.server}/api/eventReasons`;
  escalationTicketEventReasonsUrl = `${environment.server}/api/escalationReasons`
  fetchEventReasons = (payload) => {
    if (payload.event === "escalation_ticket") {
      return this.http.get(this.escalationTicketEventReasonsUrl).pipe(catchError(this.handleError));
    }
    return this.http.post(this.eventReasonsUrl, payload).pipe(catchError(this.handleError));
  }

  fetchEvents = () => {
    return this.http.get(this.eventsUrl).pipe(catchError(this.handleError));
  }

  checkBlockRedeemEligibility(allServices, campaignData) {
    const { serviceIds, eventsAndReasons } = campaignData;
    const deliveryServices = allServices.filter((service) => service.serviceType === 'delivery').map((service) => service.serviceId);
    const isDeliveryService = (service) => ~deliveryServices.indexOf(service);
    const hasAnyDeliveryService = serviceIds.some(isDeliveryService);
    const hasAnyCustomerCancelled = Boolean(eventsAndReasons.filter((trigger) => trigger.event === 'customer_cancelled').length);
    return hasAnyDeliveryService && hasAnyCustomerCancelled;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}

