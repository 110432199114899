import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { LoaderService } from 'src/app/loader.service';
import { AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { ToasterService } from 'src/app/toaster.service';
import { SubscriptionService } from '../../subscription.service';
import { environment } from 'src/environments/environment';
import Utils from 'src/app/utils/utils';
import { NgModule } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-create-subscription',
  templateUrl: './prepaid.create.component.html',
  styleUrls: ['./prepaid.create.component.css']
})
export class CreateSubscriptionComponent implements OnInit {
  public subscriptionFormGroup = new FormGroup({
    ruleName: new FormControl(null),
    city: new FormControl(null),
    services: new FormControl(null),
    userSelector: new FormControl(null),
    priority: new FormControl(null),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    excludeIncentiveTypes: new FormControl(null),
    ruleType: new FormControl(null),
    ruleValue: new FormControl(0),
    isAutoAssignedToHH: new FormControl(false),
    suspendServicesPostExpiry: new FormControl(false),
    rules: new FormControl([])
  });

  public cities: { id: string, name: string; }[] = [];
  public services: {
    id: string,
    name: string,
    serviceDetailId: string;
  }[] = [];
  public isRecommended: false;
  public userSelectors: { id: string, name: string; }[] = [];
  public incentiveTypesMap = this.sharedService.INCENTIVE_TYPES_MAP;
  public incentiveTypes = Object.keys(this.incentiveTypesMap)
  private servicesOrSDIDsAllowedForSubsCreation: Record<string,Set<String>> = {
    serviceIds: new Set<string>(environment.servicesOrSDIDsAllowedForSubsCreation.serviceIds),
    sdidsOfExcludedServices: new Set<string>(environment.servicesOrSDIDsAllowedForSubsCreation.sdidsOfExcludedServices)
  };
  public modeIdsWithHHRuleCreationEnabled = new Set(environment.modeIdsWithHHRuleCreationEnabled);
  public modeIdsWithSuspendServicesPostExpiryEnabled = new Set(environment.modeIdsWithSuspendServicesPostExpiryEnabled)
  public modeIdsWithVariableComissionEnabled = new Set(environment.modeIdsWithVariableComissionEnabled);
  public useDefaultPeriodInDays = environment.useDefaultPeriodInDays;
  public defaultPeriodInDays = environment.defaultPeriodInDays;
  public validityTypes: string[] = this.getValidityTypes();
  public maxRulesAtOnce: Number = environment.maxRulesCreationAtOnce;
  public today: Date = moment().startOf("hour").toDate();
  public tomorrowEod: Date = moment().endOf("day").minutes(30).add(1, 'days').toDate();
  public maxStartDate: Date;
  public minEndDate: Date = this.tomorrowEod;
  public highestPriorityHHRule;
  @ViewChild("OverlappingPriorityRuleAlert") OverlappingPriorityRuleAlert: TemplateRef<any>;
  public overlappingRules: any = [];
  public Utils = Utils;
  public subscriptionTitles: string[] = this.getSubscriptionTitles();

  constructor(private sharedService: SharedService,
    private subsService: SubscriptionService,
    private loader: LoaderService,
    private alertService: AlertDialogService,
    public toasterService: ToasterService,
    private dialog: MatDialog) {
    this.fetchCities();
  }

  resetOnchangeOf(controlName: string, toReset: string[], cb = () => { }) {
    this.subscriptionFormGroup.get(controlName).valueChanges.subscribe(() => {
      toReset.forEach(control => {
        this.subscriptionFormGroup.get(control).setValue(null);
      });
      cb();
    });
  }

  ngOnInit() {
    this.resetOnchangeOf("city", ["services"], () => {
      this.services = [];
      this.fetchServices();
    });
    this.resetOnchangeOf("services", ["userSelector", "ruleType", "suspendServicesPostExpiry"], () => {
      this.userSelectors = [];
      this.fetchSelectors();
      let mode: string = this.validateAndGetModeForServices() as string;
      if (!this.modeIdsWithHHRuleCreationEnabled.has(mode)) {
        this.subscriptionFormGroup.get('isAutoAssignedToHH').setValue(false)
        this.subscriptionFormGroup.get('isAutoAssignedToHH').disable()
      } else {
        this.subscriptionFormGroup.get('isAutoAssignedToHH').enable()
      }
      if (this.services && this.formValue("isAutoAssignedToHH")) {
        this.highestPriorityHHRule = null;
        this.fetchHighestPriorityHHRule();
      }
      if (!this.modeIdsWithSuspendServicesPostExpiryEnabled.has(mode)) {
        this.subscriptionFormGroup.get('suspendServicesPostExpiry').disable()
      } else {
        this.subscriptionFormGroup.get('suspendServicesPostExpiry').enable()
      }
    });
    this.resetOnchangeOf("isAutoAssignedToHH", ["rules"], () => {
      this.addRule();
      if (!this.formValue('isAutoAssignedToHH')) {
        this.highestPriorityHHRule = null;
        this.maxRulesAtOnce = environment.maxRulesCreationAtOnce;
        this.subscriptionFormGroup.get('endDate').enable();
        this.subscriptionFormGroup.get('userSelector').enable();
        this.subscriptionFormGroup.get('rules').value.map(rule => {
          rule.ruleAmount.enable();
        })
      } else {
        this.highestPriorityHHRule = null;
        this.fetchHighestPriorityHHRule();
        this.maxRulesAtOnce = 1;
        this.subscriptionFormGroup.get('endDate').setValue(null);
        this.subscriptionFormGroup.get('endDate').disable();
        this.subscriptionFormGroup.get('userSelector').setValue(null);
        this.subscriptionFormGroup.get('userSelector').disable();
        this.subscriptionFormGroup.get('rules').value.map(rule => {
          rule.ruleAmount.setValue(0)
          rule.ruleAmount.disable();
        })
      }
    });
    this.subscriptionFormGroup.get('isAutoAssignedToHH').disable();
    this.subscriptionFormGroup.get('suspendServicesPostExpiry').disable();
  }

  formValue(name) {
    return this.subscriptionFormGroup.get(name).value;
  }

  fetchSelectors() {
    const city = this.formValue("city");
    const services = this.formValue("services");
    if (city && services) {
      const serviceTypes = services
        .map(s => s.serviceDetailId).join(",");
      this.loader.openLoading();
      this.sharedService
        .fetchRules(city.id, serviceTypes, null, "rider")
        .toPromise()
        .then((res: any) => {
          this.userSelectors = res.data.selectors
            .map(({ _id, name }) => ({ id: _id, name }));
        })
        .finally(() => this.loader.closeLoading());
    }
  }

  private fetchServices(): void {
    const city = this.formValue("city");
    if (city) {
      this.loader.openLoading();
      this.sharedService
        .fetchServicesWithMode(city.id)
        .toPromise()
        .then((res: any) => {
          this.services = res.data
            .filter(serviceDetail => serviceDetail.active)
            .filter(({_id, service} ) => {
              return this.servicesOrSDIDsAllowedForSubsCreation.serviceIds.has(service._id) ||
              this.servicesOrSDIDsAllowedForSubsCreation.sdidsOfExcludedServices.has(_id);
            })
            .map(({ service, _id, modeInfo }) => ({
              id: service._id,
              name: service.name,
              mode: modeInfo._id,
              serviceDetailId: _id
            }));
        })
        .finally(() => this.loader.closeLoading());
    }
  }

  private fetchCities() {
    this.loader.openLoading();
    this.sharedService
      .fetchCities()
      .toPromise()
      .then((res: any) => {
        this.cities = res.data.cities
          .map(({ _id, displayName }) => ({ id: _id, name: displayName }));
      })
      .finally(() => this.loader.closeLoading());
  }

  changeInDate() {
    const startDate = this.formValue("startDate");

    if (startDate) {
      this.minEndDate = startDate;
    }

    const endDate = this.formValue("endDate");
    if (endDate) {
      this.maxStartDate = endDate;
    }
  }

  async createSubscriptionRules() {
    this.loader.openLoading();

    try {
      const rules = await this.buildRules();
      if (!rules) {
        return;
      }

      await this.subsService.createSubscriptionRules({rules}).toPromise();
      this.alertService.open({
        title: "Success",
        message: "Subscription rules created successfully",
      });
    } catch (error) {
      this.alertService.open({
        title: "Error",
        message: error.message,
      });
    } finally {
      this.loader.closeLoading();
    }
  }

  alertMessage(alert: { title: string, message: string }) {
    this.alertService.open(alert)
  }

  errorMessage(message) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: message
    }));
  }

  validateField(fieldName: string, message: string) {
    const value = this.formValue(fieldName);
    if (!value) {
      this.errorMessage(message);
      throw new Error(message);
    }
    return value;
  }


  async buildRules() {
    const ruleName = this.validateField("ruleName", "Rule name is required");
    const city = this.validateField("city", "City is required");
    const services = this.validateField("services", "Services is required");
    const mode: string = this.validateAndGetModeForServices() as string;
    const isAutoAssignedToHH: boolean = this.formValue('isAutoAssignedToHH');
    const suspendServicesPostExpiry: boolean = this.formValue('suspendServicesPostExpiry');
    const startTime = moment(this.validateField("startDate", "Purchase Start date is required").getTime()).unix();
    const endTime = !isAutoAssignedToHH ? moment(this.validateField("endDate", "Purchase  End date is required").getTime()).unix() : null;
    const priority = this.validateField("priority", "Priority is required");
    const userSelector = !isAutoAssignedToHH ? this.validateField("userSelector", "User selector is required") : null
    const excludeIncentiveTypes = this.formValue("excludeIncentiveTypes");
    const ruleType = this.formValue("ruleType");
    const ruleValue = this.formValue("ruleValue") || 0;
    const serviceDetailIds = services.map(s => s.serviceDetailId);

    if (this.formValue('rules').length < 1) {
      this.errorMessage("At least one rule is required");
    }
    
    let recommendedCount = this.countRecommendedRules();

    if(recommendedCount>1){
      this.errorMessage("Only one rule with Recommended tag is allowed");
      this.alertMessage({
        title: "Error",
        message: "Only one rule with Recommended tag is allowed",
      })
      return;
    }

    let modeIdsWithExclusiveOfTax = new Set(environment.modeIdsWithExclusiveOfTax);

    if (!isAutoAssignedToHH) {
      try {
        let rules: any = await this.subsService.getSamePriorityRules(serviceDetailIds, startTime, endTime, priority).toPromise();
        if (rules && rules.length > 0) {
          this.overlappingRules = rules
          this.dialog.open(this.OverlappingPriorityRuleAlert);
          return
        }
      } catch(error) {
        this.alertMessage({
          title: "Error",
          message: JSON.stringify(error.message),
        })
        return
      }
    }

    if (isAutoAssignedToHH && this.highestPriorityHHRule && priority <= this.highestPriorityHHRule.priority) {
      this.alertMessage({
        title: "Already a higher priority HH rule exists",
        message: `A HH rule with priority ${this.highestPriorityHHRule.priority} already exists with validity of ${this.highestPriorityHHRule.periodInDays} days starting from ${Utils.dateFromEpoch(this.highestPriorityHHRule.startTime*1000)}. Please create a higher priority rule.`
      })
      return
    }

    let commonFields = {
      ruleName,
      startTime: startTime,
      endTime: endTime,
      priority: Number(priority),
      city: city.id,
      services: services.map(s => s.id),
      serviceDetails: serviceDetailIds,
      mode,
      isAutoAssignedToHH,
      exclusiveOfTax: modeIdsWithExclusiveOfTax.has(mode),
      userSelectors: !isAutoAssignedToHH ? [userSelector.id] : [],
      userSelectorName: !isAutoAssignedToHH ? userSelector.name : null,
      excludeIncentiveTypes: excludeIncentiveTypes || [],
      ruleType,
      suspendServicesPostExpiry,
      rule: [this.getRuleConstruct(ruleType, ruleValue)]
    };

    return this.formValue('rules').map((rule, index) => {

      if (!rule.validityType.value) {
        this.errorMessage(`validity type is required for rule ${index + 1}`);
        throw new Error(`validity type is required for rule ${index + 1}`);
      }

      if (!rule.maxValue.value && rule.validityType.value != "unlimited") {
        this.errorMessage(`max value is required for rule ${index + 1}`);
        throw new Error(`max value is required for rule ${index + 1}`);
      }

      if (!Number.isFinite(rule.ruleAmount.value) && !isAutoAssignedToHH) {
        this.errorMessage(`purchase price is required for rule ${index + 1}`);
        throw new Error(`purchase price is required for rule ${index + 1}`);
      }

      if (rule.strikedOutAmount.value && rule.strikedOutAmount.value <= rule.ruleAmount.value) {
        this.errorMessage(`Original price should be greater than purchase price for rule ${index + 1}`);
        throw new Error(`Original price should be greater than purchase price for rule ${index + 1}`);
      }

      if (!rule.periodInDays.value) {
        this.errorMessage(`period in days is required for rule ${index + 1}`);
        throw new Error(`period in days is required for rule ${index + 1}`);
      }

      return {
        ...commonFields,
        validity: [{
          type: rule.validityType.value,
          maxValue: rule.maxValue.value,
        }],
        ruleAmount: rule.ruleAmount.value,
        strikedOutAmount: rule.strikedOutAmount.value,
        periodInDays: rule.periodInDays.value,
        ruleTitle: rule.subscriptionTitle.value,
        isRecommended: rule.isRecommended.value || false,
      }
    })
  }


  getRuleConstruct(ruleType: string, ruleValue: string | null) {
    switch (ruleType) {
      case "variableCommission":
        const value = Number(ruleValue);
        if (!value || value > 100) {
          const message = "Rule value should be between 0 to 100";
          this.errorMessage(message);
          throw new Error(message);
        }
        return {
          variable: "commission",
          operator: "-",
          value: value,
          type: "percent"
        };
      case "zeroCommission":
        return {
          variable: "commission",
          operator: "=",
          value: 0,
          type: "flat"
        };
    }
  }

  addRule() {
    let defaultRuleElement = {
      validityType: new FormControl(null),
      maxValue: new FormControl(null),
      ruleAmount: new FormControl(null),
      strikedOutAmount: new FormControl(null),
      periodInDays: new FormControl({ value: this.useDefaultPeriodInDays ? this.defaultPeriodInDays : null, disabled: this.useDefaultPeriodInDays }),
      subscriptionTitle: new FormControl(null),
      isRecommended: new FormControl(false)
    }
    if (this.formValue('rules')) {
      this.formValue('rules').push(defaultRuleElement)
    } else {
      this.subscriptionFormGroup.get('rules').setValue([defaultRuleElement])
    }
  }
  
  countRecommendedRules() {
    let rulesArray = this.subscriptionFormGroup.get('rules').value
    let recommendTag= 0;

    rulesArray.forEach((element, index)=>{
      if(element.isRecommended.value){
        recommendTag++;
      }
    })
    return recommendTag;
  }

  deleteRule(index) {
    this.formValue('rules').splice(index, 1)
  }

  validityTypeChanged(rule) {
    if (rule.validityType.value === 'unlimited') {
      rule.maxValue.setValue(null)
      rule.maxValue.disable()
    } else {
      rule.maxValue.enable()
    }
  }

  validateAndGetModeForServices() {
    if (!this.formValue('services')) {
      return
    }
    let distinctModes = new Set(this.formValue('services').map((service: { mode: string }) => service.mode))

    if ( distinctModes.size > 1 ) {
      this.subscriptionFormGroup.get('services').setValue(null)
      this.subscriptionFormGroup.get('ruleType').setValue(null)
      this.alertMessage({
        title: "Error",
        message: "Please select services with same mode"
      });
      throw new Error(`services with different mode selected`);
    }

    let mode = distinctModes.values().next().value;

    return mode;
  }

  getValidityTypes() {
    return environment.validityTypesEnabled.length > 0 ? environment.validityTypesEnabled : ["unlimited"]
  }
  getSubscriptionTitles() {
    return environment.subscriptionTitles.length > 0 ? environment.subscriptionTitles : []
  }

  isModeEnabledForVariableComission() {
    let mode: string = this.validateAndGetModeForServices() as string;
    if (mode && this.modeIdsWithVariableComissionEnabled.has(mode)) {
      return true
    }
    return false
  }

  async fetchHighestPriorityHHRule() {
    const services = this.formValue('services');
    if (!services) {
      return null
    }
    this.subsService
      .getHighestPriorityHHRule(services.map(service => service.serviceDetailId))
      .subscribe((rules: any) => {
        if (rules.length > 0) {
          this.highestPriorityHHRule =  rules[0]
        }
      })
  }
}
