<div>
  <filter-pane [filterComponents]="filterComponents" [showFilter]="showFilters" (submit)="applyFilters($event)">
  </filter-pane>
  <mat-toolbar class="toolbar-border">
    Bulk payment history
    <div class="pull-right is-flex">
      <button mat-raised-button class="btn-primary" (click)="showFilters = !showFilters">Filters</button>
    </div>
  </mat-toolbar>
  <data-table [table]="table" (paginationChange)="fetchHistory()" (headerToSort)="sortByHeader($event)"></data-table>
</div>
