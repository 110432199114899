<div>
  <table mat-table [dataSource]="table.data" class="mat-elevation-z8">
    <ng-container *ngFor="let colKey of displayedColumns" [matColumnDef]="colKey">
      <th class="table-overflow center-align padding-30" mat-header-cell *matHeaderCellDef> {{table.headers[colKey]}}
        <mat-icon *ngIf="sortStatus[colKey] == 'None'" (click)="clickedHeader(table.headers[colKey])">
          sort
        </mat-icon>
        <mat-icon *ngIf="sortStatus[colKey] == 'desc'" (click)="clickedHeader(table.headers[colKey])">
          arrow_drop_up
        </mat-icon>
        <mat-icon *ngIf="sortStatus[colKey] == 'asc'" (click)="clickedHeader(table.headers[colKey])">
          arrow_drop_down
        </mat-icon>
      </th>
      <td title="{{element[colKey] ? element[colKey].data : ''}}" [ngStyle]="{ 'padding': '8px 8px' }"
        class="table-overflow center-align" mat-cell *matCellDef="let element; let i=index">
        <ng-container *ngIf="element[colKey]?.type == TableDataTypes.DATA">
          {{element[colKey].data}}
        </ng-container>
        <ng-container *ngIf="element[colKey]?.type == TableDataTypes.STATUS">
          <div [ngClass]='element[colKey].data'>{{element[colKey].data}}</div>
        </ng-container>
        <ng-container *ngIf="element[colKey]?.type == TableDataTypes.BUTTON">
          <button [class]="element[colKey]?.button?.className"
            (click)="doAction(i, element[colKey]?.button?.onClick)">{{element[colKey]?.button?.label}}</button>
        </ng-container>
        <div *ngIf="element[colKey]?.type == TableDataTypes.ACTION" class="table-actions">
          <button class="reinit-css" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button class="reinit-css" (click)="doSelectAction(i, colKey, action.text)"
              *ngFor="let action of element[colKey]?.actions?.actions" mat-menu-item>
              <span>{{action.text}}</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageIndex]="table.pagination.pageNo" [pageSize]="table.pagination.perPage"
    [pageSizeOptions]="table.pagination.pageSizeOptions" [length]="table.pagination.count" (page)="onPageChange($event)"
    showFirstLastButtons></mat-paginator>
</div>
