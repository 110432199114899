import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import {Router, ActivatedRoute} from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { PenaltyRewardCampaignService } from './penalty-reward-campaign.service';
import { LoaderService } from '../loader.service';
import { DatePipe } from '@angular/common';
import { cloneDeep, isEqual } from 'lodash';
import { LogService } from '../logs.service';
import { AuthService } from '../auth.service';
import { ACTIONS, RULE_KEY_MAPPINGS, defaultLocale, TRIGGER_EVENT } from './constants';
import { Action } from 'rxjs/internal/scheduler/Action';

@Component({
  selector: 'app-penalty-reward-campaign',
  templateUrl: './penalty-reward-campaign.component.html',
  styleUrls: ['./penalty-reward-campaign.component.css'],
  providers: [DatePipe]
})
export class PenaltyRewardCampaignComponent implements OnInit {
  public currentDate: Date = new Date();
  public campaignData: any = {};
  campaignId: any;
  defaultValue: any = {};
  submittingForm = false;
  formLoaded = false;
  @ViewChild('stepper') stepper: MatStepper;
  state = this.sharedService.STATE.CREATE;
  initialRule: any;
  user;

  public NOT_EDITABLE = {
    name: false,
    city: false,
    services: false,
    selectors: false,
    startDate: false,
    endDate: false,
    triggerEvents: false,
    timeSlots: false,
    leeway: false,
    leewayWarnMsg: false,
    instanceLeewayWarnMsg: false,
    eligibilityPings: false,
    eligibilityEvents: false,
    eligibilityWarnMsg: false,
    eligibilitySegments: false,
    daprPercentageStart: false,
    daprPercentageEnd: false,
    notification: false,
    action: false,
    message: false,
    startLimit: false,
    endLimit: false,
    amount: false,
    activateAfterHours: false,
    prioritizeAfterMins: false,
    rollingWindowDays: false,
    ratingBetweenStart: false,
    ratingBetweenEnd: false,
    ratingGreaterOrLessThanValue: false,
    blockRedeem: false,
    priority: true
  };

  public notificationsData: [];
  public notificationMapping = {};

  createFormViewRule() {
    this.NOT_EDITABLE = {
      name: true,
      city: true,
      services: true,
      selectors: true,
      startDate: true,
      endDate: true,
      triggerEvents: true,
      timeSlots: true,
      leeway: true,
      leewayWarnMsg: true,
      instanceLeewayWarnMsg: true,
      eligibilityPings: true,
      eligibilityEvents: true,
      eligibilityWarnMsg: true,
      eligibilitySegments: true,
      daprPercentageStart: true,
      daprPercentageEnd: true,
      notification: true,
      action: true,
      message: true,
      startLimit: true,
      endLimit: true,
      amount: true,
      activateAfterHours: true,
      prioritizeAfterMins : true,
      rollingWindowDays: true,
      ratingBetweenStart: true,
      ratingBetweenEnd: true,
      ratingGreaterOrLessThanValue: true,
      blockRedeem: true,
      priority: true
    };
  }

  createFormEditRule() {
    this.NOT_EDITABLE = {
      name: true,
      city: true,
      services: true,
      selectors: false,
      startDate: false,
      endDate: false,
      triggerEvents: false,
      timeSlots: false,
      leeway: false,
      leewayWarnMsg: false,
      instanceLeewayWarnMsg: false,
      eligibilityPings: false,
      eligibilityEvents: false,
      eligibilityWarnMsg: false,
      eligibilitySegments: false,
      daprPercentageStart: false,
      daprPercentageEnd: false,
      notification: false,
      action: false,
      message: false,
      startLimit: false,
      endLimit: false,
      amount: false,
      activateAfterHours: false,
      prioritizeAfterMins: false,
      rollingWindowDays: false,
      ratingBetweenStart: false,
      ratingBetweenEnd: false,
      ratingGreaterOrLessThanValue: false,
      blockRedeem: false,
      priority: false
    };
  }

  constructor(
    public penaltyRewardCampaignService: PenaltyRewardCampaignService,
    public toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private logService: LogService,
    private authService: AuthService
    ) { }

  async ngOnInit(): Promise<void> {
    this.authService.getUserDetails().subscribe((data: any) => { this.user = data; });
    await this.getNotificationTypes();
    this.loaderService.openLoading();
    const campaignId = decodeURIComponent(this.route.snapshot.paramMap.get('campaignId'));
    this.campaignId = campaignId;
    const active = this.route.snapshot.paramMap.get('status');
    const action = this.route.snapshot.paramMap.get('action') || this.sharedService.STATE.CREATE;
    switch (action) {
      case this.sharedService.STATE.VIEW : {
        const payload = { campaignId , status: active};
        this.penaltyRewardCampaignService.getCampaign(payload).subscribe((result: any) => {
          if (result) {
            this.defaultValue = result.data.eventsData[0];
            if (this.defaultValue.rules && this.defaultValue.rules.length) {
              this.defaultValue.rules = _.orderBy(this.defaultValue.rules, ['limit'],['asc']);
            }
            this.defaultValue.notificationMapping = this.notificationMapping;
            this.defaultValue.notificationsData = this.notificationsData;
            this.createFormViewRule();
            this.formLoaded = true;
            this.loaderService.closeLoading();
          }
        });
        this.state = this.sharedService.STATE.VIEW;
        break;
      }
      case this.sharedService.STATE.DUPLICATE : {
        const payload = { campaignId, status: active };
        this.penaltyRewardCampaignService.getCampaign(payload).subscribe((result: any) => {
          if (result) {
            this.defaultValue = result.data.eventsData[0];
            if (this.defaultValue.rules && this.defaultValue.rules.length) {
              this.defaultValue.rules = _.orderBy(this.defaultValue.rules, ['limit'],['asc']);
            }
            this.defaultValue.notificationMapping = this.notificationMapping;
            this.defaultValue.notificationsData = this.notificationsData;
            this.formLoaded = true;
            this.loaderService.closeLoading();
          }
        });
        this.state = this.sharedService.STATE.DUPLICATE;
        break;
      }
      case this.sharedService.STATE.EDIT : {
        const payload = { campaignId, status: active };
        this.penaltyRewardCampaignService.getCampaign(payload).subscribe((result: any) => {
          if (result) {
            this.defaultValue = result.data.eventsData[0];
            if (this.defaultValue.rules && this.defaultValue.rules.length) {
              this.defaultValue.rules = _.orderBy(this.defaultValue.rules, ['limit'],['asc']);
            }
            this.defaultValue.notificationMapping = this.notificationMapping;
            this.defaultValue.notificationsData = this.notificationsData;
            let endDate: Date = new Date(this.defaultValue.endDate);
            if (endDate.getTime() < this.currentDate.getTime()) {
              this.createFormViewRule();
              this.state = this.sharedService.STATE.VIEW;
            } else {
              this.createFormEditRule();
              this.state = this.sharedService.STATE.EDIT;
            }
            this.formLoaded = true;
            this.loaderService.closeLoading();
            this.initialRule = cloneDeep(this.defaultValue);
          }
        });
        break;
      }
      case this.sharedService.STATE.CREATE: {
        this.formLoaded = true;
        this.defaultValue.notificationMapping = this.notificationMapping;
        this.defaultValue.notificationsData = this.notificationsData;
        this.loaderService.closeLoading();
        break;
      }
    }
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  storeCampaignInfo = (event, type) => {
    if (!event) { return; }
    if (this.submittingForm) { return; }
    const errorMessage = (error) => {
      return error.error && error.error.data && error.error.data.error && error.error.data.error.message
    }
    this.submittingForm = true;
    if (event.submit === true) {
      this.loaderService.openLoading();
      const basicInfo = this.campaignData.basicInfo;
      let rules: any;
      rules = this.campaignData.rulesChange;
      const defaultTimeSlots = [{ startTime : '0000' , endTime: '2359'}];
      const timeSlots = basicInfo.timeSlots;
      const formattedTimeSlots = [];
      const campaignData = {};
      campaignData['ruleName'] = basicInfo.name;
      if (this.state === this.sharedService.STATE.EDIT) {
        campaignData['ruleId'] = basicInfo._id;
      }
      campaignData['startDate'] = this.setDate(basicInfo.startDate);
      campaignData['endDate'] = this.setDate(basicInfo.endDate);
      campaignData['cityId'] = basicInfo.cityId;
      campaignData['cityName'] = basicInfo.city;
      campaignData['serviceDetails'] = basicInfo.services || [];
      campaignData['serviceNames'] = basicInfo.serviceNames || [];
      campaignData['serviceIds'] = basicInfo.serviceIds || [];
      campaignData['ruleType'] = basicInfo.ruleType;
      campaignData['events'] = basicInfo.triggerEvent || '';
      campaignData['rules'] = [];
      campaignData['timeWindows'] = defaultTimeSlots;
      if (basicInfo.userSelector) {
        campaignData['userSelectors'] = basicInfo.userSelector;
        campaignData['fallbackToDefaultRule'] = basicInfo.fallbackToDefaultRule;
        campaignData['priority'] = basicInfo.priority;
      }
      if (basicInfo.leeway && basicInfo.leewayWarnMsg) {
        campaignData['leeway'] = basicInfo.leeway;
        campaignData['leewayWarnMsg'] = basicInfo.leewayWarnMsg;
      }
      if (basicInfo.instanceLeeway && basicInfo.instanceLeewayWarnMsg) {
        campaignData['instanceLeeway'] = basicInfo.instanceLeeway;
        campaignData['instanceLeewayWarnMsg'] = basicInfo.instanceLeewayWarnMsg;
      }
      if (basicInfo.eligibility && basicInfo.eligibility.pings && basicInfo.eligibility.events && basicInfo.eligibility.warnMsg) {
        campaignData['eligibility'] = {
          pings: basicInfo.eligibility.pings,
          events: basicInfo.eligibility.events,
          warnMsg: basicInfo.eligibility.warnMsg
        }
      }
      if(basicInfo.daprPercentageStart && basicInfo.daprPercentageEnd){
        campaignData['daprPercentage'] = {
          start: basicInfo.daprPercentageStart,
          end: basicInfo.daprPercentageEnd
        }
      }
      if (basicInfo.eligibilitySegments) {
        campaignData['eligibilitySegments'] = basicInfo.eligibilitySegments;
      }
      if (basicInfo.rollingWindowDays) {
        campaignData['rollingWindowDays'] = basicInfo.rollingWindowDays;
      }
      timeSlots.forEach(timeSlot => {
        if (timeSlot.startTime && timeSlot.endTime) {
            timeSlot.startTime = this.datePipe.transform(timeSlot.startTime, 'HHmm');
            timeSlot.endTime = this.datePipe.transform(timeSlot.endTime, 'HHmm');
            formattedTimeSlots.push(timeSlot);
        }
      });
      if (formattedTimeSlots.length > 0) {
        campaignData['timeWindows'] = formattedTimeSlots;
      }

      const blockRedeemFieldRelatedEvents = ['penalty', 'reward', 'suspend'];
      if(rules.oldRules) {
        rules.oldRules.forEach(rule => {
          const ruleToBePushed = {};
          // ruleToBePushed['event'] = basicInfo.triggerEvent;
          ruleToBePushed['limit'] = rule['startLimit'];
          ruleToBePushed['blockRedeem'] = rule['blockRedeem'];
          ruleToBePushed['type'] = rule['type'];
          ruleToBePushed['eventsAndReasons'] = basicInfo.eventsAndReasons;
          if (rule['endLimit']) {
            ruleToBePushed['applicableTill'] = rule['endLimit'];
          }
          if (rule['_id']) {
            ruleToBePushed['_id'] = rule['_id'];
          }
          if (rule['action'] === 'warning') {
            ruleToBePushed['action'] = 'notification';
          } else if (rule['action'] === 'deactivation') {
            ruleToBePushed['action'] = 'suspend';
          } else {
            ruleToBePushed['action'] = rule['action'];
          }
          if (~blockRedeemFieldRelatedEvents.indexOf(rule['action'])) {
            ruleToBePushed['amount'] = rule['amount'] !==null ? Math.abs(rule['amount']) : null;
            if(!rule['blockRedeem'] && ruleToBePushed['amount'] == null ) { delete ruleToBePushed['amount']; }
          }
          if (rule['action'] === 'adjustment') {
            if (rule['amount'] > 0) {
              ruleToBePushed['adjustmentType'] = 'credit';
            } else if (rule['amount'] < 0) {
              ruleToBePushed['adjustmentType'] = 'debit';
            }
            ruleToBePushed['amount'] = Math.abs(rule['amount']);
          }
          if (rule['action'] === 'suspend' || rule['action'] === 'deactivation') {
            if (rule['activateAfterHours']) {
              ruleToBePushed['activateAfterHours'] = rule['activateAfterHours'];
            }
            ruleToBePushed['suspensionReason'] = rule['message'];
          }

          if (rule['action'] ===  ACTIONS.DEPRIORITIZE) {
            if (rule['prioritizeAfterMins']) {
              ruleToBePushed['prioritizeAfterMins'] = rule['prioritizeAfterMins'];
            }
            ruleToBePushed['deproritzeReason'] = rule['message'];
          }

          if (rule['action'] ===  ACTIONS.PUSH_TO_AGENT) {
            ruleToBePushed['pushToAgentReason'] = rule['message'];
          }

          ruleToBePushed['displayReason'] = rule['message'];
          ruleToBePushed['notificationType'] = rule['notification'];
          if (rule['workingDays'])
            ruleToBePushed['workingDays'] = rule['workingDays'];

          if (basicInfo.eventReasonKey) {
            ruleToBePushed['key'] = basicInfo.eventReasonKey.key;
            ruleToBePushed['values'] = basicInfo.eventReason;
            }
          if ( basicInfo.triggerEvent === 'rating_for_captain_received') {
            ruleToBePushed['values'] = basicInfo.ratings;
          }

          campaignData['rules'].push(ruleToBePushed);
        });

        switch (this.state) {
          case this.sharedService.STATE.VIEW: {
            this.submittingForm = false;
            this.loaderService.closeLoading();
            this.router.navigate(['/campaigns']);
            break;
          }
          case this.sharedService.STATE.DUPLICATE:
            case this.sharedService.STATE.CREATE: {
              if (campaignData['ruleType'] == 'captainGame') {
                if (campaignData['rules'][0]['eventsAndReasons'][0].event == "streak") {
                  campaignData['captainGameNotification'] = {
                    "message": "Dear  Captain, Now win ${amount} Rapido coins by doing ${target} rides daily for ${workingDays} days.Click here to know more about the game",
                    "title": "Game Started !"
                  }

                } else {
                  campaignData['captainGameNotification'] = {
                    "message": "Dear Captain,the Game has started. Keep your Dropped score above ${target} % to get ${amount} Coins",
                    "title": "Game Started !"
                  }
                }
                campaignData['transactionMode'] = 'coin'
              }
              this.penaltyRewardCampaignService.createCampaign(campaignData).subscribe(result => {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.SUCCESS,
                  message: 'Successfully created ' +  campaignData['ruleName'] + ' campaign',
                }));
                this.loaderService.closeLoading();
                setTimeout( () => {
                  this.submittingForm = false;
                  this.router.navigate(['/campaigns']);
                }, 1000);

                this.loaderService.closeLoading();
              }, error => {
                console.log(error);
                this.submittingForm = false;
                this.loaderService.closeLoading();
                this.toasterService.showToaster(new Toaster({
                 type: ToasterType.WARNING,
                 message: 'Could not Create Campaign',
               }));
              });
              break;
            }
          case this.sharedService.STATE.EDIT: {
            const changedKeys = [];
            let nonEmptyCampaignData;
            for (const key in this.initialRule) {
              if ((this.initialRule[key] && !campaignData[key]) || (!this.initialRule[key] && campaignData[key])) {
                changedKeys.push(key);
              } else if (!this.initialRule[key] && !campaignData[key]) {
                // noop
              } else {
                if (key === 'startDate' || key === 'endDate') {
                  if (!this.isEqualValue(this.setDate(this.initialRule[key]), this.setDate(campaignData[key]))) {
                    changedKeys.push(key);
                  }
                } else if (key === 'rules') {
                  if (this.initialRule[key].length !== campaignData[key].length) {
                    changedKeys.push(key);
                  } else {
                    const nonEmptyInitialRule = this.removeEmpty(_.cloneDeep(this.initialRule[key]));
                    nonEmptyCampaignData = this.removeEmpty(_.cloneDeep(campaignData[key]));
                    if (!isEqual(nonEmptyInitialRule, nonEmptyCampaignData)) {
                      changedKeys.push(key);
                    }
                  }
                } else if (!this.isEqualValue(JSON.stringify(this.initialRule[key]), JSON.stringify(campaignData[key]))) {
                  changedKeys.push(key);
                }
              }
            }
            let finalKeys = [];
            for (const key in changedKeys) {
              if (changedKeys[key] && changedKeys[key]  !== "active") {
                RULE_KEY_MAPPINGS[changedKeys[key]] ? finalKeys.push(RULE_KEY_MAPPINGS[changedKeys[key]]) : '';
              }
            }
            const metadata = {
              changedKeys: finalKeys,
              user: {
                name:  this.user.firstName + ' ' + this.user.lastName,
                email: this.user.email
              }
            };
            this.penaltyRewardCampaignService.editCampaign(campaignData, campaignData['ruleId']).subscribe(result => {
              if (finalKeys.length) {
                this.logService.insertEvent(campaignData['ruleId'], this.initialRule, nonEmptyCampaignData, metadata, this.user._id).subscribe();
              }
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: 'Successfully edited ' +  campaignData['ruleName'] + ' campaign',
              }));
              this.loaderService.closeLoading();
              setTimeout( () => {
                this.submittingForm = false;
                this.router.navigate(['/campaigns']);
              }, 1000);

              this.loaderService.closeLoading();
            }, error => {
              console.log(error);
              this.submittingForm = false;
              this.loaderService.closeLoading();
              this.toasterService.showToaster(new Toaster({
               type: ToasterType.WARNING,
               message: 'Could not edit Campaign',
             }));
            });
            break;
          }
        }
      }
      else {
        rules.forEach((rule,index) => {
          let ruleToBePushed:any = {};
          ruleToBePushed['communications']=[];
          rule.communications.forEach(communication =>{
            if(rule['action']==='suspend') {
              ruleToBePushed.action = 'suspend';
              ruleToBePushed['suspensionReason'] = communication.messageLocales[defaultLocale.code];
              ruleToBePushed.activateAfterHours = rule['activateAfterHours'];
              if (rule['captainTask']) {
                ruleToBePushed.captainTask = rule['captainTask']
              }
              ruleToBePushed['communications'].push(communication);
            }
            else if(rule['action']==='deactivation') {
              ruleToBePushed.activateAfterHours = rule['activateAfterHours'];
              ruleToBePushed.action = 'suspend';
              ruleToBePushed['suspensionReason'] = communication.messageLocales[defaultLocale.code];
              ruleToBePushed['communications'].push(communication);
            }
            else if(rule['action']===ACTIONS.DEPRIORITIZE) {
              ruleToBePushed.prioritizeAfterMins = rule['prioritizeAfterMins'];
              ruleToBePushed.action = rule['action'];
              ruleToBePushed['deproritzeReason'] = communication.messageLocales[defaultLocale.code];
              ruleToBePushed['communications'].push(communication);
            }
            else if(rule['action']===ACTIONS.PUSH_TO_AGENT) {
              ruleToBePushed.action = rule['action'];
              ruleToBePushed['pushToAgentReason'] = communication.messageLocales[defaultLocale.code];
              ruleToBePushed['communications'].push(communication);
              if (rule['campaignId']) {
                    ruleToBePushed.campaignId = rule['campaignId'];              
              }
            }
            else if(rule['action']==='penalty' || rule['action'] === 'reward') {
              this.mapActionData(ruleToBePushed, rule);
              ruleToBePushed.amount = rule['amount'];
              ruleToBePushed['communications'].push(communication);
            }
            else if(rule['action'] === 'adjustment') {
              this.mapActionData(ruleToBePushed, rule);
              if(rule['amount']>0) {
                ruleToBePushed.adjustmentType = 'credit';
              }
              else if(rule['amount']<0) {
                ruleToBePushed.adjustmentType = 'debit';
              }
              ruleToBePushed.amount = Math.abs(rule['amount']);
              ruleToBePushed['communications'].push(communication);
            }
            else if(rule['action'] === 'warning') {
              ruleToBePushed.action = "notification"
              ruleToBePushed['communications'].push(communication);
            }
          })
          ruleToBePushed['events'] = basicInfo.triggerEvent;
          ruleToBePushed['limit'] = rule['startLimit'];
          ruleToBePushed['blockRedeem'] = rule['blockRedeem'];

          if (~blockRedeemFieldRelatedEvents.indexOf(rule['action'])) {
            ruleToBePushed['amount'] = rule['amount'] !==null ? Math.abs(rule['amount']) : null;
            if(!rule['blockRedeem'] && ruleToBePushed['amount'] == null ) { delete ruleToBePushed['amount']; }
          }
          ruleToBePushed['eventsAndReasons'] = basicInfo.eventsAndReasons;
          if (rule['endLimit']) {
            ruleToBePushed['applicableTill'] = rule['endLimit'];
          }
          if (rule['_id']) {
            ruleToBePushed['_id'] = rule['_id'];
          }

          if (basicInfo.eventReasonKey) {
            ruleToBePushed['key'] = basicInfo.eventReasonKey.key;
            ruleToBePushed['values'] = basicInfo.eventReason;
            }
          if ( basicInfo.triggerEvent === 'rating_for_captain_received') {
            ruleToBePushed['values'] = basicInfo.ratings;
          }


          campaignData['rules'].push(ruleToBePushed);
        });

        switch (this.state) {
          case this.sharedService.STATE.VIEW: {
            this.submittingForm = false;
            this.loaderService.closeLoading();
            this.router.navigate(['/campaigns']);
            break;
          }
          case this.sharedService.STATE.DUPLICATE:
          case this.sharedService.STATE.CREATE: {
            if (campaignData['ruleType'] == 'captainGame') {
              if (campaignData['rules'][0]['eventsAndReasons'][0].event == "streak") {
                campaignData['captainGameNotification'] = {
                  "message": "Dear  Captain, Now win ${amount} Rapido coins by doing ${target} rides daily for ${workingDays} days.Click here to know more about the game",
                  "title": "Game Started !"
                }

              } else {
                campaignData['captainGameNotification'] = {
                  "message": "Dear Captain,the Game has started. Keep your Dropped score above ${target} % to get ${amount} Coins",
                  "title": "Game Started !"
                }
              }
              campaignData['transactionMode'] = 'coin'
            }
            this.penaltyRewardCampaignService.createCampaign(campaignData).subscribe(result => {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: 'Successfully created ' +  campaignData['ruleName'] + ' campaign',
              }));
              this.loaderService.closeLoading();
              setTimeout( () => {
                this.submittingForm = false;
                this.router.navigate(['/campaigns']);
              }, 1000);

              this.loaderService.closeLoading();
            }, error => {
              console.log(error);
              this.submittingForm = false;
              this.loaderService.closeLoading();
              this.toasterService.showToaster(new Toaster({
               type: ToasterType.WARNING,
               message: errorMessage(error) || 'Could not Create Campaign',
             }));
            });
            break;
          }
          case this.sharedService.STATE.EDIT: {
            const changedKeys = [];
            let nonEmptyCampaignData;
            for (const key in this.initialRule) {
              if ((this.initialRule[key] && !campaignData[key]) || (!this.initialRule[key] && campaignData[key])) {
                changedKeys.push(key);
              } else if (!this.initialRule[key] && !campaignData[key]) {
                // noop
              } else {
                if (key === 'startDate' || key === 'endDate') {
                  if (!this.isEqualValue(this.setDate(this.initialRule[key]), this.setDate(campaignData[key]))) {
                    changedKeys.push(key);
                  }
                } else if (key === 'rules') {
                  if (this.initialRule[key].length !== campaignData[key].length) {
                    changedKeys.push(key);
                  } else {
                    const nonEmptyInitialRule = this.removeEmpty(_.cloneDeep(this.initialRule[key]));
                    nonEmptyCampaignData = this.removeEmpty(_.cloneDeep(campaignData[key]));
                    if (!isEqual(nonEmptyInitialRule, nonEmptyCampaignData)) {
                      changedKeys.push(key);
                    }
                  }
                } else if (!this.isEqualValue(JSON.stringify(this.initialRule[key]), JSON.stringify(campaignData[key]))) {
                  changedKeys.push(key);
                }
              }
            }
            let finalKeys = [];
            for (const key in changedKeys) {
              if (changedKeys[key] && changedKeys[key]  !== "active") {
                RULE_KEY_MAPPINGS[changedKeys[key]] ? finalKeys.push(RULE_KEY_MAPPINGS[changedKeys[key]]) : '';
              }
            }
            const metadata = {
              changedKeys: finalKeys,
              user: {
                name:  this.user.firstName + ' ' + this.user.lastName,
                email: this.user.email
              }
            };
            this.penaltyRewardCampaignService.editCampaign(campaignData, campaignData['ruleId']).subscribe(result => {
              if (finalKeys.length) {
                this.logService.insertEvent(campaignData['ruleId'], this.initialRule, nonEmptyCampaignData, metadata, this.user._id).subscribe();
              }
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: 'Successfully edited ' +  campaignData['ruleName'] + ' campaign',
              }));
              this.loaderService.closeLoading();
              setTimeout( () => {
                this.submittingForm = false;
                this.router.navigate(['/campaigns']);
              }, 1000);

              this.loaderService.closeLoading();
            }, error => {
              console.log(error);
              this.submittingForm = false;
              this.loaderService.closeLoading();
              this.toasterService.showToaster(new Toaster({
               type: ToasterType.WARNING,
               message: 'Could not edit Campaign',
             }));
            });
            break;
          }
      }
      }
    } else {
      const info = {...this.campaignData};
      info[type] = event;
      this.campaignData = info;
      this.submittingForm = false;
    }
  }

  private mapActionData(ruleToBePushed: any, rule: any) {
    ruleToBePushed.useActionAmount = rule['useActionAmount'];
    ruleToBePushed.actionMax = rule['actionMax'];
    ruleToBePushed.actionMin = rule['actionMin'];
    ruleToBePushed.action = rule['action'];
  }

  isEqualValue(a, b) { return a == b; }

  setDate = (date) => { return moment(date).format('YYYY-MM-DD').toString(); }

  getNotificationTypes = async () => {
    try {
      const response = await this.penaltyRewardCampaignService.getNotifications().toPromise();
      this.notificationsData = response['data']['data'];
      this.createNotificationMapping(response['data']['data']);
    } catch (error) {
      console.log(error);
      this.toasterService.showToaster(
        new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch Notifications`,
        })
      );
    }
  }

  createNotificationMapping( notificationMappings ) {
    this.notificationsData = notificationMappings;
    for (const mapping of notificationMappings) {
      this.notificationMapping[mapping.notifType] = mapping.displayText;
    }
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key =>
      (obj[key] && typeof obj[key] === 'object' && key !== 'timeWindows') && this.removeEmpty(obj[key]) ||
      ((!obj[key] && obj[key] !== undefined && obj[key] !== false) || key === 'timeWindows' || key === 'notificationTitle') && delete obj[key]
    );
    return obj;
  }
}
