import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Config {
  heroesUrl: string;
  textfile: string;
}

@Injectable()
export class SigninService {
  userUrl = `${environment.server}` + '/api/getUser'; 
  loginUrl = `${environment.server}` + '/auth/login';

  constructor(private http: HttpClient) {
  }

  getUserInfo() {
    return this.http.get<any>(this.userUrl);
  }

  login(data: any) {
    return this.http.post<any>(this.loginUrl, { data });
  }
}
