import * as tslib_1 from "tslib";
var _a;
import * as Papa from 'papaparse';
var ADHOC_TYPE = 'Adhoc';
var OTHERS = 'Others';
var pendingSelectorErrorMessage = 'You cannot create an ad-hoc incentive until the user selector is created. Please wait until the user selector status is "COMPLETED".';
var failedSelectorErrorMessage = 'User selector creation has failed. Please retry by creating a user selector and incentive to proceed.';
var pendingSelectorWarnMessage = 'User selector is still not created. The incentive assignment might fail. Please ensure the the user selector is created before assignment.';
var timezone = "Asia/Kolkata";
export var defaultStatusMessage = { type: 'Ok', message: '' };
export var userSelectorStatusMessages = (_a = {},
    _a[ADHOC_TYPE] = {
        FAILED: { type: 'Error', message: failedSelectorErrorMessage },
        INITIATED: { type: 'Error', message: pendingSelectorErrorMessage },
        PROCESSING: { type: 'Error', message: pendingSelectorErrorMessage },
        UNKNOWN: defaultStatusMessage
    },
    _a[OTHERS] = {
        FAILED: { type: 'Error', message: failedSelectorErrorMessage },
        INITIATED: { type: 'Warning', message: pendingSelectorWarnMessage },
        PROCESSING: { type: 'Warning', message: pendingSelectorWarnMessage },
        UNKNOWN: defaultStatusMessage
    },
    _a);
var incentiveTypeMap = {
    'Daily': "Daily",
    'Weekly Fixed': "Weekly",
    'Weekly Selectable': "Weekly",
    'Adhoc': "Daily"
};
var days = [{ day: '', index: 0 },
    { day: 'Monday', index: 1 }, { day: 'Tuesday', index: 2 },
    { day: 'Wednesday', index: 3 }, { day: 'Thursday', index: 4 },
    { day: 'Friday', index: 5 }, { day: 'Saturday', index: 6 },
    { day: 'Sunday', index: 7 }];
export var getEpochFromDate = function (dateString) {
    var dateObject = new Date(dateString + "T00:00:00Z");
    var options = { timeZone: timezone };
    var dateFormatter = new Intl.DateTimeFormat("en-US", options);
    var formattedDate = dateFormatter.format(dateObject);
    var formattedDateObject = new Date(formattedDate);
    var epochTimestamp = formattedDateObject.getTime() / 1000;
    return epochTimestamp;
};
export var getDateFromEpoch = function (epochTimestamp) {
    var epochInMillis = epochTimestamp * 1000;
    var dateObject = new Date(epochInMillis);
    var year = dateObject.getFullYear();
    var month = String(dateObject.getMonth() + 1).padStart(2, '0');
    var day = String(dateObject.getDate()).padStart(2, '0');
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
};
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.timeToTimeStamp = function (time) {
        if (time === void 0) { time = "16:10"; }
        var _a = time.split(":"), min = _a[0], sec = _a[1];
        if (!min || !sec) {
            return "";
        }
        var timeStamp = new Date();
        timeStamp.setHours(parseInt(min));
        timeStamp.setMinutes(parseInt(sec));
        return timeStamp;
    };
    Utils.dateFromEpoch = function (epochTimeInMS) {
        var date = new Date(epochTimeInMS);
        return date.toLocaleString();
    };
    Utils.findIndexOfDay = function (day) {
        for (var _i = 0, days_1 = days; _i < days_1.length; _i++) {
            var dayObj = days_1[_i];
            if (dayObj && dayObj.day && dayObj.day === day.trim()) {
                return dayObj.index;
            }
        }
        return -1;
    };
    Utils.isFinalUserSelectorState = function (userSelectorStatus) {
        return ["COMPLETED", "FAILED"].includes(userSelectorStatus);
    };
    Utils.organizeData = function (type, incentiveData, dailyData, datePipe, variables) {
        var goal = [];
        var order;
        var distance;
        var condition;
        if (incentiveData.goalsInfo) {
            variables = incentiveData.goalsInfo.selectedVariable;
            ;
        }
        if (incentiveData.goalsInfo && incentiveData.goalsInfo.selectedCondition === "||") {
            condition = "or";
        }
        else {
            condition = "and";
        }
        if (variables && variables.indexOf("order") !== -1) {
            order = true;
        }
        if (variables && variables.indexOf("distance") !== -1) {
            distance = true;
        }
        for (var index in dailyData) {
            if (index) {
                var keys = Object.keys(dailyData[index]);
                if (keys.indexOf("startDay") !== -1) {
                    keys.splice(keys.indexOf("startDay"), 1);
                }
                if (keys.indexOf("endDay") !== -1) {
                    keys.splice(keys.indexOf("endDay"), 1);
                }
                if (keys.indexOf("days") !== -1) {
                    keys.splice(keys.indexOf("days"), 1);
                }
                if (keys.indexOf("day") !== -1) {
                    keys.splice(keys.indexOf("day"), 1);
                }
                if (keys.indexOf("disabled") !== -1) {
                    keys.splice(keys.indexOf("disabled"), 1);
                }
                for (var key in keys) {
                    if (key) {
                        var set = { timeSlot: "", rules: "", days: "", qualities: "" };
                        var time = [], rule = [], quality = [];
                        for (var i in dailyData[index][keys[key]].timeSlot) {
                            if (i) {
                                var slab = "";
                                slab +=
                                    datePipe.transform(dailyData[index][keys[key]].timeSlot[i].fromTime, "HH:mm") +
                                        "-" +
                                        datePipe.transform(dailyData[index][keys[key]].timeSlot[i].toTime, "HH:mm");
                                time.push(slab);
                            }
                        }
                        set["timeSlot"] = time.join("\n");
                        set["days"] = dailyData[index].days;
                        for (var i in dailyData[index][keys[key]].rules) {
                            if (i) {
                                var ruleMessage = "";
                                if (order && distance) {
                                    ruleMessage +=
                                        dailyData[index][keys[key]].rules[i].order +
                                            " order(s) " +
                                            condition +
                                            " " +
                                            dailyData[index][keys[key]].rules[i].distance +
                                            " Km = \u20B9 " +
                                            dailyData[index][keys[key]].rules[i].amount;
                                }
                                if (!order && distance) {
                                    ruleMessage +=
                                        dailyData[index][keys[key]].rules[i].distance +
                                            " Km = \u20B9 " +
                                            dailyData[index][keys[key]].rules[i].amount;
                                }
                                if (order && !distance) {
                                    ruleMessage +=
                                        dailyData[index][keys[key]].rules[i].order +
                                            " order(s) = \u20B9 " +
                                            dailyData[index][keys[key]].rules[i].amount;
                                }
                                rule.push(ruleMessage);
                                if (dailyData[index][keys[key]].rules[i].quality !== undefined) {
                                    var qualityMessage = incentiveTypeMap[(type == "preview")
                                        ? incentiveData.basicInfo.incentiveType : incentiveData.incentiveType] +
                                        " " +
                                        dailyData[index][keys[key]].rules[i].quality.metric +
                                        " " +
                                        dailyData[index][keys[key]].rules[i].quality.value +
                                        " % = \u20B9 " +
                                        dailyData[index][keys[key]].rules[i].quality.amount;
                                    quality.push(qualityMessage);
                                }
                            }
                        }
                        set["rules"] = rule.join("\n");
                        set["qualities"] = quality.join("\n");
                        goal.push(set);
                    }
                }
            }
        }
        return goal;
    };
    Utils.fetchUserSelectorStatus = function (type, userSelectorId, incentiveData, userSelectorStatus, sharedService) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleId, status_1, response, intcentiveType, statusMessages, statusMessage, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ruleId = (type == "preview") ? incentiveData.basicInfo.ruleId : incentiveData.ruleId;
                        if (!ruleId) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        status_1 = userSelectorStatus.status;
                        if (!(ruleId != userSelectorId ||
                            !this.isFinalUserSelectorState(userSelectorStatus.status))) return [3 /*break*/, 3];
                        return [4 /*yield*/, sharedService
                                .getUserSelectorDetails(ruleId)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        status_1 = response.status;
                        userSelectorId = ruleId;
                        _a.label = 3;
                    case 3:
                        intcentiveType = (type == "preview")
                            ? incentiveData.basicInfo.incentiveType : incentiveData.incentiveType;
                        statusMessages = userSelectorStatusMessages[intcentiveType == ADHOC_TYPE ? ADHOC_TYPE : OTHERS];
                        statusMessage = statusMessages[status_1] || defaultStatusMessage;
                        userSelectorStatus = tslib_1.__assign({ status: status_1 }, statusMessage);
                        return [2 /*return*/, { userSelectorStatus: userSelectorStatus, userSelectorId: userSelectorId }];
                    case 4:
                        error_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Utils.downloadCSVFile = function (options) {
        var fields = options.fields, data = options.data, fileName = options.fileName;
        var csv = Papa.unparse({ data: data, fields: fields });
        var blob = new Blob([csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
    };
    return Utils;
}());
export default Utils;
