import { MyHttpClient } from '../http-client';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client";
var ViewIncentivesService = /** @class */ (function () {
    function ViewIncentivesService(http) {
        this.http = http;
        this.fetchIncentives = environment.server + '/api/get/incentives/v2'; // make this configurable
    }
    ViewIncentivesService.prototype.fetchIncentiveData = function (payload) {
        // const payload = {
        //   "pagination":{
        //       "pageNo":1,
        //       "pageSize":10
        //   },
        //   "filter":{
        //       "cities":[
        //         "Bangalore"
        //       ],
        //       "serviceType":[
        //         "57370b61a6855d70057417d1"
        //       ],
        //       "types":[
        //         "Daily"
        //       ],
        //       "startDate":"2019-07-03",
        //       "endDate":"2019-07-11",
        //       "status":""
        //   }
        // };
        // payload.filter = filter;
        return this.http.post(this.fetchIncentives, payload)
            .pipe(map(function (res) { return res.data || res; }))
            .pipe(catchError(this.handleError));
    };
    ViewIncentivesService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
        // return {};
    };
    ViewIncentivesService.ngInjectableDef = i0.defineInjectable({ factory: function ViewIncentivesService_Factory() { return new ViewIncentivesService(i0.inject(i1.MyHttpClient)); }, token: ViewIncentivesService, providedIn: "root" });
    return ViewIncentivesService;
}());
export { ViewIncentivesService };
