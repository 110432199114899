import { SigninService } from './signin.service';
import { Router } from "@angular/router";
import { ToastMessage } from '../toast-message/toast-message.service';
import { AfterViewInit, Component , NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';

declare var google: any;

@Component({
  selector: 'signin',
  templateUrl: 'signin.component.html',
  providers: [SigninService, ToastMessage]
})
export class SigninComponent implements AfterViewInit {
  constructor(private router: Router,
    private signInService: SigninService,
    private ngZone: NgZone,) {
  }

  ngAfterViewInit(): void {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (response: any) => this.handleGoogleSignIn(response)
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { size: "large", shape: "rectangular", theme: "outline", text: "continue_with", width: '300'}
    );
  }

  async handleGoogleSignIn(response: any) {
    let userInfo = HelperService.deocodeJwtPayload(response.credential);
    //call backend to sign in token
    const tokenResponse = await this.signInService.login(userInfo).toPromise()
    localStorage.setItem('token', tokenResponse.token);
    localStorage.setItem('permission', tokenResponse.permissions);
    this.ngZone.run(() => {
      this.router.navigate(['/incentives']);
    });
  }
}
