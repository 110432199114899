import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import { LoaderService } from '../../loader.service';
import { ToasterService } from "../../toaster.service";
import { Toaster, ToasterType } from "src/app/shared/types/toaster.types";
import { PenaltyRewardCampaignService } from "../penalty-reward-campaign.service";
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { ACTIONS, defaultLocale, supportedLocales } from '../constants';
var PenaltyRuleComponent = /** @class */ (function () {
    function PenaltyRuleComponent(fb, sharedService, toasterService, loaderService, penaltyRewardCampaignService, dialogService) {
        var _this = this;
        this.fb = fb;
        this.sharedService = sharedService;
        this.toasterService = toasterService;
        this.loaderService = loaderService;
        this.penaltyRewardCampaignService = penaltyRewardCampaignService;
        this.dialogService = dialogService;
        this.rulesChange = new EventEmitter();
        this.back = new EventEmitter();
        this.showPopUp = false;
        this.useActionAmount = false;
        this.actionMax = false;
        this.actionMin = false;
        this.showOldRule = false;
        this.defaultLocale = defaultLocale;
        this.supportedLocales = supportedLocales;
        this.rules = this.fb.array([this.getEmptyRule()]);
        this.penaltyRulesForm = this.fb.group({ rules: this.rules });
        this.oldRules = this.fb.array([this.getOldEmptyRule()]);
        this.oldPenaltyRulesForm = this.fb.group({ oldRules: this.oldRules });
        this.blockRedeemEligible = false;
        this.captainTask = [];
        this.campaignId = [];
        this.whatsappTemplates = [];
        this.getActions = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.penaltyRewardCampaignService.fetchActions().toPromise()];
                    case 1:
                        response = _a.sent();
                        this.actionOptions = response['data']['data'];
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Unable to fetch Actions",
                        }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getCaptainTasks = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response_1, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.penaltyRewardCampaignService.fetchCaptainTasks().toPromise()];
                    case 1:
                        response_1 = _a.sent();
                        Object.keys(response_1['data']).forEach(function (key) { return _this.captainTask.push(response_1['data'][key]); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Unable to fetch captain tasks",
                        }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getGalaxyCampaigns = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response_2, error_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.penaltyRewardCampaignService.fetchGalaxyCampaigns().toPromise()];
                    case 1:
                        response_2 = _a.sent();
                        Object.keys(response_2['data']).forEach(function (key) { return _this.campaignId.push(response_2['data'][key]); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Unable to fetch galaxy campaigns",
                        }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getActions();
        this.getCaptainTasks();
        this.getGalaxyCampaigns();
    }
    PenaltyRuleComponent.prototype.getEmptyRule = function () {
        return this.fb.group({
            action: null,
            blockRedeem: false,
            startLimit: null,
            endLimit: null,
            workingDays: null,
            adjustmentType: null,
            amount: null,
            prioritizeAfterMins: null,
            activateAfterHours: null,
            actionMax: false,
            useActionAmount: false,
            actionMin: false,
            notificationDisplay: null,
            _id: null,
            communications: this.fb.array([this.getEmptySet()]),
            captainTask: null,
            showCaptainTask: false,
            campaignId: null,
        });
    };
    PenaltyRuleComponent.prototype.getOldEmptyRule = function () {
        return this.fb.group({
            action: null,
            blockRedeem: false,
            message: null,
            startLimit: null,
            endLimit: null,
            workingDays: null,
            adjustmentType: null,
            amount: null,
            activateAfterHours: null,
            prioritizeAfterMins: null,
            notification: null,
            notificationDisplay: null,
            _id: null
        });
    };
    PenaltyRuleComponent.prototype.checkTitleAndMessageForLocale = function (locale, formGroup) {
        return formGroup.controls.titleLocales.value[locale.code].value && formGroup.controls.messageLocales.value[locale.code].value;
    };
    PenaltyRuleComponent.prototype.getTitleFormControl = function (ruleFormGroup) {
        return ruleFormGroup.controls.titleLocales.value[ruleFormGroup.controls.currentSelectedLocale.value.code];
    };
    PenaltyRuleComponent.prototype.getMessageFormControl = function (ruleFormGroup) {
        return ruleFormGroup.controls.messageLocales.value[ruleFormGroup.controls.currentSelectedLocale.value.code];
    };
    PenaltyRuleComponent.prototype.getEmptySet = function () {
        var _this = this;
        return this.fb.group({
            type: null,
            templateId: null,
            titleLocales: this.supportedLocales.reduce(function (acc, locale) {
                var _a;
                return (tslib_1.__assign({}, acc, (_a = {}, _a[locale.code] = _this.fb.control(null), _a)));
            }, {}),
            messageLocales: this.supportedLocales.reduce(function (acc, locale) {
                var _a;
                return (tslib_1.__assign({}, acc, (_a = {}, _a[locale.code] = _this.fb.control(null), _a)));
            }, {}),
            currentSelectedLocale: this.fb.control(this.defaultLocale),
        });
    };
    PenaltyRuleComponent.prototype.ngOnInit = function () {
        this.notificationsData = this.defaultValue.notificationsData;
        this.whatsappTemplates = this.notificationsData && this.notificationsData.filter(function (notification) { return notification.notifType === 'WHATSAPP'; })[0].templates;
        this.notificationMapping = this.defaultValue.notificationMapping;
        switch (this.state) {
            case this.sharedService.STATE.VIEW:
            case this.sharedService.STATE.EDIT:
            case this.sharedService.STATE.DUPLICATE: {
                if (this.defaultValue.rules[0] && this.defaultValue.rules[0].communications && this.defaultValue.rules[0].communications.length !== 0) {
                    this.showOldRule = false;
                }
                else {
                    this.showOldRule = true;
                }
                this.patchDefaultValues(this.defaultValue.rules);
                break;
            }
        }
    };
    PenaltyRuleComponent.prototype.ngOnChanges = function (changes) {
        if (changes.campaignData.currentValue.basicInfo) {
            this.campaignType = changes.campaignData.currentValue.basicInfo.ruleType;
            this.gameTriggerEvent = changes.campaignData.currentValue.basicInfo.eventsAndReasons[0].event;
            this.blockRedeemEligible = changes.campaignData.currentValue.basicInfo.blockRedeemEligibility;
            if (this.isGame()) {
                this.actionOptions = ["reward"];
            }
            else {
                this.getActions();
            }
        }
    };
    PenaltyRuleComponent.prototype.addRule = function () {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.rules.push(this.getEmptyRule());
    };
    PenaltyRuleComponent.prototype.addOldRule = function () {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.oldRules.push(this.getOldEmptyRule());
    };
    PenaltyRuleComponent.prototype.deleteRule = function (index) {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.rules.controls.splice(index, 1);
        this.rules.value.splice(index, 1);
        if (!this.rules.controls.length) {
            this.addRule();
        }
    };
    PenaltyRuleComponent.prototype.deleteOldRule = function (index) {
        if (this.state === this.sharedService.STATE.VIEW) {
            return;
        }
        this.oldRules.controls.splice(index, 1);
        this.oldRules.value.splice(index, 1);
        if (!this.oldRules.controls.length) {
            this.addOldRule();
        }
    };
    PenaltyRuleComponent.prototype.deleteSet = function (index, setIndex) {
        if (this.state === this.sharedService.STATE.VIEW || setIndex == 0) {
            return;
        }
        this.rules.controls[index].controls.communications.controls.splice(setIndex, 1);
        this.rules.value[index].communications.splice(setIndex, 1);
    };
    PenaltyRuleComponent.prototype.toggleActionMax = function (index) {
        this.penaltyRulesForm.controls.rules.controls[index].controls.actionMax.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.actionMax.value);
    };
    PenaltyRuleComponent.prototype.toggleActionAmount = function (index) {
        this.penaltyRulesForm.controls.rules.controls[index].controls.useActionAmount.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.useActionAmount.value);
    };
    PenaltyRuleComponent.prototype.toggleActionMin = function (index) {
        this.penaltyRulesForm.controls.rules.controls[index].controls.actionMin.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.actionMin.value);
    };
    PenaltyRuleComponent.prototype.makeFieldsDisable = function () {
        this.oldPenaltyRulesForm.get('oldRules').disable();
    };
    PenaltyRuleComponent.prototype.patchDefaultValues = function (defaultRules) {
        var _this = this;
        if (this.showOldRule) {
            defaultRules.forEach(function (defaultRule, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var amount, ruleControls;
                return tslib_1.__generator(this, function (_a) {
                    if (!this.oldRules.controls[index]) {
                        this.oldRules.push(this.getOldEmptyRule());
                    }
                    ruleControls = this.oldPenaltyRulesForm.controls.oldRules['controls'][index].controls;
                    if (defaultRule.action === "notification") {
                        ruleControls.action.value = ACTIONS.WARNING;
                    }
                    else if (defaultRule.action === "suspend") {
                        if (defaultRule.activateAfterHours) {
                            ruleControls.action.value = ACTIONS.SUSPEND;
                            ruleControls.activateAfterHours.value = defaultRule.activateAfterHours;
                        }
                        else {
                            ruleControls.action.value = ACTIONS.DEACTIVATION;
                        }
                    }
                    else if (defaultRule.action === ACTIONS.DEPRIORITIZE) {
                        ruleControls.action.value = ACTIONS.DEPRIORITIZE;
                        if (defaultRule.prioritizeAfterMins) {
                            ruleControls.prioritizeAfterMins.value = defaultRule.prioritizeAfterMins;
                        }
                    }
                    else {
                        ruleControls.action.value = defaultRule.action;
                    }
                    ruleControls.message.value = defaultRule.displayReason;
                    ruleControls.startLimit.value = defaultRule.limit;
                    ruleControls.workingDays.value = defaultRule.workingDays || null;
                    if (defaultRule.applicableTill) {
                        ruleControls.endLimit.value = defaultRule.applicableTill;
                    }
                    if (defaultRule.amount) {
                        if (defaultRule.action === ACTIONS.ADJUSTMENT) {
                            ruleControls.adjustmentType.value = defaultRule.adjustmentType;
                        }
                        if (defaultRule.adjustmentType && defaultRule.adjustmentType === "debit") {
                            amount = -1 * defaultRule.amount;
                            ruleControls.amount.value = amount;
                        }
                        else {
                            ruleControls.amount.value = defaultRule.amount;
                        }
                    }
                    if (this.state === this.sharedService.STATE.VIEW) {
                        ruleControls.notification.value = this.notificationMapping[defaultRule.notificationType];
                    }
                    if (this.state === this.sharedService.STATE.DUPLICATE || this.state === this.sharedService.STATE.EDIT) {
                        ruleControls.notification.value = defaultRule.notificationType;
                    }
                    if (this.state === this.sharedService.STATE.EDIT) {
                        ruleControls._id.value = defaultRule._id;
                    }
                    if (defaultRule.hasOwnProperty('blockRedeem')) {
                        ruleControls.blockRedeem.value = defaultRule.blockRedeem;
                    }
                    return [2 /*return*/];
                });
            }); });
            if (this.state === this.sharedService.STATE.VIEW) {
                this.makeFieldsDisable();
            }
        }
        else {
            defaultRules.forEach(function (defaultRule, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var amount, ruleControls;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    if (!this.penaltyRulesForm.controls.rules.controls[index]) {
                        this.penaltyRulesForm.controls.rules.controls.push(this.getEmptyRule());
                    }
                    defaultRule.communications.forEach(function (rule, j) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var translations, locale;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!this.penaltyRulesForm.controls.rules.controls[index].controls.communications.controls[j]) {
                                        this.penaltyRulesForm.controls.rules.controls[index].controls.communications.controls.push(this.getEmptySet());
                                    }
                                    ruleControls = this.penaltyRulesForm.controls.rules.controls[index].controls;
                                    if (defaultRule.action === "notification") {
                                        ruleControls.action.value = ACTIONS.WARNING;
                                    }
                                    else if (defaultRule.action === "penalty" || defaultRule.action === "adjustment" || defaultRule.action === "reward") {
                                        setActionFlags(defaultRule, ruleControls);
                                        ruleControls.action.value = defaultRule.action;
                                    }
                                    else if (defaultRule.action === ACTIONS.SUSPEND) {
                                        if (defaultRule.activateAfterHours) {
                                            ruleControls.action.value = ACTIONS.SUSPEND;
                                            ruleControls.activateAfterHours.value = defaultRule.activateAfterHours;
                                        }
                                        else {
                                            ruleControls.action.value = ACTIONS.DEACTIVATION;
                                        }
                                        if (defaultRule.captainTask && defaultRule.captainTask.length) {
                                            ruleControls.captainTask.value = defaultRule.captainTask;
                                            ruleControls.showCaptainTask.value = true;
                                        }
                                    }
                                    else if (defaultRule.action === ACTIONS.DEPRIORITIZE) {
                                        ruleControls.action.value = ACTIONS.DEPRIORITIZE;
                                        if (defaultRule.prioritizeAfterMins) {
                                            ruleControls.prioritizeAfterMins.value = defaultRule.prioritizeAfterMins;
                                        }
                                    }
                                    else {
                                        ruleControls.action.value = defaultRule.action;
                                        if (defaultRule.campaignId && defaultRule.campaignId.length) {
                                            ruleControls.campaignId.value = defaultRule.campaignId;
                                        }
                                    }
                                    if (rule.message && rule.title) {
                                        ruleControls.communications.controls[j].controls.messageLocales.value[this.defaultLocale.code].value = rule.message;
                                        ruleControls.communications.controls[j].controls.titleLocales.value[this.defaultLocale.code].value = rule.title;
                                    }
                                    ruleControls.communications.controls[j].controls.type.value = rule.type;
                                    if (this.state == this.sharedService.STATE.VIEW || this.whatsappTemplates.includes(rule.templateId)) {
                                        ruleControls.communications.controls[j].controls.templateId.value = rule.templateId;
                                    }
                                    ruleControls.startLimit.value = defaultRule.limit;
                                    ruleControls.workingDays.value = defaultRule.workingDays || null;
                                    if (defaultRule.applicableTill) {
                                        ruleControls.endLimit.value = defaultRule.applicableTill;
                                    }
                                    if (defaultRule.amount || defaultRule.amount == 0) {
                                        if (rule.action === ACTIONS.ADJUSTMENT) {
                                            ruleControls.adjustmentType.value = defaultRule.adjustmentType;
                                        }
                                        if (defaultRule.adjustmentType && defaultRule.adjustmentType === "debit") {
                                            amount = -1 * defaultRule.amount;
                                            ruleControls.amount.value = amount;
                                        }
                                        else {
                                            ruleControls.amount.value = defaultRule.amount;
                                        }
                                    }
                                    if (this.state === this.sharedService.STATE.EDIT) {
                                        ruleControls._id.value = defaultRule._id;
                                    }
                                    if (defaultRule.hasOwnProperty('blockRedeem')) {
                                        ruleControls.blockRedeem.value = defaultRule.blockRedeem;
                                    }
                                    if (!(rule.titleLocaleKey && rule.messageLocaleKey)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.sharedService.fetchTranslations(this.supportedLocales.map(function (locale) { return locale.code; }), [encodeURIComponent(rule.titleLocaleKey), encodeURIComponent(rule.messageLocaleKey)]).toPromise()];
                                case 1:
                                    translations = _a.sent();
                                    for (locale in translations) {
                                        if (translations[locale][rule.titleLocaleKey]) {
                                            ruleControls.communications.controls[j].controls.titleLocales.value[locale].setValue(translations[locale][rule.titleLocaleKey]);
                                        }
                                        if (translations[locale][rule.messageLocaleKey]) {
                                            ruleControls.communications.controls[j].controls.messageLocales.value[locale].setValue(translations[locale][rule.messageLocaleKey]);
                                        }
                                    }
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                    return [2 /*return*/];
                });
            }); });
            if (this.state === this.sharedService.STATE.VIEW) {
                this.penaltyRulesForm.get('rules').disable();
                this.penaltyRulesForm.get('rules').controls.forEach(function (rule) {
                    rule.controls.communications.controls.forEach(function (set) {
                        Object.entries(set.controls.titleLocales.value).forEach(function (_a) {
                            var _ = _a[0], value = _a[1];
                            value.disable();
                        });
                        Object.entries(set.controls.messageLocales.value).forEach(function (_a) {
                            var _ = _a[0], value = _a[1];
                            value.disable();
                        });
                        set.controls.currentSelectedLocale.enable();
                    });
                });
            }
        }
        function setActionFlags(defaultRule, ruleControls) {
            if (defaultRule.useActionAmount)
                ruleControls.useActionAmount.value = true;
            else
                ruleControls.useActionAmount.value = false;
            if (defaultRule.actionMax)
                ruleControls.actionMax.value = true;
            else
                ruleControls.actionMax.value = false;
            if (defaultRule.actionMin)
                ruleControls.actionMin.value = true;
            else
                ruleControls.actionMin.value = false;
        }
    };
    PenaltyRuleComponent.prototype.toggleShowCaptainTask = function (index) {
        if (!this.penaltyRulesForm.controls.rules.at(index).get('showCaptainTask').value) {
            this.penaltyRulesForm.controls.rules.at(index).get('captainTask').setValue(null);
        }
    };
    PenaltyRuleComponent.prototype.submit = function () {
        if (this.organiseData()) {
            if (this.showOldRule) {
                this.rulesChange.emit(this.oldPenaltyRulesForm.value);
            }
            else {
                var data_1 = [];
                this.penaltyRulesForm.controls.rules.controls.map(function (penaltyRule, index) {
                    data_1.push(penaltyRule.getRawValue());
                    data_1[index].communications = [];
                    penaltyRule.controls.communications.controls.map(function (set) {
                        var setValue = set.getRawValue();
                        data_1[index].communications.push(tslib_1.__assign({}, setValue, { titleLocales: Object.keys(setValue.titleLocales).reduce(function (acc, key) {
                                if (setValue.titleLocales[key].value) {
                                    acc[key] = setValue.titleLocales[key].value;
                                }
                                return acc;
                            }, {}), messageLocales: Object.keys(setValue.messageLocales).reduce(function (acc, key) {
                                if (setValue.messageLocales[key].value) {
                                    acc[key] = setValue.messageLocales[key].value;
                                }
                                return acc;
                            }, {}) }));
                    });
                });
                this.rulesChange.emit(data_1);
            }
        }
    };
    PenaltyRuleComponent.prototype.goBack = function () {
        this.back.emit();
    };
    PenaltyRuleComponent.prototype.getValue = function (property) {
        if (this.showOldRule) {
            return this.oldPenaltyRulesForm.get(property).value;
        }
        return (this.penaltyRulesForm.get(property).value);
    };
    PenaltyRuleComponent.prototype.organiseData = function () {
        var _this = this;
        var isValid = true;
        var negativeRules = [ACTIONS.PENALTY, ACTIONS.DEPRIORITIZE, ACTIONS.SUSPEND, ACTIONS.PUSH_TO_AGENT];
        var positiveRulesCount = 0;
        var negativeRulesCount = 0;
        var isSuspend = false;
        var isDeprioritize = false;
        var overlapChecker = new Map();
        if (this.showOldRule) {
            var rules = this.getValue('oldRules');
            rules.forEach(function (rule, _index) {
                var _a, _b;
                var message = '';
                message = _this.oldRuleValidation(rule, message, _index);
                if (message.length) {
                    _this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                    isValid = false;
                    return;
                }
                rule.notificationDisplay = _this.notificationMapping[rule.notification] || rule.notification;
                checkRuleCategory(rule.action);
                if (positiveRulesCount > 0 && negativeRulesCount > 0) {
                    (_a = _this.fetchCoflictingRuleError(), message = _a.message, isValid = _a.isValid);
                }
                if (isSuspend && isDeprioritize) {
                    (_b = _this.fetchNegativeRuleError(), message = _b.message, isValid = _b.isValid);
                }
                var currentLimits = { startLimit: rule.startLimit, endLimit: rule.endLimit };
                _this.collectRuleLimits(overlapChecker, rule.action, currentLimits);
            });
            var _a = this.checkOverlap(overlapChecker), isOverLapped = _a.isOverLapped, overlappedAction = _a.overlappedAction;
            if (isOverLapped) {
                isValid = this.overLapError(overlappedAction);
                return;
            }
            this.oldRules.setValue(rules);
            if (!isValid) {
                return isValid;
            }
            if (!this.isGame()) {
                for (var i = 1; i <= rules.length; i++) {
                    var message = '';
                    if (rules[i - 1].startLimit > rules[i - 1].endLimit) {
                        message = "Start Limit is greater than End Limit for rule " + i;
                    }
                    if (message.length) {
                        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                        isValid = false;
                        return;
                    }
                }
            }
            return isValid;
        }
        else {
            var rules = this.penaltyRulesForm.controls.rules.controls;
            rules.forEach(function (rule, _index) {
                var _a, _b, _c;
                var message = '';
                message = _this.newRuleValidation(rule, message, _index);
                if (message.length) {
                    _this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                    isValid = false;
                    return;
                }
                (_a = _this.commsValidation(rule, message, _index, isValid), message = _a.message, isValid = _a.isValid);
                checkRuleCategory(rule.value.action);
                if (positiveRulesCount > 0 && negativeRulesCount > 0) {
                    (_b = _this.fetchCoflictingRuleError(), message = _b.message, isValid = _b.isValid);
                }
                if (isSuspend && isDeprioritize) {
                    (_c = _this.fetchNegativeRuleError(), message = _c.message, isValid = _c.isValid);
                }
                var currentLimits = { startLimit: rule.value.startLimit, endLimit: rule.value.endLimit };
                _this.collectRuleLimits(overlapChecker, rule.value.action, currentLimits);
            });
            var _b = this.checkOverlap(overlapChecker), isOverLapped = _b.isOverLapped, overlappedAction = _b.overlappedAction;
            if (isOverLapped) {
                isValid = this.overLapError(overlappedAction);
                return;
            }
            if (!isValid) {
                return isValid;
            }
            if (!this.isGame()) {
                for (var i = 1; i <= rules.length; i++) {
                    var message = '';
                    if (rules[i - 1].value.startLimit > rules[i - 1].value.endLimit) {
                        message = "Start Limit is greater than End Limit for rule " + i;
                    }
                    if (message.length) {
                        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                        isValid = false;
                        return;
                    }
                }
            }
            return isValid;
        }
        function checkRuleCategory(action) {
            if (action == ACTIONS.DEPRIORITIZE) {
                isDeprioritize = true;
            }
            if (action == "suspend") {
                isSuspend = true;
            }
            if (action === "reward") {
                positiveRulesCount++;
            }
            ;
            if (negativeRules.find(function (negativeAction) { return action === negativeAction; })) {
                negativeRulesCount++;
            }
            ;
        }
    };
    PenaltyRuleComponent.prototype.fetchCoflictingRuleError = function () {
        var message = "You should not club positive and negative rules in the same campaign";
        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
        return { message: message, isValid: false };
    };
    PenaltyRuleComponent.prototype.fetchNegativeRuleError = function () {
        var message = "You should not club both suspend and deprioritize rules in the same campaign";
        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
        return { message: message, isValid: false };
    };
    PenaltyRuleComponent.prototype.collectRuleLimits = function (overlapChecker, action, currentLimits) {
        if (!overlapChecker.get(action)) {
            overlapChecker.set(action, [currentLimits]);
        }
        else {
            var totalLimits = overlapChecker.get(action);
            totalLimits.push(currentLimits);
            overlapChecker.set(action, totalLimits);
        }
    };
    PenaltyRuleComponent.prototype.overLapError = function (overlappedAction) {
        var message = "You should not have overlapping rules for the " + overlappedAction + " action in the campaign";
        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
        return false;
    };
    PenaltyRuleComponent.prototype.commsValidation = function (rule, message, _index, isValid) {
        var _this = this;
        if (this.state === this.sharedService.STATE.VIEW) {
            return { message: message, isValid: isValid };
        }
        rule.controls.communications.controls.forEach(function (control, index) {
            if (control.value.type === "IVR") {
                return;
            }
            if (control.value.type === "WHATSAPP") {
                if (!control.value.templateId) {
                    message = "Please Select a Whatsapp Template for Rule #" + (_index + 1) + " Set #" + (index + 1);
                    isValid = false;
                    _this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                }
                return { message: message, isValid: isValid };
            }
            if (!control.value.titleLocales[_this.defaultLocale.code].value) {
                message = "Please Enter Title in " + _this.defaultLocale.label + " for Rule #" + (_index + 1) + " Set #" + (index + 1);
            }
            if (!control.value.messageLocales[_this.defaultLocale.code].value) {
                message = "Please Enter Message in " + _this.defaultLocale.label + " for Rule #" + (_index + 1) + " Set #" + (index + 1);
            }
            var _loop_1 = function (locale) {
                if (control.value.titleLocales[locale].value) {
                    if (!control.value.messageLocales[locale].value) {
                        message = "Please Enter Message in " + _this.supportedLocales.find(function (l) { return l.code == locale; }).label + " for Rule #" + (_index + 1) + " Set #" + (index + 1);
                    }
                    else if (control.value.messageLocales[locale].value && control.value.messageLocales[locale].length > 135) {
                        message = "Message length should be less than or equal to 135 for  Rule #" + (_index + 1) + " Set #" + (index + 1) + ".";
                    }
                }
                if (control.value.messageLocales[locale].value && !control.value.titleLocales[locale].value) {
                    message = "Please Enter Title in " + _this.supportedLocales.find(function (l) { return l.code == locale; }).label + " for Rule #" + (_index + 1) + " Set #" + (index + 1);
                }
            };
            for (var locale in control.value.titleLocales) {
                _loop_1(locale);
            }
            if (!control.value.type) {
                message = "Please Select Notification Type for  Rule #" + (_index + 1) + " Set #" + (index + 1);
            }
            if (message.length) {
                _this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message: message }));
                isValid = false;
                return;
            }
            rule.value.notificationDisplay = _this.notificationMapping[control.value.notification] || control.value.notification;
        });
        return { message: message, isValid: isValid };
    };
    PenaltyRuleComponent.prototype.checkOverlap = function (overlapChecker) {
        var isOverLapped = false;
        var overlappedAction = '';
        overlapChecker.forEach(function (value, key) {
            value.sort(function (i1, i2) {
                return i1.startLimit - i2.startLimit;
            });
            for (var i = 1; i < value.length; i++) {
                if (value[i - 1].endLimit >= value[i].startLimit) {
                    isOverLapped = true;
                    overlappedAction = key;
                    break;
                }
            }
        });
        return { isOverLapped: isOverLapped, overlappedAction: overlappedAction };
    };
    PenaltyRuleComponent.prototype.newRuleValidation = function (rule, message, _index) {
        if (this.state === this.sharedService.STATE.VIEW) {
            return message;
        }
        if (!rule.value.action) {
            message = "Please Select an Action for Rule #" + (_index + 1);
        }
        else if (rule.value.action === ACTIONS.SUSPEND && !rule.value.activateAfterHours) {
            message = "Please Enter suspension hrs for Rule #" + (_index + 1);
        }
        else if (rule.value.action === ACTIONS.DEPRIORITIZE && !rule.value.prioritizeAfterMins) {
            message = "Please Enter prioritize after mins for Rule #" + (_index + 1);
        }
        else if (rule.value.action == ACTIONS.ADJUSTMENT && !rule.value.amount) {
            message = "Please Enter Amount for Rule #" + (_index + 1);
        }
        else if (!(typeof rule.value.startLimit === 'number' && rule.value.startLimit >= 0.00001)) {
            message = "Please Enter Start Limit for Rule #" + (_index + 1);
        }
        else if (rule.value.endLimit && rule.value.endLimit < 1) {
            message = "Please Check End Limit for Rule #" + (_index + 1);
        }
        else if (rule.value.endLimit && rule.value.endLimit < rule.value.startLimit) {
            message = "Please Ensure Start Limit is less than End Limit for Rule #" + (_index + 1);
        }
        else if (rule.value.blockRedeem && typeof rule.value.amount !== 'number') {
            message = "Please fill the Block redeem amount for Rule #" + (_index + 1);
        }
        else if (rule.value.action === ACTIONS.REWARD) {
            if (!rule.value.amount) {
                message = "Please Enter Amount for Rule #" + (_index + 1);
            }
            else if (rule.value.amount < 1) {
                message = "Please Enter Valid Amount for Rule #" + (_index + 1);
            }
        }
        else if (rule.value.action === ACTIONS.PENALTY) {
            if (rule.value.amount < 0) {
                message = "Please Enter Valid Amount for Rule #" + (_index + 1);
            }
        }
        else if (rule.value.action === ACTIONS.SUSPEND && rule.value.showCaptainTask) {
            if (!rule.value.captainTask || !rule.value.captainTask.length) {
                message = "Please select captain task for #" + (_index + 1);
            }
        }
        else if (rule.value.action === ACTIONS.PUSH_TO_AGENT && rule.value.communications.some(function (communication) { return communication.type === "IVR"; })) {
            if (!rule.value.campaignId || !rule.value.campaignId.length) {
                message = "Please select galaxy campaign for Rule #" + (_index + 1);
            }
        }
        if (rule.value.action === ACTIONS.PENALTY || rule.value.action === ACTIONS.REWARD || rule.value.action === ACTIONS.ADJUSTMENT) {
            if (rule.value.useActionAmount && ((rule.value.actionMin && rule.value.actionMax) || (!rule.value.actionMin && !rule.value.actionMax))) {
                message = "Please select either min or max for use of action amount for Rule #" + (_index + 1);
            }
        }
        return message;
    };
    PenaltyRuleComponent.prototype.oldRuleValidation = function (rule, message, _index) {
        if (!rule.action) {
            message = "Please Select an Action for Rule #" + (_index + 1);
        }
        else if (rule.action === ACTIONS.SUSPEND && !rule.activateAfterHours) {
            message = "Please Enter suspension hrs for Rule #" + (_index + 1);
        }
        else if (rule.action === ACTIONS.DEPRIORITIZE && !rule.prioritizeAfterMins) {
            message = "Please Enter prioritize after mins for Rule #" + (_index + 1);
        }
        else if (rule.action == ACTIONS.ADJUSTMENT && !rule.amount) {
            message = "Please Enter Amount for Rule #" + (_index + 1);
        }
        else if (!rule.message) {
            message = "Please Enter Message for Rule #" + (_index + 1);
        }
        else if (rule.message.length > 135) {
            message = "Message length should be less than or equal to 135 for Rule #" + (_index + 1) + ".";
        }
        else if (!(typeof rule.startLimit === 'number' && rule.startLimit >= 0.00001)) {
            message = "Please Enter Start Limit for Rule #" + (_index + 1);
        }
        else if (rule.endLimit && rule.endLimit < 1) {
            message = "Please Check End Limit for Rule #" + (_index + 1);
        }
        else if (rule.endLimit && rule.endLimit < rule.startLimit) {
            message = "Please Ensure Start Limit is less than End Limit for Rule #" + (_index + 1);
        }
        else if (rule.blockRedeem && typeof rule.amount !== 'number') {
            message = "Please fill the Block redeem amount for Rule #" + (_index + 1);
        }
        else if (!rule.notification) {
            message = "Please Select Notification Type for Rule #" + (_index + 1);
        }
        else if (rule.action === ACTIONS.REWARD) {
            if (!rule.amount) {
                message = "Please Enter Amount for Rule #" + (_index + 1);
            }
            else if (rule.amount < 1) {
                message = "Please Enter Valid Amount for Rule #" + (_index + 1);
            }
        }
        else if (rule.value.action === ACTIONS.PENALTY) {
            if (rule.value.amount < 0) {
                message = "Please Enter Valid Amount for Rule #" + (_index + 1);
            }
        }
        return message;
    };
    PenaltyRuleComponent.prototype.notificationConfirm = function (event, formControl) {
        if (event && event === 'OTT') {
            var options = {
                title: 'Are you sure Over The Top communication?',
                message: 'By using Over The Top communication, the users screen will get covered from the current screen. Tip: Advised to use in important communication.',
                cancelText: 'Cancel',
                confirmText: 'Yes, Proceed'
            };
            this.dialogService.open(options);
            this.dialogService.confirmed().subscribe(function (confirmed) {
                if (confirmed) {
                    return;
                }
                else {
                    //this.penaltyRulesForm.controls.rules['controls'][index].controls.notification.patchValue("");
                }
            });
        }
        if (event && event != 'WHATSAPP') {
            formControl.controls.templateId.patchValue(null);
        }
    };
    PenaltyRuleComponent.prototype.isGame = function () {
        return this.campaignType == "captainGame";
    };
    PenaltyRuleComponent.prototype.isPenaltyAndReward = function () {
        return this.campaignType == "penaltyAndReward";
    };
    PenaltyRuleComponent.prototype.isStreakGame = function () {
        return this.gameTriggerEvent == "streak";
    };
    PenaltyRuleComponent.prototype.showBlockRedeem = function (currentRule) {
        var currentAction = currentRule.get('action').value;
        if (!currentAction) {
            return false;
        }
        var showCheckBox = this.blockRedeemEligible && currentAction === 'suspend';
        if (this.state !== 'view' && this.state !== 'edit' && this.state !== 'duplicate' && !showCheckBox && currentAction === 'suspend') {
            // currentRule.controls['amount'].setValue(null);
            // currentRule.controls['blockRedeem'].setValue(false);
        }
        return showCheckBox;
    };
    PenaltyRuleComponent.prototype.onBlockRedeemChange = function (currentRule) {
        var currentBlockRedeem = currentRule.get('blockRedeem').value;
        if (!currentBlockRedeem) {
            currentRule.controls['amount'].setValue(null);
        }
    };
    PenaltyRuleComponent.prototype.showBlockRedeemAmount = function (currentRule) {
        return currentRule.get('blockRedeem').value;
    };
    PenaltyRuleComponent.prototype.addSet = function (i) {
        this.rules.controls[i].controls.communications.controls.push(this.getEmptySet());
    };
    return PenaltyRuleComponent;
}());
export { PenaltyRuleComponent };
