import * as i0 from "@angular/core";
var ToasterService = /** @class */ (function () {
    function ToasterService() {
    }
    ToasterService.prototype.showToaster = function (toaster) {
        this.toaster = toaster;
    };
    ToasterService.ngInjectableDef = i0.defineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(); }, token: ToasterService, providedIn: "root" });
    return ToasterService;
}());
export { ToasterService };
