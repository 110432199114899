import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html'
})
export class SingleSelectDropdownComponent implements OnInit {
  @Input() singleDropdownData: any;
  @Output() selectedDropDown: EventEmitter<any> = new EventEmitter();

  public selectedValues: [];
  constructor() { }



  ngOnInit() {
    // console.log('single : ', this.singleDropdownData);
    // this.singleDropdownData = {
    //     type : 'single-searchable-dropdown',
    //     title : 'City',
    //     values : ['Bangalore', 'Hyderabad', 'Mumbai', 'Ahmedabad'],
    //     default : '',
    //     key : 'city-dropdown'
    //   };
  }

  chosenOptions() {
    const emitSelections = {
      [this.singleDropdownData.key] : this.selectedValues
    };
    this.selectedDropDown.emit(emitSelections);
  }

}
