import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { LoaderService } from 'src/app/loader.service';
import { AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { ToasterService } from 'src/app/toaster.service';
import { SubscriptionService } from '../../subscription.service';
import { environment } from 'src/environments/environment';
import Utils from 'src/app/utils/utils';
import { MatDialog } from '@angular/material';
var CreateSubscriptionComponent = /** @class */ (function () {
    function CreateSubscriptionComponent(sharedService, subsService, loader, alertService, toasterService, dialog) {
        this.sharedService = sharedService;
        this.subsService = subsService;
        this.loader = loader;
        this.alertService = alertService;
        this.toasterService = toasterService;
        this.dialog = dialog;
        this.subscriptionFormGroup = new FormGroup({
            ruleName: new FormControl(null),
            city: new FormControl(null),
            services: new FormControl(null),
            userSelector: new FormControl(null),
            priority: new FormControl(null),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            excludeIncentiveTypes: new FormControl(null),
            ruleType: new FormControl(null),
            ruleValue: new FormControl(0),
            isAutoAssignedToHH: new FormControl(false),
            suspendServicesPostExpiry: new FormControl(false),
            rules: new FormControl([])
        });
        this.cities = [];
        this.services = [];
        this.userSelectors = [];
        this.incentiveTypesMap = this.sharedService.INCENTIVE_TYPES_MAP;
        this.incentiveTypes = Object.keys(this.incentiveTypesMap);
        this.servicesOrSDIDsAllowedForSubsCreation = {
            serviceIds: new Set(environment.servicesOrSDIDsAllowedForSubsCreation.serviceIds),
            sdidsOfExcludedServices: new Set(environment.servicesOrSDIDsAllowedForSubsCreation.sdidsOfExcludedServices)
        };
        this.modeIdsWithHHRuleCreationEnabled = new Set(environment.modeIdsWithHHRuleCreationEnabled);
        this.modeIdsWithSuspendServicesPostExpiryEnabled = new Set(environment.modeIdsWithSuspendServicesPostExpiryEnabled);
        this.modeIdsWithVariableComissionEnabled = new Set(environment.modeIdsWithVariableComissionEnabled);
        this.useDefaultPeriodInDays = environment.useDefaultPeriodInDays;
        this.defaultPeriodInDays = environment.defaultPeriodInDays;
        this.validityTypes = this.getValidityTypes();
        this.maxRulesAtOnce = environment.maxRulesCreationAtOnce;
        this.today = moment().startOf("hour").toDate();
        this.tomorrowEod = moment().endOf("day").minutes(30).add(1, 'days').toDate();
        this.minEndDate = this.tomorrowEod;
        this.overlappingRules = [];
        this.Utils = Utils;
        this.subscriptionTitles = this.getSubscriptionTitles();
        this.fetchCities();
    }
    CreateSubscriptionComponent.prototype.resetOnchangeOf = function (controlName, toReset, cb) {
        var _this = this;
        if (cb === void 0) { cb = function () { }; }
        this.subscriptionFormGroup.get(controlName).valueChanges.subscribe(function () {
            toReset.forEach(function (control) {
                _this.subscriptionFormGroup.get(control).setValue(null);
            });
            cb();
        });
    };
    CreateSubscriptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetOnchangeOf("city", ["services"], function () {
            _this.services = [];
            _this.fetchServices();
        });
        this.resetOnchangeOf("services", ["userSelector", "ruleType", "suspendServicesPostExpiry"], function () {
            _this.userSelectors = [];
            _this.fetchSelectors();
            var mode = _this.validateAndGetModeForServices();
            if (!_this.modeIdsWithHHRuleCreationEnabled.has(mode)) {
                _this.subscriptionFormGroup.get('isAutoAssignedToHH').setValue(false);
                _this.subscriptionFormGroup.get('isAutoAssignedToHH').disable();
            }
            else {
                _this.subscriptionFormGroup.get('isAutoAssignedToHH').enable();
            }
            if (_this.services && _this.formValue("isAutoAssignedToHH")) {
                _this.highestPriorityHHRule = null;
                _this.fetchHighestPriorityHHRule();
            }
            if (!_this.modeIdsWithSuspendServicesPostExpiryEnabled.has(mode)) {
                _this.subscriptionFormGroup.get('suspendServicesPostExpiry').disable();
            }
            else {
                _this.subscriptionFormGroup.get('suspendServicesPostExpiry').enable();
            }
        });
        this.resetOnchangeOf("isAutoAssignedToHH", ["rules"], function () {
            _this.addRule();
            if (!_this.formValue('isAutoAssignedToHH')) {
                _this.highestPriorityHHRule = null;
                _this.maxRulesAtOnce = environment.maxRulesCreationAtOnce;
                _this.subscriptionFormGroup.get('endDate').enable();
                _this.subscriptionFormGroup.get('userSelector').enable();
                _this.subscriptionFormGroup.get('rules').value.map(function (rule) {
                    rule.ruleAmount.enable();
                });
            }
            else {
                _this.highestPriorityHHRule = null;
                _this.fetchHighestPriorityHHRule();
                _this.maxRulesAtOnce = 1;
                _this.subscriptionFormGroup.get('endDate').setValue(null);
                _this.subscriptionFormGroup.get('endDate').disable();
                _this.subscriptionFormGroup.get('userSelector').setValue(null);
                _this.subscriptionFormGroup.get('userSelector').disable();
                _this.subscriptionFormGroup.get('rules').value.map(function (rule) {
                    rule.ruleAmount.setValue(0);
                    rule.ruleAmount.disable();
                });
            }
        });
        this.subscriptionFormGroup.get('isAutoAssignedToHH').disable();
        this.subscriptionFormGroup.get('suspendServicesPostExpiry').disable();
    };
    CreateSubscriptionComponent.prototype.formValue = function (name) {
        return this.subscriptionFormGroup.get(name).value;
    };
    CreateSubscriptionComponent.prototype.fetchSelectors = function () {
        var _this = this;
        var city = this.formValue("city");
        var services = this.formValue("services");
        if (city && services) {
            var serviceTypes = services
                .map(function (s) { return s.serviceDetailId; }).join(",");
            this.loader.openLoading();
            this.sharedService
                .fetchRules(city.id, serviceTypes, null, "rider")
                .toPromise()
                .then(function (res) {
                _this.userSelectors = res.data.selectors
                    .map(function (_a) {
                    var _id = _a._id, name = _a.name;
                    return ({ id: _id, name: name });
                });
            })
                .finally(function () { return _this.loader.closeLoading(); });
        }
    };
    CreateSubscriptionComponent.prototype.fetchServices = function () {
        var _this = this;
        var city = this.formValue("city");
        if (city) {
            this.loader.openLoading();
            this.sharedService
                .fetchServicesWithMode(city.id)
                .toPromise()
                .then(function (res) {
                _this.services = res.data
                    .filter(function (serviceDetail) { return serviceDetail.active; })
                    .filter(function (_a) {
                    var _id = _a._id, service = _a.service;
                    return _this.servicesOrSDIDsAllowedForSubsCreation.serviceIds.has(service._id) ||
                        _this.servicesOrSDIDsAllowedForSubsCreation.sdidsOfExcludedServices.has(_id);
                })
                    .map(function (_a) {
                    var service = _a.service, _id = _a._id, modeInfo = _a.modeInfo;
                    return ({
                        id: service._id,
                        name: service.name,
                        mode: modeInfo._id,
                        serviceDetailId: _id
                    });
                });
            })
                .finally(function () { return _this.loader.closeLoading(); });
        }
    };
    CreateSubscriptionComponent.prototype.fetchCities = function () {
        var _this = this;
        this.loader.openLoading();
        this.sharedService
            .fetchCities()
            .toPromise()
            .then(function (res) {
            _this.cities = res.data.cities
                .map(function (_a) {
                var _id = _a._id, displayName = _a.displayName;
                return ({ id: _id, name: displayName });
            });
        })
            .finally(function () { return _this.loader.closeLoading(); });
    };
    CreateSubscriptionComponent.prototype.changeInDate = function () {
        var startDate = this.formValue("startDate");
        if (startDate) {
            this.minEndDate = startDate;
        }
        var endDate = this.formValue("endDate");
        if (endDate) {
            this.maxStartDate = endDate;
        }
    };
    CreateSubscriptionComponent.prototype.createSubscriptionRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rules, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loader.openLoading();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.buildRules()];
                    case 2:
                        rules = _a.sent();
                        if (!rules) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.subsService.createSubscriptionRules({ rules: rules }).toPromise()];
                    case 3:
                        _a.sent();
                        this.alertService.open({
                            title: "Success",
                            message: "Subscription rules created successfully",
                        });
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _a.sent();
                        this.alertService.open({
                            title: "Error",
                            message: error_1.message,
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        this.loader.closeLoading();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CreateSubscriptionComponent.prototype.alertMessage = function (alert) {
        this.alertService.open(alert);
    };
    CreateSubscriptionComponent.prototype.errorMessage = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message
        }));
    };
    CreateSubscriptionComponent.prototype.validateField = function (fieldName, message) {
        var value = this.formValue(fieldName);
        if (!value) {
            this.errorMessage(message);
            throw new Error(message);
        }
        return value;
    };
    CreateSubscriptionComponent.prototype.buildRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleName, city, services, mode, isAutoAssignedToHH, suspendServicesPostExpiry, startTime, endTime, priority, userSelector, excludeIncentiveTypes, ruleType, ruleValue, serviceDetailIds, recommendedCount, modeIdsWithExclusiveOfTax, rules, error_2, commonFields;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ruleName = this.validateField("ruleName", "Rule name is required");
                        city = this.validateField("city", "City is required");
                        services = this.validateField("services", "Services is required");
                        mode = this.validateAndGetModeForServices();
                        isAutoAssignedToHH = this.formValue('isAutoAssignedToHH');
                        suspendServicesPostExpiry = this.formValue('suspendServicesPostExpiry');
                        startTime = moment(this.validateField("startDate", "Purchase Start date is required").getTime()).unix();
                        endTime = !isAutoAssignedToHH ? moment(this.validateField("endDate", "Purchase  End date is required").getTime()).unix() : null;
                        priority = this.validateField("priority", "Priority is required");
                        userSelector = !isAutoAssignedToHH ? this.validateField("userSelector", "User selector is required") : null;
                        excludeIncentiveTypes = this.formValue("excludeIncentiveTypes");
                        ruleType = this.formValue("ruleType");
                        ruleValue = this.formValue("ruleValue") || 0;
                        serviceDetailIds = services.map(function (s) { return s.serviceDetailId; });
                        if (this.formValue('rules').length < 1) {
                            this.errorMessage("At least one rule is required");
                        }
                        recommendedCount = this.countRecommendedRules();
                        if (recommendedCount > 1) {
                            this.errorMessage("Only one rule with Recommended tag is allowed");
                            this.alertMessage({
                                title: "Error",
                                message: "Only one rule with Recommended tag is allowed",
                            });
                            return [2 /*return*/];
                        }
                        modeIdsWithExclusiveOfTax = new Set(environment.modeIdsWithExclusiveOfTax);
                        if (!!isAutoAssignedToHH) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.subsService.getSamePriorityRules(serviceDetailIds, startTime, endTime, priority).toPromise()];
                    case 2:
                        rules = _a.sent();
                        if (rules && rules.length > 0) {
                            this.overlappingRules = rules;
                            this.dialog.open(this.OverlappingPriorityRuleAlert);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.alertMessage({
                            title: "Error",
                            message: JSON.stringify(error_2.message),
                        });
                        return [2 /*return*/];
                    case 4:
                        if (isAutoAssignedToHH && this.highestPriorityHHRule && priority <= this.highestPriorityHHRule.priority) {
                            this.alertMessage({
                                title: "Already a higher priority HH rule exists",
                                message: "A HH rule with priority " + this.highestPriorityHHRule.priority + " already exists with validity of " + this.highestPriorityHHRule.periodInDays + " days starting from " + Utils.dateFromEpoch(this.highestPriorityHHRule.startTime * 1000) + ". Please create a higher priority rule."
                            });
                            return [2 /*return*/];
                        }
                        commonFields = {
                            ruleName: ruleName,
                            startTime: startTime,
                            endTime: endTime,
                            priority: Number(priority),
                            city: city.id,
                            services: services.map(function (s) { return s.id; }),
                            serviceDetails: serviceDetailIds,
                            mode: mode,
                            isAutoAssignedToHH: isAutoAssignedToHH,
                            exclusiveOfTax: modeIdsWithExclusiveOfTax.has(mode),
                            userSelectors: !isAutoAssignedToHH ? [userSelector.id] : [],
                            userSelectorName: !isAutoAssignedToHH ? userSelector.name : null,
                            excludeIncentiveTypes: excludeIncentiveTypes || [],
                            ruleType: ruleType,
                            suspendServicesPostExpiry: suspendServicesPostExpiry,
                            rule: [this.getRuleConstruct(ruleType, ruleValue)]
                        };
                        return [2 /*return*/, this.formValue('rules').map(function (rule, index) {
                                if (!rule.validityType.value) {
                                    _this.errorMessage("validity type is required for rule " + (index + 1));
                                    throw new Error("validity type is required for rule " + (index + 1));
                                }
                                if (!rule.maxValue.value && rule.validityType.value != "unlimited") {
                                    _this.errorMessage("max value is required for rule " + (index + 1));
                                    throw new Error("max value is required for rule " + (index + 1));
                                }
                                if (!Number.isFinite(rule.ruleAmount.value) && !isAutoAssignedToHH) {
                                    _this.errorMessage("purchase price is required for rule " + (index + 1));
                                    throw new Error("purchase price is required for rule " + (index + 1));
                                }
                                if (rule.strikedOutAmount.value && rule.strikedOutAmount.value <= rule.ruleAmount.value) {
                                    _this.errorMessage("Original price should be greater than purchase price for rule " + (index + 1));
                                    throw new Error("Original price should be greater than purchase price for rule " + (index + 1));
                                }
                                if (!rule.periodInDays.value) {
                                    _this.errorMessage("period in days is required for rule " + (index + 1));
                                    throw new Error("period in days is required for rule " + (index + 1));
                                }
                                return tslib_1.__assign({}, commonFields, { validity: [{
                                            type: rule.validityType.value,
                                            maxValue: rule.maxValue.value,
                                        }], ruleAmount: rule.ruleAmount.value, strikedOutAmount: rule.strikedOutAmount.value, periodInDays: rule.periodInDays.value, ruleTitle: rule.subscriptionTitle.value, isRecommended: rule.isRecommended.value || false });
                            })];
                }
            });
        });
    };
    CreateSubscriptionComponent.prototype.getRuleConstruct = function (ruleType, ruleValue) {
        switch (ruleType) {
            case "variableCommission":
                var value = Number(ruleValue);
                if (!value || value > 100) {
                    var message = "Rule value should be between 0 to 100";
                    this.errorMessage(message);
                    throw new Error(message);
                }
                return {
                    variable: "commission",
                    operator: "-",
                    value: value,
                    type: "percent"
                };
            case "zeroCommission":
                return {
                    variable: "commission",
                    operator: "=",
                    value: 0,
                    type: "flat"
                };
        }
    };
    CreateSubscriptionComponent.prototype.addRule = function () {
        var defaultRuleElement = {
            validityType: new FormControl(null),
            maxValue: new FormControl(null),
            ruleAmount: new FormControl(null),
            strikedOutAmount: new FormControl(null),
            periodInDays: new FormControl({ value: this.useDefaultPeriodInDays ? this.defaultPeriodInDays : null, disabled: this.useDefaultPeriodInDays }),
            subscriptionTitle: new FormControl(null),
            isRecommended: new FormControl(false)
        };
        if (this.formValue('rules')) {
            this.formValue('rules').push(defaultRuleElement);
        }
        else {
            this.subscriptionFormGroup.get('rules').setValue([defaultRuleElement]);
        }
    };
    CreateSubscriptionComponent.prototype.countRecommendedRules = function () {
        var rulesArray = this.subscriptionFormGroup.get('rules').value;
        var recommendTag = 0;
        rulesArray.forEach(function (element, index) {
            if (element.isRecommended.value) {
                recommendTag++;
            }
        });
        return recommendTag;
    };
    CreateSubscriptionComponent.prototype.deleteRule = function (index) {
        this.formValue('rules').splice(index, 1);
    };
    CreateSubscriptionComponent.prototype.validityTypeChanged = function (rule) {
        if (rule.validityType.value === 'unlimited') {
            rule.maxValue.setValue(null);
            rule.maxValue.disable();
        }
        else {
            rule.maxValue.enable();
        }
    };
    CreateSubscriptionComponent.prototype.validateAndGetModeForServices = function () {
        if (!this.formValue('services')) {
            return;
        }
        var distinctModes = new Set(this.formValue('services').map(function (service) { return service.mode; }));
        if (distinctModes.size > 1) {
            this.subscriptionFormGroup.get('services').setValue(null);
            this.subscriptionFormGroup.get('ruleType').setValue(null);
            this.alertMessage({
                title: "Error",
                message: "Please select services with same mode"
            });
            throw new Error("services with different mode selected");
        }
        var mode = distinctModes.values().next().value;
        return mode;
    };
    CreateSubscriptionComponent.prototype.getValidityTypes = function () {
        return environment.validityTypesEnabled.length > 0 ? environment.validityTypesEnabled : ["unlimited"];
    };
    CreateSubscriptionComponent.prototype.getSubscriptionTitles = function () {
        return environment.subscriptionTitles.length > 0 ? environment.subscriptionTitles : [];
    };
    CreateSubscriptionComponent.prototype.isModeEnabledForVariableComission = function () {
        var mode = this.validateAndGetModeForServices();
        if (mode && this.modeIdsWithVariableComissionEnabled.has(mode)) {
            return true;
        }
        return false;
    };
    CreateSubscriptionComponent.prototype.fetchHighestPriorityHHRule = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var services;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                services = this.formValue('services');
                if (!services) {
                    return [2 /*return*/, null];
                }
                this.subsService
                    .getHighestPriorityHHRule(services.map(function (service) { return service.serviceDetailId; }))
                    .subscribe(function (rules) {
                    if (rules.length > 0) {
                        _this.highestPriorityHHRule = rules[0];
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return CreateSubscriptionComponent;
}());
export { CreateSubscriptionComponent };
