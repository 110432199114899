import { Component, OnInit, Input } from '@angular/core';
import { Toaster, ToasterType } from '../types/toaster.types';
import { ToasterButtonType } from '../types/toaster.types';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css']
})
export class ToasterComponent implements OnInit {
  @Input() config: Toaster;
  ToasterType = ToasterType;
  ToasterButtonType = ToasterButtonType;
  constructor() { }

  ngOnInit() {
  }

}
