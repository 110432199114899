import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { LoaderService } from 'src/app/loader.service';
import { AlertDialogService } from 'src/app/shared/alert-dialog/alert-dialog.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { ToasterService } from 'src/app/toaster.service';
import { SubscriptionService } from '../../subscription.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-create-accessfee',
  templateUrl: './accessFee.create.component.html',
  styleUrls: ['./accessFee.create.component.css']
})
export class CreateAccessFeeComponent implements OnInit {
  public accessFeeFormGroup = new FormGroup({
    planName: new FormControl(null),
    city: new FormControl(null),
    services: new FormControl(null),
    zones: new FormControl(null),
    userSelector: new FormControl(null),
    targetSegment: new FormControl('userSelectors'),
    priority: new FormControl(null),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    tiers: new FormControl([])
  });

  public BillingPlanResponse : {
    rejectedPlans: string[];
    reason: string;
  } ;


  public cities: { id: string, name: string; }[] = [];
  public zones: { id: string, name: string; }[] = [];
  public services: {
    id: string,
    name: string,
    mode: string,
    serviceDetailId: string,
    geoServiceGroupId: string;
  }[] = [];

  
  public userSelectors: { id: string, name: string; }[] = [];

  private servicesOrSDIDsAllowedForAccessFeeCreation: Record<string,Set<String>> = {
    serviceIds: new Set<string>(environment.servicesOrSDIDsAllowedForAccessFeeCreation.serviceIds)
  };

  public today: Date = moment().startOf('day').toDate();
  public tomorrowEod: Date = moment().endOf('day').add(1, 'days').toDate();
  public maxStartDate: Date;
  public minEndDate: Date = this.tomorrowEod;


  constructor(private sharedService: SharedService,
    private subsService: SubscriptionService,
    private loader: LoaderService,
    private alertService: AlertDialogService,
    public toasterService: ToasterService) {
    this.fetchCities();
    this.addTier();
  }

  resetOnchangeOf(controlName: string, toReset: string[], cb = () => { }) {
    this.accessFeeFormGroup.get(controlName).valueChanges.subscribe(() => {
      toReset.forEach(control => {
        this.accessFeeFormGroup.get(control).setValue(null);
      });
      cb();
    });
  }

  ngOnInit(): void {
    this.resetOnchangeOf("city", ["services", "zones", "userSelector", "tiers", "startDate", "endDate", "priority"], () => {
      this.services = [];
      this.userSelectors = [];
      try {
        this.addTier();
        this.fetchServices();
      } catch (err) {
        console.error(err);
      }
    });

    this.resetOnchangeOf("services", ["zones", "userSelector", "tiers", "startDate", "endDate", "priority"], () => {
      this.userSelectors = [];
      this.zones = [];
      try {
        this.addTier();
        this.fetchZones();
        this.fetchSelectors();
        this.validateModeAndGeoGroupIdForServices() as string;
      } catch (err) {
        console.error(err);
      }
    });


    this.resetOnchangeOf("zones", ["userSelector", "tiers", "startDate", "endDate", "priority"], () => {
      this.addTier();
    })

    this.resetOnchangeOf("targetSegment", ["priority", "userSelector"], () =>{
    })
  }

  fetchSelectors() {
    const city = this.formValue("city");
    const services = this.formValue("services");
    if (city && services) {
      const serviceTypes = services
        .map(s => s.serviceDetailId).join(",");
      this.loader.openLoading();
      this.sharedService
        .fetchRules(city.id, serviceTypes, null, "rider", "custom")
        .toPromise()
        .then((res: any) => {
          this.userSelectors = res.data.selectors
            .map(({ _id, name }) => ({ id: _id, name }));
        })
        .finally(() => this.loader.closeLoading());
    }
  }

  formValue(name) {
    return this.accessFeeFormGroup.get(name).value;
  }

  fetchZones() {
    const city = this.formValue("city");
    const services = this.formValue("services");
    let serviceWithGeoGroupId = services.find(service => service.geoServiceGroupId !== '');
    let firstNonEmptyGeoServiceGroupId = serviceWithGeoGroupId ? serviceWithGeoGroupId.geoServiceGroupId : undefined;
    if (city && services && firstNonEmptyGeoServiceGroupId) {
      this.loader.openLoading();
      this.sharedService
        .fetchZones(city.id, firstNonEmptyGeoServiceGroupId)
        .toPromise()
        .then((res: any) => {
          this.zones = res.geolayers
          .map(({ id, name }) => ({ id: id, name: name }));
        })
        .finally(() => this.loader.closeLoading());
    }
  }


  private fetchServices(): void {
    const city = this.formValue("city");
    if (city) {
      this.loader.openLoading();
      this.sharedService
        .fetchServicesWithMode(city.id)
        .toPromise()
        .then((res: any) => {
          this.services = res.data
            .filter(serviceDetail => serviceDetail.active)
            .filter(({_id, service} ) => {
              return this.servicesOrSDIDsAllowedForAccessFeeCreation.serviceIds.has(service._id)
            })
            .map(({ service, _id, modeInfo, geoServiceGroupId }) => ({
              id: service._id,
              name: service.name,
              mode: modeInfo._id,
              serviceDetailId: _id,
              geoServiceGroupId: geoServiceGroupId
            }));
        })
        .finally(() => this.loader.closeLoading());
    }
  }

  private fetchCities() {
    this.loader.openLoading();
    this.sharedService
      .fetchCities()
      .toPromise()
      .then((res: any) => {
        this.cities = res.data.cities
          .map(({ _id, displayName }) => ({ id: _id, name: displayName }));
      })
      .finally(() => this.loader.closeLoading());
  }

  changeInDate() {
    const startDate = this.formValue("startDate");

    if (startDate) {
      this.minEndDate = startDate;
    }

    const endDate = this.formValue("endDate");
    if (endDate) {
      this.maxStartDate = endDate;
    } else {
      this.maxStartDate = null;
    }
  }

  async createAccessFeeRules() {
    this.loader.openLoading();

    try {
      const plans = await this.buildBillingPlan();
      if (!plans) {
        return;
      }

      await this.subsService.createBillingPlan({plans}).subscribe((resp: any) => {
        if (resp.rejectedPlans && resp.rejectedPlans.length > 0) {
          alert(resp.reason);
        } else {
          this.alertService.open({
            title: "Success",
            message: "Access Fee rules created successfully",
          });
        }
      });
     
    } catch (error) {
      this.alertService.open({
        title: "Error",
        message: error.message,
      });
    } finally {
      this.loader.closeLoading();
    }
  }

  alertMessage(alert: { title: string, message: string }) {
    this.alertService.open(alert)
  }

  errorMessage(message) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: message
    }));
  }

  validateField(fieldName: string, message: string) {
    const value = this.formValue(fieldName);
    if (!value) {
      this.errorMessage(message);
      throw new Error(message);
    }
    return value;
  }


  async buildBillingPlan() {
    const planName = this.validateField("planName", "Plan name is required");
    const city = this.validateField("city", "City is required");
    const services = this.validateField("services", "Services is required");
    const mode: string = this.validateModeAndGeoGroupIdForServices() as string;
    const zones = this.formValue("zones");
    const startDate = this.validateField("startDate", "Purchase Start date is required");
    const endDate =  this.validateField("endDate", "Purchase  End date is required");
    let priority, userSelector;

    if(this.accessFeeFormGroup.get('targetSegment').value === 'userSelectors') {
      
      priority =  this.formValue("priority");
      if (!priority || !Number.isInteger(priority) || priority <= 0 )  {
        this.errorMessage("Priority is required/should be positive number for userSelector");
        throw new Error("Priority is required/should be positive number for userSelector");
      }
      
      userSelector = this.formValue("userSelector");
      if (!userSelector)  {
        this.errorMessage("UserSelector is required for targetSegment userSelector");
        throw new Error("UserSelector is required for targetSegment userSelector");
      }
    } 

    if (this.formValue('tiers').length < 1) {
      this.errorMessage("At least one tier is required");
    }

    if(this.formValue('tiers').length>1) {
      let minRides = this.formValue('tiers').map(tier => tier.minRides.value)
      for (let i = 1; i < minRides.length; i++) {
        if (minRides[i] <= minRides[i - 1]) {
          this.errorMessage("Minimum rides should be in increasing order");
          throw new Error("Minimum rides should be in increasing order");
        }
      }
    }
 
    let commonFields = {
      planName,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      priority: Number(priority),
      city: city.id,
      services: services.map(s => s.id),
      zones: (zones && zones.length > 0) ? zones.map(z => z.id) : undefined,
      mode,
      userSelectors: userSelector ? userSelector.map(usId => usId.id) : undefined,
    };

    return [{
      ...commonFields,
      tiers: this.formValue('tiers').map((tier, index) => {
        this.validateTier(tier, index);
    
        return {
          minValue: tier.minRides.value,
          amount: tier.tierAmount.value,
          strikeOutAmount: tier.strikeOutAmount.value
        };
      })
    }];
  }

  validateTier(tier, index) {

    if (!tier.minRides || !Number.isInteger(tier.minRides.value) || tier.minRides.value <= 0 ) {
      this.errorMessage(`minimum rides value is required for tier ${index + 1}`);
      throw new Error(`Minimum rides value is required/ should be non zero positive number for tier ${index + 1}`);
    }

    if (!tier.tierAmount || (!Number.isInteger(tier.tierAmount.value) || tier.tierAmount.value < 0)) {
      this.errorMessage(`Charged Fee is required and should be integer for tier  ${index + 1}`);
      throw new Error(`Charged Fee is required and should be non negative number for tier ${index + 1}`);
    }

    if (tier.strikeOutAmount && (tier.strikeOutAmount.value || tier.strikeOutAmount.value == 0) && 
      (!Number.isInteger(tier.strikeOutAmount.value) || tier.strikeOutAmount.value < 0 || tier.strikeOutAmount.value <= tier.tierAmount.value)) {
      this.errorMessage(`Striked Out amount should be positive number for tier ${index + 1}`);
      throw new Error(`Striked Out should be greater than charged fee for tier ${index + 1}`);
    }
  }

  addTier() {
    let defaultTierElement = {
        tierCondition : new FormControl(null),
        tierAmount: new FormControl(null),
        minRides: new FormControl(null),
        strikeOutAmount: new FormControl(null),
      }
      if (this.formValue('tiers')) {
        this.formValue('tiers').push(defaultTierElement)
      } else {
        this.accessFeeFormGroup.get('tiers').setValue([defaultTierElement])
      }  
  }

  deleteTier(index) {
    this.formValue('tiers').splice(index, 1)
  }

  validateModeAndGeoGroupIdForServices() {
    if (!this.formValue('services')) {
      return
    }
    let distinctModes = new Set(this.formValue('services').map((service: { mode: string }) => service.mode))

    let distinctGeoGroupId = new Set(this.formValue('services')
                                      .map((service: {geoServiceGroupId: string}) => service.geoServiceGroupId))

    if ( distinctModes.size > 1 ) {
      this.accessFeeFormGroup.get('services').setValue(null)
      this.alertMessage({
        title: "Error",
        message: "Please select services with same mode"
      });
      throw new Error(`services with different mode selected`);
    }

    if (distinctGeoGroupId.size >1) {
      this.accessFeeFormGroup.get('services').setValue(null)
      this.alertMessage({
        title: "Error",
        message: "Please select services with same geoGroupId"
      });
      throw new Error(`services with different geoGroupId selected`);
    }

    let mode = distinctModes.values().next().value;
    let geoServiceGroupId = distinctModes.values().next().value;
    return mode;
  }

}
