<div *ngIf="!resetFields" id="mySidenav" class="sidenav" style="top: 62px;">
  <mat-toolbar class="primary-toolbar">
    <mat-icon class="close-button cursor-pointer" (click)="closeNav()">close</mat-icon>
    <span class="header-font">Filter</span>
    <a (click)="clearFilters()" class="clear-filters-a">Clear All</a>
  </mat-toolbar>
  <div class="main-container">
    <div *ngFor="let component of filterComponents">
      <searchable-dropdown *ngIf="component.type === 'searchable-dropdown'" [dropdownData]="component"
        (selectedDropDowns)="updateData($event, component.key)"></searchable-dropdown>

      <radio-buttons *ngIf="component.type === 'radio-button'" [radioData]="component"
        (selectedRadio)="updateData($event, component.key)"></radio-buttons>

      <calendar *ngIf="component.type === 'calendar'" [calendarData]="component"
        (selectedDates)="updateData($event, component.key)"></calendar>

      <single-select-dropdown *ngIf="component.type === 'single-searchable-dropdown'" [singleDropdownData]="component"
        (selectedDropDown)="updateData($event, component.key)"></single-select-dropdown>
    </div>
    <div class="row">
      <div class="col">
        <button mat-flat-button (click)="submitFilters()" type="button"
          class="apply-filters-btn bg-primary-color-white">Apply</button>
      </div>
    </div>
  </div>
</div>
