import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../http-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
export const BASE_URL = {
  OLD_DASHBOARD: environment.rapido_dashboard, // make this configurable
};
@Injectable({
  providedIn: 'root'
})
export class IncentiveService {
  addIncentive = environment.server + '/api/createIncentive';
  incentive = environment.server + '/api/incentive';

  createIncentive(incentive): Observable<any> {
    incentive.active = incentive.active || false;
    return this.http.post(this.addIncentive, incentive).pipe(catchError(this.handleError));
  }

  editIncentive(incentiveId, incentive) {
    incentive.active = incentive.active || false;
    return this.http.put(`${this.incentive}/${incentiveId}`, incentive).pipe(catchError(this.handleError));
  }

  getIncentive(incentiveId) {
    return this.http.get(`${this.incentive}/${incentiveId}`).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  redirectToDashbaord(url) {
    const redirectUrl = `${BASE_URL.OLD_DASHBOARD}${url}`;
    const token = this.readCookie('token');
    window.open(`${redirectUrl}?token=${token}`);

    // window.location.href = `${redirectUrl}?token=${token}`;
  }

  private readCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  constructor(private http: MyHttpClient) { }
}
