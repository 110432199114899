import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-material-timepicker',
  templateUrl: './material-timepicker.component.html',
  styleUrls: ['./material-timepicker.component.css']
})
export class MaterialTimepickerComponent implements OnInit {
  @Input() disabled = false;
  public _value = '';
  @Input() placeholder = 'Select Time'
  @Input() style = {
    height: '34px',
    width: '103px'
  };
  @Input() set value(date: any) {
    if (date && Date.parse(date)) {
      // date = new Date(date);
      // let hour = date.getHours();
      // let minutes = date.getMinutes();
      // let type = 'am';
      // if (hour > 12) {
      //   hour = hour % 12;
      //   type = 'pm';
      // }
      // this._value = `${hour}:${minutes} ${type}`;
      this._value = this.getTimeInString(date);
    }
  }

  get value() {
    return this._value;
  }

  @Output() valueChange = new EventEmitter();
  constructor() { }
  private getTimeInString(date: Date) {
    date = new Date(date);
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let type = 'am';
    if (hour === 12) {
      type = 'pm';
    }
    if (hour === 0) {
      hour = 12;
      type = 'am';
    }
    if (hour > 12) {
        hour = hour % 12;
        type = 'pm';
    }
    const timeInString = `${hour}:${minutes} ${type}`;
    return timeInString;
  }

  ngOnInit() {
  }


  onTimeChange(time) {
    const [timing, type] = time.split(' ');
    let [hour, minute] = timing.split(':');
    if (type === 'pm' && hour !== '12') {
      hour = parseInt(hour) + 12;
    }
    if (type === 'am' && hour === '12') {
      hour = 0;
    }
    const date = new Date();
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(hour);
    date.setMinutes(minute);
    this.value = date;
    this.valueChange.emit(date);
  }

}
