import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { IncentiveService } from '../create-incentive/create-incentive.service';
import { SharedService } from '../shared/shared.service';
var AssignmentDetailsComponent = /** @class */ (function () {
    function AssignmentDetailsComponent(sharedService, incentiveService) {
        this.sharedService = sharedService;
        this.incentiveService = incentiveService;
        this.close = new EventEmitter();
        this.metrics = {
            selectorName: "NA",
            selecorUserCount: "NA",
            assigned: "NA",
            date: "NA",
            status: "NA"
        };
    }
    AssignmentDetailsComponent.prototype.ngOnInit = function () {
        this.fetchIncentiveDetails();
    };
    AssignmentDetailsComponent.prototype.fetchIncentiveDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var incentive, selectorDetails, metrics, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.incentiveService.getIncentive(this.incentiveId).toPromise()];
                    case 1:
                        incentive = (_b.sent()).data;
                        return [4 /*yield*/, this.sharedService.getUserSelectorDetails(incentive.ruleId).toPromise()];
                    case 2:
                        selectorDetails = _b.sent();
                        this.metrics = tslib_1.__assign({}, this.metrics, { selectorName: selectorDetails.name, selecorUserCount: selectorDetails.userCount });
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.sharedService.getIncentiveMetrics(this.incentiveId).toPromise()];
                    case 4:
                        metrics = _b.sent();
                        this.metrics = tslib_1.__assign({}, this.metrics, { assigned: metrics.assignedCount, date: metrics.date, status: "PASS" });
                        return [3 /*break*/, 6];
                    case 5:
                        _a = _b.sent();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return AssignmentDetailsComponent;
}());
export { AssignmentDetailsComponent };
