import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var permission, allowed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permission = localStorage.getItem('permission');
                        return [4 /*yield*/, this.auth.isAuthenticated()];
                    case 1:
                        if (!(_a.sent())) {
                            this.router.navigate(["/login"]);
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.auth.isAuthorizedFor(permission)];
                    case 2:
                        allowed = _a.sent();
                        if (!allowed) {
                            this.router.navigate(["/login"]);
                        }
                        return [2 /*return*/, allowed];
                }
            });
        });
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
