<app-toaster class="toaster" [config]="toasterService.toaster"></app-toaster>
<form [formGroup]="iAllocatorFormGroup" class="incentive-form">
  <div class="incentive-form-row">
    <div class="allocator-form-field">
      <label class="heading">City</label>
      <ng-select class="allocator-form-select" formControlName="selectedCity" name="city" placeholder="Select city"
        (change)="citySelected()">
        <ng-option *ngFor="let item of config" [value]="item">{{item.cityName}}</ng-option>
      </ng-select>
    </div>
    <div class="allocator-form-field">
      <label class="heading">Service</label>
      <ng-select class="allocator-form-select" formControlName="selectedService" name="service"
        placeholder="Select service">
        <ng-option *ngFor="let item of getServices()" [value]="item">{{item}}</ng-option>
      </ng-select>
    </div>
    <div class="allocator-form-field">
      <label class="heading">Incentive Type </label>
      <ng-select class="allocator-form-select" formControlName="selectedIncentive" name="type"
        placeholder="Select type">
        <ng-option *ngFor="let item of getIncentiveTypes()" [value]="item">{{item}}</ng-option>
      </ng-select>
    </div>
    <div class="allocator-form-field">
      <label class="heading">Start Date</label>
      <p-calendar dateFormat="d/m/yy" selectOtherMonths="true" [inputStyle]="{'width': '100px'}"
        formControlName="fromDate" icon="pi pi-calendar" [showIcon]=true (ngModelChange)="changeInDate()"
        [maxDate]="maxDate" [minDate]="todayDate">
      </p-calendar>
    </div>
    <div class="allocator-form-field">
      <label class="heading">End Date</label>
      <p-calendar dateFormat="d/m/yy" selectOtherMonths="true" [inputStyle]="{'width': '100px'}"
        formControlName="toDate" icon="pi pi-calendar" [showIcon]=true (ngModelChange)="changeInDate()"
        [minDate]="minDate">
      </p-calendar>
    </div>
  </div>
  <div class="incentive-form-row">
    <div class="allocator-form-field-40Percent-width">
      <label class="heading">Captain Phase</label>
      <ng-select class="allocator-form-select" formControlName="captainPhase" name="captainPhase" placeholder="Select captain phase">
        <ng-option *ngFor="let item of getCaptainPhases()" [value]="item">{{item}}</ng-option>
      </ng-select>
    </div>
    <div *ngIf="budgetAllocationEnabled()" class="allocator-form-field-60Percent-width">
      <label class="heading">Budget ₹</label>
      <input type="number" formControlName="budget" placeholder="Enter budget" min="1" required style="width: 12l0px">
      <span class="note">(Enter the {{getValues("selectedIncentive").toLowerCase()}} incentive budget for {{getValues("captainPhase")}} captains)</span>
    </div>
  </div>
</form>
<button mat-raised-button class="btn-primary medium" (click)="fetchConstructs()">
  Generate Incentive Constructs
</button>
<app-incentive-generator *ngIf="constructs" [constructs]="constructs"></app-incentive-generator>
