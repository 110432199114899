export const STRING = Object.freeze({
  FUNCTION: 'function',
})

export enum LogType {
  PENALTY_CAMPAIGN = 'penalty-reward-campaign'
}

export const MONTHS = [
  'Jan', 'Feb', 'Mar',
  'Apr', 'May', 'Jun', 'Jul',
  'Aug', 'Sept', 'Oct',
  'Nov', 'Dec'
];
