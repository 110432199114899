import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../global-constants';
import { LoaderService } from '../loader.service';
import { AlertDialogService } from '../shared/alert-dialog/alert-dialog.service';
import { Toaster, ToasterType } from '../shared/types/toaster.types';
import { ToastMessage } from '../toast-message/toast-message.service';
import { ToasterService } from '../toaster.service';
import { IAllocatorService } from './i-allocator.service';

@Component({
  selector: 'app-i-alloator-incentive',
  templateUrl: './i-allocator-incentive.component.html',
  styleUrls: ['./i-allocator-incentive.component.css'],
  providers: [ToastMessage]
})
export class IAllocatorIncentiveComponent implements OnInit {
  public config = _.sortBy(environment.iAllocatorConfig, 'cityName');

  public todayDate: Date = new Date();
  public minDate: Date = this.todayDate;
  public maxDate: Date;

  public constructs: any;
  public defaultCaptainPhase = "PHH-Recent";

  constructor(
    public toasterService: ToasterService,
    private iAllocatorService: IAllocatorService,
    private loaderService: LoaderService,
    private alertDialogService: AlertDialogService
  ) { }

  ngOnInit() {
  }

  errorMessage(message) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: message
    }));
  }

  iAllocatorFormGroup = new FormGroup({
    selectedCity: new FormControl(null, Validators.required),
    selectedService: new FormControl(null, Validators.required),
    selectedIncentive: new FormControl(null, Validators.required),
    fromDate: new FormControl(null, Validators.required),
    toDate: new FormControl(null, Validators.required),
    budget: new FormControl(0, Validators.required),
    captainPhase: new FormControl(this.defaultCaptainPhase, Validators.required)
  });

  getValues(name: string) {
    return this.iAllocatorFormGroup.get(name).value;
  }

  getServices() {
    const config = this.getValues("selectedCity") || { services: [] };
    return config.services;
  }

  budgetAllocationEnabled() {
    const config = this.getValues("selectedCity");
    const incentiveType = this.getValues("selectedIncentive");
    return config && incentiveType && (config.budgetAllocation || []).includes(incentiveType);
  }

  getIncentiveTypes() {
    const config = this.getValues("selectedCity") || { incentiveTypes: [] };
    return config.incentiveTypes;
  }

  getCaptainPhases() {
    const config = this.getValues("selectedCity");
    return config && config.captainPhases ? config.captainPhases.concat(this.defaultCaptainPhase) : [this.defaultCaptainPhase];
  }

  citySelected() {
    this.iAllocatorFormGroup.patchValue({
      selectedService: null,
      selectedIncentive: null,
      captainPhase: this.defaultCaptainPhase
    });
    this.constructs = null;
  }

  changeInDate() {
    if (this.getValues('fromDate')) {
      this.minDate = this.getValues('fromDate');
    }
    if (this.getValues('toDate')) {
      this.maxDate = this.getValues('toDate');
    }
  }

  showAlertBox(options, cb = () => { }) {
    this.alertDialogService.open(options);
    this.alertDialogService.confirmed()
      .subscribe(confirmed => confirmed && cb());
  }

  async fetchConstructs() {
    const request = this.createRequest();
    if (!request) {
      return;
    }
    try {
      this.loaderService.openLoading();
      const constructs: any = await this.iAllocatorService.fetchIncentiveConstructs(request);
      constructs
        .segments
        .forEach(segment => this.calculateSegmentTargets(segment));
      this.constructs = constructs;
    } catch (error) {
      console.error(error);
      this.showAlertBox({
        title: 'Error',
        message: 'Looks like iAllocator is down. Please reach out to the Captain-Retention team and try again later.',
      });
    } finally {
      this.loaderService.closeLoading();
    }
  }

  private calculateSegmentTargets(segment: { segmentInfo: { days: string[] }[] }) {
    const segmentInfo = segment.segmentInfo;
    segmentInfo
      .forEach(si => si.days.sort(this.compareDays))

    segmentInfo
      .sort((a, b) => {
        const aEnd = a.days[a.days.length - 1]
        const bStart = b.days[0]
        return this.compareDays(aEnd, bStart)
      })
      .forEach((incentive) => this.calculateIncentiveTargets(segment, incentive));

    this.validateIncentiveDays(segmentInfo);
  }

  private calculateIncentiveTargets(segment: any, incentive: any) {
    incentive
      .forecastedData
      .forEach(f => {
        let distanceTarget = 0;
        let prevRideTarget = 0;
        f.rideSlabs
          .forEach(slab => {
            if (segment.distanceBaseTarget) {
              if (!slab.perOrderDistance) {
                throw new Error("Invalid incentive missing distance targets")
              }
              const rides = slab.rideTarget - prevRideTarget;
              prevRideTarget = slab.rideTarget;
              distanceTarget += rides * slab.perOrderDistance;
              slab.distanceTarget = distanceTarget;
              slab.rides = rides;
            }
            slab.suggestedIPR = slab.ipr;
          });
      });
  }

  validateIncentiveDays(segementInfo: { days: string[] }[]) {
    const validateDays = (days: string[]) => {
      days.forEach(d => {
        if (!GlobalConstants.AllIncentiveWeekDays.includes(d)) {
          throw new Error("Invalid days in segment incentive: " + d)
        }
      });

      const start = GlobalConstants.AllIncentiveWeekDays.indexOf(days[0])
      const end = GlobalConstants.AllIncentiveWeekDays.indexOf(days[days.length - 1])

      if (end - start != (days.length - 1)) {
        throw new Error("Invalid incentive contruct day range")
      }

      return days;
    }

    const allDays = _.flatMap(segementInfo, s => validateDays(_.uniq(s.days)))

    const seen = {};
    allDays.forEach(d => {
      if (!seen[d]) {
        seen[d] = true
        return;
      }
      throw new Error("Overlapping day range in incentives")
    })
  }

  validateAndGet(field: string, message: string) {
    const value = this.getValues(field);
    if (!value) {
      this.errorMessage(message);
      throw new Error(message);
    }
    return value;
  }

  createRequest() {
    const startDate = this.validateAndGet('fromDate', 'Start date is required');
    const endDate = this.validateAndGet('toDate', 'Start date is required');
    const incentiveType = this.validateAndGet("selectedIncentive", "Incentive type is required");
    this.validateDates(startDate, endDate);
    const budget = this.budgetAllocationEnabled() ?
      this.validateAndGet('budget', 'Budget is required') : undefined;

    const captainPhase = this.validateAndGet('captainPhase', 'captain phase is required');

    return {
      cityId: this.validateAndGet("selectedCity", "City is required").cityId,
      services: [this.validateAndGet("selectedService", "Service is required")],
      incentiveType,
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate),
      budget,
      captainPhase
    };
  }

  private validateDates(startDate: any, endDate: any) {
    if (startDate.getDay() != 1) {
      this.errorMessage("Start date should be Monday");
      throw new Error("Start date should be Monday");
    }
    if (endDate.getDay() != 0) {
      this.errorMessage("End date should be Sunday");
      throw new Error("End date should be Sunday");
    }
  }

  private compareDays(day1, day2) {
    return GlobalConstants.AllIncentiveWeekDays.indexOf(day1) - GlobalConstants.AllIncentiveWeekDays.indexOf(day2)
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  };
}
