import { OnInit, EventEmitter } from '@angular/core';
var SearchableDropdownComponent = /** @class */ (function () {
    function SearchableDropdownComponent() {
        this.selectedDropDowns = new EventEmitter();
    }
    SearchableDropdownComponent.prototype.ngOnInit = function () {
        // this.dropdownData = {
        //     type : 'searchable-dropdown',
        //     title : 'City',
        //     values : ['Bangalore', 'Hyderabad', 'Mumbai', 'Ahmedabad'],
        //     default : '',
        //     key : 'city-dropdown'
        //   };
    };
    SearchableDropdownComponent.prototype.chosenOptions = function () {
        var _a;
        var emitSelections = (_a = {},
            _a[this.dropdownData.key] = this.selectedValues,
            _a);
        this.selectedDropDowns.emit(emitSelections);
    };
    return SearchableDropdownComponent;
}());
export { SearchableDropdownComponent };
