<div class="mt-20 font-size-20 color-gray" *ngIf="constructs.budgetAllocationStatus">
  <span class="bold">Note: </span> {{constructs.budgetAllocationStatus}}
</div>
<div class="mt-20 font-size-20 color-gray" *ngIf="iMetricStatus">
  <span class="mr-50"><span class="bold">iMetric Days: </span>{{iMetricStatus.dataStatus}}</span>
  <span class="mr-50"><span class="bold">iMetric Last Date: </span>{{iMetricStatus.dataLatestDate}}</span>
  <span class="mr-50"><span class="bold">User Selector Date: </span>{{iMetricStatus.selectorLatestDate}}</span>
</div>
<div class="my-20">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let groupedSegments of groupedConstructs">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-size-20 color-gray">
          {{groupedSegments.label}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mt-20" *ngFor="let segment of groupedSegments.segments">
        <h3 class="ml-20">
          <mat-slide-toggle [(ngModel)]="segment.selected" (change)="segmentToggled()"></mat-slide-toggle>
          <span class="segment-label">{{segment.label}}</span>
        </h3>
        <mat-card *ngFor="let incentive of segment.segmentInfo">
          <mat-card-title-group>
            <mat-card-title class="center color-gray">
              {{incentiveDays(incentive)}}
            </mat-card-title>
            <mat-card-header class="incentive-header">
              <div class="bold">
                <span>{{constructs.incentiveType.toUpperCase()}} </span>
                <span>PROJECTIONS: </span>
              </div>
              <div class="mx-10">
                <span>{{constructs.incentiveType}} </span>
                <span>Net Captains: </span>
                <span>{{incentive.netCaptains| fmtNumber}}</span>
              </div>
              <div class="mx-10">
                <span>{{constructs.incentiveType}} </span>
                <span>Net Rides: </span>
                <span>{{incentive.netRides| fmtNumber}}</span>
              </div>
              <div class="mx-10">
                <span>Total {{constructs.incentiveType}} Burn: </span>
                <span>{{calculateTotalBurn(incentive.forecastedData)| inr}}</span>
              </div>
            </mat-card-header>
          </mat-card-title-group>
          <mat-card-content>
            <div class="flex" *ngFor="let forecast of incentive.forecastedData">
              <div class="flex-col flex-shrink-8 w-100 font-size-20 color-gray center">
                <div class="flex" *ngFor="let timeSlot of forecast.timeSlotsProjection">
                  <div class="flex-col w-100">
                    {{timeSlot.fromTime}} - {{timeSlot.toTime}}
                  </div>
                  <div class="flex-col">
                    <mat-icon matTooltip="Net Captains: {{timeSlot.netCaptains}} &  Net Rides: {{timeSlot.netRides}}"
                      class="bar-chart-icon">bar_chart</mat-icon>
                  </div>
                </div>
              </div>
              <table class="table segment-details">
                <thead>
                  <tr>
                    <th>IPR</th>
                    <th>Target</th>
                    <th>Slab Effort/Reward</th>
                    <th>{{constructs.incentiveType}} Achieving Captains</th>
                    <th>{{constructs.incentiveType}} Burn</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let slab of forecast.rideSlabs">
                    <td>
                      <input class="width-80" pInputText [ngModelOptions]="{standalone: true}" [(ngModel)]="slab.ipr"
                        type="number" required (change)="resetInvalidIPR(slab)">
                    </td>
                    <td>{{slab.rideTarget}} rides <span *ngIf="segment.distanceBaseTarget">&
                        {{slab.distanceTarget}} km</span></td>
                    <td>
                      {{slabCreditAmount(slab) | inr}}, {{slab.rides}} rides @ {{slab.ipr | inr}}/Ride
                    </td>
                    <td>{{slab.achievingCaptains| fmtNumber}}</td>
                    <td>{{calculateBurn(slab)| inr}}</td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td style="font-weight: 500;">
                      Total Rides: {{earningPotential(forecast).target}}
                    </td>
                    <td style="font-weight: 500;">
                      Earning Potential: {{earningPotential(forecast).total | inr}}
                    </td>
                    <td>
                    </td>
                    <td style="font-weight: 500;">
                      Total Burn: {{calculateTotalBurnInTimeSlot(forecast) | inr}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="my-20">
  <mat-card>
    <mat-card-title-group>
      <mat-card-title class="center color-gray">
        <span style="margin-left: 5px;">Selected Segments Stats</span>
      </mat-card-title>
      <mat-card-header class="incentive-header">
        <div class="mx-10">
          <span>Net Captains: </span>
          <span>{{selectedSegmentTotals().netCaptains | fmtNumber}}</span>
        </div>
        <div class="mx-10">
          <span>Net Rides: </span>
          <span>{{selectedSegmentTotals().netRides| fmtNumber}}</span>
        </div>
        <div class="mx-10">
          <span>Overall Burn: </span>
          <span>{{selectedSegmentTotals().totalBurn | inr}}</span>
        </div>
        <button mat-raised-button class="space-2 ml-auto btn-primary" (click)="csvForUserSelectorIdAndUserId()">
          Download Segments
        </button>
      </mat-card-header>
    </mat-card-title-group>
  </mat-card>
</div>
<div class="row mx-10 align-items-center font-size-20">
  <mat-slide-toggle class="space-2" (change)="toggleAllSegments()" [(ngModel)]="allSegmentsToggle">
    All Segments
  </mat-slide-toggle>
  <button mat-raised-button class="space-2 ml-auto btn-primary" (click)="confirmSelectedIncentives()">
    Set Incentives
  </button>
</div>
