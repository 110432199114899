import * as tslib_1 from "tslib";
import { OnChanges, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IncentiveService } from 'src/app/create-incentive/create-incentive.service';
import { GlobalConstants } from 'src/app/global-constants';
import { LoaderService } from 'src/app/loader.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import Utils from 'src/app/utils/utils';
import { AlertDialogService } from '../../shared/alert-dialog/alert-dialog.service';
import { ToasterService } from '../../toaster.service';
import { IAllocatorIncentiveTypes, IAllocatorService } from '../i-allocator.service';
var InvalidSelector = "INVALID_SELECTOR";
var SegmentLabels = {
    D: "Daily Captains",
    INTER: "Inter Captains",
    INTRA: "Intra Captains",
};
var dateFormat = "YYYY-MM-DD";
var IncentiveGeneratorComponent = /** @class */ (function () {
    function IncentiveGeneratorComponent(alertDialogService, loaderService, incentiveService, sharedService, toasterService, iallocatorService) {
        this.alertDialogService = alertDialogService;
        this.loaderService = loaderService;
        this.incentiveService = incentiveService;
        this.sharedService = sharedService;
        this.toasterService = toasterService;
        this.iallocatorService = iallocatorService;
        this.allSegmentsToggle = false;
        this.cities = Promise.resolve({});
        this.services = Promise.resolve({});
        this.groupedConstructs = [];
    }
    IncentiveGeneratorComponent.prototype.ngOnChanges = function () {
        var _this = this;
        var groupedConstructs = _.groupBy(this.constructs.segments, function (segment) { return _this.getGroupIdentifier(segment.label); });
        this.groupedConstructs = Object.entries(groupedConstructs)
            .map(function (_a) {
            var label = _a[0], segments = _a[1];
            return {
                label: SegmentLabels[label],
                segments: _.sortBy(segments, "label")
            };
        });
        var dateFormat = "DD-MM-YYYY";
        this.iMetricStatus = {
            dataStatus: this.constructs.imetricNumDays >= 28 ? "Complete" : "InComplete",
            dataLatestDate: moment(this.constructs.imetricLatestDate).format(dateFormat),
            selectorLatestDate: moment(this.constructs.selectorLatestDate).format(dateFormat),
        };
    };
    IncentiveGeneratorComponent.prototype.getGroupIdentifier = function (label) {
        return label.split("_")[1].toUpperCase();
    };
    IncentiveGeneratorComponent.prototype.ngOnInit = function () {
        this.cities = this.sharedService
            .fetchCities().toPromise()
            .then(function (r) { return r.data.cities; });
        this.services = this.sharedService
            .fetchServices(this.constructs.cityId).toPromise()
            .then(function (r) { return r.data; });
    };
    IncentiveGeneratorComponent.prototype.incentiveDays = function (incentive) {
        var days = incentive.days;
        var first = days[0];
        var last = days[days.length - 1];
        if (first == last) {
            return first;
        }
        return first + " - " + last;
    };
    IncentiveGeneratorComponent.prototype.showAlertBox = function (options, cb) {
        if (cb === void 0) { cb = function () { }; }
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed().subscribe(function (confirmed) { return confirmed && cb(); });
    };
    IncentiveGeneratorComponent.prototype.toggleAllSegments = function () {
        var _this = this;
        this.constructs.segments
            .forEach(function (segment) {
            segment.selected = _this.allSegmentsToggle;
        });
    };
    IncentiveGeneratorComponent.prototype.segmentToggled = function () {
        this.allSegmentsToggle = this.constructs.segments.every(function (segment) { return segment.selected; });
    };
    IncentiveGeneratorComponent.prototype.resetInvalidIPR = function (forecast) {
        var ipr = forecast.ipr;
        if (!ipr) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'Invalid IPR',
            }));
            forecast.ipr = IAllocatorService.defaultIPR;
        }
    };
    IncentiveGeneratorComponent.prototype.calculateBurn = function (slab) {
        return slab.ipr * slab.rides * slab.achievingCaptains;
    };
    IncentiveGeneratorComponent.prototype.calculateTotalBurn = function (forecastedData) {
        var _this = this;
        return _.flatMap(forecastedData, function (forecast) { return forecast.rideSlabs; })
            .reduce(function (total, slab) { return total + _this.calculateBurn(slab); }, 0);
    };
    IncentiveGeneratorComponent.prototype.calculateTotalBurnInTimeSlot = function (forecast) {
        var _this = this;
        var slabs = forecast.rideSlabs;
        var totalBurnInTimeSlot = 0;
        slabs.forEach(function (slab) {
            totalBurnInTimeSlot += _this.calculateBurn(slab);
        });
        return totalBurnInTimeSlot;
    };
    IncentiveGeneratorComponent.prototype.selectedSegmentTotals = function () {
        var _this = this;
        return this.constructs
            .segments
            .filter(function (segment) { return segment.selected; })
            .flatMap(function (segment) { return segment.segmentInfo; })
            .reduce(function (acc, incentive) {
            var forecastedData = incentive.forecastedData, netCaptains = incentive.netCaptains, netRides = incentive.netRides;
            return {
                netCaptains: acc.netCaptains + netCaptains,
                netRides: acc.netRides + netRides,
                totalBurn: acc.totalBurn + _this.calculateTotalBurn(forecastedData)
            };
        }, {
            netCaptains: 0,
            netRides: 0,
            totalBurn: 0
        });
    };
    IncentiveGeneratorComponent.prototype.confirmSelectedIncentives = function () {
        var _this = this;
        var selectedSegments = this.getSelectedSegments();
        var segmentsList = selectedSegments.map(function (segment) { return segment.label; });
        this.showAlertBox({
            title: "Create incentives",
            message: "Create incentives for following segments",
            confirmText: 'Confirm',
            value: segmentsList
        }, function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, created, failed, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.loaderService.openLoading();
                        return [4 /*yield*/, this.createIncentives(selectedSegments)];
                    case 1:
                        _a = _b.sent(), created = _a.created, failed = _a.failed;
                        this.showIncentiveCreationResult(created, failed);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _b.sent();
                        console.log(error_1);
                        return [2 /*return*/, this.incentiveCreationFailed(error_1)];
                    case 3:
                        this.loaderService.closeLoading();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    IncentiveGeneratorComponent.prototype.getSelectedSegments = function () {
        var selectedSegments = this.constructs
            .segments.filter(function (segment) { return segment.selected; });
        if (selectedSegments.length === 0) {
            this.showAlertBox({
                title: 'No Segments Selected',
                message: 'Please select at least one segment to proceed.',
            });
            throw new Error("No segments selected");
        }
        return selectedSegments;
    };
    IncentiveGeneratorComponent.prototype.incentiveCreationFailed = function (error) {
        console.error(error);
        return this.showAlertBox({
            title: 'Error',
            message: 'We are not able to create incentives at this point in time. Please try again after some time. If the issue persists, please reach out to the Captain-Retention team.',
        });
    };
    IncentiveGeneratorComponent.prototype.showIncentiveCreationResult = function (created, failed) {
        var _this = this;
        var details = [];
        created.length > 0 && details.push({
            message: "Following incentives were created successfully",
            value: created.map(function (r) { return r.message; }),
        });
        failed.length > 0 && details.push({
            message: "Failed to create following incentives",
            value: failed,
        });
        this.showAlertBox({
            title: "Result",
            details: details,
            confirmText: 'Download Constructs'
        }, function () { return _this.downloadSegments(created.map(function (c) { return c.incentive; })); });
    };
    IncentiveGeneratorComponent.prototype.downloadSegments = function (incentives) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cityId, cityName, fileName, formatTimeSlots, data, fields;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cityId = this.constructs.cityId;
                        return [4 /*yield*/, this.findCityShortName(cityId)];
                    case 1:
                        cityName = _a.sent();
                        fileName = this.constructs.incentiveType + "_" + cityName + "_" + this.constructs.startDate + "_" + moment().unix();
                        formatTimeSlots = function (goal) { return goal.timeSlot
                            .map(function (_a) {
                            var fromTime = _a.fromTime, toTime = _a.toTime;
                            return fromTime + " - " + toTime;
                        })
                            .join(", "); };
                        data = _.flatMap(incentives, function (incentive) {
                            var ruleName = incentive.ruleName, ruleId = incentive.ruleId, startDate = incentive.startDate, endDate = incentive.endDate, segment = incentive.segment;
                            return _.flatten(Object.values(incentive.goals))
                                .map(function (goal) {
                                var timeSlots = formatTimeSlots(goal);
                                var _a = goal.rules
                                    .reduce(function (res, rule) {
                                    var order = rule.order, amount = rule.amount;
                                    var totalEarning = res.totalEarning, prevTarget = res.prevTarget;
                                    var newTotalEarning = totalEarning + amount;
                                    var newOrders = order - prevTarget;
                                    var ipr = amount / newOrders;
                                    res.slabTargets.push(order);
                                    res.earningPotential.push(newTotalEarning);
                                    res.ipr.push(ipr);
                                    return tslib_1.__assign({}, res, { totalEarning: newTotalEarning, prevTarget: order });
                                }, { slabTargets: [], earningPotential: [], ipr: [], totalEarning: 0, prevTarget: 0 }), slabTargets = _a.slabTargets, earningPotential = _a.earningPotential, ipr = _a.ipr;
                                return {
                                    slabTargets: slabTargets.join(", "),
                                    earningPotential: earningPotential.join(", "),
                                    ipr: ipr.join(", "),
                                    timeSlots: timeSlots
                                };
                            })
                                .map(function (g) {
                                return [segment, ruleName, ruleId, startDate, endDate, g.timeSlots, g.slabTargets, g.earningPotential, g.ipr];
                            })
                                .concat([]);
                        });
                        fields = ["segment", "user_selector_name", "user_selector_id", "start_date", "end_date", "time_buckets", "slab_targets", "earning_potential", "slab_wise_ipr"];
                        Utils.downloadCSVFile({ fields: fields, data: data, fileName: fileName });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncentiveGeneratorComponent.prototype.createIncentives = function (segments) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, incentives, _i, incentives_1, incentive, startDate, endDate, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = { created: [], failed: [] };
                        return [4 /*yield*/, this.buildIncentives(segments)];
                    case 1:
                        incentives = _a.sent();
                        _i = 0, incentives_1 = incentives;
                        _a.label = 2;
                    case 2:
                        if (!(_i < incentives_1.length)) return [3 /*break*/, 7];
                        incentive = incentives_1[_i];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        if (incentive instanceof Error) {
                            throw incentive;
                        }
                        return [4 /*yield*/, this.incentiveService.createIncentive(incentive).toPromise()];
                    case 4:
                        _a.sent();
                        result.created.push({
                            message: incentive.incentiveName + " - Priority: " + incentive.priority, incentive: incentive
                        });
                        startDate = incentive.startDate, endDate = incentive.endDate;
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.SUCCESS,
                            message: "Created: " + incentive.incentiveName + ", Date: " + startDate + " - " + endDate + ", Priority: " + incentive.priority
                        }));
                        return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        console.log(error_2);
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Failed: " + error_2.message,
                        }));
                        result.failed.push(error_2.message);
                        return [3 /*break*/, 6];
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [2 /*return*/, result];
                }
            });
        });
    };
    IncentiveGeneratorComponent.prototype.buildIncentives = function (segments) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allCities, allServices, cityId, city, incentiveServices, serviceNames, serviceType, incentiveType, priority, incentives, _i, segments_1, segment, segmentIncentives;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cities];
                    case 1:
                        allCities = _a.sent();
                        return [4 /*yield*/, this.services];
                    case 2:
                        allServices = _a.sent();
                        cityId = this.constructs.cityId;
                        city = allCities.find(function (c) { return cityId === c._id; });
                        incentiveServices = allServices
                            .filter(function (s) { return _this.constructs.services.includes(s._id); });
                        serviceNames = incentiveServices.map(function (s) { return s.service.name; });
                        serviceType = incentiveServices.map(function (s) { return s._id; });
                        if (serviceType.length != this.constructs.services.length) {
                            throw new Error('Invalid services');
                        }
                        if (!city) {
                            throw new Error('Invalid city');
                        }
                        incentiveType = this.constructs.incentiveType.toUpperCase();
                        priority = this.constructs.priority;
                        incentives = [];
                        _i = 0, segments_1 = segments;
                        _a.label = 3;
                    case 3:
                        if (!(_i < segments_1.length)) return [3 /*break*/, 6];
                        segment = segments_1[_i];
                        return [4 /*yield*/, this.buildIncentive({
                                segment: segment, city: city, incentiveType: incentiveType, serviceType: serviceType,
                                serviceNames: serviceNames, priority: priority
                            })];
                    case 4:
                        segmentIncentives = _a.sent();
                        incentives = incentives.concat(segmentIncentives);
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/, incentives];
                }
            });
        });
    };
    IncentiveGeneratorComponent.prototype.buildIncentive = function (_a) {
        var segment = _a.segment, city = _a.city, incentiveType = _a.incentiveType, serviceType = _a.serviceType, serviceNames = _a.serviceNames, priority = _a.priority;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleId, ruleName, _b, name_1, selectorStatus, error_3, selectedVariable;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        ruleId = segment.selectorId;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.sharedService
                                .getUserSelectorDetails(ruleId).toPromise()];
                    case 2:
                        _b = _c.sent(), name_1 = _b.name, selectorStatus = _b.status;
                        if (selectorStatus != "COMPLETED") {
                            throw new Error("Selector is not completed");
                        }
                        ruleName = name_1;
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _c.sent();
                        return [2 /*return*/, [incentiveError(segment.label)]];
                    case 4:
                        selectedVariable = ["order"];
                        if (segment.distanceBaseTarget) {
                            selectedVariable.push("distance");
                        }
                        return [2 /*return*/, this.getSegmentConstructs(segment.segmentInfo)
                                .map(function (incentiveConstruct) {
                                var startDate = incentiveConstruct.startDate, endDate = incentiveConstruct.endDate;
                                return tslib_1.__assign({ type: "incentives", incentiveName: _this.getIncentiveName(segment, city.shortName, incentiveConstruct), incentiveType: _this.getIncentiveType(incentiveType), cities: [city.displayName], shift: [], label: "iAllocator", segment: segment.label, serviceType: serviceType,
                                    serviceNames: serviceNames,
                                    ruleName: ruleName,
                                    ruleId: ruleId,
                                    startDate: startDate,
                                    endDate: endDate,
                                    priority: priority, smsTemplate: GlobalConstants.smsTemplate[incentiveType], tnc: GlobalConstants.incentiveTncs, active: true, selectedCondition: "&&", selectedVariable: selectedVariable }, _this.createGoalsAndPredictions(segment, incentiveConstruct));
                            })];
                }
            });
        });
    };
    IncentiveGeneratorComponent.prototype.getSegmentConstructs = function (segmentConstructs) {
        var _this = this;
        var _a = this.constructs, startDate = _a.startDate, endDate = _a.endDate;
        if (this.constructs.incentiveType == IAllocatorIncentiveTypes.WEEKLY) {
            return [
                {
                    startDate: startDate,
                    endDate: endDate,
                    forecastedData: segmentConstructs
                        .flatMap(function (_a) {
                        var days = _a.days, forecastedData = _a.forecastedData, netRides = _a.netRides, netCaptains = _a.netCaptains;
                        return forecastedData
                            .map(function (fc) { return (tslib_1.__assign({}, fc, { days: days, netRides: netRides, netCaptains: netCaptains })); });
                    })
                }
            ];
        }
        var allDayDates = this.getDatesBetween(startDate, endDate)
            .map(function (d) { return ({
            date: d,
            day: _this.getWeekDay(d)
        }); })
            .reduce(function (acc, d) {
            if (!acc[d.day]) {
                acc[d.day] = [];
            }
            acc[d.day].push(d.date);
            return acc;
        }, {});
        return segmentConstructs
            .flatMap(function (_a) {
            var days = _a.days, forecastedData = _a.forecastedData, netRides = _a.netRides, netCaptains = _a.netCaptains;
            var first = days[0];
            var last = days.length - 1;
            return allDayDates[first]
                .map(function (startDate) { return ({
                startDate: startDate,
                endDate: moment(startDate).add(last, "days").format(dateFormat),
                forecastedData: forecastedData,
                netRides: netRides, netCaptains: netCaptains
            }); });
        });
    };
    IncentiveGeneratorComponent.prototype.getWeekDay = function (d) {
        moment(d).endOf("isoWeek");
        return moment(d).format("dddd");
    };
    IncentiveGeneratorComponent.prototype.getDatesBetween = function (startDate, endDate) {
        var dates = [startDate];
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.format(dateFormat));
        }
        return dates;
    };
    IncentiveGeneratorComponent.prototype.createSlabRules = function (slabs, isDistanceTargetEnabled) {
        var _this = this;
        return slabs.map(function (slab, index) {
            var slabRule = {
                order: slab.rideTarget,
                amount: _this.slabCreditAmount(slab),
                index: index + 1
            };
            if (isDistanceTargetEnabled) {
                slabRule["distance"] = slab.distanceTarget;
            }
            return slabRule;
        });
    };
    ;
    IncentiveGeneratorComponent.prototype.createGoalsAndPredictions = function (segment, incentive) {
        var _this = this;
        var isDistanceTargetEnabled = segment.distanceBaseTarget;
        return incentive.forecastedData
            .reduce(function (acc, forecast) {
            var goals = acc.goals, allocatorPredictions = acc.allocatorPredictions;
            var daysKey = _this.createDaysKey(forecast);
            var uuid = GlobalConstants.generateUuid();
            var goal = {
                timeSlot: forecast.timeSlotsProjection,
                rules: _this.createSlabRules(forecast.rideSlabs, isDistanceTargetEnabled),
                uuid: uuid
            };
            goals[daysKey] = goals[daysKey] || [];
            goals[daysKey].push(goal);
            var _a = forecast.timeSlotsProjection[0], netRides = _a.netRides, netCaptains = _a.netCaptains;
            allocatorPredictions
                .forecasts
                .push({
                uuid: uuid,
                netRides: netRides,
                netCaptains: netCaptains,
                rideSlabs: _this.createSlabForecasts(forecast.rideSlabs, isDistanceTargetEnabled)
            });
            return acc;
        }, {
            goals: {},
            allocatorPredictions: {
                forecasts: [],
                netCaptains: segment.segmentInfo.netCaptains,
                netRides: segment.segmentInfo.netRides,
                selectorSize: segment.selectorSize
            }
        });
    };
    IncentiveGeneratorComponent.prototype.createDaysKey = function (_a) {
        var days = _a.days;
        if (this.constructs.incentiveType == IAllocatorIncentiveTypes.DAILY) {
            return GlobalConstants.AllIncentiveWeekDays.join(', ');
        }
        return days.join(", ");
    };
    IncentiveGeneratorComponent.prototype.createSlabForecasts = function (rideSlabs, isDistanceTargetEnabled) {
        return rideSlabs.map(function (slab, index) {
            var slabForecast = {
                index: index + 1,
                achievingCaptains: slab.achievingCaptains,
                bauIPR: slab.bauIPR,
                suggestedIPR: slab.suggestedIPR,
                finalIPR: slab.ipr,
                finalAchievingCaptains: slab.achievingCaptains
            };
            if (isDistanceTargetEnabled) {
                slabForecast["distancePerOrder"] = slab.perOrderDistance;
                slabForecast["distanceTarget"] = slab.distanceTarget;
            }
            return slabForecast;
        });
    };
    IncentiveGeneratorComponent.prototype.slabCreditAmount = function (slab) {
        return Math.floor(slab.rides * slab.ipr);
    };
    IncentiveGeneratorComponent.prototype.earningPotential = function (forecast) {
        var _this = this;
        var slabs = forecast.rideSlabs;
        var target = slabs[slabs.length - 1].rideTarget;
        return {
            total: slabs.map(function (slab) { return _this.slabCreditAmount(slab); })
                .reduce(function (a, b) { return a + b; }),
            target: target
        };
    };
    IncentiveGeneratorComponent.prototype.getIncentiveName = function (segment, cityName, incentiveConstruct) {
        var incentiveType = this.constructs.incentiveType;
        var startDate = incentiveConstruct.startDate, endDate = incentiveConstruct.endDate;
        var shortStartDate = moment(startDate).format('DDMMYYYY');
        var shortEndDate = moment(endDate).format('DDMMYYYY');
        var incentiveName = "ALLOCATOR_" + cityName + "_" + segment.label + "_" + shortStartDate + "_" + shortEndDate + "_" + incentiveType;
        return incentiveName.toUpperCase();
    };
    IncentiveGeneratorComponent.prototype.getIncentiveType = function (incentiveType) {
        return GlobalConstants.IAllocatorIncentiveTypes[incentiveType];
    };
    IncentiveGeneratorComponent.prototype.csvForUserSelectorIdAndUserId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userSelectorIdsWithLabels, data, cityName, fileName, fields;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userSelectorIdsWithLabels = this.getSelectedSegments()
                            .map(function (selectedSegment) { return [selectedSegment.selectorId, selectedSegment.label]; });
                        return [4 /*yield*/, this.iallocatorService.fetchAllUserFor(userSelectorIdsWithLabels)];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.findCityShortName(this.constructs.cityId)];
                    case 2:
                        cityName = _a.sent();
                        fileName = "selectors_" + cityName + "_" + this.dateToday() + "_" + this.getCurrentTime();
                        fields = ["segment_name", "selector_id", "rider_id"];
                        Utils.downloadCSVFile({ fields: fields, data: data, fileName: fileName });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncentiveGeneratorComponent.prototype.dateToday = function () {
        return moment().format(dateFormat);
    };
    IncentiveGeneratorComponent.prototype.getCurrentTime = function () {
        return moment().format('HH:mm:ss');
    };
    IncentiveGeneratorComponent.prototype.findCityShortName = function (cityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allCities;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cities];
                    case 1:
                        allCities = _a.sent();
                        return [2 /*return*/, allCities.find(function (c) { return cityId === c._id; }).shortName.toUpperCase()];
                }
            });
        });
    };
    return IncentiveGeneratorComponent;
}());
export { IncentiveGeneratorComponent };
function incentiveError(segment) {
    var err = new Error(segment + " : User selector not created");
    err["type"] = InvalidSelector;
    return err;
}
