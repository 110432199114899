import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ToastMessage } from '../../toast-message/toast-message.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/components/common/api';
import * as _ from 'lodash';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { ViewIncentivesService } from 'src/app/view-incentives/view-incentives.service';
var GoalsComponent = /** @class */ (function () {
    function GoalsComponent(datePipe, fb, toastMessage, sharedService, confirmationService, changeDetectorRef, toasterService, incentiveService) {
        this.datePipe = datePipe;
        this.fb = fb;
        this.toastMessage = toastMessage;
        this.sharedService = sharedService;
        this.confirmationService = confirmationService;
        this.changeDetectorRef = changeDetectorRef;
        this.toasterService = toasterService;
        this.incentiveService = incentiveService;
        this.goalsInfo = new EventEmitter();
        this.back = new EventEmitter();
        this.selectedVariable = [];
        this.condition = '&&';
        this.nextClicked = false;
        this.warningAlert = false; // alert to show when more variables are selected
        this.rules = [];
        this.confirmationValue = true;
        this.blockServiceDetailIdsForIncentiveCreation = new Set(environment.blockServiceDetailIdsForIncentiveCreation);
        this.displayGoalConfirmation = false; //confirmation box before changing the variables
        this.dailyIncentiveData = [
            {
                'days': 'All',
            }
        ];
        this.NOT_EDITABLE = {
            variables: false,
            timings: false,
            goals: false,
            weeklyDays: false,
        };
        this.weeklyIncentiveData = [
            {
                'days': 'All',
                'startDay': { day: 'Monday', index: 1 },
                'endDay': { day: '', index: null }
            }
        ];
        this.variableError = false;
        this.days = [{ day: '', index: 0 }, { day: 'Monday', index: 1 }, { day: 'Tuesday', index: 2 },
            { day: 'Wednesday', index: 3 }, { day: 'Thursday', index: 4 },
            { day: 'Friday', index: 5 }, { day: 'Saturday', index: 6 },
            { day: 'Sunday', index: 7 }];
        this.subSetCount = [];
        this.orderChecked = false;
        this.distanceChecked = false;
        this.cancellationChecked = false;
        this.cancellationLimitScope = "";
        this.cancellationGlobalLimit = 0;
        this.setsCancellationLimit = [0];
        this.maxOrderDistanceChecked = false;
        this.maxOrderDistanceGlobal = 0;
        this.qualityChecked = false;
        this.qualitativeMetrics = ['APR', 'DPR'];
        this.dailyIncentiveTimeBuckets = environment.incentiveTimeBuckets;
        this.overlappingTimeBuckets = {};
        this.existingIncentives = null;
        this.errorPopupIncentives = [];
        this.hours = this.getHours();
        this.startMinutes = [{ "value": "00" }, { "value": "15" }, { "value": "30" }, { "value": "45" }];
        this.endMinutes = [{ "value": "14" }, { "value": "29" }, { "value": "44" }, { "value": "59" }];
        this.totalOrdersMap = new Map();
        this.totalDistanceMap = new Map();
        this.totalAmountMap = new Map();
        this.ruleWarnAmountLimit = environment.ruleWarnAmountLimit;
        this.ruleMaxAmountLimit = environment.ruleMaxAmountLimit;
        this.setWarnAmountLimit = environment.setWarnAmountLimit;
        this.setMaxAmountLimit = environment.setMaxAmountLimit;
        // public bonusType = 'Bonus Amt';
        this.redeemCondition = 'flat';
        this.goalsFormGroup = new FormGroup({
            selectedCondition: new FormControl('flat', Validators.required),
            redeemGoals: this.fb.array([]),
        });
    }
    GoalsComponent.prototype.getHours = function () {
        var hours = [];
        var hourValues = ["00", "01", "02", "03", "04", "05", "06", "07",
            "08", "09", "10", "11", "12", "13", "14",
            "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
        Array.from(hourValues).forEach(function (hour) {
            hours.push({ "value": hour });
        });
        return hours;
    };
    GoalsComponent.prototype.preventDefault = function (event) {
        event.stopPropagation();
    };
    GoalsComponent.prototype.findIndexOfDay = function (day) {
        for (var _i = 0, _a = this.days; _i < _a.length; _i++) {
            var dayObj = _a[_i];
            if (dayObj && dayObj.day && dayObj.day === day.trim()) {
                return dayObj.index;
            }
        }
        return -1;
    };
    GoalsComponent.prototype.patchWeeklyDefaultValue = function (state, defaultValue) {
        var _this = this;
        var selectedCondition = defaultValue.selectedCondition;
        this.goalsFormGroup.patchValue({
            selectedCondition: selectedCondition,
        });
        this.condition = selectedCondition;
        var incentives = [];
        var formattedWeeklyincentiveData;
        if (!defaultValue.goals) {
            return;
        }
        Object.keys(defaultValue.goals).forEach(function (goalInDays, index) {
            formattedWeeklyincentiveData = {};
            formattedWeeklyincentiveData['days'] = goalInDays;
            var weekDays = goalInDays.split(',');
            formattedWeeklyincentiveData['startDay'] = { day: weekDays[0].trim(), index: _this.findIndexOfDay(weekDays[0]) };
            formattedWeeklyincentiveData['endDay'] = {
                day: weekDays[weekDays.length - 1].trim(),
                index: _this.findIndexOfDay(weekDays[weekDays.length - 1])
            };
            var weekWiseIncentive = defaultValue.goals[goalInDays];
            weekWiseIncentive.map(function (incentive) {
                incentive.timeSlot = incentive.timeSlot.map(function (slot) {
                    var _a = slot.fromTime.split(":"), fromTimeHour = _a[0], fromTimeMin = _a[1];
                    var _b = slot.toTime.split(":"), toTimeHour = _b[0], toTimeMin = _b[1];
                    return {
                        fromTime: _this.timeToTimeStamp(slot.fromTime),
                        toTime: _this.timeToTimeStamp(slot.toTime),
                        fromTimeHour: { value: fromTimeHour },
                        fromTimeMin: { value: fromTimeMin },
                        toTimeHour: { value: toTimeHour },
                        toTimeMin: { value: toTimeMin },
                    };
                });
                //make orders, distance non cumulative for display
                incentive.rules = _this.makeOrdersAndDistanceNonCumulative(incentive.rules);
            });
            if (state === _this.sharedService.STATE.EDIT) {
                var incentiveStartDate = new Date(defaultValue.startDate).getTime();
                var currentDate = new Date().getTime();
                var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
                if ((t) >= 7) {
                    formattedWeeklyincentiveData['disabled'] = true;
                }
                else if (t <= 6 && t >= 0) {
                    if (formattedWeeklyincentiveData['startDay'].index <= new Date().getUTCDay()) {
                        formattedWeeklyincentiveData['disabled'] = true;
                    }
                }
            }
            weekWiseIncentive.forEach(function (incentive) {
                formattedWeeklyincentiveData['1'] = incentive;
                incentives.push(formattedWeeklyincentiveData);
            });
        });
        var keepWatchOnBasicDetails = function () {
            return new Promise(function (resolve, reject) {
                var timer = setInterval(function () {
                    if (_this.incentiveData && _this.incentiveData.basicInfo) {
                        clearTimeout(timer);
                        return resolve({});
                    }
                }, 1000);
            });
        };
        var addAllVariable = [];
        defaultValue.selectedVariable.forEach(function (variable) {
            switch (variable) {
                case 'order': {
                    _this.orderChecked = true;
                    break;
                }
                case 'distance': {
                    _this.distanceChecked = true;
                    break;
                }
                case 'quality': {
                    _this.qualityChecked = true;
                    break;
                }
                case 'maxOrderDistance': {
                    _this.maxOrderDistanceChecked = true;
                    break;
                }
            }
            addAllVariable.push(keepWatchOnBasicDetails()
                .then(function () {
                console.log('patching');
                _this.addVariables(variable, null, true);
            }));
        });
        Promise.all(addAllVariable).then(function () {
            // this.weeklyIncentiveData.push(formattedWeeklyincentiveData);
            _this.weeklyIncentiveData = incentives;
        }).then(function () {
            for (var index in _this.weeklyIncentiveData) {
                var incentiveData = incentives[index]['1'];
                _this.reComputeTotalAmount(index, incentiveData.rules);
                _this.reComputeTotalDistance(index, incentiveData.rules);
                _this.reComputeTotalOrders(index, incentiveData.rules);
            }
        });
    };
    GoalsComponent.prototype.patchDailyDefaultValue = function (defaultValue) {
        var _this = this;
        var selectedCondition = defaultValue.selectedCondition;
        this.goalsFormGroup.patchValue({
            selectedCondition: selectedCondition,
        });
        this.condition = selectedCondition;
        var incentives = [];
        var formattedDailyincentiveData = {
            'days': 'All',
        };
        Object.keys(defaultValue.goals).forEach(function (goalInDays, index) {
            var dayWiseIncentive = defaultValue.goals[goalInDays];
            dayWiseIncentive.map(function (incentive) {
                incentive.timeSlot = incentive.timeSlot.map(function (slot) {
                    var _a = slot.fromTime.split(":"), fromTimeHour = _a[0], fromTimeMin = _a[1];
                    var _b = slot.toTime.split(":"), toTimeHour = _b[0], toTimeMin = _b[1];
                    return {
                        fromTime: _this.timeToTimeStamp(slot.fromTime),
                        toTime: _this.timeToTimeStamp(slot.toTime),
                        fromTimeHour: { value: fromTimeHour },
                        fromTimeMin: { value: fromTimeMin },
                        toTimeHour: { value: toTimeHour },
                        toTimeMin: { value: toTimeMin },
                    };
                });
                //make orders, distance non cumulative for display
                incentive.rules = _this.makeOrdersAndDistanceNonCumulative(incentive.rules);
            });
            dayWiseIncentive.forEach(function (incentive, index) {
                formattedDailyincentiveData[index + 1] = incentive;
            });
        });
        var keepWatchOnBasicDetails = function () {
            return new Promise(function (resolve, reject) {
                var timer = setInterval(function () {
                    if (_this.incentiveData && _this.incentiveData.basicInfo) {
                        clearTimeout(timer);
                        return resolve({});
                    }
                }, 1000);
            });
        };
        var addAllVariable = [];
        defaultValue.selectedVariable.forEach(function (variable) {
            switch (variable) {
                case 'order': {
                    _this.orderChecked = true;
                    break;
                }
                case 'distance': {
                    _this.distanceChecked = true;
                    break;
                }
                case 'quality': {
                    _this.qualityChecked = true;
                    break;
                }
                case 'cancellation': {
                    _this.cancellationChecked = true;
                }
                case 'maxOrderDistance': {
                    _this.maxOrderDistanceChecked = true;
                }
            }
            var goalsKey = Object.keys(defaultValue.goals)[0];
            _this.setsCancellationLimit = [];
            _this.cancellationGlobalLimit = defaultValue.cancellationLimit;
            _this.maxOrderDistanceGlobal = defaultValue.maxOrderDistance;
            for (var _i = 0, _a = defaultValue.goals[goalsKey]; _i < _a.length; _i++) {
                var set = _a[_i];
                _this.setsCancellationLimit.push(set.cancellationLimit);
            }
            if (_this.cancellationChecked) {
                if (defaultValue.cancellationLimit != 0) {
                    _this.cancellationLimitScope = "global";
                }
                else {
                    _this.cancellationLimitScope = "set";
                }
            }
            addAllVariable.push(keepWatchOnBasicDetails()
                .then(function () {
                console.log('patching');
                _this.addVariables(variable, null, true);
            }));
        });
        Promise.all(addAllVariable).then(function () {
            _this.dailyIncentiveData = [formattedDailyincentiveData];
        }).then(function () {
            var incentives = _this.dailyIncentiveData[0];
            for (var index in incentives) {
                if (index !== 'days') {
                    _this.reComputeTotalAmount(index, incentives[index].rules);
                    _this.reComputeTotalDistance(index, incentives[index].rules);
                    _this.reComputeTotalOrders(index, incentives[index].rules);
                }
            }
        });
    };
    GoalsComponent.prototype.ngOnChanges = function (changes) {
        if (this.incentiveData && this.incentiveData.basicInfo && !this.incentiveData.basicInfo.isCancellationLimitEnabledForCity) {
            this.cancellationChecked = false;
            this.cancellationGlobalLimit = 0;
            this.cancellationLimitScope = "";
            this.setsCancellationLimit = this.setsCancellationLimit.map(function (x) { return 0; });
        }
        if (this.incentiveData && this.incentiveData.basicInfo && !this.incentiveData.basicInfo.isMaxOrderDistanceEnabledForCity) {
            this.maxOrderDistanceChecked = false;
            this.maxOrderDistanceGlobal = 0;
        }
        if (changes.incentiveData) {
            var previous = changes.incentiveData.previousValue;
            this.incentiveData = changes.incentiveData.currentValue;
            if (previous && this.incentiveData) {
                if (previous.basicInfo && (previous.basicInfo.incentiveType !== this.incentiveData.basicInfo.incentiveType)) {
                    this.dailyIncentiveData = [
                        {
                            'days': 'All',
                        }
                    ];
                    this.weeklyIncentiveData = [
                        {
                            'days': 'All',
                            'startDay': { day: 'Monday', index: 1 },
                            'endDay': { day: '', index: null }
                        }
                    ];
                    this.selectedVariable = [];
                    this.goalsFormGroup = new FormGroup({
                        selectedCondition: new FormControl('', Validators.required),
                        redeemGoals: this.fb.array([]),
                    });
                    this.goalsFormGroup.patchValue({ selectedCondition: 'flat' });
                }
            }
            if (this.incentiveData.basicInfo) {
                this.header = this.incentiveData.basicInfo.incentiveType + ' incentive - ' +
                    this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy')
                    + ' to ' + this.datePipe.transform(this.incentiveData.basicInfo.endDate, 'd MMMM yy');
                if (this.incentiveData.basicInfo.incentiveType == 'Adhoc') {
                    this.header = this.incentiveData.basicInfo.incentiveType + ' incentive - ' + this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy');
                }
                if (this.incentiveData.basicInfo.incentiveType === 'Redeem' && this.redeemIncentives.value.length === 0) {
                    this.addRedeemSet();
                }
            }
            if (changes.incentiveData.previousValue && !_.isEqual(changes.incentiveData.currentValue.basicInfo, changes.incentiveData.previousValue.basicInfo)) {
                this.existingIncentives = null;
                this.checkTimeSlots();
            }
        }
    };
    GoalsComponent.prototype.findTypeOfIncentive = function (state, defaultValue) {
        if (defaultValue && (defaultValue.incentiveType === 'Daily' || defaultValue.incentiveType === 'Adhoc')) {
            this.patchDailyDefaultValue(defaultValue);
        }
        else {
            this.patchWeeklyDefaultValue(state, defaultValue);
        }
    };
    GoalsComponent.prototype.ngOnInit = function () {
        this.goalsFormGroup.patchValue({ selectedCondition: 'flat' });
        this.findTypeOfIncentive(this.state, this.defaultValue);
    };
    GoalsComponent.prototype.conditionChange = function (condition) {
        this.goalsFormGroup = new FormGroup({
            selectedCondition: new FormControl(condition, Validators.required),
            redeemGoals: this.fb.array([])
        });
        this.redeemCondition = condition;
        this.addRedeemSet();
    };
    GoalsComponent.prototype.onDailyIncentiveTimeClick = function () {
        $('#dailyIncenitveTiming').animate({ scrollTop: 200 }, 1000);
    };
    GoalsComponent.prototype.onWeeklyIncentiveTimeClick = function () {
        $('#weeklyIncentive').animate({ scrollTop: 200 }, 1000);
    };
    GoalsComponent.prototype.getValues = function (formControl) {
        return this.goalsFormGroup.get(formControl).value;
    };
    GoalsComponent.prototype.erroMessage = function (message) {
        if (message === void 0) { message = "Please check your data"; }
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message,
        }));
    };
    GoalsComponent.prototype.checkRulesValdity = function (incentiveData) {
        if (incentiveData === void 0) { incentiveData = []; }
        if (this.incentiveData.basicInfo.incentiveType == "Adhoc"
            && this.incentiveData.basicInfo.incentiveDisplayName == "Short Ride Incentive"
            && !this.maxOrderDistanceChecked) {
            return [false, "The max order distance must be selected for Short Ride Incentive."];
        }
        if (this.incentiveData.basicInfo.incentiveType == "Adhoc" && this.maxOrderDistanceChecked) {
            if (!this.maxOrderDistanceGlobal) {
                return [false, "The max order distance must be greater than 0."];
            }
            if (this.countDecimals(this.maxOrderDistanceGlobal) > 2) {
                return [false, "The max order distance must be rounded to two decimals."];
            }
        }
        var isDistanceSelected = this.selectedVariable.includes('distance');
        var isOrder = this.selectedVariable.includes('order');
        for (var _i = 0, incentiveData_1 = incentiveData; _i < incentiveData_1.length; _i++) {
            var incentives = incentiveData_1[_i];
            var incentiveKeys = Object.keys(incentives);
            for (var _a = 0, incentiveKeys_1 = incentiveKeys; _a < incentiveKeys_1.length; _a++) {
                var key = incentiveKeys_1[_a];
                var currentSlot = incentives[key] || {};
                var rules = currentSlot.rules || [];
                for (var _b = 0, rules_1 = rules; _b < rules_1.length; _b++) {
                    var rule = rules_1[_b];
                    if (isOrder && (!rule.order || rule.order < 1)) {
                        return [false, "The target order(s) should be more than 0."];
                    }
                    if (!rule.amount || rule.amount < 1) {
                        return [false, "The amount should be more than 0 INR."];
                    }
                    if (rule.quality !== undefined && this.qualityChecked && (rule.quality.value == null || rule.quality.value < 0)) {
                        return [false, "Percentage should be postive or 0"];
                    }
                    if (rule.quality !== undefined && this.qualityChecked && (rule.quality.amount == null || rule.quality.amount < 0)) {
                        return [false, "Amount should be postive or 0"];
                    }
                    if (rule.quality !== undefined && this.qualityChecked && !rule.quality.metric) {
                        return [false, "Please select APR/DPR Quality Metric"];
                    }
                    if (isDistanceSelected && (!rule.distance || rule.distance < 1)) {
                        return [false, "The target distance should be more than 0 Km."];
                    }
                    if (rule.order) {
                        var orderString = rule.order.toString();
                        if (orderString.split('.').length > 1) {
                            return [false, "The target order(s) should not be a decimal"];
                        }
                    }
                    if (rule.distance) {
                        var distanceString = rule.distance.toString();
                        if (distanceString.split('.').length > 1) {
                            return [false, "The target distance should not be a decimal"];
                        }
                    }
                    if (rule.amount) {
                        var amountString = rule.amount.toString();
                        if (amountString.split('.').length > 1) {
                            return [false, "Amount should not be a decimal"];
                        }
                    }
                    if (rule.quality !== undefined && rule.quality.amount) {
                        var amountString = rule.quality.amount.toString();
                        if (amountString.split('.').length > 1) {
                            return [false, "APR/DPR Amount should not be a decimal"];
                        }
                    }
                }
            }
        }
        return [true, ''];
    };
    GoalsComponent.prototype.countDecimals = function (value) {
        if ((value % 1) != 0)
            return value.toString().split(".")[1].length;
        return 0;
    };
    ;
    GoalsComponent.prototype.timeToTimeStamp = function (time) {
        if (time === void 0) { time = "16:10"; }
        var _a = time.split(':'), min = _a[0], sec = _a[1];
        if (!min || !sec) {
            return '';
        }
        var timeStamp = new Date();
        timeStamp.setHours(parseInt(min));
        timeStamp.setMinutes(parseInt(sec));
        return timeStamp;
    };
    GoalsComponent.prototype.validateIncentiveData = function (incentiveData, incentiveType, isCancellationChecked, datePipe) {
        if (isCancellationChecked === void 0) { isCancellationChecked = false; }
        if (datePipe === void 0) { datePipe = null; }
        if (incentiveType === 'Daily' || incentiveType === 'Adhoc') {
            var _a = this.isDailyIncentiveTimeSlotsUnique(incentiveData, isCancellationChecked, datePipe), isDailyIncentiveSlotsValid = _a[0], errMsg = _a[1];
            if (!isDailyIncentiveSlotsValid) {
                this.erroMessage(errMsg);
                return false;
            }
        }
        if (this.state !== this.sharedService.STATE.EDIT && this.setAmountLimitExceeded()) {
            this.erroMessage("Total amount for a set exceeded limit of " + this.setMaxAmountLimit);
            return false;
        }
        var _b = this.checkRulesValdity(incentiveData), valid = _b[0], err = _b[1];
        if (!valid) {
            return this.erroMessage(err);
        }
        for (var index in incentiveData) {
            if (index) {
                var keys = Object.keys(incentiveData[index]);
                for (var key in keys) {
                    if (keys[key]) {
                        for (var time in incentiveData[index][keys[key]].timeSlot) {
                            if (time) {
                                if (incentiveData[index][keys[key]].timeSlot[time].fromTime === null ||
                                    incentiveData[index][keys[key]].timeSlot[time].toTime === null) {
                                    this.erroMessage();
                                    return false;
                                }
                            }
                        }
                        for (var rule in incentiveData[index][keys[key]].rules) {
                            if (rule) {
                                if (incentiveData[index][keys[key]].rules[rule].order &&
                                    incentiveData[index][keys[key]].rules[rule].order === null) {
                                    this.erroMessage();
                                    return false;
                                }
                                if (incentiveData[index][keys[key]].rules[rule].distance &&
                                    incentiveData[index][keys[key]].rules[rule].distance === null) {
                                    this.erroMessage();
                                    return false;
                                }
                                if (incentiveData[index][keys[key]].rules[rule].amount) {
                                    if (incentiveData[index][keys[key]].rules[rule].amount === null) {
                                        this.erroMessage();
                                        return false;
                                    }
                                    if (this.state !== this.sharedService.STATE.EDIT) {
                                        if (incentiveData[index][keys[key]].rules[rule].quality
                                            && incentiveData[index][keys[key]].rules[rule].quality.amount
                                            && (incentiveData[index][keys[key]].rules[rule].quality.amount + incentiveData[index][keys[key]].rules[rule].amount > this.ruleMaxAmountLimit)) {
                                            this.erroMessage("Individual rule amount value is greater than limit " + this.ruleMaxAmountLimit);
                                            return false;
                                        }
                                        else if (incentiveData[index][keys[key]].rules[rule].amount > this.ruleMaxAmountLimit) {
                                            this.erroMessage("Individual rule amount value is greater than limit " + this.ruleMaxAmountLimit);
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    };
    GoalsComponent.prototype.isDailyIncentiveTimeSlotsUnique = function (dailyIncentiveData, isCancellationChecked, datePipe) {
        var ERROR = {
            OVERLAPPING_TIME_SLOTS: 'Overlapping time LO',
            INVALID_TIME_SLOTS: 'Invalid time slot',
            INVALID_MINS_FOR_CANCELLATION: 'Since cancellation is checked, minute value of both start and end time should be 00'
        };
        var dailyIncenitveTimeSlots = dailyIncentiveData.reduce(function (acc, slotsData) {
            Object.keys(slotsData).forEach(function (key) {
                var slotData = slotsData[key];
                var timeSlots = slotData.timeSlot || [];
                acc = acc.concat(timeSlots);
            });
            return acc;
        }, []);
        for (var _i = 0, dailyIncenitveTimeSlots_1 = dailyIncenitveTimeSlots; _i < dailyIncenitveTimeSlots_1.length; _i++) {
            var timeSlot = dailyIncenitveTimeSlots_1[_i];
            if (!isValidTimeSlot(timeSlot)) {
                return [false, ERROR.INVALID_TIME_SLOTS];
            }
            if (!isMinutesValidForCancellationSelected(timeSlot, isCancellationChecked, datePipe)) {
                return [false, ERROR.INVALID_MINS_FOR_CANCELLATION];
            }
        }
        var _a = isUniqueTimeSlots(dailyIncenitveTimeSlots), validTimeSlot = _a[0], errMsg = _a[1];
        if (!validTimeSlot) {
            return [false, errMsg];
        }
        return [true, ''];
        function isUniqueTimeSlots(timeSlots) {
            if (timeSlots === void 0) { timeSlots = [{ fromTime: '', toTime: '' }]; }
            for (var i = 0; i < timeSlots.length; i++) {
                for (var j = i + 1; j < timeSlots.length; j++) {
                    if (isTimeSlotOverLapping(timeSlots[i], timeSlots[j])) {
                        return [false, ERROR.OVERLAPPING_TIME_SLOTS];
                    }
                }
            }
            return [true, ''];
        }
        function isValidTimeSlot(timeSlot) {
            return timeSlot.fromTime < timeSlot.toTime;
        }
        function isMinutesValidForCancellationSelected(timeSlot, isCancellationChecked, datePipe) {
            if (timeSlot === void 0) { timeSlot = { fromTime: '', toTime: '' }; }
            var minutesValidation = true;
            if (isCancellationChecked) {
                var fromTimeMinutes = datePipe.transform(timeSlot.fromTime, 'HH:mm').split(":")[1];
                var toTimeMinutes = datePipe.transform(timeSlot.toTime, 'HH:mm').split(":")[1];
                minutesValidation = fromTimeMinutes == "00" && toTimeMinutes == "00";
            }
            return minutesValidation;
        }
        function isTimeSlotsEqual(timeSlot1, timeSlot2) {
            return timeSlot1.fromTime === timeSlot2.fromTime &&
                timeSlot1.toTime === timeSlot2.toTime;
        }
        function changeDateToTodays(date) {
            if (!date) {
                return date;
            }
            var currentDate = new Date();
            var selectedSlot = new Date(date);
            selectedSlot.setDate(currentDate.getDate());
            selectedSlot.setMonth(currentDate.getMonth());
            selectedSlot.setDate(currentDate.getDate());
            selectedSlot.setSeconds(0);
            return selectedSlot;
        }
        function isTimeSlotOverLapping(timeSlot1, timeSlot2) {
            timeSlot1.fromTime = changeDateToTodays(timeSlot1.fromTime);
            timeSlot1.toTime = changeDateToTodays(timeSlot1.toTime);
            timeSlot2.fromTime = changeDateToTodays(timeSlot2.fromTime);
            timeSlot2.toTime = changeDateToTodays(timeSlot2.toTime);
            if (isTimeSlotsEqual(timeSlot1, timeSlot2)) {
                return true;
            }
            if (timeSlot1.fromTime < timeSlot2.fromTime) {
                if (timeSlot1.toTime > timeSlot2.fromTime) {
                    return true;
                }
            }
            if (timeSlot2.fromTime < timeSlot1.fromTime) {
                if (timeSlot2.toTime > timeSlot1.fromTime) {
                    return true;
                }
            }
            return false;
        }
    };
    GoalsComponent.prototype.onOrderValueChange = function (obj) {
        if (obj.order < 1) {
            obj.order = '';
        }
    };
    GoalsComponent.prototype.checkIfDaysOverlap = function () {
        var weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        var weekDayCount = new Array(7).fill(0);
        for (var index in this.weeklyIncentiveData) {
            if (this.weeklyIncentiveData[index] && this.weeklyIncentiveData[index].days) {
                var selectedDays = this.weeklyIncentiveData[index].days;
                var splitSelectedDays = selectedDays.split(',');
                for (var _i = 0, splitSelectedDays_1 = splitSelectedDays; _i < splitSelectedDays_1.length; _i++) {
                    var day = splitSelectedDays_1[_i];
                    if (day) {
                        var dayIndex = weekDays.indexOf(day.trim());
                        weekDayCount[dayIndex] = weekDayCount[dayIndex] + 1;
                    }
                }
            }
        }
        for (var _a = 0, weekDayCount_1 = weekDayCount; _a < weekDayCount_1.length; _a++) {
            var weekIndex = weekDayCount_1[_a];
            if (weekIndex > 1) {
                return true;
            }
        }
        return false;
    };
    GoalsComponent.prototype.passGoalsInfo = function () {
        var _this = this;
        this.nextClicked = true;
        var goalsInfo = {};
        if (this.incentiveData.basicInfo.incentiveType === 'Redeem') {
            if (!this.getValues('selectedCondition')) {
                this.erroMessage();
                return;
            }
            for (var redeem in this.redeemIncentives.value) {
                if (redeem) {
                    if (this.redeemIncentives.value[redeem].redeemAmount === null) {
                        this.erroMessage();
                        return;
                    }
                    if (this.redeemIncentives.value[redeem].redeemAmount <= 0) {
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Redeem should be greater than 0",
                        }));
                        return;
                    }
                    if (this.redeemIncentives.value[redeem].bonusAmount <= 0) {
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Bonus amount should be greater than 0",
                        }));
                        return;
                    }
                }
            }
            goalsInfo['selectedVariable'] = this.selectedVariable;
            goalsInfo['selectedCondition'] = this.getValues('selectedCondition');
            goalsInfo['redeem'] = this.redeemIncentives.value;
            this.goalsInfo.emit(goalsInfo);
        }
        else if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
            if (this.selectedVariable.length === 0) {
                this.erroMessage();
                this.variableError = true;
                return;
            }
            if (this.state === 'edit') {
                var sdidIsBlocked = this.incentiveData.basicInfo.service.some(function (ser) { return _this.blockServiceDetailIdsForIncentiveCreation.has(ser); });
                if (sdidIsBlocked) {
                    this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: 'Cannot edit incentive as it is blocked for creation/edit',
                    }));
                    return;
                }
            }
            if (this.validateIncentiveData(this.dailyIncentiveData, this.incentiveData.basicInfo.incentiveType, this.cancellationChecked, this.datePipe)) {
                if (this.incentiveData.basicInfo.incentiveType === 'Daily') {
                    goalsInfo['cancellationLimitScope'] = this.cancellationLimitScope;
                    goalsInfo['cancellationLimit'] = this.cancellationGlobalLimit;
                    goalsInfo['cancellationChecked'] = this.cancellationChecked;
                    goalsInfo['setsCancellationLimit'] = this.setsCancellationLimit;
                }
                else if (this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
                    goalsInfo['maxOrderDistanceGlobal'] = this.maxOrderDistanceGlobal;
                    goalsInfo['maxOrderDistanceChecked'] = this.maxOrderDistanceChecked;
                }
                goalsInfo['selectedVariable'] = this.selectedVariable;
                goalsInfo['selectedCondition'] = this.condition;
                goalsInfo['dailyIncentiveData'] = this.dailyIncentiveData;
                this.goalsInfo.emit(goalsInfo);
            }
            else {
                return;
            }
        }
        else if (this.state === 'edit' && this.incentiveData.basicInfo.incentiveType === 'Weekly Fixed') {
            for (var _i = 0, _a = this.weeklyIncentiveData; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item) {
                    if (item.startDay.index > item.endDay.index) {
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Please check the selected days in set ' + (parseInt(index) + 1),
                        }));
                        return;
                    }
                }
            }
            if (this.selectedVariable.length === 0) {
                this.erroMessage();
                this.variableError = true;
                return;
            }
            if (this.checkIfDaysOverlap()) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'Overlapping of days are not allowed',
                }));
                return;
            }
            var incentiveStartDate = new Date(this.incentiveData.basicInfo.startDate).getTime();
            var currentDate = new Date().getTime();
            var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
            if ((t) >= 7) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'Cannot edit incentive which has already run for a week',
                }));
                return;
            }
            var sdidIsBlocked = this.incentiveData.basicInfo.service.some(function (ser) { return _this.blockServiceDetailIdsForIncentiveCreation.has(ser); });
            if (sdidIsBlocked) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'Cannot edit incentive as it is blocked for creation/edit',
                }));
                return;
            }
            if (this.validateIncentiveData(this.weeklyIncentiveData, this.incentiveData.basicInfo.incentiveType)) {
                goalsInfo['selectedVariable'] = this.selectedVariable;
                goalsInfo['selectedCondition'] = this.condition;
                goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
                console.log('Goals Info Weekly: ', goalsInfo);
                this.goalsInfo.emit(goalsInfo);
            }
            else {
                return;
            }
        }
        else {
            for (var index in this.weeklyIncentiveData) {
                if (this.weeklyIncentiveData[index] && this.weeklyIncentiveData[index]['1'] && this.weeklyIncentiveData[index]['1']['timeSlot']) {
                    if (this.weeklyIncentiveData[index].startDay.index > this.weeklyIncentiveData[index].endDay.index) {
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Please check the selected days in set ' + (parseInt(index) + 1),
                        }));
                        return;
                    }
                    if (!this.weeklyIncentiveData[index]['1']['timeSlot'].every(function (timeSlot) { return timeSlot.fromTime < timeSlot.toTime; })) {
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Invalid time slot in set ' + (parseInt(index) + 1),
                        }));
                        return;
                    }
                }
            }
            if (this.selectedVariable.length === 0) {
                this.erroMessage();
                this.variableError = true;
                return;
            }
            if (this.checkIfDaysOverlap()) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'Overlapping of days are not allowed',
                }));
                return;
            }
            if (!this.qualityChecked) {
                this.weeklyIncentiveData.forEach(function (element) {
                    element['1'].rules.forEach(function (rule) {
                        delete rule["quality"];
                    });
                });
            }
            if (this.validateIncentiveData(this.weeklyIncentiveData, this.incentiveData.basicInfo.incentiveType)) {
                goalsInfo['selectedVariable'] = this.selectedVariable;
                goalsInfo['selectedCondition'] = this.condition;
                goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
                console.log('Goals Info Weekly: ', goalsInfo);
                this.goalsInfo.emit(goalsInfo);
            }
            else {
                return;
            }
        }
    };
    Object.defineProperty(GoalsComponent.prototype, "redeemIncentives", {
        get: function () {
            return this.goalsFormGroup.get('redeemGoals');
        },
        enumerable: true,
        configurable: true
    });
    GoalsComponent.prototype.addRedeemSet = function () {
        var redeemObj;
        if (this.getValues('selectedCondition') === 'flat') {
            redeemObj = this.fb.group({
                redeemAmount: null,
                bonusAmount: null
            });
        }
        else {
            redeemObj = this.fb.group({
                redeemAmount: null,
                bonusPercentage: null
            });
        }
        this.redeemIncentives.push(redeemObj);
        console.log(this.redeemIncentives);
    };
    GoalsComponent.prototype.deleteRedeemSet = function (index) {
        console.log('RedeemSet : ', this.redeemIncentives, index);
        this.redeemIncentives.removeAt(index);
        console.log('RedeemSet : ', this.redeemIncentives.value, index);
    };
    GoalsComponent.prototype.addVariables = function (variable, event, silently) {
        if (silently === void 0) { silently = false; }
        if (this.NOT_EDITABLE.variables && !silently) {
            return;
        }
        //remove variable from array
        if (!silently && this.selectedVariable.length >= 1) {
            this.confirmationValue = confirm('Are you sure you want to continue with changes?');
        }
        if (silently || this.confirmationValue) {
            //clear total value maps
            this.totalOrdersMap.clear();
            this.totalDistanceMap.clear();
            this.totalAmountMap.clear();
            if (this.selectedVariable.indexOf(variable) !== -1) {
                this.selectedVariable.splice(this.selectedVariable.indexOf(variable), 1);
                if (this.selectedVariable.length <= 1) {
                    this.condition = '&&';
                }
            }
            else { // add variable to array
                this.selectedVariable.push(variable);
                if (this.selectedVariable.length > 1) {
                    this.condition = this.condition || '&&';
                }
            }
            if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
                this.modifyData();
            }
            else {
                if (this.selectedVariable.indexOf(variable) !== -1) {
                    if (variable === 'quality') {
                        this.qualityChecked = true;
                    }
                }
                else {
                    if (variable === 'quality') {
                        this.qualityChecked = false;
                    }
                }
                this.modifyWeeklyData();
            }
        }
        else {
            event && event.preventDefault();
            if (this.selectedVariable.indexOf(variable) !== -1) {
                if (variable === 'order') {
                    this.orderChecked = true;
                }
                if (variable === 'distance') {
                    this.distanceChecked = true;
                }
                if (variable === 'quality') {
                    this.qualityChecked = true;
                }
            }
            else {
                if (variable === 'order') {
                    this.orderChecked = false;
                }
                if (variable === 'distance') {
                    this.distanceChecked = false;
                }
                if (variable === 'quality') {
                    this.qualityChecked = false;
                }
            }
        }
        if (this.variableError) {
            this.variableError = false;
        }
    };
    GoalsComponent.prototype.resetDailyIncentiveGoals = function () {
        this.dailyIncentiveData.forEach(function (data) {
            Object.keys(data).forEach(function (key) {
                var incentive = data[key] || {};
                var rules = incentive.rules;
                if (rules) {
                    rules.forEach(function (rule) {
                        rule.amount = null;
                        rule.distance = null;
                        rule.order = null;
                    });
                }
            });
        });
    };
    GoalsComponent.prototype.resetWeeklyIncentiveGoals = function () {
        this.weeklyIncentiveData.forEach(function (data) {
            Object.keys(data).forEach(function (key) {
                var incentive = data[key] || {};
                var rules = incentive.rules;
                if (rules) {
                    rules.forEach(function (rule) {
                        rule.amount = null;
                        rule.distance = null;
                        rule.order = null;
                        rule.quality = {};
                        rule.quality.metric = null;
                        rule.quality.value = null;
                        rule.quality.amount = null;
                    });
                }
            });
        });
    };
    GoalsComponent.prototype.modifyWeeklyData = function () {
        console.log(this.weeklyIncentiveData);
        function copyObj(obj) {
            try {
                return JSON.parse(JSON.stringify(obj));
            }
            catch (err) {
                return {};
            }
        }
        var ruleObj = {};
        this.rules = [];
        this.resetWeeklyIncentiveGoals();
        if (this.weeklyIncentiveData.length > 1) {
            for (var index in this.weeklyIncentiveData) {
                if (this.weeklyIncentiveData[index]) {
                    var ruleIndex = 1;
                    var keys = Object.keys(this.weeklyIncentiveData[index]);
                    for (var key in keys) {
                        if (keys[key] !== 'days' && keys[key] !== 'startDay' && keys[key] !== 'endDay') {
                            try {
                                // this.dailyIncentiveData[index][keys[key]].rules = []; //deleting the rules
                                this.weeklyIncentiveData[index][keys[key]].rules.forEach(function (rule) {
                                    delete rule.amount;
                                    delete rule.distance;
                                    delete rule.order;
                                    // delete rule.quality;
                                });
                                this.rules = [];
                                //adding the newly selected variables
                                for (var i in this.selectedVariable) {
                                    if (i) {
                                        ruleObj[this.selectedVariable[i]] = null;
                                        if (this.selectedVariable[i] === 'quality') {
                                            ruleObj[this.selectedVariable[i]] = {};
                                            ruleObj[this.selectedVariable[i]].metric = null;
                                            ruleObj[this.selectedVariable[i]].value = null;
                                            ruleObj[this.selectedVariable[i]].amount = null;
                                        }
                                    }
                                }
                                ruleObj['index'] = ruleIndex;
                                ruleIndex++;
                                this.rules.push(copyObj(ruleObj));
                                // this.rules.push(ruleObj);
                                // this.dailyIncentiveData[index][keys[key]]['rules'] = this.rules.slice();
                            }
                            catch (err) {
                                console.log(err);
                            }
                        }
                    }
                }
            }
        }
        else {
            for (var index in this.weeklyIncentiveData) {
                if (index) {
                    // add time and selected rules
                    this.timeSlot = [{ fromTime: null, toTime: null }];
                    for (var i in this.selectedVariable) {
                        if (i) {
                            ruleObj[this.selectedVariable[i]] = null;
                            if (this.selectedVariable[i] === 'quality') {
                                ruleObj[this.selectedVariable[i]] = {};
                                ruleObj[this.selectedVariable[i]].metric = null;
                                ruleObj[this.selectedVariable[i]].value = null;
                                ruleObj[this.selectedVariable[i]].amount = null;
                            }
                        }
                    }
                    this.weeklyIncentiveData[index][1] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
                    ruleObj['index'] = 1;
                    this.rules.push(ruleObj);
                    this.weeklyIncentiveData[index][1]['timeSlot'] = this.timeSlot;
                    this.weeklyIncentiveData[index][1]['rules'] = this.rules;
                    this.subSetCount[parseInt(index)] = 1;
                }
            }
        }
    };
    GoalsComponent.prototype.modifyData = function () {
        function copyObj(obj) {
            try {
                return JSON.parse(JSON.stringify(obj));
            }
            catch (err) {
                return {};
            }
        }
        var ruleObj = {};
        this.rules = [];
        this.resetDailyIncentiveGoals();
        for (var index in this.dailyIncentiveData) {
            //creating for first set (0)
            if (Object.keys(this.dailyIncentiveData[index]).length === 1) {
                // add time and selected rules
                this.timeSlot = [{ fromTime: null, toTime: null }];
                for (var i in this.selectedVariable) {
                    if (i && this.selectedVariable[i] != "cancellation") {
                        ruleObj[this.selectedVariable[i]] = null;
                    }
                }
                this.dailyIncentiveData[index][1] = { timeSlot: [], rules: [] };
                ruleObj['index'] = 1;
                this.rules.push(ruleObj);
                this.dailyIncentiveData[index][1]['timeSlot'] = this.timeSlot;
                this.dailyIncentiveData[index][1]['rules'] = this.rules;
            }
            else { // when more than one variable is selected
                // loop through the Object keys and delete the rules
                var ruleIndex = 1;
                var keys = Object.keys(this.dailyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] !== 'days') {
                        try {
                            // this.dailyIncentiveData[index][keys[key]].rules = []; //deleting the rules
                            this.dailyIncentiveData[index][keys[key]].rules.forEach(function (rule) {
                                delete rule.amount;
                                delete rule.distance;
                                delete rule.order;
                                // delete rule.quality;
                            });
                            this.rules = [];
                            //adding the newly selected variables
                            for (var i in this.selectedVariable) {
                                if (i && this.selectedVariable[i] != "cancellation") {
                                    ruleObj[this.selectedVariable[i]] = null;
                                    if (this.selectedVariable[i] === 'quality') {
                                        ruleObj[this.selectedVariable[i]] = {};
                                        ruleObj[this.selectedVariable[i]].metric = null;
                                        ruleObj[this.selectedVariable[i]].value = null;
                                        ruleObj[this.selectedVariable[i]].amount = null;
                                    }
                                }
                            }
                            ruleObj['index'] = ruleIndex;
                            ruleIndex++;
                            this.rules.push(copyObj(ruleObj));
                            // this.rules.push(ruleObj);
                            // this.dailyIncentiveData[index][keys[key]]['rules'] = this.rules.slice();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.generateUuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    GoalsComponent.prototype.addSet = function () {
        console.log(this.weeklyIncentiveData);
        var ruleObj = {};
        if (this.incentiveData.basicInfo.incentiveType === 'Daily' || this.incentiveData.basicInfo.incentiveType === 'Adhoc') {
            if (this.incentiveData.basicInfo.incentiveType === 'Daily') {
                this.setsCancellationLimit.push(0);
            }
            for (var index in this.dailyIncentiveData) {
                if (index) {
                    var keys = Object.keys(this.dailyIncentiveData[index]);
                    keys.splice(keys.indexOf('days'), 1);
                    var newSet = keys.length + 1;
                    this.timeSlot = [{ fromTime: null, toTime: null }];
                    this.dailyIncentiveData[index][newSet] = { timeSlot: [], rules: [] };
                    this.dailyIncentiveData[index][newSet]['timeSlot'] = this.timeSlot;
                    for (var i in this.selectedVariable) {
                        if (i && this.selectedVariable[i] != "cancellation") {
                            ruleObj[this.selectedVariable[i]] = null;
                        }
                    }
                    ruleObj['index'] = 1;
                    this.rules = [];
                    this.rules.push(ruleObj);
                    this.dailyIncentiveData[index][newSet]['rules'] = this.rules;
                }
            }
        }
        else {
            this.rules = [];
            this.quality = {};
            var keys = Object.keys(this.weeklyIncentiveData);
            var newSet = keys.length;
            if (!this.weeklyIncentiveData[newSet - 1].endDay.day) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Please select end day before creating new set",
                }));
                return;
            }
            var nextStartDay = void 0;
            if (this.weeklyIncentiveData[newSet - 1].endDay.index === this.days.length - 1) {
                nextStartDay = { day: 'Monday', index: 1 };
            }
            else {
                for (var i in this.days) {
                    if (this.days[i].index === this.weeklyIncentiveData[newSet - 1].endDay.index) {
                        nextStartDay = this.days[parseInt(i) + 1];
                        break;
                    }
                }
            }
            var weeklyObj = { days: 'All', startDay: nextStartDay, endDay: { day: '', index: null } };
            this.weeklyIncentiveData.push(weeklyObj);
            this.weeklyIncentiveData[newSet][1] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
            this.timeSlot = [{ fromTime: null, toTime: null }];
            for (var i in this.selectedVariable) {
                if (i) {
                    ruleObj[this.selectedVariable[i]] = null;
                }
            }
            ruleObj['index'] = 1;
            this.rules.push(ruleObj);
            this.weeklyIncentiveData[newSet][1]['timeSlot'] = this.timeSlot;
            this.weeklyIncentiveData[newSet][1]['rules'] = this.rules;
            if (this.weeklyIncentiveData[newSet - 1][1]['rules'][0]['quality']) {
                this.weeklyIncentiveData[newSet][1]['rules'][0]['quality'] = this.quality;
                this.weeklyIncentiveData[newSet][1]['rules'][0]['quality']['metric'] = this.globalQualityMetric;
            }
            this.subSetCount[newSet] = 1;
            this.changeDetectorRef.detectChanges();
        }
    };
    GoalsComponent.prototype.addSubset = function (setIndex) {
        var ruleObj = {};
        // let keys = Object.keys(this.weeklyIncentiveData[setIndex]);
        // keys.splice(keys.indexOf('days'), 1);
        var newSet = this.subSetCount[setIndex] + 1;
        this.subSetCount[setIndex] = newSet;
        this.timeSlot = [{ fromTime: null, toTime: null }];
        this.weeklyIncentiveData[setIndex][newSet] = { timeSlot: [], rules: [], uuid: this.generateUuid() };
        this.weeklyIncentiveData[setIndex][newSet]['timeSlot'] = this.timeSlot;
        for (var i in this.selectedVariable) {
            if (i && this.selectedVariable[i] != "cancellation") {
                ruleObj[this.selectedVariable[i]] = null;
                if (this.selectedVariable[i] === 'quality') {
                    ruleObj[this.selectedVariable[i]] = {};
                    ruleObj[this.selectedVariable[i]].metric = null;
                    ruleObj[this.selectedVariable[i]].value = null;
                    ruleObj[this.selectedVariable[i]].amount = null;
                }
            }
        }
        ruleObj['index'] = 1;
        this.rules = [];
        this.rules.push(ruleObj);
        this.weeklyIncentiveData[setIndex][newSet]['rules'] = this.rules;
    };
    GoalsComponent.prototype.deleteSet = function (setIndex) {
        delete this.setsCancellationLimit[parseInt(setIndex) - 1];
        var backUpIncentive = [], set;
        for (var index in this.dailyIncentiveData) {
            if (index) {
                var keys = Object.keys(this.dailyIncentiveData[index]);
                keys.splice(keys.indexOf('days'), 1);
                if (keys.length === parseInt(setIndex)) {
                    delete this.dailyIncentiveData[index][setIndex];
                    break;
                }
                else {
                    backUpIncentive = this.dailyIncentiveData;
                    for (var i = parseInt(setIndex); i < keys.length; i++) {
                        if (i) {
                            set = (parseInt(setIndex) + 1).toString();
                            // backUpIncentive[index][i] = this.dailyIncentiveData[index][i];
                            backUpIncentive[index][i] = this.dailyIncentiveData[index][set];
                        }
                    }
                    delete backUpIncentive[index][keys.length];
                }
            }
        }
    };
    GoalsComponent.prototype.deleteSubSet = function (setIndex, subsetIndex) {
        console.log(this.weeklyIncentiveData[setIndex][subsetIndex]);
        var backUpIncentive = this.weeklyIncentiveData, set;
        var keys = Object.keys(this.weeklyIncentiveData[setIndex]);
        console.log('Keys : ', keys);
        keys.splice(keys.indexOf('days'), 1);
        keys.splice(keys.indexOf('startDay'), 1);
        keys.splice(keys.indexOf('endDay'), 1);
        console.log('Keys : ', keys);
        for (var i = parseInt(subsetIndex); i < keys.length; i++) {
            if (i) {
                set = (parseInt(subsetIndex) + 1).toString();
                backUpIncentive[setIndex][i] = this.weeklyIncentiveData[setIndex][set];
            }
        }
        delete backUpIncentive[setIndex][keys.length];
        this.subSetCount[setIndex] = this.subSetCount[setIndex] - 1;
        console.log('Delete subset : ', this.weeklyIncentiveData);
    };
    GoalsComponent.prototype.deleteWeeklySet = function (setIndex) {
        var backUpIncentive = [], set;
        var keys = Object.keys(this.weeklyIncentiveData);
        if (keys.length === (setIndex + 1)) {
            this.weeklyIncentiveData.splice(setIndex, 1);
        }
        else {
            backUpIncentive = this.weeklyIncentiveData;
            for (var i = setIndex; i < (keys.length - 1); i++) {
                backUpIncentive[i] = this.weeklyIncentiveData[i + 1];
            }
            backUpIncentive.splice(keys.length - 1, 1);
        }
    };
    GoalsComponent.prototype.addTimeSlot = function (setIndex) {
        for (var index in this.dailyIncentiveData) {
            if (index) {
                var keys = Object.keys(this.dailyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] == setIndex) {
                        this.dailyIncentiveData[index][setIndex].timeSlot = this.dailyIncentiveData[index][setIndex].timeSlot.concat([
                            { fromTime: null, toTime: null }
                        ]);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.addWeeklyTimeSlot = function (subsetIndex, setIndex) {
        for (var index in this.weeklyIncentiveData) {
            if (index === setIndex.toString()) {
                var keys = Object.keys(this.weeklyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] === subsetIndex) {
                        this.weeklyIncentiveData[setIndex][subsetIndex].timeSlot = this.weeklyIncentiveData[setIndex][subsetIndex].timeSlot.concat([
                            { fromTime: null, toTime: null }
                        ]);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.deleteTimeSlot = function (setIndex, timeSlotIndex) {
        for (var index in this.dailyIncentiveData) {
            if (index) {
                var keys = Object.keys(this.dailyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] == setIndex) {
                        var timeSlot = this.dailyIncentiveData[index][setIndex].timeSlot;
                        this.dailyIncentiveData[index][setIndex].timeSlot.splice(timeSlotIndex, 1);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.deleteWeeklyTimeSlot = function (setIndex, subsetIndex, timeSlotIndex) {
        for (var index in this.weeklyIncentiveData) {
            if (index === setIndex.toString()) {
                var keys = Object.keys(this.weeklyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] === subsetIndex) {
                        var timeSlot = this.weeklyIncentiveData[index][subsetIndex].timeSlot;
                        this.weeklyIncentiveData[index][subsetIndex].timeSlot.splice(timeSlotIndex, 1);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.addGoals = function (setIndex, ruleIndex) {
        var ruleObj = {};
        for (var i in this.selectedVariable) {
            if (i && this.selectedVariable[i] != "cancellation") {
                ruleObj[this.selectedVariable[i]] = null;
                if (this.selectedVariable[i] === 'quality') {
                    ruleObj[this.selectedVariable[i]] = {};
                    ruleObj[this.selectedVariable[i]].metric = null;
                    ruleObj[this.selectedVariable[i]].value = null;
                    ruleObj[this.selectedVariable[i]].amount = null;
                }
            }
        }
        ruleObj['index'] = ruleIndex + 1;
        for (var index in this.dailyIncentiveData) {
            if (index) {
                var keys = Object.keys(this.dailyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] == setIndex) {
                        this.dailyIncentiveData[index][setIndex].rules = this.dailyIncentiveData[index][setIndex].rules.concat([ruleObj]);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.addWeeklyGoals = function (setIndex, subsetIndex, ruleIndex) {
        var ruleObj = {};
        for (var i in this.selectedVariable) {
            if (i) {
                ruleObj[this.selectedVariable[i]] = null;
                if (this.selectedVariable[i] === 'quality') {
                    ruleObj[this.selectedVariable[i]] = {};
                    ruleObj[this.selectedVariable[i]].metric = this.globalQualityMetric;
                    ruleObj[this.selectedVariable[i]].value = null;
                    ruleObj[this.selectedVariable[i]].amount = null;
                }
            }
        }
        ruleObj['index'] = ruleIndex + 1;
        for (var index in this.weeklyIncentiveData) {
            if (index === setIndex.toString()) {
                var keys = Object.keys(this.weeklyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] === subsetIndex) {
                        this.weeklyIncentiveData[index][subsetIndex].rules = this.weeklyIncentiveData[index][subsetIndex].rules.concat([ruleObj]);
                        break;
                    }
                }
            }
        }
    };
    GoalsComponent.prototype.deleteGoals = function (setIndex, ruleIndex, rules) {
        for (var index in this.dailyIncentiveData) {
            if (index) {
                var keys = Object.keys(this.dailyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] == setIndex) {
                        this.dailyIncentiveData[index][setIndex].rules.splice(ruleIndex, 1);
                        break;
                    }
                }
            }
        }
        //recompute all vars 
        this.reComputeTotalAmount(setIndex, rules);
        this.reComputeTotalDistance(setIndex, rules);
        this.reComputeTotalOrders(setIndex, rules);
    };
    GoalsComponent.prototype.deleteWeeklyGoals = function (setIndex, subsetIndex, ruleIndex, rules) {
        for (var index in this.weeklyIncentiveData) {
            if (index === setIndex.toString()) {
                var keys = Object.keys(this.weeklyIncentiveData[index]);
                for (var key in keys) {
                    if (keys[key] === subsetIndex) {
                        this.weeklyIncentiveData[index][subsetIndex].rules.splice(ruleIndex, 1);
                        break;
                    }
                }
            }
        }
        this.reComputeTotalAmount(setIndex.toString(), rules);
        this.reComputeTotalDistance(setIndex.toString(), rules);
        this.reComputeTotalOrders(setIndex.toString(), rules);
    };
    GoalsComponent.prototype.goBack = function () {
        this.back.emit();
    };
    GoalsComponent.prototype.setMetricValue = function (selectedValue) {
        var _this = this;
        this.globalQualityMetric = selectedValue;
        for (var i = 0; i < this.weeklyIncentiveData.length; i++) {
            this.weeklyIncentiveData[i]['1'].rules.forEach(function (element) {
                element.quality.metric = _this.globalQualityMetric;
            });
        }
    };
    GoalsComponent.prototype.joinDays = function (day, setIndex) {
        //add start day for next set.
        // if (this.weeklyIncentiveData[setIndex + 1]) {
        //   this.weeklyIncentiveData[setIndex + 1].startDay.day = day;
        //   this.weeklyIncentiveData = this.weeklyIncentiveData;
        // }
        var startIndex, endIndex;
        if (this.weeklyIncentiveData[setIndex].startDay.day && this.weeklyIncentiveData[setIndex].endDay.day) {
            this.weeklyIncentiveData[setIndex].days = '';
            for (var index = 1; index < this.days.length; index++) {
                if (this.weeklyIncentiveData[setIndex].startDay.day === this.days[index].day) {
                    startIndex = index;
                }
                if (this.weeklyIncentiveData[setIndex].endDay.day === this.days[index].day) {
                    endIndex = index;
                }
                if (startIndex && endIndex) {
                    break;
                }
            }
            var listOfDays = [];
            for (var i = startIndex; i <= endIndex; i++) {
                listOfDays.push(this.days[i].day);
            }
            this.weeklyIncentiveData[setIndex].days = listOfDays.join(', ');
        }
    };
    GoalsComponent.prototype.handleCancellation = function () {
        // this.cancellationChecked = !this.cancellationChecked;
        this.cancellationGlobalLimit = 0;
        this.cancellationLimitScope = "";
        this.setsCancellationLimit = this.setsCancellationLimit.map(function (x) { return 0; });
        var cancellationIndex = this.selectedVariable.indexOf("cancellation");
        if (cancellationIndex > -1) {
            this.selectedVariable.splice(cancellationIndex, 1);
        }
    };
    GoalsComponent.prototype.handleMaxOrderDistance = function () {
        this.maxOrderDistanceGlobal = 0;
        var maxOrderDistanceIndex = this.selectedVariable.indexOf("maxOrderDistance");
        if (maxOrderDistanceIndex > -1) {
            this.selectedVariable.splice(maxOrderDistanceIndex, 1);
        }
    };
    GoalsComponent.prototype.clearCancellationLimits = function () {
        this.cancellationGlobalLimit = 0;
        this.setsCancellationLimit = this.setsCancellationLimit.map(function (x) { return 0; });
    };
    GoalsComponent.prototype.fetchIncentives = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var basicInfo, incentives, currentEditIncentiveID;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        basicInfo = this.incentiveData.basicInfo;
                        return [4 /*yield*/, this.incentiveService.fetchIncentiveData({
                                "filters": {
                                    "cities": basicInfo.city,
                                    "serviceType": basicInfo.service,
                                    "types": [
                                        "Daily"
                                    ],
                                    "startDate": basicInfo.startDate,
                                    "endDate": basicInfo.startDate,
                                    "active": true,
                                    "isHHIncentive": false,
                                }
                            }).toPromise()];
                    case 1:
                        incentives = _a.sent();
                        currentEditIncentiveID = this.defaultValue && this.defaultValue._id;
                        return [2 /*return*/, incentives.filter(function (x) { return !_.isEqual(x._id, currentEditIncentiveID); })];
                }
            });
        });
    };
    GoalsComponent.prototype.toMinutes = function (time) {
        var _a = time.split(":").map(Number), hour = _a[0], minute = _a[1];
        return hour * 60 + minute;
    };
    GoalsComponent.prototype.isOverlappingTime = function (range1, range2) {
        if (range1.start >= range2.start && range1.start <= range2.end) {
            return true;
        }
        if (range1.end >= range2.start && range1.end <= range2.end) {
            return true;
        }
        if (range1.start <= range2.start && range1.end >= range2.end) {
            return true;
        }
        return false;
    };
    GoalsComponent.prototype.findTimeBuckets = function (timeSlot) {
        var _this = this;
        var start = this.toMinutes(timeSlot.fromTime);
        var end = this.toMinutes(timeSlot.toTime);
        var timeBuckets = this.dailyIncentiveTimeBuckets.filter(function (bucket) {
            var bucketStart = _this.toMinutes(bucket.fromTime);
            var bucketEnd = _this.toMinutes(bucket.toTime);
            return _this.isOverlappingTime({ start: start, end: end }, { start: bucketStart, end: bucketEnd });
        });
        return timeBuckets.map(function (bucket) { return bucket.name; });
    };
    GoalsComponent.prototype.weeklyTimeSlotChanged = function (setIndex, slotIndex, timeIndex) {
        var newTimeSlot = this.weeklyIncentiveData[setIndex][slotIndex].timeSlot[timeIndex];
        if (!newTimeSlot.fromTimeHour || !newTimeSlot.fromTimeMin || !newTimeSlot.toTimeHour || !newTimeSlot.toTimeMin) {
            return;
        }
        newTimeSlot.fromTime = this.timeToTimeStamp(newTimeSlot.fromTimeHour.value + ":" + newTimeSlot.fromTimeMin.value);
        newTimeSlot.toTime = this.timeToTimeStamp(newTimeSlot.toTimeHour.value + ":" + newTimeSlot.toTimeMin.value);
        this.weeklyIncentiveData[setIndex][slotIndex].timeSlot[timeIndex] = newTimeSlot;
    };
    GoalsComponent.prototype.timeSlotChanged = function (slotIndex, timeIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var incentiveType, isHHIncentive, incentives, newTimeSlot, newTimeBuckets, _a, overlappingIncentives;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        incentiveType = this.incentiveData.basicInfo.incentiveType;
                        isHHIncentive = this.incentiveData.basicInfo.isHHIncentive;
                        incentives = this.dailyIncentiveData[0][slotIndex];
                        newTimeSlot = incentives.timeSlot[timeIndex];
                        if (!newTimeSlot.fromTimeHour || !newTimeSlot.fromTimeMin || !newTimeSlot.toTimeHour || !newTimeSlot.toTimeMin) {
                            return [2 /*return*/];
                        }
                        newTimeSlot.fromTime = this.timeToTimeStamp(newTimeSlot.fromTimeHour.value + ":" + newTimeSlot.fromTimeMin.value);
                        newTimeSlot.toTime = this.timeToTimeStamp(newTimeSlot.toTimeHour.value + ":" + newTimeSlot.toTimeMin.value);
                        this.dailyIncentiveData[0][slotIndex].timeSlot[timeIndex] = newTimeSlot;
                        newTimeBuckets = this.findTimeBuckets({
                            fromTime: newTimeSlot.fromTimeHour.value + ":" + newTimeSlot.fromTimeMin.value,
                            toTime: newTimeSlot.toTimeHour.value + ":" + newTimeSlot.toTimeMin.value
                        });
                        if (incentiveType !== "Daily" || isHHIncentive) {
                            return [2 /*return*/];
                        }
                        if (!!this.existingIncentives) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.fetchIncentives()];
                    case 1:
                        _a.existingIncentives = _b.sent();
                        _b.label = 2;
                    case 2:
                        overlappingIncentives = this.existingIncentives
                            .filter(function (incentive) {
                            var goals = _.flatten(Object.values(incentive.goals));
                            var timeBucketsForSlots = _.flatMap(goals, function (goal) { return _.flatMap(goal.timeSlot, _this.findTimeBuckets.bind(_this)); });
                            return timeBucketsForSlots.some(function (timeBucket) { return newTimeBuckets.includes(timeBucket); });
                        }).map(function (filteredIncentive) { return _this.buildOverlappingIncentive(filteredIncentive); });
                        this.overlappingTimeBuckets[slotIndex + "-" + timeIndex] = overlappingIncentives.length > 0 ? overlappingIncentives : null;
                        return [2 /*return*/];
                }
            });
        });
    };
    GoalsComponent.prototype.checkTimeSlots = function () {
        var _this = this;
        var incentives = this.dailyIncentiveData[0];
        Object.entries(incentives)
            .filter(function (_a) {
            var key = _a[0];
            return key !== "days";
        }).map(function (_a) {
            var index = _a[0], incentive = _a[1];
            incentive.timeSlot
                .map(function (_timeSlot, timeIndex) { return _this.timeSlotChanged(index, timeIndex); });
        });
    };
    GoalsComponent.prototype.slotHasError = function (slotIndex, timeIndex) {
        return Boolean(this.overlappingTimeBuckets[slotIndex + "-" + timeIndex]);
    };
    GoalsComponent.prototype.showTimeBucketPopup = function () {
        this.hidePopup();
        $('.incentiveBucketsPopup').show();
    };
    GoalsComponent.prototype.showIncentivePopup = function (slotIndex, timeIndex) {
        this.hidePopup();
        this.errorPopupIncentives = this.overlappingTimeBuckets[slotIndex + "-" + timeIndex];
        $('.incentiveNamePopup').show();
    };
    GoalsComponent.prototype.hidePopup = function () {
        $('.popup').hide();
    };
    GoalsComponent.prototype.formatTimeStringto12H = function (time) {
        return moment(time, "HH:mm").format("hh:mm A");
    };
    GoalsComponent.prototype.buildOverlappingIncentive = function (filteredIncentive) {
        var goals = filteredIncentive.goals["Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"];
        var goalRuleArray = goals.map(function (goal) { return ({
            timeSlots: goal.timeSlot,
            rules: goal.rules,
            cancellationLimit: goal.cancellationLimit
        }); });
        return {
            incentiveName: filteredIncentive.incentiveName,
            ruleName: filteredIncentive.ruleName,
            selectedVariable: filteredIncentive.selectedVariable,
            selectedCondition: filteredIncentive.selectedCondition,
            cancellationLimit: filteredIncentive.cancellationLimit,
            goals: goalRuleArray
        };
    };
    GoalsComponent.prototype.maxOrderConditionCheck = function () {
        return this.incentiveData.basicInfo
            && (this.incentiveData.basicInfo.incentiveType === 'Adhoc')
            && this.incentiveData.basicInfo.isMaxOrderDistanceEnabledForCity
            && this.incentiveData.basicInfo.incentiveDisplayName == 'Short Ride Incentive';
    };
    GoalsComponent.prototype.showDistanceVariable = function () {
        return !(this.incentiveData.basicInfo
            && this.incentiveData.basicInfo.incentiveType === 'Adhoc'
            && this.incentiveData.basicInfo.incentiveDisplayName == 'Short Ride Incentive');
    };
    GoalsComponent.prototype.reComputeTotalOrders = function (setIndex, rules) {
        var newTotalOrders = rules.reduce(function (accumulator, currentRule) {
            if ('order' in currentRule) {
                return accumulator + currentRule.order;
            }
            return accumulator;
        }, 0);
        this.totalOrdersMap.set(setIndex, newTotalOrders);
    };
    GoalsComponent.prototype.reComputeTotalDistance = function (setIndex, rules) {
        var newTotalDistance = rules.reduce(function (accumulator, currentRule) {
            if ('distance' in currentRule) {
                return accumulator + currentRule.distance;
            }
            return accumulator;
        }, 0);
        this.totalDistanceMap.set(setIndex, newTotalDistance);
    };
    GoalsComponent.prototype.reComputeTotalAmount = function (setIndex, rules) {
        var newTotalAmount = this.calculateRulesTotalAmount(rules);
        this.totalAmountMap.set(setIndex, newTotalAmount);
    };
    GoalsComponent.prototype.calculateRulesTotalAmount = function (rules) {
        var total = rules.reduce(function (accumulator, currentRule) {
            if ('amount' in currentRule) {
                if ('quality' in currentRule && currentRule.quality.amount) {
                    return accumulator + currentRule.amount + currentRule.quality.amount;
                }
                return accumulator + currentRule.amount;
            }
            return accumulator;
        }, 0);
        return total;
    };
    GoalsComponent.prototype.setAmountLimitExceeded = function () {
        var _this = this;
        var limtExceeded = false;
        this.totalAmountMap.forEach(function (amount) {
            if (amount > _this.setMaxAmountLimit) {
                limtExceeded = true;
            }
        });
        return limtExceeded;
    };
    GoalsComponent.prototype.makeOrdersAndDistanceNonCumulative = function (oldRules) {
        var newRules = _.cloneDeep(oldRules);
        for (var index = 0; index < oldRules.length; index++) {
            if ('distance' in oldRules[index]) {
                newRules[index].distance = index === 0 ? oldRules[index].distance : oldRules[index].distance - oldRules[index - 1].distance;
            }
            if ('order' in oldRules[index]) {
                newRules[index].order = index === 0 ? oldRules[index].order : oldRules[index].order - oldRules[index - 1].order;
            }
        }
        return newRules;
    };
    return GoalsComponent;
}());
export { GoalsComponent };
