import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnInit, ViewChild } from '@angular/core';
import { IncentiveService } from '../create-incentive/create-incentive.service';
import {Router, ActivatedRoute} from '@angular/router';
import { LoaderService } from '../loader.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared/shared.service';
import Utils  from '.././utils/utils';
import { defaultStatusMessage, userSelectorStatusMessages } from ".././utils/utils";

const ADHOC_TYPE = 'Adhoc';
const OTHERS = 'Others';

@Component({
  selector: 'app-view-incentive',
  templateUrl: './view-incentive.component.html',
  styleUrls: ['../create-incentive/preview/preview.component.css'],
  providers: [DatePipe]
})

export class ViewIncentiveComponent implements OnInit {
  public header: any = 'View Incentive';
  public incentiveData: any = {};
  public userSelectorCount = {};
  public isHHIncentive: boolean;
  public selectorStatusUpdateTimer: number;
  public isCreateOrDuplicateFlow = true;
  public variables: any;
  public goal = [];
  public state = this.sharedService.STATE.VIEW;
  public order: boolean;
  public distance: boolean;
  public dailyData: any;
  public type = "view"
  public condition: any = '&&';
  public userSelectorStatus = {
    status: "UNKNOWN",
    ...defaultStatusMessage
  };

  public weeklyIncentiveData = [
    {
      'days': 'All',
      'startDay': { day: 'Monday', index: 1 },
      'endDay': { day: '', index: null }
    }
  ];
  public dailyIncentiveData = [
    {
      'days': 'All',
    }
  ];
  public startDay: any;
  public endDay: any;
  public subSetCount = [];
  public orderChecked = false;
  public distanceChecked = false;
  public cancellationChecked = false;
  public cancellationLimitScope = "";
  public cancellationGlobalLimit = 0;
  public setsCancellationLimit = [0];
  public maxOrderDistanceChecked = false;
  public maxOrderDistanceGlobal = 0;
  public qualityChecked = false;
  public active = true;
  public qualitativeMetrics = ['APR', 'DPR'];
  public dailyIncentiveTimeBuckets = environment.incentiveTimeBuckets;
  public overlappingTimeBuckets = {};
  private userSelectorId: string;
  formLoaded = false;
  @Output() back: EventEmitter<any> = new EventEmitter();

  constructor(
    private incentiveService: IncentiveService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private router: Router,
    private datePipe: DatePipe,
    private sharedService: SharedService){}

  ngOnInit() {
    this.loaderService.openLoading();
    const incentiveId = this.route.snapshot.paramMap.get('incentiveId');
    this.incentiveService.getIncentive(incentiveId)
          .subscribe((res: any) => {
            this.incentiveData = res.data;
            this.isHHIncentive = this.incentiveData.tags ? this.incentiveData.tags.includes("HH") : false;
            this.active = this.incentiveData.active;
            this.findTypeOfIncentive(this.incentiveData);
            this.passGoalsInfo();
            this.formLoaded = true;
            this.loaderService.closeLoading();
            if (!this.isHHIncentive) {
              this.sharedService.getUserSelectorDetails(this.incentiveData.ruleId)
                .subscribe((response: any) => {
                  this.userSelectorCount = { count: response.userCount };
                }, err => console.log(err));
            }
          }, err => console.log(err))
  }

  goBack() {
    this.router.navigate(['/incentives']);
  }

  findTypeOfIncentive(defaultValue) {
    if (defaultValue && (defaultValue.incentiveType === 'Daily'
    || defaultValue.incentiveType === 'Adhoc')) {
      this.getDailyIncentiveData(this.incentiveData);
    } else {
      this.getWeeklyIncentiveData(this.incentiveData);
    }
  }

  private getWeeklyIncentiveData(defaultValue) {
    this.condition = defaultValue.selectedCondition;
    const incentives = [];
    let formattedWeeklyincentiveData;
    Object.keys(defaultValue.goals).forEach((goalInDays, index) => {
      formattedWeeklyincentiveData = {};
      formattedWeeklyincentiveData['days'] = goalInDays;
      const weekDays = goalInDays.split(',');
      formattedWeeklyincentiveData['startDay'] = { day: weekDays[0].trim(), index: Utils.findIndexOfDay(weekDays[0]) };
      formattedWeeklyincentiveData['endDay'] = {
        day: weekDays[weekDays.length - 1].trim(),
        index: Utils.findIndexOfDay(weekDays[weekDays.length - 1])
      };
      const weekWiseIncentive = defaultValue.goals[goalInDays];
      weekWiseIncentive.map(incentive => {
        incentive.timeSlot = incentive.timeSlot.map(slot => {
          return {
            fromTime: Utils.timeToTimeStamp(slot.fromTime),
            toTime: Utils.timeToTimeStamp(slot.toTime),
          };
        });
      });
      const incentiveStartDate = new Date(defaultValue.startDate).getTime();
      const currentDate = new Date().getTime();
      var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
      if ((t) >= 7) {
        formattedWeeklyincentiveData['disabled'] = true;
      }
      else if (t <= 6 && t >= 0) {
        if (formattedWeeklyincentiveData['startDay'].index <= new Date().getUTCDay()) {
          formattedWeeklyincentiveData['disabled'] = true;
        }
      }
      weekWiseIncentive.forEach((incentive, index) => {
        const weeklyIndex = incentives.length;
        formattedWeeklyincentiveData[weeklyIndex] = incentive;
        incentives.push(formattedWeeklyincentiveData);
      });
    });
    this.weeklyIncentiveData = incentives
  }

  private getDailyIncentiveData(defaultValue) {
    const incentives = [];
    this.condition = defaultValue.selectedCondition;;
    const formattedDailyincentiveData = {
      'days': 'All',
    };
    Object.keys(defaultValue.goals).forEach((goalInDays, index) => {
      const dayWiseIncentive = defaultValue.goals[goalInDays];
      dayWiseIncentive.map(incentive => {
        incentive.timeSlot = incentive.timeSlot.map(slot => {
          return {
            fromTime: Utils.timeToTimeStamp(slot.fromTime),
            toTime: Utils.timeToTimeStamp(slot.toTime),
          };
        });
      });
      dayWiseIncentive.forEach((incentive, index) => {
        formattedDailyincentiveData[index + 1] = incentive;
      });
    });

    defaultValue.selectedVariable.forEach((variable) => {
      switch (variable) {
        case 'order': {
          this.orderChecked = true;
          break;
        }
        case 'distance': {
          this.distanceChecked = true;
          break;
        }
        case 'quality': {
          this.qualityChecked = true;
          break;
        }
        case 'cancellation': {
          this.cancellationChecked = true;
        }
        case 'maxOrderDistance': {
          this.maxOrderDistanceChecked = true;
        }
      }
      var goalsKey = Object.keys(defaultValue.goals)[0];
      this.setsCancellationLimit = [];
      this.cancellationGlobalLimit = defaultValue.cancellationLimit;
      this.maxOrderDistanceGlobal = defaultValue.maxOrderDistance;
      for (var set of defaultValue.goals[goalsKey]) {
        this.setsCancellationLimit.push(set.cancellationLimit);
      }
      if (this.cancellationChecked) {
        if (defaultValue.cancellationLimit != 0) {
          this.cancellationLimitScope = "global";
        } else {
          this.cancellationLimitScope = "set";
        }
      }
    });
    this.dailyIncentiveData = [formattedDailyincentiveData];

  }


  async passGoalsInfo() {
    let goalsInfo = {};
    if (this.incentiveData.incentiveType === 'Daily'
    || this.incentiveData.incentiveType === 'Adhoc') {

      if (this.incentiveData.incentiveType === 'Daily') {
        goalsInfo['cancellationLimitScope'] = this.cancellationLimitScope;
        goalsInfo['cancellationLimit'] = this.cancellationGlobalLimit;
        goalsInfo['cancellationChecked'] = this.cancellationChecked;
        goalsInfo['setsCancellationLimit'] = this.setsCancellationLimit;
      }
      if (this.incentiveData.incentiveType === 'Adhoc') {
        goalsInfo['maxOrderDistanceGlobal'] = this.maxOrderDistanceGlobal;
        goalsInfo['maxOrderDistanceChecked'] = this.maxOrderDistanceChecked;
      }
      goalsInfo['dailyIncentiveData'] = this.dailyIncentiveData;

    } else if (this.incentiveData.incentiveType === 'Weekly Fixed') {
        goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;

    } else {
        goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
    }
    goalsInfo['selectedVariable'] = this.incentiveData.selectedVariable;
    goalsInfo['selectedCondition'] = this.incentiveData.selectedCondition;

    this.incentiveData['goalsInfo'] = goalsInfo;

    if (this.incentiveData) {
      this.header = 'View - ' + this.incentiveData.incentiveType + ' incentive - ' +
        this.datePipe.transform(this.incentiveData.startDate, 'd MMMM yy')
        + ' to ' + this.datePipe.transform(this.incentiveData.endDate, 'd MMMM yy');

      const details = await Utils.fetchUserSelectorStatus(
        this.type,this.userSelectorId,this.incentiveData,
        this.userSelectorStatus, this.sharedService
      );
      if (details !== undefined) {
        this.userSelectorStatus = details.userSelectorStatus;
        this.userSelectorId = details.userSelectorId;
      }
    }

    if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.dailyIncentiveData) {
      this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.dailyIncentiveData);
    }
    if(this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.weeklyIncentiveData) {
      this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.weeklyIncentiveData);
    }

    this.goal = Utils.organizeData(this.type, this.incentiveData,this.dailyData, this.datePipe,
    this.variables);
  }

}
