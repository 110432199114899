import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule,
  MatIconModule, MatListModule, MatInputModule, MatDialogModule
} from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { ToastSnackbar } from './toast-message/toast-message.service';
import { MyHttpClient } from './http-client';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { SharedModule } from './shared/shared.module';
import { ViewSlabsComponent } from './view-slabs/view-slabs.component';
import { ViewIncentivesComponent } from './view-incentives/view-incentives.component';
import { FilterPaneComponent } from './shared/filter-pane/filter-pane.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';
import { SearchableDropdownComponent } from './shared/searchable-dropdown/searchable-dropdown.component';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { TimePickerComponent } from './shared/time-picker/time-picker.component';
import { SingleSelectDropdownComponent } from './shared/single-select-dropdown/single-select-dropdown.component';
import { RadioButtonsComponent } from './shared/radio-buttons/radio-buttons.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CreateIncentiveComponent } from './create-incentive/create-incentive.component';
import { BasicInfoComponent } from './create-incentive/basic-info/basic-info.component';
import { GoalsComponent } from './create-incentive/goals/goals.component';
import { CommunicationComponent } from './create-incentive/communication/communication.component';
import { PreviewComponent } from './create-incentive/preview/preview.component';
import { SidenavContentComponent } from './shared/sidenav-content/sidenav-content.component';
import { SidenavListComponent } from './shared/sidenav-list/sidenav-list.component';
import { PrimengModule } from './primeng.module';
import { ToasterComponent } from './shared/toaster/toaster.component';
import { LoaderService } from './loader.service';
import { MaterialTimepickerComponent } from './material-timepicker/material-timepicker.component';
import { SigninService } from './signin/signin.service';
import { AuthInterceptor } from 'src/app/auth.interceptor';
import { PenaltyRewardCampaignComponent } from './penalty-reward-campaign/penalty-reward-campaign.component';
import { PenaltyRuleComponent } from './penalty-reward-campaign/penalty-rule/penalty-rule.component';
import { PenaltyPreviewComponent } from './penalty-reward-campaign/penalty-preview/penalty-preview.component';
import { PenaltyBasicInfoComponent } from './penalty-reward-campaign/penalty-basic-info/penalty-basic-info.component';
import { ViewCampaignsComponent } from './view-campaigns/view-campaigns.component';
import { RollingWindowDialogComponent } from './rolling-window-dialog/rolling-window-dialog.component';
import { ConfirmDialogService } from './shared/confirm-dialog/confirm-dialog.service';
import { AlertDialogService } from './shared/alert-dialog/alert-dialog.service';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { LogService } from './logs.service';
import { IAllocatorIncentiveComponent } from './i-allocator-incentive/i-allocator-incentive.component';
import { IncentiveGeneratorComponent } from './i-allocator-incentive/incentive-generator/incentive-generator.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { ViewIncentiveComponent } from './view-incentive/view-incentive.component';
import { IndianCurrency } from './pipes/inr';
import { IndianNumber } from './pipes/number';
import { AssignmentDetailsComponent } from './assignment-details/assignment-details.component';
import { BulkCreditInitiateComponent } from './bulkcredit/initiate/initiate.component';
import { BulkCreditService } from './bulkcredit/bulkcredit.service';
import { BulkCreditHistoryComponent } from './bulkcredit/history/history.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CreateSubscriptionComponent } from './subscription/prepaid/create/prepaid.create.component';
import { CreateAccessFeeComponent } from './subscription/accessFee/create/accessFee.create.component';
import { AuditCampaignInitiateComponent } from './penalty-reward-campaign/audit-campaign/audit-campaign.component';
import { AuditCampaignService } from './penalty-reward-campaign/audit-campaign/audit-campaign.service';
import { IAllocatorConstructUploadComponent } from './i-allocator-incentive/construct-upload/construct-upload.component';
import { ListSubscriptionsComponent } from './subscription/prepaid/list/list.component';
import { ViewSubscriptionRuleComponent } from './subscription/prepaid/view/view.component';

@NgModule({
  declarations: [
    AppComponent,
    ToastSnackbar,
    SigninComponent,
    ViewSlabsComponent,
    ViewIncentivesComponent,
    FilterPaneComponent,
    SearchableDropdownComponent,
    CalendarComponent,
    TimePickerComponent,
    SingleSelectDropdownComponent,
    RadioButtonsComponent,
    CreateIncentiveComponent,
    BasicInfoComponent,
    GoalsComponent,
    CommunicationComponent,
    PreviewComponent,
    SidenavContentComponent,
    SidenavListComponent,
    ToasterComponent,
    MaterialTimepickerComponent,
    PenaltyRewardCampaignComponent,
    PenaltyRuleComponent,
    PenaltyPreviewComponent,
    PenaltyBasicInfoComponent,
    ViewCampaignsComponent,
    RollingWindowDialogComponent,
    ConfirmDialogComponent,
    ActivityLogsComponent,
    IAllocatorIncentiveComponent,
    IncentiveGeneratorComponent,
    IAllocatorConstructUploadComponent,
    AlertDialogComponent,
    ViewIncentiveComponent,
    IndianCurrency,
    IndianNumber,
    AssignmentDetailsComponent,
    AuditCampaignInitiateComponent,
    BulkCreditInitiateComponent,
    BulkCreditHistoryComponent,
    CreateSubscriptionComponent,
    SubscriptionComponent,
    CreateAccessFeeComponent,
    ListSubscriptionsComponent,
    ViewSubscriptionRuleComponent
  ],
  imports: [
    BrowserModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LayoutModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    SharedModule,
    NgSelectModule,
    NgbTooltipModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    PrimengModule,
    CommonModule
  ],
  exports: [ConfirmDialogComponent, AlertDialogComponent],
  entryComponents: [
    ToastSnackbar,
    RollingWindowDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    MyHttpClient,
    LoaderService,
    SigninService,
    AuditCampaignService,
    ConfirmDialogService,
    AlertDialogService,
    LogService,
    BulkCreditService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
