import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatStepper } from '@angular/material';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { PenaltyRewardCampaignService } from './penalty-reward-campaign.service';
import { LoaderService } from '../loader.service';
import { DatePipe } from '@angular/common';
import { cloneDeep, isEqual } from 'lodash';
import { LogService } from '../logs.service';
import { AuthService } from '../auth.service';
import { ACTIONS, RULE_KEY_MAPPINGS, defaultLocale } from './constants';
var PenaltyRewardCampaignComponent = /** @class */ (function () {
    function PenaltyRewardCampaignComponent(penaltyRewardCampaignService, toasterService, router, route, sharedService, loaderService, datePipe, logService, authService) {
        var _this = this;
        this.penaltyRewardCampaignService = penaltyRewardCampaignService;
        this.toasterService = toasterService;
        this.router = router;
        this.route = route;
        this.sharedService = sharedService;
        this.loaderService = loaderService;
        this.datePipe = datePipe;
        this.logService = logService;
        this.authService = authService;
        this.currentDate = new Date();
        this.campaignData = {};
        this.defaultValue = {};
        this.submittingForm = false;
        this.formLoaded = false;
        this.state = this.sharedService.STATE.CREATE;
        this.NOT_EDITABLE = {
            name: false,
            city: false,
            services: false,
            selectors: false,
            startDate: false,
            endDate: false,
            triggerEvents: false,
            timeSlots: false,
            leeway: false,
            leewayWarnMsg: false,
            instanceLeewayWarnMsg: false,
            eligibilityPings: false,
            eligibilityEvents: false,
            eligibilityWarnMsg: false,
            eligibilitySegments: false,
            daprPercentageStart: false,
            daprPercentageEnd: false,
            notification: false,
            action: false,
            message: false,
            startLimit: false,
            endLimit: false,
            amount: false,
            activateAfterHours: false,
            prioritizeAfterMins: false,
            rollingWindowDays: false,
            ratingBetweenStart: false,
            ratingBetweenEnd: false,
            ratingGreaterOrLessThanValue: false,
            blockRedeem: false,
            priority: true
        };
        this.notificationMapping = {};
        this.storeCampaignInfo = function (event, type) {
            if (!event) {
                return;
            }
            if (_this.submittingForm) {
                return;
            }
            var errorMessage = function (error) {
                return error.error && error.error.data && error.error.data.error && error.error.data.error.message;
            };
            _this.submittingForm = true;
            if (event.submit === true) {
                _this.loaderService.openLoading();
                var basicInfo_1 = _this.campaignData.basicInfo;
                var rules = void 0;
                rules = _this.campaignData.rulesChange;
                var defaultTimeSlots = [{ startTime: '0000', endTime: '2359' }];
                var timeSlots = basicInfo_1.timeSlots;
                var formattedTimeSlots_1 = [];
                var campaignData_1 = {};
                campaignData_1['ruleName'] = basicInfo_1.name;
                if (_this.state === _this.sharedService.STATE.EDIT) {
                    campaignData_1['ruleId'] = basicInfo_1._id;
                }
                campaignData_1['startDate'] = _this.setDate(basicInfo_1.startDate);
                campaignData_1['endDate'] = _this.setDate(basicInfo_1.endDate);
                campaignData_1['cityId'] = basicInfo_1.cityId;
                campaignData_1['cityName'] = basicInfo_1.city;
                campaignData_1['serviceDetails'] = basicInfo_1.services || [];
                campaignData_1['serviceNames'] = basicInfo_1.serviceNames || [];
                campaignData_1['serviceIds'] = basicInfo_1.serviceIds || [];
                campaignData_1['ruleType'] = basicInfo_1.ruleType;
                campaignData_1['events'] = basicInfo_1.triggerEvent || '';
                campaignData_1['rules'] = [];
                campaignData_1['timeWindows'] = defaultTimeSlots;
                if (basicInfo_1.userSelector) {
                    campaignData_1['userSelectors'] = basicInfo_1.userSelector;
                    campaignData_1['fallbackToDefaultRule'] = basicInfo_1.fallbackToDefaultRule;
                    campaignData_1['priority'] = basicInfo_1.priority;
                }
                if (basicInfo_1.leeway && basicInfo_1.leewayWarnMsg) {
                    campaignData_1['leeway'] = basicInfo_1.leeway;
                    campaignData_1['leewayWarnMsg'] = basicInfo_1.leewayWarnMsg;
                }
                if (basicInfo_1.instanceLeeway && basicInfo_1.instanceLeewayWarnMsg) {
                    campaignData_1['instanceLeeway'] = basicInfo_1.instanceLeeway;
                    campaignData_1['instanceLeewayWarnMsg'] = basicInfo_1.instanceLeewayWarnMsg;
                }
                if (basicInfo_1.eligibility && basicInfo_1.eligibility.pings && basicInfo_1.eligibility.events && basicInfo_1.eligibility.warnMsg) {
                    campaignData_1['eligibility'] = {
                        pings: basicInfo_1.eligibility.pings,
                        events: basicInfo_1.eligibility.events,
                        warnMsg: basicInfo_1.eligibility.warnMsg
                    };
                }
                if (basicInfo_1.daprPercentageStart && basicInfo_1.daprPercentageEnd) {
                    campaignData_1['daprPercentage'] = {
                        start: basicInfo_1.daprPercentageStart,
                        end: basicInfo_1.daprPercentageEnd
                    };
                }
                if (basicInfo_1.eligibilitySegments) {
                    campaignData_1['eligibilitySegments'] = basicInfo_1.eligibilitySegments;
                }
                if (basicInfo_1.rollingWindowDays) {
                    campaignData_1['rollingWindowDays'] = basicInfo_1.rollingWindowDays;
                }
                timeSlots.forEach(function (timeSlot) {
                    if (timeSlot.startTime && timeSlot.endTime) {
                        timeSlot.startTime = _this.datePipe.transform(timeSlot.startTime, 'HHmm');
                        timeSlot.endTime = _this.datePipe.transform(timeSlot.endTime, 'HHmm');
                        formattedTimeSlots_1.push(timeSlot);
                    }
                });
                if (formattedTimeSlots_1.length > 0) {
                    campaignData_1['timeWindows'] = formattedTimeSlots_1;
                }
                var blockRedeemFieldRelatedEvents_1 = ['penalty', 'reward', 'suspend'];
                if (rules.oldRules) {
                    rules.oldRules.forEach(function (rule) {
                        var ruleToBePushed = {};
                        // ruleToBePushed['event'] = basicInfo.triggerEvent;
                        ruleToBePushed['limit'] = rule['startLimit'];
                        ruleToBePushed['blockRedeem'] = rule['blockRedeem'];
                        ruleToBePushed['type'] = rule['type'];
                        ruleToBePushed['eventsAndReasons'] = basicInfo_1.eventsAndReasons;
                        if (rule['endLimit']) {
                            ruleToBePushed['applicableTill'] = rule['endLimit'];
                        }
                        if (rule['_id']) {
                            ruleToBePushed['_id'] = rule['_id'];
                        }
                        if (rule['action'] === 'warning') {
                            ruleToBePushed['action'] = 'notification';
                        }
                        else if (rule['action'] === 'deactivation') {
                            ruleToBePushed['action'] = 'suspend';
                        }
                        else {
                            ruleToBePushed['action'] = rule['action'];
                        }
                        if (~blockRedeemFieldRelatedEvents_1.indexOf(rule['action'])) {
                            ruleToBePushed['amount'] = rule['amount'] !== null ? Math.abs(rule['amount']) : null;
                            if (!rule['blockRedeem'] && ruleToBePushed['amount'] == null) {
                                delete ruleToBePushed['amount'];
                            }
                        }
                        if (rule['action'] === 'adjustment') {
                            if (rule['amount'] > 0) {
                                ruleToBePushed['adjustmentType'] = 'credit';
                            }
                            else if (rule['amount'] < 0) {
                                ruleToBePushed['adjustmentType'] = 'debit';
                            }
                            ruleToBePushed['amount'] = Math.abs(rule['amount']);
                        }
                        if (rule['action'] === 'suspend' || rule['action'] === 'deactivation') {
                            if (rule['activateAfterHours']) {
                                ruleToBePushed['activateAfterHours'] = rule['activateAfterHours'];
                            }
                            ruleToBePushed['suspensionReason'] = rule['message'];
                        }
                        if (rule['action'] === ACTIONS.DEPRIORITIZE) {
                            if (rule['prioritizeAfterMins']) {
                                ruleToBePushed['prioritizeAfterMins'] = rule['prioritizeAfterMins'];
                            }
                            ruleToBePushed['deproritzeReason'] = rule['message'];
                        }
                        if (rule['action'] === ACTIONS.PUSH_TO_AGENT) {
                            ruleToBePushed['pushToAgentReason'] = rule['message'];
                        }
                        ruleToBePushed['displayReason'] = rule['message'];
                        ruleToBePushed['notificationType'] = rule['notification'];
                        if (rule['workingDays'])
                            ruleToBePushed['workingDays'] = rule['workingDays'];
                        if (basicInfo_1.eventReasonKey) {
                            ruleToBePushed['key'] = basicInfo_1.eventReasonKey.key;
                            ruleToBePushed['values'] = basicInfo_1.eventReason;
                        }
                        if (basicInfo_1.triggerEvent === 'rating_for_captain_received') {
                            ruleToBePushed['values'] = basicInfo_1.ratings;
                        }
                        campaignData_1['rules'].push(ruleToBePushed);
                    });
                    switch (_this.state) {
                        case _this.sharedService.STATE.VIEW: {
                            _this.submittingForm = false;
                            _this.loaderService.closeLoading();
                            _this.router.navigate(['/campaigns']);
                            break;
                        }
                        case _this.sharedService.STATE.DUPLICATE:
                        case _this.sharedService.STATE.CREATE: {
                            if (campaignData_1['ruleType'] == 'captainGame') {
                                if (campaignData_1['rules'][0]['eventsAndReasons'][0].event == "streak") {
                                    campaignData_1['captainGameNotification'] = {
                                        "message": "Dear  Captain, Now win ${amount} Rapido coins by doing ${target} rides daily for ${workingDays} days.Click here to know more about the game",
                                        "title": "Game Started !"
                                    };
                                }
                                else {
                                    campaignData_1['captainGameNotification'] = {
                                        "message": "Dear Captain,the Game has started. Keep your Dropped score above ${target} % to get ${amount} Coins",
                                        "title": "Game Started !"
                                    };
                                }
                                campaignData_1['transactionMode'] = 'coin';
                            }
                            _this.penaltyRewardCampaignService.createCampaign(campaignData_1).subscribe(function (result) {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: 'Successfully created ' + campaignData_1['ruleName'] + ' campaign',
                                }));
                                _this.loaderService.closeLoading();
                                setTimeout(function () {
                                    _this.submittingForm = false;
                                    _this.router.navigate(['/campaigns']);
                                }, 1000);
                                _this.loaderService.closeLoading();
                            }, function (error) {
                                console.log(error);
                                _this.submittingForm = false;
                                _this.loaderService.closeLoading();
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'Could not Create Campaign',
                                }));
                            });
                            break;
                        }
                        case _this.sharedService.STATE.EDIT: {
                            var changedKeys = [];
                            var nonEmptyCampaignData_1;
                            for (var key in _this.initialRule) {
                                if ((_this.initialRule[key] && !campaignData_1[key]) || (!_this.initialRule[key] && campaignData_1[key])) {
                                    changedKeys.push(key);
                                }
                                else if (!_this.initialRule[key] && !campaignData_1[key]) {
                                    // noop
                                }
                                else {
                                    if (key === 'startDate' || key === 'endDate') {
                                        if (!_this.isEqualValue(_this.setDate(_this.initialRule[key]), _this.setDate(campaignData_1[key]))) {
                                            changedKeys.push(key);
                                        }
                                    }
                                    else if (key === 'rules') {
                                        if (_this.initialRule[key].length !== campaignData_1[key].length) {
                                            changedKeys.push(key);
                                        }
                                        else {
                                            var nonEmptyInitialRule = _this.removeEmpty(_.cloneDeep(_this.initialRule[key]));
                                            nonEmptyCampaignData_1 = _this.removeEmpty(_.cloneDeep(campaignData_1[key]));
                                            if (!isEqual(nonEmptyInitialRule, nonEmptyCampaignData_1)) {
                                                changedKeys.push(key);
                                            }
                                        }
                                    }
                                    else if (!_this.isEqualValue(JSON.stringify(_this.initialRule[key]), JSON.stringify(campaignData_1[key]))) {
                                        changedKeys.push(key);
                                    }
                                }
                            }
                            var finalKeys_1 = [];
                            for (var key in changedKeys) {
                                if (changedKeys[key] && changedKeys[key] !== "active") {
                                    RULE_KEY_MAPPINGS[changedKeys[key]] ? finalKeys_1.push(RULE_KEY_MAPPINGS[changedKeys[key]]) : '';
                                }
                            }
                            var metadata_1 = {
                                changedKeys: finalKeys_1,
                                user: {
                                    name: _this.user.firstName + ' ' + _this.user.lastName,
                                    email: _this.user.email
                                }
                            };
                            _this.penaltyRewardCampaignService.editCampaign(campaignData_1, campaignData_1['ruleId']).subscribe(function (result) {
                                if (finalKeys_1.length) {
                                    _this.logService.insertEvent(campaignData_1['ruleId'], _this.initialRule, nonEmptyCampaignData_1, metadata_1, _this.user._id).subscribe();
                                }
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: 'Successfully edited ' + campaignData_1['ruleName'] + ' campaign',
                                }));
                                _this.loaderService.closeLoading();
                                setTimeout(function () {
                                    _this.submittingForm = false;
                                    _this.router.navigate(['/campaigns']);
                                }, 1000);
                                _this.loaderService.closeLoading();
                            }, function (error) {
                                console.log(error);
                                _this.submittingForm = false;
                                _this.loaderService.closeLoading();
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'Could not edit Campaign',
                                }));
                            });
                            break;
                        }
                    }
                }
                else {
                    rules.forEach(function (rule, index) {
                        var ruleToBePushed = {};
                        ruleToBePushed['communications'] = [];
                        rule.communications.forEach(function (communication) {
                            if (rule['action'] === 'suspend') {
                                ruleToBePushed.action = 'suspend';
                                ruleToBePushed['suspensionReason'] = communication.messageLocales[defaultLocale.code];
                                ruleToBePushed.activateAfterHours = rule['activateAfterHours'];
                                if (rule['captainTask']) {
                                    ruleToBePushed.captainTask = rule['captainTask'];
                                }
                                ruleToBePushed['communications'].push(communication);
                            }
                            else if (rule['action'] === 'deactivation') {
                                ruleToBePushed.activateAfterHours = rule['activateAfterHours'];
                                ruleToBePushed.action = 'suspend';
                                ruleToBePushed['suspensionReason'] = communication.messageLocales[defaultLocale.code];
                                ruleToBePushed['communications'].push(communication);
                            }
                            else if (rule['action'] === ACTIONS.DEPRIORITIZE) {
                                ruleToBePushed.prioritizeAfterMins = rule['prioritizeAfterMins'];
                                ruleToBePushed.action = rule['action'];
                                ruleToBePushed['deproritzeReason'] = communication.messageLocales[defaultLocale.code];
                                ruleToBePushed['communications'].push(communication);
                            }
                            else if (rule['action'] === ACTIONS.PUSH_TO_AGENT) {
                                ruleToBePushed.action = rule['action'];
                                ruleToBePushed['pushToAgentReason'] = communication.messageLocales[defaultLocale.code];
                                ruleToBePushed['communications'].push(communication);
                                if (rule['campaignId']) {
                                    ruleToBePushed.campaignId = rule['campaignId'];
                                }
                            }
                            else if (rule['action'] === 'penalty' || rule['action'] === 'reward') {
                                _this.mapActionData(ruleToBePushed, rule);
                                ruleToBePushed.amount = rule['amount'];
                                ruleToBePushed['communications'].push(communication);
                            }
                            else if (rule['action'] === 'adjustment') {
                                _this.mapActionData(ruleToBePushed, rule);
                                if (rule['amount'] > 0) {
                                    ruleToBePushed.adjustmentType = 'credit';
                                }
                                else if (rule['amount'] < 0) {
                                    ruleToBePushed.adjustmentType = 'debit';
                                }
                                ruleToBePushed.amount = Math.abs(rule['amount']);
                                ruleToBePushed['communications'].push(communication);
                            }
                            else if (rule['action'] === 'warning') {
                                ruleToBePushed.action = "notification";
                                ruleToBePushed['communications'].push(communication);
                            }
                        });
                        ruleToBePushed['events'] = basicInfo_1.triggerEvent;
                        ruleToBePushed['limit'] = rule['startLimit'];
                        ruleToBePushed['blockRedeem'] = rule['blockRedeem'];
                        if (~blockRedeemFieldRelatedEvents_1.indexOf(rule['action'])) {
                            ruleToBePushed['amount'] = rule['amount'] !== null ? Math.abs(rule['amount']) : null;
                            if (!rule['blockRedeem'] && ruleToBePushed['amount'] == null) {
                                delete ruleToBePushed['amount'];
                            }
                        }
                        ruleToBePushed['eventsAndReasons'] = basicInfo_1.eventsAndReasons;
                        if (rule['endLimit']) {
                            ruleToBePushed['applicableTill'] = rule['endLimit'];
                        }
                        if (rule['_id']) {
                            ruleToBePushed['_id'] = rule['_id'];
                        }
                        if (basicInfo_1.eventReasonKey) {
                            ruleToBePushed['key'] = basicInfo_1.eventReasonKey.key;
                            ruleToBePushed['values'] = basicInfo_1.eventReason;
                        }
                        if (basicInfo_1.triggerEvent === 'rating_for_captain_received') {
                            ruleToBePushed['values'] = basicInfo_1.ratings;
                        }
                        campaignData_1['rules'].push(ruleToBePushed);
                    });
                    switch (_this.state) {
                        case _this.sharedService.STATE.VIEW: {
                            _this.submittingForm = false;
                            _this.loaderService.closeLoading();
                            _this.router.navigate(['/campaigns']);
                            break;
                        }
                        case _this.sharedService.STATE.DUPLICATE:
                        case _this.sharedService.STATE.CREATE: {
                            if (campaignData_1['ruleType'] == 'captainGame') {
                                if (campaignData_1['rules'][0]['eventsAndReasons'][0].event == "streak") {
                                    campaignData_1['captainGameNotification'] = {
                                        "message": "Dear  Captain, Now win ${amount} Rapido coins by doing ${target} rides daily for ${workingDays} days.Click here to know more about the game",
                                        "title": "Game Started !"
                                    };
                                }
                                else {
                                    campaignData_1['captainGameNotification'] = {
                                        "message": "Dear Captain,the Game has started. Keep your Dropped score above ${target} % to get ${amount} Coins",
                                        "title": "Game Started !"
                                    };
                                }
                                campaignData_1['transactionMode'] = 'coin';
                            }
                            _this.penaltyRewardCampaignService.createCampaign(campaignData_1).subscribe(function (result) {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: 'Successfully created ' + campaignData_1['ruleName'] + ' campaign',
                                }));
                                _this.loaderService.closeLoading();
                                setTimeout(function () {
                                    _this.submittingForm = false;
                                    _this.router.navigate(['/campaigns']);
                                }, 1000);
                                _this.loaderService.closeLoading();
                            }, function (error) {
                                console.log(error);
                                _this.submittingForm = false;
                                _this.loaderService.closeLoading();
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: errorMessage(error) || 'Could not Create Campaign',
                                }));
                            });
                            break;
                        }
                        case _this.sharedService.STATE.EDIT: {
                            var changedKeys = [];
                            var nonEmptyCampaignData_2;
                            for (var key in _this.initialRule) {
                                if ((_this.initialRule[key] && !campaignData_1[key]) || (!_this.initialRule[key] && campaignData_1[key])) {
                                    changedKeys.push(key);
                                }
                                else if (!_this.initialRule[key] && !campaignData_1[key]) {
                                    // noop
                                }
                                else {
                                    if (key === 'startDate' || key === 'endDate') {
                                        if (!_this.isEqualValue(_this.setDate(_this.initialRule[key]), _this.setDate(campaignData_1[key]))) {
                                            changedKeys.push(key);
                                        }
                                    }
                                    else if (key === 'rules') {
                                        if (_this.initialRule[key].length !== campaignData_1[key].length) {
                                            changedKeys.push(key);
                                        }
                                        else {
                                            var nonEmptyInitialRule = _this.removeEmpty(_.cloneDeep(_this.initialRule[key]));
                                            nonEmptyCampaignData_2 = _this.removeEmpty(_.cloneDeep(campaignData_1[key]));
                                            if (!isEqual(nonEmptyInitialRule, nonEmptyCampaignData_2)) {
                                                changedKeys.push(key);
                                            }
                                        }
                                    }
                                    else if (!_this.isEqualValue(JSON.stringify(_this.initialRule[key]), JSON.stringify(campaignData_1[key]))) {
                                        changedKeys.push(key);
                                    }
                                }
                            }
                            var finalKeys_2 = [];
                            for (var key in changedKeys) {
                                if (changedKeys[key] && changedKeys[key] !== "active") {
                                    RULE_KEY_MAPPINGS[changedKeys[key]] ? finalKeys_2.push(RULE_KEY_MAPPINGS[changedKeys[key]]) : '';
                                }
                            }
                            var metadata_2 = {
                                changedKeys: finalKeys_2,
                                user: {
                                    name: _this.user.firstName + ' ' + _this.user.lastName,
                                    email: _this.user.email
                                }
                            };
                            _this.penaltyRewardCampaignService.editCampaign(campaignData_1, campaignData_1['ruleId']).subscribe(function (result) {
                                if (finalKeys_2.length) {
                                    _this.logService.insertEvent(campaignData_1['ruleId'], _this.initialRule, nonEmptyCampaignData_2, metadata_2, _this.user._id).subscribe();
                                }
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: 'Successfully edited ' + campaignData_1['ruleName'] + ' campaign',
                                }));
                                _this.loaderService.closeLoading();
                                setTimeout(function () {
                                    _this.submittingForm = false;
                                    _this.router.navigate(['/campaigns']);
                                }, 1000);
                                _this.loaderService.closeLoading();
                            }, function (error) {
                                console.log(error);
                                _this.submittingForm = false;
                                _this.loaderService.closeLoading();
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'Could not edit Campaign',
                                }));
                            });
                            break;
                        }
                    }
                }
            }
            else {
                var info = tslib_1.__assign({}, _this.campaignData);
                info[type] = event;
                _this.campaignData = info;
                _this.submittingForm = false;
            }
        };
        this.setDate = function (date) { return moment(date).format('YYYY-MM-DD').toString(); };
        this.getNotificationTypes = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.penaltyRewardCampaignService.getNotifications().toPromise()];
                    case 1:
                        response = _a.sent();
                        this.notificationsData = response['data']['data'];
                        this.createNotificationMapping(response['data']['data']);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log(error_1);
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Unable to fetch Notifications",
                        }));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.removeEmpty = function (obj) {
            Object.keys(obj).forEach(function (key) {
                return (obj[key] && typeof obj[key] === 'object' && key !== 'timeWindows') && _this.removeEmpty(obj[key]) ||
                    ((!obj[key] && obj[key] !== undefined && obj[key] !== false) || key === 'timeWindows' || key === 'notificationTitle') && delete obj[key];
            });
            return obj;
        };
    }
    PenaltyRewardCampaignComponent.prototype.createFormViewRule = function () {
        this.NOT_EDITABLE = {
            name: true,
            city: true,
            services: true,
            selectors: true,
            startDate: true,
            endDate: true,
            triggerEvents: true,
            timeSlots: true,
            leeway: true,
            leewayWarnMsg: true,
            instanceLeewayWarnMsg: true,
            eligibilityPings: true,
            eligibilityEvents: true,
            eligibilityWarnMsg: true,
            eligibilitySegments: true,
            daprPercentageStart: true,
            daprPercentageEnd: true,
            notification: true,
            action: true,
            message: true,
            startLimit: true,
            endLimit: true,
            amount: true,
            activateAfterHours: true,
            prioritizeAfterMins: true,
            rollingWindowDays: true,
            ratingBetweenStart: true,
            ratingBetweenEnd: true,
            ratingGreaterOrLessThanValue: true,
            blockRedeem: true,
            priority: true
        };
    };
    PenaltyRewardCampaignComponent.prototype.createFormEditRule = function () {
        this.NOT_EDITABLE = {
            name: true,
            city: true,
            services: true,
            selectors: false,
            startDate: false,
            endDate: false,
            triggerEvents: false,
            timeSlots: false,
            leeway: false,
            leewayWarnMsg: false,
            instanceLeewayWarnMsg: false,
            eligibilityPings: false,
            eligibilityEvents: false,
            eligibilityWarnMsg: false,
            eligibilitySegments: false,
            daprPercentageStart: false,
            daprPercentageEnd: false,
            notification: false,
            action: false,
            message: false,
            startLimit: false,
            endLimit: false,
            amount: false,
            activateAfterHours: false,
            prioritizeAfterMins: false,
            rollingWindowDays: false,
            ratingBetweenStart: false,
            ratingBetweenEnd: false,
            ratingGreaterOrLessThanValue: false,
            blockRedeem: false,
            priority: false
        };
    };
    PenaltyRewardCampaignComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var campaignId, active, action, payload, payload, payload;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.authService.getUserDetails().subscribe(function (data) { _this.user = data; });
                        return [4 /*yield*/, this.getNotificationTypes()];
                    case 1:
                        _a.sent();
                        this.loaderService.openLoading();
                        campaignId = decodeURIComponent(this.route.snapshot.paramMap.get('campaignId'));
                        this.campaignId = campaignId;
                        active = this.route.snapshot.paramMap.get('status');
                        action = this.route.snapshot.paramMap.get('action') || this.sharedService.STATE.CREATE;
                        switch (action) {
                            case this.sharedService.STATE.VIEW: {
                                payload = { campaignId: campaignId, status: active };
                                this.penaltyRewardCampaignService.getCampaign(payload).subscribe(function (result) {
                                    if (result) {
                                        _this.defaultValue = result.data.eventsData[0];
                                        if (_this.defaultValue.rules && _this.defaultValue.rules.length) {
                                            _this.defaultValue.rules = _.orderBy(_this.defaultValue.rules, ['limit'], ['asc']);
                                        }
                                        _this.defaultValue.notificationMapping = _this.notificationMapping;
                                        _this.defaultValue.notificationsData = _this.notificationsData;
                                        _this.createFormViewRule();
                                        _this.formLoaded = true;
                                        _this.loaderService.closeLoading();
                                    }
                                });
                                this.state = this.sharedService.STATE.VIEW;
                                break;
                            }
                            case this.sharedService.STATE.DUPLICATE: {
                                payload = { campaignId: campaignId, status: active };
                                this.penaltyRewardCampaignService.getCampaign(payload).subscribe(function (result) {
                                    if (result) {
                                        _this.defaultValue = result.data.eventsData[0];
                                        if (_this.defaultValue.rules && _this.defaultValue.rules.length) {
                                            _this.defaultValue.rules = _.orderBy(_this.defaultValue.rules, ['limit'], ['asc']);
                                        }
                                        _this.defaultValue.notificationMapping = _this.notificationMapping;
                                        _this.defaultValue.notificationsData = _this.notificationsData;
                                        _this.formLoaded = true;
                                        _this.loaderService.closeLoading();
                                    }
                                });
                                this.state = this.sharedService.STATE.DUPLICATE;
                                break;
                            }
                            case this.sharedService.STATE.EDIT: {
                                payload = { campaignId: campaignId, status: active };
                                this.penaltyRewardCampaignService.getCampaign(payload).subscribe(function (result) {
                                    if (result) {
                                        _this.defaultValue = result.data.eventsData[0];
                                        if (_this.defaultValue.rules && _this.defaultValue.rules.length) {
                                            _this.defaultValue.rules = _.orderBy(_this.defaultValue.rules, ['limit'], ['asc']);
                                        }
                                        _this.defaultValue.notificationMapping = _this.notificationMapping;
                                        _this.defaultValue.notificationsData = _this.notificationsData;
                                        var endDate = new Date(_this.defaultValue.endDate);
                                        if (endDate.getTime() < _this.currentDate.getTime()) {
                                            _this.createFormViewRule();
                                            _this.state = _this.sharedService.STATE.VIEW;
                                        }
                                        else {
                                            _this.createFormEditRule();
                                            _this.state = _this.sharedService.STATE.EDIT;
                                        }
                                        _this.formLoaded = true;
                                        _this.loaderService.closeLoading();
                                        _this.initialRule = cloneDeep(_this.defaultValue);
                                    }
                                });
                                break;
                            }
                            case this.sharedService.STATE.CREATE: {
                                this.formLoaded = true;
                                this.defaultValue.notificationMapping = this.notificationMapping;
                                this.defaultValue.notificationsData = this.notificationsData;
                                this.loaderService.closeLoading();
                                break;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PenaltyRewardCampaignComponent.prototype.goForward = function (stepper) {
        stepper.next();
    };
    PenaltyRewardCampaignComponent.prototype.goBack = function (stepper) {
        stepper.previous();
    };
    PenaltyRewardCampaignComponent.prototype.mapActionData = function (ruleToBePushed, rule) {
        ruleToBePushed.useActionAmount = rule['useActionAmount'];
        ruleToBePushed.actionMax = rule['actionMax'];
        ruleToBePushed.actionMin = rule['actionMin'];
        ruleToBePushed.action = rule['action'];
    };
    PenaltyRewardCampaignComponent.prototype.isEqualValue = function (a, b) { return a == b; };
    PenaltyRewardCampaignComponent.prototype.createNotificationMapping = function (notificationMappings) {
        this.notificationsData = notificationMappings;
        for (var _i = 0, notificationMappings_1 = notificationMappings; _i < notificationMappings_1.length; _i++) {
            var mapping = notificationMappings_1[_i];
            this.notificationMapping[mapping.notifType] = mapping.displayText;
        }
    };
    return PenaltyRewardCampaignComponent;
}());
export { PenaltyRewardCampaignComponent };
