import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IncentiveService } from '../create-incentive/create-incentive.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.css']
})
export class AssignmentDetailsComponent implements OnInit {
  @Output() public close = new EventEmitter();
  @Input() public incentiveId: string;
  public metrics = {
    selectorName: "NA",
    selecorUserCount: "NA",
    assigned: "NA",
    date: "NA",
    status: "NA"
  };

  constructor(
    private sharedService: SharedService,
    private incentiveService: IncentiveService) { }

  ngOnInit(): void {
    this.fetchIncentiveDetails();
  }

  private async fetchIncentiveDetails() {
    const { data: incentive }: any = await this.incentiveService.getIncentive(this.incentiveId).toPromise();
    const selectorDetails: any = await this.sharedService.getUserSelectorDetails(incentive.ruleId).toPromise();

    this.metrics = {
      ...this.metrics,
      selectorName: selectorDetails.name,
      selecorUserCount: selectorDetails.userCount,
    };

    try {
      const metrics: any = await this.sharedService.getIncentiveMetrics(this.incentiveId).toPromise();
      this.metrics = {
        ...this.metrics,
        assigned: metrics.assignedCount,
        date: metrics.date,
        status: "PASS",
      };
    } catch { }
  }
}
