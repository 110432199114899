<div class="secondary-container shadow-all">
    <app-header [header]="header"></app-header>
    <div style="padding: 20px">
        <form [formGroup]="basicInfoFormGroup">
          <span class="heading" *ngIf="state != 'edit'">
            MANUAL_{{buildCityShortName()}}_{{buildServiceName()}}_{{buildIncentiveAndSegmentName()}}_{{buildFormatedFromDate()}}_{{buildFormatedToDate()}}_{{buildIncentiveType()}}_{{buildIdentifier()}}_{{buildRuleName()}}
          </span>
          <span class="heading" *ngIf="state == 'edit'">
            {{defaultValue.incentiveName}}
          </span>
            <div class="row padding-top-12">
                <div class="width-220 padding-left-24 padding-right-24">
                    <span class="rapido-form-field">
                        <label class="heading"> Type of Incentive </label><br>
                        <ng-select formControlName="selectedIncentive" name="type" placeholder="Select type"
                            (change)="incentiveTypeSelected();">
                            <!-- <ng-select formControlName="selectedIncentive" name="type" placeholder="Select type"> -->
                            <ng-option *ngFor="let item of incentiveType" [value]="item">{{item}}</ng-option>
                        </ng-select>
                        <p *ngIf="(basicInfoFormGroup.get('selectedIncentive').dirty && basicInfoFormGroup.get('selectedIncentive').invalid)"
                            [ngClass]="'error'"> Incentive type required. </p>
                        <p *ngIf="!((basicInfoFormGroup.get('selectedIncentive').dirty && basicInfoFormGroup.get('selectedIncentive').invalid))"
                            [ngClass]="'hidden-visibility'"> Incentive type required. </p>
                    </span>
                </div>

                <div class="width-220 padding-left-24 padding-right-24">
                  <span class="rapido-form-field">
                      <label class="heading"> Segment name </label><br>
                      <ng-select formControlName="selectedSegment" name="segmentName" placeholder="Select segment"
                      (change)="segmentSelected();">
                          <ng-option *ngFor="let item of segmentType" [value]="item">{{item}}</ng-option>
                      </ng-select>
                      <p *ngIf="(basicInfoFormGroup.get('selectedSegment').dirty && basicInfoFormGroup.get('selectedSegment').invalid)"
                          [ngClass]="'error'"> Segment name required. </p>
                      <p *ngIf="!((basicInfoFormGroup.get('selectedSegment').dirty && basicInfoFormGroup.get('selectedSegment').invalid))"
                          [ngClass]="'hidden-visibility'"> Segment name required. </p>
                  </span>
              </div>
                <div class="width-196 padding-right-24">
                    <span class="rapido-form-field">
                        <label class="heading"> Incentive name </label><br>
                        <input class="text-box" name="incentiveName" formControlName="incentiveName" pattern="[a-zA-Z0-9 ]*">
                        <p *ngIf="basicInfoFormGroup.get('incentiveName').invalid && basicInfoFormGroup.get('incentiveName').value != ''"
                            [ngClass]="'error'"> Only alphanumeric characters allowed. </p>
                        <p *ngIf="!(basicInfoFormGroup.get('incentiveName').invalid && basicInfoFormGroup.get('incentiveName').value != '')"
                            [ngClass]="'hidden-visibility'"> Only alphanumeric characters allowed. </p>
                    </span>
                </div>
                <div class="incentive-selectbox">
                  <span class="rapido-form-field">
                      <label class="heading"> City</label><br>
                    <ng-select formControlName="selectedCity" [compareWith]="compareCityFn" name="city" placeholder="Select city" (change)="fetchRequiredDetails()">
                        <ng-option *ngFor="let item of cities" [value]="item">{{item.displayName}}</ng-option>
                    </ng-select>
                    <p *ngIf="(basicInfoFormGroup.get('selectedCity').dirty && basicInfoFormGroup.get('selectedCity').invalid)" [ngClass]="'error'"> City required. </p>
                    <p *ngIf="!((basicInfoFormGroup.get('selectedCity').dirty && basicInfoFormGroup.get('selectedCity').invalid))" [ngClass]="'hidden-visibility'"> City required. </p>
                  </span>
                </div>
                <div class="width-196 padding-right-24">
                  <span class="rapido-form-field">
                    <label class="heading"> Type of service</label><br>
                    <ng-select formControlName="selectedService" [compareWith]="compareServiceFn" name="service"
                      placeholder="Select service" [multiple]=true (change)="serviceSelected()">
                      <ng-option *ngFor="let item of services" [value]="item">{{item.service.name}}</ng-option>
                    </ng-select>
                    <p *ngIf="(basicInfoFormGroup.get('selectedService').dirty && basicInfoFormGroup.get('selectedService').invalid)"
                      [ngClass]="'error'"> Service required. </p>
                    <p *ngIf="!((basicInfoFormGroup.get('selectedService').dirty && basicInfoFormGroup.get('selectedService').invalid))"
                      [ngClass]="'hidden-visibility'"> Service required. </p>
                  </span>
                </div>
                <div *ngIf="adHocIncentive" class="width-196 padding-right-24">
                    <span class="rapido-form-field">
                        <label class="heading"> App Display Name </label><br>
                        <ng-select formControlName="incentiveDisplayName" name="incentiveDisplayName" placeholder="Select display name">
                            <ng-option *ngFor="let item of adhocIncentiveNames" [value]="item">{{item}}</ng-option>
                        </ng-select>
                        <p *ngIf="(basicInfoFormGroup.get('incentiveDisplayName').dirty && basicInfoFormGroup.get('incentiveDisplayName').invalid)"
                            [ngClass]="'error'"> Display name required. </p>
                    </span>
                </div>
            </div>

            <div class="row">
              <div class="padding-left-24 padding-right-24">
                <span class="rapido-form-field">
                  <label class="heading">Check the below box for creation of HH incentive</label><br>
                  <mat-checkbox formControlName="isHHIncentive" (change)="HHIncentiveChecked()">
                    HH Incentive
                  </mat-checkbox>
                </span>
              </div>
            </div>

             <div class="row">
                <div class="incentive-selectbox margin-top-48 display-none">
                    <span class="rapido-form-field">
                        <label class="heading"> Shift</label><br>
                        <ng-select formControlName="selectedShift" [compareWith]="compareShiftFn"  name="shift" placeholder="Select shift"
                        [multiple]=true (change)="shiftSelected()">
                            <ng-option *ngFor="let item of shifts" [value]="item">{{item}}</ng-option>
                        </ng-select>
                        <p *ngIf="(basicInfoFormGroup.get('selectedShift').dirty && basicInfoFormGroup.get('selectedShift').invalid)" [ngClass]="'error'"> Shift required. </p>
                        <p *ngIf="!((basicInfoFormGroup.get('selectedShift').dirty && basicInfoFormGroup.get('selectedShift').invalid))" [ngClass]="'hidden-visibility'"> Shift required. </p>
                    </span>
                </div>
                <div class="margin-top-48 padding-left-24 flex-col">
                  <div class="flex-row">
                    <div class="width-196 padding-right-24 ruleSelector">
                      <span class="rapido-form-field">
                          <label class="heading"> Identifier </label><br>
                          <input class="text-box" name="identifier" formControlName="identifier" pattern="[a-zA-Z0-9 ]*">
                          <p *ngIf="basicInfoFormGroup.get('identifier').invalid && basicInfoFormGroup.get('identifier').value != ''"
                              [ngClass]="'error'"> Only alphanumeric characters allowed. </p>
                          <p *ngIf="!(basicInfoFormGroup.get('identifier').invalid && basicInfoFormGroup.get('identifier').value != '')"
                              [ngClass]="'hidden-visibility'"> Only alphanumeric characters allowed. </p>
                      </span>
                  </div>
                    <div class="incentive-selectbox ruleSelector">
                      <span class="rapido-form-field">
                          <label class="heading"> Rule - User Selector </label><br>
                          <ng-select formControlName="selectedRule" [compareWith]="compareRuleFn" name="rule" placeholder="Rule" (click)="checkRuleObj()">
                            <ng-option *ngFor="let item of rules" [value]="item">{{item}}</ng-option>
                          </ng-select>
                          <p *ngIf="(basicInfoFormGroup.get('selectedRule').dirty && basicInfoFormGroup.get('selectedRule').invalid)" [ngClass]="'error'"> Rule required. </p>
                          <p *ngIf="!((basicInfoFormGroup.get('selectedRule').dirty && basicInfoFormGroup.get('selectedRule').invalid))" [ngClass]="'hidden-visibility'"> Rule required. </p>
                        </span>
                    </div>
                    <button class="rule-button" mat-raised-button color="primary" type="button" (click)="openUserSelectorPage()" [disabled]="getValues('isHHIncentive')">Create new selector</button>
                  </div>
                  <span class="heading" *ngIf="basicInfoFormGroup.get('incentiveName').value && !(basicInfoFormGroup.get('isHHIncentive').value)">Selected incentive name is ( {{basicInfoFormGroup.get('incentiveName').value}} ). Please select user selector accordingly</span>
                  <span class="user-count" *ngIf="selectedRuleUserCount">
                    <span class="bold">
                      Actual captain count in the user selector: {{selectedRuleUserCount.count}}
                    </span>
                    <span class="italic">
                      {{selectedRuleUserCount.loaded ? "(The number might change at the time of assignment.)" : "Loading..."}}
                    </span>
                  </span>
                </div>
            </div>

             <div class="row">
                <div class="width-220 padding-left-24 padding-right-24 margin-top-48">
                  <span class="rapido-form-field">
                    <label class="heading"> Start Date</label><br>
                      <p-calendar selectOtherMonths="true" [inputStyle]="{'width':'137px', 'border': '1px solid #ccc'}" formControlName="fromDate" (ngModelChange)="changeInDate()" icon="pi pi-calendar" [showIcon]=true dateFormat="m/d/yy"  [maxDate]="maxDate"></p-calendar>
                      <p *ngIf="(basicInfoFormGroup.get('fromDate').dirty && basicInfoFormGroup.get('fromDate').invalid)" [ngClass]="'error'"> Start date required. </p>
                      <p *ngIf="!((basicInfoFormGroup.get('fromDate').dirty && basicInfoFormGroup.get('fromDate').invalid))" [ngClass]="'hidden-visibility'"> Start date required. </p>
                  </span>
                </div>
                <div class="margin-top-48 padding-right-28">
                  <span class="rapido-form-field">
                    <label class="heading"> End Date</label><br>
                      <p-calendar selectOtherMonths="true" [inputStyle]="{'width':'140px', 'border': '1px solid #ccc'}" formControlName="toDate"  icon="pi pi-calendar"
                        [showIcon]=true dateFormat="m/d/yy" (ngModelChange)="changeInDate()" [minDate]="getMinEditableDate()"></p-calendar>
                      <p *ngIf="(basicInfoFormGroup.get('toDate').dirty && basicInfoFormGroup.get('toDate').invalid)" [ngClass]="'error'"> End date required. </p>
                      <p *ngIf="!((basicInfoFormGroup.get('toDate').dirty && basicInfoFormGroup.get('toDate').invalid))" [ngClass]="'hidden-visibility'"> End date required. </p>
                  </span>
                </div>
                <div *ngIf="(basicInfoFormGroup.get('selectedIncentive').value !== 'Adhoc')" ngClass="width-176 margin-top-48">
                    <label class="heading"> Priority </label><br>
                      <input formControlName="priority" class="text-box"  name="priority" type="number"
                      (ngModelChange)="checkPriority()">
                      <p *ngIf="(basicInfoFormGroup.get('priority').dirty && basicInfoFormGroup.get('priority').invalid)" [ngClass]="'error'"> Priority Required. </p>
                      <p *ngIf="!((basicInfoFormGroup.get('priority').dirty && basicInfoFormGroup.get('priority').invalid))" [ngClass]="'hidden-visibility'"> Priority Required. </p>
                </div>
            </div>

            <div class="row">
                <div class="margin-top-100 divider"></div>
            </div>

            <div class="row">
                <div class="pull-right">
                    <span class="rapido-form-field">
                        <button class="back-button" (click)="goBack()" type="button">
                          Back
                        </button>
                      </span>
                    <span class="rapido-form-field">
                        <button mat-raised-button class="next-button" color="primary" type="button" (click)="passBasicInfo()">Next</button>
                      </span>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #HHIncentiveAlertDialog>
  <h3 matDialogTitle>Overlapping HH Incentives with Higher Priority</h3>
  <table class="table table-striped table-bordered outerTable">
    <thead>
      <tr>
        <th>Incentive Id</th>
        <th>Incentive name</th>
        <th>City</th>
        <th>Services</th>
        <th>Priority</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let incentive of errorPopupIncentives">
        <td class="displayStyle">{{incentive._id.$oid}}</td>
        <td class="displayStyle">{{incentive.incentiveName}}</td>
        <td class="displayStyle">{{incentive.cities}}</td>
        <td class="displayStyle">{{incentive.serviceNames}}</td>
        <td class="displayStyle">{{incentive.priority}}</td>
      </tr>
    </tbody>
  </table>
  <span>Please create an Incentive with Higher Priority to proceed</span>
  <div mat-dialog-actions class="button-flex" style="float:right;">
    <button mat-button matDialogClose>ok</button>
  </div>
</ng-template>
