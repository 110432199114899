import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { MatTableModule, MatPaginatorModule, MatIconModule, MatMenuModule } from '@angular/material';
import { HeaderComponent } from './header/header.component';
// import { SidenavContentComponent } from './sidenav-content/sidenav-content.component';
// import { SidenavListComponent } from './sidenav-list/sidenav-list.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
// import { ToasterComponent } from './toaster/toaster.component';

@NgModule({
  declarations: [TableComponent, HeaderComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule
  ],
  exports: [
    TableComponent,
    HeaderComponent,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule
  ]
})
export class SharedModule { }
