import { OnInit, EventEmitter } from '@angular/core';
var RadioButtonsComponent = /** @class */ (function () {
    function RadioButtonsComponent() {
        this.selectedRadio = new EventEmitter();
    }
    RadioButtonsComponent.prototype.ngOnInit = function () {
        // console.log('radio : ', this.radioData);
        // this.radioData = {
        //     type : 'radio-button',
        //     title : 'Status',
        //     values : ['Both', 'Active', 'Inactive'],
        //     default : 'Both',
        //     key : 'active-radio'
        //   };
    };
    RadioButtonsComponent.prototype.chosenOptions = function () {
        var _a;
        var emitSelections = (_a = {},
            _a[this.radioData.key] = this.selectedValues,
            _a);
        // console.log('Emitted radio : ', emitSelections);
        this.selectedRadio.emit(emitSelections);
    };
    return RadioButtonsComponent;
}());
export { RadioButtonsComponent };
