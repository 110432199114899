import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes, Button } from '../shared/types';
import { SharedService } from '../shared/shared.service';
import { LoaderService } from '../loader.service';
import { Router } from '@angular/router';
import { ViewCampaignsService } from './view-campaigns.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types/toaster.types';
import * as moment from 'moment-timezone';
import { LogService } from '../logs.service';
import { AuthService } from '../auth.service';
import { RULE_KEY_MAPPINGS } from '../penalty-reward-campaign/constants';
const timezone = 'Asia/Kolkata';

const statusOptions = {
  active: 'Active',
  inactive: 'Inactive'
};

@Component({
  selector: 'app-view-campaigns',
  templateUrl: './view-campaigns.component.html',
  styleUrls: ['./view-campaigns.component.css']
})
export class ViewCampaignsComponent implements OnInit, OnChanges {

  public limit = 10;
  public offset = 0;
  public table: Table;
  tableLoaded = false;
  cities: any;
  services: any;
  uniqueServices: any = [];
  status = [
    statusOptions.active,
    statusOptions.inactive
  ];
  mappedEvents = [];
  public filterParams: any = {};
  public filter: any = {};
  public serviceInfo: any = {};
  public date: any;
  events: any = {};
  user;

  showActivityLogs = false;
  activityLog: any;

  constructor(
    private viewCampaignsService: ViewCampaignsService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private _route: Router,
    public toasterService: ToasterService,
    private logService: LogService,
    private authService: AuthService

  ) {
    this.defineTable();
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  submitFilter() {
    this.filter = {};
    if (this.filterParams.city && this.filterParams.city.length && this.filterParams.service && this.filterParams.service.length) {
      if (this.filterParams.campaignName) {
        this.filter.action = this.filterParams.campaignName;
      }

      if (this.filterParams.status === statusOptions.inactive) {
        this.filter.active = false;
      } else if (this.filterParams.status === statusOptions.active) {
        this.filter.active = true;
      }

      if (this.filterParams.event) {
        this.filter.event = this.filterParams.event;
      }

      if (this.filterParams.city) {
        if (this.filterParams.service && this.filterParams.service.length) {
          const filteredServiceDetails = [];
          for (let i = 0; i < this.filterParams.service.length; i++) {
            let matchingServiceDetails = this.services.filter(service => service.serviceId === this.filterParams.service[i]).map(service => service.id);
            filteredServiceDetails.push.apply(filteredServiceDetails, matchingServiceDetails);
          }
          this.filter.serviceDetails = filteredServiceDetails;
        } else {
          const filteredServices = [];
          if (this.services) {
            this.services.forEach(service => {
              filteredServices.push(service.id);

            });
          }
          this.filter.serviceDetails = filteredServices;
        }
      }

      if (this.filterParams.startDate) {
        this.filter.startDate = moment.tz(this.filterParams.startDate, timezone).format('YYYY-MM-DD').toString();
      }

      if (this.filterParams.endDate) {
        this.filter.endDate = moment.tz(this.filterParams.endDate, timezone).format('YYYY-MM-DD').toString();
      }
      this.table.pagination.setPageNoToZero();
      this.fetchTableData();
    }
    else {
      if ((!this.filterParams.city || !this.filterParams.city.length) && (!this.filterParams.service || !this.filterParams.service.length)) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Please select a city and service`,
        }));
      }
      else if (!this.filterParams.city || !this.filterParams.city.length) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Please select a city`,
        }));
      }
      else if (!this.filterParams.service || !this.filterParams.service.length) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Please select a service`,
        }));
      }
    }
  }

  clearFilter() {
    const filters = this.filter;
    this.filterParams = {};
    this.filter = {};
    if (Object.keys(filters).length === 0) {
      return;
    }
    this.table.pagination.pageNo = 0;
    this.fetchTableData();
  }

  defineTable() {
    this.table = new Table({
      headers: {
        campaignName: 'Campaign Name',
        events: 'Events',
        city: 'City',
        services: 'Services',
        startDate: 'Start Date',
        endDate: 'End Date',
        status: 'Status',
        actions: 'Actions'
      },
      pagination: new Pagination(0, 10, 10, [10, 15, 20]),
      onSortingChange: (header) => {
      }
    });
    this.tableLoaded = false;
  }

  ngOnInit() {
    this.getEvents();
    this.getCities();
    this.authService.getUserDetails().subscribe((data: any) => { this.user = data; });
  }

  async getCities() {
    try {
      const response: any = await this.sharedService.fetchCities().toPromise();
      const cities = response['data']['cities'];
      this.cities = cities.map(city => {
        return {
          id: city._id,
          name: city.displayName,
        };
      }).sort((c1: { name: number; }, c2: { name: number; }) => {
        return c1.name > c2.name ? 1 : -1;
      });
    } catch (error) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching cities`,
      }));
    }

  }

  public onSelectAll() {
    const allCities = this.cities.map(item => item.id);
    this.filterParams.city = allCities;
    this.getCityServiceDetails(allCities);
  }

  public onClearAll() {
    this.filterParams.city = [];
    this.getCityServiceDetails(this.filterParams.city);
  }

  async getCityServiceDetails(event: any) {
    try {
      if (event) {
        this.loaderService.openLoading();
        const response: any = await this.sharedService.fetchServices(event).toPromise();
        const services = response.data;
        this.services = services.map((service: { _id: any; service: { name: any, _id: any; }; }) => {
          return {
            id: service._id,
            serviceId: service.service._id,
            name: service.service.name
          };
        });

        let map = new Map();
        this.uniqueServices = [];
        for (let service of this.services) {
          if (!map.has(service.serviceId)) {
            map.set(service.serviceId, true); // set any value to Map
            this.uniqueServices.push({
              id: service.id,
              serviceId: service.serviceId,
              name: service.name
            });
          }
        }
        this.loaderService.closeLoading();
      } else {
        delete this.services;
      }
    } catch (error) {
      this.loaderService.closeLoading();
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching services`,
      }));
    }
  }

  getEvents = () => {
    this.viewCampaignsService.fetchEventsData().subscribe((response) => {
      if (response && response['data'] && response['data']['data']) {
        const events = response['data']['data'];
        this.mappedEvents = events;
        events.forEach(event => {
          this.events[event['event']] = event['displayText'];
        });
      }
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in fetching events`,
      }));
    });
  }

  fetchTableData = async () => {
    const filter = { ...this.filter };
    const payload = {
      filter,
      pagination: {
        limit: this.table.pagination.perPage,
        offset: this.table.pagination.pageNo * this.table.pagination.perPage
      }
    };

    const accessRights = await this.authService.getPermissions();
    this.table = new Table({
      headers: {
        campaignName: 'Name',
        events: 'Event',
        city: 'City',
        services: 'Service',
        startDate: 'Start Date',
        endDate: 'End Date',
        status: 'Status',
        action: 'Action'
      },
      pagination: this.table.pagination,
      onSortingChange: (header) => {
      }
    });
    if (Object.keys(filter).length > 0) {
      this.viewCampaignsService.fetchCampaignsData(payload)
        .subscribe((result: any) => {
          this.table.pagination.count = result.data.totalDocs;
          this.table.data = result.data.eventsData.map(data => {
            this.serviceInfo = {};
            const id = data._id;
            const rid = data.ruleId;
            const campaign = data.ruleName;
            const city = data.cityName;
            const service = data.serviceNames || data.serviceName;
            const event = this.getEventsList(data.events);
            const startDate: string = moment.tz(data.startDate, timezone).format('YYYY-MM-DD');
            const endDate: string = moment.tz(data.endDate, timezone).format('YYYY-MM-DD');
            const status = data.active ? statusOptions.active : statusOptions.inactive;
            return {
              _id: id,
              rid,
              campaignName: campaign,
              events: event,
              city,
              services: service,
              startDate,
              endDate,
              status: this.createStatus(status),
              action: this.createAction(accessRights, status, endDate)
            };
          });
          this.tableLoaded = true;
          this.loaderService.closeLoading();
        }, (err) => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Error in fetching data`,
          }));
          this.loaderService.closeLoading();
          this.tableLoaded = false;
        });
    } else {
      this.loaderService.closeLoading();
      this.tableLoaded = false;
    };
  }

  getEventsList(events) {
    switch (typeof (events)) {
      case "string":
        return this.events[events];
        break;
      case "object":
        {
          let eventList = [];
          for (let event of events) {
            eventList.push(this.events[event]);
          }
          return eventList.join(", ");
        }
        break;
    }
  }

  createStatus(status) {

    return new TableData({
      data: status,
      type: TableDataTypes.STATUS,
    });
  }

  createAction(accessData: any, status: any, endDate: string) {
    const actionArr = [];
    if (accessData.includes('view_penalty_reward') || accessData.includes('edit_penalty_reward')) {
      actionArr.push(
        new TableAction({
          onClick: (data) => {
            this.onView(data.rid, data.status);
          },
          text: 'View'
        })
      );
    }
    if (accessData.includes('edit_penalty_reward')) {

      if (status === statusOptions.active) {
        actionArr.push(
          new TableAction({
            onClick: (data) => {
              this.onDeActivate(data.rid);
            },
            text: 'De-Activate'
          })
        );

        if (endDate >= moment.tz(timezone).format('YYYY-MM-DD')) {
          actionArr.push(
            new TableAction({
              onClick: (data) => {
                this.OnEdit(data.rid, data.status);
              },
              text: 'Edit'
            })
          );
        }
      }

      actionArr.push(
        new TableAction({
          onClick: (data) => {
            this.OnClone(data.rid, data.status);
          },
          text: 'Clone'
        })
      );
      actionArr.push(
        new TableAction({
          onClick: (data) => {
            this.OnLogs(data.rid);
          },
          text: 'Activity Log'
        })
      );
    }

    return new TableData({
      actions: new TableActions(actionArr),
      type: TableDataTypes.ACTION
    });
  }

  onView = (campaignId, status) => {
    const cId = encodeURIComponent(campaignId);
    let active: boolean;
    if (status.data === statusOptions.inactive) {
      active = false;
    } else {
      active = true;
    }
    const url = this._route.serializeUrl(this._route.createUrlTree([`/campaign/${cId}/${active}/view`]));
    window.open(url);
  }

  onDeActivate = (data) => {
    let previous = {
      _id: data,
      rid: data,
      active: true
    }

    let changed = {
      _id: data,
      rid: data,
      active: false
    }
    this.viewCampaignsService.deactivateRule(data).subscribe(result => {
      if (result) {
        const metadata = {
          changedKeys: [RULE_KEY_MAPPINGS['active']],
          user: {
            name: this.user.firstName,
            email: this.user.email
          }
        };
        this.logService.insertEvent(data, previous, changed, metadata, this.user._id).subscribe();
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: 'Successfully De-Activated Campaign',
        }));
      }
    }, error => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Error in DeActivating campaign`,
      }));
      console.log(error);
    });
  }

  OnClone = (campaignId, status) => {
    const cId = encodeURIComponent(campaignId);
    let active: boolean;
    if (status.data === statusOptions.inactive) {
      active = false;
    } else {
      active = true;
    }
    const url = this._route.serializeUrl(this._route.createUrlTree([`/campaign/${cId}/${active}/duplicate`]));
    window.open(url);
  }

  OnLogs = (campaignId) => {
    this.logService.fetchLogs(campaignId).subscribe(result => {
      this.activityLog = result && result['data'] || [];
    });
    this.showActivityLogs = true;
  }

  OnEdit = (campaignId, status) => {
    const cId = encodeURIComponent(campaignId);
    let active: boolean;
    if (status.data === statusOptions.inactive) {
      active = false;
    } else {
      active = true;
    }
    const url = window.location.origin + `/campaign/${cId}/${active}/edit`;
    window.open(url);
  }

  toggleActivityLog = (value) => {
    if (value) {
      this.showActivityLogs = value;
    } else {
      this.showActivityLogs = !this.showActivityLogs;
    }
    if (this.showActivityLogs) {
      document.getElementById('overlay').style.display = 'block';
    } else {
      document.getElementById('overlay').style.display = 'none';
    }
  }
}
