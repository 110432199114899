import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import { LoaderService } from '../../loader.service';
import { ToasterService } from "../../toaster.service";
import { Toaster, ToasterType } from "src/app/shared/types/toaster.types";
import { PenaltyRewardCampaignService } from "../penalty-reward-campaign.service";
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { ACTIONS, defaultLocale, supportedLocales } from '../constants';
import { TextFieldModule } from '@angular/cdk/text-field';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-penalty-rule',
  templateUrl: './penalty-rule.component.html',
  styleUrls: ['./penalty-rule.component.css']
})
export class PenaltyRuleComponent implements OnInit, OnChanges {

  @Output() rulesChange: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Input() defaultValue: any;
  @Input() campaignData: string;
  @Input() state: string;
  @Input() NOT_EDITABLE: any;
  showPopUp = false;
  selection: any;
  useActionAmount = false;
  actionMax = false;
  actionMin = false;
  showOldRule:boolean = false;

  defaultLocale = defaultLocale;
  supportedLocales = supportedLocales;

  getEmptyRule() {
    return this.fb.group({
      action: null,
      blockRedeem: false,
      startLimit: null,
      endLimit: null,
      workingDays: null,
      adjustmentType: null,
      amount: null,
      prioritizeAfterMins: null,
      activateAfterHours: null,
      actionMax:false,
      useActionAmount: false,
      actionMin:false,
      notificationDisplay: null,
      _id: null,
      communications : this.fb.array([this.getEmptySet()]),
     captainTask:null,
     showCaptainTask:false,
     campaignId:null,
    });
  }
  getOldEmptyRule() {
    return this.fb.group({
      action: null,
      blockRedeem: false,
      message: null,
      startLimit: null,
      endLimit: null,
      workingDays: null,
      adjustmentType: null,
      amount: null,
      activateAfterHours: null,
      prioritizeAfterMins: null,
      notification: null,
      notificationDisplay: null,
      _id: null
    });
  }

  checkTitleAndMessageForLocale(locale: any, formGroup: any) {
    return formGroup.controls.titleLocales.value[locale.code].value && formGroup.controls.messageLocales.value[locale.code].value
  }

  getTitleFormControl(ruleFormGroup: any) {
    return ruleFormGroup.controls.titleLocales.value[ruleFormGroup.controls.currentSelectedLocale.value.code];
  }

  getMessageFormControl(ruleFormGroup: any) {
    return ruleFormGroup.controls.messageLocales.value[ruleFormGroup.controls.currentSelectedLocale.value.code];
  }

  getEmptySet() {
   return this.fb.group({
      type: null,
      templateId: null,
      titleLocales: this.supportedLocales.reduce((acc, locale) => ({ ...acc, [locale.code]: this.fb.control(null) }), {}),
      messageLocales: this.supportedLocales.reduce((acc, locale) => ({ ...acc, [locale.code]: this.fb.control(null) }), {}),
      currentSelectedLocale: this.fb.control(this.defaultLocale),
    })
  }
  rules:any = this.fb.array([this.getEmptyRule()]);
  penaltyRulesForm:any = this.fb.group({ rules: this.rules });
  oldRules:any = this.fb.array([this.getOldEmptyRule()]);
  oldPenaltyRulesForm:any = this.fb.group({ oldRules: this.oldRules });

  public actionOptions: any;
  public campaignType: string;
  public gameTriggerEvent: string;
  public notificationsData: any;
  public notificationMapping: any;
  public blockRedeemEligible: boolean = false;
  public captainTask:any = [];
  public campaignId:any = [];
  public whatsappTemplates:any = [];
  constructor(
    public fb: FormBuilder,
    public sharedService: SharedService,
    public toasterService: ToasterService,
    public loaderService: LoaderService,
    public penaltyRewardCampaignService: PenaltyRewardCampaignService,
    private dialogService: ConfirmDialogService

  ) {
    this.getActions();
    this.getCaptainTasks();
    this.getGalaxyCampaigns();
  }
  ngOnInit() {
    this.notificationsData = this.defaultValue.notificationsData;
    this.whatsappTemplates = this.notificationsData && this.notificationsData.filter(notification => notification.notifType === 'WHATSAPP')[0].templates;
    this.notificationMapping = this.defaultValue.notificationMapping;
    switch (this.state) {
      case this.sharedService.STATE.VIEW:
      case this.sharedService.STATE.EDIT:
      case this.sharedService.STATE.DUPLICATE: {
        if(this.defaultValue.rules[0] && this.defaultValue.rules[0].communications && this.defaultValue.rules[0].communications.length!==0) {
          this.showOldRule=false;
        }
        else {
          this.showOldRule=true;
        }
        this.patchDefaultValues(this.defaultValue.rules);
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campaignData.currentValue.basicInfo) {
      this.campaignType = changes.campaignData.currentValue.basicInfo.ruleType;
      this.gameTriggerEvent = changes.campaignData.currentValue.basicInfo.eventsAndReasons[0].event;
      this.blockRedeemEligible = changes.campaignData.currentValue.basicInfo.blockRedeemEligibility;
      if (this.isGame()) {
        this.actionOptions = ["reward"];
      }
      else {
        this.getActions()
      }
    }
  }

  addRule() {
    if (this.state === this.sharedService.STATE.VIEW) { return; }
    this.rules.push(this.getEmptyRule());
  }

  addOldRule() {
    if (this.state === this.sharedService.STATE.VIEW) { return; }
    this.oldRules.push(this.getOldEmptyRule());
  }

  deleteRule(index: number) {
    if (this.state === this.sharedService.STATE.VIEW) { return; }
    this.rules.controls.splice(index, 1);
    this.rules.value.splice(index, 1);
    if (!this.rules.controls.length) { this.addRule(); }
  }

  deleteOldRule(index: number) {
    if (this.state === this.sharedService.STATE.VIEW) { return; }
    this.oldRules.controls.splice(index, 1);
    this.oldRules.value.splice(index, 1);
    if (!this.oldRules.controls.length) { this.addOldRule(); }
  }


  deleteSet(index,setIndex) {
    if (this.state === this.sharedService.STATE.VIEW || setIndex==0) { return; }
    this.rules.controls[index].controls.communications.controls.splice(setIndex,1);
    this.rules.value[index].communications.splice(setIndex,1);
  }

  toggleActionMax(index) {
    this.penaltyRulesForm.controls.rules.controls[index].controls.actionMax.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.actionMax.value)
  }

  toggleActionAmount(index) {
    this.penaltyRulesForm.controls.rules.controls[index].controls.useActionAmount.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.useActionAmount.value)
  }

  toggleActionMin(index) {
    this.penaltyRulesForm.controls.rules.controls[index].controls.actionMin.patchValue(!this.penaltyRulesForm.controls.rules.controls[index].controls.actionMin.value)
  }

  makeFieldsDisable() {
    this.oldPenaltyRulesForm.get('oldRules').disable();
  }

  patchDefaultValues(defaultRules) {
    if(this.showOldRule) {
      defaultRules.forEach(async (defaultRule, index) => {
           let amount;
        let ruleControls;
        if (!this.oldRules.controls[index]) {
          this.oldRules.push(this.getOldEmptyRule());
        }
        ruleControls = this.oldPenaltyRulesForm.controls.oldRules['controls'][index].controls;
        if (defaultRule.action === "notification") {
          ruleControls.action.value = ACTIONS.WARNING;
        } else if (defaultRule.action === "suspend") {
          if (defaultRule.activateAfterHours) {
            ruleControls.action.value = ACTIONS.SUSPEND;
            ruleControls.activateAfterHours.value = defaultRule.activateAfterHours;
          } else {
            ruleControls.action.value = ACTIONS.DEACTIVATION;
          }
        } else if (defaultRule.action === ACTIONS.DEPRIORITIZE) {
          ruleControls.action.value = ACTIONS.DEPRIORITIZE;
          if (defaultRule.prioritizeAfterMins) {
            ruleControls.prioritizeAfterMins.value = defaultRule.prioritizeAfterMins;
          }
        } else {
          ruleControls.action.value = defaultRule.action;
        }
        ruleControls.message.value = defaultRule.displayReason;
        ruleControls.startLimit.value = defaultRule.limit;
        ruleControls.workingDays.value = defaultRule.workingDays || null;
        if (defaultRule.applicableTill) {
          ruleControls.endLimit.value = defaultRule.applicableTill;
        }
        if (defaultRule.amount) {
          if (defaultRule.action === ACTIONS.ADJUSTMENT) {
            ruleControls.adjustmentType.value = defaultRule.adjustmentType;
          }
          if (defaultRule.adjustmentType && defaultRule.adjustmentType === "debit") {
            amount = -1 * defaultRule.amount;
            ruleControls.amount.value = amount;
          } else {
            ruleControls.amount.value = defaultRule.amount;
          }
        }
        if (this.state === this.sharedService.STATE.VIEW) {
          ruleControls.notification.value = this.notificationMapping[defaultRule.notificationType];
        }

        if (this.state === this.sharedService.STATE.DUPLICATE || this.state === this.sharedService.STATE.EDIT) {
          ruleControls.notification.value = defaultRule.notificationType;
        }

        if (this.state === this.sharedService.STATE.EDIT) {
          ruleControls._id.value = defaultRule._id;
        }
        if (defaultRule.hasOwnProperty('blockRedeem')) {
          ruleControls.blockRedeem.value = defaultRule.blockRedeem;
        }
      });
      if (this.state === this.sharedService.STATE.VIEW) {
        this.makeFieldsDisable();
      }
    }
    else {
      defaultRules.forEach(async (defaultRule, index) => {
        let amount;
        let ruleControls;
        if (!this.penaltyRulesForm.controls.rules.controls[index]) {
          this.penaltyRulesForm.controls.rules.controls.push(this.getEmptyRule());
        }
        defaultRule.communications.forEach(async (rule, j)=>{
          if(!this.penaltyRulesForm.controls.rules.controls[index].controls.communications.controls[j]) {
            this.penaltyRulesForm.controls.rules.controls[index].controls.communications.controls.push(this.getEmptySet());
          }
          ruleControls = this.penaltyRulesForm.controls.rules.controls[index].controls;
          if (defaultRule.action === "notification") {
            ruleControls.action.value = ACTIONS.WARNING;
          } else if(defaultRule.action === "penalty" || defaultRule.action === "adjustment" || defaultRule.action === "reward") {
            setActionFlags(defaultRule, ruleControls);
            ruleControls.action.value = defaultRule.action;
          } else if (defaultRule.action === ACTIONS.SUSPEND) {
            if (defaultRule.activateAfterHours) {
              ruleControls.action.value = ACTIONS.SUSPEND;
              ruleControls.activateAfterHours.value = defaultRule.activateAfterHours;
            } else {
              ruleControls.action.value = ACTIONS.DEACTIVATION;
            }
            if(defaultRule.captainTask && defaultRule.captainTask.length) {
              ruleControls.captainTask.value = defaultRule.captainTask
              ruleControls.showCaptainTask.value = true
            }
          }  else if (defaultRule.action === ACTIONS.DEPRIORITIZE) {
            ruleControls.action.value = ACTIONS.DEPRIORITIZE;
            if (defaultRule.prioritizeAfterMins) {
              ruleControls.prioritizeAfterMins.value = defaultRule.prioritizeAfterMins;
            }
          } else {
            ruleControls.action.value = defaultRule.action;
            if(defaultRule.campaignId && defaultRule.campaignId.length) {
              ruleControls.campaignId.value = defaultRule.campaignId
            }
          }
          if (rule.message && rule.title) {
            ruleControls.communications.controls[j].controls.messageLocales.value[this.defaultLocale.code].value = rule.message;
            ruleControls.communications.controls[j].controls.titleLocales.value[this.defaultLocale.code].value = rule.title;
          }
          
          ruleControls.communications.controls[j].controls.type.value = rule.type;
          if (this.state == this.sharedService.STATE.VIEW || this.whatsappTemplates.includes(rule.templateId)) {
            ruleControls.communications.controls[j].controls.templateId.value = rule.templateId;
          }
          ruleControls.startLimit.value = defaultRule.limit;
          ruleControls.workingDays.value = defaultRule.workingDays || null;
          if (defaultRule.applicableTill) {
            ruleControls.endLimit.value = defaultRule.applicableTill;
          }
          if (defaultRule.amount || defaultRule.amount == 0) {            
            if (rule.action === ACTIONS.ADJUSTMENT) {
              ruleControls.adjustmentType.value = defaultRule.adjustmentType;
            }
            if (defaultRule.adjustmentType && defaultRule.adjustmentType === "debit") {
              amount = -1 * defaultRule.amount;
              ruleControls.amount.value = amount;
            } else {
              ruleControls.amount.value = defaultRule.amount;
            }
          }

          if (this.state === this.sharedService.STATE.EDIT) {
            ruleControls._id.value = defaultRule._id;
          }
          if (defaultRule.hasOwnProperty('blockRedeem')) {
            ruleControls.blockRedeem.value = defaultRule.blockRedeem;
          }
          if (rule.titleLocaleKey && rule.messageLocaleKey) {
            let translations = await this.sharedService.fetchTranslations(this.supportedLocales.map(locale => locale.code), [encodeURIComponent(rule.titleLocaleKey), encodeURIComponent(rule.messageLocaleKey)]).toPromise();
            for (const locale in translations) {
              if (translations[locale][rule.titleLocaleKey]) {
                ruleControls.communications.controls[j].controls.titleLocales.value[locale].setValue(translations[locale][rule.titleLocaleKey])
              }
              if (translations[locale][rule.messageLocaleKey]) {
                ruleControls.communications.controls[j].controls.messageLocales.value[locale].setValue(translations[locale][rule.messageLocaleKey])
              }
            }
          }
        })
      });
      if (this.state === this.sharedService.STATE.VIEW) {
        this.penaltyRulesForm.get('rules').disable();
        this.penaltyRulesForm.get('rules').controls.forEach((rule) => {
          rule.controls.communications.controls.forEach((set) => {
            Object.entries(set.controls.titleLocales.value as object).forEach(([_, value]) => { value.disable() })
            Object.entries(set.controls.messageLocales.value as object).forEach(([_, value]) => { value.disable() })
            set.controls.currentSelectedLocale.enable();
          });
        });
      }
    }

    function setActionFlags(defaultRule: any, ruleControls: any) {
      if (defaultRule.useActionAmount)
        ruleControls.useActionAmount.value = true;
      else
        ruleControls.useActionAmount.value = false;
      if (defaultRule.actionMax)
        ruleControls.actionMax.value=true;
      else
        ruleControls.actionMax.value=false;
      if(defaultRule.actionMin)
        ruleControls.actionMin.value=true;
      else
        ruleControls.actionMin.value=false;
    }
  }

  toggleShowCaptainTask(index) {
    if(!this.penaltyRulesForm.controls.rules.at(index).get('showCaptainTask').value) {
      this.penaltyRulesForm.controls.rules.at(index).get('captainTask').setValue(null);
    }
  }
  getActions = async () => {
    try {
      const response = await this.penaltyRewardCampaignService.fetchActions().toPromise();
      this.actionOptions = response['data']['data'];
    } catch (error) {
      this.toasterService.showToaster(
        new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch Actions`,
        })
      );
    }
  }
  getCaptainTasks = async () => {
    try {
      const response = await this.penaltyRewardCampaignService.fetchCaptainTasks().toPromise();
      Object.keys(response['data']).forEach(key => this.captainTask.push(response['data'][key]));
    } catch (error) {
      this.toasterService.showToaster(
        new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch captain tasks`,
        })
      );
    }
  }

  getGalaxyCampaigns = async () => {
    try {
      const response = await this.penaltyRewardCampaignService.fetchGalaxyCampaigns().toPromise();
      Object.keys(response['data']).forEach(key => this.campaignId.push(response['data'][key]));
    } catch (error) {
      this.toasterService.showToaster(
        new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch galaxy campaigns`,
        })
      );
    }
  }

  submit() {
    if (this.organiseData()) {
      if(this.showOldRule) {
        this.rulesChange.emit(this.oldPenaltyRulesForm.value);
      }
      else {
        let data =[];
     this.penaltyRulesForm.controls.rules.controls.map((penaltyRule,index)=>{
      data.push(penaltyRule.getRawValue());
      data[index].communications=[];
      penaltyRule.controls.communications.controls.map(set => {
        let setValue = set.getRawValue();
        data[index].communications.push({
          ...setValue,
          titleLocales: Object.keys(setValue.titleLocales).reduce((acc, key) => {
            if (setValue.titleLocales[key].value) {
              acc[key] = setValue.titleLocales[key].value;
            }
            return acc
          }, {}),
          messageLocales: Object.keys(setValue.messageLocales).reduce((acc, key) => {
            if (setValue.messageLocales[key].value) {
              acc[key] = setValue.messageLocales[key].value;
            }
            return acc
          }, {}),
        });
      })
      })
      this.rulesChange.emit(data);
      }
    }
  }

  goBack() {
    this.back.emit();
  }

  getValue(property) {
    if(this.showOldRule) {
     return this.oldPenaltyRulesForm.get(property).value;
    }
    return (this.penaltyRulesForm.get(property).value);
  }

  organiseData() {
    let isValid = true;
    let negativeRules = [ACTIONS.PENALTY, ACTIONS.DEPRIORITIZE, ACTIONS.SUSPEND, ACTIONS.PUSH_TO_AGENT];
    let positiveRulesCount = 0;
    let negativeRulesCount = 0;
    let isSuspend = false;
    let isDeprioritize = false;
    const overlapChecker = new Map();
    if(this.showOldRule) {
      const rules = this.getValue('oldRules');
      rules.forEach( (rule, _index) => {
        let message = '';
        message = this.oldRuleValidation(rule, message, _index);
        if (message.length) {
          this.toasterService.showToaster( new Toaster({ type: ToasterType.WARNING, message }));
          isValid = false;
          return;
        }

        rule.notificationDisplay = this.notificationMapping[rule.notification] || rule.notification;
        checkRuleCategory(rule.action);
        if(positiveRulesCount > 0 && negativeRulesCount > 0){({ message, isValid } = this.fetchCoflictingRuleError());}
        if(isSuspend && isDeprioritize){({ message, isValid } = this.fetchNegativeRuleError());}
        let currentLimits = {startLimit: rule.startLimit, endLimit : rule.endLimit};
        this.collectRuleLimits(overlapChecker, rule.action, currentLimits);
      });

      const {isOverLapped, overlappedAction } = this.checkOverlap(overlapChecker);
      if(isOverLapped){
        isValid = this.overLapError(overlappedAction);
        return;
      }
      this.oldRules.setValue(rules);
      if (!isValid) {
        return isValid;
      }

      if (!this.isGame()) {
        for (let i = 1; i <= rules.length; i++) {
          let message = '';
          if (rules[i-1].startLimit > rules[i-1].endLimit) { message = `Start Limit is greater than End Limit for rule ${i}`; }
          if (message.length) {
            this.toasterService.showToaster( new Toaster({ type: ToasterType.WARNING, message }));
            isValid = false;
            return;
          }
        }
      }

      return isValid;
    }
    else {
    const rules = this.penaltyRulesForm.controls.rules.controls;
    rules.forEach((rule, _index) => {
      let message = '';
      message = this.newRuleValidation(rule, message, _index);
      if (message.length) {
        this.toasterService.showToaster( new Toaster({ type: ToasterType.WARNING, message }));
        isValid = false;
        return;
      }
      ({ message, isValid } = this.commsValidation(rule, message, _index, isValid));
      checkRuleCategory(rule.value.action);
      if(positiveRulesCount > 0 && negativeRulesCount > 0){({ message, isValid } = this.fetchCoflictingRuleError());}
      if(isSuspend && isDeprioritize){({ message, isValid } = this.fetchNegativeRuleError());}
      let currentLimits = {startLimit: rule.value.startLimit, endLimit : rule.value.endLimit};
      this.collectRuleLimits(overlapChecker, rule.value.action, currentLimits);
    });
    const {isOverLapped, overlappedAction } = this.checkOverlap(overlapChecker);
    if(isOverLapped){
      isValid = this.overLapError(overlappedAction);
      return;
    }
    if (!isValid) {
      return isValid;
    }

    if (!this.isGame()) {
      for (let i = 1; i <= rules.length; i++) {
        let message = '';
        if (rules[i - 1].value.startLimit > rules[i - 1].value.endLimit) { message = `Start Limit is greater than End Limit for rule ${i}`; }
        if (message.length) {
          this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
          isValid = false;
          return;
        }
      }
    }

    return isValid;
  }

    function checkRuleCategory(action: any) {
      if (action == ACTIONS.DEPRIORITIZE) {
        isDeprioritize = true;
      }
      if (action == "suspend") {
        isSuspend = true;
      }
      if (action === "reward") {
        positiveRulesCount++;
      };
      if (negativeRules.find(negativeAction => action === negativeAction)) {
        negativeRulesCount++;
      };
    }
  }

  private fetchCoflictingRuleError() {
    let message = "You should not club positive and negative rules in the same campaign";
    this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
    return { message, isValid : false };
  }

  private fetchNegativeRuleError() {
    let message = "You should not club both suspend and deprioritize rules in the same campaign";
    this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
    return { message, isValid : false};
  }

  private collectRuleLimits(overlapChecker: Map<any, any>, action: any, currentLimits: { startLimit: any; endLimit: any; }) {
    if (!overlapChecker.get(action)) {
      overlapChecker.set(action, [currentLimits]);
    }
    else {
      let totalLimits = overlapChecker.get(action);
      totalLimits.push(currentLimits);
      overlapChecker.set(action, totalLimits);
    }
  }

  private overLapError(overlappedAction: string) {
    let message = "You should not have overlapping rules for the " + overlappedAction + " action in the campaign";
    this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
    return false;
  }

  private commsValidation(rule: any, message: string, _index: any, isValid: boolean) {
    if (this.state === this.sharedService.STATE.VIEW) { return { message, isValid }; }
    rule.controls.communications.controls.forEach((control, index) => {
      if(control.value.type === "IVR"){
        return;
      }

      if (control.value.type === "WHATSAPP") {
        if (!control.value.templateId) {
          message = `Please Select a Whatsapp Template for Rule #${_index + 1} Set #${index + 1}`;
          isValid = false
          this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
        }
        return { message, isValid };
      }

      if (!control.value.titleLocales[this.defaultLocale.code].value) {
        message = `Please Enter Title in ${this.defaultLocale.label} for Rule #${_index + 1} Set #${index + 1}`;
      }

      if (!control.value.messageLocales[this.defaultLocale.code].value) {
        message = `Please Enter Message in ${this.defaultLocale.label} for Rule #${_index + 1} Set #${index + 1}`;
      }

      for (const locale in control.value.titleLocales) {
        if (control.value.titleLocales[locale].value) {
          if (!control.value.messageLocales[locale].value) {
            message = `Please Enter Message in ${this.supportedLocales.find(l => l.code == locale).label} for Rule #${_index + 1} Set #${index + 1}`;
          } else if (control.value.messageLocales[locale].value && control.value.messageLocales[locale].length > 135) {
            message = `Message length should be less than or equal to 135 for  Rule #${_index + 1} Set #${index + 1}.`;
          }
        }

        if (control.value.messageLocales[locale].value && !control.value.titleLocales[locale].value) {
          message = `Please Enter Title in ${this.supportedLocales.find(l => l.code == locale).label} for Rule #${_index + 1} Set #${index + 1}`;
        }
      }
      if (!control.value.type) { message = `Please Select Notification Type for  Rule #${_index + 1} Set #${index + 1}`; }
      if (message.length) {
        this.toasterService.showToaster(new Toaster({ type: ToasterType.WARNING, message }));
        isValid = false;
        return;
      }
      rule.value.notificationDisplay = this.notificationMapping[control.value.notification] || control.value.notification;
    });
    return { message, isValid };
  }

  private checkOverlap(overlapChecker){
    let isOverLapped = false;
    let overlappedAction = '';
    overlapChecker.forEach(function(value, key) {
      value.sort(function(i1, i2){
        return i1.startLimit - i2.startLimit;
      });
      for(let i = 1; i < value.length; i++){
        if(value[i - 1].endLimit >= value[i].startLimit){
          isOverLapped =  true;
          overlappedAction = key;
          break;
        }
      }
    })
    return {isOverLapped, overlappedAction};
  }

  private newRuleValidation(rule: any, message: string, _index: any) {
    if (this.state === this.sharedService.STATE.VIEW) { return message; }
    if (!rule.value.action) { message = `Please Select an Action for Rule #${_index + 1}`; }
    else if (rule.value.action === ACTIONS.SUSPEND && !rule.value.activateAfterHours) { message = `Please Enter suspension hrs for Rule #${_index + 1}`; }
    else if (rule.value.action === ACTIONS.DEPRIORITIZE && !rule.value.prioritizeAfterMins) { message = `Please Enter prioritize after mins for Rule #${_index + 1}`; }
    else if (rule.value.action == ACTIONS.ADJUSTMENT && !rule.value.amount) { message = `Please Enter Amount for Rule #${_index + 1}`; }
    else if (!(typeof rule.value.startLimit === 'number' && rule.value.startLimit >= 0.00001)) { message = `Please Enter Start Limit for Rule #${_index + 1}`; }
    else if (rule.value.endLimit && rule.value.endLimit < 1) { message = `Please Check End Limit for Rule #${_index + 1}`; }
    else if (rule.value.endLimit && rule.value.endLimit < rule.value.startLimit) { message = `Please Ensure Start Limit is less than End Limit for Rule #${_index + 1}`; }
    else if (rule.value.blockRedeem && typeof rule.value.amount !== 'number') { message = `Please fill the Block redeem amount for Rule #${_index + 1}`; }
    else if (rule.value.action === ACTIONS.REWARD) {
      if (!rule.value.amount) { message = `Please Enter Amount for Rule #${_index + 1}`; }
      else if (rule.value.amount < 1) { message = `Please Enter Valid Amount for Rule #${_index + 1}`; }
    }
    else if (rule.value.action === ACTIONS.PENALTY) {
      if (rule.value.amount < 0) { message = `Please Enter Valid Amount for Rule #${_index + 1}`; }
    }
    else if (rule.value.action === ACTIONS.SUSPEND && rule.value.showCaptainTask) {
      if(!rule.value.captainTask || !rule.value.captainTask.length) {
        message = `Please select captain task for #${_index + 1}`;
      }
    }
    else if (rule.value.action === ACTIONS.PUSH_TO_AGENT && rule.value.communications.some(communication => communication.type === "IVR")) {
      if(!rule.value.campaignId || !rule.value.campaignId.length) {
        message = `Please select galaxy campaign for Rule #${_index + 1}`;
      }
    }
    if (rule.value.action === ACTIONS.PENALTY || rule.value.action === ACTIONS.REWARD || rule.value.action === ACTIONS.ADJUSTMENT){
      if (rule.value.useActionAmount && ((rule.value.actionMin && rule.value.actionMax) || (!rule.value.actionMin && !rule.value.actionMax))) {
         message = `Please select either min or max for use of action amount for Rule #${_index + 1}`;
      }
    }
    return message;
  }

  private oldRuleValidation(rule: any, message: string, _index: any) {
    if (!rule.action) { message = `Please Select an Action for Rule #${_index + 1}`; }
    else if (rule.action === ACTIONS.SUSPEND && !rule.activateAfterHours) { message = `Please Enter suspension hrs for Rule #${_index + 1}`; }
    else if (rule.action === ACTIONS.DEPRIORITIZE && !rule.prioritizeAfterMins) { message = `Please Enter prioritize after mins for Rule #${_index + 1}`; }
    else if (rule.action == ACTIONS.ADJUSTMENT && !rule.amount) { message = `Please Enter Amount for Rule #${_index + 1}`; }
    else if (!rule.message) { message = `Please Enter Message for Rule #${_index + 1}`; }
    else if (rule.message.length > 135) { message = `Message length should be less than or equal to 135 for Rule #${_index + 1}.`; }
    else if (!(typeof rule.startLimit === 'number' && rule.startLimit >= 0.00001)) { message = `Please Enter Start Limit for Rule #${_index + 1}`; }
    else if (rule.endLimit && rule.endLimit < 1) { message = `Please Check End Limit for Rule #${_index + 1}`; }
    else if (rule.endLimit && rule.endLimit < rule.startLimit) { message = `Please Ensure Start Limit is less than End Limit for Rule #${_index + 1}`; }
    else if (rule.blockRedeem && typeof rule.amount !== 'number') { message = `Please fill the Block redeem amount for Rule #${_index + 1}`; }
    else if (!rule.notification) { message = `Please Select Notification Type for Rule #${_index + 1}`; }
    else if (rule.action === ACTIONS.REWARD) {
      if (!rule.amount) { message = `Please Enter Amount for Rule #${_index + 1}`; }
      else if (rule.amount < 1) { message = `Please Enter Valid Amount for Rule #${_index + 1}`; }
    }
    else if (rule.value.action === ACTIONS.PENALTY) {
      if (rule.value.amount < 0) { message = `Please Enter Valid Amount for Rule #${_index + 1}`; }
    }
    return message;
  }

  notificationConfirm(event, formControl) {
    if (event && event === 'OTT') {
      const options = {
        title: 'Are you sure Over The Top communication?',
        message: 'By using Over The Top communication, the users screen will get covered from the current screen. Tip: Advised to use in important communication.',
        cancelText: 'Cancel',
        confirmText: 'Yes, Proceed'
      };

      this.dialogService.open(options);

      this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          return;
        } else {
          //this.penaltyRulesForm.controls.rules['controls'][index].controls.notification.patchValue("");
        }
      });
    }
    if (event && event != 'WHATSAPP') {
      formControl.controls.templateId.patchValue(null);
    }
  }

  isGame() {
    return this.campaignType == "captainGame"
  }

  isPenaltyAndReward() {
    return this.campaignType == "penaltyAndReward"
  }
  isStreakGame() {
    return this.gameTriggerEvent == "streak"
  }

  showBlockRedeem(currentRule) {
    const currentAction = currentRule.get('action').value;
    if (!currentAction) { return false; }
    const showCheckBox = this.blockRedeemEligible && currentAction === 'suspend';
    if (this.state !== 'view' && this.state !== 'edit' && this.state !== 'duplicate' && !showCheckBox && currentAction === 'suspend') {
      // currentRule.controls['amount'].setValue(null);
      // currentRule.controls['blockRedeem'].setValue(false);
    }
    return showCheckBox;
  }

  onBlockRedeemChange(currentRule) {
    const currentBlockRedeem = currentRule.get('blockRedeem').value;
    if(!currentBlockRedeem){
      currentRule.controls['amount'].setValue(null);
    }
  }

  showBlockRedeemAmount(currentRule) {
    return currentRule.get('blockRedeem').value;
  }
  addSet(i) {
  this.rules.controls[i].controls.communications.controls.push(this.getEmptySet());
  }
}
