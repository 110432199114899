import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { IncentiveService } from '../create-incentive/create-incentive.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from '../loader.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared/shared.service';
import Utils from '.././utils/utils';
import { defaultStatusMessage } from ".././utils/utils";
var ADHOC_TYPE = 'Adhoc';
var OTHERS = 'Others';
var ViewIncentiveComponent = /** @class */ (function () {
    function ViewIncentiveComponent(incentiveService, route, loaderService, router, datePipe, sharedService) {
        this.incentiveService = incentiveService;
        this.route = route;
        this.loaderService = loaderService;
        this.router = router;
        this.datePipe = datePipe;
        this.sharedService = sharedService;
        this.header = 'View Incentive';
        this.incentiveData = {};
        this.userSelectorCount = {};
        this.isCreateOrDuplicateFlow = true;
        this.goal = [];
        this.state = this.sharedService.STATE.VIEW;
        this.type = "view";
        this.condition = '&&';
        this.userSelectorStatus = tslib_1.__assign({ status: "UNKNOWN" }, defaultStatusMessage);
        this.weeklyIncentiveData = [
            {
                'days': 'All',
                'startDay': { day: 'Monday', index: 1 },
                'endDay': { day: '', index: null }
            }
        ];
        this.dailyIncentiveData = [
            {
                'days': 'All',
            }
        ];
        this.subSetCount = [];
        this.orderChecked = false;
        this.distanceChecked = false;
        this.cancellationChecked = false;
        this.cancellationLimitScope = "";
        this.cancellationGlobalLimit = 0;
        this.setsCancellationLimit = [0];
        this.maxOrderDistanceChecked = false;
        this.maxOrderDistanceGlobal = 0;
        this.qualityChecked = false;
        this.active = true;
        this.qualitativeMetrics = ['APR', 'DPR'];
        this.dailyIncentiveTimeBuckets = environment.incentiveTimeBuckets;
        this.overlappingTimeBuckets = {};
        this.formLoaded = false;
        this.back = new EventEmitter();
    }
    ViewIncentiveComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaderService.openLoading();
        var incentiveId = this.route.snapshot.paramMap.get('incentiveId');
        this.incentiveService.getIncentive(incentiveId)
            .subscribe(function (res) {
            _this.incentiveData = res.data;
            _this.isHHIncentive = _this.incentiveData.tags ? _this.incentiveData.tags.includes("HH") : false;
            _this.active = _this.incentiveData.active;
            _this.findTypeOfIncentive(_this.incentiveData);
            _this.passGoalsInfo();
            _this.formLoaded = true;
            _this.loaderService.closeLoading();
            if (!_this.isHHIncentive) {
                _this.sharedService.getUserSelectorDetails(_this.incentiveData.ruleId)
                    .subscribe(function (response) {
                    _this.userSelectorCount = { count: response.userCount };
                }, function (err) { return console.log(err); });
            }
        }, function (err) { return console.log(err); });
    };
    ViewIncentiveComponent.prototype.goBack = function () {
        this.router.navigate(['/incentives']);
    };
    ViewIncentiveComponent.prototype.findTypeOfIncentive = function (defaultValue) {
        if (defaultValue && (defaultValue.incentiveType === 'Daily'
            || defaultValue.incentiveType === 'Adhoc')) {
            this.getDailyIncentiveData(this.incentiveData);
        }
        else {
            this.getWeeklyIncentiveData(this.incentiveData);
        }
    };
    ViewIncentiveComponent.prototype.getWeeklyIncentiveData = function (defaultValue) {
        this.condition = defaultValue.selectedCondition;
        var incentives = [];
        var formattedWeeklyincentiveData;
        Object.keys(defaultValue.goals).forEach(function (goalInDays, index) {
            formattedWeeklyincentiveData = {};
            formattedWeeklyincentiveData['days'] = goalInDays;
            var weekDays = goalInDays.split(',');
            formattedWeeklyincentiveData['startDay'] = { day: weekDays[0].trim(), index: Utils.findIndexOfDay(weekDays[0]) };
            formattedWeeklyincentiveData['endDay'] = {
                day: weekDays[weekDays.length - 1].trim(),
                index: Utils.findIndexOfDay(weekDays[weekDays.length - 1])
            };
            var weekWiseIncentive = defaultValue.goals[goalInDays];
            weekWiseIncentive.map(function (incentive) {
                incentive.timeSlot = incentive.timeSlot.map(function (slot) {
                    return {
                        fromTime: Utils.timeToTimeStamp(slot.fromTime),
                        toTime: Utils.timeToTimeStamp(slot.toTime),
                    };
                });
            });
            var incentiveStartDate = new Date(defaultValue.startDate).getTime();
            var currentDate = new Date().getTime();
            var t = Math.round(currentDate - incentiveStartDate) / (1000 * 60 * 60 * 24);
            if ((t) >= 7) {
                formattedWeeklyincentiveData['disabled'] = true;
            }
            else if (t <= 6 && t >= 0) {
                if (formattedWeeklyincentiveData['startDay'].index <= new Date().getUTCDay()) {
                    formattedWeeklyincentiveData['disabled'] = true;
                }
            }
            weekWiseIncentive.forEach(function (incentive, index) {
                var weeklyIndex = incentives.length;
                formattedWeeklyincentiveData[weeklyIndex] = incentive;
                incentives.push(formattedWeeklyincentiveData);
            });
        });
        this.weeklyIncentiveData = incentives;
    };
    ViewIncentiveComponent.prototype.getDailyIncentiveData = function (defaultValue) {
        var _this = this;
        var incentives = [];
        this.condition = defaultValue.selectedCondition;
        ;
        var formattedDailyincentiveData = {
            'days': 'All',
        };
        Object.keys(defaultValue.goals).forEach(function (goalInDays, index) {
            var dayWiseIncentive = defaultValue.goals[goalInDays];
            dayWiseIncentive.map(function (incentive) {
                incentive.timeSlot = incentive.timeSlot.map(function (slot) {
                    return {
                        fromTime: Utils.timeToTimeStamp(slot.fromTime),
                        toTime: Utils.timeToTimeStamp(slot.toTime),
                    };
                });
            });
            dayWiseIncentive.forEach(function (incentive, index) {
                formattedDailyincentiveData[index + 1] = incentive;
            });
        });
        defaultValue.selectedVariable.forEach(function (variable) {
            switch (variable) {
                case 'order': {
                    _this.orderChecked = true;
                    break;
                }
                case 'distance': {
                    _this.distanceChecked = true;
                    break;
                }
                case 'quality': {
                    _this.qualityChecked = true;
                    break;
                }
                case 'cancellation': {
                    _this.cancellationChecked = true;
                }
                case 'maxOrderDistance': {
                    _this.maxOrderDistanceChecked = true;
                }
            }
            var goalsKey = Object.keys(defaultValue.goals)[0];
            _this.setsCancellationLimit = [];
            _this.cancellationGlobalLimit = defaultValue.cancellationLimit;
            _this.maxOrderDistanceGlobal = defaultValue.maxOrderDistance;
            for (var _i = 0, _a = defaultValue.goals[goalsKey]; _i < _a.length; _i++) {
                var set = _a[_i];
                _this.setsCancellationLimit.push(set.cancellationLimit);
            }
            if (_this.cancellationChecked) {
                if (defaultValue.cancellationLimit != 0) {
                    _this.cancellationLimitScope = "global";
                }
                else {
                    _this.cancellationLimitScope = "set";
                }
            }
        });
        this.dailyIncentiveData = [formattedDailyincentiveData];
    };
    ViewIncentiveComponent.prototype.passGoalsInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var goalsInfo, details;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        goalsInfo = {};
                        if (this.incentiveData.incentiveType === 'Daily'
                            || this.incentiveData.incentiveType === 'Adhoc') {
                            if (this.incentiveData.incentiveType === 'Daily') {
                                goalsInfo['cancellationLimitScope'] = this.cancellationLimitScope;
                                goalsInfo['cancellationLimit'] = this.cancellationGlobalLimit;
                                goalsInfo['cancellationChecked'] = this.cancellationChecked;
                                goalsInfo['setsCancellationLimit'] = this.setsCancellationLimit;
                            }
                            if (this.incentiveData.incentiveType === 'Adhoc') {
                                goalsInfo['maxOrderDistanceGlobal'] = this.maxOrderDistanceGlobal;
                                goalsInfo['maxOrderDistanceChecked'] = this.maxOrderDistanceChecked;
                            }
                            goalsInfo['dailyIncentiveData'] = this.dailyIncentiveData;
                        }
                        else if (this.incentiveData.incentiveType === 'Weekly Fixed') {
                            goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
                        }
                        else {
                            goalsInfo['weeklyIncentiveData'] = this.weeklyIncentiveData;
                        }
                        goalsInfo['selectedVariable'] = this.incentiveData.selectedVariable;
                        goalsInfo['selectedCondition'] = this.incentiveData.selectedCondition;
                        this.incentiveData['goalsInfo'] = goalsInfo;
                        if (!this.incentiveData) return [3 /*break*/, 2];
                        this.header = 'View - ' + this.incentiveData.incentiveType + ' incentive - ' +
                            this.datePipe.transform(this.incentiveData.startDate, 'd MMMM yy')
                            + ' to ' + this.datePipe.transform(this.incentiveData.endDate, 'd MMMM yy');
                        return [4 /*yield*/, Utils.fetchUserSelectorStatus(this.type, this.userSelectorId, this.incentiveData, this.userSelectorStatus, this.sharedService)];
                    case 1:
                        details = _a.sent();
                        if (details !== undefined) {
                            this.userSelectorStatus = details.userSelectorStatus;
                            this.userSelectorId = details.userSelectorId;
                        }
                        _a.label = 2;
                    case 2:
                        if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.dailyIncentiveData) {
                            this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.dailyIncentiveData);
                        }
                        if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.weeklyIncentiveData) {
                            this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.weeklyIncentiveData);
                        }
                        this.goal = Utils.organizeData(this.type, this.incentiveData, this.dailyData, this.datePipe, this.variables);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ViewIncentiveComponent;
}());
export { ViewIncentiveComponent };
