import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MONTHS } from '../shared/constants';
@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.css']
})
export class ActivityLogsComponent implements OnInit, OnChanges {

  @Input() activityLog;
  @Output() closedPanel: EventEmitter<any> = new EventEmitter();
  constructor() { }

  logs;

  ngOnInit() {
    this.logs = this.modifyLogs(this.activityLog);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logs = this.modifyLogs(this.activityLog);
  }

  modifyLogs(logs) {
    const extractedLogs = [];
    const finalLogs = {};
    for (const log in logs) {
      if (logs[log] && logs[log]['insertionDate']) {
        finalLogs[logs[log]['insertionDate']] = finalLogs[logs[log]['insertionDate']] || [];
        const data = logs[log]['metadata'];
        if (data && data.changedKeys && data.changedKeys.length) {
          finalLogs[logs[log]['insertionDate']].push({
            key: data.changedKeys,
            timestamp: this.modifyTimestamp(logs[log]['timeStamp']),
            actorEmail: data.user && data.user.email || 'Unknown'
          });
        }
      }
    }
    for (const log in finalLogs) {
      if (finalLogs[log]) {
        extractedLogs.push({
          date: log,
          logs: finalLogs[log]
        });
      }
    }
    return extractedLogs;
  }

  modifyDate(changeDate) {
    const date = new Date(changeDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${MONTHS[monthIndex]} ${year}`;
  }

  modifyTimestamp(timestamp) {
    return new Date(timestamp).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
  }

  closedActivityLogs() {
    this.closedPanel.emit();
  }

}
