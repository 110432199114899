import * as tslib_1 from "tslib";
import { SigninService } from './signin.service';
import { Router } from "@angular/router";
import { AfterViewInit, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';
var SigninComponent = /** @class */ (function () {
    function SigninComponent(router, signInService, ngZone) {
        this.router = router;
        this.signInService = signInService;
        this.ngZone = ngZone;
    }
    SigninComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        google.accounts.id.initialize({
            client_id: environment.googleClientId,
            callback: function (response) { return _this.handleGoogleSignIn(response); }
        });
        google.accounts.id.renderButton(document.getElementById("buttonDiv"), { size: "large", shape: "rectangular", theme: "outline", text: "continue_with", width: '300' });
    };
    SigninComponent.prototype.handleGoogleSignIn = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userInfo, tokenResponse;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userInfo = HelperService.deocodeJwtPayload(response.credential);
                        return [4 /*yield*/, this.signInService.login(userInfo).toPromise()];
                    case 1:
                        tokenResponse = _a.sent();
                        localStorage.setItem('token', tokenResponse.token);
                        localStorage.setItem('permission', tokenResponse.permissions);
                        this.ngZone.run(function () {
                            _this.router.navigate(['/incentives']);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return SigninComponent;
}());
export { SigninComponent };
