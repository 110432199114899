import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import * as Papa from "papaparse";
import { FileUpload } from "primeng/fileupload";
import { ToasterService } from "src/app/toaster.service";
import { environment } from "src/environments/environment";
import { IAllocatorService } from "../i-allocator.service";
import { LoaderService } from "src/app/loader.service";
import { AlertDialogOptions, AlertDialogService } from "src/app/shared/alert-dialog/alert-dialog.service";
import * as moment from "moment";
import { GlobalConstants } from "src/app/global-constants";
import Utils from "src/app/utils/utils";


@Component({
    selector: 'i-allocator-construct-upload',
    templateUrl: './construct-upload.component.html',
    styleUrls: ['./../i-allocator-incentive.component.css'],
})
export class IAllocatorConstructUploadComponent implements OnInit {

    ngOnInit(): void {
    }

    constructor(
        public toasterService: ToasterService,
        private iAllocatorService: IAllocatorService,
        private loaderService: LoaderService,
        private alertDialogService: AlertDialogService
    ) { }

    public config = _.sortBy(environment.iAllocatorConfig, 'cityName');
    @ViewChild('constructUpload') fileUploadRef: FileUpload;
    fileDetails: any;
    public minDate: Date = new Date();
    public maxDate: Date;

    iAllocatorConstructFormGroup = new FormGroup({
        selectedCity: new FormControl(null, Validators.required),
        selectedService: new FormControl(null, Validators.required),
        selectedIncentive: new FormControl(null, Validators.required),
        fromDate: new FormControl(null, Validators.required),
        toDate: new FormControl(null, Validators.required),
    })

    getValues(name: string) {
        return this.iAllocatorConstructFormGroup.get(name).value;
    }

    getServices() {
        const config = this.getValues("selectedCity") || { services: [] };
        return config.services;
    }

    getIncentiveTypes() {
        const config = this.getValues("selectedCity") || { incentiveTypes: [] };
        return config.incentiveTypes;
    }

    citySelected() {
        this.iAllocatorConstructFormGroup.patchValue({
            selectedService: null,
            selectedIncentive: null,
        });
    }

    canConstructBeUploaded() {
        return this.getValues("selectedCity") 
            && this.getValues("selectedService") 
            && this.getValues("selectedIncentive")
            && this.getValues('fromDate')
            && this.getValues('toDate')
    }

    getIncentiveType(incentiveType) {
        return GlobalConstants.IAllocatorIncentiveTypes[incentiveType];
    }

    changeInDate() {
        if (this.getValues('fromDate')) {
            this.minDate = this.getValues('fromDate');
        }
        if (this.getValues('toDate')) {
            this.maxDate = this.getValues('toDate');
        }
    }

    resetFile() {
        this.fileDetails = null;
    };

    handleFile(event: { files: File[]; }) {
        const file = event.files[0];
        this.resetFile();
        Papa.parse(file, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            transformHeader: (header) => header.trim(),
            transform: (value) => value.trim(),
            complete: (results) => {
                this.fileDetails = { records: results.data, name: file.name };
            }
        });
    }

    async submitConstructs() {
        this.loaderService.openLoading();
        this.iAllocatorService.uploadConstructs({
            city_name: this.getValues('selectedCity').cityName,
            city_id: this.getValues('selectedCity').cityId,
            service_name: this.getValues('selectedService'),
            incentive_type: this.getIncentiveType(this.getValues('selectedIncentive').toUpperCase()),
            segment_details: this.fileDetails.records,
            start_date: this.formatDate(this.getValues('fromDate')),
            end_date: this.formatDate(this.getValues('toDate'))
        })
        .then(res => this.showAlertBox({
            title: 'Success',
            message: 'File successfully uploaded.',
        }))
        .catch(err => {
            console.log(err);
            
            if (err.error.code == "CSV_VALIDATION_FAILED") {
                this.showAlertBox({
                    title: 'Error',
                    details: this.getCSVErrorDetails(err.error.data)
                })
            } else {
                this.showAlertBox({
                    title: 'Error',
                    message: JSON.stringify(err.error.data) || err.error.message
                })
            }
        })
        .finally(() => {
            this.loaderService.closeLoading();
        }) 
    }

    showAlertBox(options: AlertDialogOptions, cb = () => { }) {
        this.alertDialogService.open(options);
        this.alertDialogService.confirmed()
            .subscribe(confirmed => confirmed && cb());
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    };

    getCSVErrorDetails(data: any) {
        let value = data.map(error => {
            const jsonPath = error.instancePath
            let errData = jsonPath.split("/")
            let column = errData[3]
            let row = Number(errData[2])

            if (Number.isNaN(row) || column == undefined) {
                return `Validation failed for ${errData[1]} on ${error.keyword} : ${error.message}`
            }

            return `Validation failed for line ${row + 2} in ${column} column on ${error.keyword} : ${error.message}`
        })
        return [{
            value,
            message: "There are some validation errors, please fix them and try again"
        }]
    }

    downloadSampleFile() {
        Utils.downloadCSVFile({
            fields: environment.iAllocatorConstructsCSVHeaders,
            data: null,
            fileName: `iallocator_construct_sample.csv`,
        });
    }
}
