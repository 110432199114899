import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material';
import * as _ from 'lodash';
import { SharedService } from 'src/app/shared/shared.service';
import Utils from 'src/app/utils/utils';
import {defaultStatusMessage, userSelectorStatusMessages} from "../../utils/utils";

const ADHOC_TYPE = 'Adhoc';
const OTHERS = 'Others';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  providers: [DatePipe],
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnChanges, OnInit {
  constructor(private datePipe: DatePipe,
    private sharedService: SharedService) { }

  @Output() back: EventEmitter<any> = new EventEmitter();
  @Input() incentiveData: any;
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Output() stepperIndex: EventEmitter<any> = new EventEmitter();
  public previewData: any;
  public header: any = 'Preview';
  public dailyData: any;
  public variables: any;
  public condition: any;
  public order: boolean;
  public distance: boolean;
  public type = "preview";
  public goal = [];
  public incentiveTypeMap = [];
  public termsPreview = [];
  public userSelectorStatus = {
    status: "UNKNOWN",
    ...defaultStatusMessage
  };
  public selectorStatusUpdateTimer: number;
  public isCreateOrDuplicateFlow = true;
  private userSelectorId: string;

  @Input() NOT_EDITABLE = {
  };
  @Input() state: string;
  @Input() incentiveId: string;
  @Input() defaultValue = {
    active: true
  };
  public active = true;
  ngOnChanges(changes: SimpleChanges) {
    this.incentiveData = changes.incentiveData.currentValue;
    this.incentiveTypeMap['Daily'] = "Daily";
    this.incentiveTypeMap['Weekly Fixed'] = "Weekly";
    this.incentiveTypeMap['Weekly Selectable'] = "Weekly";
    this.incentiveTypeMap['Adhoc'] = "Daily";

    if (this.incentiveData.basicInfo) {
      this.header = 'Preview - ' + this.incentiveData.basicInfo.incentiveType + ' incentive - ' +
        this.datePipe.transform(this.incentiveData.basicInfo.startDate, 'd MMMM yy')
        + ' to ' + this.datePipe.transform(this.incentiveData.basicInfo.endDate, 'd MMMM yy');

      if (!this.incentiveData.basicInfo.isHHIncentive){
        this.updateUserSelectorStatus();
      }
    }

    if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.dailyIncentiveData) {
      this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.dailyIncentiveData);
    }
    if (this.incentiveData.goalsInfo && this.incentiveData.goalsInfo.weeklyIncentiveData) {
      this.dailyData = _.cloneDeep(this.incentiveData.goalsInfo.weeklyIncentiveData);
    }

    this.goal =  Utils.organizeData(this.type, this.incentiveData,this.dailyData, this.datePipe,
     this.variables);

    if (this.incentiveData.communicationInfo && this.incentiveData.communicationInfo.termsAndCondition) {
      this.termsPreview = this.incentiveData.communicationInfo.termsAndCondition;
    }
  }

  ngOnInit() {
    this.active = this.defaultValue.hasOwnProperty('active') ? this.defaultValue.active : true;
    this.isCreateOrDuplicateFlow = this.state == this.sharedService.STATE.DUPLICATE ||
      this.state == this.sharedService.STATE.CREATE;
  }

  goBack() {
    this.back.emit();
  }

  submit() {
    if (this.userSelectorStatus.type == 'Error' && this.isCreateOrDuplicateFlow) {
      return;
    }
    const data = {
      submit: true,
      active: this.active,
    };
    this.saveData.emit(data);
  }

  toggleActive() {
    this.active = !this.active;
  }

  moveStepper(event) {
    this.stepperIndex.emit(event);
  }

  async updateUserSelectorStatus() {
    if (this.selectorStatusUpdateTimer) {
      clearTimeout(this.selectorStatusUpdateTimer);
    }
    this.selectorStatusUpdateTimer = null;

    const details = await Utils.fetchUserSelectorStatus(
      this.type,this.userSelectorId,this.incentiveData,
      this.userSelectorStatus, this.sharedService
    );
    this.userSelectorStatus = details.userSelectorStatus;
    this.userSelectorId = details.userSelectorId;

    if (this.isCreateOrDuplicateFlow && !Utils.isFinalUserSelectorState(this.userSelectorStatus.status) && !this.incentiveData.basicInfo.isHHIncentive) {
      this.selectorStatusUpdateTimer = setTimeout(() => {
        this.updateUserSelectorStatus();
      }, 30 * 1000) as any as number;
      return;
    }
  }

}
