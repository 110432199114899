import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html'
})
export class SearchableDropdownComponent implements OnInit {

  @Input() dropdownData: any;
  @Output() selectedDropDowns: EventEmitter<any> = new EventEmitter();

  public selectedValues: [];
  constructor() { }

  ngOnInit() {
    // this.dropdownData = {
    //     type : 'searchable-dropdown',
    //     title : 'City',
    //     values : ['Bangalore', 'Hyderabad', 'Mumbai', 'Ahmedabad'],
    //     default : '',
    //     key : 'city-dropdown'
    //   };
  }

  chosenOptions() {
    const emitSelections = {
      [this.dropdownData.key]: this.selectedValues
    };
    this.selectedDropDowns.emit(emitSelections);
  }
}
