import * as tslib_1 from "tslib";
import { isObject, isArray } from 'util';
var TableAction = /** @class */ (function () {
    function TableAction(action) {
        Object.assign(this, action);
    }
    return TableAction;
}());
export { TableAction };
var Button = /** @class */ (function () {
    function Button(data) {
        Object.assign(this, data);
    }
    return Button;
}());
export { Button };
export var TableDataTypes;
(function (TableDataTypes) {
    TableDataTypes[TableDataTypes["DATA"] = 0] = "DATA";
    TableDataTypes[TableDataTypes["ACTION"] = 1] = "ACTION";
    TableDataTypes[TableDataTypes["BUTTON"] = 2] = "BUTTON";
    TableDataTypes[TableDataTypes["STATUS"] = 3] = "STATUS";
})(TableDataTypes || (TableDataTypes = {}));
var TableActions = /** @class */ (function () {
    function TableActions(actions) {
        this.actions = actions;
    }
    return TableActions;
}());
export { TableActions };
var Pagination = /** @class */ (function () {
    function Pagination(pageNo, perPage, count, pageSizeOptions) {
        if (pageNo === void 0) { pageNo = 1; }
        if (perPage === void 0) { perPage = 10; }
        if (count === void 0) { count = 10; }
        if (pageSizeOptions === void 0) { pageSizeOptions = [
            5, 10, 15, 20
        ]; }
        this.pageNo = pageNo;
        this.perPage = perPage;
        this.count = count;
        this.pageSizeOptions = pageSizeOptions;
    }
    Pagination.prototype.setPageNoToZero = function () {
        this.pageNo = 0;
    };
    return Pagination;
}());
export { Pagination };
var TableData = /** @class */ (function () {
    function TableData(data) {
        Object.assign(this, data);
    }
    return TableData;
}());
export { TableData };
var Table = /** @class */ (function () {
    function Table(data) {
        this.pagination = new Pagination();
        this.onSortingChange = function () { };
        this._data = [];
        this.headers = {};
        this.realData = [];
        Object.assign(this, data);
    }
    Object.defineProperty(Table.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (rows) {
            this.realData = rows;
            this._data = rows
                .map(function (row) {
                return Object.entries(row)
                    .reduce(function (row, _a) {
                    var key = _a[0], value = _a[1];
                    var _b;
                    var dataValue = value;
                    if (isArray(value)) {
                        dataValue = value.join(', ');
                    }
                    if (!isObject(dataValue)) {
                        dataValue = new TableData({
                            data: value,
                            type: TableDataTypes.DATA
                        });
                    }
                    return tslib_1.__assign({}, row, (_b = {}, _b[key] = dataValue, _b));
                }, {});
            });
        },
        enumerable: true,
        configurable: true
    });
    return Table;
}());
export { Table };
