<div *ngIf="showOldRule" class="secondary-container shadow-all">
  <label class="step-header text-capitalize">{{state}} Rules</label>
  <div class="divider"></div>
  <div style="padding: 50px 20px;">
    <form [formGroup]="oldPenaltyRulesForm" class="padding-child-row-td-10">
      <div formArrayName="oldRules">
        <div class="row" *ngFor="let pr of oldPenaltyRulesForm.controls.oldRules['controls']; let i=index;"
          [formGroupName]="i">
          <div class="col-2">
            <span>
              <label class="heading" for="action">Action&nbsp;*</label>
              <ng-select [formControl]="pr.get('action')">
                <ng-option *ngFor="let action of actionOptions" [value]="action">{{action}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div class="col-2">
            <span>
              <label class="heading" for="message">Message&nbsp;*</label>
              <textarea class="text-box" style="height: 50px;" rows="3"
                [formControl]="pr.get('message')">hvlhblkhjbkjbkjbkjbjk</textarea>
              <label>Max. limit is 135 characters</label>
            </span>
          </div>
          <div class="width-190">
            <span>
              <label class="heading" for="notification">Notification&nbsp;type&nbsp;*</label>
              <ng-select [formControl]="pr.get('notification')" placeholder="Select Notification Type"
                (change)="notificationConfirm($event, i)">
                <ng-option *ngFor="let notification of notificationsData" [value]="notification.notifType">
                  {{notification.displayText}}</ng-option>
              </ng-select>
            </span>
          </div>
          <div class="col margin-top-neg-10">
            <div class="row">
              <div class="col-md-3" *ngIf="oldRules['controls'][i-1]">
                <span>
                  <label class="heading" [for]="'startLimit-'+i">Start&nbsp;Limit&nbsp;*</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('startLimit')" pInputText
                    [min]="oldRules['controls'][i-1]['value']['endLimit']+1">
                </span>
              </div>
              <div class="col-md-3" *ngIf="!(oldRules['controls'][i-1]) && !isStreakGame()">
                <span>
                  <label class="heading" [for]="'startLimit-'+i">Start&nbsp;Limit&nbsp;*</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('startLimit')" pInputText
                    [min]="0">
                </span>
              </div>
              <div class="col-md-3" *ngIf="!isGame()">
                <span>
                  <label class="heading" [for]="'endLimit-'+i">End&nbsp;Limit</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('endLimit')" pInputText
                    [min]="oldRules['controls'][i]['value']['startLimit']+1">
                </span>
              </div>
              <div class="col-md-3" *ngIf="isStreakGame()">
                <span>
                  <label class="heading" [for]="'startLimit-'+i">Ride&nbsp;Count</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('startLimit')" pInputText
                    [min]="0">
                </span>
              </div>
              <div class="col-md-3" *ngIf="isStreakGame()">
                <span>
                  <label class="heading" [for]="'workingDays-'+i">Minimum&nbsp;Days</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('workingDays')" pInputText
                    [min]="0">
                </span>
              </div>
              <div class="col-md-3"
                *ngIf="pr.get('action').value === 'penalty' || pr.get('action').value === 'reward' || pr.get('action').value === 'adjustment'">
                <span>
                  <label class="heading">Amount&nbsp;*</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('amount')" pInputText>
                </span>
              </div>
              <div class="col-md-4" *ngIf="pr.get('action').value === 'suspend'">
                <span>
                  <label class="heading margin-top-neg-15">Suspension&nbsp;Hrs&nbsp;*</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('activateAfterHours')" pInputText
                    min="0">
                </span>
              </div>
              <div class="col-md-4" *ngIf="pr.get('action').value === 'deprioritize'">
                <span>
                  <label class="heading margin-top-neg-15">Deprioritize&nbsp;Mins&nbsp;*</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('prioritizeAfterMins')" pInputText
                    min="0">
                </span>
              </div>
              <div class="col-md-2" *ngIf="showBlockRedeem(pr)">
                <span>
                  <label class="heading margin-top-neg-15">Block Redeem</label>
                  <br>
                  <input type="checkbox" class="width-85 border" [formControl]="pr.get('blockRedeem')"
                    (change)="onBlockRedeemChange(pr)">
                </span>
              </div>
              <div class="col-md-3" *ngIf="showBlockRedeemAmount(pr)">
                <span>
                  <label class="heading">Block Redeem Amount</label>
                  <br>
                  <input type="number" class="width-85 border" [formControl]="pr.get('amount')" pInputText>
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-1" style="padding-top: 30px;">
            <i class="pi pi-trash font-30 color-danger outlint-auto" (click)="deleteOldRule(i)"></i>
          </div>
        </div>
        <div *ngIf="!isGame()" class="row">
          <span class="rapido-form-field">
            <button class="ui-button-secondary sms-template" pButton type="button" (click)="addOldRule()" icon="pi pi-plus"
              label="Add Rule"></button>
          </span>
        </div>

      </div>
      <div class="row">
        <div class="margin-top-100 divider"></div>
      </div>
      <div class="row">
        <div class="pull-right">
          <span>
            <button class="back-button" type="button" (click)="goBack()">
              Back
            </button>
          </span>
          <span>
            <button mat-raised-button class="next-button" color="primary" type="button" (click)="submit()">Next</button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="!showOldRule" class="secondary-container shadow-all">
  <label class="step-header text-capitalize">{{state}} Rule</label>
  <div class="divider"></div>
  <div style="width:100%;">
    <form [formGroup]="penaltyRulesForm" class="padding-child-row-td-10">
      <div formArrayName="rules">
        <div *ngFor="let prr of penaltyRulesForm.controls.rules.controls; let i=index;" [formGroup]="prr">
          <div style="padding-top: 3%" class="flex-display">
            <div class="actionStyling">
              <span>
                <label class="heading" for="action">Action&nbsp;*</label>
                <ng-select class="action-form-field" [formControl]="prr.get('action')">
                  <ng-option *ngFor="let action of actionOptions" [value]="action">{{action}}</ng-option>
                </ng-select>
              </span>
              <span *ngIf="prr.get('action').value === 'suspend'">
                <mat-checkbox (click) = "toggleShowCaptainTask(i)" [formControl]="prr.get('showCaptainTask')"
                  [checked]="prr.get('showCaptainTask').value">Show captain task
                </mat-checkbox>
              </span>
              <span  *ngIf="prr.get('action').value === 'suspend' && prr.get('showCaptainTask').value">
                <label class="heading" for="action">Captain task</label>
                <ng-select class="action-form-field" [formControl]="prr.get('captainTask')">
                  <ng-option *ngFor="let task of captainTask" [value]="task">{{task}}</ng-option>
                </ng-select>
              </span>
              <span *ngIf="prr.get('action').value === 'pushToAgent' && prr.get('communications').at(0).value.type === 'IVR'">
                <label class="heading" for="action">Galaxy Campaigns</label>
                <ng-select class="action-form-field" [formControl]="prr.get('campaignId')">
                  <ng-option *ngFor="let campaign of campaignId" [value]="campaign">{{campaign}}</ng-option>
                </ng-select>
              </span>
              <div *ngIf="prr.get('action').value === 'penalty' || prr.get('action').value === 'reward' ||  prr.get('action').value === 'adjustment'">
                <mat-checkbox [disabled]="true || prr.get('useActionAmount').value" [formControl]="prr.get('useActionAmount')"
                  [checked]="prr.get('useActionAmount').value" (click)="state==='view'?$event.preventDefault():toggleActionAmount(i)"> Use Action Amount
                </mat-checkbox>
                <div *ngIf="prr.get('useActionAmount').value">
                  <mat-checkbox [disabled]="true || prr.get('actionMax').value" [formControl]="prr.get('actionMax')"
                  [checked]="prr.get('actionMax').value" (click)="state==='view'?$event.preventDefault():toggleActionMax(i)"> Max&nbsp;&nbsp;
                </mat-checkbox>
                <mat-checkbox [disabled]="true || prr.get('actionMin').value" [formControl]="prr.get('actionMin')"
                  [checked]="prr.get('actionMin').value" (click)="state==='view'?$event.preventDefault():toggleActionMin(i)">Min
                </mat-checkbox>
                </div>
              </div>
            </div>
            <div>
              <div class="communication-flex" *ngFor="let pr of prr.controls.communications.controls; let ii=index;"
                [formGroup]="pr">
                <div class="inputData">
                  <span>
                    <label class="heading" for="notification">Notification&nbsp;type&nbsp;*</label>
                    <ng-select [formControl]="pr.get('type')" placeholder="Select Notification Type"
                      (change)="notificationConfirm($event, pr)">
                      <ng-container *ngFor="let notification of notificationsData">
                        <ng-container *ngIf="((notification.notifType === 'INAPP' && prr.get('action').value === 'warning') ||  notification.notifType !== 'INAPP')
                        && 
                        ((notification.notifType === 'IVR' && prr.get('action').value === 'pushToAgent' && campaignType === 'automatedReactiveModel') || notification.notifType !== 'IVR')">
                        <ng-option [value]="notification.notifType" >{{notification.displayText}}</ng-option>
                        </ng-container>
                      </ng-container>
                    </ng-select>
                  </span>
                </div>
                <div class="inputData" *ngIf="prr.get('communications').at(0).value.type !== 'IVR' && pr.get('type').value != 'WHATSAPP'">
                  <span>
                    <label class="heading" for="notification">Language</label>
                    <p-dropdown [options]="supportedLocales" optionLabel="label" [style]="{'width':'100%'}" [formControl]="pr.get('currentSelectedLocale')">
                      <ng-template let-locale pTemplate="item">
                        <mat-icon *ngIf="checkTitleAndMessageForLocale(locale.value, pr)">check</mat-icon> {{ locale.label }}
                      </ng-template>
                    </p-dropdown>
                  </span>
                </div>
                <div class="inputData" *ngIf="prr.get('communications').at(0).value.type !== 'IVR' && pr.get('type').value != 'WHATSAPP'">
                  <span>
                    <label class="heading" for="title">Notification&nbsp;Title&nbsp;*</label>
                    <textarea id="textarea" class="text-boxes"  rows="3" [formControl]="getTitleFormControl(pr)"></textarea>
                  </span>
                </div>
                <div style="padding-left: 5px;" class="inputData" *ngIf="prr.get('communications').at(0).value.type !== 'IVR' && pr.get('type').value != 'WHATSAPP'">
                  <span>
                    <label class="heading" for="message">Message&nbsp;*</label>
                    <textarea class="text-boxes" rows="3"
                      [formControl]="getMessageFormControl(pr)"></textarea>
                    <label>Max. limit is 135 characters</label>
                  </span>
                </div>
                <span *ngIf="pr.get('type').value === 'WHATSAPP'">
                  <label class="heading" for="action">Whatsapp Templates</label>
                  <ng-select class="action-form-field" [formControl]="pr.get('templateId')">
                    <ng-option *ngFor="let template of whatsappTemplates" [value]="template">{{template}}</ng-option>
                  </ng-select>
                </span>
                <div class="inputDataAddMore">
                  <span>
                    <button class="addMoreButton" type="button"
                      [disabled]="state==='view'" (click)="addSet(i)" icon="pi pi-plus">+</button>
                  </span>
                </div>
                <div class="rapido-form-field">
                  <i class="pi pi-trash font-30 color-danger outlint-auto" (click)="deleteSet(i,ii)"></i>
                </div>
              </div>
            </div>
            <div class="inputData" style="padding-right: 20px;" *ngIf="!isGame()">
              <span>
                <label class="heading" [for]="'startLimit-'+i">Start&nbsp;Limit&nbsp;*</label>
                <br>
                <input type="number" class="width-85 border" [formControl]="prr.get('startLimit')" pInputText [min]="0">
              </span>
            </div>
            <div class="inputData" style="padding-right: 20px;" *ngIf="!isGame()">
              <span>
                <label class="heading" [for]="'endLimit-'+i">End&nbsp;Limit</label>
                <br>
                <input type="number" class="width-85 border" [formControl]="prr.get('endLimit')" pInputText
                  [min]="rules['controls'][i]['value']['startLimit']+1">
              </span>
            </div>
            <div class="inputData"
              *ngIf="prr.get('action').value === 'penalty'|| prr.get('action').value === 'reward' || prr.get('action').value === 'adjustment'">
              <span>
                <label class="heading">Amount&nbsp;*</label>
                <br>
                <input [disabled]="prr.get('useActionAmount')" [disabled]="prr.get('actionMax')" [disabled]="prr.get('actionMin')" type="number" class="width-85 border" [formControl]="prr.get('amount')" pInputText>
              </span>
            </div>
            <div class="inputData" *ngIf="prr.get('action').value === 'suspend'">
              <span>
                <label class="heading">Suspension&nbsp;Hrs&nbsp;*</label>
                <br>
                <input [disabled]="prr.get('showLMS')" type="number" class="width-85 border"
                  [formControl]="prr.get('activateAfterHours')" pInputText min="0">
              </span>
            </div>
            <div class="inputData" *ngIf="prr.get('action').value === 'deprioritize'">
              <span>
                <label class="heading">Deprioritize&nbsp;Mins&nbsp;*</label>
                <br>
                <input type="number" class="width-85 border"
                  [formControl]="prr.get('prioritizeAfterMins')" pInputText min="0">
              </span>
            </div>
            <div class="inputData" *ngIf="showBlockRedeem(prr)">
              <span>
                <label class="heading">Block Redeem</label>
                <br>
                <input type="checkbox" class="width-85 border" [formControl]="prr.get('blockRedeem')"
                  (change)="onBlockRedeemChange(prr)">
              </span>
            </div>
            <div class="inputData" *ngIf="showBlockRedeemAmount(prr)">
              <span>
                <label class="heading">Block Redeem Amount</label>
                <br>
                <input type="number" class="width-85 border" [formControl]="prr.get('amount')" pInputText>
              </span>
            </div>
            <div class="inputData" style="padding-top: 30px;">
              <i class="pi pi-trash font-30 color-danger outlint-auto" (click)="deleteRule(i)"></i>
            </div>
          </div>
          <hr style="border-top:1px solid">
        </div>
        <div *ngIf="!isGame()">
          <span class="rapido-form-field">
            <button class="ui-button-secondary sms-template" pButton type="button" (click)="addRule()" icon="pi pi-plus"
              label="Add Rule"></button>
          </span>
        </div>

      </div>
      <div>
        <div class="divider"></div>
      </div>
      <div>
        <div class="pull-right">
          <span>
            <button class="back-button" type="button" (click)="goBack()">
              Back
            </button>
          </span>
          <span>
            <button mat-raised-button class="next-button" color="primary" type="button" (click)="submit()">Next</button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
