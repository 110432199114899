<app-toaster [config]="toastService.toaster"></app-toaster>
<filter-pane [filterComponents]="filterComponents" [showFilter]="showFilters" (change)="filterChange($event)"
  (submit)="applyFilters($event)"></filter-pane>
<div>
  <mat-toolbar class="toolbar-border">
    Subscription Details
    <div class="pull-right is-flex" style="height: 35px;">
      <button mat-raised-button class="btn-primary" (click)="showFilters = !showFilters">Filters</button>
    </div>
  </mat-toolbar>
  <data-table [table]="table" (paginationChange)="fetchTableData()" (headerToSort)="sortByHeader($event)"></data-table>
</div>
