/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toast-message.service";
import * as i2 from "@angular/material/snack-bar";
var styles_ToastSnackbar = [];
var RenderType_ToastSnackbar = i0.ɵcrt({ encapsulation: 2, styles: styles_ToastSnackbar, data: {} });
export { RenderType_ToastSnackbar as RenderType_ToastSnackbar };
export function View_ToastSnackbar_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }); }
export function View_ToastSnackbar_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toast-snackbar", [], null, null, null, View_ToastSnackbar_0, RenderType_ToastSnackbar)), i0.ɵdid(1, 49152, null, 0, i1.ToastSnackbar, [i2.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var ToastSnackbarNgFactory = i0.ɵccf("toast-snackbar", i1.ToastSnackbar, View_ToastSnackbar_Host_0, {}, {}, []);
export { ToastSnackbarNgFactory as ToastSnackbarNgFactory };
