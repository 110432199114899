import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { MyHttpClient } from "../http-client";

const ROSDHeaders = ["riderId", "amount", "orderIds", "serviceDetailIds"];
const ROSDSample = {
  riderId: "61702d357ac72e5b29ed0046",
  amount: "100",
  orderIds: "62ab32e31ac88c0cbac7733d|62ab32e31ac88c0cbac7733e|62ab32e31ac88c0cbac7733f",
  serviceDetailIds: "5c4317d8487a78564f91f4c7|5c4317d8487a78564f91f4c8"
};

const RSDHeaders = ["riderId", "amount", "serviceDetailIds"];
const RSDSample = {
  riderId: "61702d357ac72e5b29ed0046",
  amount: "100",
  serviceDetailIds: "5c4317d8487a78564f91f4c7"
};

const RCHeaders = ["riderId", "amount", "cityId"];
const RCSample = {
  riderId: "61702d357ac72e5b29ed0046",
  amount: "100",
  cityId: "5c30ae72f0c2c508a5be17ca",
};

const ROCHeaders = ["riderId", "amount", "cityId", "orderIds"];
const ROCSample = {
  riderId: "61702d357ac72e5b29ed0046",
  amount: "100",
  cityId: "5c30ae72f0c2c508a5be17ca",
  orderIds: "62ab32e31ac88c0cbac7733d|62ab32e31ac88c0cbac7733e|62ab32e31ac88c0cbac7733f",
};

export const BulkCreditArrayFields = ["orderIds", "serviceDetailIds"]

const sampleFileFactoryMap = new Map([
  ["ROSD", { headers: ROSDHeaders, sample: ROSDSample }],
  ["RSD", { headers: RSDHeaders, sample: RSDSample }],
  ["RC", { headers: RCHeaders, sample: RCSample }],
  ["ROC", { headers: ROCHeaders, sample: ROCSample }],
]) as Map<string, { headers: string[], sample: any }>

type Config = {
  headers: string[];
  sampleData: any[];
  name: string;
  displayName: string;
  disabled?: boolean;
  type: string;
};

@Injectable()
export class BulkCreditService {
  public transactionTypes: Config[] = environment.bulkCreditTransactions
    .map(t => ({
      ...t,
      headers: sampleFileFactoryMap.get(t.factoryType).headers,
      sampleData: [sampleFileFactoryMap.get(t.factoryType).sample]!,
    }))

  private static apiRoot = environment.server;

  constructor(private httpClient: MyHttpClient) { }

  initiateBulkCredit(data: any): Promise<any> {
    return this.httpClient.post(BulkCreditService.apiRoot + '/api/bulkcredit/initiate', data).toPromise();
  }

  valdidateBulkCreditFile(data: any): Promise<any> {
    return this.httpClient.post(BulkCreditService.apiRoot + '/api/bulkcredit/validate', data).toPromise();
  }

  fetchBulkCreditHistory(data: any) {
    return this.httpClient.post(BulkCreditService.apiRoot + '/api/bulkcredit/history', data).toPromise();
  }

  downloadResult(fileId: string) {
    return this.httpClient.get(BulkCreditService.apiRoot + '/api/bulkcredit/' + fileId).toPromise();
  }
}
