import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit {

  @Input() calendarData: any;
  @Output() selectedDates: EventEmitter<any> = new EventEmitter();
  public fromDate: Date;
  public toDate: Date;
  public maxDate: Date;
  public minDate: Date;

  public selectedValues: any;
  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    // this.calendarData = {
    //     type : 'calendar',
    //     title : 'From Date',
    //     values : [],
    //     default : '',
    //     key : 'from-to-date'
    //   };
    // console.log('calendarData : ', this.calendarData);
  }

  changeInDate() {
    if (this.fromDate) {
      this.minDate = this.fromDate;
    }
    if (this.toDate) {
      this.maxDate = this.toDate;
    }

    // console.log('dates : : : : ', this.fromDate, this.toDate);
    const sendStartDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    const sendEndDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');

    const emitSelections = {
      [this.calendarData.key] : [sendStartDate, sendEndDate]
    };
    // console.log('Emitted vals : ', emitSelections);
    this.selectedDates.emit(emitSelections);
  }

}
